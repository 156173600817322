import { Component, OnInit, Inject } from '@angular/core';
import { MdlDialogService } from '@angular-mdl/core';
import { Router,ActivatedRoute } from '@angular/router';
import { ConfigComponent } from './config.component';
import { DashboardService } from '../_services/dashboard.service';
import { ConfirmationDialog } from '../dialog/dialog.component';
import { ShowProjectDetailsComponent } from '../admin/show-project-details/show-project-details.component';
import { ToastrService } from 'ngx-toastr';
import { ResearchService} from "../_services/research.service";
import { OrganizationService} from "../_services/organization.service";
import { Globals } from "../globals";
import { AssignOrganisationComponent } from '../dialog/assign-organisation/assign-organisation.component';
import { DataService } from '../_services/data.service';
import { UpdateNetworkDetailsComponent } from './update-network-details/update-network-details.component';
import { UpdateEgressStoreDetailsComponent } from './update-egress-store-details/update-egress-store-details.component';

declare var $;

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
  providers:[DashboardService, Globals]
})
export class SettingsComponent implements OnInit {

  topologyMapperURL: string = "/topology/uploadFile";
  fileUploadYml: any = '';
  topoFile: any;
  conFigStatus: string = "Ready";
  contactValue: any;
  contact: any;
  email: any;
  name: any;
  groupName: any;
  groupEmail: any;
  secondaryContacts: any;
  groupEmailsList: any;
  groupContacts: any;
  oneAtATime: boolean = true;
  isOpen = true;
  isOpen1 = true;
  contacts: any;
  groupEmails: any;
  groupId: any;
  type:any;
  settings:any;
  businessService:any;
  page=1;
  pageSizeSelected=10;
  pageSize=10;
  currentPageLowerCount = 1;
  currentPageUpperCount = 10;
  sortBy='created_on';
  sortOrder='asc';
  totalRecords = 0;
  totalPages = 1;
  business_page=1;
  business_pageSizeSelected=10;
  business_pageSize=10;
  business_totalRecords = 0;
  business_totalPages = 1;
  contact_page = 1;
  contact_pageSizeSelected = 10;
  contact_pageSize = 10;
  contact_totalRecords = 0;
  contact_totalPages = 1;
  search: string='';
  openTap: any=0;
  cur: number = 0;
  loader: boolean=true;
  public showSpinner = false;
  contactsById: any='';
  private parametersObservable: any;
  runbookList: any = [];
  selectedRunbook: any = '';
  selectedBSM: any = '';
  selectedCheckId: any = '';
  selectedGroupId: any = '';
  workflowList: any;
  workflow_page = 1;
  workflow_pageSizeSelected = 10;
  workflow_pageSize = 10;
  workflow_totalRecords = 0;
  workflow_totalPages = 1;
  orgData: any;
  principleProjData: any;
  accountIdList = [];
  orgList = [];
  userType: any;
  getEntitlementData = [];
  public currentTab = 0;
  orgId;
  org_id;
  showSubscriptionTab = false;
  showAddnew = false;
  settingHelpMessage = 'Contact your project owner to add at least one project account.';
  settingDelinkMessage = ` Please delete the setting and try adding again.`;
  currentUser;
  userLevel;
  orgEnableTREFeature;
  accountType = 'project'
  eventS = this.getEventSource(`/stream_events`);
  selectedIndex:number
  studies: any;

  constructor(private globals: Globals,
              private dialog: MdlDialogService,
              private dashboardService: DashboardService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private researchService: ResearchService,
              private organizationService: OrganizationService,
              private toastr: ToastrService,
              private dataService: DataService) { }

  ngOnInit() {
    this.serverStreamEvent();
    this.activatedRoute.queryParams.subscribe(params => {
      const selectedTab = params['selectedTab'];
      if (selectedTab === 'studyAccount') {
        // Select the "Data Accounts" tab
        this.selectedIndex = 1; 
        this.accountType = "study"
      }
    });
    this.userLevel = sessionStorage.getItem('Level');
    this.org_id = sessionStorage.getItem('org_id');
    this.dataService.updatePage.subscribe(event => {
      if(event){
        this.getSettingsData();
      }
    })
    let userRole = sessionStorage.getItem('5b1647bf469e0b0cf9dcacca');
    
    this.currentUser = sessionStorage.getItem('currentUserEmail');
    let orgId = sessionStorage.getItem('tenantid');
      if(userRole === "landlord" || this.userLevel == '2'){
           this.userType = 'admin'
           //check if registerServiceUrl is configured, then only show subscription tab as per requirement
           this.getRegisterServiceUrl(orgId);
           this.settingHelpMessage = 'Click the Add New button above to add at least one project account.';
      }else if(this.userLevel == "1"){
           this.userType = 'principal';
          this.settingHelpMessage = 'Click the Add New button to add a new project account.';
      }else{
            this.userType = 'researcher';
      }

    this.parametersObservable = this.activatedRoute.params.subscribe(params => {
      if(params["vendor"]==="fromRunbook"){
        this.openTap = 1;
      }
      if(params['time']) {
        this.openTap=0;
      }

      if(params['message']) {
        this.openTap=0;
        var urlArr = new Object(this.activatedRoute.url);
        if(urlArr['value'] && urlArr['value'][1].path == 'error')
        this.toastr.error(params['message'], '',  this.globals.tosterOverride);
        else this.toastr.success(params['message'], '', this.globals.tosterOverride);
      }

      let userRole = sessionStorage.getItem('5b1647bf469e0b0cf9dcacca');
      if(userRole === "landlord" || this.userLevel == '2'){
           this.userType = 'admin';
      }else if(this.userLevel == "1"){
           this.userType = 'principal';
      }else{
            this.userType = 'researcher';
      }
      this.getSettingsData();
    })
    this.dashboardService.getApplicationMode().subscribe((data:any) => {
      if (data.enableB2CMode) {
        this.showAddnew = true;
      }
    },error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    });
}

  getSettings(action) {
    if(action === 'next'){
      this.page = this.page + 1;
    }
    if(action === 'previous'){
      this.page = this.page - 1;
    }
    this.dashboardService.getSettingsDetail(this.page,this.pageSize,this.sortBy,this.sortOrder,this.userType,this.accountType).subscribe((settingsDetail:any) => {
      this.settings = settingsDetail.settings;
      this.page = settingsDetail.metaData.page;
      this.pageSize = settingsDetail.metaData.pageSize;
      this.totalRecords = settingsDetail.metaData.totalRecords;
      this.sortBy = settingsDetail.metaData.sortBy;
      this.sortOrder = settingsDetail.metaData.sortOrder;
      this.totalPages = settingsDetail.metaData.totalPages;
    }, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
     })
  }

  getOrgTREStatus(){
    this.organizationService.getOrganizationsList().subscribe(orgData =>{
      this.orgData = orgData;
          this.orgData.forEach((num1, index)=>{
            if(this.org_id == num1._id){
               if(num1.EnableTREFeature != undefined && num1.EnableTREFeature != null){
                this.orgEnableTREFeature = num1.EnableTREFeature;
               }
            }
          });
    },error =>{
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    })
  }

  onTabIndexChanged(selectedIndex): void {
    switch (selectedIndex) {
      case 0:
        this.accountType = 'project';
        this.getSettingsData()
        break;
      case 1:
        this.accountType = 'study';
        this.getSettingsData()
        break;
    }
  }

getSettingsData(){
  this.showSpinner = true;
  this.dashboardService.getSettingsDetail(this.page, this.pageSize, this.sortBy, this.sortOrder, this.userType,this.accountType).subscribe((settingsDetail:any) => {
    this.settings = settingsDetail.settings;
    this.page = settingsDetail.metaData.page;
    this.pageSize = settingsDetail.metaData.pageSize;
    this.totalRecords = settingsDetail.metaData.totalRecords;
    this.sortBy = settingsDetail.metaData.sortBy;
    this.sortOrder = settingsDetail.metaData.sortOrder;
    this.totalPages = settingsDetail.metaData.totalPages;
    if (this.userLevel == '1' || this.userLevel == '0'){
    this.getOrgTREStatus();
    }else if(this.userLevel == '2'){
      this.orgEnableTREFeature = true
    }
    //Calculation for lower & upper count per page
  this.currentPageLowerCount = 1 + ((this.page - 1) * this.pageSize);
  if(this.settings.length < 10){
    this.currentPageUpperCount = this.settings.length + (this.pageSize * (this.page - 1));
  }else {
    this.currentPageUpperCount = this.pageSize * this.page;
  }
    var orgIds = [];
    this.settings = settingsDetail.settings.map(obj => {
        const accountObj = {
        'accountId': obj._id,
        'name': obj.name,
        'vendor': obj.vendor,
        'region': obj.region,
        'accountNumber': obj.accountNumber,
        'orgName': [],
        'project': [],
        'externalStudies':[],
        'created_on': obj.created_on,
        '_id': obj._id,
        'isCleanUpStarted': obj.isCleanUpStarted,
        'status': obj.status,
        'data': obj.data,
        'username': obj.username,
        'projectId': obj.projectId,
        'orgId': obj.orgId,
        'isTreEnabled': obj.isTreEnabled,
        'isShared' : obj.isShared
      };
      return accountObj;
    });
    
    if(this.accountType == 'study'){
      let studyAccount = [];
      for(let account of this.settings) {
        if(account.isShared === true && account.orgId.includes(this.org_id)) {
          studyAccount.push(account);
        } else if(account.isShared === false && account.username === this.currentUser) {
          studyAccount.push(account);
        }
      }
      this.settings = studyAccount;
    }

    this.organizationService.getOrganizationsList().subscribe(orgData =>{
      this.orgData = orgData;
      this.orgData.forEach((num1, index)=>{
          this.orgData[index].account_id.forEach((num2, j)=>{
            const found = this.settings.find(element => {
              if(element.accountId == num2){
                element.orgName.push(this.orgData[index].org_name)
              }

            });
          })
        })
    },error =>{
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    })

    if(this.userType == 'principal'){
      this.researchService.getPrincipalProjects('1', '9999').subscribe((principleProjData:any) =>{
        this.principleProjData = principleProjData.docs;
        this.principleProjData.forEach((num1, index)=>{
              const found = this.settings.find(element => {
                if(element.accountId == num1.account_id){
                  element.project.push(this.principleProjData[index].name);
                  //check if PI is part of the project
                  if(num1.researchers.includes(this.currentUser)){
                    element.researcherIsPartOfProject = true;
                  }
                }
                if(element.username === this.currentUser){
                  element.researcherIsPartOfProject = true;
                }
              });
          });
          //Check for setting username
          this.settings.forEach((setting)=>{
            if(setting.username == this.currentUser){
              setting.researcherIsPartOfProject = true;
            }
          });
      }, error=>{
        if(error && error.status === 401){
          let errBody = error.error;
           this.toastr.error(errBody.err, '', this.globals.tosterOverride);
           this.router.navigate(['login']);
         }
      })
    }else if(this.userType == 'researcher'){
      this.researchService.getProjects().subscribe(principleProjData =>{
        this.principleProjData = principleProjData;
        this.principleProjData.forEach((num1, index)=>{
              const found = this.settings.find(element => {
                if(element.accountId == num1.account_id){
                  element.project.push(this.principleProjData[index].name);
                   //check if researcher is part of the project
                   if(num1.researchers.includes(this.currentUser)){
                    element.researcherIsPartOfProject = true;
                  }
                }

              });
          })
      },  error => {
        if(error && error.status === 401){
          let errBody = error.error;
           this.toastr.error(errBody.err, '', this.globals.tosterOverride);
           this.router.navigate(['login']);
         }
      })
    }
    if(this.accountType == "study"){
      this.researchService.getExternalStudies().subscribe(study =>{
        this.studies = study;
        this.studies.forEach((num1, index)=>{
          const found = this.settings.find(element => {
            if(element.accountId == num1.accountId){
              element.externalStudies.push(this.studies[index].name);
            }
          });
      });
      })
    }
    this.showSpinner = false

  }, error => {
    if(error && error.status === 401){
      let errBody = error.error;
       this.toastr.error(errBody.err, '', this.globals.tosterOverride);
       this.router.navigate(['login']);
     }
   })
}

getProjectDetails(settingsData){
  this.dashboardService.getDialogBox().subscribe((data:any) => {
    if(data.status === 200){
  this.dialog.showCustomDialog({
    component: ShowProjectDetailsComponent,
    providers: [{ provide: 'data', useValue: { settingsData: settingsData } }],
    isModal: true,
    styles: {
      "height":"373px",
      "width":"800px",
      "border": "1px solid #FFFFFF33",
      "border-radius": "10px",
      "background-color": "#07102A",
      "padding": "0px"
    },
    clickOutsideToClose: true,
    enterTransitionDuration: 400,
    leaveTransitionDuration: 400
  })}}, error => {
    if(error && error.status === 401){
      let errBody = error.error;
       this.toastr.error(errBody.err, '', this.globals.tosterOverride);
       this.router.navigate(['login']);
     }
  })
}

  getNextSettings(action) {
    if (action === 'first' && this.page !== 1) {
      this.page = 1;
      this.getSettingsData();
    }
    if (action === 'previous' && this.page > 1) {
      this.page = this.page - 1;
      this.getSettingsData();

    }
    if (action === 'next' && this.page !== this.totalPages) {
      this.page = this.page + 1;
      this.getSettingsData();

    }
    if (action === 'last' && this.page !== this.totalPages) {
      this.page = this.totalPages;
        this.getSettingsData();
      }
  }

  convertDateToString(date) {
    if(date !== 0) {
      var timeString = new Date().setTime(date);
      var sysCreatedOn = new Date(timeString).toLocaleString();
      return sysCreatedOn;
    } else {
      return '-'
    }
  }

  changePageSize() {
    this.pageSize = this.pageSizeSelected;
    this.page = 1;
    this.dashboardService.getSettingsDetail(this.page,this.pageSize,this.sortBy,this.sortOrder,this.userType,this.accountType).subscribe((settingsDetail:any) => {
      this.settings = settingsDetail.settings;
      this.page = settingsDetail.metaData.page;
      this.pageSize = settingsDetail.metaData.pageSize;
      this.totalRecords = settingsDetail.metaData.totalRecords;
      this.sortBy = settingsDetail.metaData.sortBy;
      this.sortOrder = settingsDetail.metaData.sortOrder;
      this.totalPages = settingsDetail.metaData.totalPages;
    },error=>{
      if(error && error.status === 401){
      let errBody = error.error;
       this.toastr.error(errBody.err, '', this.globals.tosterOverride);
       this.router.navigate(['login']);
    }
     })
  }

  updateSettingById(settings) {
    this.dashboardService.getDialogBox().subscribe((data:any) => {
      if(data.status === 200){
    this.dialog.showCustomDialog({
      component: ConfigComponent,
      providers: [{ provide: 'data', useValue: { settings: settings, action: 'update' } }],
      isModal: true,
      clickOutsideToClose: true,
      enterTransitionDuration: 400,
      leaveTransitionDuration: 400
    })}}, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    })
  }

deleteSettingById(settingName, settingId) {
    this.dashboardService.getDialogBox().subscribe((data:any) => {
      if(data.status === 200){
    this.dialog.showCustomDialog({
      component: ConfirmationDialog,
      providers: [{ provide: 'data', useValue: { action: 'setting', accountName: settingName, id:settingId}}],
      isModal: true,
      styles: {
        "height":"auto",
        "width":"520px",
        "border": "1px solid #FFFFFF33",
        "border-radius": "10px",
        "background-color": "#07102A",
        "padding": "0px"
      },
      clickOutsideToClose: true,
      enterTransitionDuration: 400,
      leaveTransitionDuration: 400,
    })}}, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    })
    setTimeout(function(){
      $('.mdl-dialog').attr('id','dilog-delete')
    },10)
  }
  
  deleteStudyAccount(settingName, settingId) {
    this.dashboardService.getDialogBox().subscribe((data:any) => {
      if(data.status === 200){
      this.dialog.showCustomDialog({
      component: ConfirmationDialog,
      providers: [{ provide: 'data', useValue: { action: 'studyAccount', accountName: settingName, id:settingId}}],
      isModal: true,
      styles: {
        "height":"auto",
        "width":"520px",
        "border": "1px solid #FFFFFF33",
        "border-radius": "10px",
        "background-color": "#07102A",
        "padding": "0px"
      },
      clickOutsideToClose: true,
      enterTransitionDuration: 400,
      leaveTransitionDuration: 400,
    })}}, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    })
    setTimeout(function(){
      $('.mdl-dialog').attr('id','dilog-delete')
    },10)
  }
  
  addSettings(vendor:string): void {
    sessionStorage.removeItem('projectName');
    sessionStorage.removeItem('projectDesc');
    sessionStorage.removeItem('projectBudget');
    sessionStorage.setItem('accountType', this.accountType);
    this.router.navigate(['/addAccount'], { queryParams: { vendor: vendor } });
  }

  editSettings(id): void {
    this.dashboardService.getSettingsDetails(id).subscribe(data => {
      this.dialog.showCustomDialog({
        component: ConfigComponent,
        providers: [{ provide: 'data', useValue: { services: data, name: data } }],
        isModal: true,
        styles: {
        "height":"auto",
        "width":"40%",
        "border": "1px solid #FFFFFF33",
        "border-radius": "10px",
        "background-color": "#07102A",
        "padding": "0px"
        },
        clickOutsideToClose: true,
        enterTransitionDuration: 400,
        leaveTransitionDuration: 400,
      })
    }, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    })
  }
  updateNetworkDetails(id: any) {
    this.dashboardService.getDialogBox().subscribe((data: any) => {
      if (data.status === 200) {
        this.dialog.showCustomDialog({
          component: UpdateNetworkDetailsComponent,
          providers: [{ provide: 'data', useValue: { accountId: id } }],
          isModal: true,
          styles: {
            "height":"auto",
            "width":"40%",
            "border": "1px solid #FFFFFF33",
            "border-radius": "10px",
            "background-color": "#07102A",
            "padding": "0px"
            },
          clickOutsideToClose: true,
          enterTransitionDuration: 400,
          leaveTransitionDuration: 400,
        })
      }
    }, error => {
      if (error && error.status === 401) {
        let errBody = error.error;
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
      }
    })    
  }

  updateEgressStoreDetails(setting: any) {
    this.dashboardService.getDialogBox().subscribe((data: any) => {
      if (data.status === 200) {
        this.dialog.showCustomDialog({
          component: UpdateEgressStoreDetailsComponent,
          providers: [{ provide: 'data', useValue: { accountId: setting._id, accountNumber: setting.accountNumber, region: setting.region } }],
          isModal: true,
          styles: {
            "height":"auto",
            "width":"40%",
            "border": "1px solid #FFFFFF33",
            "border-radius": "10px",
            "background-color": "#07102A",
            "padding": "0px"
            },
          clickOutsideToClose: true,
          enterTransitionDuration: 400,
          leaveTransitionDuration: 400,
        })
      }
    }, error => {
      if (error && error.status === 401) {
        let errBody = error.error;
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
      }
    })    
  }
  

  productSync(settingDetail){
    this.showSpinner = true;
    this.researchService.syncProduct(settingDetail).subscribe( (response:any) => {
      this.showSpinner = false;
      this.toastr.success(response.message, '', this.globals.tosterOverride);
    }, error => {
      console.log(error);
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }else{
        this.toastr.error('Error in syncing products', '', this.globals.tosterOverride);
       }
    });
  }

  getOrgList(data){
    if(data.length>0){
      let str = data.toString();
      return str
    }else{
      return '-'
    }

  }

  getEntitlement(data){
    this.showSpinner = true;
    this.researchService.getEntitlement(data).subscribe( (response:any) => {
      this.showSpinner = false;
      this.getEntitlementData = [];
      if(response.Entitlements){
        this.getEntitlementData = response.Entitlements;
      }else{
        this.getEntitlementData = [];
      }

    }, error => {
      console.log(error);
      this.showSpinner = false;
      this.toastr.error('Error in getting entitlement data', '', this.globals.tosterOverride);
    });
  }

  getRegisterServiceUrl(orgId){
    this.showSpinner = true;
    this.researchService.getRegisterServiceUrl(orgId).subscribe( (response:any) => {
      this.showSpinner = false;
      if(response.registerServiceUrl != null){
        this.orgId = {
          "orgId": orgId
        }
        this.getEntitlement(this.orgId);
        this.showSubscriptionTab = true;
       }

    }, error => {
      console.log(error);
      this.showSpinner = false;
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }else{
      this.toastr.error('Error in getting registerService URL', '', this.globals.tosterOverride);
       }
    });
  }

  assignOrganisation(account) {
    this.dashboardService.getDialogBox().subscribe((data:any) =>{
      if(data.status === 200){
    this.dialog.showCustomDialog({
      component: AssignOrganisationComponent,
      providers: [{ provide: 'data', useValue: {action: 'assignOrganisation', accountInfo: account} }],
      isModal: true,
      styles: {
        "height":"auto",
        "width":"500px",
        "border": "1px solid #FFFFFF33",
        "border-radius": "10px",
        "background-color": "#07102A",
        "padding": "0px"
      },
      clickOutsideToClose: true,
      enterTransitionDuration: 400,
      leaveTransitionDuration: 400,
    })}}, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    })
  }

  trimSettingsName(data) {
      const strLen = data.length;
      let str = data;
      if (strLen > 15) {
        str = str.slice(0, 15) + '...';
      }
      return str;
    }

    getEventSource(url: string): EventSource {
      return new EventSource(url, { withCredentials: true });
    }

    //SSE for add study account events
  serverStreamEvent() {
    this.researchService.getServerSentEvent(this.eventS).subscribe(res => {
      const resObj = res.data;
      if (!resObj) {
        return;
      }
      try {
        const { status, data, payload } = JSON.parse(resObj);
        // server give handshake to the alive connection
        if (data === 'check') {
          return;
        }
        if (data === 'studyAccount') {
          this.getSettingsData();
        }
      } catch (e) {
        console.log("Error in SES events for studyAccount", e.message);
      }
    });
  }

}
