import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as io from 'socket.io-client';
@Injectable()
export class SocketService {
    private socket;

    constructor() {
        this.socket = io.connect('/',{
            reconnection: true,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
            reconnectionAttempts: Infinity
        });
    }

    getGroupsByStatus() {
        return new Observable(observer => {
            this.socket.on('getGroupsByStatus', (data) => {
                observer.next(data);
            });
        })
    }
}
