import { Component, OnInit, ViewChild} from '@angular/core';
import { ResearchService } from "../../_services/research.service";
import { DashboardService } from '../../_services/dashboard.service';
import { Router, RoutesRecognized, ActivatedRoute } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { Globals } from "../../globals";
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { DataService } from '../../_services/data.service';
import { MdlDialogService } from '@angular-mdl/core';
import { AddUserComponent } from '../../dialog/add-user/add-user.component';
import { CostControlComponent } from '../../dialog/cost-control/cost-control.component';
import { PreviousRouteService } from '../../_services/url.service';
// import 'rxjs/add/operator/filter';
// import 'rxjs/add/operator/pairwise';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.css'],
  providers: [Globals]
})
export class AddProjectComponent implements OnInit {

  @ViewChild('addProjectTabs') addProjectTabs: TabsetComponent;
  orgId = sessionStorage.getItem('org_id');
  availableProductsToShow;
  catalogCount = 0;
  accountIdArr = [];
  fAccountIdList = [];
  fAccountUsers = [];
  loadForm;
  accountIdname = [];
  viewAccountIdname: string;
  ProjectData;
  accountListFound;
  accountIdList = [];
  showSpinner = false;
  showProjectSpinner = false;
  projectName;
  projectDescription;
  budgetAvailable;
  accountId = [];
  accountValue;
  researchIds = [];
  gotProductSyncType = true;
  gotAccount = false;
  productSyncType = [];
  catalogs;
  byocCatalogs;
  tagKey;
  tagValue;
  productCatalogTag;
  showTagFiled = false;
  defaultClass = 'listValue';
  filedType;
  helpTextData;
  sectionWiseHelpdata;
  cur: number = 0;
  curx: number = 0;
  showHelp = false;
  catalogValue;
  researcherIdList= [];
  selected=-1;
  previousUrl: Observable<string> = this.previousRouteService.previousUrl$;
  previousURLVal;
  backPage;
  currentUrl;
  fromWhichPage;
  loader;
  gotResList;
  isCostControlEnabled = true;
  projectCostControl;
  projectPause;
  projectStop;
  costControlLink;
  projectCopies;
  projectCopiesMaxCount;
  standardCatalogLink;
  ownCatalogLink;
  ownCatalogWithTagLink;
  projectStorageLink;
  clicked = false;
  createProjectStorage = true;
  private parametersObservable: any;
  treEnable;
  egressEnable;
  settingType;
  projectTypeArray = [
    { 'type': 'Secure Research', checked: true },
    { 'type': 'Data Library', checked: false },
    { 'type': 'Ingress Gateway', checked: false }
  ];
  projectType;
  selectedSettingType = 'Regular';
  selectedProjectType;
  isEgressStoreEnabled = false;
  selectedSettingDetails;
  image;
  addProjectLink;
  projectTypedescription: any;
  readMoreLink: any;
  loginStrategy;
  showAddUser = true;
  showLinkedExternalGroup = false;
  linkedActiveDirectoryGroup;
  IscurrentVendorAws=false;

  constructor(
    private serviceApi: ResearchService,
    private dashboardService: DashboardService,
    private toastr: ToastrService,
    private globals: Globals,
    public route: Router, private dataService: DataService,
    private dialog: MdlDialogService, private previousRouteService: PreviousRouteService,
    private router: ActivatedRoute) {
    // this.route.events
    // .filter(e => e instanceof RoutesRecognized)
    // .pairwise()
    // .subscribe((event: any[]) => {
    //   this.previousUrl = event[0].urlAfterRedirects
    // });
      this.route.routeReuseStrategy.shouldReuseRoute = () => false;
    }

  ngOnInit() {
    this.dashboardService.checkIfPI();
    this.loginStrategy = sessionStorage.getItem('strategy');
    if (this.loginStrategy === 'saml' || this.loginStrategy === 'azure-ad-oidc') {
      this.showAddUser = false;
      this.showLinkedExternalGroup = true;
    }
    const element = document.querySelector('#scrollId');
    this.projectCopies = 1;
    element.scrollIntoView();
    const defaultProjectTypeObj = this.projectTypeArray.find(item => item.checked);

    // If a default project type is found, call selectProjectType with its name and a dummy event
    if (defaultProjectTypeObj) {
      this.selectProjectType(defaultProjectTypeObj.type, { target: { checked: true } });
    }
    this.parametersObservable = this.router.params.subscribe(params => {
      this.gotProductSyncType = true;
      this.fromWhichPage = this.router.snapshot.params['from'];
      this.previousRouteService.previousUrl$.subscribe((previousUrl: string) => {
        this.previousURLVal = previousUrl;
      })
      this.currentUrl = this.route.url;
      if(this.fromWhichPage == 'addUser' || this.fromWhichPage == 'fromSettings'){
        this.currentUrl = this.route.url.split('?')[0] ;
      }
      if(this.currentUrl == '/addProject/addUser' || this.currentUrl == '/addProject/fromSettings' || this.previousURLVal == '/addAccount'){
        if((sessionStorage.getItem('projectName') !=='null') && (sessionStorage.getItem('projectName') !=='undefined') && (sessionStorage.getItem('projectName') !== '')){
          this.projectName = sessionStorage.getItem('projectName');
        }
        if((sessionStorage.getItem('projectDesc') !=='null') && (sessionStorage.getItem('projectDesc') !=='undefined') && (sessionStorage.getItem('projectDesc') !== '' )){
          this.projectDescription = sessionStorage.getItem('projectDesc');
        }
        if( (sessionStorage.getItem('projectBudget') !=='null') && (sessionStorage.getItem('projectBudget') !=='undefined') && (sessionStorage.getItem('projectBudget') !== '')){
          this.budgetAvailable = sessionStorage.getItem('projectBudget');
        }

      }else{
        sessionStorage.removeItem('projectName');
        sessionStorage.removeItem('projectDesc');
        sessionStorage.removeItem('projectBudget');
        this.projectName = '';
        this.projectDescription = '';
        this.budgetAvailable = '';
      }
      this.getPrincipalProjects();
      this.getResearchers();
      this.catalogs = [{
        viewValue: 'Create standard catalog',
        value: 'standard',
        checked: false
      }
    ];
      this.byocCatalogs = [{
        viewValue: 'Bring all catalog items',
        value: 'ownCatalog',
        checked: false
      },
      {
        viewValue: 'Bring specific catalog items',
        value: 'ownCatalogWithTag',
        checked: false
      }
    ];
      this.getAllHelptextdata('all', 'create_project_helpText');
      this.getOrgSpecificCatalogs();
    });
    this.serviceApi.getSelectionEvent().subscribe(() => {
      this.isCostControlEnabled = true;
    });
    this.getProjectCostControlLimit();
    this.getMaxProjectCopiesLimit();
  }

  getPrincipalProjects() {
    this.loadForm = true;
    this.loader = true;
    this.serviceApi.getPrincipalProjects('1', '9999').subscribe((res:any) => {
      this.loadForm = false;
      this.ProjectData = res.docs;
      for (let i = 0; i < this.ProjectData.length; i++) {
        this.accountIdArr.push(this.ProjectData[i].account_id);
      }
      this.viewAccountIdname = this.accountIdname.toString();
      this.getAccounts();
    }, error=>{
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.route.navigate(['login']);
       }else if(error && error.status === 403){
          const err = error.error;
          this.toastr.error(err.error, 'Error', this.globals.tosterOverride);
          this.route.navigate(['pageNotFound']);
      }
      this.loadForm = false;
      this.accountListFound = false;
      this.fAccountIdList = [];
    });
  }

  getAccounts() {
    this.loadForm = true;
    this.loader = true;
    this.fAccountIdList = [];
    this.dashboardService.getSettingsDetail('1', '999', 'created_on', 'asc', 'principal','project').subscribe(
      (res:any) => {
        this.loader = false;
        // this.accountIdList = [];
        res.settings.filter(obj => {
          if(obj.status === 'success'){
            this.treEnable = obj.isTreEnabled;
            this.egressEnable = obj.isEgressStoreEnabled;
            if(this.treEnable === true) {
              this.settingType = 'Secure Research Environment';
            } else {
              this.settingType = '';
            }
            const fObj = {
              'value': obj._id,
              'viewValue': obj.name,
              'vendor': obj.vendor,
              'checked' : false,
              'type' : this.settingType,
              'isEgressStoreEnabled' : this.egressEnable
            };
            this.fAccountIdList.push(fObj);
          }
          this.accountListFound = true;
        });
        if(this.fAccountIdList.length == 1){
          this.fAccountIdList[0].checked = true;
          this.accountId = this.fAccountIdList[0].value;
          this.gotAccount = true;
          if(this.fAccountIdList[0].type === 'Secure Research Environment') {
            this.selectedSettingType = 'Secure Research Environment';
            this.selectedProjectType = 'secure';
            this.projectCopies = 1;
            this.productSyncType = [];
            // For enterprise deployment we are hiding the project copies
            if(!this.showLinkedExternalGroup) {
              (document.getElementById("projectCopiesId") as any).disabled = true;
              const dl = document.getElementById("projectCopiesId");
              dl.style.cursor = 'not-allowed';
            }
            this.image = '../assets/images/secureResearch.png';
            if(this.fAccountIdList[0].isEgressStoreEnabled === true) {
              this.isEgressStoreEnabled = true;
            } else {
              this.isEgressStoreEnabled = false;
            }
          } else {
            this.selectedSettingType = 'Regular';
            this.selectedProjectType = 'regular';
            // For enterprise deployment we are hiding the project copies
            if(!this.showLinkedExternalGroup) {
              (document.getElementById("projectCopiesId") as any).disabled = false;
              const dl = document.getElementById("projectCopiesId");
              dl.style.cursor = 'text';
            }
            this.isEgressStoreEnabled = false;
            this.image = '../assets/images/rocket.png';
          }
        }
        this.loadForm = false;
        if(this.fAccountIdList.length == 0){
          this.accountListFound = false;
        }
    },error=>{
      this.loader = false;
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.route.navigate(['login']);
       }
    });
  }

  createProject($event: MouseEvent) {
    this.showProjectSpinner = true;
    //disable button on click
    // ($event.target as HTMLButtonElement).disabled = true;
    this.clicked = true;
    const el = document.getElementById("submitProject");
    el.style.backgroundColor = '#3E6BFC';
    if(this.selectedSettingType == 'Secure Research Environment'){
      this.createProjectStorage = false;
    }
    let formObj:any = {
      'name': this.projectName,
      'description': this.projectDescription,
      'budget': this.budgetAvailable,
      'account_id': this.accountId,
      'img_src': this.image,
      'researchers': this.researchIds,
      'projectStatus': 'Creating',
      'syncType': this.productSyncType,
      'orgId': this.orgId,
      'projectCostControl': this.projectCostControl,
      'projectCopies': this.projectCopies,
      'createProjectStorage' : this.createProjectStorage,
      'project_type' : this.selectedProjectType,
      'isEgressStoreEnabled' : this.isEgressStoreEnabled
    };
    if(this.showLinkedExternalGroup){
      formObj.linkedActiveDirectoryGroup = this.linkedActiveDirectoryGroup;
    }
    if(this.productSyncType.includes('ownCatalogWithTag') && this.productCatalogTag != undefined){
      formObj['productCatalogTag'] = this.productCatalogTag;
    }
    if(!this.isCostControlEnabled){
      formObj.projectCostControl = { isCostControlEnabled : false }
    }
    //remove the session data related to project form.
    sessionStorage.removeItem('projectName');
    sessionStorage.removeItem('projectDesc');
    sessionStorage.removeItem('projectBudget');
    // If we receive multiple project creation request we are waiting for 5 sec and then navigating to principal screen to avoid loader issue
    const navigateToPrincipal = setTimeout(() => {
        this.route.navigate(['/principal']);
        this.toastr.success(``, `Project is being created. Please wait for some time.`, this.globals.tosterOverride);
    },
      10000);
    this.serviceApi.postProject(formObj).subscribe(
      (res:any) => {
        //this.showProjectSpinner = false;
        if (res.status === 'Success') {
          //if project creation is success, remove the session data related to project form.
          sessionStorage.removeItem('projectName');
          sessionStorage.removeItem('projectDesc');
          sessionStorage.removeItem('projectBudget');
          this.route.navigate(['/principal']);
          ($event.target as HTMLButtonElement).disabled = false;
          this.toastr.success(``, `Project creation initiated: `+res.message, this.globals.tosterOverride);
        } else if (res.status === 'Failed') {
          this.toastr.error('', res.message , { tapToDismiss: false, timeOut: 0, extendedTimeOut: 0, closeButton : true });
        } else {
          this.toastr.error('', res.message , { tapToDismiss: false, timeOut: 0, extendedTimeOut: 0, closeButton : true });
        }
      },
      error => {
        clearTimeout(navigateToPrincipal);
        this.showProjectSpinner = false;
        this.clicked = false;
        // ($event.target as HTMLButtonElement).disabled = false;
        try{
          let errBody = error.error;
        if(error && error.status === 401){
          this.toastr.error(errBody.err, '', this.globals.tosterOverride);
          this.route.navigate(['login']);
        }else{
          this.toastr.error('', errBody.message , { tapToDismiss: false, timeOut: 0, extendedTimeOut: 0, closeButton : true });
      }
     }catch(e){
          //this.toastr.error(``, 'Service seems to be down. Please try after sometime.' , { tapToDismiss: false, timeOut: 0, extendedTimeOut: 0, closeButton : true });
        }
      });
  }

  selectAccountIds(accountId, event) {

    console.log(accountId)
    if (event.target.checked) {
      this.gotAccount = true;
      this.IscurrentVendorAws = accountId.vendor.toLowerCase() === 'aws';
      console.log(this.IscurrentVendorAws)

      this.accountId = accountId.value.toString();
      this.selectedSettingDetails = accountId
      this.selectedSettingType = accountId.type;
      if(this.selectedSettingType == 'Secure Research Environment') {
        this.selectedProjectType = 'secure';
        this.projectCopies = 1;
        this.productSyncType = [];
        // For enterprise deployment we are hiding the project copies
        if(!this.showLinkedExternalGroup) {
          (document.getElementById("projectCopiesId") as any).disabled = true;
          const dl = document.getElementById("projectCopiesId");
          dl.style.cursor = 'not-allowed';
        }
        this.image = '../assets/images/secureResearch.png';
        if(accountId.isEgressStoreEnabled === true) {
          this.isEgressStoreEnabled = true;
        } else {
          this.isEgressStoreEnabled = false;
        }
      } else {
        this.selectedSettingType = 'Regular';
        this.selectedProjectType = 'regular';
        // For enterprise deployment we are hiding the project copies
        if(!this.showLinkedExternalGroup) {
          (document.getElementById("projectCopiesId") as any).disabled = false;
          const dl = document.getElementById("projectCopiesId");
          dl.style.cursor = 'text';
        }
        this.isEgressStoreEnabled = false;
        this.image = '../assets/images/rocket.png';
      }
      this.fAccountIdList.forEach(function (acc, j){
        if(accountId.value == acc.value){
          acc.checked = true
        }else{
          acc.checked = false;
        }
      })
    }else{
      this.gotAccount = false;
      this.selectedSettingType = 'Regular';
      // For enterprise deployment we are hiding the project copies
      if(!this.showLinkedExternalGroup) {
        (document.getElementById("projectCopiesId") as any).disabled = false;
        const dl = document.getElementById("projectCopiesId");
        dl.style.cursor = 'text';
      }
      this.isEgressStoreEnabled = false;
      this.image = '../assets/images/rocket.png';
    }
  }

  selectProjectType(project, event) {
    this.serviceApi
      .getProjectTypeData(project, "project_type")
      .subscribe((res: any) => {
        this.projectTypedescription = res.value;
        this.readMoreLink = res.readMoreLink;
        if (event.target.checked) {
          if (project == "Secure Research") {
            this.selectedProjectType = "secure";
            this.projectCopies = 1;
            this.productSyncType = [];
            this.image = "../assets/images/secureResearch.png";
            if (this.selectedSettingDetails.isEgressStoreEnabled === true) {
              this.isEgressStoreEnabled = true;
            } else {
              this.isEgressStoreEnabled = false;
            }
          } else if (project == "Data Library") {
            this.selectedProjectType = "DataLibrary";
            this.projectCopies = 1;
            this.productSyncType = [];
            this.isEgressStoreEnabled = false;
            this.image = "../assets/images/dataLibrary.png";
          } else if (project == "Ingress Gateway") {
            this.selectedProjectType = "IngressGateway";
            this.projectCopies = 1;
            this.image = "../assets/images/dataLibrary.png";
          }
        }
      });
  }

  selectResearcherIds(researcher, event) {
    if (event.target.checked) {
      this.researchIds.push(researcher.value)
    } else {
      this.researchIds = this.researchIds.filter((item) => {
        item !== researcher.value
      })
    }
  }

  selectProductSyncType(syncType, event, i) {
    if (event.target.checked) {
      this.gotProductSyncType = true;
      this.productSyncType.push(syncType.value.toString());
    }else{
      const index = this.productSyncType.indexOf(syncType.value);
      if (index !== -1) {
        this.productSyncType.splice(index, 1);
      }
    }
  }

  selectBYOCSyncType(syncType, event, i) {
    this.byocCatalogs.forEach(function (catalog, j) {
      if (catalog.value == syncType.value) {
        catalog.checked = ! catalog.checked
      }else{
        catalog.checked = false;
      }
    });
    if (event.target.checked) {
      this.gotProductSyncType = true;
      if(this.productSyncType.includes('standard')){
          this.productSyncType = ['standard'];
      }else {
        this.productSyncType.length = 0;
      }
      this.productSyncType.push(syncType.value.toString());
      if(syncType.value === 'ownCatalogWithTag'){
        this.showTagFiled = true;
      }else{
        this.showTagFiled = false;
        this.tagKey = '';
        this.tagValue = '';
        this.productCatalogTag = undefined;
      }
    }else{
      const index = this.productSyncType.indexOf(syncType.value);
      if (index !== -1) {
        this.productSyncType.splice(index, 1);
      }
      this.showTagFiled = false;
      if(this.productSyncType.includes('ownCatalogWithTag')){
        this.showTagFiled = true;
      }else{
        this.showTagFiled = false;
        this.tagKey = '';
        this.tagValue = '';
        this.productCatalogTag = undefined;
      }
    }
  }

  getMaxProjectCopiesLimit() {
    //default value
    let defaultLink = 'https://researchgateway.readthedocs.io/en/latest/';
    this.projectCopiesMaxCount = 10;
    this.serviceApi.getConfigData('projectConfig').subscribe((data:any) => {
      if (data.value) {
        this.projectCopiesMaxCount = data.value.maxProjectCount;
        this.standardCatalogLink = data.value.standardCatalogLink ? data.value.standardCatalogLink : defaultLink;
        this.ownCatalogLink = data.value.ownCatalogLink ? data.value.ownCatalogLink : defaultLink;
        this.ownCatalogWithTagLink = data.value.ownCatalogWithTag ? data.value.ownCatalogWithTag : defaultLink;
        this.projectStorageLink = data.value.projectStorage ? data.value.projectStorage : defaultLink;
        this.addProjectLink = data.value.addProjectLink ? data.value.addProjectLink : defaultLink;
      }
    },error=>{
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.route.navigate(['login']);
       }else if(error && error.status === 403){
          const err = error.error;
          this.toastr.error(err.error, 'Error', this.globals.tosterOverride);
          this.route.navigate(['pageNotFound']);
      }
    })
  }

  submitTag(){
    if (!this.tagKey.trim() || !this.tagValue.trim()) {
      return;
    }
    this.productCatalogTag = {
      'key': this.tagKey,
      'value': this.tagValue
    }
    this.showTagFiled = false;
  }

  removeTag(){
    this.tagKey = '';
    this.tagValue = '';
    this.productCatalogTag = undefined;
    this.showTagFiled = true;
  }

  selectCostControl(event){
    //Check whether cost control feature is enabled
    if(event.target.checked){
      this.isCostControlEnabled = true;
    }else{
      this.isCostControlEnabled = false;
      this.dialog.showCustomDialog({
          component: CostControlComponent,
          providers: [{ provide: 'data', useValue: { costControlValue: this.isCostControlEnabled}}],
          isModal: true,
          styles: {
            "height":"auto",
            "width":"40%",
            "border": "1px solid #FFFFFF33",
            "border-radius": "10px",
            "background-color": "#07102A",
            "padding": "0px"
          },
          clickOutsideToClose: true,
          enterTransitionDuration: 400,
          leaveTransitionDuration: 400,
        });
      }
  }

  costControlReadMore(){
    if(this.costControlLink){
      window.open(this.costControlLink, '_blank');
    }
  }

  getProjectCostControlLimit() {
    this.serviceApi.getConfigData('projectCostControl').subscribe((data:any) => {
      if (data.value) {
        this.projectCostControl = data.value;
        this.projectPause = data.value.projectPauseThresholdPercentage || 75;
        this.projectStop = data.value.projectStopThresholdPercentage || 90;
        this.costControlLink = data.value.costControlReadMoreLink;
      }
    }, error=>{
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.route.navigate(['login']);
       }else {
        const err = error.error;
        this.toastr.error(err.error, 'Error', this.globals.tosterOverride);
       }
    })
  }

  highlightHelpText(projectData, fieldValue){
    this.filedType = fieldValue;
    let dataLeng;
    if(projectData !=null || projectData!=undefined){
      dataLeng = projectData.length;
      this.defaultClass = 'listValue';
    }
    if(dataLeng == 0){
      this.defaultClass = 'listValue';
    }
  }


  changeCheckbox(tags, i) {
    if (tags) {
      this.catalogs[i].checked = !this.catalogs[i].checked;
    }
  }

  accordianCtrlNode(j) {
    this.curx = 999;
    if (this.cur == j)
      return this.cur = 999;
    else return this.cur = j;
  }

  accordianCtrlNodeX(j) {
    this.cur = 999;
    if (this.curx == j)
      return this.curx = 999;
    else return this.curx = j;
  }

  getAllHelptextdata(key, configType){
    this.serviceApi.getAddAccountHelpText(key, 'create_project_helpText').subscribe(res => {
      this.helpTextData = res[0].helpText;

    }, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.route.navigate(['login']);
       }
})
  }

  getSectionWiseHelp(key){
    this.getAllHelptextdata('all', 'create_project_helpText')
    this.serviceApi.getAddAccountHelpText(key, 'create_project_helpText').subscribe(res => {
      this.sectionWiseHelpdata = res[0].helpText;
      const idToRemove = res[0].helpText[0].key;
      this.helpTextData = this.helpTextData.filter((item) => item.key !== idToRemove);

    }, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.route.navigate(['login']);
       }
})
  }

  onFocus(keyValue){
    this.cur = 999;
    this.curx = 0;
    this.getSectionWiseHelp(keyValue)
  }

  changeTab(){
    this.addProjectTabs.tabs[1].active = true;
  }

  addAccounts(vendor: string){
    sessionStorage.setItem('projectName', this.projectName);
    sessionStorage.setItem('projectDesc', this.projectDescription);
    sessionStorage.setItem('projectBudget', this.budgetAvailable);
    sessionStorage.setItem('accountType','project');
    this.route.navigate(['/addAccount'], { queryParams: { vendor: vendor } });
  }


  addUser(){
    sessionStorage.setItem('projectName', this.projectName);
    sessionStorage.setItem('projectDesc', this.projectDescription);
    sessionStorage.setItem('projectBudget', this.budgetAvailable);
    this.dashboardService.getDialogBox().subscribe((data:any) => {
      if(data.status === 200){
    this.dialog.showCustomDialog({
      component: AddUserComponent,
      providers: [{ provide: 'data', useValue: {action: 'addUserFromProjectPage', orgId: this.orgId} }],
      isModal: true,
      styles: {
        "height": "auto",
        "width":"400px",
        "border": "1px solid #FFFFFF33",
        "border-radius": "10px",
        "background-color": "#07102A",
        "padding": "0px"
      },
      clickOutsideToClose: true,
      enterTransitionDuration: 400,
      leaveTransitionDuration: 400,
    })}}, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.route.navigate(['login']);
       }
    })
  }

  getResearchers() {
    this.loadForm = true;
    this.gotResList = false;
    try{
      this.serviceApi.getResearcherList().subscribe(
        (res:any) => {
          this.gotResList = true;
          this.researcherIdList = res.map(obj => {
            const fObj = {
              'value': obj.username,
              'viewValue': `${obj.fullName} (${obj.email})`,
              'email': obj.email
            };
            return fObj;
          });
        }, error =>{
          this.gotResList = true;
          this.loadForm = false;
          this.accountListFound = false;
          this.fAccountIdList = [];
        });
    }catch(error){
    }

  }

  getOrgSpecificCatalogs() {
    this.showSpinner = true;
    this.serviceApi.getCatalogItems(this.orgId).subscribe(res => {
      if (res) {
        this.showSpinner = false;
        this.availableProductsToShow = res;
        this.catalogCount = 0;
        this.availableProductsToShow.forEach(item => {
          if (item.isSelectedOUAssigned) {
            item.selected = true;
            item.isDisabled = true;
            this.catalogCount++;
          } else {
            item.selected = false;
            item.isDisabled = false;
          }

        });
      }
    }, error => {
      this.showSpinner = false;
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.route.navigate(['login']);
       }else if(error && error.status === 403){
          const err = error.error;
          this.toastr.error(err.error, 'Error', this.globals.tosterOverride);
          this.route.navigate(['pageNotFound']);
       }else {
        let err = error.error;
      //this.toastr.error(`${this.bucketName} - `+err.error, 'Error', this.globals.tosterOverride)
       }
    });
  }

  readMoreForAddProjectType() {
    window.open(this.readMoreLink, "_blank");
  }

  readMoreForAddProduct(syncType) {
    switch(syncType) {
      case 'standard':
        window.open(this.standardCatalogLink, "_blank");
        break;
      case 'ownCatalog':
        window.open(this.ownCatalogLink, "_blank");
        break;
      case 'ownCatalogWithTag':
        window.open(this.ownCatalogWithTagLink, "_blank");
        break;
      case 'projectStorage':
        window.open(this.projectStorageLink, "_blank");
          break;
      default:
        window.open('https://researchgateway.readthedocs.io/en/latest/', "_blank");
    }
  }

  checkCreateProjectStorage(event) {
    if (event.target.checked) {
      this.createProjectStorage = true;
    }else{
      this.createProjectStorage = false;
    }
  }

  checkProjectCopies(projectCopies){
    let projCopies = parseInt(projectCopies);
    if(projCopies >= 1 && projCopies <= this.projectCopiesMaxCount){
      this.clicked = false;
      const el = document.getElementById("projectCopiesId");
      el.style.borderLeft = '5px solid #42A948';
    }else{
      this.clicked = true;
      const el = document.getElementById("projectCopiesId");
      el.style.borderLeft = '5px solid #a94442';
    }
  }

}
