<div>
  <mdl-layout #mainnav="mdlLayout">
    <mdl-layout-header class="nav">
      <app-header></app-header>
    </mdl-layout-header>
    <mdl-layout-drawer class="drawer-layout" *ngIf="authGuard.isloggedin() && userType && showMenu() && showMenus">
        <mdl-layout-title class="drawer-container"><mdl-icon class="material-icons menu-icon" (click)="mainnav.closeDrawer();"></mdl-icon></mdl-layout-title>
        <div class="nav-title font-class" >Navigate to</div>
        <nav class="mdl-navigation" style="max-width: 300px !important">
          <div *ngIf="userType === 'admin'">
            <button mdl-button class="nav-btn font-class" [routerLinkActive]="'is-active'" (click)='navigateToResearch()'
              (click)="mainnav.closeDrawer()">My Organizations</button>
            <button mdl-button class="nav-btn font-class" [routerLinkActive]="'is-active'" (click)='navigateToBudget()'
              (click)="mainnav.closeDrawer()">Budgets</button>
            <button mdl-button class="nav-btn font-class" [routerLinkActive]="'is-active'" (click)='navigateToUser()'
              (click)="mainnav.closeDrawer()">Users</button>
            <button mdl-button class="nav-btn font-class" [routerLinkActive]="'is-active'" (click)='navigateToAuditTrail()'
                    (click)="mainnav.closeDrawer()">Audit Trail</button>
            <button mdl-button class="nav-btn font-class" [routerLinkActive]="'is-active'" (click)='navigateToCatalog()'
                    (click)="mainnav.closeDrawer()">Catalog</button>
            <button mdl-button class="nav-btn font-class" [routerLinkActive]="'is-active'" (click)='navigateToBillingAccount()'
              (click)="mainnav.closeDrawer()">Billing  Accounts</button>            
          </div>
          <div *ngIf="userType === 'principal'">
            <button mdl-button class="nav-btn font-class" [routerLinkActive]="'is-active'" (click)='navigateToResearch()'
              (click)="mainnav.closeDrawer()">My Projects</button>
            <button mdl-button class="nav-btn font-class" [routerLinkActive]="'is-active'" (click)='navigateToBudget()'
              (click)="mainnav.closeDrawer()">Budgets</button>
              <button mdl-button class="nav-btn font-class" [routerLinkActive]="'is-active'" (click)='navigateToCatalog()'
              (click)="mainnav.closeDrawer()">Catalog</button>
              <button mdl-button class="nav-btn font-class" [routerLinkActive]="'is-active'" (click)='navigateToUser()'
              (click)="mainnav.closeDrawer()">Users</button>
            <button mdl-button class="nav-btn font-class" *ngIf="showResearcherView"  [routerLinkActive]="'is-active'" (click)='navigateToStudies()'
                    (click)="mainnav.closeDrawer()">Studies</button>
            <button mdl-button class="nav-btn font-class" *ngIf="showResearcherView"  [routerLinkActive]="'is-active'" (click)='navigateToKeypair()'
                    (click)="mainnav.closeDrawer()">Key Pairs</button>
            <button mdl-button class="nav-btn font-class" [routerLinkActive]="'is-active'" (click)='navigateToAuditTrail()'
                    (click)="mainnav.closeDrawer()">Audit Trail</button>
            <button mdl-button class="nav-btn font-class" [routerLinkActive]="'is-active'" (click)='navigateToBillingAccount()'
              (click)="mainnav.closeDrawer()">Billing  Accounts</button>    
          </div>
          <div *ngIf="userType === 'researcher'">
            <button mdl-button class="nav-btn font-class" [routerLinkActive]="'is-active'" (click)='navigateToResearch()'
                    (click)="mainnav.closeDrawer()">My Projects</button>
            <button mdl-button class="nav-btn font-class" [routerLinkActive]="'is-active'" (click)='navigateToStudies()'
                    (click)="mainnav.closeDrawer()">Studies</button>
            <button mdl-button class="nav-btn font-class" [routerLinkActive]="'is-active'" (click)='navigateToKeypair()'
                    (click)="mainnav.closeDrawer()">Key Pairs</button>
            <button mdl-button class="nav-btn font-class" [routerLinkActive]="'is-active'" (click)='navigateToBudget()'
                    (click)="mainnav.closeDrawer()">Budgets</button>
          </div>
        </nav>
    </mdl-layout-drawer>
    <mdl-layout-content class="mdl-layout__content page-content">
      <div>
       <router-outlet></router-outlet>
       <div style="clear: both;margin-bottom:20px;"></div>
      </div>
      <app-footer></app-footer>
    </mdl-layout-content>
  </mdl-layout>
</div>
