import { Component, OnInit, Inject} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import { MdlDialogReference } from '@angular-mdl/core';
import { MdlDialogService } from '@angular-mdl/core';
import { DataService } from '../../_services/data.service';

@Component({
  selector: 'app-success-message',
  templateUrl: './success-message.component.html',
  styleUrls: ['./success-message.component.css']
})
export class SuccessMessageComponent implements OnInit {

  projectName;
  provisionedProductName;
  provisionedProductId;
  projectStatus;
  prodDetails;
  action;
  message;

  constructor(private router: Router,
    @Inject('data') public data: any,
    public dialogRef: MdlDialogReference,
    private dialog: MdlDialogService,
    private dataService: DataService) { }

  ngOnInit() {

    if(this.data.hasOwnProperty('prodDetails')){
      this.prodDetails = this.data.prodDetails;
      this.projectName = this.prodDetails.projectName;
      this.provisionedProductName = this.prodDetails.productName;
      this.provisionedProductId = this.prodDetails.ppId;
      this.projectStatus = this.prodDetails.projectStatus;
    }

    if(this.data.hasOwnProperty('action')) {
      this.action = this.data.action;
    }
    if(this.action === 'studyLink') {
      this.message = "Your study has been linked ";
    }else {
      this.message = "Your S3 bucket has been linked ";
    }
  }

submit(){
  if(this.action === 'studyLink'){
    this.dialogRef.hide();
    this.dataService.updatePage.next(true);
  }else {
    this.dialogRef.hide();
    this.productDetailsPage();
  }
}

productDetailsPage(){
  this.router.navigate(['/product/' + this.projectName + '/' +
  this.provisionedProductName + '/' + 'linkResource'+ '/' +'Success' , { ppId: this.provisionedProductId }],{ queryParams: { projectStatus: this.projectStatus}});
}

}
