<div class="container" id="scrollId">
  <div class="fixed-header">
      <nav aria-label="breadcrumb" class="breadcrumb-a">
          <ol class="breadcrumb bc-custom">
            <li class="breadcrumb-item">
              <a class="bc-item" routerLink='/principal'> My Projects </a>
            </li>
            <li class="breadcrumb-item active bc-item-active" aria-current="page">Create Project</li>
          </ol>
        </nav>
  <div class="product-name-layout">
    <h3 class="product-name text-white font-class">Create Project
      <!-- <button class="launch-btn-cancel text-white font-class" (click)="startTour()"   style="margin-left: 25px;width: 145px;outline: none " name="button" type="button">Start Tour</button> -->
    </h3>
    <div class="launch-now-wrapper">
      <!-- <button class="launch-btn-cancel text-white font-class" (click)="startTour()"   name="button" type="button">Cancel</button> -->
      <button id="submitProject" class="launch-btn text-white font-class" [disabled]="!f.valid || ! gotProductSyncType || ! gotAccount || clicked" name="button" (click)="createProject($event); clicked=true" (keypress)="createProject($event); clicked=true" type="submit">Create Project
          <i *ngIf="showProjectSpinner" class="fa fa-refresh fa-spin" style="margin-left: 10px;"></i>
      </button>
    </div>
  </div>
</div>
<form #f="ngForm" novalidate (ngSubmit)="createProject($event);f.valid" style="margin-top: 115px;">
  <div class="addProjectheader">
      <div class="addNewProject">
        <div style="margin-top: 20px;margin-bottom: 20px; border-bottom: 1px solid #465374;">
            <h3 class="product-desc font-class">Project Details</h3>
            <h3 class="prod-c">Add the Name and Description of the Project that you wish to create</h3>
        </div>
        <div style="display: flow-root !important">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="padding: 0px;margin-right: 15px">
            <h3 class="prov-product-field text-white font-class" style="margin-bottom: 10px;">Project Name <span class="order-st">*</span></h3>
            <input required [(ngModel)]="projectName" pattern="^\S.*$" aria-label="projectName" (focus)="onFocus('project_name')" (keyup)="highlightHelpText(projectName, 'projectName')" class="inp-txt user-input" type="text" minlength="1" maxlength="32" name="name" placeholder="Please enter your project name - (between 1 to 32 characters) (required)">
        </div>
        <div *ngIf="showLinkedExternalGroup" class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="padding: 0px;margin-right: 15px; margin-top: 10px">
          <h3 class="prov-product-field text-white font-class" style="margin-bottom: 10px;">Linked Active Directory Group <span class="order-st">*</span></h3>
          <input required [(ngModel)]="linkedActiveDirectoryGroup" (focus)="onFocus('linkedActiveDirectoryGroup')" (keyup)="highlightHelpText(linkedActiveDirectoryGroup, 'linkedActiveDirectoryGroup')" class="inp-txt user-input" type="text" minlength="1" maxlength="32" name="linkedActiveDirectoryGroup" placeholder="Please enter Linked Active Directory Group - (required)">
      </div>
        <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="padding: 0px;margin-top: 10px">
          <h3 class="prov-product-field text-white font-class" style="margin-bottom: 10px;">Project Description <span class="order-st">*</span></h3>
          <textarea style="padding-left: 20px;padding-top: 10px;" required pattern="^\S.*$" (focus)="onFocus('project_desc')"
            [(ngModel)]="projectDescription" class="inp-txt user-input"  aria-label="projectDescription" type="text" name="projectDescription" placeholder="Please enter your project description - (required)" maxlength="256" rows="4"> </textarea>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="padding: 0px;margin-right: 15px; margin-top: 10px;">
          <h3 class="prov-product-field text-white font-class" style="margin-bottom: 10px;">Budget Available <span class="order-st">*</span></h3>
          <input class="inp-txt user-input" type="number" min="1" value="5" (focus)="onFocus('project_budget')"
            placeholder="Please enter your direct cost budget - (required)" aria-label="availableBudget" [(ngModel)]="budgetAvailable" #rCEvery="ngModel" required pattern="^([0-9]*[1-9][0-9]*)$"
            name="budgetAvailable">
        </div>
        <div *ngIf="!showLinkedExternalGroup" class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="padding: 0px;margin-right: 15px; margin-top: 10px;">
          <h3 class="prov-product-field text-white font-class" style="margin-bottom: 10px;">Project Copies <span class="order-st">*</span></h3>
          <input id="projectCopiesId" class="inp-txt user-input" type="number" min="1" max="{{projectCopiesMaxCount}}" value="5" (focus)="onFocus('project_copies')"
            placeholder="Please enter number of projects you want to create - (between 1 and {{ projectCopiesMaxCount }}) (required)" [(ngModel)]="projectCopies" #rCEvery="ngModel" required pattern="^([0-9]*[1-9][0-9]*)$"
            name="projectCopies" (keyup)="checkProjectCopies($event.target.value)" aria-label="projectCopies" (keydown.space)="$event.preventDefault()">
        </div>
      </div>
      <div style="margin-top: 10px;margin-bottom: 20px; border-bottom: 1px solid #465374;">
        <h3 class="product-desc font-class">Account Details <span class="order-st">*</span></h3>
        <h3 class="prod-c">Add an AWS/Azure account which you want to use, to provision products in this project.</h3>
      </div>
        <div class="add-new-btn-container-cloud" tabindex="0" style="margin-bottom: 20px;">
          <button type="button"
                  class="btn add-btn-cloud "
                  title="Add Settings" data-toggle="dropdown">Add Account
            <i class="fa fa-angle-down" title="Actions" aria-hidden="true"
               style="color: #000;width: 12px;font-size: 15px; margin: 3px;"></i>
          </button>
          <ul class="dropdown-menu dropdown-content" style="cursor: pointer;position: absolute;
                                will-change: transform;top: 0px;left: 0px;
                                transform: translate3d(-58px, 17px, 0px); position: relative;">
            <li><a class="listItems" (click)="addAccounts('AWS')"> AWS
            </a>
            </li>
            <li><a class="listItems" (click)="addAccounts('AZURE')"> Azure
            </a></li>
          </ul>

        </div>
      <div *ngIf="loader" style="text-align: center; ">
        <img src="/assets/images/barspreloaders.svg" style="height: 60px">
      </div>
      <div *ngIf="!loader && fAccountIdList.length == 0" class="addAccount text-white font-class">
        <h3 class="noData">
            No Accounts added
        </h3>
      </div>
      <div *ngIf="!loader && fAccountIdList.length > 0" class="form-control account-selection-content" style="margin-top: 15px;margin-bottom: 20px" tabindex="0">
        <span class="selection-header">Select an account from the list</span>
        <div>
        <div class="selection" *ngFor="let acc of fAccountIdList; let i = index">
            <input [checked]="acc.checked" type="checkbox" [id]="'accountCheckbox' + i" name="accountCheckbox" required
                   (focus)="onFocus('add_accounts')" (change)="selectAccountIds(acc, $event); onFocus('add_accounts')">
            <label [for]="'accountCheckbox' + i" class="selection-label">{{acc.viewValue}} ( {{acc.vendor}} )</label>
            <span class="selection-label" style="margin-left: 3px;" *ngIf="acc.type == 'Secure Research Environment'">
              ({{acc.type}})
            </span>
          </div>
        </div>
      </div>
      <div *ngIf="!loader && selectedSettingType == 'Secure Research Environment'" style="margin-top: 10px;margin-bottom: 20px; border-bottom: 1px solid #465374;">
          <h3 class="product-desc font-class">Project Type</h3>
          <h3 class="prod-c">Select the project type</h3>
      </div>
      <div *ngIf="!loader && selectedSettingType == 'Secure Research Environment'" class="form-control account-selection-content" style="margin-top: 15px;margin-bottom: 20px;min-height: 150px;">
        <span class="selection-header">Select the project type</span>
        <div>
          <div class="selection-ProjectType" *ngFor="let project of projectTypeArray; let i=index" >
            <input type="radio" [id]="project.type" name="project_type" [value]="project.type" [checked]="project.checked" (change)="selectProjectType(project.type, $event);" >
            <label style="margin-bottom: 10px;" class="selection-label" [for]="project.type">{{project.type}}</label>
          </div>
        </div>
        <span *ngIf="!loader " class="description">{{ projectTypedescription }}
          <span (click)="readMoreForAddProjectType()" (keypress)="readMoreForAddProjectType()" tabindex="0" class="read-more">Read more</span>
        </span>
      </div>
    <div *ngIf="showAddUser">
      <div style="margin-top: 10px;margin-bottom: 20px; border-bottom: 1px solid #465374;">
          <h3 class="product-desc font-class">Add Users</h3>
          <h3 class="prod-c">Invite collaborators to this project - (optional).</h3>
      </div>
      <div class="add-new-btn-container" style="width: 150px !important;"  (click)="addUser()" (keypress)="addUser()" tabindex="0">
        <div class="add-new-btn-select-wrapper">
          <div class="add-new-btn-select">
              <div class="add-new-btn-trigger" ><span class="add-new-txt" style=" margin-right: 10px;">Add Users</span>
<!--                <div class="arrow"></div>-->
              </div>
              <div class="add-new-btn-dropdown-options">
                <span class="filter-dropdown-option" data-value="add">Create New User</span>
              </div>
          </div>
        </div>
      </div>
      <div *ngIf="!gotResList" style="text-align: center; ">
        <img src="/assets/images/barspreloaders.svg" style="height: 60px">
      </div>
      <div *ngIf="gotResList && researcherIdList.length == 0" class="addAccount text-white font-class" name="button" type="button">
        <h3 class="noData">
          No Users added
        </h3>
      </div>
      <div *ngIf="gotResList && researcherIdList.length > 0" class="form-control account-selection-content" style="margin-top: 15px;margin-bottom: 20px" tabindex="0">
        <span class="selection-header">Select users from the list</span>
        <div>
          <div class="selection" *ngFor="let acc of researcherIdList let i = index" >
            <input type="checkbox" [id]="'userCheckbox' + i" name="userCheckbox" (change)="onFocus('add_users');selectResearcherIds(acc, $event)">
            <label [for]="'userCheckbox' + i" class="selection-label">{{acc.viewValue}}</label>
          </div>
        </div>
      </div>
    </div>
      <div *ngIf="!loader && selectedSettingType == 'Regular'" style="margin-top: 10px;margin-bottom: 10px; border-bottom: 1px solid #465374;">
        <h3 class="product-desc font-class">Add Products</h3>
        <h3 class="prod-c">Create products in the service catalog from our standard catalog or bring your own service catalog portfolio.</h3>
      </div>

      <div *ngIf="!loader && selectedSettingType == 'Regular'" class="form-control account-selection-content" style="min-height: 100px;">
        <span class="selection-header">Select Create standard catalog type from the list</span>
        <div>
          <div class="selection" *ngFor="let catalog of catalogs; let i = index" >
            <input class="" [checked]="catalog.checked" required type="checkbox" [id]="catalog.value" name="catalog.value" (change)="onFocus('add_products');selectProductSyncType(catalog, $event, i)">
            <label [for]="catalog.value" class="selection-label" *ngIf="catalog.value == 'standard'">{{catalog.viewValue}} - {{catalogCount}} products are available. <span (click)="readMoreForAddProduct('standard')" (keypress)="readMoreForAddProduct('standard')" tabindex="0" class="read-more">Read more</span> </label>
          </div>
        </div>
      </div>
      <div *ngIf="!loader && selectedSettingType == 'Regular' && IscurrentVendorAws" class="form-control account-selection-content" style="min-height: 165px;">
        <span class="selection-header">Select Bring your own catalog type from the list</span>
        <div>
          <div class="selection" *ngFor="let cat of byocCatalogs; let i = index" >
            <input class="" [checked]="cat.checked" required type="checkbox" [id]="cat.value" name="cat.value" (change)="onFocus(cat.value);selectBYOCSyncType(cat, $event, i)">
            <label [for]="cat.value" class="selection-label" *ngIf="cat.value == 'ownCatalog'">{{cat.viewValue}} (This option will sync all the products which have the required launch permission). <span (click)="readMoreForAddProduct('ownCatalog')" (keypress)="readMoreForAddProduct('ownCatalog')" tabindex="0" class="read-more">Read more</span></label>
            <label [for]="cat.value" class="selection-label" *ngIf="cat.value == 'ownCatalogWithTag'">{{cat.viewValue}} (This option will sync only products which have the required tag). <span (click)="readMoreForAddProduct('ownCatalogWithTag')" (keypress)="readMoreForAddProduct('ownCatalogWithTag')" tabindex="0" class="read-more">Read more</span></label>
            <form class="form-inline" *ngIf="cat.value == 'ownCatalogWithTag' && showTagFiled" style="margin-top: 20px;">
              <label class="selection-label" for="key">Key:</label>
              <input type="text" id="key" placeholder="Enter Key" name="key" [(ngModel)]="tagKey">
              <label class="selection-label" for="value">Value:</label>
              <input type="text" id="value" placeholder="Enter Value" name="value" [(ngModel)]="tagValue">
              <button type="submit" tabindex="0" (keypress)="submitTag()" (click)="submitTag()">Submit</button>
            </form>
          </div>
          <div  *ngIf="IscurrentVendorAws" class="col-lg-12 col-md-12" style="margin-top: 20px;">
            <div  *ngIf="productCatalogTag" class="tag-t-s">
              <span title="{{productCatalogTag.value}}" style=" font-size: 12px; ">{{productCatalogTag.key}}</span>
              <i class="fa fa-times-circle tag-span-i" tabindex="0" (keypress)="removeTag()" (click)="removeTag()"></i>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!loader && selectedSettingType == 'Regular'" style="margin-top: 20px;margin-bottom: 20px; border-bottom: 1px solid #465374;">
        <h3 class="product-desc font-class">Storage Configuration<span class="order-st"></span></h3>
      </div>
      <div *ngIf="!loader && selectedSettingType == 'Regular'" class="form-control account-selection-content"
        style="margin-bottom: 20px;min-height: 130px !important;">
        <div class="selection">
          <input class="" [checked]="createProjectStorage" type="checkbox" id="createProjectStorage"
            name="createProjectStorageId" (change)="checkCreateProjectStorage($event);">
          <label class="selection-label" for="createProjectStorage"
            style="display: block; margin-bottom: 5px;font-size: 14px !important; margin-top:-21px;">
            Use Project Storage</label>
          <span style="font-family: 'Nunito Sans', sans-serif;">
            Research Gateway will setup a shared S3 bucket (project storage) where the team members can store data.
            This shared storage will be mounted into all supported workspaces. Storage costs will be accounted at the project level.
             <span (click)="readMoreForAddProduct('projectStorage')" tabindex="0" (keypress)="readMoreForAddProduct('projectStorage')" class="read-more">Read more</span>
          </span>
        </div>
      </div>
      <div *ngIf="IscurrentVendorAws" style="margin-top: 20px;margin-bottom: 20px; border-bottom: 1px solid #465374;">
        <h3 class="product-desc font-class">Cost Control<span class="order-st"></span></h3>
      </div>
      <div *ngIf="IscurrentVendorAws" class="form-control account-selection-content"
        style="min-height: 130px !important;margin-bottom: 515px;">
        <div class="selection">
          <input class="form-check-input" [checked]="isCostControlEnabled" type="checkbox" id="isCostControlEnabled"
            name="isCostControlEnabled" (change)="selectCostControl($event);">
          <label class="selection-label" for="isCostControlEnabled"
            style="display: block; margin-bottom: 5px;font-size: 14px !important; margin-top: -18px;">
            Automatically respond to budget alerts</label>
          <span style="font-family: 'Nunito Sans', sans-serif;">
            Research Gateway can automatically create budget consumption alerts and take actions like pausing the project (at {{projectPause}}%) or stopping the project (at
            {{projectStop}}%). Check this box to enable these actions. <span (click)="costControlReadMore()"  tabindex="0" (keypress)="costControlReadMore()" class="read-more">Read more</span>
          </span>
        </div>
      </div>
    </div>
    <div class="helpSection">
      <h3 style="color: #3FCB57; font: normal normal 800 18px/32px Nunito Sans;">Help</h3>
      <!-- <ul style="padding: 0px;">
          <li [ngClass]="filedType == 'projectName' ? 'highlightText-1' : 'listValue'">Project Name : Enter a unique and descriptive project name.
            <br>
            <h3 class="listValue" style="color: #3FCB57 !important; margin-top: 10px;"> Example : Medical Research Project</h3>
          </li>
          <li [ngClass]="filedType == 'projectDescription' ? 'highlightText-1' : 'listValue'" >Project Description: Add your project description
              <h3 class="listValue" style="color: #3FCB57 !important; margin-top: 10px;">  Example : This project uses remotely sensed data products and measurements for studying processes.</h3>
            </li>
          <li [ngClass]="filedType == 'budgetAvailable' ? 'highlightText-1' : 'listValue'" class="listValue" style="margin-top: 20px;">Budget Available: Budget should be greater than 0
            <h3 class="listValue" style="color: #3FCB57 !important; margin-top: 10px;">  Example : 1000 </h3>
          </li>
          <li [ngClass]="filedType == 'account' ? 'highlightText-1' : 'listValue'">Account Details: Select the account from the list
            <h3 class="listValue" style="color: #3FCB57 !important; margin-top: 10px;">  Example : Choose any one of the account id from the list. If list is empty, you can add account by clicking
              add account button</h3>
          </li>
          <li class="listValue">Add Users: Select researchers from the list
            <h3 class="listValue" style="color: #3FCB57 !important; margin-top: 10px;">  Example : Choose any one of the user from the list. If list is empty, you can add new user by clicking add users button</h3>
          </li>
          <li [ngClass]="filedType == 'catalogs' ? 'highlightText-1' : 'listValue'">Add Products : Select catalog type from the list
              <h3 class="listValue" style="color: #3FCB57 !important; margin-top: 10px;">  Example : Choose any one of the catalog type from the list.</h3>
          </li>
        </ul> -->

        <div class="panel panel-default" *ngFor="let item of sectionWiseHelpdata; let k = index">
          <div class="panel-heading heading" (click)="accordianCtrlNodeX(k)" (keypress)="accordianCtrlNodeX(k)" tabindex="0">
            <h5 class="panel-title">
              <table id="BotList-node" class="table table-striped" style="width: 100%;margin-bottom: unset">
                <thead>
                  <tr>
                    <th class="accordian-header" style="width:18%;text-align: initial;"
                      [ngStyle]="{'background-color': '#06153E'}">
                      <span style="color: white; font: normal normal normal 13px/18px Nunito Sans;font-weight: 900">{{item.key}}</span></th>
                      <td class="accordian-header" style="width:1%"
                      [ngStyle]="{'background-color': '#06153E' }">
                      <em class='glyphicon' style="font-size: 20px;font-weight: 900;color: white"
                        [class.glyphicon-plus]="k != curx" [class.glyphicon-minus]="k == curx"
                        class="accordion-toggle"></em></td>
                  </tr>
                </thead>
              </table>
            </h5>
          </div>
          <div id="collapse-node" class="panel-collapse" [class.collapse]='k != curx'>
            <table id="topology-info-node" class="table table-striped" style="width: 100%;">
              <tbody class="txt-align">
                <tr>
                  <td style="padding-top:10px;width:20%;background:#030A1E;border: 1px solid #85d4ff;font: normal normal normal 13px/18px Nunito Sans;
                  white-space: pre-wrap !important;
                  color: #FFFFFF !important" [innerHTML]="item.value | linkify">
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>


        <div class="panel panel-default" *ngFor="let item of helpTextData; let j = index">
            <div class="panel-heading heading" (click)="accordianCtrlNode(j)" (keypress)="accordianCtrlNode(j)" tabindex="0">
              <h5 class="panel-title">
                <table id="BotList-node" class="table table-striped" style="width: 100%;margin-bottom: unset">
                  <thead>
                    <tr>
                      <th class="accordian-header" style="width:18%;text-align: initial;"
                        [ngStyle]="{'background-color': '#06153E'}">
                        <span style="color: white; font: normal normal normal 13px/18px Nunito Sans;font-weight: 900">{{item.key}}</span></th>
                        <td class="accordian-header" style="width:1%"
                        [ngStyle]="{'background-color': '#06153E' }">
                        <em class='glyphicon' style="font-size: 20px;font-weight: 900;color: white"
                          [class.glyphicon-plus]="j != cur" [class.glyphicon-minus]="j == cur"
                          class="accordion-toggle"></em></td>
                    </tr>
                  </thead>
                </table>
              </h5>
            </div>
            <div id="collapse-node" class="panel-collapse" [class.collapse]='j != cur'>
              <table id="topology-info-node" class="table table-striped" style="width: 100%;">
                <tbody class="txt-align">
                  <tr>
                    <td style="padding-top:10px;width:20%;background:#030A1E;border: 1px solid #85d4ff;font: normal normal normal 13px/18px Nunito Sans;
                    white-space: pre-wrap !important;
                    color: #FFFFFF !important" [innerHTML]="item.value | linkify">
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

    </div>
  </div>

  <!-- Mobile view -->
<div class="addProjectheader-mob">
    <tabset #addProjectTabs>
        <tab class="tab" heading="Create Project">
            <div class="addNewProject">
                <div style="margin-top: 20px;margin-bottom: 20px; border-bottom: 1px solid #465374;">
                    <h3 class="product-desc font-class">Project Details</h3>
                    <h3 class="prod-c">Add the Name and Description of the Project that you wish to create</h3>
                </div>

              <div style="margin-bottom: 270px;">
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="padding: 0px;margin-right: 15px">
                      <h3 class="prov-product-field text-white font-class" style="margin-bottom: 10px;">Project Name <span class="order-st">*</span>
                        <i class="fa fa-question-circle" aria-hidden="true" style="color: #FFB500;margin-left: 12px;"
                        (click)= "changeTab();onFocus('project_name')"></i>
                      </h3>
                      <input required [(ngModel)]="projectName" aria-label="projectName"  pattern="^\S.*$" (focus)="onFocus('project_name')" (keyup)="highlightHelpText(projectName, 'projectName')" class="inp-txt user-input" type="text" name="name" minlength="1" maxlength="32" placeholder="Enter your project name - (between 1 to 32 characters) (required)">
                    </div>
              <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="padding: 0px;margin-top: 10px">
                        <h3 class="prov-product-field text-white font-class" style="margin-bottom: 10px;">Project Description <span class="order-st">*</span>
                          <i class="fa fa-question-circle" aria-hidden="true" style="color: #FFB500;margin-left: 12px;"
                          (click)= "changeTab();onFocus('project_desc')"></i>
                        </h3>
                        <textarea style="padding-left: 20px;padding-top: 10px;" pattern="^\S.*$" required (focus)="onFocus('project_desc')"
                        [(ngModel)]="projectDescription" aria-label="projectDescription"  class="inp-txt user-input" (keypress)="highlightHelpText(projectDescription, 'projectDescription')" (keyup)="highlightHelpText(projectDescription, 'projectDescription')" type="text" name="projectDescription" placeholder="Enter your project description - (required)" maxlength="256" rows="4"> </textarea>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="padding: 0px;margin-right: 15px; margin-top: 10px;">
                  <h3 class="prov-product-field text-white font-class" style="margin-bottom: 10px;">Budget Available <span class="order-st">*</span>
                    <i class="fa fa-question-circle" aria-hidden="true" style="color: #FFB500;margin-left: 12px;"
                    (click)= "changeTab();onFocus('project_budget')"></i>
                  </h3>
                  <input class="inp-txt user-input" type="number" min="1" value="5" aria-label="availableBudget"  (focus)="onFocus('project_budget')" (keypress)="highlightHelpText(budgetAvailable, 'budgetAvailable')" (keyup)="highlightHelpText(budgetAvailable, 'budgetAvailable')"
                  placeholder="Enter your budget - (required)" [(ngModel)]="budgetAvailable" #rCEvery="ngModel" required pattern="^([0-9]*[1-9][0-9]*)$"
                  name="budgetAvailable">
              </div>
              </div>

              <div style="margin-top: 20px;margin-bottom: 20px;border-bottom: 1px solid #465374;">
                  <h3 class="product-desc font-class">Account Details <span class="order-st">*</span>
                    <i class="fa fa-question-circle" aria-hidden="true" style="color: #FFB500;margin-left: 12px;font-size: 14px;"
                    (click)= "changeTab();onFocus('add_accounts')"></i>
                  </h3>
                  <h3 class="prod-c" style="width: 50% !important">Add an AWS account which you want to use, to provision products in this project.</h3>
              </div>
              <div class="add-new-btn-container">
                  <div class="add-new-btn-select-wrapper">
                    <div class="add-new-btn-select">
                      <div class="dropdown">
                        <button type="button"
                                class="btn btn-lg btn-primary btn-add text-dark font-class mr-2 mb-1"
                                title="Add Settings" data-toggle="dropdown">Add New
                          <i class="fa fa-angle-down" title="Actions" aria-hidden="true"
                             style="color: #000;width: 12px;font-size: 15px; margin: 3px;"></i>
                        </button>
                        <ul class="dropdown-menu dropdown-content" style="cursor: pointer;background: rgb(7, 16, 42);position: absolute;
                                will-change: transform;top: 0px;left: 0px;
                                transform: translate3d(-58px, 17px, 0px); position: relative;">
                          <li><a class="listItems" (click)="addAccounts('AWS')"> AWS
                          </a>
                          </li>
                          <li><a class="listItems" (click)="addAccounts('AZURE')"> Azure
                          </a></li>
                        </ul>

                      </div>
                        <div class="add-new-btn-dropdown-options">
                            <span class="filter-dropdown-option" data-value="add" (click)="addUser()">Add account</span>

                        </div>
                    </div>
                  </div>
                </div>

              <div *ngIf="fAccountIdList.length == 0" class="addAccount text-white font-class" name="button" type="button">No Accounts added</div>

              <div *ngIf="fAccountIdList.length > 0" class="form-control account-selection-content" style="margin-top: 15px;margin-bottom: 15px">
                  <span class="selection-header">Select an account from the list</span>
                  <div>
                    <div class="selection" *ngFor="let acc of fAccountIdList; let i = index" >
                      <input type="checkbox" [id]="acc.value+i" name="acc.value" (focus)="onFocus('add_accounts')" (change)="selectAccountIds(acc, $event); onFocus('add_accounts')">
                      <label [for]="acc.value+i" class="selection-label">{{acc.viewValue}}</label>
                    </div>
                  </div>
                </div>


              <div style="margin-top: 10px;margin-bottom: 20px; border-bottom: 1px solid #465374;">
                  <h3 class="product-desc font-class">Add Users
                    <i class="fa fa-question-circle" aria-hidden="true" style="color: #FFB500;margin-left: 12px;font-size: 14px;"
                    (click)= "changeTab();onFocus('add_users')"></i>
                  </h3>
                  <h3 class="prod-c" style="width: 50% !important">Invite collaborators to this project (optional).</h3>
              </div>

              <div class="add-new-btn-container" style="width: 130px !important">
                  <div class="add-new-btn-select-wrapper">
                    <div class="add-new-btn-select">
                        <div class="add-new-btn-trigger" ><span class="add-new-txt" style=" margin-right: 10px;">Add Users</span>
                            <div class="arrow"></div>
                        </div>
                        <div class="add-new-btn-dropdown-options">
                            <span class="filter-dropdown-option" data-value="add" (click)="addUser()">Create New User</span>

                        </div>
                    </div>
                  </div>
                </div>

              <div *ngIf="researcherIdList.length == 0" class="addAccount text-white font-class" name="button" type="button">No Users added</div>

              <div *ngIf="researcherIdList.length > 0" class="form-control account-selection-content" style="margin-top: 15px;margin-bottom: 15px">
                  <span class="selection-header">Select users from the list</span>
                  <div>
                    <div class="selection" *ngFor="let acc of researcherIdList" >
                      <input type="checkbox" [id]="acc.value" name="acc.value" (focus)="onFocus('add_users')" (change)="onFocus('add_users')">
                      <label [for]="acc.value" class="selection-label">{{acc.viewValue}}</label>
                    </div>
                  </div>
                </div>

                <div *ngIf="IscurrentVendorAws" style="margin-top: 10px;margin-bottom: 10px; border-bottom: 1px solid #465374;">
                    <h3 class="product-desc font-class">Add Products  <span class="order-st">*</span>
                      <i class="fa fa-question-circle" aria-hidden="true" style="color: #FFB500;margin-left: 12px;font-size: 14px;"
                      (click)= "changeTab();onFocus('add_products')"></i>
                    </h3>
                    <h3 class="prod-c">Create products in the service catalog from our standard catalog or bring your own service catalog portfolio.</h3>
                </div>

                  <div class="form-control account-selection-content" style="margin-bottom: 15px">
                  <span class="selection-header">Select catalog type from the list</span>
                  <div>
                    <div class="selection" *ngFor="let acc of catalogs" >
                      <input type="checkbox" [id]="acc.viewValue" required name="acc.viewValue" (focus)="onFocus('add_products')" (change)="onFocus('add_products')">
                      <label [for]="acc.viewValue" class="selection-label">{{acc.viewValue}}</label>
                    </div>
                  </div>
                </div>

                <div style="margin-top: 10px;margin-bottom: 10px; border-bottom: 1px solid #465374;">
                  <h3 class="product-desc font-class">Storage Configuration</h3>
              </div>
              <div class="form-control account-selection-content" style="margin-bottom: 15px">
                <div>
                  <div class="selection" >
                    <input class="" [checked]="createProjectStorage" type="checkbox"  id="createProjectStorageMobileView" name="createProjectStorageId"
                    (change)="checkCreateProjectStorage($event);">
                    <label class="selection-label" for="createProjectStorageMobileView"
                    style="display: block; margin-bottom: 5px;font-size: 14px!important;">Use Project Storage</label>
                    <span class="network-config">
                      Research Gateway will setup a shared S3 bucket (project storage) where the team members can store data.
                      This shared storage will be mounted into all supported workspaces. Storage costs will be accounted at the project level.
                    </span>
                  </div>
                </div>
              </div>

                <div style="margin-top: 10px;margin-bottom: 10px; border-bottom: 1px solid #465374;">
                  <h3 class="product-desc font-class">Cost Control</h3>
              </div>
              <div class="form-control account-selection-content" style="margin-bottom: 15px">
                <div>
                  <div class="selection">
                    <input class="" [checked]="isCostControlEnabled" type="checkbox" id="isCostControlEnabledMobileView" name="isCostControlEnabled"
                    (change)="selectCostControl($event);">
                    <label class="selection-label" for="isCostControlEnabledMobileView"
                    style="display: block; margin-bottom: 5px;font-size: 14px!important;">Automatically respond to budget alerts</label>
                    <span class="network-config">
                      Research Gateway can automatically create budget consumption alerts and take actions like pausing the project (at {{projectPause}}%) or stopping the project (at {{projectStop}}%). Check this box to enable these actions.
                    </span>
                  </div>
                </div>
              </div>

            </div>
          </tab>
          <tab class="tab" heading="Help">
              <div class="helpSection">
                  <h3 style="color: #3FCB57; font: normal normal 800 18px/32px Nunito Sans;">Help</h3>
                    <div class="panel panel-default" *ngFor="let item of sectionWiseHelpdata; let k = index">
                      <div class="panel-heading heading" (click)="accordianCtrlNodeX(k)">
                        <h5 class="panel-title">
                          <table id="BotList-node" class="table table-striped" style="width: 100%;margin-bottom: unset">
                            <thead>
                              <tr>
                                <th class="accordian-header" style="width:18%;text-align: initial;"
                                  [ngStyle]="{'background-color': '#06153E'}">
                                  <span style="color: white; font: normal normal normal 13px/18px Nunito Sans;font-weight: 900">{{item.key}}</span></th>
                                  <td class="accordian-header" style="width:1%" [ngStyle]="{'background-color': '#06153E' }">
                                    <em class='glyphicon' style="font-size: 20px;font-weight: 900;color: white"
                                      [class.glyphicon-plus]="k != curx" [class.glyphicon-minus]="k == curx"
                                      class="accordion-toggle"></em>
                                </td>
                              </tr>
                            </thead>
                          </table>
                        </h5>
                      </div>
                      <div id="collapse-node" class="panel-collapse" [class.collapse]='k != curx'>
                        <table id="topology-info-node" class="table table-striped" style="width: 100%;">
                          <tbody class="txt-align">
                            <tr>
                              <td style="padding-top:10px;width:20%;background:#030A1E;border: 1px solid #85d4ff;font: normal normal normal 13px/18px Nunito Sans;
                              white-space: pre-wrap !important;
                              color: #FFFFFF;" [innerHTML]="item.value | linkify">
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>


                    <div class="panel panel-default" *ngFor="let item of helpTextData; let j = index">
                        <div class="panel-heading heading" (click)="accordianCtrlNode(j)">
                          <h5 class="panel-title">
                            <table id="BotList-node" class="table table-striped" style="width: 100%;margin-bottom: unset">
                              <thead>
                                <tr>
                                  <th class="accordian-header" style="width:18%;text-align: initial;"
                                    [ngStyle]="{'background-color': '#06153E'}">
                                    <span style="color: white; font: normal normal normal 13px/18px Nunito Sans;font-weight: 900">{{item.key}}</span></th>
                                    <td class="accordian-header" style="width:1%"
                                    [ngStyle]="{'background-color': '#06153E' }">
                                    <em class='glyphicon' style="font-size: 20px;font-weight: 900;color: white"
                                      [class.glyphicon-plus]="j != cur" [class.glyphicon-minus]="j == cur"
                                      class="accordion-toggle"></em></td>
                                </tr>
                              </thead>
                            </table>
                          </h5>
                        </div>
                        <div id="collapse-node" class="panel-collapse" [class.collapse]='j != cur'>
                          <table id="topology-info-node" class="table table-striped" style="width: 100%;">
                            <tbody class="txt-align">
                              <tr>
                                <td style="padding-top:10px;width:20%;background:#030A1E;border: 1px solid #85d4ff;font: normal normal normal 13px/18px Nunito Sans;
                                white-space: pre-wrap !important;
                                color: #FFFFFF;" [innerHTML]="item.value | linkify">
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                </div>
            </tab>
      </tabset>
</div>
</form>
</div>
