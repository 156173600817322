import { Component, OnInit, Inject } from '@angular/core';
import { MdlDialogReference } from '@angular-mdl/core';
import { DashboardService } from '../_services/dashboard.service';
import { ActivatedRoute, Router, Event, NavigationStart, NavigationError, NavigationEnd } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { Globals } from "../globals";

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./settings.component.css'],
  providers: [DashboardService, Globals]
})


export class ConfigComponent implements OnInit {

  serviceName: string = '';
  authType: string = '';
  showDiscoverMenu: boolean = false;
  vendor: string = '';
  discover:  string = '';
  timeZone: string = '';
  host:  string = '';
  userName:  string = '';
  password:  string = '';
  tableName: string = '';
  accessKey:  string = '';
  secretKey: string = '';
  region: string = '';
  repeatEvery: number=1;
  interval: string = 'Minutes';
  accountNumber: string = '';
  token: string = '';
  ticket: string = '';
  clientId:  string = '';
  clientSecret: string = '';
  resource: string = '';
  grantType: string = '';
  isEdit: boolean;
  subscriptionId: string = '';
  tenantId: string = '';
  projectId: string = '';
  privateKey: string = '';
  clientEmail: string = '';
  client_cert_url: string = '';
  public vendorCategory = [];
  editData: any;
  vendors: any;
  vendorlist: string = '';
  public submitBTN:any=true;
  showSpinner:boolean=false;
  adminView = false;
  accuntNameErrDesc = 'Account name should be unique and only alphanumeric characters, hyphens and underscores are allowed';
  defaultClass = 'helpText';
  userType;
  isVerified: boolean = false;
  accountType;

  constructor(
    private globals: Globals,
    public dialogRef: MdlDialogReference,
    public router: Router,
    private saveSettings: DashboardService,
    private toastr: ToastrService,
    @Inject('data') public data: any,
    private route: Router
  ) {
    this.route.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (event['url'] === '/setting/success' || event['url'] === '/setting/error') {
          this.route.navigate(['/setting']);
        }
      }
    });
   }

  ngOnInit() {
    if(this.data.name) {
      this.isEdit = true;
      this.populateData();
    }
    if(this.data.userType){
      this.userType = this.data.userType;
    }
    this.defaultChanges();
  } 
  defaultChanges() {
    this.vendorlist = 'Cloud';
    this.authType = 'IAM';
    this.vendor = 'AWS';
    this.timeZone = 'IST';
    this.discover = 'VPC';
    this.repeatEvery = 5;
    this.interval = 'Minutes';
  }

  onNoClick(): void {
    this.dialogRef.hide();
  }

  addSettings() {
     console.log("Add settings")
      this.submitBTN = false;
      var data = {};
      var auth = {};
      data["name"] = this.serviceName;
      data["vendor_category"] = this.vendorlist;
      data["vendor"] = this.vendor;
      data["auth_type"] = this.authType;
      data["interval"] = this.interval;
      data["repeat_every"] = this.repeatEvery;
      data["tableName"] = this.tableName;
      data["accountNumber"] = this.accountNumber;
      data["accountType"] = this.accountType;
      data["region"] = this.region;
      data["orgId"] = this.data.name.orgId;
      data["stackName"] = this.data.name?.studyDetails?.stackName;
      data["uniqueId"] = this.data.name?.studyDetails?.uniqueId;
      data["isShared"] = this.data.name?.isShared;
      data["username"] = this.data.name.username;
      data["_id"] = this.data.name._id;
      if (this.authType == 'Password') { 
        data["discover"] = this.discover
        auth = {
          host: this.host,
          username: this.userName,
          password: this.password
        }
        data["auth"] = auth;
      } else if (this.authType == 'Token') {
        data["discover"] = this.discover
        auth = {
          token: this.token,
          //ticket: this.ticket
        }
        data["auth"] = auth;
      } else if (this.authType == 'IAM') {
        data["discover"] = this.discover
        auth = {
          accessKey: this.accessKey,
          secretKey: this.secretKey,
          region: this.region,
          accountNumber: this.accountNumber
        }
        data["auth"] = auth;
      } else if (this.authType == 'oAuth') {
        auth = {
          clientId: this.clientId,
          clientSecret: this.clientSecret,
          resource: this.resource,
          grantType: this.grantType,
          subscriptionId: this.subscriptionId,
          tenantId: this.tenantId
        }
        data["auth"] = auth;
      } else if(this.authType == "Google Service Account"){
        auth = {
          projectId: this.projectId,
          privateKey: this.privateKey,
          clientEmail: this.clientEmail,
          client_cert_url: this.client_cert_url
        }
        data["auth"] = auth;
      } else if (this.authType == 'None') {
        data["auth"] = {};
        data["discover"] = [];
      }
      data["time_zone"] = this.timeZone;
      if (!this.isEdit) {
        this.showSpinner=true;
        this.saveSettings.saveSettings(data).subscribe(data => {
          this.showSpinner=false;
          this.navigate('success', 'Setting-saved-successfully');
        }, error => {
          this.showSpinner=false;
          let errorMes = error.error;
          if(errorMes && (errorMes.code && errorMes.message)){
            this.navigate('error', errorMes.message);
          } else if(errorMes && errorMes.code === 11000 ){
              //this is for checking duplicate record with same account name
                this.navigate('error', 'Setting cannot be saved, record already exists - ' + errorMes.errmsg);
          }else {
            this.navigate('error', 'Setting cannot be saved ');
          }
        });
      } else if(this.isEdit && this.accountType == 'project') {
        this.showSpinner=true;
        this.saveSettings.updateSettingById(this.data.name._id, data).subscribe(data => {
          this.navigate('success', 'Setting updated successfully');
          this.showSpinner=false;
        }, error => {
          console.log("update", error);
          this.showSpinner=false;
          this.isVerified = false;
          const err = error.error;
          this.toastr.error(``,err.message, this.globals.tosterOverride)
        });
      }  else if(this.isEdit && this.accountType == 'study') {
        this.showSpinner = true;
        this.saveSettings.repairStudyAccount(data).subscribe(data => {
          if(data) {
            this.navigate('info', 'Repair action started successfully');
          }          
          this.showSpinner=false;
        }, error => {
          console.log("Repair study account", error);
          this.showSpinner=false;
          this.isVerified = false;
          const err = error.error;
          this.toastr.error(``,err.error, this.globals.tosterOverride)
        });
      }  
  }
  
  verifyAccount(event){
    event.stopPropagation();
    event.preventDefault();
    var data = {};
    var auth = {};
    data["name"] = this.serviceName;
    data["vendor_category"] = this.vendorlist;
    data["vendor"] = this.vendor;
    data["auth_type"] = this.authType;
    data["interval"] = this.interval;
    data["repeat_every"] = this.repeatEvery;
    data["tableName"] = this.tableName;
    data["accountNumber"] = this.accountNumber;
    data["accountType"] = this.accountType;
    data["region"] = this.region;
    data["isEdit"] = true;
     if (this.authType == 'IAM') {
      data["discover"] = this.discover
      auth = {
        accessKey: this.accessKey,
        secretKey: this.secretKey,
        region: this.region,
        accountNumber: this.accountNumber
      }
      data["auth"] = auth;
    }
    data["time_zone"] = this.timeZone;
      this.showSpinner = true;
      this.saveSettings.verifySettings(data).subscribe(data => {
        this.showSpinner = false;
        this.isVerified = true;
        this.toastr.success(``, `Settings verified successfully.`, this.globals.tosterOverride);
      }, error => {
        console.log("verify", error);
        this.showSpinner = false;
        try{
          let errorMes = error.error;
          this.toastr.error(errorMes.message, ``, this.globals.tosterOverride);
          if(error && error.status === 401){
             this.toastr.error(errorMes.err, '', this.globals.tosterOverride);
             this.route.navigate(['login']);
           }else {
            this.toastr.error(errorMes.message, ``, this.globals.tosterOverride);
           }
        }catch(e){
          this.toastr.error(``, 'Service seems to be down. Please try after sometime.' , { tapToDismiss: false, timeOut: 0, extendedTimeOut: 0, closeButton : true });
        }
      });
}

  cancel(): void {
    this.dialogRef.hide();
  }

  populateData(): void {
    var nameData = this.data.name;
    this.serviceName = nameData.name;
    this.timeZone = nameData.time_zone;
    this.authType = nameData.auth_type;
    this.host = nameData.auth.host;
    this.interval = nameData.interval;
    this.repeatEvery = nameData.repeat_every;
    this.vendorlist = nameData.vendor_category;
    this.vendor = nameData.vendor;
    this.accountNumber = nameData.accountNumber;
    this.region = nameData.region;
    this.authType = 'IAM';
    this.accountType = nameData.accountType;
  }

  navigate(type, msg) {
    if(type == 'success') {
      this.dialogRef.hide();
      if(this.userType == 'principal'){
        this.router.navigate(['/principal']);
      }else{
        this.router.navigate(['setting/success']);
      }
      
      let message = `Setting saved successfully`;
      if(this.isEdit && this.accountType == 'project') {
        message = `Setting repaired successfully`;
        this.toastr.success(``, `Setting repaired successfully`, this.globals.tosterOverride);
      }       
    } else if(type == "info") {
      this.dialogRef.hide();
      this.router.navigate(['/setting'] ,{
        queryParams: { selectedTab: 'studyAccount' }
      });
      if(this.isEdit && this.accountType == 'study') {        
        this.toastr.info(``, `Repair action started successfully`, this.globals.tosterOverride);        
      }
    }
    else{
      this.router.navigate(['setting/error']);
      this.toastr.error(``, msg , { tapToDismiss: false, timeOut: 0, extendedTimeOut: 0, closeButton : true });

    }
  }

  checkAccountName(){
    const dataLeng = this.serviceName.length;
    const regexPattern = /^[-_ a-zA-Z0-9]+$/
    const getRegValue = this.serviceName.match(regexPattern);
    if(dataLeng == 0){
      this.defaultClass = 'helpText';
      this.accuntNameErrDesc = 'Account name should be unique and only alphanumeric characters, hyphens and underscores are allowed';
    }else if(getRegValue === null){
      this.defaultClass = 'errorText';
      this.accuntNameErrDesc = 'Only alphanumeric characters, hyphens and underscores are allowed';
    }else if(dataLeng < 3){
        this.defaultClass = 'errorText';
        this.accuntNameErrDesc = "Account name must be at least 3 characters";
    }else this.accuntNameErrDesc = '';

  }

}