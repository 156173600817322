<div id="ssh-conn-header" class="shh-con-head">
  <div class="ssh-configure-window">
    <div id='instanceSSHPage'>

      <div class="modal-header">
        <h2 class="modal-title p-header" id="myModalLabel">
          Get RDP Details - {{ getInstanceIp }}
        </h2>
      </div>
      <div style="display: flex; flex-direction: row; margin-top: 10px;">
        <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
        <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
        <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
        <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
      </div>
      <div class="modal-body ssh-model-body p-body">
        <fieldset>
          <div id="ssh-loadingContainer">
            <div class="txt-cent">
              <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
          </div>
          <form class="forms" id="ssh-login-form">
            <input type="hidden" id="ssh-instanceId" value={{getInstanceIp}}>
            <div class="col-lg-12 col-md-12 credentialSection">
              Username
              <span class="control-label red">*</span>
              <input  aria-label="RDPWindowUsername"  required name="usernameF" [(ngModel)]="usernameF" type="text" id="ssh-username" value="" autocomplete="off" class="form-control c-file-in"/>
              <b class="tooltip tooltip-top-right">
                <i class="fa fa-user txt-color-teal"></i>
                Please enter Instance Username
              </b>
            </div>
            <div class="col-lg-12 col-md-12 credentialSection sshStyle authType">
              Select Authentication Type
              <span class="control-label red">*</span>
              <select [(ngModel)]="pemFileCheckboxF" required name="pemFileCheckbox" id="ssh-pemFileCheckbox" class="chooseAuthenticationtype width-100 form-control c-file-in">
                <option id="pemFileCheckbox" value="pemFile">Pem File</option>
              </select>
            </div>
            <div class="col-lg-12 col-md-12 credentialSection passwordCheck sshStyle">
              Password
              <span class="control-label red">*</span>
              
            <div style="padding: 0px !important;" class="input-group user-input">
              <input aria-label="InstancePasswordInputField" [type]="showPassword ? 'text' : 'password'" name="passwordF" id="ssh-password" class="form-control c-file-in noBorder" #password="ngModel" data-toggle="passwordF"  required [(ngModel)]="passwordF" value="" autocomplete="off">
              <div (click)="togglePassword(true)" *ngIf="showPassword === false">
                  <img class="show-icon" alt="Show Password" src="../../assets/images/pass_inactive_icon.png"/>
              </div>
              <div *ngIf="showPassword === true" (click)="togglePassword(false)">
                  <img class="show-icon"  alt="Hide Password"  src="../../assets/images/pass_active_icon.png" />
              </div>
            </div>
            <span>
              <h4 class="pemFileHelpText" style="margin-top: 10px">Before downloading the RDP file you should copy/save the password</h4>
            </span>
            </div>
            <div class="col-lg-12 col-md-12 pemFileCheck sshStyle">
              <div class="smart-forms">
                <span class="control-label">Pem File</span>
                <span class="control-label red">*</span>
                <label name="field" class="file form-control file-lab-red c-file-label" id="ssh-pemfileInput-lab">
                  <input required id="ssh-pemfileInput" type="file" class="gui-file c-file-input">
                  <span id="ssh-fileName" class="c-file-span-fn">No file selected</span>
                  <span class="c-file-span">Choose a file</span>
                </label>
              </div>
              <span>
                <h4 class="pemFileHelpText"> Please upload the key file that was used to create this instance. It will be used to decrypt and show you the password to this instance. Research Gateway will not store or use this key beyond the current action</h4>
              </span>
            </div>
            <div class="col-lg-12 ssh-error-area">
            </div>
            <br>
          </form>
          <input  aria-label="keyPairSelectionButtonIcon"  type="button" style="display:none">
          <input type="hidden" id="ssh-actionLogId" />

        </fieldset>
      </div>
      <div class="modal-footer">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 importErrorMsg text-right error-padding">
          <span class="ssh-errorArea" id="ssh-errorArea"></span>
        </div>
        <div class="marginForButtons l-footer">
          <button type="button" class="btn cat-btn-update sshSubmit ssh-btn l-submit">
            Submit
          </button>
          <button type="button" class="btn cat-btn-update ssh-btn rdpSubmit">
            Download RDP file
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
