<div class="container">
  <!-- breadcrumb -->
  <div class="project-header" *ngIf="!projectLoader">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb bc-custom">
      <li class="breadcrumb-item">
        <a class="bc-item-a" routerLink="/principal"> My Projects </a>
      </li>
      <li class="breadcrumb-item active bc-item-a-active" aria-current="page">{{projectName}}</li>
    </ol>
  </nav>
  <div class="project-status-wrapper">
    <label class="project-text-a font-class text-white">Project Status :</label>
    <div *ngIf="projectStatus === 'Active'" class="status-info status-running"><label
      class="status-text">{{projectStatus}}</label></div>
    <div *ngIf="projectStatus === 'Paused'" class="status-info status-paused"><label
      class="status-text">{{projectStatus}}</label></div>
      <button *ngIf="projectStatus === 'Creating'" title="Please wait for some time, your project is being created."
      class="status-info status-paused" style="border: none !important;padding-right: 10px;padding-left: 20px;outline: none;
      cursor: default;">
          <label class="status-text">{{projectStatus}}</label>
          <i class="fa fa-refresh fa-spin" style="margin-right: 5px;"></i>
      </button>
    <div *ngIf="projectStatus === 'Stopped' || projectStatus === 'Failed'" class="status-info status-stopped"><label
      class="status-text">{{projectStatus}}</label></div>
  </div>
  </div>
  <!-- project details -->
  <!-- <div style="display: flex; justify-content: space-between; align-items: center; margin: 5px 0px;" *ngIf="!projectLoader">
    <div style="display: flex;width: 80%">
      <img *ngIf="projectImg" style="max-width: 75px; max-height: 75px; border-radius: 10px;" src={{projectImg}} alt="../assets/images/rocket.png"
      />
      <img *ngIf="!projectImg" style="max-width: 75px; max-height: 75px; border-radius: 10px;" src="../assets/images/rocket.png"
        alt="../assets/images/rocket.png" />
      <div style="display: flex; flex-direction: column; margin: 0px 15px;">
        <h4 class="text-white font-class">{{projectName}}</h4>
        <span style="color: #3E6BFF; font-size: 14px;line-height: 1.3em;" class="font-class" title="{{projectDescription}}">
          {{projectDescTrim(projectDescription)}}
        </span>
        <span class="form-info" *ngIf="projectStatus == 'Creating'">
          Please wait for some time, your project is being created.
        </span>
      </div>
    </div>
    <div class="web-status" *ngIf="projectStatus" style="height: 45px; display: flex; border: 1px solid #465374; border-radius: 23px; align-items: center; padding: 10px;">
      <div style="width: 20px; height: 20px; border-radius: 10px;" class="{{getStatusColor(projectStatus)}}" *ngIf="projectStatus"></div>
      <span style="margin: 0px 10px; font-size: 14px;" class="text-white font-class">{{projectStatus}}</span>
      <img style="height: 15px; margin: 10px; cursor: pointer;" src="../../assets/images/refresh.png" (click)="getProjects(projectId)"
      />
    </div>
  </div> -->
  <div style="transform-origin: center top;" *ngIf="!projectLoader">
    <div class="header">
      <div class="header-body">
        <div class="kpi-container">

          <div class="kpi-card" tabindex="0" (click)="getBudgetDetails()" (keypress)="getBudgetDetails()">
            <div class="kpicard bg-gradient-info border-0">
              <div class="card-body">
                <div class="row">
                  <div class="col card-bx">
                    <div class="kpi-card-header">
                        <span class="h2 font-weight-bold mb-0 text-white card-bx-ft card-value">$
                          <span class="h2 font-weight-bold mb-0 text-white card-bx-ft card-value"
                                >{{totalBudgetAllotted}}</span>
                        </span>
                      <img class="kpi-card-icon" src="../../assets/images/available_budget.png" alt="Available Project Budget"/>
                    </div>
                    <div class="kpi-info">
                      <h5 class="card-title text-muted mb-0 text-white card-bx-se font-class">Direct Cost Budget
                        <!-- <span class="budget-percentage"> ( {{availableBudgetPercentage}} % ) </span> -->
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="kpi-card" tabindex="0" (click)="getBudgetDetails()" (keypress)="getBudgetDetails()">
            <div class="kpicard bg-gradient-danger border-0">
              <div class="card-body">
                <div class="row">
                  <div class="col card-bx">
                    <div class="kpi-card-header">
                              <span class="h2 font-weight-bold mb-0 text-white card-bx-ft card-value">$
                                <span class="h2 font-weight-bold mb-0 text-white card-bx-ft card-value"
                                      >{{totalBudgetConsumed}} 
                                      <!-- <span *ngIf="consumedBudgetPercentage >= thresholdPercentage"><i class="fa fa-exclamation-triangle"></i></span> -->
                                    </span>
                              </span>
                      <img class="kpi-card-icon" src="../../assets/images/consumed_budget.png" alt="Consumed Project Budget"/>
                    </div>
                    <div class="kpi-info">
                      <h5 class="card-title text-muted mb-0 text-white card-bx-se font-class">Total Direct Cost
                        <!-- <span class="budget-percentage"> ( {{consumedBudgetPercentage}} % ) </span> -->
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="kpi-card" tabindex="0" (click)="getBudgetDetails()"  (keypress)="getBudgetDetails()">
            <div class="kpicard bg-gradient-default border-0">
              <div class="card-body">
                <div class="row">
                  <div class="col card-bx">
                    <div class="kpi-card-header">
                          <span class="h2 font-weight-bold mb-0 text-white card-bx-ft card-value">$
                            <span class="h2 font-weight-bold mb-0 text-white card-bx-ft card-value"
                                  >{{totalcurrentMonthCost}}</span>
                          </span>
                      <img class="kpi-card-icon" src="../../assets/images/consumed_project_budget.png" alt="Current Month Consumed Project Budget"/>
                    </div>
                    <div class="kpi-info">
                      <h5 class="card-title text-muted mb-0 text-white card-bx-se font-class">Current Month Direct Cost</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!-- tab section -->
  <div class="webViewTab" *ngIf="!projectLoader">
    <!-- material tab -->
    <mat-tab-group mat-align-tabs="start" animationDuration="0ms" [selectedIndex]="currentTab" [disableRipple]="true" (selectedTabChange)="tabChanged($event)">
      <mat-tab label="Project Details">
          <div style="display: block; position: relative;">
            <div class="product-details-wrapper" *ngFor="let project of projects">
              <div style="display: flex; flex-direction: column;">
                <label style="color: #85D4FF; font-size: 16px; font-weight: 500; margin-top: 20px;" class="font-class">Project Name 
                  <span (click)="projectSyncType = true; isProjectEdit = true" tabindex="0" (keypress)="projectSyncType = true; isProjectEdit = true" *ngIf="!projectSyncType && checkIfResearcherIsPartOfProject" class="add-researcher-manage">
                  <i style=" width: auto; " class="fa fa-pencil" aria-hidden="true"></i>
                  Edit
                </span> &ensp;<span *ngIf="dependencyChecksStatus === 'Failed' && projectStatus !== 'Creating'"><img style="height: 16px;margin-bottom: 6px" src="../../assets/images/alert-128.png"></span>
                  <span class="no-access" *ngIf="!checkIfResearcherIsPartOfProject" title="You don't have access to this project. Please contact the project owner {{project.created_by}}">
                    No Access
                </span>
                </label>
                <label style="font-size: 14px; font-weight: 400;" *ngIf="!isProjectEdit" class="text-white font-class">{{project.name}}</label>
                <a *ngIf="dependencyChecksStatus === 'Failed' && projectStatus !== 'Creating'" style="color: #cc3300; font-size: 11px;">{{failedReason}}</a>
              </div>
                <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="padding: 0px;margin-right: 15px" *ngIf="projectSyncType">
                  <input required aria-label="Project Name Input Field" [(ngModel)]="updatedName" pattern="^\S.*$"  class="inp-txt user-input" type="text" minlength="1" maxlength="32" name="name" placeholder="Please enter your project name - (between 1 to 32 characters) (required)">
                </div>
              <div *ngIf="isProjectEdit">
                <button class="btn btn-primary edit-btn" (click)="updateProjectName(updatedName, project.name)" name="button"   [disabled]="isInvalidProjectName(updatedName)">
                  Update Name
                  <em class="fa fa-refresh fa-spin" *ngIf="showSpinner"></em>
                </button>
                <button class="btn btn-primary edit-cancel-btn" type="button" (click)="cancelEdit(project.name)">
                  Cancel
                  <em class="fa fa-refresh fa-spin" *ngIf="showSpinner"></em>
                </button>
              </div>
              <div style="display: flex; flex-direction: column;">
                <label style="color: #85D4FF; font-size: 16px; font-weight: 500; margin-top: 20px;" class="font-class">Project Type</label>
                <label style="font-size: 14px; font-weight: 400;" class="text-white font-class">{{projectType}}</label>
              </div>
              <div style="display: flex; flex-direction: column;">
                <label style="color: #85D4FF; font-size: 16px; font-weight: 500; margin-top: 20px;" class="font-class">Created On</label>
                <label style="font-size: 14px; font-weight: 400;" class="text-white font-class">{{project.created_on | date}}</label>
              </div>              
              <div style="display: flex; flex-direction: column;">
                <label style="color: #85D4FF; font-size: 16px; font-weight: 500; margin-top: 20px;" class="font-class">Project Owner</label>
                <label style="font-size: 14px; font-weight: 400;" class="text-white font-class">{{project.created_by}}</label>
              </div>
              <div style="display: flex; flex-direction: column;width: 75%">
                <h2 style="color: #85D4FF; font-size: 16px; font-weight: 500; margin-top: 20px;" class="font-class">
                  Assigned Users
                  <span tabindex="0" (keypress)="updateBTN=false" (click)="updateBTN=false" *ngIf="updateBTN && researcherIdList.length > 0 && checkIfResearcherIsPartOfProject && showMangeUsers" class="add-researcher-manage">
                    <i style=" width: auto; " class="fa fa-pencil" aria-hidden="true"></i>
                    Manage
                  </span>
                </h2>
                <p style="font-size: 14px; font-weight: 400;" class="text-white font-class" *ngIf="updateBTN">{{ researchName }}</p>
                <p style="font-size: 14px; font-weight: 400;" class="text-white font-class" *ngIf="updateBTN && researchName.length == 0">
                  There are no additional collaborators on this project. You can add collaborators through Users.</p>
                <div class="form-control researcher-selection-container" *ngIf="!updateBTN">
                  <span *ngIf="researcherIdList.length > 0" class="selection-header">Select users from the list</span>
                  <div *ngIf="researcherIdList.length === 0" style="margin-top: 60px; font-size: 14px; font-weight: 400;" class="text-white font-class"> We could not find any users that matched your search </div>
                  <div class="selection" *ngFor="let researcher of researcherIdList; let i = index">
                    <input type="checkbox" [id]="researcher.value+i" [checked]="validateResearcher(researcher.value)" [disabled]="validateProvisionedResearcher(researcher.value)"
                    name="researcher.value" (change)="EditResearcherId(researcher, $event)">
                  <label [ngClass]="{'uncheck-item': validateProvisionedResearcher(researcher.value) === true}" [for]="researcher.value+i"
                    class="selection-label">{{researcher.viewValue}}</label>
                  </div>
                </div>
                  <span *ngIf="!updateBTN" class="add-researcher-title">Users who have associated products cannot be unchecked.</span>
                  <div class="primary-flex" *ngIf="!updateBTN">
                  <button class="btn btn-primary edit-btn" (click)="insertResearchers()" type="button" [disabled]="updateBTN">
                    Update list
                    <em class="fa fa-refresh fa-spin" *ngIf="showSpinner"></em>
                  </button>
                  <button class="btn btn-primary edit-cancel-btn" type="button" (click)="updateBTN=true" [disabled]="updateBTN">
                    Cancel
                    <em class="fa fa-refresh fa-spin" *ngIf="showSpinner"></em>
                  </button>
                  <div class="search-div-users" style="margin-right: auto">
                    <em class="glyphicon glyphicon-search search-icon-users"></em>
                    <input (keyup)="searchUsers()" aria-label="searchUsers" [(ngModel)]="searchUser" class="form-control search-inp-users search-inp-add-users" placeholder="Search" type="text">
                  </div>
                </div>
              </div>
              <div *ngIf="projectType !== 'Secure Research' && projectType !== 'Data Library' && projectType !== 'Ingress Gateway' ">
                <h2 class="font-class" style="color: #85D4FF; font-size: 16px; font-weight: 500; margin-top: 20px;">Add Products
                  <span (click)="manageSyncType = true; isEdit = true" (keypress)="manageSyncType = true; isEdit = true" tabindex="0" *ngIf="!manageSyncType && checkIfResearcherIsPartOfProject" class="add-researcher-manage">
                    <i style=" width: auto; " class="fa fa-pencil" aria-hidden="true"></i>
                    Manage
                  </span>
                </h2>
                <span *ngIf="!manageSyncType">
                <span *ngFor="let type of withSpaceAndComma(productSyncTypeVal)">
                    <p style="font-size: 14px; font-weight: 400;" class="text-white font-class">
                      {{type}}
                    </p>
                  </span>
              </span>
                <p style="font-size: 14px; font-weight: 400;" class="text-white font-class" *ngIf="!manageSyncType && productCatalogTag !=undefined && ( productCatalogTag.key !=null || productCatalogTag.value !=null)">Key: {{ productCatalogTag.key }} , Value: {{ productCatalogTag.value }}</p>
                <p style="font-size: 14px; font-weight: 400;" class="text-white font-class" *ngIf="!manageSyncType && productSyncTypeVal.length == 0">
                  There are no Bring your own catalog type configured for this project.</p>
              <div class="form-control account-selection-content" style="min-height: 170px;width: 75%" *ngIf="manageSyncType">
                <span class="selection-header">Select Bring your own catalog type from the list</span>
                <div>
                  <div class="selection" *ngFor="let cat of byocCatalogs; let i = index" >
                    <input [disabled] = "!showAddProducts" class="" [checked]="cat.checked" required type="checkbox" [id]="cat.value" name="cat.value" (change)="selectBYOCSyncType(cat, $event, i)">
                    <label [for]="cat.value" class="selection-label" *ngIf="cat.value == 'ownCatalog'">{{cat.viewValue}} (This option will sync all the products which have the required launch permission)</label>
                    <label [for]="cat.value" class="selection-label" *ngIf="cat.value == 'ownCatalogWithTag'">{{cat.viewValue}} (This option will sync only products which have the required tag)</label>
                    <form class="form-inline" *ngIf="cat.value == 'ownCatalogWithTag' && showTagFiled" style="margin-top: 20px;">
                      <label class="selection-label" for="key">Key:</label>
                      <input type="text" id="key" placeholder="Enter Key" name="key" [(ngModel)]="tagKey">
                      <label class="selection-label" for="value">Value:</label>
                      <input type="text" id="value" placeholder="Enter Value" name="value" [(ngModel)]="tagValue">
                      <button type="submit" tabindex="0" (keypress)="submitTag();isEdit=true" (click)="submitTag();isEdit=true">Submit</button>
                    </form>
                  </div>
                  <div class="col-lg-12 col-md-12" style="margin-top: 20px;">
                    <div  *ngIf="productCatalogTag" class="tag-t-s">
                      <span title="{{productCatalogTag.value}}" style=" font-size: 12px; ">{{productCatalogTag.key}}</span>
                      <i class="fa fa-times-circle tag-span-i" tabindex="0" (click)="removeTag()" (keypress)="removeTag()"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="isEdit">
                <button class="btn btn-primary edit-btn" (click)="updateAddProducts()" type="button">
                  Update list
                  <em class="fa fa-refresh fa-spin" *ngIf="showSpinner"></em>
                </button>
                <button class="btn btn-primary edit-cancel-btn" type="button" (click)="isEdit=false; manageSyncType=false;">
                  Cancel
                  <em class="fa fa-refresh fa-spin" *ngIf="showSpinner"></em>
                </button>
              </div>
            </div>
              <div style="display: flex; flex-direction: column;">
                <label style="color: #85D4FF; font-size: 16px; font-weight: 500; margin-top: 20px;" class="font-class">Budget</label>
                <label style="font-size: 14px; font-weight: 400;" class="text-white font-class">USD {{ project.budget }}
                  <span [ngClass]="getProjectThresholdClass(project)"> ( {{project.budgetConsumedPercentage}} % used )</span>
                </label>
              </div>
              <div *ngIf="showLinkedExternalGroup" style="display: flex; flex-direction: column;">
                <label style="color: #85D4FF; font-size: 16px; font-weight: 500; margin-top: 20px;" class="font-class">Linked Active Directory Group</label>
                <label style="font-size: 14px; font-weight: 400;" class="text-white font-class">{{ project?.linkedActiveDirectoryGroup }}
                </label>
              </div>
              <div style="display: flex; flex-direction: column;width: 75%;margin-bottom: 20px;">
                <label style="color: #85D4FF; font-size: 16px; font-weight: 500; margin-top: 20px;" class="font-class">Project Description</label>
                <label style="font-size: 14px; font-weight: 400;" class="text-white font-class">{{project.description}}</label>
              </div>
              <div style="display: flex; flex-direction: column;width: 75%;margin-bottom: 20px;">
                <label style="color: #85D4FF; font-size: 16px; font-weight: 500; margin-top: 10px;" class="font-class">Linked Studies</label>
                <div *ngIf="studyList.length == 0">
                  <label style="font-size: 14px; font-weight: 400;" class="text-white font-class">
                    No studies are Linked to this project
                  </label>
                </div>
                <div *ngFor="let studyName of studyList">
                  <label style="font-size: 14px; font-weight: 400;" class="text-white font-class" *ngIf="studyList.length !== 0" >{{studyName}}</label>
              </div>
              </div>
            </div>
            <div class="web-actions" *ngIf="projectStatus != 'Creating' && checkIfResearcherIsPartOfProject" style="background-color: #07102A; border-radius: 10px; margin: 20px; padding: 20px;">
              <div style="display: flex; flex-direction: column">
                <span style="color: #85D4FF; font-size: 14px; font-weight: 600; margin-top: 20px;" class="font-class">ACTIONS</span>
                <div class="tab-flex">
                  <button class="action-link" title="Resume" *ngIf="(projectStatus === 'Paused' || projectStatus === 'Stopped') && allowProjectActionsOverride" (click)="projectActions('Active', projectStatus)"
                    style="background-color: #15244B; border-radius: 5px; width: 100%; height: 45px; display: flex; align-items: center; margin-top: 10px;margin-right: 9px;cursor: pointer;">
                    <img style="height: 12px;height: 15px;margin-right: 15px;margin-left: 15px" src="../../assets/images/play@2x.png" alt="Resume Project"
                    />
                    <span style="font-size: 12px;cursor: pointer;" class="text-white font-class">Resume</span>
                 </button>
                  <button class="action-link" title="Pause" *ngIf="projectStatus === 'Active' " (click)="projectActions('Paused', projectStatus)"
                    style="background-color: #15244B; border-radius: 5px; width: 100%; height: 45px; display: flex; align-items: center; margin-top: 10px;margin-right: 9px;cursor: pointer;">
                    <i class="fa fa-pause" aria-hidden="true" style="color: #FFFFFF;width: 3px;height: 11px;margin-right: 20px;margin-left: 15px; margin-top: -10px;"></i>
                    <span style="font-size: 12px;cursor: pointer;" class="text-white font-class">Pause</span>
                 </button>
                  <button class="action-link" title="Stop" *ngIf="projectStatus === 'Active' " (click)="projectActions('Stopped', projectStatus)"
                    style="background-color: #15244B; border-radius: 5px; width: 100%; height: 45px; display: flex; align-items: center; margin-top: 10px;margin-right: 9px;cursor: pointer;">
                    <img style="height: 12px;height: 15px;margin-right: 15px;margin-left: 18px" src="../../assets/images/stop.png"
                      alt="Stop Project" />
                    <span style="font-size: 12px;cursor: pointer;" class="text-white font-class">Stop</span>
                 </button>
                  <button class="action-link" title="Repair" *ngIf="projectStatus === 'Failed' " (click)="repairProject()"
                  style="background-color: #15244B; border-radius: 5px; border: none; width: 100%; height: 45px; display: flex; align-items: center; margin-top: 10px;margin-right: 9px;cursor: pointer;">
                  <i class="fa fa-wrench" aria-hidden="true" style=" margin-left: 10px;margin-right: 5px;color: #FFFFFF;font-size: 15px;"></i>
                  <span style="font-size: 12px;cursor: pointer;" class="text-white font-class">Repair
                    <i *ngIf="showSpinnerForRepair" class="fa fa-refresh fa-spin" style="margin-left: 10px;"></i>
                  </span>
                </button>
                <button class="action-link" title="Sync" *ngIf="projectStatus === 'Active' " (click)="syncProject()"
                style="background-color: #15244B; border-radius: 5px; width: 100%; height: 45px; display: flex; align-items: center; margin-top: 10px;margin-right: 9px;cursor: pointer;">
                <i class="fa fa-refresh" aria-hidden="true" style=" margin-left: 15px;margin-right: 12px;color: #FFFFFF;font-size: 15px;"></i>
                <span style="font-size: 12px;cursor: pointer;" class="text-white font-class">Sync</span>
                </button>
                <button class="action-link" title="Add Budget" *ngIf="(projectStatus === 'Active' || projectStatus === 'Paused' || projectStatus === 'Stopped')" (click)="addBudget()"
                style="background-color: #15244B; border-radius: 5px; width: 100%; height: 45px; display: flex; align-items: center; margin-top: 10px;margin-right: 9px;cursor: pointer;">
                <img style="height: 12px;color: white;height: 15px;margin-right: 15px;margin-left: 18px" src="../../assets/images/Dollor-icon.png" alt="Add Budget to Project" />
                <span style="font-size: 12px;cursor: pointer;" class="text-white font-class">Add Budget</span>
              </button>
                <button class="action-link" title="Archive" *ngIf="projectStatus === 'Active' || projectStatus === 'Failed'" (click)="archiveProject()"
                style="background-color: #15244B; border-radius: 5px; width: 100%; height: 45px; display: flex; align-items: center; margin-top: 10px;margin-right: 9px;cursor: pointer;">
                <i class="fa fa-archive" aria-hidden="true" style=" margin-left: 15px;margin-right: 12px;color: #FFFFFF;font-size: 15px;"></i>
                <span style="font-size: 12px;cursor: pointer;" class="text-white font-class">Archive</span>
              </button>                
                </div>
              </div>
            </div>
          </div>
      </mat-tab>
      <mat-tab label="Events">
        <div class="event-tab-header" style="display: flex; justify-content: space-between; align-items: center; margin: 37px 2px;">
          <span style="color: #fff; font-size: 1.75rem; font-weight: 500;" class="font-class">Events</span>
          <div class="refresh-events" style="display: flex; align-items: center; padding: 5px 10px; border: 1px solid #6E7C9F; background-color: #030A1E; border-radius: 5px; cursor: pointer;"
            (click)="getProjectEvents()" tabindex="0" (keypress)="getProjectEvents()" *ngIf="!loader">
            <img style="height: 12px;" src="../../assets/images/refresh.png" alt="Refresh Project Events"/>
            <span style="font-size: 12px; font-weight: 400; margin-left: 10px;" class="text-white font-class">REFRESH</span>
          </div>
        </div>
        <div *ngIf="projectEvents.length === 0 && !loader && !gotEventsData">
          <h4 style=" color: #fff;text-align: center;padding: 100px; font-size: 22px">
            No Events found
          </h4>
        </div>
        <div *ngIf="projectEvents.length > 0 && !loader && gotEventsData" class="table-responsive md-layout lg-layout" style="margin-bottom: 25px; margin-top: 42px;">
          <table style="width: 100%">
            <thead>
              <tr>
                <th class="tableHeader" style="width: 15%; border-top-left-radius: 10px; border-bottom-left-radius: 10px;">Logical ID</th>
                <th class="tableHeader" style="width: 20%;">Time Stamp</th>
                <th class="tableHeader" style="width: 10%;">Status</th>
                <th class="tableHeader" style="width: 30%; border-top-right-radius: 10px; border-bottom-right-radius: 10px;">Status Reason</th>
              </tr>
            </thead>
            <div class="table" style="width: 100%;"></div>
            <tbody>
              <tr *ngFor="let data of projectEvents" class="table-tr-data">
                <td title="{{data.productId}}" class="tableData" style="width: 15%;text-transform: uppercase;">{{data.productId}}</td>
                <td class="tableData" style="width: 20%;" *ngIf="data.createdDate">{{data.createdDate | date:'medium'}}</td>
                <td [ngClass]="getEventStatusClass(data.severity)" style="width: 10%; text-transform: uppercase;"> {{data.action}}
                  <span *ngIf="data.action === 'SYNC_FAILED'" title="Do a sync from project details tab"  style="margin-left: 5px;">
                    <img style="height: 15px; width: 15px;" src="../../assets/images/info.png" alt="Refresh Project Events"/>
                  </span>
                </td>
                <td title="{{data.message}}" class="tableData" style="width: 30%;">{{getprojectStatus(data.message)}}</td>
            </tbody>
          </table>
          <div class="product-avail" style=" border-radius: 10px; ">
            <span class="page-count-text">{{ eventsCurrentPageLowerCount }} - {{ eventsCurrentPageUpperCount }} of {{ eventsTotalRecords }}</span>
            <div class="pagination-wrapper">
              <img class="pagination-next-btn" [ngClass]="{'disable-first-btn':eventsPage === 1 }" src="../../assets/images/pagination-first-active.png" alt="First Page" [attr.tabindex]="eventsPage === 1 ? -1 : 0" [attr.aria-disabled]="eventsPage === 1 ? 'true' : 'false'"
                (click)="getNextProjectEvents('first')" (keypress)="getNextProjectEvents('first')" />
              <img class="pagination-next-btn" [ngClass]="{'disable-prev-btn':eventsPage === 1 }" src="../../assets/images/pagination-prev-active.png" alt="Previous Page" [attr.tabindex]="eventsPage === 1 ? -1 : 0" [attr.aria-disabled]="eventsPage === 1 ? 'true' : 'false'"
                (click)="getNextProjectEvents('previous')" (keypress)="getNextProjectEvents('previous')" />
              <img class="pagination-next-btn" [ngClass]="{'disable-next-btn':eventsPage === eventsTotalPages}" src="../../assets/images/pagination-next-active.png" alt="Next Page" [attr.tabindex]="eventsPage === eventsTotalPages ? -1 : 0" [attr.aria-disabled]="eventsPage === eventsTotalPages ? 'true' : 'false'"
                (click)="getNextProjectEvents('next')" (keypress)="getNextProjectEvents('next')" />
              <img class="pagination-next-btn" [ngClass]="{'disable-last-btn':eventsPage === eventsTotalPages}" src="../../assets/images/pagination-last-active.png" alt="Last Page" [attr.tabindex]="eventsPage === eventsTotalPages ? -1 : 0" [attr.aria-disabled]="eventsPage === eventsTotalPages ? 'true' : 'false'"
                (click)="getNextProjectEvents('last')" (keypress)="getNextProjectEvents('last')" />
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Available Products" *ngIf="checkIfResearcherIsPartOfProject">
          <div class="project-title-layout">
            <h3 class="box-title text-white font-class">Available Products
              <h3 class="box-title text-white font-class" *ngIf="!loader && availableProductsList.length > 0">&nbsp;( {{availableProductCount}} )</h3>
            </h3>
            <div style="display: flex; justify-content: center; align-items: center;">
              <div class="search-div">
                <div style="display: flex;">
                  <em class="glyphicon glyphicon-search search-icon"></em>
                  <label for="searchAvailableProduct" class="visually-hidden">searchAvailableProduct</label>   
                  <input (keyup)="availableSearchProduct()" aria-label="searchUsersMobileView" [(ngModel)]="searchKeyForAvailableProduct" class="form-control search-inp search-inp-add"
                    placeholder='Search available products' type="text" name="searchAvailableProduct" id="searchAvailableProduct">
                </div>
                <div style="display: flex; align-items: center;">
                  <div class="vertical-divider"></div>
                  <div class="custom-select-wrapper">
                    <div class="dropdown">
                      <span style="width: 45% !important;z-index: 2;" class="tenant-cl btn btn-primary dropdown-toggle menu-dropdown-contents"
                        type="button" data-toggle="dropdown">
                        <span class="menu-btn">
                          <span class="menu-btn-text username" title="{{categoryFilterForAvailableProduct}}">
                            {{getFilterName(categoryFilterForAvailableProduct)}} </span>
                        </span>
                        <em class="fa fa-angle-down white menu-cal-f" tabindex="0" style="font-size: 20px; margin-left: 10px;"></em>
                      </span>
                      <ul class="dropdown-menu">
                        <button type="button" title="{{data}}" class="btn menu-item-btn" [ngClass]="{'filter-log-active': categoryFilterForAvailableProduct === data}"
                          *ngFor="let data of filterList" (click)="setPage(); getAllAvailableProducts(data, this.accountId); categoryFilterForAvailableProduct = data;">
                          {{getFilterName(data)}}</button>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="hide-layout" style="z-index: 999;">
                <img class="table-btn" tabindex="0" *ngIf="isCardView" src="../../assets/images/table_view_inactive.png" alt="Switch To Table View" (click)="isCardView=false" (keypress)="isCardView=false" />
                <img class="card-btn" tabindex="0" *ngIf="!isCardView" src="../../assets/images/card_view_inactive.png" alt="Switch To Card View" (click)="isCardView=true" (keypress)="isCardView=true" />
              </div>
            </div>
          </div>
          <div>
            <div *ngIf="isCardView">
              <div class="product-container" *ngIf="!loader">
                <div class="available-product-card" *ngFor="let availableProduct of availableProductsList">
                  <div style="display: flex;">
                    <img class="available-product-img" src="{{availableProduct.imageUrl}}" alt="Available Product {{ availableProduct.name}}"/>
                    <div style="display: flex; flex-direction: column; width: 100%;">
                      <label class="text-white font-class available-product-title available-product-title-max-line">{{ availableProduct.name}}</label>
                      <div class="know-more-wrapper">
                        <div [ngClass]="{'available-prod-view-details-layout': availableProduct.detailsLink, 'available-prod-view-details-layout-noLink' : !availableProduct.detailsLink }"
                          (click)="openKnowMore(availableProduct.detailsLink)" (keypress)="openKnowMore(availableProduct.detailsLink)" >
                          <img style="height: 15px; width: 15px;" src="../../assets/images/info.png" alt="Know More About {{ availableProduct.name}} Product"/>
                          <span tabindex="0" [ngClass]="{'available-prod-view-details-text': availableProduct.detailsLink, 'available-prod-view-details-text-noLink' : !availableProduct.detailsLink }"
                            class="available-prod-view-details-text">Know more</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <label class="text-white font-class available-product-description line-clamp">{{availableProduct.shortDescription}}</label>
                  </div>
                  <div class="launch-btn-wrapper" *ngIf="projectStatus == 'Active'">
                    <div class="launch-btn-layout">
                      <button class="launch-btn font-class" tabindex="0" (keypress)="catalogOrder(availableProduct)" (click)="catalogOrder(availableProduct)">LAUNCH NOW</button>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="availableProductsList && availableProductsList.length === 0 && !loader && !gotAvailableProducts" class="empty-my-product-layout">
                <div>
                  <h4 class="no-data-found text-white font-class text-center noDataText">{{noDataTemplate}}</h4>
                  <h4 class="no-data-found-subtext text-white font-class text-center">{{noDataMsg}}</h4>
                </div>
              </div>
            </div>
            <div *ngIf="!isCardView">
              <div class="table-responsive">
                <div class="table-header" *ngIf="availableProductsList.length > 0">
                  <div class="table-title-layout" style="width: 23%;">
                    <span class="table-title-text font-class">Product Name</span>
                  </div>
                  <div class="table-title-layout" style="width: 57%;">
                    <span class="table-title-text font-class">Description</span>
                  </div>
                </div>
                <table class="table" style="width: 100%; margin-top: 10px;">
                  <tbody>
                    <tr class="table-body-separator" *ngFor="let availableProduct of availableProductsList">
                      <td style="width: 30%; padding-top: 15px !important;">
                        <div>
                          <img class="table-body-icon" src="{{availableProduct.imageUrl}}" alt="Available Product {{ availableProduct.name}}"/>
                          <span class="table-body-name font-class" style="font-size: 12px !important">{{availableProduct.name}}</span>
                        </div>
                      </td>
                      <td>
                        <div style="display: flex;">
                          <div class="table-know-more-btn" tabindex="0" (keypress)="openKnowMore(availableProduct.detailsLink)" (click)="openKnowMore(availableProduct.detailsLink)" >
                            <img style="height: 15px; width: 15px;" src="../../assets/images/info.png" alt="Know More About {{ availableProduct.name}} Product" />
                            <span style="color: #85D4FF; margin-left: 10px;">Know more</span>
                          </div>
                          <span class="table-body-description">{{availableProduct.shortDescription}}</span>
                          <div class="table-body-launch-btn" tabindex="0" (keypress)="catalogOrder(availableProduct)" (click)="catalogOrder(availableProduct)" *ngIf="projectStatus == 'Active'">
                            <span class="table-body-launch-text font-class">Launch Now</span>
                            <img style="height: 15px; width: 15px; margin-left: 20px;" src="../../assets/images/right-arrow.png" alt="Click Here For Launch the Product" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div *ngIf="availableProductsList && availableProductsList.length === 0 && !loader && !gotAvailableProducts" class="empty-my-product-layout" style="margin-top: 20px">
                <div>
                  <h4 class="no-data-found text-white font-class text-center noDataText">{{noDataTemplate}}</h4>
                  <h4 class="no-data-found-subtext text-white font-class text-center">{{noDataMsg}}</h4>
                </div>
              </div>
            </div>
            <div class="product-avail" *ngIf="!loader &&  availableProductsList.length > 0">
              <span class="page-count-text">{{ currentPageLowerCount }} - {{ currentPageUpperCount }} of {{ totalProducts }}</span>
              <div class="pagination-wrapper">
                <img class="pagination-next-btn" [ngClass]="{'disable-first-btn':currentPage === 1 }" src="../../assets/images/pagination-first-active.png" alt="First Page" [attr.tabindex]="currentPage === 1 ? -1 : 0" [attr.aria-disabled]="currentPage === 1 ? 'true' : 'false'"
                (click)="getNextAvailableProducts('first')" (keypress)="getNextAvailableProducts('first')" />
                <img class="pagination-next-btn" [ngClass]="{'disable-prev-btn':currentPage === 1 }" src="../../assets/images/pagination-prev-active.png" alt="Previous Page" [attr.tabindex]="currentPage === 1 ? -1 : 0" [attr.aria-disabled]="currentPage === 1 ? 'true' : 'false'"
                (click)="getNextAvailableProducts('previous')" (keypress)="getNextAvailableProducts('previous')" />
               <img class="pagination-next-btn" [ngClass]="{'disable-next-btn':currentPage === totalPages}" src="../../assets/images/pagination-next-active.png" alt="Next Page" [attr.tabindex]="currentPage === totalPages ? -1 : 0" [attr.aria-disabled]="currentPage === totalPages ? 'true' : 'false'"
                (click)="getNextAvailableProducts('next')" (keypress)="getNextAvailableProducts('next')" />
              <img class="pagination-next-btn" [ngClass]="{'disable-last-btn':currentPage === totalPages}" src="../../assets/images/pagination-last-active.png" alt="Last Page" [attr.tabindex]="currentPage === totalPages ? -1 : 0" [attr.aria-disabled]="currentPage === totalPages ? 'true' : 'false'"
                (click)="getNextAvailableProducts('last')" (keypress)="getNextAvailableProducts('last')" />
              </div>
            </div>
          </div>
      </mat-tab>
      <mat-tab label="My Products" *ngIf="checkIfResearcherIsPartOfProject">
          <div class="project-title-layout">
            <h3 class="box-title text-white font-class"> My Products
              <h3 class="box-title text-white font-class" *ngIf="!loader && activeProductsList.length > 0">&nbsp;( {{activeProductCount}} )</h3>
            </h3>
            <div style="display: flex; justify-content: center; align-items: center;">
              <div class="search-div">
                <div style="display: flex;">
                  <em class="glyphicon glyphicon-search search-icon"></em>
                  <label for="searchKeyForMyProduct" class="visually-hidden">searchKeyForMyProduct</label>   
                  <input (keyup)="myProductsSearch()" [(ngModel)]="searchKeyForActiveProduct" class="form-control search-inp search-inp-add"
                    placeholder='Search in my products' type="text" name="searchKeyForMyProduct" id="searchKeyForMyProduct">
                </div>
                <div style="display: flex; align-items: center;">
                  <div class="vertical-divider"></div>
                  <div class="dropdown">
                    <span style="width: 45% !important;z-index: 2;" class="tenant-cl btn btn-primary dropdown-toggle menu-dropdown-contents"
                      type="button" data-toggle="dropdown">
                      <span class="menu-btn">
                        <span class="menu-btn-text username" title="{{categoryFilterForActiveProduct}}">
                          {{getFilterName(categoryFilterForActiveProduct)}} </span>
                      </span>
                      <em class="fa fa-angle-down white menu-cal-f"  tabindex="0"  style="font-size: 20px; margin-left: 10px;"></em>
                    </span>
                    <ul class="dropdown-menu">
                      <button type="button" title="{{data}}" class="btn menu-item-btn" [ngClass]="{'filter-log-active': categoryFilterForActiveProduct === data}"
                        *ngFor="let data of filterListForMyProducts" (click)="setPage(); getAllActiveProducts(data); categoryFilterForActiveProduct = data;">
                        {{getFilterName(data)}}</button>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="hide-layout" style="z-index: 999;">
                <img class="table-btn" *ngIf="isCardView" src="../../assets/images/table_view_inactive.png" alt="Switch To Table View" (click)="isCardView=false" tabindex="0" (keypress)="isCardView=false" />
                <img class="card-btn" *ngIf="!isCardView" src="../../assets/images/card_view_inactive.png" alt="Switch To Card View" (click)="isCardView=true" tabindex="0" (keypress)="isCardView=true" />
              </div>
            </div>
          </div>

          <div>
            <div *ngIf="isCardView">
              <div class="my-product-container" style="margin-bottom: 40px;" *ngIf="activeProductsList && activeProductsList.length > 0 && !loader">
                <div class="my-product-card" tabindex="0" *ngFor="let activeProduct of activeProductsList" (keypress)="openProductDetails(activeProduct)" (click)="openProductDetails(activeProduct)">
                  <div class="card-header">
                    <span class="product-title">$ {{activeProduct.budgetConsumed}} used</span>
                    <div class="product-status-layout">
                      <span class="text-white font-class status-text">{{getStatus(activeProduct.Status)}}</span>
                      <div class="status-indicator {{getProductStatusColor(activeProduct.Status)}}"></div>
                    </div>
                  </div>
                  <div class="card-info-layout">
                    <img class="product-img" src="{{activeProduct.imageUrl}}" alt="My Product {{ activeProduct.ProvisionedProductName }}" />
                    <div>
                      <label title="{{activeProduct.ProvisionedProductName}}" class="text-white font-class max-lines product-name">{{ nameTrim(activeProduct.ProvisionedProductName) }}</label>
                      <label title="{{activeProduct.ProductName}}" class="font-class max-lines product-name-type">{{ nameTrimData(activeProduct.ProductName) }}</label>
                    </div>
                  </div>
                  <div class="shared-layout" *ngIf="activeProduct.isShared === true">
                    <div class="share-data" title="This folder is currently shared">
                      <img class="share-icon" src="../../assets/images/share-white.png" alt="Shared" />
                      <span class="share-icon-text" style="margin-left: 10px;"> Shared </span>
                    </div>
                    <div class="view-details-layout">
                      <span class="view-details-text">Last update {{activeProduct.updated_on | amTimeAgo}}</span>
                    </div>
                  </div>
                  <div *ngIf="activeProduct.isShared !== true && activeProduct.isProjectStorage != true && activeProduct.isIngressStorage != true" class="view-details-wrapper">
                    <div class="view-details-layout">
                      <span class="view-details-text">Last update {{activeProduct.updated_on | amTimeAgo}}</span>
                    </div>
                  </div>
                  <div class="shared-layout" *ngIf="activeProduct.isShared !== true && activeProduct.isProjectStorage == true">
                      <div class="share-data" style="width: 45% !important;">
                          <i class="fa fa-hdd-o" style="font-size: 2.1em; color: #FFFFFF; padding: 0px !important; width: 25px;vertical-align: middle;margin-right: 5px;" aria-hidden="true"></i>
                          <span class="share-icon-text"> Project Storage </span>
                        </div>
                    <div class="view-details-layout" style="margin-right: 20px !important; margin-left: 0px !important">
                      <span class="view-details-text">Last update {{activeProduct.updated_on | amTimeAgo}}</span>
                    </div>
                  </div>
                  <div class="shared-layout" *ngIf="activeProduct.isShared !== true && activeProduct.isIngressStorage == true">
                    <div class="share-data" style="width: 45% !important;">
                        <i class="fa fa-hdd-o" style="font-size: 2.1em; color: #FFFFFF; padding: 0px !important; width: 25px;vertical-align: middle;margin-right: 5px;" aria-hidden="true"></i>
                        <span class="share-icon-text"> Ingress Storage </span>
                      </div>
                  <div class="view-details-layout" style="margin-right: 20px !important; margin-left: 0px !important">
                    <span class="view-details-text">Last update {{activeProduct.updated_on | amTimeAgo}}</span>
                  </div>
                </div>
                </div>
              </div>
              <div *ngIf="activeProductsList && activeProductsList.length === 0 && !loader && !gotMyProductsData" class="empty-my-product-layout">
                <div>
                  <h3 class="no-data-found text-white font-class text-center">{{noDataTemplate}}</h3>
                  <!-- <h4 class="no-data-found-subtext text-white font-class text-center">{{noDataMsg}}</h4> -->
                </div>
              </div>
            </div>
            <div *ngIf="!isCardView">
              <div class="table-responsive" *ngIf="activeProductsList && activeProductsList.length > 0 && !loader">
                <div class="table-header">
                  <div class="my-product-name-header" style="width: 25%;">
                    <span class="table-title-text font-class">Product Name</span>
                  </div>
                  <div class="my-product-header" style="width: 15%;">
                    <span class="table-title-text font-class">Catalog Item</span>
                  </div>
                  <div class="my-product-header" style="width: 10%;">
                    <span class="table-title-text font-class">Cost (USD)</span>
                  </div>
                  <div class="my-product-header" style="width: 20%;padding-left: 5px;">
                    <span class="table-title-text font-class">Updated On</span>
                  </div>
                  <div class="my-product-header" style="width: 10%;padding-left: 10px;">
                    <span class="table-title-text font-class">Status</span>
                  </div>
                  <div class="my-product-header" style="width: 10%;padding-left: 52px;">
                    <span class="table-title-text font-class">Actions</span>
                  </div>
                </div>
                <table class="table" style="width: 100%; margin-top: 10px; margin-bottom: 60px;">
                  <tbody>
                    <tr class="table-body-separator" *ngFor="let myProduct of activeProductsList">
                      <td style="width: 25%; padding-top: 15px !important;">
                        <div>
                          <img class="table-body-icon" src="{{myProduct.imageUrl}}" alt="My Product {{ myProduct.ProvisionedProductName }}" />
                          <span class="table-body-description" *ngIf="myProduct.isShared === true" style="margin-left: 10px;width: 30px;display: inline-table;position: absolute;
                                margin-top: 5px;" title="Shared">
                            <img class="share-icon" src="../../assets/images/share-white.png" alt="Shared" />
                          </span>
                          <span class="table-body-description" *ngIf="myProduct.isProjectStorage === true" style="margin-left: 10px;width: 30px;display: inline-table;position: absolute;
                          margin-top: 5px;" title="Project Storage">
                          <i class="fa fa-hdd-o" style="font-size: 1.8em; color: #fff;padding-top: 0px !important;vertical-align: middle;" aria-hidden="true"></i>
                         </span>
                          <span class="table-body-name font-class" style="font-size: 12px !important" title="{{myProduct.ProvisionedProductName}}">{{getActiveProductName(myProduct.ProvisionedProductName)}}</span>
                        </div>
                      </td>
                      <td style="width: 15%;">
                        <div>
                          <span class="my-product-value font-class">{{myProduct.ProductName}}</span>
                        </div>
                      </td>
                      <td style="width: 10%;">
                        <div>
                          <span class="my-product-value font-class">{{myProduct.budgetConsumed ? myProduct.budgetConsumed : 0.00}}</span>
                        </div>
                      </td>
                      <td style="width: 20%;">
                        <div>
                          <span class="my-product-value font-class">{{myProduct.updated_on | date:'medium'}}</span>
                        </div>
                      </td>
                      <td>
                        <div style="display: flex;">
                          <span class="table-body-description">{{getStatus(myProduct.Status)}}</span>
                          <div class="table-body-launch-btn" tabindex="0" (keypress)="openProductDetails(myProduct)" (click)="openProductDetails(myProduct)">
                            <span class="table-body-launch-text font-class">View Details</span>
                            <img style="height: 15px; width: 15px; margin-left: 20px;" src="../../assets/images/right-arrow.png" alt="Click Here For Product Details Page" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div *ngIf="activeProductsList && activeProductsList.length === 0 && !loader && !gotMyProductsData" class="empty-my-product-layout">
                <div>
                  <h3 class="no-data-found text-white font-class text-center">{{noDataTemplate}}</h3>
                  <!-- <h4 class="no-data-found-subtext text-white font-class text-center">{{noDataMsg}}</h4> -->
                </div>
              </div>
            </div>
            <div class="product-avail" *ngIf="!loader && activeProductsList.length > 0">
              <span class="page-count-text">{{ activeProductCurrentPageLowerCountActive }} - {{ activeProductCurrentPageUpperCountActive }} of {{ totalProductsActive
                }}</span>
              <div class="pagination-wrapper">
                <img class="pagination-next-btn" [ngClass]="{'disable-first-btn':activeProductCurrentPage === 1 }" src="../../assets/images/pagination-first-active.png" alt="First Page" [attr.tabindex]="activeProductCurrentPage === 1 ? -1 : 0" [attr.aria-disabled]="activeProductCurrentPage === 1 ? 'true' : 'false'"
                  (click)="getNextActiveProducts('first')" (keypress)="getNextActiveProducts('first')" />
                <img class="pagination-next-btn" [ngClass]="{'disable-prev-btn':activeProductCurrentPage === 1 }" src="../../assets/images/pagination-prev-active.png" alt="Previous Page" [attr.tabindex]="activeProductCurrentPage === 1 ? -1 : 0" [attr.aria-disabled]="activeProductCurrentPage === 1 ? 'true' : 'false'"
                  (click)="getNextActiveProducts('previous')" (keypress)="getNextActiveProducts('previous')" />
                <img class="pagination-next-btn" [ngClass]="{'disable-next-btn':activeProductCurrentPage === activeProductTotalPages}" src="../../assets/images/pagination-next-active.png" alt="Next Page" [attr.tabindex]="activeProductCurrentPage === activeProductTotalPages ? -1 : 0" [attr.aria-disabled]="activeProductCurrentPage === activeProductTotalPages ? 'true' : 'false'"
                  (click)="getNextActiveProducts('next')" (keypress)="getNextActiveProducts('next')" />
                <img class="pagination-next-btn" [ngClass]="{'disable-last-btn':activeProductCurrentPage === activeProductTotalPages}" src="../../assets/images/pagination-last-active.png" alt="Last Page" [attr.tabindex]="activeProductCurrentPage === activeProductTotalPages ? -1 : 0" [attr.aria-disabled]="activeProductCurrentPage === activeProductTotalPages ? 'true' : 'false'"
                  (click)="getNextActiveProducts('last')" (keypress)="getNextActiveProducts('last')" />
              </div>
            </div>
          </div>
      </mat-tab>
      <mat-tab label="All Products" *ngIf="checkIfResearcherIsPartOfProject">
        <div class="project-title-layout">
          <h3 class="box-title text-white font-class"> All Products
            <h3 class="box-title text-white font-class" *ngIf="!loader && allProductsList.length > 0">&nbsp;( {{allProductCount}} )</h3>
          </h3>
          <div style="display: flex; justify-content: center; align-items: center;">
            <div class="search-div">
              <div style="display: flex;">
                <em class="glyphicon glyphicon-search search-icon"></em>
                <label for="searchKeyForAllProduct" class="visually-hidden">searchKeyForAllProduct</label>  
                <input (keyup)="allProductsSearch()" [(ngModel)]="searchKeyForAllProduct" class="form-control search-inp search-inp-add"
                  placeholder='Search in all products' type="text" name="searchKeyForAllProduct" id="searchKeyForAllProduct">
              </div>
              <div style="display: flex; align-items: center;">
                <div class="vertical-divider"></div>
                <div class="dropdown">
                  <span style="width: 45% !important;z-index: 2;" class="tenant-cl btn btn-primary dropdown-toggle menu-dropdown-contents"
                    type="button" data-toggle="dropdown">
                    <span class="menu-btn">
                      <span class="menu-btn-text username" title="{{categoryFilterForActiveProduct}}">
                        {{getFilterName(categoryFilterForActiveProduct)}} </span>
                    </span>
                    <em class="fa fa-angle-down white menu-cal-f" tabindex="0" style="font-size: 20px; margin-left: 10px;"></em>
                  </span>
                  <ul class="dropdown-menu">
                    <button type="button" title="{{data}}" class="btn menu-item-btn" [ngClass]="{'filter-log-active': categoryFilterForActiveProduct === data}"
                      *ngFor="let data of filterListForMyProducts" (click)="setPage(); getProjectwiseProvisionedProducts(data); categoryFilterForActiveProduct = data;">
                      {{getFilterName(data)}}</button>
                  </ul>
                </div>
              </div>
            </div>           
          </div>
        </div>
        <div>
          <div class="table-responsive" *ngIf="allProductsList && allProductsList.length > 0 && !loader">
            <div class="table-header">
              <div class="my-product-name-header" style="width: 25%;">
                <span class="table-title-text font-class">Product Name</span>
              </div>
              <div class="my-product-header" style="width: 15%;">
                <span class="table-title-text font-class">Catalog Item</span>
              </div>
              <div class="my-product-header" style="width: 5%;">
                <span class="table-title-text font-class">Cost (USD)</span>
              </div>
              <div class="my-product-header" style="width: 15%;padding-left: 6px;">
                <span class="table-title-text font-class">Updated On</span>
              </div>
              <div class="my-product-header" style="width: 10%;padding-left: 11px;">
                <span class="table-title-text font-class">Status</span>
              </div>
              <div class="my-product-header" style="width: 15%;padding-left: 13px;">
                <span class="table-title-text font-class">Owner</span>
              </div>
              <div class="my-product-header" style="width: 10%;padding-left: 20px;">
                <span class="table-title-text font-class">Actions</span>
              </div>
            </div>
            <table class="table" style="width: 100%; margin-top: 10px; margin-bottom: 60px;">
              <tbody>
                <tr class="table-body-separator" *ngFor="let allProduct of allProductsList">
                  <td style="width: 25%; padding-top: 15px !important;">
                    <div>
                      <img class="table-body-icon" src="{{allProduct.imageUrl}}" alt="All Products {{allProduct.ProductName}}"/>
                      <span class="table-body-description" *ngIf="allProduct.isShared === true" style="margin-left: 10px;width: 30px;display: inline-table;position: absolute;
                            margin-top: 5px;" title="Shared">
                        <img class="share-icon" src="../../assets/images/share-white.png" alt="Shared" />
                      </span>
                      <span class="table-body-description" *ngIf="allProduct.isProjectStorage === true" style="margin-left: 10px;width: 30px;display: inline-table;position: absolute;
                      margin-top: 5px;" title="Project Storage">
                      <i class="fa fa-hdd-o" style="font-size: 1.8em; color: #fff;padding-top: 0px !important;vertical-align: middle;" aria-hidden="true"></i>
                     </span>
                      <span class="table-body-name font-class" style="font-size: 12px !important" title="{{allProduct.ProvisionedProductName}}">{{getActiveProductName(allProduct.ProvisionedProductName)}}</span>
                    </div>
                  </td>
                  <td style="width: 15%;">
                    <div>
                      <span class="my-product-value font-class">{{allProduct.ProductName}}</span>
                    </div>
                  </td>
                  <td style="width: 5%;">
                    <div>
                      <span class="my-product-value font-class">{{allProduct.budgetConsumed ? allProduct.budgetConsumed : 0.00}}</span>
                    </div>
                  </td>
                  <td style="width: 15%;">
                    <div>
                      <span class="my-product-value font-class">{{allProduct.updated_on | date:'medium'}}</span>
                    </div>
                  </td>
                  <td style="width: 10%;">
                    <div>
                      <span class="my-product-value font-class">{{getStatus(allProduct.Status)}}</span>
                    </div>
                  </td>
                  <td style="width: 15%;">
                    <div>
                      <span [title]="allProduct.user" *ngIf="(allProduct.user).length > 25;" class="my-product-value font-class">{{(allProduct.user).slice(0,20).concat("...")}}</span>
                      <span [title]="allProduct.user" *ngIf="(allProduct.user).length <= 25;" class="my-product-value font-class">{{(allProduct.user)}}</span>
                    </div>
                  </td>
                  <td>
                    <div style="display: flex;">
                      <div class="table-body-launch-btn">                        
                        <div>
                          <div tabindex="0" class="fa fa-ellipsis-v" *ngIf="allProduct.user !== 'SYSTEM' && allProduct.Status !== 'DELETE_COMPLETE' && allProduct.Status !== 'Transitioning' && allProduct.Status !== 'DELETE_IN_PROGRESS' && allProduct.Status !== 'CREATE_IN_PROGRESS'" data-toggle="dropdown" title="Action" aria-hidden="true" style="color: #f1f1f1; cursor: pointer; padding: 0px 9px;">
                          </div>
                          <ul class="dropdown-menu" >
                              <li><a class="listItems" *ngIf="allProduct.Status == 'Active' && allProduct.ProductName !== 'Amazon S3'" tabindex="0" (keypress)="actions(allProduct, 'Stop')" (click)="actions(allProduct, 'Stop')" >Stop</a></li>
                              <li><a class="listItems" tabindex="0" (keypress)="actions(allProduct, 'Terminate')" (click)="actions(allProduct, 'Terminate')" >Terminate</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="allProductsList && allProductsList.length === 0 && !loader && !gotMyProductsData" class="empty-my-product-layout">
            <div>
              <h3 class="no-data-found text-white font-class text-center">{{noDataTemplate}}</h3>
              <!-- <h4 class="no-data-found-subtext text-white font-class text-center">{{noDataMsg}}</h4> -->
            </div>
          </div>
          <div class="product-avail" *ngIf="!loader && allProductsList.length > 0">
            <span class="page-count-text">{{ allProductCurrentPageLowerCountActive }} - {{ allProductCurrentPageUpperCountActive }} of {{ allProductCount }}</span>
            <div class="pagination-wrapper">
              <img class="pagination-next-btn" [ngClass]="{'disable-first-btn':allProductCurrentPage === 1 }" src="../../assets/images/pagination-first-active.png" alt="First Page" [attr.tabindex]="allProductCurrentPage === 1 ? -1 : 0"  [attr.aria-disabled]="allProductCurrentPage === 1 ? 'true' : 'false'"
                (click)="getNextAllProducts('first')" (keypress)="getNextAllProducts('first')" />
              <img class="pagination-next-btn" [ngClass]="{'disable-prev-btn':allProductCurrentPage === 1 }" src="../../assets/images/pagination-prev-active.png" alt="Previous Page" [attr.tabindex]="allProductCurrentPage === 1 ? -1 : 0" [attr.aria-disabled]="allProductCurrentPage === 1 ? 'true' : 'false'" 
                (click)="getNextAllProducts('previous')" (keypress)="getNextAllProducts('previous')" />
              <img class="pagination-next-btn" [ngClass]="{'disable-next-btn':allProductCurrentPage === allProductTotalPages}" src="../../assets/images/pagination-next-active.png" alt="Next Page" [attr.tabindex]="allProductCurrentPage === allProductTotalPages ? -1 : 0" [attr.aria-disabled]="allProductCurrentPage === allProductTotalPages ? 'true' : 'false'"
                (click)="getNextAllProducts('next')" (keypress)="getNextAllProducts('next')" />
              <img class="pagination-next-btn" [ngClass]="{'disable-last-btn':allProductCurrentPage === allProductTotalPages}" src="../../assets/images/pagination-last-active.png" alt="Last Page" [attr.tabindex]="allProductCurrentPage === allProductTotalPages ? -1 : 0" [attr.aria-disabled]="allProductCurrentPage === allProductTotalPages ? 'true' : 'false'"
                (click)="getNextAllProducts('last')" (keypress)="getNextAllProducts('last')" />
            </div>
          </div>
        </div>
      </mat-tab>

    </mat-tab-group>
  </div>

  <!-- mobile responsive --> 
  <div class="responsiveTab">
    <mdl-tabs (mdl-tab-active-changed)="tabChanged($event)">
        <mdl-tab-panel style="width: 100%">
            <mdl-tab-panel-title>
              <span class="text-white" style="text-transform: none;font-size: 11px">Project Details</span>
            </mdl-tab-panel-title>
            <mdl-tab-panel-content>
                <div style="display: block; position: relative;">
                  <div class="product-details-wrapper" *ngFor="let project of projects">
                    <div style="display: flex; flex-direction: column;">
                      <label style="color: #85D4FF; font-size: 16px; font-weight: 500; margin-top: 20px;" class="font-class">Project Name &ensp;<span *ngIf="dependencyChecksStatus === 'Failed' && projectStatus !== 'Creating'"><img style="height: 16px;margin-bottom: 6px" src="../../assets/images/alert-128.png"></span>
                        <span class="no-access" *ngIf="!checkIfResearcherIsPartOfProject" title="You don't have access to this project. Please contact the project owner {{project.created_by}}" >
                          No Access
                        </span>
                      </label>
                      <label style="font-size: 14px; font-weight: 400;" class="text-white font-class">{{project.name}}</label>
                      <a *ngIf="dependencyChecksStatus === 'Failed' && projectStatus !== 'Creating'" style="color: #cc3300; font-size: 11px;">{{failedReason}}</a>
                    </div>
                    <div style="display: flex; flex-direction: column;">
                      <label style="color: #85D4FF; font-size: 16px; font-weight: 500; margin-top: 20px;" class="font-class">Account ID</label>
                      <label style="font-size: 14px; font-weight: 400;" class="text-white font-class">{{project.account_name}}</label>
                    </div>
                    <div style="display: flex; flex-direction: column;">
                      <label style="color: #85D4FF; font-size: 16px; font-weight: 500; margin-top: 20px;" class="font-class">Created On</label>
                      <label style="font-size: 14px; font-weight: 400;" class="text-white font-class">{{project.created_on | date}}</label>
                    </div>
                    <div style="display: flex; flex-direction: column;">
                      <label style="color: #85D4FF; font-size: 16px; font-weight: 500; margin-top: 20px;" class="font-class">Project Owner</label>
                      <label style="font-size: 14px; font-weight: 400;" class="text-white font-class">{{project.created_by}}</label>
                    </div>
                    <div style="display: flex; flex-direction: column;width: 75%">
                      <h2 style="color: #85D4FF; font-size: 16px; font-weight: 500; margin-top: 20px;" class="font-class">
                        Assigned Users
                        <span tabindex="0" (click)="updateBTN=false" (keypress)="updateBTN=false" *ngIf="updateBTN && researcherIdList.length > 0 && checkIfResearcherIsPartOfProject" class="add-researcher-manage">
                          <i style=" width: auto; " class="fa fa-pencil" aria-hidden="true"></i>
                          Manage
                        </span>
                      </h2>
                      <p style="font-size: 14px; font-weight: 400;" class="text-white font-class" *ngIf="updateBTN">{{ researchName }}</p>
                      <p style="font-size: 14px; font-weight: 400;" class="text-white font-class" *ngIf="updateBTN && researchName.length == 0">
                        There are no additional collaborators on this project</p>
                      <div class="form-control researcher-selection-container" *ngIf="!updateBTN">
                        <span class="selection-header">Select users from the list</span>
                        <div class="selection" *ngFor="let researcher of researcherIdList; let i = index">
                          <input type="checkbox" [id]="researcher.value+'MobileView'+i" [checked]="validateResearcher(researcher.value)" [disabled]="validateProvisionedResearcher(researcher.value)"
                            name="researcher.value" (change)="EditResearcherId(researcher, $event)">
                          <label [ngClass]="{'uncheck-item': validateProvisionedResearcher(researcher.value) === true}" [for]="researcher.value+'MobileView'+i"
                            class="selection-label">{{researcher.viewValue}}</label>
                        </div>
                      </div>
                      <span *ngIf="!updateBTN" class="add-researcher-title">Users who have associated products cannot be unchecked.</span>
                      <div *ngIf="!updateBTN">
                        <button class="btn btn-primary edit-btn" tabindex="0" (keypress)="insertResearchers()" (click)="insertResearchers()" type="button" [disabled]="updateBTN">
                          Update list
                          <em class="fa fa-refresh fa-spin" *ngIf="showSpinner"></em>
                        </button>
                        <button class="btn btn-primary edit-cancel-btn" type="button" tabindex="0" (keypress)="updateBTN=true" (click)="updateBTN=true" [disabled]="updateBTN">
                          Cancel
                          <em class="fa fa-refresh fa-spin" *ngIf="showSpinner"></em>
                        </button>
                      </div>
                    </div>
                    <div style="display: flex; flex-direction: column;">
                      <label style="color: #85D4FF; font-size: 16px; font-weight: 500; margin-top: 20px;" class="font-class">Budget</label>
                      <label style="font-size: 14px; font-weight: 400;" class="text-white font-class">USD {{ project.budget }}
                        <span [ngClass]="getProjectThresholdClass(project)"> ( {{project.budgetConsumedPercentage}} % used )</span>
                      </label>
                    </div>
                    <div style="display: flex; flex-direction: column;width: 75%;margin-bottom: 20px;">
                      <label style="color: #85D4FF; font-size: 16px; font-weight: 500; margin-top: 20px;" class="font-class">Project Description</label>
                      <label style="font-size: 14px; font-weight: 400;" class="text-white font-class">{{project.description}}</label>
                    </div>
                  </div>
                  <div class="web-actions" *ngIf="projectStatus != 'Creating' && checkIfResearcherIsPartOfProject" style="background-color: #07102A; border-radius: 10px; margin: 20px; padding: 20px;">
                    <div style="display: flex; flex-direction: column">
                      <span style="color: #85D4FF; font-size: 14px; font-weight: 600; margin-top: 20px;" class="font-class">ACTIONS</span>
                      <div class="tab-flex">
                        <button class="action-link" title="Resume" *ngIf="projectStatus === 'Paused' || projectStatus === 'Stopped'" (click)="projectActions('Active', projectStatus)"
                          style="background-color: #15244B; border-radius: 5px; width: 100%; height: 45px; display: flex; align-items: center; margin-top: 10px;margin-right: 9px;cursor: pointer;">
                          <img style="height: 12px;height: 15px;margin-right: 15px;margin-left: 15px" src="../../assets/images/play@2x.png" alt="Resume Project"
                          />
                          <span style="font-size: 12px;cursor: pointer;" class="text-white font-class">Resume</span>
                       </button>
                        <button class="action-link" title="Pause" *ngIf="projectStatus === 'Active' " (click)="projectActions('Paused', projectStatus)"
                          style="background-color: #15244B; border-radius: 5px; width: 100%; height: 45px; display: flex; align-items: center; margin-top: 10px;margin-right: 9px;cursor: pointer;">
                          <i class="fa fa-pause" aria-hidden="true" style="color: #FFFFFF;width: 3px;height: 11px;margin-right: 20px;margin-left: 15px; margin-top: -10px;"></i>
                          <span style="font-size: 12px;cursor: pointer;" class="text-white font-class">Pause</span>
                       </button>
                        <button class="action-link" title="Stop" *ngIf="projectStatus === 'Active' " (click)="projectActions('Stopped', projectStatus)"
                          style="background-color: #15244B; border-radius: 5px; width: 100%; height: 45px; display: flex; align-items: center; margin-top: 10px;margin-right: 9px;cursor: pointer;">
                          <img style="height: 12px;height: 15px;margin-right: 15px;margin-left: 18px" src="../../assets/images/stop.png"
                            alt="Stop Project" />
                          <span style="font-size: 12px;cursor: pointer;" class="text-white font-class">Stop</span>
                       </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <app-loader class="spinner" *ngIf="loader"></app-loader> -->
              </mdl-tab-panel-content>
              </mdl-tab-panel>
              <mdl-tab-panel style="width: 100%">
                  <mdl-tab-panel-title>
                    <span class="text-white" style="text-transform: none;font-size: 11px">Events</span>
                  </mdl-tab-panel-title>
                  <mdl-tab-panel-content>
                      <div *ngIf="projectEvents.length === 0 && !loader && !gotEventsData">
                          <h4 style=" color: #fff;text-align: center;padding: 100px; font-size: 22px">
                            No Events found
                          </h4>
                        </div>
                        <div class="sm-layout xs-layout" *ngIf="projectEvents.length > 0 && !loader && gotEventsData">
                            <div class="project-cards-layout">
                              <div class="project-card" *ngFor="let data of projectEvents;">
                                <ul class="list-group">
                                  <div class="d-flex justify-content-between mb-2">
                                    <div class="project-card-title project-card-line-height font-class">Logical ID</div>
                                    <div class="project-card-project-name" title="{{data.productId}}">{{data.productId}}</div>
                                  </div>
                                  <div class="d-flex justify-content-between mb-2">
                                    <div class="project-card-title project-card-line-height font-class">Time Stamp</div>
                                    <div class="project-card-project-name">{{data.createdDate | date:'medium'}}</div>
                                  </div>
                                  <div class="d-flex justify-content-between mb-2">
                                    <div class="project-card-title project-card-line-height font-class">Status</div>
                                    <div class="project-card-project-name">{{data.action}}</div>
                                  </div>
                                  <div class="d-flex justify-content-between mb-2">
                                    <div class="project-card-title project-card-line-height font-class">Status Reason</div>
                                    <div class="project-card-project-name" >{{getprojectStatus(data.message)}}</div>
                                  </div>
                                </ul>
                              </div>
                            </div>
                            <div class="product-avail">
                              <span class="page-count-text">{{ eventsCurrentPageLowerCount }} - {{ eventsCurrentPageUpperCount }} of {{ eventsTotalRecords }}</span>
                              <div class="pagination-wrapper">
                                <img class="pagination-next-btn" [ngClass]="{'disable-first-btn':eventsPage === 1 }" src="../../assets/images/pagination-first-active.png" alt="First Page" (click)="getNextProjectEvents('first')"/>
                                <img class="pagination-next-btn" [ngClass]="{'disable-prev-btn':eventsPage === 1 }" src="../../assets/images/pagination-prev-active.png" alt="Previous Page" (click)="getNextProjectEvents('previous')"/>
                                <img class="pagination-next-btn" [ngClass]="{'disable-next-btn':eventsPage === eventsTotalPages}" src="../../assets/images/pagination-next-active.png" alt="Next Page" (click)="getNextProjectEvents('next')"/>
                                <img class="pagination-next-btn" [ngClass]="{'disable-last-btn':eventsPage === eventsTotalPages}" src="../../assets/images/pagination-last-active.png" alt="Last Page" (click)="getNextProjectEvents('last')"/>
                              </div>
                            </div>
                          </div>
                    </mdl-tab-panel-content>
                    </mdl-tab-panel>
                    <mdl-tab-panel style="width: 100%" *ngIf="checkIfResearcherIsPartOfProject">
                        <mdl-tab-panel-title>
                          <span class="text-white" style="text-transform: none;font-size: 11px">Available Products</span>
                        </mdl-tab-panel-title>
                        <mdl-tab-panel-content>
                          </mdl-tab-panel-content>
                          </mdl-tab-panel>
                          <mdl-tab-panel style="width: 100%" *ngIf="checkIfResearcherIsPartOfProject">
                              <mdl-tab-panel-title>
                                <span class="text-white" style="text-transform: none;font-size: 11px">My Products</span>
                              </mdl-tab-panel-title>
                              <mdl-tab-panel-content>
                                </mdl-tab-panel-content>
                                </mdl-tab-panel>
                                <mdl-tab-panel style="width: 100%" *ngIf="checkIfResearcherIsPartOfProject">
                                  <mdl-tab-panel-title>
                                    <span class="text-white" style="text-transform: none;font-size: 11px">All Products</span>
                                  </mdl-tab-panel-title>
                                  <mdl-tab-panel-content>
                                    </mdl-tab-panel-content>
                                    </mdl-tab-panel>      
      </mdl-tabs>
  </div>
</div>
<app-loader class="spinner" *ngIf="loader"></app-loader>
