<div class="container successMsg" style="margin-top: 75px" *ngIf="showSuccessMsg">
  <img class="success-icon" src="../../assets/images/success@2x.png" alt="Verification Mail Sent Successfully."/>
  <h3 class="successDataMsg">
    Verification mail sent successfully.
    </h3>
  <h4 class="linkMsg" style="margin-top: 15px !important">
    Please check your registered mail.
    </h4>
</div>

<app-loader class="spinner" *ngIf="showSpinner"></app-loader>

<div class="container ErrorMsg" style="margin-top: 75px" *ngIf="!showSuccessMsg && showErrorMsg">
  <img class="success-icon" src="../../assets/images/icons/error@2x.png" alt="Verification Mail Does Not Sent"/>
  <h3 class="successDataMsg">
    Oops ! Something went wrong.
    </h3>
  <h4 class="linkMsg-err" style="margin-top: 15px !important">
    Your RL Research Gateway account verification was unsuccessful, please try again.
    </h4>
    <div class="row" style="margin-bottom: 30px">
      <div class="col-xs-8">
      </div>
      <div class="col-xs-4">
        <button type="submit" class="btn btn-dark btn-block btn-flat submit-btn" (click)="sendVerification()">Resend link</button>
      </div>
    </div>
</div>
