import {Component, Inject, OnInit} from '@angular/core';
import {MdlDialogReference} from "@angular-mdl/core";
import {ActivatedRoute, Router} from "@angular/router";
import {ResearchService} from "../../_services/research.service";
import { DomSanitizer } from '@angular/platform-browser';
import {ToastrService} from "ngx-toastr";
import {Globals} from "../../globals";

@Component({
  selector: 'app-create-keypair',
  templateUrl: './create-keypair.component.html',
  styleUrls: ['./create-keypair.component.css'],
  providers: [Globals]
})
export class CreateKeypairComponent implements OnInit {

  constructor(
    @Inject('data') public data: any,
    private router: Router,
    private researchService: ResearchService, private toastr: ToastrService,
    private activatedRoute: ActivatedRoute, public dialogRef: MdlDialogReference,
    private sanitizer: DomSanitizer, private globals: Globals
  ) { }
  accountId;
  showSpinner = false;
  projectList = [];
  keypairName;
  pemFile;
  keyName = '';
  keyValue = '';
  fileUrl;
  fileFormat;
  projectId;
  pemFileCheck;
  projectIds = [];
  tags = [];
  service = null;
  ngOnInit() {
    console.log(this.data);
    if(this.data.hasOwnProperty('services')){
      if(this.data.services === 'order'){
        this.service = 'order';
      }
    }
    this.getResearchProject();
  }

  private getResearchProject() {
    try {
      this.showSpinner = true;
      this.researchService.getProjects().subscribe((res:any) => {
        this.projectList = res;
        this.showSpinner = false;
      }, error =>{
        this.showSpinner = false;
        if(error && error.status === 401){
          let errBody = error.error;
           this.toastr.error(errBody.err, '', this.globals.tosterOverride);
           this.router.navigate(['login']);
         }
      }
      );
    } catch (e) {
      this.showSpinner = false;
    }
  }

  insertKeypair() {
    const keypairName = this.keypairName.trim() || '';
    if (keypairName.length > 255) {
      return;
    }
    const projectObj = this.projectList.find((e) => e._id === this.projectId);
    const projectId = projectObj._id;
    const projectName = projectObj.name;
    const projectAccountId = projectObj.account_id;
    if (!projectAccountId || !this.keypairName || !projectId) {
      return;
    }
    const payload = { accountId : projectAccountId, KeyName : keypairName, projectId, tags : this.tags, projectName };
    const fileName = this.pemFileCheck;
    // if (this.pemFileCheck === false) {
    //   fileName = 'ppk-file';
    // }
    this.keypairName = keypairName;
    this.showSpinner = true;
    this.researchService.createKeypair(payload).subscribe((res:any) => {
      const data = res.data.KeyMaterial;
      const blob = new Blob([data], { type: 'application/octet-stream' });

      this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
      const element: HTMLElement = document.getElementById(fileName) as HTMLElement;
      setTimeout(() => {
        element.click();
        this.dialogRef.hide();
        this.showSpinner = true;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        if(this.service === 'order'){
          this.researchService.sendKeypairCreateEvent();
        } else {
          this.router.navigate([`keypair/${this.keypairName}/success/createSuccess`]);
        }
        this.toastr.success(`Created keypair successfully`, '', this.globals.tosterOverride);
        }, 1000);
    }, error => {
      this.dialogRef.hide();
      this.showSpinner = false;
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
      else{const err = error.error;
        this.toastr.error(`${this.keypairName} - A key pair with this name already exists in the AWS account. Please use another name. If you would like to import the key pair into Research Gateway, please consult the documentation.`, 'Error', this.globals.tosterOverride);
      }
    });
  }

  cancel(): void {
    this.dialogRef.hide();
  }

  removeTag(tag) {
    const index = this.tags.indexOf(tag);
    if (index > -1) {
      this.tags.splice(index, 1);
    }
  }

  addTag() {
    if (!this.keyName.trim() || !this.keyValue.trim()) {
      return;
    }
    const obj = {
      Key  : this.keyName,
      Value : this.keyValue
    };
    this.tags.push(obj);
    this.keyName = '';
    this.keyValue = '';
  }

  isASCII(str) {
    return /^[\x00-\x7F]*$/.test(str);
  }
}
