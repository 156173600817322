import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { ResearchService} from "../../_services/research.service";
import { DashboardService } from '../../_services/dashboard.service';
import { Router } from "@angular/router";
import { MdlDialogService } from "@angular-mdl/core";
import { ActivatedRoute } from '@angular/router';
import {Globals} from "../../globals";

@Component({
  selector: 'app-principal',
  templateUrl: './budget-details.component.html',
  styleUrls: ['./budget-tracking.component.css', '../../product-details/product-details.component.css', './budget-details.component.css', '../../app.component.css'],
  providers: [Globals]
})
export class BudgetDetailsComponent implements OnInit {
  projectDetails;
  projectId;
  budgetAllotted: any = 0;
  budgetConsumed: any = 0;
  budgetAvailable: any = 0;
  projectCurrentMonthCost: any = 0;
  currencySymbol: string = '$';
  researchers = [];
  researcherProducts = [];
  products = [];
  budgetData;
  budgetCal;
  projectImg;
  projectName;
  projectType
  projects = [];

  private sub: any;

  loader: boolean = true;
  constructor(private dialog: MdlDialogService, private toastr: ToastrService,
              private router: Router, private researchService: ResearchService,
              private apiService: DashboardService,
              private route: ActivatedRoute,
              private location: Location,
              private globals: Globals,

  ) {}

  ngOnInit() {
    this.apiService.checkIfPI();
    this.projectId = this.route.snapshot.params['id']
    this.getResearcherBudget();
    this.getProjects();
  }


  goBack() {
    this.location.back();
  }

  getResearcherBudget() {
    this.loader = true;
    this.researchService.getResearcherListFromProjectId(this.projectId).subscribe((data:any) => {
      this.loader = false;
      this.researchers = data;
      this.budgetData = `Researcher Available Budget = Allocated Budget - Researcher Consumed Budget - ((Project Consumed Budget - Total Researcher Consumed Budget) / Total Number Of Researchers)\n`;
      this.researchers.forEach(item => {
        if(item.productList.length > 0){
          item.productsInString = item.productList.toString()
        }else {
          item.productsInString = "No products configured"
        }
        item.budgetCal = `${item.budgetAllocated} - ${ parseFloat(item.budgetConsumed).toFixed(2)} - ((${ parseFloat(item.projectConsumedBudget).toFixed(2)} - ${ parseFloat(item.totalResearcherConsumedBudget).toFixed(2)})/ ${item.totalNumberOfResearchers})`
      })
    }, error => {
      this.loader = false;
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
      console.log(error)
    });
  }

  researcherName(name) {
    // let splitName = name.split('/');
    return name
  }

  researcherBudgetAvailable(researcher) {
    let value;
    if(researcher.budgetConsumed){
      value = researcher.budgetAllocated - researcher.budgetConsumed;
    }else{
      value = researcher.budgetAllocated;
    }
    return value;
  }

  openProductBudgetScreen(researcher){
    this.router.navigate(['/principal/project/researcher/' + this.projectId + '/details'], { queryParams: { researcher: researcher.name, displayName: researcher.displayName}});
  }

  exportCSV(){
    this.toastr.error("Coming Soon!");
  }

  getRoundOffBudget(data){
    return parseFloat(data).toFixed(2)
  }

  getProjects() {
    this.researchService.getPrincipalProjectsUsingId(this.projectId).subscribe((data:any) => {
      this.projects = data;
      this.projectImg = data[0].img_src;
      this.projectName = data[0].name;
      this.projectType = data[0].project_type;

      this.getBudgetCardsData();
    }, error => {
      this.loader = false;
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }else if (error && error.status === 403) {
          const err = error.error;
          this.toastr.error(err.error, 'Error', this.globals.tosterOverride);
          this.router.navigate(['pageNotFound']);
      }
    });
  }

  getBudgetCardsData(){
    let allotted : any = 0;
    let consumed : any = 0;
    // let available : any = 0;
    let currentMonthCost : any = 0;
    this.projects.forEach((project) => {
      // Safe navigation on all keys
      if(project.budget){
        allotted += project.budget
      }
      let budgetConsumed = project.budgetConsumed;
      if (budgetConsumed) {
        let projectBudgetConsumed = budgetConsumed['projectBudgetConsumed']
        if (projectBudgetConsumed) {
          consumed += projectBudgetConsumed;
        }
      }
      let today = new Date();
      let currentMonth = today.getMonth() + 1;
      let currentYear = today.getFullYear();
      if (budgetConsumed.monthlyProjectBudgetConsumed && budgetConsumed.monthlyProjectBudgetConsumed.length > 0) {
        let currentMonthProjectCost = budgetConsumed.monthlyProjectBudgetConsumed.filter((monthCostByProject) => {
          return currentMonth == monthCostByProject.month && currentYear == monthCostByProject.year;
        });
        if (currentMonthProjectCost.length > 0) {
          currentMonthCost = currentMonthProjectCost[0].cost;
        }
      }
      this.budgetAllotted = parseFloat(allotted).toFixed(2);
      this.budgetConsumed = parseFloat(consumed).toFixed(2);
      this.projectCurrentMonthCost = parseFloat(currentMonthCost).toFixed(2);
    });
  }

}
