<div class="container">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb bc-custom">
      <li class="breadcrumb-item"><a class="bc-item" routerLink="/researcher"> My Projects </a></li>
      <li class="breadcrumb-item" aria-current="page"><a class="bc-item" (click)="navigateToProject()"> {{ projectName }} </a></li>
      <li class="breadcrumb-item active bc-item-active" aria-current="page">{{ categoryName }}</li>
    </ol>
  </nav>
  <div class="project-title-layout">
    <h3 class="box-title text-white font-class">{{categoryName}}<h3 class="box-title text-white font-class" *ngIf="category === 'active' && !loader">&nbsp;( {{activeProductCount}} )</h3></h3>
    <div style="display: flex; justify-content: center; align-items: center;">
<!--      <form class="navbar-form" role="search">-->
<!--        <div class="input-group add-on">-->
<!--          <input [(ngModel)]="searchKey" class="form-control search-inp" placeholder="Search" name="srch-term" id="srch-term" type="text">-->
<!--          <div class="input-group-btn">-->
<!--            <button (click)="availableSearchProduct()" class="btn btn-default search-btn" type="submit"><em class="glyphicon glyphicon-search"></em></button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </form>-->
      <!-- <div class="search-div">
        <input (keyup)="availableSearchProduct()" [(ngModel)]="searchKey" class="form-control search-inp search-inp-add" placeholder="Type a product name to search" type="text">
        <em class="glyphicon glyphicon-search search-icon"></em>
      </div> -->
      <div class="search-div">
        <div style="display: flex;">
          <em class="glyphicon glyphicon-search search-icon"></em>
          <input aria-label="SearchForAvailableProducts" (keyup)="availableSearchProduct()" [(ngModel)]="searchKey" class="form-control search-inp search-inp-add" placeholder={{placeHolder}} type="text">
        </div>
        <div style="display: flex; align-items: center;">
          <div class="vertical-divider"></div>
            <div class="custom-select-wrapper">
                <div class="custom-select">
                    <div class="custom-select__trigger"><span class="filterData" title="{{categoryFilter}}">{{getFilterName(categoryFilter)}}</span>
                        <div class="arrow"></div>
                    </div>
                    <div class="custom-options">
                        <!-- <span class="custom-option" [ngClass]="{'filter-log-active': selectedItem === 'all'}"  data-value="all" (click)="selectedItem = 'all'; setPage(); getAllActiveProducts('all'); categoryFilter = 'All'; setPage()">All</span> -->
                        <span title="{{data}}" class="custom-option" [ngClass]="{'filter-log-active': categoryFilter === data}" *ngFor="let data of filterList" data-value="data" (click)="setPage(); getAllActiveProducts(data); categoryFilter = data;">{{getFilterName(data)}}</span>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <div class="hide-layout">
        <img class="table-btn" alt="Switch To Table View" *ngIf="isCardView" src="../../assets/images/table_view_inactive.png" (click)="isCardView=false" />
        <img class="card-btn" alt="Switch To Card View" *ngIf="!isCardView" src="../../assets/images/card_view_inactive.png" (click)="isCardView=true" />
      </div>
    </div>
  </div>
  <div class="mobile-project-title-layout">
    <div style="margin-top: 25px; margin-bottom: 25px;">
      <h4 class="box-title text-white font-class">{{categoryName}}<h3 class="box-title text-white font-class" *ngIf="category === 'active' && !loader">&nbsp;( {{activeProductCount}} )</h3></h4>
      <div class="available-product-divider"></div>
       <!-- mobile responsive -->
    <div class="mobile-divider"></div>
      <div class="mobile-search-div">
          <div style="display: flex;">
            <em class="glyphicon glyphicon-search search-icon"></em>
            <input  aria-label="SearchForAvailableProducts"  (keyup)="availableSearchProduct()" [(ngModel)]="searchKey" class="form-control search-inp search-inp-add" placeholder={{placeHolder}} type="text">
          </div>
          <div style="display: flex; align-items: center;">
            <div class="vertical-divider"></div>
              <div class="mobile-custom-select-wrapper">
                  <div class="mobile-custom-select">
                      <div class="mobile-custom-select__trigger"><span>{{getFilterName(categoryFilter)}}</span>
                          <div class="mobile-arrow"></div>
                      </div>
                      <div class="mobile-custom-options">
                          <!-- <span class="mobile-custom-option" [ngClass]="{'filter-log-active': selectedItem === 'all'}"  data-value="all" (click)="selectedItem = 'all'; getAllActiveProducts('all'); categoryFilter = 'All'">All</span> -->
                          <span class="mobile-custom-option" [ngClass]="{'filter-log-active': categoryFilter === data}" *ngFor="let data of filterList" data-value="data" (click)="getAllActiveProducts(data); categoryFilter = data">{{getFilterName(data)}}</span>
                      </div>
                  </div>
              </div>
          </div>
        </div>
    </div>
  </div>
  <div *ngIf="category === 'available'">
    <div *ngIf="isCardView">
      <div class="product-container">
        <div class="available-product-card" *ngFor="let availableProduct of availableProductsList">
          <div style="display: flex;">
            <img alt="Available Product {{ availableProduct.name}}" class="available-product-img" src="{{availableProduct.imageUrl}}" />
            <div style="display: flex; flex-direction: column; width: 100%;">
              <label class="text-white font-class available-product-title available-product-title-max-line">{{ availableProduct.name}}</label>
              <div class="know-more-wrapper">
                <div [ngClass]="{'available-prod-view-details-layout': availableProduct.detailsLink, 'available-prod-view-details-layout-noLink' : !availableProduct.detailsLink }" (click)="openKnowMore(availableProduct.detailsLink)">
                  <img alt="Know More About {{ availableProduct.name}} Product" style="height: 15px; width: 15px;" src="../../assets/images/info.png" />
                  <span [ngClass]="{'available-prod-view-details-text': availableProduct.detailsLink, 'available-prod-view-details-text-noLink' : !availableProduct.detailsLink }" class="available-prod-view-details-text">Know more</span>
                </div>
                <!-- <span class="available-prod-time-text">ETA: {{getProductETA(availableProduct.tags)}}</span> -->
              </div>
            </div>
          </div>
          <div>
            <label class="text-white font-class available-product-description line-clamp">{{availableProduct.shortDescription}}</label>
          </div>
          <div class="launch-btn-wrapper" *ngIf="projectStatus == 'Active'">
            <div class="launch-btn-layout">
              <button class="launch-btn font-class" (click)="catalogOrder(availableProduct)">LAUNCH NOW</button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="availableProductsList && availableProductsList.length === 0 && !loader" class="empty-my-product-layout">
        <div>
          <h4 class="no-data-found text-white font-class text-center noDataText">{{noDataTemplate}}</h4>
          <!-- <h4 class="no-data-found-subtext text-white font-class text-center">{{noDataMsg}}</h4> -->
        </div>
        </div>
    </div>
    <div *ngIf="!isCardView">
      <div class="table-responsive">
        <div class="table-header" *ngIf="availableProductsList.length > 0">
          <div class="table-title-layout" style="width: 25%;">
            <span class="table-title-text font-class">Product Name</span>
          </div>
          <div class="table-title-layout" style="width: 53%;">
            <span class="table-title-text font-class">Description</span>
          </div>
          <!-- <div class="table-title-layout" style="width: 15%;">
            <span class="table-title-text font-class">ETA</span>
          </div> -->
        </div>
        <table class="table" style="width: 100%; margin-top: 10px;">
          <!-- <thead>
            <tr>
              <th class="table-header" style="color: #85D4FF !important; display: flex; justify-content: center; align-items: center;">Product Name</th>
              <th class="table-header" style="color: #85D4FF !important; display: flex; justify-content: center; align-items: center;">Description</th>
            </tr>
          </thead> -->
          <tbody>
            <tr class="table-body-separator" *ngFor="let availableProduct of availableProductsList">
              <td style="width: 30%;">
                <div>
                  <img alt="Available Product {{ availableProduct.name}}"  class="table-body-icon" src="{{availableProduct.imageUrl}}" />
                  <span class="table-body-name font-class">{{availableProduct.name}}</span>
                </div>
              </td>
              <td>
                <div style="display: flex;">
                  <div class="table-know-more-btn" (click)="openKnowMore(availableProduct.detailsLink)">
                    <img alt="Know More About {{ availableProduct.name}} Product" style="height: 15px; width: 15px;" src="../../assets/images/info.png" />
                    <span style="color: #85D4FF; margin-left: 10px;">Know more</span>
                  </div>
                  <span class="table-body-description">{{availableProduct.shortDescription}}</span>
                  <!-- <div class="table-estimated-time-btn">
                    <span style="color: #85D4FF; margin-left: 55px;">{{getProductETA(availableProduct.tags)}}</span>
                  </div> -->
                  <div class="table-body-launch-btn" (click)="catalogOrder(availableProduct)" *ngIf="projectStatus == 'Active'">
                    <span class="table-body-launch-text font-class">Launch Now</span>
                    <img  alt="Click Here for Launch the Product" style="height: 15px; width: 15px; margin-left: 20px;" src="../../assets/images/right-arrow.png" />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <!-- <tfoot style="margin-top: 10px">
          </tfoot> -->
        </table>

      </div>
      <div *ngIf="availableProductsList && availableProductsList.length === 0 && !loader" class="empty-my-product-layout" style="margin-top: 20px">
        <div>
          <h4 class="no-data-found text-white font-class text-center noDataText">{{noDataTemplate}}</h4>
          <!-- <h4 class="no-data-found-subtext text-white font-class text-center">{{noDataMsg}}</h4> -->
        </div>
      </div>
    </div>
    <div class="product-avail" *ngIf="!loader &&  availableProductsList.length > 0">
      <span class="page-count-text">{{ currentPageLowerCount }} - {{ currentPageUpperCount }} of {{ totalProducts }}</span>
      <div class="pagination-wrapper">
        <img  alt="First Page" class="pagination-next-btn" [ngClass]="{'disable-first-btn':currentPage === 1 }" src="../../assets/images/pagination-first-active.png" (click)="getNextAvailableProducts('first')"/>
        <img  alt="Previous Page" class="pagination-next-btn" [ngClass]="{'disable-prev-btn':currentPage === 1 }" src="../../assets/images/pagination-prev-active.png" (click)="getNextAvailableProducts('previous')"/>
        <img alt="Next Page" class="pagination-next-btn" [ngClass]="{'disable-next-btn':currentPage === totalPages}" src="../../assets/images/pagination-next-active.png" (click)="getNextAvailableProducts('next')"/>
        <img  alt="Last Page" class="pagination-next-btn" [ngClass]="{'disable-last-btn':currentPage === totalPages}" src="../../assets/images/pagination-last-active.png" (click)="getNextAvailableProducts('last')"/>
      </div>
    </div>
  </div>
  <div *ngIf="category === 'active'">
    <div *ngIf="isCardView">
      <div class="my-product-container" style="margin-bottom: 40px;" *ngIf="activeProductsList && activeProductsList.length > 0">
        <div class="my-product-card" *ngFor="let activeProduct of activeProductsList" (click)="openProductDetails(activeProduct)">
          <div class="card-header">
            <span class="product-title">$ {{activeProduct.budgetConsumed}} used</span>
            <div class="product-status-layout">
              <span class="text-white font-class status-text">{{getStatus(activeProduct.Status)}}</span>
              <div class="status-indicator {{getStatusColor(activeProduct.Status)}}"></div>
            </div>
          </div>
          <div class="card-info-layout">
            <img class="product-img" src="{{activeProduct.imageUrl}}" alt="My Product {{activeProduct.ProvisionedProductName}}" />
            <div>
              <label title="{{activeProduct.ProvisionedProductName}}" class="text-white font-class max-lines product-name">{{ nameTrim(activeProduct.ProvisionedProductName) }}</label>
              <label title="{{activeProduct.ProductName}}" class="font-class max-lines product-name-type">{{ nameTrimData(activeProduct.ProductName) }}</label>
            </div>
          </div>
          <div class="shared-layout" *ngIf="activeProduct.isShared === true">
            <div class="share-data" title="This folder is currently shared">
              <img class="share-icon" src="../../assets/images/share-white.png" alt="Shared" />
              <span class="share-icon-text" style="margin-left: 10px;"> Shared </span>
            </div>
            <div class="view-details-layout">
              <span class="view-details-text">Last update {{activeProduct.updated_on | amTimeAgo}}</span>
            </div>
          </div>
          <div *ngIf="activeProduct.isShared !== true && activeProduct.isProjectStorage != true" class="view-details-wrapper">
            <div class="view-details-layout">
              <span class="view-details-text">Last update {{activeProduct.updated_on | amTimeAgo}}</span>
            </div>
          </div>
          <div class="shared-layout" *ngIf="activeProduct.isShared !== true && activeProduct.isProjectStorage == true">
              <div class="share-data" style="width: 45% !important;">
                  <i class="fa fa-hdd-o" style="font-size: 2.1em; color: #FFFFFF; padding: 0px !important; width: 25px;vertical-align: middle;margin-right: 5px;" aria-hidden="true"></i>
                  <span class="share-icon-text"> Project Storage </span>
                </div>
            <div class="view-details-layout" style="margin-right: 20px !important; margin-left: 0px !important">
              <span class="view-details-text">Last update {{activeProduct.updated_on | amTimeAgo}}</span>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="loadMore" class="load-more-btn-wrapper">
        <button class="load-more-btn" (click)="loadMoreEvents()">Load More</button>
      </div>
      <div *ngIf="activeProductsList && activeProductsList.length === 0 && !loader" class="empty-my-product-layout">
        <div>
          <h3 class="no-data-found text-white font-class text-center">{{noDataTemplate}}</h3>
          <!-- <h4 class="no-data-found-subtext text-white font-class text-center">{{noDataMsg}}</h4> -->
        </div>
      </div>
    </div>
    <div *ngIf="!isCardView">
      <div class="table-responsive" *ngIf="activeProductsList && activeProductsList.length > 0">
        <div class="table-header">
          <div class="my-product-name-header" style="width: 25%;">
            <span class="table-title-text font-class">Product Name</span>
          </div>
          <div class="my-product-header" style="width: 15%;">
            <span class="table-title-text font-class">Service Name</span>
          </div>
          <div class="my-product-header" style="width: 15%;">
            <span class="table-title-text font-class">Budget Consumed (USD)</span>
          </div>
          <div class="my-product-header" style="width: 15%;">
            <span class="table-title-text font-class">Updated On</span>
          </div>
          <div class="my-product-header" style="width: 10%;">
            <span class="table-title-text font-class">Status</span>
          </div>
        </div>
        <table class="table" style="width: 100%; margin-top: 10px; margin-bottom: 60px;">
          <tbody>
            <tr class="table-body-separator" *ngFor="let myProduct of activeProductsList">
              <td style="width: 25%;">
                <div>
                  <img  alt="Provisioned Product {{myProduct.ProvisionedProductName}}"  class="table-body-icon" src="{{myProduct.imageUrl}}" />
                  <span class="table-body-description" *ngIf="myProduct.isShared === true" style="margin-left: 10px;width: 30px;display: inline-table;position: absolute;
    margin-top: 5px;" title="Shared">
                    <img class="share-icon" src="../../assets/images/share-white.png" alt="Shared" />
                  </span>
                  <span class="table-body-description" *ngIf="myProduct.isProjectStorage === true" style="margin-left: 10px;width: 30px;display: inline-table;position: absolute;
                  margin-top: 5px;" title="Project Storage">
                  <i class="fa fa-hdd-o" style="font-size: 1.8em; color: #fff;padding-top: 0px !important;vertical-align: middle;" aria-hidden="true"></i>
                  </span>
                  <span class="table-body-name font-class">{{myProduct.ProvisionedProductName}}</span>
                </div>
              </td>
              <td style="width: 15%;">
                <div>
                  <span class="my-product-value font-class">{{myProduct.ProductName}}</span>
                </div>
              </td>
              <td style="width: 15%;">
                <div>
                  <span class="my-product-value font-class">{{myProduct.budgetConsumed ? myProduct.budgetConsumed : 0.00}}</span>
                </div>
              </td>
              <td style="width: 15%;">
                <div>
                  <span class="my-product-value font-class">{{myProduct.updated_on | date:'medium'}}</span>
                </div>
              </td>
              <td>
                <div style="display: flex;">
                  <span class="table-body-description">{{getStatus(myProduct.Status)}}</span>
                  <!-- <span class="table-body-description" *ngIf="myProduct.isShared === true">
                    <img class="share-icon" src="../../assets/images/share.png" alt="../../assets/images/share.png" />
                    <span class="share-icon-text"> Shared </span>
                  </span> -->
                  <div class="table-body-launch-btn" (click)="openProductDetails(myProduct)">
                    <span class="table-body-launch-text font-class">View Details</span>
                    <img  alt="Click Here For Product Details Page" style="height: 15px; width: 15px; margin-left: 20px;" src="../../assets/images/right-arrow.png" />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="activeProductsList && activeProductsList.length === 0 && !loader" class="empty-my-product-layout">
        <div>
          <h3 class="no-data-found text-white font-class text-center">{{noDataTemplate}}</h3>
          <!-- <h4 class="no-data-found-subtext text-white font-class text-center">{{noDataMsg}}</h4> -->
        </div>
      </div>
    </div>
    <div class="product-avail" *ngIf="!loader && activeProductsList.length > 0">
      <span class="page-count-text">{{ currentPageLowerCount }} - {{ currentPageUpperCount }} of {{ totalProducts }}</span>
      <div class="pagination-wrapper">
        <img alt="First Page" class="pagination-next-btn" [ngClass]="{'disable-first-btn':currentPage === 1 }" src="../../assets/images/pagination-first-active.png" (click)="getNextActiveProducts('first')"/>
        <img alt="Previous Page" class="pagination-next-btn" [ngClass]="{'disable-prev-btn':currentPage === 1 }" src="../../assets/images/pagination-prev-active.png" (click)="getNextActiveProducts('previous')"/>
        <img alt="Next Page" class="pagination-next-btn" [ngClass]="{'disable-next-btn':currentPage === totalPages}" src="../../assets/images/pagination-next-active.png" (click)="getNextActiveProducts('next')"/>
        <img alt="Last Page" class="pagination-next-btn" [ngClass]="{'disable-last-btn':currentPage === totalPages}" src="../../assets/images/pagination-last-active.png" (click)="getNextActiveProducts('last')"/>
      </div>
    </div>
  </div>
</div>
<app-loader class="spinner" *ngIf="loader"></app-loader>
