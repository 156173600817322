<div id="popupBoxId" #AddUserAction tabindex="-1" >
  <div class="dialog-header">
    <img class="cancel-icon" tabindex="0" (click)="cancel()" (keypress)="cancel()" src="../../assets/images/cancel@2x.png" alt="Cancel"/>
    <h2 class="modal-title title-header" *ngIf="action == 'addUser' || this.action == 'addUserFromProjectPage' || this.action =='addUserFromOrgPage'" id="myModalLabel" class="Header">
      Add User
    </h2>
    <h2 class="modal-title title-header" *ngIf="action == 'editUser'" id="myModalLabel" class="Header">
      Edit User
    </h2>
  </div>

  <div style="display: flex; flex-direction: row; margin-top: 10px;">
    <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
  </div>
  <form #f="ngForm" novalidate class="form-wrapper" (ngSubmit)="f.form.valid && addUser()">
  <div class="form-group col-md-12 col-sm-12">
      <label class="input-label" for="email">Email <span class="order-st">*</span></label>
      <input [disabled]="isEditDisabled" type="email" class="form-control user-input" name="email" id="email" style="border-left: 5px solid #F32561" placeholder="Enter Email ID - (required)"
        maxlength="254" [(ngModel)]="formData.email" #email="ngModel"
        pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" required>
      <div class="help-block">User will receive an invite on the above email</div>
  </div>
  <div class="form-group col-md-12 col-sm-12 focus-highlight">
      <label class="input-label" for="username">Role <span class="order-st">*</span></label>
      <label class="wrap">
        <select *ngIf="action == 'addUser'|| this.action == 'addUserFromProjectPage' || this.action =='addUserFromOrgPage'" class="dropdown font-class" [ngClass]="{'placeholder-text': selectedRole === undefined}" style="border-left: 5px solid #F32561" required [(ngModel)]="selectedRole" name="selectedRole" (change)="checkRole();">
          <option [ngValue]="undefined" disabled selected>Select a role - (required)</option>
          <option style="cursor: pointer;" *ngFor="let role of roleData; let $index=index" [ngValue]="role">{{role.value}}</option>
        </select>
        <select [disabled]="isEditDisabled" *ngIf="action == 'editUser'" class="dropdown font-class" style="border-left: 5px solid #42A948;cursor: not-allowed" name="roleSelected">
          <option >{{roleSelected}}</option>
        </select>

      </label>
      <div class="help-block">User's role defines their access on the platform</div>
    </div>
    <div class="form-group col-md-12 col-sm-12">
      <label class="input-label" for="firstName">First Name</label>
      <input type="text" class="form-control user-input" style="border-left: 5px solid #42A948" id="firstName" maxlength="50" name="firstName"
        [(ngModel)]="formData.firstName" #firstName="ngModel" (keyup)="checkFirstName()" placeholder="Enter first name of the user - (optional)">
      <div class="help-block">Name should contain only alphabets</div>
    </div>
    <div class="form-group col-md-12 col-sm-12">
      <label class="input-label" for="lastName">Last Name</label>
      <input type="text" class="form-control user-input" (keyup)="checkLastName()" style="border-left: 5px solid #42A948" id="lastName" maxlength="50" name="lastName"
        [(ngModel)]="formData.lastName" #lastName="ngModel" placeholder="Enter last name of the user - (optional)">
      <div class="help-block">Name should contain only alphabets</div>
    </div>
    <div class="form-group col-md-12 col-sm-12" *ngIf="!isDisabled && !checkIfUserIsPI()">
      <label class="input-label" for="username">Organizational Unit</label>
      <label class="wrap">
        <select [disabled]="isDisabledOrgUnit" *ngIf="action == 'addUser' || this.action == 'addUserFromProjectPage' || this.action =='addUserFromOrgPage'" class="dropdown font-class" [ngClass]="{'placeholder-text': selectedOrganisation === undefined}" style="border-left: 5px solid #42A948" [(ngModel)]="selectedOrganisation" name="selectedOrganisation">
          <option [ngValue]="undefined" disabled selected>Select a Organizational Unit - (optional)</option>
          <option style="cursor: pointer;" *ngFor="let organisation of organisationData; let $index=index" [ngValue]="organisation">{{organisation.value}}</option>
        </select>
        <select [disabled]="isEditDisabled" *ngIf="action == 'editUser' && organisationSelected" class="dropdown font-class" style="border-left: 5px solid #42A948;cursor: not-allowed;" name="orgSelected">
          <option *ngIf="organisationSelected">{{organisationSelected}}</option>
        </select>
        <select [disabled]="isDisabledOrgUnit" *ngIf="action == 'editUser' && roleSelected == 'Admin' && !organisationSelected" class="dropdown font-class" id = "orgDropdownID" style="border-left: 5px solid #42A948;cursor: not-allowed;" name="orgSelected" [(ngModel)]="selectedOrganisation" (change)="checkOrg();">
          <option [ngValue]="undefined" disabled selected>Select a Organizational Unit - (optional)</option>
        </select>
        <select [disabled]="isDisabledOrgUnit" *ngIf="action == 'editUser' && !organisationSelected && roleSelected !== 'Admin'" class="dropdown font-class" id = "orgDropdownID" style="border-left: 5px solid #42A948" name="orgSelected" [(ngModel)]="selectedOrganisation" (change)="checkOrg();">
          <option [ngValue]="undefined" disabled selected>Select a Organizational Unit - (optional)</option>
          <option style="cursor: pointer;" *ngFor="let organisation of organisationData; let $index=index" [ngValue]="organisation">{{organisation.value}}</option>
        </select>
      </label>
      <div class="help-block">User's Organizational Unit provides access to resources</div>
    </div>
    <div class="col-lg-12 col-md-12" >
      <label class="input-label"> Tags </label>
    </div>
    
    <div *ngIf="tags.length < 5" class="col-lg-12 add-tags col-md-12" style="padding-right: 0px;">
      <input minlength="3" aria-label="tagsInputBox" maxlength="32" id="tagValueId"  class="form-control user-input kp-lab-text" pattern="[a-zA-Z0-9][a-zA-Z0-9@+._-]*" name="keyValue" style="display: initial; border-left: 5px solid #42A948; border-radius: 5px !important; width: 76%; color: #FFFFFF" type="text"
        placeholder="Enter value - (optional)" [(ngModel)]="keyValue" (keyup)="checkTagValue(f.valid)">
        <span><i (click)="addTag(f.valid)" tabindex="0" (keypress)="addTag(f.valid)"  id="buttonClickId"  class="fa fa-arrow-right kp-tag-ent" (keyup)="checkTagValue(f.valid)"></i></span>
        <div class="help-block"> Enter up to five tags </div>
    </div>
 
    <div class="col-lg-12 col-md-12" style="margin-top:5px; margin-left:20px;">
    <div *ngFor="let tag of tags"  class="tag-t-s">
      <span [title]="tag" *ngIf="tag.length > 6; else elseBlock" style="font-size: 14px;">{{tag.slice(0,6).concat("...")}}</span>
      <ng-template #elseBlock>
        <span [title]="tag" style="font-size: 14px;">{{tag}}</span>
      </ng-template>
        <i class="fa fa-times-circle tag-span-i" tabindex="0" (keypress)="removeTag(tag)" (click)="removeTag(tag)"></i>   
    </div>
  </div>
     <!-- <div class="form-group col-md-12 col-sm-12">
      <label class="input-label" for="username">Username <span class="order-st">*</span></label>
      <input type="text" class="form-control user-input" id="username" maxlength="50" name="username" required
        [(ngModel)]="formData.username" #username="ngModel" placeholder="Select a username" (keydown.space)="$event.preventDefault()">
      <div class="help-block">Give the user a name to identify on this platform</div>
    </div> -->
    
    <div class="btn-wrapper">
      <button type="submit" *ngIf="action == 'addUser'|| this.action == 'addUserFromProjectPage' || this.action =='addUserFromOrgPage'" [disabled]="!f.valid" tabindex="0" class="submit-btn">Add User</button>      
    </div>
    <div class="btn-wrapper">
      <button type="submit" *ngIf="action == 'editUser'" [disabled]="!(firstNameScope || lastNameScope || tagScope || orgScope)" class="submit-btn" (click)="updateUser()">Edit User</button>      
    </div>
  </form>
</div>
<app-loader class="spinner" *ngIf="showSpinner"></app-loader>
