import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';

import { ResearchService } from '../../_services/research.service';
import { Globals } from "../../globals";

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css'],
  providers: [Globals]
})
export class PageNotFoundComponent implements OnInit {

  constructor(private router: Router, private researchService: ResearchService,
    private toastr: ToastrService, private globals: Globals) { }
  
  helpLink: any;

  ngOnInit(): void {

  }

  goToHomePage(){
    let level = sessionStorage.getItem('Level');
      if(level === '2'){
        this.router.navigate(['admin']);
      }else if(level === '1'){
        this.router.navigate(['principal']);
      }else if(level === '0'){
        this.router.navigate(['researcher']);
      }else{
        this.router.navigate(['login']);
      }
  }

  getHelpLink(){
    this.researchService.getHelp().subscribe((data:any) => {
      if(data.value){
        window.open(data.value, '_blank');
      }
    }, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    });
  }

}
