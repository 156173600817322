import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {ResearchService} from "../../_services/research.service";
import {Globals} from "../../globals";
import {FormGroup, FormControl} from '@angular/forms';

@Component({
  selector: 'app-audit-details',
  templateUrl: './audit-details.component.html',
  styleUrls: ['./audit-details.component.css'],
  providers: [Globals]
})
export class AuditDetailsComponent implements OnInit {

  filterSearchDate = '';
  filterSearchCat = '';
  filterSearchVal = '';
  orgId = '';
  orgName = '';
  searchKey = '';
  auditDetailList = [];
  currentPage = 1;
  limit = 10;
  showSpinner = false;
  checkBoxValue = [];
  searchExecKey = '';
  showLoadMore = false;
  noData = false;
  searchValid = false;
  noDataTemplate = 'No audit logs exist';
  nameFilter = 'Select a value';
  categoryFilter = 'Filter logs : By';
  nameFilterValueArr = [];
  userResList;
  currentPageLowerCount = 1;
  currentPageUpperCount = 10;
  totalRecords = 0;
  page = 1;
  totalPages = 1;
  toTime;
  fromTime;
  dateChanged: boolean = false;
  selectedItem;
  valFilter = true;
  userLevel;
  public date: any = {};
  today = new Date();
  range = new FormGroup({
    start: new FormControl(null),
    end: new FormControl(null),
  });
  public model: any = {
    // beginDate: { year: new Date().getFullYear(), month: new Date().getMonth(), day: new Date().getDate() },
    // endDate: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }
  };
  constructor(private researchService: ResearchService, private router: Router,
              private toastr: ToastrService, private globals: Globals,
              private activatedRoute: ActivatedRoute) {
    const date = new Date();
    this.toTime = date.toISOString();
    date.setHours(date.getHours() - 24);
    this.fromTime = date.toISOString();
  }
  ngOnInit() {
    this.orgId = sessionStorage.getItem('org_id');
    this.userLevel = sessionStorage.getItem('Level') || "2";
    this.getAuditDetails();
    this.getUserList();
    document.querySelector('.mat-datepicker-toggle').addEventListener('click', function () {

    })
  }

  getUserList() {
    this.researchService.getUserList(this.orgId).subscribe((res:any) => {
      // console.log(res);
      this.userResList = res.data;
    }, error => {
      this.showSpinner = false;
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }else {
        const err = error.error.error;
        this.toastr.error(err, 'Error', this.globals.tosterOverride);
       }
    });
  }

  setDate(event) {
    this.dateChanged = true;
    if (event.beginJsDate == null && event.endJsDate == null) {
      this.date["startDate"] = null;
      this.date["endDate"] = null;
    } else {
      this.date["startDate"] = new Date(event.beginDate.year + "-" + event.beginDate.month + "-" + event.beginDate.day).toISOString();
      const endDate = new Date(event.endDate.year + "-" + event.endDate.month + "-" + event.endDate.day);
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setSeconds(59);
      this.date["endDate"] = endDate.toISOString();
    }
    this.page = 1;
    const reqDate = this.date;
    if (reqDate) {
      if (reqDate.startDate && reqDate.endDate) {
        this.filterSearch(reqDate, 'date');
      } else {
        this.filterSearchDate = '';
        this.refresh();
      }
    } else {
      this.filterSearchDate = '';
      this.refresh();
    }
  }

  dateRangeChange(dateRangeStart, dateRangeEnd) {
    this.dateChanged = true;
    this.date["startDate"] = new Date(dateRangeStart.value).toISOString();
    let endDate :any;
    endDate = new Date(dateRangeEnd.value);
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);
    this.date["endDate"] = endDate.toISOString();
    this.page = 1;
    const reqDate = this.date;
    if (reqDate) {
      if (reqDate.startDate && reqDate.endDate) {
        this.filterSearch(reqDate, 'date');
      } else {
        this.filterSearchDate = '';
        this.refresh();
      }
    } else {
      this.filterSearchDate = '';
      this.refresh();
    }
  }

  filterSearch(reqDate, type) {
    this.valFilter = false;
    if (type === 'date') {
      this.filterSearchDate = `&startDate=${reqDate.startDate}&endDate=${reqDate.endDate}`;
      this.refresh();
    } else if (type === 'category') {
      this.filterSearchCat = `&category=${reqDate}`;
    } else if (type === 'value') {
      this.filterSearchVal = `&value=${encodeURIComponent(reqDate.id)}`;
      if (reqDate.name) {
        const strLen = reqDate.name.length;
        let str = reqDate.name;
        if (strLen > 20) {
          str = str.slice(0, 20) + '...';
        }
        this.nameFilter = str;
      }
      this.refresh();
    }
  }

  filterSearchMobile(reqDate, type) {
    this.valFilter = false;
    if (type === 'date') {
      this.filterSearchDate = `&startDate=${reqDate.startDate}&endDate=${reqDate.endDate}`;
      this.refresh();
    } else if (type === 'category') {
      this.filterSearchCat = `&category=${reqDate}`;
    } else if (type === 'value') {
      this.filterSearchVal = `&value=${encodeURIComponent(reqDate.id)}`;
      if (reqDate.name) {
        const strLen = reqDate.name.length;
        let str = reqDate.name;
        if (strLen > 10) {
          str = str.slice(0, 10) + '...';
        }
        this.nameFilter = str;
      }
      this.refresh();
    }
  }

  dropButtonTxt(data) {
    const strLen = data.length;
    let str = data;
    if (strLen > 20) {
      str = str.slice(0, 20) + '...';
    }
    return str;
  }

  dropButtonTxtMobile(data) {
    const strLen = data.length;
    let str = data;
    if (strLen > 10) {
      str = str.slice(0, 10) + '...';
    }
    return str;
  }

  resetFilter() {
    this.filterSearchDate = '';
    this.filterSearchCat = '';
    this.filterSearchVal = '';
    this.selectedItem = '';
    this.nameFilter = 'Select a value';
    this.categoryFilter = 'Filer logs : By';
    this.model = '';
    this.valFilter = true;
    this.range.reset();
    this.refresh();
  }

  getNextSettings(action) {
    if (action === 'first' && this.page !== 1) {
      this.page = 1;
      this.getAuditDetails();
    }
    if (action === 'previous' && this.page > 1) {
      this.page = this.page - 1;
      this.getAuditDetails();

    }
    if (action === 'next' && this.page !== this.totalPages) {
      this.page = this.page + 1;
      this.getAuditDetails();

    }
    if (action === 'last' && this.page !== this.totalPages) {
      this.page = this.totalPages;
      this.getAuditDetails();
    }
  }

  private getAuditDetails() {
    this.showSpinner = true;
    this.noData = false;
    this.researchService.getAuditDetails(this.page, this.limit, this.searchKey,
      this.filterSearchDate, this.filterSearchCat, this.filterSearchVal).subscribe((res:any) => {
      if (this.searchKey || this.filterSearchDate || this.filterSearchCat || this.filterSearchVal) {
        this.noDataTemplate = 'No matching audit logs found';
      }
      this.showSpinner = false;
      this.showLoadMore = false;
      const validSearch = this.searchKey.trim();
      if (validSearch && this.page === 1) {
        this.auditDetailList = [];
      }
      // this.auditDetailList = [].concat(this.auditDetailList, res.docs);
      this.auditDetailList = res.docs;
      if (this.auditDetailList.length < res.total) {
        this.showLoadMore = true;
      }

      if (this.auditDetailList.length === 0) {
        this.noData = true;
      }

      this.totalRecords = res.total || 0;
      this.page = res.page;
      this.totalPages = res.pages;
      this.limit = res.limit;
      // Calculation for lower & upper count per page
      this.currentPageLowerCount = 1 + ((this.page - 1) * this.limit);
      if (this.auditDetailList.length < 10) {
        this.currentPageUpperCount = this.auditDetailList.length + (this.limit * (this.page - 1));
      }else {
        this.currentPageUpperCount = this.limit * this.page;
      }
    }, error => {
      this.showSpinner = false;
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }else if(error && error.status === 403){
        const err = error.error;
        this.toastr.error(err.error, 'Error', this.globals.tosterOverride);
        this.router.navigate(['pageNotFound']);
      }else{
        const err = error.error;
        this.toastr.error(err, 'Error', this.globals.tosterOverride);
       }
    });
  }

  deleteKeypair() {
    const payload = { keypairList : this.checkBoxValue };
    this.showSpinner = true;
  }

  getProjectName(data) {
    let projectName = '-';
    if (data) {
      projectName = data['name'] || '-';
    }
    return projectName;
  }

  searchKeypair() {
    const searchKey = this.searchKey.trim() || '';
    if (searchKey.length >= 3 && this.searchExecKey !== searchKey)  {
      this.searchExecKey = searchKey;
      this.searchValid = true;
      this.refresh();
    } else if (searchKey.length === 0 && this.searchExecKey !== searchKey) {
      this.searchExecKey = searchKey;
      this.searchValid = true;
      this.refresh();
    } else {
      this.searchValid = false;
    }
  }

  refresh() {
    this.page = 1;
    this.auditDetailList = [];
    this.getAuditDetails();
  }

  loadMoreEvents() {
    this.page = this.page + 1;
    this.getAuditDetails();
  }

  projectName(data) {
    if (data) {
      const strLen = data.length;
      let str = data;
      if (strLen > 160) {
        str = str.slice(0, 160) + '...';
      }
      return str;
    } else {
      return 'Nil';
    }
  }

  activateClass(subModule) {
    subModule.active = !subModule.active;
  }

  filterCatChange(data) {
    this.filterSearch(data, 'category');
    switch (data) {
      case 'admin':
        this.nameFilterValueArr = this.userResList.admins;
        break;
      case 'principal':
        this.nameFilterValueArr = this.userResList.principals;
        break;
      case 'researcher':
        this.nameFilterValueArr = this.userResList.researchers;
        break;
      case 'project':
        this.nameFilterValueArr = this.userResList.projects;
        break;
      case 'organization':
        this.nameFilterValueArr = this.userResList.organizations;
        break;
    }
  }


}
