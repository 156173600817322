<form #f="ngForm">
  <div class="form-group" id="formData">
    <button class="close" data-dismiss="modal" type="button" style="background: #6E7C9F" title="Close"
            style="outline: none; padding:10px 15px !important; opacity: 0.5;" (click)="cancel()"><i
      class="fa fa-times-circle" style="margin-right: 10px;font-size: 25px;color: #f5f5f5;"></i></button>
    <h3 class="Header ml-3"
        style="text-align: left; color: #FFFFFF; padding:10px 15px;margin-top: 10px;font-size: 18px;">Create Key pair</h3>
    <div style="display: flex; flex-direction: row; margin-top: 10px;">
      <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
      <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
      <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
      <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
    </div>
    <app-loader class="spinner" *ngIf="showSpinner"></app-loader>
    <!--  Create Keypair Section -->
    <div class="add-new-project-form" style="padding: 1rem;" *ngIf="projectList.length > 0 && !showSpinner">
      <div class="col-lg-12 col-md-12" style="margin-top: 15px">
        <label class="formLabel">A key pair, consisting of a private and a public key, is a set of security credentials that you use to prove your identity when connecting to a resource.</label>
      </div>
      <div class="col-lg-6 col-md-12" style="margin-top: 15px">
        <label class="formLabel">Project</label>
        <span class="after">*</span>
        <select class="form-control kp-lab-text" required placeholder="Select the project" [(ngModel)]="projectId" name="projectId">
          <option value="undefined" disabled selected >Select the project</option>
          <option *ngFor="let acc of projectList" [value]="acc._id">{{acc.name}}</option>
        </select>
        <div class="name-helper-container">
          <span class="help-block">Select the project with which the key pair will be associated.</span>
        </div>

      </div>
      <div class="col-lg-6 col-md-12" style="margin-top: 15px">
        <label class="formLabel">Name</label>
        <span class="after">*</span>
        <input maxlength="255" aria-label="keyPairName" class="form-control kp-lab-text" style="width: 100%; color: #FFFFFF" type="text" pattern="^[\x00-\x7F]*$"
               placeholder="Enter a name for the key pair" [(ngModel)]="keypairName" #rCEvery="ngModel"  required
               name="keypairName">
        <div class="name-helper-container">
          <span class="help-block">The name can include up to 255 ASCII characters.
Leading or trailing spaces will be removed.</span>
        </div>

      </div>
      <div class="col-lg-12 col-md-12" style="margin-top: 15px">
        <label class="formLabel">File format</label>
        <div class="">
<!--          <div class="display-none col-lg-6 col-md-12">-->
<!--            <label class = "mdl-js-radio" for = "option1">-->
<!--              <input [(ngModel)]="pemFileCheck" type = "radio" id = "option1" name = "fileFormat"-->
<!--                     class = "mdl-radio__button" [value]="true" >-->
<!--              <label style=" font-weight: 300; " for="option1"></label>-->
<!--              <span class = "mdl-radio__label keypair-create">pem</span>-->
<!--              <span class="kp-file-radio">For use with OpenSSH </span>-->
<!--            </label>-->
<!--          </div>-->
<!--          <div class="display-none col-lg-6 col-md-12">-->
<!--            <label class = "mdl-js-radio mdl-js-ripple-effect"-->
<!--                   for = "option2">-->
<!--              <input [(ngModel)]="pemFileCheck" type = "radio" id = "option2" name = "fileFormat"-->
<!--                     class = "mdl-radio__button"  [value]="false" >-->
<!--              <label style=" font-weight: 300; " for="option2"></label>-->
<!--              <span class = "mdl-radio__label keypair-create">ppk</span>-->
<!--              <span class="kp-file-radio">For use with PuTTy </span>-->
<!--            </label>-->
<!--          </div>-->
          <select class="form-control kp-lab-text" required placeholder="Select the file format" [(ngModel)]="pemFileCheck" name="fileFormat">
            <option value="undefined" disabled selected >Select the file format</option>
            <option value="pem-file">pem ( For use with OpenSSH )</option>
            <option value="ppk-file">ppk ( For use with PuTTy )</option>
          </select>
          <div class="name-helper-container">
            <span class="help-block">Select the file format for the key file to be downloaded.</span>
          </div>
        </div>
      </div>

      <div class="col-lg-12 col-md-12" style="margin-top: 25px">
        <label class="formLabel">Add Tags ( Optional ) you can add upto 10 tags</label>
      </div>
      <div *ngIf="tags.length < 10" class="col-lg-12 col-md-12"  >
        <div class="col-lg-6 col-md-12"  >
          <label class="formLabel kp-key">Key</label>
          <input maxlength="25" aria-label="keyForKeyPair" class="form-control kp-lab-text" name="keyName" style="display: initial; border-radius: 5px !important; width: 75%; color: #FFFFFF" type="text"
                 placeholder="Enter key" [(ngModel)]="keyName" >
          <span class="key-colon"> : </span>
        </div>
        <div class="col-lg-6 col-md-12">
          <label class="formLabel kp-key">Value</label>
          <input maxlength="25" aria-label="valueForKeyPair" class="form-control kp-lab-text" name="keyValue" style="display: initial; border-radius: 5px !important; width: 75%; color: #FFFFFF" type="text"
                 placeholder="Enter value" [(ngModel)]="keyValue" >
          <span><i (click)="addTag()" class="fa fa-arrow-right kp-tag-ent"></i></span>
        </div>
      </div>

      <div class="col-lg-12 col-md-12" style=" top: 15px; ">
        <div *ngFor="let tag of tags" class="tag-t-s">
          <span title="{{tag.Value}}" style=" font-size: 13px; ">{{tag.Key}}</span>
          <i class="fa fa-times-circle tag-span-i" (click)="removeTag(tag)"></i>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 " style="margin-top: 12px;text-align: center">
        <button style="background-color: #0039FA !important;border-radius:23px !important;opacity: 1 !important; font-weight: 600;font-family: Nunito Sans; font-size: 14px !important; width: 175px; height:45px; margin: 25px 0px 20px 0px;"
                class="btn btn-primary " data-dismiss="modal" type="button" (click)="insertKeypair()" [disabled]="!f.valid">
          Create Key Pair
        </button>
      </div>

    </div>
    <div *ngIf="!showSpinner && projectList.length == 0">
      <h3 class="noData">
        There are no projects. Please create a project first.
      </h3>
      </div>
  </div>
  <a id="pem-file" [href]="fileUrl" download={{keypairName}}.pem>DownloadFile</a>
  <a id="ppk-file" [href]="fileUrl" download={{keypairName}}.ppk>DownloadFile</a>
</form>
