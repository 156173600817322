<div>
  <div class="dialog-header">
    <img class="cancel-icon" (click)="cancel()" src="../../assets/images/cancel@2x.png"
      alt="Cancel" />
    <h3 class="title">Attach EBS volume to product <h3 class="content-2">"{{productName}}"</h3>
    </h3>
  </div>
  <div style="display: flex; flex-direction: row; margin-top: 0px;">
    <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
  </div>
  <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="padding: 0px;margin-right: 15px;margin-top: 10px">
    <h3 class="prov-product-field text-white font-class" style="margin-bottom: 10px;">Device Name <span
        class="order-st">*</span></h3>
    <label class="wrap">
      <select class=" inp-txt user-input" [ngClass]="{'placeholder-text': deviceName === undefined}" id="deviceNameId"
        required [(ngModel)]="deviceName" name="deviceName" (change)="checkDevice()">
        <option [ngValue]="undefined" disabled>Select a device name - (required)</option>
        <option selected>/dev/sdf</option>
        <option selected>/dev/sdg</option>
        <option selected>/dev/sdh</option>
        <option selected>/dev/sdi</option>        
        <option selected>/dev/sdj</option>
        <option selected>/dev/sdk</option>
      </select>
    </label>
  </div>
  <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="padding: 0px;margin-right: 15px;margin-top: 10px">
    <h3 class="prov-product-field text-white font-class" style="margin-bottom: 10px;">Available Volume<span
      class="order-st">*</span></h3>
      <label class="wrap">
        <select class=" inp-txt user-input" [ngClass]="{'placeholder-text': deviceName === undefined}" id="deviceNameId"
          required [(ngModel)]="selectedVolume" name="selectedVolume"  (change)="seletedAvailabeVolume()">
          <option [ngValue]="undefined" disabled>Select a available Volume - (required)</option>
          <option *ngFor="let volume of volumeList" [value] = "volume.volumeName" selected>{{volume.volumeName}}({{volume.volumeId}})</option>       
        </select>
      </label>
  </div>          
  <div class="mdl-dialog__actions" style="margin-top: 15px;margin-bottom: 15px;">
    <button class="btn btn-primary btn-default sub-btn" [disabled]="clicked" (click)="attachVolume(); clicked=true" name="button" type="submit" style="cursor:hand">
      <h2 class="sub-text">Submit</h2>
    </button>
    <button class="btn can-btn" type="button" (click)="cancel()">
      <h2 class="cal-text">Cancel</h2>
    </button>
  </div>
</div>