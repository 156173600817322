<div class="row field-layout">
  <form [formGroup]="form"
        (ngSubmit)="onSubmit()"
        class="row field-layout-inner">

    <ng-container *ngFor="let parameter of parameters;let i = index"
                  class="col-sm-12 col-md-6 col-lg-6 col-xs-12">

      <div class="col-sm-12 col-md-6 col-lg-6 col-xs-12">
        <label class="label-left text-white font-class">{{ parameter.key | properName }}</label>

        <div *ngIf="parameter.value.ParameterKey ==='KeyName'" style="display: flex; width: 116.5%">
          <div class="s3-search-area-tp"
               *ngIf="isDropdown(parameter)">
            <select class="dropdown text-white font-class s3-select"
                    [formControlName]="parameter.key">
              <option *ngFor="let value of keyPair"
                      [value]="value">{{ value }}
              </option>
            </select>

          </div>
          <button type="submit"
                  class="add-key-pair" (click)="addKeyPairs()" title="Add Key Pair">
            <i class="fa fa-plus white"></i>
          </button>
        </div>
        <div *ngIf="parameter.value.ParameterKey !=='KeyName'">
          <div class="s3-search-area-tp"
               *ngIf="isDropdown(parameter)">
            <select class="dropdown text-white font-class s3-select"
                    [formControlName]="parameter.key">
              <option *ngFor="let value of parameter.value.ParameterConstraints.allowedValues"
                      [value]="value">{{ value }}
              </option>
            </select>

          </div>

        </div>
        <!-- Date field -->
        <input type="datetime"
               class="inp-txt user-input font-class"
               [formControlName]="parameter.key"
               [placeholder]="parameter.value?.Description"
               *ngIf="parameter.value.ParameterType === 'date'">

        <!-- Secure string field (password input) -->
        <input type="password"
               class="inp-txt user-input font-class"
               [formControlName]="parameter.key"
               [placeholder]="parameter.value?.Description"
               *ngIf="parameter.value.ParameterType === 'securestring'">

        <!-- Text input field -->
        <!-- <input class="inp-txt user-input"
               [formControlName]="parameter.key"
               [placeholder]="parameter.value.Description"
               [ngClass]="{ 'is-invalid': isInvalid(parameter.key) }"
               *ngIf="isTextInput(parameter)"> -->
        <p class="tot-txt font-class">{{ parameter.value?.Description }}</p>
        <!-- Validation error messages -->
        <div *ngIf="isInvalid(parameter.key)"
             class="text-danger">
          <div *ngIf="isRequired(parameter.key)">Field is required.</div>
          <div *ngIf="isMinLength(parameter.key)">Minimum length is {{ parameter.value.minLength }}.</div>
          <div *ngIf="isMaxLength(parameter.key)">Maximum length is {{ parameter.value.maxLength }}.</div>
        </div>
      </div>
      <div></div>
    </ng-container>
  </form>
</div>
