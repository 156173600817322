import { Component, OnInit, Inject } from '@angular/core';
import { MdlDialogReference } from "@angular-mdl/core";
import { MdlDialogService } from '@angular-mdl/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-account-not-found',
  templateUrl: './account-not-found.component.html',
  styleUrls: ['./account-not-found.component.css']
})
export class AccountNotFoundComponent implements OnInit {
  userType;
  value;
  constructor(
    private dialog: MdlDialogService,
    public dialogRef: MdlDialogReference,
    private router: Router,
    @Inject('data') public data: any
  ) { }

  ngOnInit() {
    if(this.data.from){
      this.userType = this.data.from;
    }
    if(this.userType === 'admin'){
      this.value = 'organization'
    }else{
      this.value = 'project'
    }
  }

  cancel(): void {
    this.dialogRef.hide();
  }

  routeToSettings(){
    this.dialogRef.hide();
    sessionStorage.setItem('accountType','project');
    this.router.navigate(['/addAccount']);
  }

}
