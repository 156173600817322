import { Component, OnInit, Inject } from '@angular/core';
import { MdlDialogReference } from '@angular-mdl/core';
import { ResearchService } from "../../_services/research.service";
import { ServiceActionsService } from "../../_services/service-actions.service";
import { ToastrService } from 'ngx-toastr';
import { Globals } from "../../globals";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-s3-unshare',
  templateUrl: './s3-unshare.component.html',
  styleUrls: ['./s3-unshare.component.css'],
  providers: [Globals]
})
export class S3UnshareComponent implements OnInit {

  researcherList=[];
  researcher : string = '';
  loader=false;
  s3ShareData;
  projectName;
  provisionedProductName;
  provisionedProductId;
  projectStatus;
  projectId;
  
  constructor(@Inject('data') public data: any, public dialogRef: MdlDialogReference,
  private researchService: ResearchService,private serviceAction: ServiceActionsService,
  private toastr: ToastrService,private router: Router, private globals: Globals) { }

  ngOnInit() {
    if(this.data.hasOwnProperty('s3ShareObj')){
      this.s3ShareData = this.data.s3ShareObj;
      this.researcherList = this.data.researcherList;
      this.projectName = this.s3ShareData.projectName;
      this.provisionedProductName = this.s3ShareData.ProductName;
      this.provisionedProductId = this.s3ShareData.ProvisionedProductId;
      this.projectStatus = this.s3ShareData.Status;
      this.projectId = this.s3ShareData.projectId;
    }
  }

  cancel() {
    this.dialogRef.hide();
  }

  unShareS3(){
    this.loader = true;
    let data = {
      "action":this.s3ShareData.action,
      "owner": this.s3ShareData.user,
      "user": this.researcher,
      "ppId": this.s3ShareData.ProvisionedProductId,
      "account_id": this.s3ShareData.account_id,
      "service":this.s3ShareData.service,
      "projectId": this.projectId,
      "provisionedProductId": this.provisionedProductId
     }
    this.serviceAction.serviceAction(data).subscribe(res => {
      this.dialogRef.hide();
      this.loader = false;
      this.router.navigate(['/product/' + this.projectName + '/' +
      this.provisionedProductName + '/' + 'linkResource'+ '/' +'Success' , { ppId: this.provisionedProductId }],{ queryParams: { projectStatus: this.projectStatus}});
      this.toastr.success(`${this.s3ShareData.ProvisionedProductName} : unshare process initiated successfully`, '', this.globals.tosterOverride);
    }, error => {
      this.dialogRef.hide();
      let errBody = error.error;
      if(error && error.status === 401){
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
       }else{
      this.toastr.error(errBody.error,'',this.globals.tosterOverride)
       }
    });
  }

}
