<div #projectActionWindow *ngIf="projectAction ==='Paused'" tabindex="-1">
  <div class="dialog-header">
      <img class="cancel-icon" (click)="cancel()" src="../../assets/images/cancel@2x.png" alt="Cancel"/>
      <h3 class="title">Pause {{projectName}} project</h3>
  </div>
  <div style="display: flex; flex-direction: row; margin-top: 0px;">
    <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
  </div>
  <div class="content" *ngIf='!loader'>
    <h2 class="content-1">Are you sure you want to pause  <h3 class="content-2"> "{{ projectName}}"</h3> project? </h2>
  </div>
  <div class="warningData" *ngIf='!loader'>
    <h2 class="war-msg">In the paused state, provisioning of new products is not allowed. Users can continue to use 
      already provisioned resources as before. </h2>
  </div>
  <div class="mdl-dialog__actions" style="margin-top: 25px; margin-bottom: 25px" *ngIf='!loader'>
    <button class="btn btn-primary dele-btn" type="button" (click)="projectActions()"><h2 class="del-text">Pause</h2></button>
    <button class="btn  can-btn" type="button"  (click)="cancel()"><h2 class="cal-text">Cancel</h2></button>
  </div>
</div>

<div #projectActionWindow *ngIf="projectAction ==='Active' && projectStatus === 'Paused' " tabindex="-1">
  <div class="dialog-header">
      <img class="cancel-icon" (click)="cancel()" src="../../assets/images/cancel@2x.png" alt="Cancel"/>
      <h3 class="title">Resume {{projectName}} project</h3>
  </div>
  <div style="display: flex; flex-direction: row; margin-top: 0px;">
    <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
  </div>
  <div class="content" *ngIf='!loader'>
    <h2 class="content-1">Are you sure you want to resume  <h3 class="content-2"> "{{ projectName}}"</h3> project? </h2>
  </div>
  <div class="warningData" *ngIf='!loader'>
    <h2 class="war-msg">Once the project is resumed, the user will be able to launch new products from the catalog.</h2>
  </div>
  <div class="mdl-dialog__actions" style="margin-top: 25px; margin-bottom: 25px" *ngIf='!loader'>
    <button class="btn btn-primary dele-btn" type="button" (click)="projectActions()"><h2 class="del-text">Resume</h2></button>
    <button class="btn  can-btn" type="button"  (click)="cancel()"><h2 class="cal-text">Cancel</h2></button>
  </div>
</div>

<div #projectActionWindow *ngIf="projectAction ==='Active' && projectStatus === 'Stopped' " tabindex="-1">
  <div class="dialog-header">
      <img class="cancel-icon" (click)="cancel()" src="../../assets/images/cancel@2x.png" alt="Cancel"/>
      <h3 class="title">Resume {{projectName}} project</h3>
  </div>
  <div style="display: flex; flex-direction: row; margin-top: 0px;">
    <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
  </div>
  <div class="content" *ngIf='!loader'>
    <h2 class="content-1">Are you sure you want to resume  <h3 class="content-2"> "{{ projectName}}"</h3> project? </h2>
  </div>
  <div class="warningData" *ngIf='!loader'>
    <h2 class="war-msg">The project was in stopped state. Please inform the user to manually start the resources.</h2>
  </div>
  <div class="mdl-dialog__actions" style="margin-top: 25px; margin-bottom: 25px" *ngIf='!loader'>
    <button class="btn btn-primary dele-btn" type="button" (click)="projectActions()"><h2 class="del-text">Resume</h2></button>
    <button class="btn  can-btn" type="button"  (click)="cancel()"><h2 class="cal-text">Cancel</h2></button>
  </div>
</div>

<div #projectActionWindow *ngIf="projectAction ==='Stopped'" tabindex="-1">
  <div class="dialog-header">
      <img class="cancel-icon" (click)="cancel()" src="../../assets/images/cancel@2x.png" alt="Cancel"/>
      <h3 class="title">Stop {{projectName}} project</h3>
  </div>
  <div style="display: flex; flex-direction: row; margin-top: 0px;">
    <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
  </div>
  <div class="content" *ngIf='!loader'>
    <h2 class="content-1">Are you sure you want to stop  <h3 class="content-2"> "{{ projectName}}"</h3> project? </h2>
  </div>
  <div class="warningData" *ngIf='!loader'>
    <h2 class="war-msg">In the stopped state, all the underlying resources will be stopped (not Terminated) and the user will 
      not be able to perform actions on them.</h2>
  </div>
  <div class="mdl-dialog__actions" style="margin-top: 25px; margin-bottom: 25px" *ngIf='!loader'>
    <button class="btn btn-primary dele-btn" type="button" (click)="projectActions()"><h2 class="del-text">Stop</h2></button>
    <button class="btn  can-btn" type="button"  (click)="cancel()"><h2 class="cal-text">Cancel</h2></button>
  </div>
</div>

<app-loader class="spinner" *ngIf="loader"></app-loader>