import { Component, OnInit } from '@angular/core';
import { DashboardService } from './../_services/dashboard.service';
import { ResearchService } from "../_services/research.service";
import { Globals } from "../globals";
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

@Component({
  selector: 'app-billing-account',
  templateUrl: './billing-account.component.html',
  styleUrls: ['./billing-account.component.css'],
  providers: [DashboardService, Globals]
})
export class BillingAccountComponent implements OnInit {

  currencySymbol: string = '$';
  loader: boolean = true;
  userLevel;
  orgId;
  accountBills;
  accountBillsArray;
  accountCount;
  orgCount;
  currentMonthBilling: any = 0;
  currentMonthForecast: any = 0;
  page = 1;
  pageSize = 10;
  currentPageLowerCount = 1;
  currentPageUpperCount = 10;
  totalPages;
  total;

  constructor(
    private apiService: DashboardService, private globals: Globals,
    private researchApi: ResearchService, private router: Router, private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.userLevel = sessionStorage.getItem('Level');
    this.orgId = sessionStorage.getItem('org_id')
    this.getBillingAccounts();
  }

  getBillingAccounts() {    
    this.researchApi.getAccountBills(this.page, this.pageSize).subscribe(res => {
      this.loader = false;
      this.accountBills = res[0];
      this.accountBillsArray = this.accountBills.docs;
      this.totalPages = this.accountBills.pages;
      this.total = this.accountBills.total;
      this.accountCount = this.accountBills.total;
      this.page = this.accountBills.page;
      this.pageSize = this.accountBills.limit;
      if (this.userLevel == '2') {
        this.orgCount = this.accountBills.orgCount;
      }
      this.currentMonthBilling = (this.accountBills.totalCurrentMonthCost).toFixed(2);
      if (this.userLevel == '1') {
        this.currentMonthForecast = (this.accountBills.totalCurrentMonthForeCast).toFixed(2);
      }      
      //Calculation for lower & upper count per page
      this.currentPageLowerCount = 1 + ((this.page - 1) * this.pageSize);
      if (this.accountBillsArray.length < 10) {
        this.currentPageUpperCount = this.accountBillsArray.length + (this.pageSize * (this.page - 1));
      } else {
        this.currentPageUpperCount = this.pageSize * this.page;
      }
    }, error => {
      if (error && error.status === 403) {
        let errBody = error.error;
        this.toastr.error(errBody.error, '', this.globals.tosterOverride);
        this.router.navigate(['pageNotFound']);
      } else if (error.status === 500) {
        this.router.navigate(['pageNotFound']);
        this.toastr.error(error.error, '', this.globals.tosterOverride);
      } else {
        let errBody = error.error;
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
      }
    })
  }
  getNextBills(action) {
    if (action === 'first' && this.page !== 1) {
      this.page = 1;
      this.getBillingAccounts();
    }
    if (action === 'previous' && this.page > 1) {
      this.page = this.page - 1;
      this.getBillingAccounts();
    }
    if (action === 'next' && this.page !== this.totalPages) {
      this.page = this.page + 1;
      this.getBillingAccounts();
    }
    if (action === 'last' && this.page !== this.totalPages) {
      this.page = this.totalPages;
      this.getBillingAccounts();
    }
  }
  addSettings(): void {    
    sessionStorage.setItem('accountType','project');
    this.router.navigate(['/addAccount']);
  }

  convertDateToString(date) {
    if(date !== 0) {
      var sysCreatedOn =  moment(date).format('DD/MM/YYYY, h:mm:ss ')
      return sysCreatedOn;
    } else {
      return '-'
    }
  }
}
