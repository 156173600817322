
    <mdl-layout-header-row>
      <mdl-layout-title class="navigate_research" (click)="navigateToResearch()">
        <img src="../../assets/images/RGLogo.png" alt="Research Gateway" [ngClass]="userType==='researcher'? 'researcher-navlogo' : 'navlogo'">
      </mdl-layout-title>
      <mdl-layout-spacer></mdl-layout-spacer>
      <nav class="mdl-navigation" *ngIf = "authGuard.isloggedin() && showUserName">
<!--        <div class="dropdown" *ngIf="showMenu() && (this.userRoles && this.userRoles.length > 1)" joyrideStep="firstStep" title="Switch Role" text="To view product catalog and provisioned products switch role to researcher from the role drop-down in the top bar.">-->
<!--          <button title="{{userType}}" class="tenant-cl btn btn-primary dropdown-toggle switch-wrapper" type="button" data-toggle="dropdown">-->
<!--            <img class="switch-icon" src="../../assets/images/user-switch.png" />-->
<!--            <span class="menu-btn">-->
<!--              <span class="menu-btn-text switch-btn-text" *ngIf="userType === 'principal'">Principal Investigator</span>-->
<!--              <span class="menu-btn-text switch-btn-text" *ngIf="userType === 'researcher'">Researcher</span>-->
<!--            </span>-->
<!--            <em class="fa fa-angle-down white menu-cal-f" style="font-size: 20px;"></em>-->
<!--          </button>-->
<!--          <ul class="dropdown-menu switch-menu">-->
<!--            <button type="button" class="btn menu-item-btn" (click)="switchUserRole('principal')"><a class="menu-item-btn switch-menu-item" href="/principal">Principal Investigator</a></button>-->
<!--            <button type="button" class="btn menu-item-btn" (click)="switchUserRole('researcher')"><a class="menu-item-btn switch-menu-item" href="/researcher">Researcher</a></button>-->
<!--          </ul>-->
<!--        </div>-->
        <div class="dropdown">
          <button title="{{displayName}}" class="tenant-cl btn btn-primary dropdown-toggle menu-dropdown-content" type="button" data-toggle="dropdown">
            <span class="menu-btn">
              <span *ngIf="this.photo !== 'undefined' && this.photo !== null && this.photo !== ''"><img style="border-radius: 50%; height: 30px" src={{this.photo}} alt="Profile Picture"></span>
              <span *ngIf="this.photo === 'undefined' || this.photo === null || this.photo === ''"><mdl-icon>account_circle</mdl-icon></span>
              <span class="menu-btn-text username" *ngIf="displayName">{{(displayName.length > 14) ? (displayName | slice:0:14)+ '...':(displayName)}}</span>
            </span>
            <em class="fa fa-angle-down white menu-cal-f" style="font-size: 20px; margin-left: 10px;"></em>
          </button>
          <ul class="dropdown-menu">
            <button type="button" *ngIf="showMenu()" class="btn menu-item-btn" routerLink='/setting'>Settings</button>
            <button type="button" class="btn menu-item-btn" (click)='getHelp()'>Help</button>
            <!-- <button *ngIf="showAboutMenu===true" type="button" class="btn menu-item-btn" (click)='navigateToShowRenewalDate()'>About</button> -->
            <button type="button" class="btn menu-item-btn" (click)='logout()'>Sign out</button>
          </ul>
        </div>
      </nav>
      <nav class="mdl-navigation" *ngIf = "!authGuard.isloggedin() && showChatAssist">
        <div class="chatAssist" tabindex="0" (click)="openChatAssistant()" (keypress)="openChatAssistant()">
          <img src={{chatAssistantLogo}} alt="Chat With Expert" class="chatAssistLogo" 
          title="Chat with our Generative-AI based BOT to get clarifications on Research Gateway">
        </div>
      </nav>
    </mdl-layout-header-row>
