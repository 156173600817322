<div id="ssh-conn-header" class="shh-con-head">
  <div class="ssh-configure-window">
    <div id='instanceSSHPage'>

      <div class="modal-header">
        <h2 class="modal-title p-header" id="myModalLabel">
          SSH Terminal - {{ getInstanceIp }}
        </h2>
      </div>
      <div style="display: flex; flex-direction: row; margin-top: 10px;">
        <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
        <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
        <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
        <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
      </div>
      <div class="modal-body ssh-model-body p-body">
        <fieldset>
          <div id="ssh-loadingContainer">
            <div class="txt-cent">
              <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
          </div>
          <form class="forms" id="ssh-login-form">
            <input type="hidden" id="ssh-instanceId" value={{getInstanceIp}}>
            <!--        <input type="hidden" id="ssh-sessionUser" value={{appDetails.cn}}>-->
            <div class="col-lg-12 col-md-12 credentialSection">
              Username
              <span class="control-label red">*</span>
              <input aria-label="sshWindowUserName" required name="usernameF" [(ngModel)]="usernameF" type="text" id="ssh-username" value="" autocomplete="off" class="form-control c-file-in"/>
              <b class="tooltip tooltip-top-right">
                <i class="fa fa-user txt-color-teal"></i>
                Please enter Instance Username
              </b>
            </div>
            <div class="col-lg-12 col-md-12 credentialSection sshStyle">
              Select Authentication Type
              <span class="control-label red">*</span>
              <select [(ngModel)]="pemFileCheckboxF" required name="pemFileCheckbox" id="ssh-pemFileCheckbox" class="chooseAuthenticationtype width-100 form-control c-file-in">
                <option id="authPassword" value="Password">Password</option>
                <option id="pemFileCheckbox" value="pemFile">Pem File</option>
              </select>
            </div>
            <div class="col-lg-12 col-md-12 credentialSection passwordCheck sshStyle">
              Password
              <span class="control-label red">*</span>
              <input aria-label="InstancePasswordInputField" required name="password" [(ngModel)]="passwordF" type="password" id="ssh-password" value="" autocomplete="off" class="form-control" />
              <b class="tooltip tooltip-top-right">
                <i class="fa fa-lock txt-color-teal"></i>
                Please enter Instance Password
              </b>
            </div>
            <div class="col-lg-12 col-md-12 pemFileCheck sshStyle">
              <div class="smart-forms">
                <span class="control-label">Pem File</span>
                <span class="control-label red">*</span>
                <label name="field" class="file form-control file-lab-red c-file-label" id="ssh-pemfileInput-lab">
                  <input required id="ssh-pemfileInput" type="file" class="gui-file c-file-input">
                  <span id="ssh-fileName" class="c-file-span-fn">No file selected</span>
                  <span class="c-file-span">Choose a file</span>
                </label>
              </div>
            </div>
            <div class="col-lg-12 ssh-error-area">
            </div>
            <br>
          </form>
          <input aria-label="keyPairSelectionButtonIcon" type="button" style="display:none">
          <input type="hidden" id="ssh-actionLogId" />
<!--          <div id="ssh-terminalContainer" style="display:none"></div>-->

        </fieldset>
      </div>
      <div class="modal-footer">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 importErrorMsg text-right error-padding">
          <span class="ssh-errorArea" id="ssh-errorArea"></span>
        </div>
        <div class="marginForButtons l-footer">
          <!--      <button id="" (click)="onNoClick()" data-dismiss="modal" aria-label="Close" type="button" class="btn cat-btn-close ssh-btn ssh-terminateBtn"  >-->
          <!--          Close-->
          <!--      </button>-->
          <button type="button" class="btn cat-btn-update sshSubmit ssh-btn l-submit">
            Submit
          </button>
          <!--      <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">-->
          <!--        Launch demo modal-->
          <!--      </button>-->
        </div>
      </div>
    </div>
  </div>
</div>
<div id="ssh-win-box">
  <div class="ssh-top">
    <h2 class="modal-title p-header ssh-top-header" id="">
      SSH Terminal - {{ getInstanceIp }}
    </h2>
    <button (click)="terminate()" type="button" class="btn cat-btn-update ssh-btn l-terminate">
      Terminate
    </button>
  </div>
  <div class="term-container" id="ssh-terminalContainer" style="display:none">

  </div>
</div>
