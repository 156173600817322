<div class="container">
  <!-- breadcrumb -->
  <!-- <div class="fixed-header"> -->
  <nav aria-label="breadcrumb">
      <ol class="breadcrumb bc-custom" style="padding-left: 0px !important">
        <li class="breadcrumb-item"><a class="bc-item-active"> Catalog </a></li>
      </ol>
  </nav>
  <div class="project-title-layout pb-3" style="border-bottom: 1px solid #465374 !important">
    <h3 class="box-title">Catalog <h3 class="box-title" *ngIf="!showSpinner">&nbsp;( {{catalogCount}} )</h3></h3>
    <div style="display: flex;margin-bottom: 5px;">
      <div class="dropdown-a">
        <div class="dropdown">
          <button class="btn header-menu-ft dropdown-toggle" type="button" data-toggle="dropdown">
            <span class="menu-btn">
              <span class="menu-btn-text menu-cal-c">View : {{getOrgName(categoryFilter)}}</span>
              <em class="fa fa-angle-down white menu-cal-f" style="margin-left: 40px;font-size: 20px;"></em>
            </span>
            <span class="menu-btn-mobile">
              <span class="menu-btn-text menu-cal-c">{{getOrgNameMobile(categoryFilter)}}</span>
              <em class="fa fa-angle-down white menu-cal-f" style="font-size: 15px;"></em>
            </span>
          </button>
          <ul class="dropdown-menu filter-dd">
            <button (click)="getCatalogItems('all'); categoryFilter = 'Standard Catalog'" type="button" class="btn menu-item-btn filter-log"> Standard Catalog </button>
            <button (click)="getCatalogItems(data._id); categoryFilter = data.org_name" *ngFor="let data of orgList;" type="button" class="btn menu-item-btn filter-log"> {{data.org_name}}</button>
          </ul>
        </div>
      </div>
      
      <div class="create-study-container"   *ngIf="categoryFilter == 'Standard Catalog'" (click)="assignToOrg()" [ngClass]="{ 'create-study-container-disabled': disableAssign,  'create-study-container': ! disableAssign}">
        <div class="create-study-select-wrapper">
          <div class="create-study-select">
              <div class="create-study__trigger">
                <span class="assignOU">Assign selected to O.U.</span>
              </div>
          </div>
        </div>
      </div>

      <div class="create-study-container"  *ngIf="categoryFilter != 'Standard Catalog'" (click)="updateToOrg()" [ngClass]="{ 'create-study-container-disabled': disableAssign,  'create-study-container': ! disableAssign}">
        <div class="create-study-select-wrapper">
          <div class="create-study-select">
              <div class="create-study__trigger">
                <span class="assignOU" >Update selected to O.U.</span>
              </div>
          </div>
        </div>
      </div>

    </div>
  </div>
<!-- </div> -->
  <div class="catalog-info">
    <h3 class="catalog-box-title" *ngIf="categoryFilter == 'Standard Catalog'">
      Add Products to the Organizational Unit Catalog <br>
      <h3 class="catalog-box-title-info">Select all the products that you want to make available for an Organizational Unit</h3>
    </h3>
    <h3 class="catalog-box-title" *ngIf="categoryFilter != 'Standard Catalog'">
      Products in {{categoryFilter}} Organizational Unit<br>
      <h3 class="catalog-box-title-info">View and update the available products for {{categoryFilter}} Organizational Unit</h3>
    </h3>
    <div class="search-div">
      <div style="display: flex;">
        <em class="glyphicon glyphicon-search search-icon"></em>
        <input (keyup)="searchCatalogItems()" [(ngModel)]="searchKey" aria-label="searchCatalogItems" class="form-control search-inp search-inp-add" placeholder="Search" type="text">
      </div>
      <div style="display: flex; align-items: center;">
        <div class="vertical-divider"></div>
          <div class="custom-select-wrapper">
              <div class="custom-select">
                  <div class="custom-select__trigger"><span class="filterData" title="{{selectedFilter}}">{{getFilterName(selectedFilter)}}</span>
                      <div class="arrow"></div>
                  </div>
                  <div class="custom-options">
                      <span class="custom-option" [ngClass]="{'selected': filter === selectedFilter}" *ngFor="let filter of productTypes" data-value="filter" (click)="onChangeFilter(filter)" title="{{filter}}">{{getFilterName(filter)}}</span>
                  </div>
              </div>
          </div>
      </div>
    </div>
  </div>
  <div class="catalog-info-mobile">
    <h3 class="catalog-box-title" *ngIf="categoryFilter == 'Standard Catalog'">
      Add Products to the Organizational Unit Catalog <br>
      <h3 class="catalog-box-title-info">Select all the products that you want to make available for an Organizational Unit</h3>
    </h3>
    <h3 class="catalog-box-title" *ngIf="categoryFilter != 'Standard Catalog'">
      Products in {{categoryFilter}} Organizational Unit<br>
      <h3 class="catalog-box-title-info">View and update the available products for {{categoryFilter}} Organizational Unit</h3>
    </h3>
    <div class="mobile-search-div">
      <div style="display: flex;">
        <em class="glyphicon glyphicon-search search-icon"></em>
        <input (keyup)="searchCatalogItems()" [(ngModel)]="searchKey" aria-label="searchCatalogItemsMobileView" class="form-control search-inp search-inp-add" placeholder="Search" type="text">
      </div>
      <div style="display: flex; align-items: center;">
        <div class="vertical-divider"></div>
          <div class="mobile-custom-select-wrapper">
              <div class="mobile-custom-select">
                  <div class="mobile-custom-select__trigger"><span>{{getFilterName(selectedFilter)}}</span>
                      <div class="mobile-arrow"></div>
                  </div>
                  <div class="mobile-custom-options">
                      <span class="mobile-custom-option" [ngClass]="{'selected': filter === selectedFilter}" *ngFor="let filter of productTypes" data-value="filter" (click)="onChangeFilter(filter)">{{getFilterName(filter)}}</span>
                  </div>
              </div>
          </div>
      </div>
    </div>
  </div>
  <app-loader class="spinner" *ngIf="showSpinner"></app-loader>
  <div class="col-md-12 col-sm-12 my-product-container" *ngIf="!showSpinner && availableProductsToShow.length > 0">
    <div class="available-product-card" *ngFor="let availableProduct of availableProductsToShow;let i = index;" [ngClass]="{ 'catalog-selected': availableProduct.selected }">
      <div style="display: flex;">
        <img class="available-product-img" src="{{availableProduct.imageUrl}}" alt="{{availableProduct.name}} Product"/>
        <div class="available-product-card-name">
          <label
            class="text-white font-class available-product-title available-product-title-max-line" title="{{availableProduct.name}}">{{orgName(availableProduct.name)}}</label>
          <label class="text-white font-class tablet-view available-product-title-tab available-product-title-max-line-tab" title="{{availableProduct.name}}">{{getOrgNameTab(availableProduct.name)}}</label>
          <div class="know-more-wrapper">
            <div class="available-prod-view-details-layout" (click)="openKnowMore(availableProduct.detailsLink)">
              <img style="height: 15px; width: 15px;" src="../../assets/images/info.png" alt="Know More About {{availableProduct.name}} Product"/>
              <span class="available-prod-view-details-text">Know more</span>
            </div>
          </div>
        </div>
        <input id= `checkbox-`${{i}} class="checkbox-custom" name=`checkbox-`${{i}} type="checkbox" [(ngModel)]="availableProduct.selected" 
        [checked]="availableProduct.selected" (change)="onCheckboxChanged($event, availableProduct)">
        <label for= `checkbox-`${{i}} class="checkbox-custom-label" [ngClass]="{ 'checkbox-custom-disabled': availableProduct.isDisabled }">
          <span style="display: none;">-</span>
        </label>
      </div>
      <div>
        <label style="-webkit-box-orient: vertical !important"
          class="text-white font-class available-product-desc-max-line available-product-description line-clamp" title="{{availableProduct.description}}">{{getDescription(availableProduct.description)}}</label>
      </div>
    </div>
</div>
<div *ngIf="availableProductsToShow && availableProductsToShow.length === 0 && !showSpinner" class="empty-my-product-layout-data" style="margin-top: 20px">
  <h3 class="no-data-found text-white font-class text-center noDataText">{{noDataTemplate}}</h3>
</div>
</div>
