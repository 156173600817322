import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {ResearchService} from "../_services/research.service";
import {Globals} from "../globals";

@Component({
  selector: 'app-audit-trail',
  templateUrl: './audit-trail.component.html',
  styleUrls: ['./audit-trail.component.css'],
  providers: [Globals]
})
export class AuditTrailComponent implements OnInit {

  constructor(private researchService: ResearchService, private router: Router,
              private toastr: ToastrService, private globals: Globals,
              private activatedRoute: ActivatedRoute) { }
  searchKey = '';
  auditOrgNameList = [];
  currentPage = 1;
  limit = 10;
  showSpinner = false;
  checkBoxValue = [];
  searchExecKey = '';
  showLoadMore = false;
  noData = false;
  searchValid = false;
  noDataTemplate = 'No organization are configured';
  ngOnInit() {
    this.getAuditOrganisation();
  }

  private getAuditOrganisation() {
    this.showSpinner = true;
    this.noData = false;
    this.researchService.getAuditOrganisation(this.currentPage, this.limit, this.searchKey).subscribe(res => {
      this.showSpinner = false;
      this.showLoadMore = false;
      const validSearch = this.searchKey.trim();
      if (this.searchKey) {
        this.noDataTemplate = 'No matching organizations found';
      }
      if (validSearch && this.currentPage === 1) {
        this.auditOrgNameList = [];
      }
      const resArr = res[0];
      if (!resArr) {
        this.toastr.error('Error in fetching data', 'Error', this.globals.tosterOverride);
      }
      const resData = resArr.data || [];
      const resMetaData = resArr.metadata[0];
      let resTotal = 0;
      if (resMetaData) {
        resTotal = resMetaData.total;
      }
      this.auditOrgNameList = [].concat(this.auditOrgNameList, resData);
      if (this.auditOrgNameList.length < resTotal) {
        this.showLoadMore = true;
      }
      if (this.auditOrgNameList.length === 0) {
        this.noData = true;
      }
    }, error => {
      this.showSpinner = false;
      console.log(error);
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
     else if(error && error.status === 403){
      const err = error.error;
      this.toastr.error(err.error, 'Error', this.globals.tosterOverride);
      this.router.navigate(['pageNotFound']);
      }
    });
  }

  deleteKeypair() {
    const payload = { auditOrgName : this.checkBoxValue };
    this.showSpinner = true;
  }

  getProjectName(data) {
    let projectName = '-';
    if (data) {
      projectName = data['name'] || '-';
    }
    return projectName;
  }

  searchKeypair() {
    const searchKey = this.searchKey.trim() || '';
    if (searchKey.length >= 3 && this.searchExecKey !== searchKey)  {
      this.searchExecKey = searchKey;
      this.searchValid = true;
      this.refresh();
    } else if (searchKey.length === 0 && this.searchExecKey !== searchKey) {
      this.searchExecKey = searchKey;
      this.searchValid = true;
      this.refresh();
    } else {
      this.searchValid = false;
    }
  }

  refresh() {
    this.currentPage = 1;
    this.auditOrgNameList = [];
    this.getAuditOrganisation();
  }

  loadMoreEvents() {
    this.currentPage = this.currentPage + 1;
    this.getAuditOrganisation();
  }

  projectName(data) {
    const strLen = data.length;
    let str = data;
    if (strLen > 30) {
      str = str.slice(0, 30) + '...';
    }
    return str;
  }

  navigateToAuditDetails(orgId, orgName) {
    this.router.navigate([`/audittrail/${orgId[0]}/${orgName}`]);
  }
}
