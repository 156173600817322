<div class="container font-class">
  <div class="project-header">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb bc-custom">
        <li class="breadcrumb-item"><a class="bc-item" routerLink="/researcher">My Projects</a></li>
        <li class="breadcrumb-item active bc-item-active" aria-current="page">{{projectName}}</li>
      </ol>
    </nav>
    <div class="project-status-wrapper">
      <label class="project-text font-class text-white">Project Status :</label>
      <div *ngIf="projectStatus === 'Active'" class="status-info status-running"><label
        class="status-text">{{projectStatus}}</label></div>
      <div *ngIf="projectStatus === 'Paused'" class="status-info status-paused"><label
        class="status-text">{{projectStatus}}</label></div>
      <div *ngIf="projectStatus === 'Stopped'" class="status-info status-stopped"><label
        class="status-text">{{projectStatus}}</label></div>
    </div>
  </div>

  <!-- <div class="project-info">
    <h3 class="project-name font-class text-white">{{projectName}}</h3>
    <div style="display: flex; justify-content: center; align-items: center">
        <label class="project-text font-class text-white">Project Status :</label>
        <div *ngIf="projectStatus === 'Active'" class="status-info status-running"><label class="status-text">{{projectStatus}}</label></div>
        <div *ngIf="projectStatus === 'Paused'" class="status-info status-paused"><label class="status-text">{{projectStatus}}</label></div>
        <div *ngIf="projectStatus === 'Stopped'" class="status-info status-stopped"><label class="status-text">{{projectStatus}}</label></div>
    </div>
  </div> -->
  <div style="transform-origin: center top;">
    <div class="header">
      <div class="header-body">
        <div class="kpi-container">

          <div class="kpi-card">
            <div class="card bg-gradient-info border-0">
              <div class="card-body">
                <div class="row">
                  <div class="col card-bx">
                    <div class="kpi-card-header">
                        <span class="h2 font-weight-bold mb-0 text-white card-bx-ft card-value">$
                          <span class="h2 font-weight-bold mb-0 text-white card-bx-ft card-value"
                                *ngIf="!showSpinner">{{budgetConsumed}}</span>
                        </span>
                      <img alt="Total Project Direct Cost" class="kpi-card-icon" src="../../assets/images/available_budget.png"/>
                    </div>
                    <div class="kpi-info">
                      <h5 class="card-title text-muted mb-0 text-white card-bx-se font-class">Total Project Direct Cost</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="kpi-card">
            <div class="card bg-gradient-danger border-0">
              <div class="card-body">
                <div class="row">
                  <div class="col card-bx">
                    <div class="kpi-card-header">
                              <span class="h2 font-weight-bold mb-0 text-white card-bx-ft card-value">$
                                <span class="h2 font-weight-bold mb-0 text-white card-bx-ft card-value"
                                      *ngIf="!showSpinner">{{budgetConsumedByResearcher}} <span *ngIf="consumedBudgetPercentage >= thresholdPercentage"><i class="fa fa-exclamation-triangle"></i></span></span>
                              </span>
                      <img alt="My Total Direct Cost" class="kpi-card-icon" src="../../assets/images/consumed_budget.png"/>
                    </div>
                    <div class="kpi-info">
                      <h5 class="card-title text-muted mb-0 text-white card-bx-se font-class">My Total Direct Cost</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="kpi-card">
            <div class="card bg-gradient-default border-0">
              <div class="card-body">
                <div class="row">
                  <div class="col card-bx">
                    <div class="kpi-card-header">
                          <span class="h2 font-weight-bold mb-0 text-white card-bx-ft card-value">$
                            <span class="h2 font-weight-bold mb-0 text-white card-bx-ft card-value"
                                  *ngIf="!showSpinner">{{monthlyBudgetConsumedByResearcher}}</span>
                          </span>
                      <img alt="My Current Month Direct Cost" class="kpi-card-icon" src="../../assets/images/consumed_project_budget.png"/>
                    </div>
                    <div class="kpi-info">
                      <h5 class="card-title text-muted mb-0 text-white card-bx-se font-class">My Current Month Direct Cost</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="my-product-header-layout">
      <h4 class="pr-tit text-white font-class">My Products <h4 class="product-count-text text-white font-class" *ngIf="!showSpinner">&nbsp;( {{activeProductCount}} )</h4></h4>
      <div class="my-product-divider"></div>
      <button (click)="navigateAllActiveProduct()" class="custom-btn font-class" name="button" type="button">
        View all
      </button>
    </div>
    <div class="my-product-container" *ngIf="activeProducts > 0">
      <div class="my-product-card" *ngFor="let activeProduct of activeProductsToShow"
           (click)="openProductDetails(activeProduct)">
        <div class="card-header">
          <span class="product-title">$ {{ activeProduct.budgetConsumed}} used 
          </span>
          <!-- <div *ngIf="activeProduct.isShared ===true"> <span  title="This folder is currently shared"><em class="fa fa-share-alt" style="    font-size: 30px;
            margin-top: 10px;color: #42A948;"></em></span> </div> -->
          <div class="product-status-layout">
            <span class="text-white font-class status-text">{{getStatus(activeProduct.Status)}}</span>
            <div class="status-indicator {{getStatusColor(activeProduct.Status)}}"></div>
          </div>
        </div>
        <div class="card-info-layout">
          <img class="product-img" src="{{activeProduct.imageUrl}}" alt="My Product {{activeProduct.ProductName}}"/>
          <div>
            <label title="{{activeProduct.ProvisionedProductName}}" class="text-white font-class max-lines product-name">{{ nameTrim(activeProduct.ProvisionedProductName) }}</label>
            <label title="{{activeProduct.ProductName}}" class="font-class product-type-name">{{ nameTrimData(activeProduct.ProductName) }}</label>
          </div>
        </div>
        <div class="shared-layout" *ngIf="activeProduct.isShared === true">
          <div class="share-data" title="This folder is currently shared">
            <img class="share-icon" src="../../assets/images/share-white.png" alt="Shared"/>
            <span class="share-icon-text"> Shared </span>
          </div>
          <div class="view-details-layout">
            <span class="view-details-text">Last update {{activeProduct.updated_on | amTimeAgo}}</span>
          </div>
        </div>
        <div *ngIf="activeProduct.isShared !== true && activeProduct.isProjectStorage != true && activeProduct.isIngressStorage != true" class="view-details-wrapper">
          <div class="view-details-layout">
            <span class="view-details-text">Last update {{activeProduct.updated_on | amTimeAgo}}</span>
          </div>
        </div>
        <div class="shared-layout" *ngIf="activeProduct.isShared !== true && activeProduct.isProjectStorage == true">
            <div class="share-data">
                <i class="fa fa-hdd-o" style="font-size: 2.1em; color: #ffffff;vertical-align: middle;margin-right: 5px;" aria-hidden="true"></i>
                <span class="share-icon-text" style="margin-left: 5px !important;"> Project Storage </span>
              </div>
          <div class="view-details-layout">
            <span class="view-details-text">Last update {{activeProduct.updated_on | amTimeAgo}}</span>
          </div>
        </div>
        <div class="shared-layout" *ngIf="activeProduct.isShared !== true && activeProduct.isIngressStorage == true">
          <div class="share-data">
              <i class="fa fa-hdd-o" style="font-size: 2.1em; color: #ffffff;vertical-align: middle;margin-right: 5px;" aria-hidden="true"></i>
              <span class="share-icon-text" style="margin-left: 5px !important;"> Ingress Storage </span>
            </div>
        <div class="view-details-layout">
          <span class="view-details-text">Last update {{activeProduct.updated_on | amTimeAgo}}</span>
        </div>
      </div>
      </div>
    </div>
    <div *ngIf="activeProducts === 0 && !showSpinner" class="empty-my-product-layout">
      <div>
        <h3 class="no-data-found text-white font-class text-center">You currently don't have any products</h3>
        <h4 class="no-data-found-subtext text-white font-class text-center">Launch your first product from the below
          available products</h4>
      </div>
    </div>
    <div *ngIf="activeProducts === 0 && showSpinner" style="height: 175px;">
    </div>

    <!-- <div class="card-custom btn-sm btn-neutral">
      <div>
        <div class="md-cd-top product-header" style="padding: 0 2px;">
          <h4 class="pr-tit">Available Products</h4>
          <button (click)="navigateAllAvailableProduct()" class="custom-btn pull-right" name="button" type="button">
            <span class="icon ion-ios-cart"></span>
            View All
          </button>
        </div>
        <div *ngIf="availSlider1.length > 0" id="demo1" data-interval="false" class="carousel slide" data-ride="carousel">
          <ul class="carousel-indicators display-none">
            <li data-target="#demo" data-slide-to="0" class="active"></li>
            <li data-target="#demo" data-slide-to="1"></li>
            <li data-target="#demo" data-slide-to="2"></li>
          </ul>
          <div  class="carousel-inner" style=" padding: 0px 70px; ">
            <div *ngIf="availSlider1.length > 0" class="carousel-item active">
              <div class="row">
                <div class="col-sm-6 col-lg-3 col-md-4 col-xs-12" *ngFor="let availPObj of availSlider1">
                  <div class="card card-pro">
                    <div class="card-body m-card">
                      <img class="card-image" src="{{availPObj.imageUrl}}" alt="/assets/images/aws_log.png">
                      <div>
                        <div title="{{availPObj.name}}" class="card-text-align-h">{{ productName(availPObj.name) }}</div>
                        <div title="{{availPObj.shortDescription}}" class="card-desc">{{ productDescription(availPObj.shortDescription) }}</div>
                        <div *ngIf="getKnowMoreURL(availPObj.tags)" ><a class="card-link" target="_blank" href="{{ getKnowMoreURL(availPObj.tags)}}">Know more</a></div>
                        <div class="card-prize-val"></div>
                        <button class="order-btn card-button" (click)="catalogOrder(availPObj)" name="button" type="button">
                          <span class="icon ion-ios-cart"></span>
                          LAUNCH
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="availSlider2.length > 0" class="carousel-item">
              <div class="row">
                <div class="col-sm-6 col-lg-3 col-md-4 col-xs-12" *ngFor="let availPObj of availSlider2">
                  <div class="card card-pro">
                    <div class="card-body m-card">
                      <img class="card-image" src="{{availPObj.imageUrl}}" alt="/assets/images/no-image-icon.png">
                      <div>
                        <div title="{{availPObj.name}}" class="card-text-align-h">{{ productName(availPObj.name) }}</div>
                        <div title="{{availPObj.shortDescription}}" class="card-desc"> {{ productDescription(availPObj.shortDescription) }}</div>
                        <div><a class="card-link" target="_blank" href="{{ getKnowMoreURL(availPObj.tags)}}">Know more</a></div>
                        <div class="card-prize-val"></div>
                        <button class="order-btn card-button" name="button" (click)="catalogOrder(availPObj)" type="button">
                          <span class="icon ion-ios-cart"></span>
                          LAUNCH
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="availSlider3.length > 0" class="carousel-item">
              <div class="row">
                <div class="col-sm-6 col-lg-3 col-md-4 col-xs-12" *ngFor="let availPObj of availSlider3">
                  <div class="card card-pro">
                    <div class="card-body m-card">
                      <img class="card-image" src="{{availPObj.imageUrl}}" alt="/assets/images/no-image-icon.png">
                      <div>
                        <div title="{{availPObj.name}}" class="card-text-align-h">{{ productName(availPObj.name) }}</div>
                        <div title="{{availPObj.shortDescription}}" class="card-desc">{{ productDescription(availPObj.shortDescription) }}</div>
                        <div><a class="card-link" target="_blank" href="{{ getKnowMoreURL(availPObj.tags)}}">Know more</a></div>
                        <div class="card-prize-val"></div>
                        <button class="order-btn card-button" name="button" (click)="catalogOrder(availPObj)" type="button">
                          <span class="icon ion-ios-cart"></span>
                          LAUNCH
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <a class="carousel-control-prev" href="#demo1" data-slide="prev">
              <span class="carousel-control-prev-icon"></span>
            </a>
            <a class="carousel-control-next" href="#demo1" data-slide="next">
              <span class="carousel-control-next-icon"></span>
            </a>
        </div>
        </div>
      </div>
      <div *ngIf="availSlider1.length == 0">
        <img src="/assets/images/no_data.png" alt="/assets/images/no_image-icon.png" style=" width: 15em; ">
        <h3 class="no-data-found">NO DATA FOUND</h3>
        <div>There seems to be no data available to show.</div>
        <div>Try again later.</div>
        <button (click)="getAllAvailProduct()" class="order-btn" name="button" type="button">
          <span class="icon ion-ios-cart"></span>
          TRY AGAIN
        </button>
      </div>
    </div> -->
  </div>
  <br>
  <div>
    <div class="my-product-header-layout">
      <h4 class="pr-tit text-white font-class">Available Products</h4>
      <div class="available-product-divider"></div>
      <button (click)="navigateAllAvailableProduct()" class="custom-btn pull-right font-class" name="button"
              type="button">
        View all
      </button>
    </div>
    <div class="my-product-container">
      <div class="available-product-card" *ngFor="let availableProduct of availableProductsToShow">
        <div style="display: flex;">
          <img alt="Available Product {{availableProduct.name}}" class="available-product-img" src="{{availableProduct.imageUrl}}"/>
          <div style="display: flex; flex-direction: column; width: 100%;">
            <label
              class="text-white font-class available-product-title available-product-title-max-line">{{availableProduct.name}}</label>
            <div class="know-more-wrapper">
              <div [ngClass]="{'available-prod-view-details-layout': availableProduct.detailsLink, 'available-prod-view-details-layout-noLink' : !availableProduct.detailsLink }" (click)="openKnowMore(availableProduct.detailsLink)">
                <img alt="Know More About {{availableProduct.name}} Product" style="height: 15px; width: 15px;" src="../../assets/images/info.png"/>
                <span [ngClass]="{'available-prod-view-details-text': availableProduct.detailsLink, 'available-prod-view-details-text-noLink' : !availableProduct.detailsLink }">Know more</span>
              </div>
              <!-- <span class="available-prod-time-text">ETA: {{getProductETA(availableProduct.tags)}}</span> -->
            </div>
          </div>
        </div>
        <div>
          <label
            class="text-white font-class available-product-desc-max-line available-product-description line-clamp">{{availableProduct.shortDescription}}</label>
        </div>
        <div class="launch-btn-wrapper" *ngIf="projectStatus == 'Active'">
          <div class="launch-btn-layout">
            <button class="launch-btn font-class" (click)="catalogOrder(availableProduct)">LAUNCH NOW</button>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="card-custom btn-sm btn-neutral">
      <div>
        <div class="md-cd-top product-header" style="padding: 0 2px;">
          <h4 class="pr-tit">Active Products</h4>
          <button (click)="getAllActiveProduct()" type="button" class="btn btn-xs btn-dark cat-btn-update refresh-btn btn-black pull-right" title="Refresh" ><em
            class="fa fa-refresh white"></em></button>
          <button (click)="navigateAllActiveProduct()" class="custom-btn pull-right" name="button" type="button" >
            <span class="icon ion-ios-cart"></span>
            View All
          </button>
        </div>
        <div *ngIf="activeSlider1.length > 0" data-interval="false" id="demo" class="carousel slide" data-ride="carousel">

          <div  class="carousel-inner" style=" padding: 0px 70px; ">
            <div *ngIf="activeSlider1.length > 0" class="carousel-item active">
              <div class="row">
                <div class="col-sm-6 col-lg-3 col-md-4 col-xs-12" *ngFor="let availPObj of activeSlider1">
                  <div class="card card-pro">
                    <div class="dropdown {{ getStatusColor(availPObj.state) }}">
                      <span class="card-s-n dropbtn"> {{ getStatus(availPObj.Status) }}
                        <div class="dropdown drop-ws" >
                        <em *ngIf="availPObj.state === 'active' || availPObj.Status.toLowerCase() === 'active' || availPObj.Status.toLowerCase() === 'stopped'" class="fa fa-ellipsis-v card-option" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="getMeunForService(availPObj)"></em>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <a  *ngFor="let menu of servicemenu" class="dropdown-item" (click)="actions(availPObj, menu)">{{ menu }}</a>
                        </div>
                      </div>
                      </span>
                    </div>
                    <div class="card-body m-card-active">
                      <img class="card-image" src="{{ getActiveImg(availPObj.Tags)  }}" alt="/assets/images/aws_log.png">
                      <div style=" text-align: center; ">
                        <div title="{{availPObj.ProvisionedProductName}}" class="card-text-align-h">{{ productName(availPObj.ProvisionedProductName) }}</div>
                        <div title="{{availPObj.ProductName}}" class="card-desc">{{ productName(availPObj.ProductName) }}</div>
                        <div *ngIf="availPObj.isShared ===true"> <span  title="This folder is currently shared"><em class="fa fa-share-alt" style="    font-size: 30px;
                          margin-top: 10px;color: #42A948;"></em></span> </div>
                      </div>
                      <div class="p_status">
                      </div>
                    </div>
                    <div class="card-footer-status">
                      <span class="card-multiple-o card-multiple-ol" (click)="getLogs(availPObj.ProvisionedProductId, false)" title="Information" data-toggle="modal" data-target="#showBotLogs"><em class="fa fa-info-circle"></em></span>
                      <span class="card-multiple-o" (click)="deleteProvisionedProduct(availPObj)" title="Terminate"><em class="fa fa-trash"></em></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="activeSlider2.length > 0" class="carousel-item">
              <div class="row">
                <div class="col-sm-6 col-lg-3 col-md-4 col-xs-12" *ngFor="let availPObj of activeSlider2">
                  <div class="card card-pro">
                    <div class="dropdown {{ getStatusColor(availPObj.state) }}">
                      <span class="card-s-n dropbtn"> {{ getStatus(availPObj.Status) }}
                        <div class="dropdown drop-ws" >
                        <em *ngIf="availPObj.state === 'active' || availPObj.Status.toLowerCase() === 'active' || availPObj.Status.toLowerCase() === 'stopped'"  class="fa fa-ellipsis-v card-option" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="getMeunForService(availPObj)"></em>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                          <a  *ngFor="let menu of servicemenu" class="dropdown-item" (click)="actions(availPObj, menu)">{{ menu }}</a>
                        </div>
                      </div>
                      </span>
                    </div>
                    <div class="card-body m-card-active">
                      <img class="card-image" src="{{ getActiveImg(availPObj.Tags)  }}" alt="/assets/images/aws_log.png">
                      <div style=" text-align: center; ">
                        <div title="{{availPObj.ProvisionedProductName}}" class="card-text-align-h">{{ productName(availPObj.ProvisionedProductName) }}</div>
                        <div title="{{availPObj.ProductName}}" class="card-desc">{{ productName(availPObj.ProductName) }}</div>
                      </div>
                      <div class="p_status">
                      </div>
                    </div>
                    <div class="card-footer-status">
                      <span class="card-multiple-o card-multiple-ol" (click)="getLogs(availPObj.ProvisionedProductId, false)" title="Information" data-toggle="modal" data-target="#showBotLogs"><em class="fa fa-info-circle"></em></span>
                      <span class="card-multiple-o" (click)="deleteProvisionedProduct(availPObj)" title="Terminate"><em class="fa fa-trash"></em></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="activeSlider3.length > 0" class="carousel-item">
              <div class="row">
                <div class="col-sm-6 col-lg-3 col-md-4 col-xs-12" *ngFor="let availPObj of activeSlider3">
                  <div class="card card-pro">
                    <div class="dropdown {{ getStatusColor(availPObj.state) }}">
                      <span class="card-s-n dropbtn"> {{ getStatus(availPObj.Status) }}
                        <div class="dropdown drop-ws" >
                        <em *ngIf="availPObj.state === 'active' || availPObj.Status.toLowerCase() === 'active' || availPObj.Status.toLowerCase() === 'stopped'" class="fa fa-ellipsis-v card-option" id="dropdownMenuButton3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="getMeunForService(availPObj)"></em>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                          <a  *ngFor="let menu of servicemenu" class="dropdown-item" (click)="actions(availPObj, menu)">{{ menu }}</a>
                        </div>
                      </div>
                      </span>
                    </div>
                    <div class="card-body m-card-active">
                      <img class="card-image" src="{{ getActiveImg(availPObj.Tags)  }}" alt="/assets/images/aws_log.png">
                      <div style=" text-align: center; ">
                        <div title="{{availPObj.ProvisionedProductName}}" class="card-text-align-h">{{ productName(availPObj.ProvisionedProductName) }}</div>
                        <div title="{{availPObj.ProductName}}" class="card-desc">{{ productName(availPObj.ProductName) }}</div>
                      </div>
                      <div class="p_status">
                      </div>
                    </div>
                    <div class="card-footer-status">
                      <span class="card-multiple-o card-multiple-ol" (click)="getLogs(availPObj.ProvisionedProductId, false)" title="Information" data-toggle="modal" data-target="#showBotLogs"><em class="fa fa-info-circle"></em></span>
                      <span class="card-multiple-o" (click)="deleteProvisionedProduct(availPObj)" title="Terminate"><em class="fa fa-trash"></em></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <a class="carousel-control-prev" href="#demo" data-slide="prev">
              <span class="carousel-control-prev-icon"></span>
            </a>
            <a class="carousel-control-next" href="#demo" data-slide="next">
              <span class="carousel-control-next-icon"></span>
            </a>
          </div>
        </div>
      </div>
      <div *ngIf="activeSlider1.length == 0">
        <img src="/assets/images/no_data.png" alt="/assets/images/no_image-icon.png" style=" width: 15em; ">
        <h3 class="no-data-found">NO DATA FOUND</h3>
        <div>There seems to be no data available to show.</div>
        <div>Try again later.</div>
        <button (click)="getAllActiveProduct()" class="order-btn" name="button" type="button">
          <span class="icon ion-ios-cart"></span>
          TRY AGAIN
        </button>
      </div>
    </div> -->
    <div *ngIf="availableProducts === 0 && !showSpinner" class="empty-my-product-layout">
      <div>
        <h3 class="no-data-found text-white font-class text-center">No products assigned to the project catalog</h3>
        <h4 class="no-data-found-subtext text-white font-class text-center">Please contact your project owner to assign products that you can use</h4>
      </div>
    </div>
  </div>
  <br>
  <br>
  <br>
</div>


<!-- Bot Logs Modal -->
<div class="modal fade" id="showBotLogs" role="dialog">
  <div class="modal-dialog">
    <!-- Bot Logs Modal-->
    <div class="modal-content">
      <div class="modal-header">
        <h3 style="text-align: left;" class="modal-title">Log History</h3>
        <button type="button" aria-label="closeButtonIcon" class="close btn btn-dark" data-dismiss="modal" (click)="cleanLogs()"><i
          class="fa fa-times" aria-hidden="true"></i></button>
      </div>

      <div class="modal-body">
        <hr>
        <div class="form-group" id="formData"
             style="overflow-y: auto !important;max-height: 300px !important;min-height: 50px !important;">
          <div style="padding-top: 5px">
            <div *ngIf="logLoader" style="text-align: center;">
              <h4><span style="display:block"> Please wait for the logs.</span>
                <br>
                <img alt="Logs History" src="/assets/images/index.bar-chart-preloader.svg">
              </h4>
            </div>
            <div *ngIf="!auditTrail.length">
              <p style="text-align:center">No logs found</p>
            </div>
            <div *ngIf="auditTrail">
              <div class="rowSpacing col-lg-12 col-sm-12 logMsg" *ngFor="let log of auditTrail;">
                <span class="timeStamp ng-binding" style="font-size: 12px"> {{ log.Timestamp | date:'medium' }}
                  : </span>
                <span class="logInfo ng-binding "
                      style="font-size: 12px; color: #565656"> {{ log.ResourceType }}   </span>
                <span class="logInfo ng-binding" style="font-size: 12px; color: #565656"> {{ log.Status }}   </span>
                <span class="logInfo ng-binding" style="font-size: 12px; color: #565656"> {{ log.StatusMessage }}</span>
                <p></p>
              </div>
            </div>
          </div>

        </div>
      </div>
      <!--<div style="width:12%;margin:0 auto">-->
      <!--</div>-->
      <!--<div id="outer">-->
      <!--<div class="inner"><button type="submit" class="msgBtn" onClick="return false;" >Save</button></div>-->
      <!--<div class="inner"><button type="submit" class="msgBtn2" onClick="return false;">Publish</button></div>-->
      <!--<div class="inner"><button class="msgBtnBack">Back</button></div>-->
      <!--</div>-->
      <div class="outer modal-footer">
        <div class="inner">
          <button class="btn btn-black"
                  style="border-radius: 3px !important; color: white; font-size:13px; text-align: center; cursor: pointer;"
                  *ngIf="loadMore" (click)="getLogs(provisionIdForLog, true)">Load more
          </button>
        </div>
        <div class="inner">
          <button *ngIf="auditTrail.length" (click)="getLogs(provisionIdForLog, false)" type="button"
                  class="btn btn-black float-right"
                  style="border-radius: 3px !important; color: white; font-size:13px; text-align: center;"
                  title="Refresh">Refresh
          </button>
        </div>
        <div class="inner">
          <button class="btn btn-black"
                  style="border-radius: 3px !important; color: white; font-size:13px; text-align: center; cursor: pointer;"
                  data-dismiss="modal" (click)="cleanLogs()">Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-loader class="spinner" *ngIf="showSpinner"></app-loader>
