<div class="container">
  <div class="fixed-header">
    <div class="product-name-layout">
      <h3 class="product-name text-white font-class">Create Organization</h3>
      <div class="launch-now-wrapper">
        <button class="launch-btn text-white font-class" [disabled]="!f.valid" name="button"
          (click)="addOrganization()" type="submit">Create Organization
          <i *ngIf="showSpinner" class="fa fa-refresh fa-spin" style="margin-left: 10px;"></i>
        </button>
      </div>
    </div>
  </div>
  <form #f="ngForm" novalidate (ngSubmit)="addOrganization();f.valid" style="margin-top: 100px;">
    <div class="addProjectheader">
      <div class="addNewProject">
        <div style="margin-top: 20px;margin-bottom: 20px; border-bottom: 1px solid #465374;">
          <h3 class="product-desc font-class">Organization Details</h3>
          <h3 class="prod-c">Add the Name and Description of the Organization that you wish to create</h3>
        </div>

        <div style="display: flow-root !important">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="padding: 0px;margin-right: 15px">
            <label for="OrganizationName" class="prov-product-field text-white font-class" style="margin-bottom: 10px;">Organization Name
              <span class="order-st">*</span>
            </label>
            <input id="OrganizationName" required [(ngModel)]="organizationName" pattern="^\S.*$" (keyup)="highlightHelpText(organizationName, 'organizationName')" (focus)="onFocus('organizationName')"
              (keyup)="highlightHelpText(organizationName, 'organizationName')" class="inp-txt user-input" type="text" name="organizationName" placeholder="Please enter your organization name - (required)">
          </div>
          <div *ngIf="showLinkedExternalGroup" class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="padding: 0px;margin-right: 15px; margin-top: 10px; margin-bottom: 15px;">
            <h3 class="prov-product-field text-white font-class" style="margin-bottom: 10px;">Linked Active Directory Group
              <span class="order-st">*</span>
            </h3>
            <input required [(ngModel)]="linkedActiveDirectoryGroup" (keyup)="highlightHelpText(linkedActiveDirectoryGroup, 'linkedActiveDirectoryGroup')" (focus)="onFocus('linkedActiveDirectoryGroup')"
              (keyup)="highlightHelpText(linkedActiveDirectoryGroup, 'linkedActiveDirectoryGroup')" class="inp-txt user-input" type="text" name="linkedActiveDirectoryGroup" placeholder="Please enter Linked Active Directory Group - (required)">
          </div>

          <div *ngIf="showLinkedExternalGroup" class="form-control account-selection-content" style="margin-top: 15px;margin-bottom: 15px; min-height: 130px">
            <span class="selection-header">Use this as Default Organization</span>
              <div class="selection">
                <input [checked]="isDefaultOU" type="checkbox" [id]="isDefaultOU" name="isDefaultOU" (focus)="onFocus('default_OU')" (change)="selectDefaultOu($event); onFocus('default_OU')">
                <label [for]="isDefaultOU" class="selection-label" style="margin-bottom: 10px;">Default Organization</label>
                <br>
                <span class="selection-header"> Any Active Directory user who logs in to Research Gateway but is not assigned to an AD group 
                  corresponding to a Research Gateway OU, will be assigned to this OU.</span>
              </div>
          </div>

          <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="padding: 0px;margin-top: 10px">
            <label for="Organizationdescription" class="prov-product-field text-white font-class" style="margin-bottom: 10px;">Organization Description
              <span class="order-st">*</span>
            </label>
            <textarea id="Organizationdescription" style="padding-left: 20px;padding-top: 10px;" pattern="^\S.*$" required (focus)="onFocus('organizationDescription')" [(ngModel)]="organizationDescription"
              class="inp-txt user-input" type="text" name="organizationDescription" placeholder="Please enter your organization description - (required)"
              maxlength="256" rows="4"> </textarea>
          </div>
        </div>

        <div style="margin-top: 20px;margin-bottom: 20px;border-bottom: 1px solid #465374;">
          <h3 class="product-desc font-class">Account Details
          </h3>
          <h3 class="prod-c">Add an AWS account which you want to use, to provision products in this organization.</h3>
        </div>
        <div class="add-new-btn-container">
          <div class="add-new-btn-select-wrapper">
            <div class="add-new-btn-select">
              <div class="add-new-btn-trigger" (click)="addAccounts()">
                <span class="add-new-txt" style=" margin-right: 10px;">Add Accounts</span>
                <div class="arrow"></div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="loader" style="text-align: center; ">
          <img src="/assets/images/barspreloaders.svg" style="height: 60px">
        </div>

        <div *ngIf="!loader && fAccountIdList.length == 0" class="addAccount text-white font-class">
          <h3 class="noData">
            No Accounts added
          </h3>
        </div>

        <div *ngIf="!loader && fAccountIdList.length > 0" class="form-control account-selection-content" style="margin-top: 15px;margin-bottom: 20px">
          <span class="selection-header">Select an account from the list</span>
          <div>
            <div class="selection" *ngFor="let acc of fAccountIdList; let i = index">
              <input [checked]="acc.checked" type="checkbox" [id]="acc.value" name="acc.value" (focus)="onFocus('add_accounts')"
                (change)="selectAccountIds(acc, $event); onFocus('add_accounts')">
              <label [for]="acc.value" class="selection-label">{{acc.viewValue}}</label>
            </div>
          </div>
        </div>

      <div *ngIf="showAddUser">
        <div style="margin-top: 10px;margin-bottom: 20px; border-bottom: 1px solid #465374;">
          <h3 class="product-desc font-class">Add Users</h3>
          <h3 class="prod-c">Invite collaborators to this project - (optional).</h3>
        </div>

        <div class="add-new-btn-container" style="width: 150px !important;">
          <div class="add-new-btn-select-wrapper">
            <div class="add-new-btn-select">
              <div class="add-new-btn-trigger" (click)="addUser()">
                <span class="add-new-txt" style=" margin-right: 10px;">Add Users</span>
                <div class="arrow"></div>
              </div>
              <div class="add-new-btn-dropdown-options">
                <span class="filter-dropdown-option" data-value="add">Create New User</span>

              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!gotPIList" style="text-align: center; ">
            <img src="/assets/images/barspreloaders.svg" style="height: 60px">
          </div>

        <div *ngIf="gotPIList && principalIdList.length == 0" class="addAccount text-white font-class">
          <h3 class="noData">
            No Users added
          </h3>
        </div>

        <div *ngIf="gotPIList && principalIdList.length > 0 " class="form-control account-selection-content" style="margin-top: 15px;margin-bottom: 20px">
          <span class="selection-header">Select Users from the list</span>
          <div>
            <div class="selection" *ngFor="let acc of principalIdList">
              <input type="checkbox" [id]="acc.value" name="acc.value" (change)="onFocus('add_users');selectPrincipalIds(acc, $event)">
              <label [for]="acc.value" class="selection-label">{{acc.viewValue}}</label>
            </div>
          </div>
        </div>
      </div>

      </div>
      <div class="helpSection">
        <h3 style="color: #3FCB57; font: normal normal 800 18px/32px Nunito Sans;">Help</h3>
        <div class="panel panel-default" *ngFor="let item of sectionWiseHelpdata; let k = index">
          <div class="panel-heading heading" (click)="accordianCtrlNodeX(k)">
            <h5 class="panel-title">
              <table id="BotList-node" class="table table-striped" style="width: 100%;margin-bottom: unset">
                <thead>
                  <tr>
                    <th class="accordian-header" style="width:18%;text-align: initial;" [ngStyle]="{'background-color': '#06153E'}">
                      <span style="color: white; font: normal normal normal 13px/18px Nunito Sans;font-weight: 900">{{item.key}}</span>
                    </th>
                    <td class="accordian-header" style="width:1%" [ngStyle]="{'background-color': '#06153E' }">
                      <em class='glyphicon' style="font-size: 20px;font-weight: 900;color: white" [class.glyphicon-plus]="k != curx" [class.glyphicon-minus]="k == curx"
                        class="accordion-toggle"></em>
                    </td>
                  </tr>
                </thead>
              </table>
            </h5>
          </div>
          <div id="collapse-node" class="panel-collapse" [class.collapse]='k != curx'>
            <table id="topology-info-node" class="table table-striped" style="width: 100%;">
              <tbody class="txt-align">
                <tr>
                  <td style="padding-top:10px;width:20%;background:#030A1E;border: 1px solid #85d4ff;font: normal normal normal 13px/18px Nunito Sans;
                    white-space: pre-wrap !important;
                    color: #FFFFFF;" [innerHTML]="item.value">
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>


        <div class="panel panel-default" *ngFor="let item of helpTextData; let j = index">
          <div class="panel-heading heading" (click)="accordianCtrlNode(j)">
            <h5 class="panel-title">
              <table id="BotList-node" class="table table-striped" style="width: 100%;margin-bottom: unset">
                <thead>
                  <tr>
                    <th class="accordian-header" style="width:18%;text-align: initial;" [ngStyle]="{'background-color': '#06153E'}">
                      <span style="color: white; font: normal normal normal 13px/18px Nunito Sans;font-weight: 900">{{item.key}}</span>
                    </th>
                    <td class="accordian-header" style="width:1%" [ngStyle]="{'background-color': '#06153E' }">
                      <em class='glyphicon' style="font-size: 20px;font-weight: 900;color: white" [class.glyphicon-plus]="j != cur" [class.glyphicon-minus]="j == cur"
                        class="accordion-toggle"></em>
                    </td>
                  </tr>
                </thead>
              </table>
            </h5>
          </div>
          <div id="collapse-node" class="panel-collapse" [class.collapse]='j != cur'>
            <table id="topology-info-node" class="table table-striped" style="width: 100%;">
              <tbody class="txt-align">
                <tr>
                  <td style="padding-top:10px;width:20%;background:#030A1E;border: 1px solid #85d4ff;font: normal normal normal 13px/18px Nunito Sans;
                      white-space: pre-wrap !important;
                      color: #FFFFFF;" [innerHTML]="item.value">
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>

    <!-- Mobile view -->
    <!-- <div class="addProjectheader-mob">
      <tabset #addProjectTabs>
          <tab class="tab" heading="Create Project">
              <div class="addNewProject">
                  <div style="margin-top: 20px;margin-bottom: 20px; border-bottom: 1px solid #465374;">
                      <h3 class="product-desc font-class">Project Details</h3>
                      <h3 class="prod-c">Add the Name and Description of the Project that you wish to create</h3>
                  </div>
                  
                <div style="margin-bottom: 270px;">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="padding: 0px;margin-right: 15px">
                        <h3 class="prov-product-field text-white font-class" style="margin-bottom: 10px;">Project Name <span class="order-st">*</span> 
                          <i class="fa fa-question-circle" aria-hidden="true" style="color: #FFB500;margin-left: 12px;" 
                          (click)= "changeTab();onFocus('project_name')"></i>
                        </h3>
                        <input required [(ngModel)]="projectName" (keypress)="highlightHelpText(projectName, 'projectName')" (focus)="onFocus('project_name')" (keyup)="highlightHelpText(projectName, 'projectName')" class="inp-txt user-input" type="text" name="name" placeholder="Enter your project name - (required)">
                      </div>
                <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="padding: 0px;margin-top: 10px">
                          <h3 class="prov-product-field text-white font-class" style="margin-bottom: 10px;">Project Description <span class="order-st">*</span>
                            <i class="fa fa-question-circle" aria-hidden="true" style="color: #FFB500;margin-left: 12px;" 
                            (click)= "changeTab();onFocus('project_desc')"></i>
                          </h3>
                          <textarea style="padding-left: 20px;padding-top: 10px;" required (focus)="onFocus('project_desc')"
                          [(ngModel)]="projectDescription" class="inp-txt user-input" (keypress)="highlightHelpText(projectDescription, 'projectDescription')" (keyup)="highlightHelpText(projectDescription, 'projectDescription')" type="text" name="projectDescription" placeholder="Enter your project description - (required)" maxlength="256" rows="4"> </textarea>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="padding: 0px;margin-right: 15px; margin-top: 10px;">
                    <h3 class="prov-product-field text-white font-class" style="margin-bottom: 10px;">Budget Available <span class="order-st">*</span>
                      <i class="fa fa-question-circle" aria-hidden="true" style="color: #FFB500;margin-left: 12px;" 
                      (click)= "changeTab();onFocus('project_budget')"></i>
                    </h3>
                    <input class="inp-txt user-input" type="number" min="1" value="5" (focus)="onFocus('project_budget')" (keypress)="highlightHelpText(budgetAvailable, 'budgetAvailable')" (keyup)="highlightHelpText(budgetAvailable, 'budgetAvailable')"
                    placeholder="Enter your budget - (required)" [(ngModel)]="budgetAvailable" #rCEvery="ngModel" required pattern="^([0-9]*[1-9][0-9]*)$"
                    name="budgetAvailable">
                </div>
                </div>
                
                <div style="margin-top: 20px;margin-bottom: 20px;border-bottom: 1px solid #465374;">
                    <h3 class="product-desc font-class">Account Details <span class="order-st">*</span>
                      <i class="fa fa-question-circle" aria-hidden="true" style="color: #FFB500;margin-left: 12px;font-size: 14px;" 
                      (click)= "changeTab();onFocus('add_accounts')"></i>
                    </h3>
                    <h3 class="prod-c" style="width: 50% !important">Add an AWS account which you want to use, to provision products in this project.</h3>
                </div>
                <div class="add-new-btn-container">
                    <div class="add-new-btn-select-wrapper">
                      <div class="add-new-btn-select">
                          <div class="add-new-btn-trigger" ><span class="add-new-txt" style=" margin-right: 10px;">Add Accounts</span>
                              <div class="arrow"></div>
                          </div>
                          <div class="add-new-btn-dropdown-options">
                              <span class="filter-dropdown-option" data-value="add" (click)="addUser()">Add account</span>
        
                          </div>
                      </div>
                    </div>
                  </div>
                
                <div *ngIf="fAccountIdList.length == 0" class="addAccount text-white font-class" (click)="onFormSubmit(formedParams)" name="button" type="button">No Accounts added</div>
        
                <div *ngIf="fAccountIdList.length > 0" class="form-control account-selection-content" style="margin-top: 15px;margin-bottom: 15px">
                    <span class="selection-header">Select an account from the list</span>
                    <div>
                      <div class="selection" *ngFor="let acc of fAccountIdList; let i = index" >
                        <input type="checkbox" [id]="acc.value+i" name="acc.value" (focus)="onFocus('add_accounts')" (change)="selectAccountIds(acc, $event); onFocus('add_accounts')">
                        <label [for]="acc.value+i" class="selection-label">{{acc.viewValue}}</label>
                      </div>
                    </div>
                  </div>
                
                
                <div style="margin-top: 10px;margin-bottom: 20px; border-bottom: 1px solid #465374;">
                    <h3 class="product-desc font-class">Add Users
                      <i class="fa fa-question-circle" aria-hidden="true" style="color: #FFB500;margin-left: 12px;font-size: 14px;" 
                      (click)= "changeTab();onFocus('add_users')"></i>
                    </h3>
                    <h3 class="prod-c" style="width: 50% !important">Invite collaborators to this project (optional).</h3>
                </div>
        
                <div class="add-new-btn-container" style="width: 130px !important">
                    <div class="add-new-btn-select-wrapper">
                      <div class="add-new-btn-select">
                          <div class="add-new-btn-trigger" ><span class="add-new-txt" style=" margin-right: 10px;">Add Users</span>
                              <div class="arrow"></div>
                          </div>
                          <div class="add-new-btn-dropdown-options">
                              <span class="filter-dropdown-option" data-value="add" (click)="addUser()">Create New User</span>
        
                          </div>
                      </div>
                    </div>
                  </div>
                
                <div *ngIf="principalIdList.length == 0" class="addAccount text-white font-class" (click)="onFormSubmit(formedParams)" name="button" type="button">No Users added</div>
                
                <div *ngIf="principalIdList.length > 0" class="form-control account-selection-content" style="margin-top: 15px;margin-bottom: 15px">
                    <span class="selection-header">Select principal investigator from the list</span>
                    <div>
                      <div class="selection" *ngFor="let acc of principalIdList" >
                        <input type="checkbox" [id]="acc.value" name="acc.value" (focus)="onFocus('add_users')" (change)="onFocus('add_users')">
                        <label [for]="acc.value" class="selection-label">{{acc.viewValue}}</label>
                      </div>
                    </div>
                  </div>
        
                  <div style="margin-top: 10px;margin-bottom: 10px; border-bottom: 1px solid #465374;">
                      <h3 class="product-desc font-class">Add Products  <span class="order-st">*</span>
                        <i class="fa fa-question-circle" aria-hidden="true" style="color: #FFB500;margin-left: 12px;font-size: 14px;" 
                        (click)= "changeTab();onFocus('add_products')"></i>
                      </h3>
                      <h3 class="prod-c">Create products in the service catalog from our standard catalog or bring your own service catalog portfolio.</h3>
                  </div>
        
                    <div class="form-control account-selection-content" style="margin-bottom: 15px">
                    <span class="selection-header">Select catalog type from the list</span>
                    <div>
                      <div class="selection" *ngFor="let acc of catalogs" >
                        <input type="checkbox" [id]="acc.viewValue" required name="acc.viewValue" (focus)="onFocus('add_products')" (change)="onFocus('add_products')">
                        <label [for]="acc.viewValue" class="selection-label">{{acc.viewValue}}</label>
                      </div>
                    </div>
                  </div>
        
              </div>
            </tab>
            <tab class="tab" heading="Help">
                <div class="helpSection">
                    <h3 style="color: #3FCB57; font: normal normal 800 18px/32px Nunito Sans;">Help</h3>            
                      <div class="panel panel-default" *ngFor="let item of sectionWiseHelpdata; let k = index">
                        <div class="panel-heading heading" (click)="accordianCtrlNodeX(k)">
                          <h5 class="panel-title">
                            <table id="BotList-node" class="table table-striped" style="width: 100%;margin-bottom: unset">
                              <thead>
                                <tr>
                                  <th class="accordian-header" style="width:18%;text-align: initial;"
                                    [ngStyle]="{'background-color': '#06153E'}">
                                    <span style="color: white; font: normal normal normal 13px/18px Nunito Sans;font-weight: 900">{{item.key}}</span></th>
                                    <th class="accordian-header" style="width:1%"
                                    [ngStyle]="{'background-color': '#06153E' }">
                                    <em class='glyphicon' style="font-size: 20px;font-weight: 900;color: white"
                                      [class.glyphicon-plus]="k != curx" [class.glyphicon-minus]="k == curx"
                                      class="accordion-toggle"></em></th>
                                </tr>
                              </thead>
                            </table>
                          </h5>
                        </div>
                        <div id="collapse-node" class="panel-collapse" [class.collapse]='k != curx'>
                          <table id="topology-info-node" class="table table-striped" style="width: 100%;">
                            <tbody class="txt-align">
                              <tr>
                                <td style="padding-top:10px;width:20%;background:#030A1E;border: 1px solid #85d4ff;font: normal normal normal 13px/18px Nunito Sans;
                                white-space: pre-wrap !important;
                                color: #FFFFFF;" [innerHTML]="item.value">
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
              
              
                      <div class="panel panel-default" *ngFor="let item of helpTextData; let j = index">
                          <div class="panel-heading heading" (click)="accordianCtrlNode(j)">
                            <h5 class="panel-title">
                              <table id="BotList-node" class="table table-striped" style="width: 100%;margin-bottom: unset">
                                <thead>
                                  <tr>
                                    <th class="accordian-header" style="width:18%;text-align: initial;"
                                      [ngStyle]="{'background-color': '#06153E'}">
                                      <span style="color: white; font: normal normal normal 13px/18px Nunito Sans;font-weight: 900">{{item.key}}</span></th>
                                      <th class="accordian-header" style="width:1%"
                                      [ngStyle]="{'background-color': '#06153E' }">
                                      <em class='glyphicon' style="font-size: 20px;font-weight: 900;color: white"
                                        [class.glyphicon-plus]="j != cur" [class.glyphicon-minus]="j == cur"
                                        class="accordion-toggle"></em></th>
                                  </tr>
                                </thead>
                              </table>
                            </h5>
                          </div>
                          <div id="collapse-node" class="panel-collapse" [class.collapse]='j != cur'>
                            <table id="topology-info-node" class="table table-striped" style="width: 100%;">
                              <tbody class="txt-align">
                                <tr>
                                  <td style="padding-top:10px;width:20%;background:#030A1E;border: 1px solid #85d4ff;font: normal normal normal 13px/18px Nunito Sans;
                                  white-space: pre-wrap !important;
                                  color: #FFFFFF;" [innerHTML]="item.value">
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
              
                  </div>
              </tab>
        </tabset>
  </div> -->
  </form>
</div>