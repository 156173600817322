<div class="container">
  <div class="fixed-header">
    <div class="container">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb bc-custom">
          <li class="breadcrumb-item" *ngIf="userLevel == '0'"><a class="bc-item" routerLink="/researcher"> My
            Projects </a></li>
          <li class="breadcrumb-item" *ngIf="userLevel == '1'"><a class="bc-item" routerLink="/principal"> My
            Projects </a></li>
          <li class="breadcrumb-item" aria-current="page" *ngIf="userLevel == '0'"><a class="bc-item"
                                                                                      (click)="navigateToProject()"> {{ projectName }} </a>
          </li>
          <li class="breadcrumb-item" aria-current="page" *ngIf="userLevel == '1'"><a class="bc-item"
                                                                                      routerLink="/project-details/{{projectId}}"
                                                                                      [queryParams]="{showTab: 'availableProducts'}"> {{ projectName }} </a>
          </li>
          <li class="breadcrumb-item active bc-item-active" aria-current="page">{{ productName }}</li>
        </ol>
      </nav>
      <div class="product-name-layout">
        <h3 class="product-name text-white font-class">{{ productName }}</h3>
        <div class="launch-now-wrapper">
          <span class="available-prod-time-text">Expected time to provision: {{ getETADetails }}</span>
          <button class="launch-btn text-white font-class" (click)="onFormSubmit(formedParams)" name="button"
                  type="button">LAUNCH NOW
          </button>
        </div>
      </div>
      <div class="product-name-layout-responsive">
        <h3 class="product-name text-white font-class">{{ productName }}</h3>
        <div class="launch-now-wrapper-responsive">
          <span class="available-prod-time-text">Expected time to provision: {{ getETADetails }}</span>
          <button class="launch-btn text-white font-class" style="margin-top: 15px;"
                  (click)="onFormSubmit(formedParams)" name="button" type="button">LAUNCH NOW
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="order-loader" *ngIf="loaderShow">
    <div class="outer-layer"></div>
    <div class="txt-cent">
      <div class="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
  <form style="margin-top: 140px;">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
        <div class="row lf-tp">
          <div class="col-sm-12 lf-se-tp">
            <div class="row lf-pro-name">
              <div class="col-sm-12 col-md-6 col-lg-6 col-xs-12">
                <h3 class="prov-product-name text-white font-class">Product Name<span class="order-st">*</span></h3>
                <p class="prov-product-helper text-white font-class">Specify a name for the product that you want to
                  create.</p>
                <input required pattern="[a-zA-Z0-9][a-zA-Z0-9._-]*" [(ngModel)]="provisionedProductName"
                       class="inp-txt user-input" type="text" name="name"
                       (keypress)="checkProductName($event)" aria-label="product name"
                       (keyup)="checkProductName($event)" placeholder="Please enter your product name">
                <div [ngClass]="defaultHelpClass">
                  Provide a name to help you easily identify this instance of the product. Only alphanumeric characters,
                  dots, hyphens and underscores are allowed.
                  Spaces and special characters are not allowed.
                </div>
              </div>
            </div>
            <div class="lf-param-name">
              <!-- Start Study Expansion -->
              <div *ngIf="studySelection">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                    <!-- TODO : Study Configuration -->
                    <h3 class="prod-config font-class">Study Selection</h3>
                    <mat-accordion class="example-headers-align" multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            Expand this section to select studies to mount to your workspace.
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <!-- <div class="study-conf">Expand this section to select studies to mount to your workspace.</div> -->
                        <mat-panel-description>
                          <div class="form-control study-selection-container"
                               *ngIf="studyList.length > 0; else elseBlock">
                            <span class="selection-header">Select one or more studies to mount to your workspace (Maximum of {{ this.studySelectionCount }}
                              )</span>
                            <div class="selection ng-star-inserted" *ngFor="let study of studyList;let $index=index">
                              <input [id]="study._id" (change)="updateStudySelection(study, $event)" type="checkbox"
                                     name="one">
                              <label class="selection-label" [for]="study._id">{{ study.name }}({{ study.studyAccess }}
                                , {{ study.studyType }})
                              </label>
                            </div>
                          </div>
                          <ng-template #elseBlock>
                            <div class="study-selection-error">No studies are linked to this project. <span
                              *ngIf="userLevel == 1">Please click here to <a (click)="createStudy()">create studies</a>.</span>
                            </div>
                          </ng-template>
                        </mat-panel-description>
                      </mat-expansion-panel>

                    </mat-accordion>
                  </div>
                </div>
                <div class="divider"></div>
              </div>
              <!-- End Study Expansion -->
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                  <h3 class="prod-config font-class">Product Configuration<span class="order-st">*</span></h3>
                  <p class="prod-config-helper font-class">Specify values or use the default values for the parameters.
                    All parameters are required.</p>
                </div>
              </div>
              <div class="divider"></div>
              <div *ngIf="vendor!='AZURE'">
                <div class="row field-layout" *ngFor="let fParams of formedSecondParams;let $indexG=index">
                  <h3 class="product-label" *ngIf="fParams.label">{{ fParams.label }}</h3>
                  <div class="row field-layout-inner" *ngFor="let paramsObj of fParams.getKeyObj; let $index=index">
                    <label class="label-tit text-white font-class"
                           *ngIf="paramsObj.parameterType != 'RL::SC::PARAM::HD'">{{ paramsObj.key }}</label>
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xs-12">
                  <span *ngIf="paramsObj.parameterType == 'AWS::EC2::KeyPair::KeyName'"
                        style="display: flex; width: 114.5%">
                  <div class="s3-search-area-tp">
                    <select aria-label="dropdownForKeyPair" class="dropdown text-white font-class s3-select"
                            [(ngModel)]="dynamicKeyPair" required name="value-{{$indexG}}-{{$index}}"
                            [(ngModel)]="fParams.getKeyObj[$index].value"
                            *ngIf="paramsObj.parameterType == 'AWS::EC2::KeyPair::KeyName'">
                      <option *ngIf="browserName === 'safari'" disabled selected value></option>
                      <option *ngFor="let paramsStr of keyPairNames" [value]="paramsStr">{{ paramsStr }}</option>
                    </select>
                  </div>
                     <button type="submit"
                             class="add-key-pair" (click)="addKeyPairs()" title="Add Key Pair">
                      <i class="fa fa-plus white"></i>
                    </button>
                  </span>
                      <div class="s3-search-area-tp" *ngIf="paramsObj.parameterType == 'AWS::EC2::VPC::Id'">
                        <select [(ngModel)]="dynamicVpcId" aria-label="dropdownForSecurityGroupsAndSubnet"
                                (change)="getSecurityGroupsAndSubnet()" class="dropdown text-white font-class s3-select"
                                required name="value-{{$indexG}}-{{$index}}"
                                [(ngModel)]="fParams.getKeyObj[$index].value"
                                *ngIf="paramsObj.parameterType == 'AWS::EC2::VPC::Id'">
                          <option *ngFor="let paramsStr of vpcNames" [value]="paramsStr.vpcId">{{ paramsStr.formVPC }}
                          </option>
                        </select>
                      </div>
                      <div class="s3-search-area-tp" *ngIf="paramsObj.parameterType == 'AWS::EC2::Subnet::Id'">
                        <select class="dropdown text-white font-class s3-select" aria-label="dropdownForSubnet" required
                                name="value-{{$indexG}}-{{$index}}" [(ngModel)]="fParams.getKeyObj[$index].value"
                                *ngIf="paramsObj.parameterType == 'AWS::EC2::Subnet::Id'">
                          <option *ngFor="let paramsStr of subnetNames"
                                  [value]="paramsStr.subnetId">{{ paramsStr.formSubnets }}
                          </option>
                        </select>
                      </div>
                      <div class="s3-search-area-tp" *ngIf="paramsObj.parameterType == 'AWS::EC2::SecurityGroup::Id'">
                        <select class="dropdown text-white font-class s3-select" aria-label="dropdownForSecurityGroups"
                                required name="value-{{$indexG}}-{{$index}}"
                                [(ngModel)]="fParams.getKeyObj[$index].value"
                                *ngIf="paramsObj.parameterType == 'AWS::EC2::SecurityGroup::Id'">
                          <option *ngFor="let paramsStr of securityGroupsNames"
                                  [value]="paramsStr.groupId">{{ paramsStr.formSG }}
                          </option>
                        </select>
                      </div>
                      <div class="s3-search-area-tp" *ngIf="paramsObj.select.length != 0">
                        <select class="dropdown text-white font-class s3-select" aria-label="dropdownForParamObj"
                                required name="value-{{$indexG}}-{{$index}}"
                                [(ngModel)]="fParams.getKeyObj[$index].value" *ngIf="paramsObj.select.length != 0"
                                (change)="fParams.getKeyObj[$index].key === 'Scheduler' ? onSelectChange(fParams, $event.target.value) : null">
                          <option *ngFor="let paramsStr of paramsObj.select" [value]="paramsStr">{{ paramsStr }}
                          </option>
                        </select>
                      </div>
                      <input required class="inp-txt user-input font-class" name="value-{{$indexG}}-{{$index}}"
                             [(ngModel)]="fParams.getKeyObj[$index].value"
                             *ngIf="!paramsObj.parameterConstraints.AllowedPattern &&
                         paramsObj.select.length == 0 &&
                         paramsObj.parameterType != 'AWS::EC2::KeyPair::KeyName' &&
                         paramsObj.parameterType != 'AWS::EC2::SecurityGroup::Id' &&
                         paramsObj.parameterType != 'AWS::EC2::VPC::Id' &&
                         paramsObj.parameterType != 'RL::SC::PIPELINE' &&
                         paramsObj.parameterType != 'RL::SC::S3' &&
                         paramsObj.parameterType != 'RL::SC::PARAM::HD' &&
                         paramsObj.parameterType != 'AWS::EC2::Subnet::Id'"
                             [value]="paramsObj.value || ''" type="text" name="name" aria-label="KmsArnKeyInputField">
                      <div *ngIf="paramsObj.parameterType === 'RL::SC::PIPELINE'">
                        <input (keyup)="searchNFPipeline()" [(ngModel)]="dynamicPPName" required
                               class="inp-txt user-input font-class pp-name pp-input-btn-ip"
                               name="value-{{$indexG}}-{{$index}}" [(ngModel)]="fParams.getKeyObj[$index].value"
                               [value]="paramsObj.value || ''" type="text" name="name" aria-label="searchBox"
                               placeholder="Search...">
                        <em _ngcontent-c9="" *ngIf="ppOptionImage == false"
                            class="glyphicon glyphicon-search search-icon"></em>
                        <em _ngcontent-c9="" *ngIf="ppOptionImage == true" (click)="ppLead()"
                            class="glyphicon glyphicon glyphicon-info-sign search-icon search-icon-h"></em>
                        <button class="pp-input-btn">
                          <em _ngcontent-c1="" class="fa fa-angle-down pp-input-btn-em">
                            <span style="display: none;">-</span>
                          </em>
                        </button>
                        <div style=" top: 45px; " *ngIf="ppSearchArea == true" class="pipeline-search-area">
                          <div (click)="ppContainerArea(ppObj)" class="pp-top"
                               *ngFor="let ppObj of ppContainer;let $indexH=index">
                            <div><label class="pp-top-label">Pipeline Name</label> : <label
                              class="pp-top-label">{{ ppObj.pipeline_name }}</label></div>
                            <div><label class="pp-top-label">Pipeline URL</label> : <label
                              class="pp-top-label">{{ ppObj.pipeline_git_url }}</label></div>
                          </div>
                          <div *ngIf="ppContainer.length == 0" class="pp-top">
                            <div style=" padding: 5px; text-align: center;" class="pp-top-label">No items matched your
                              search.
                            </div>
                          </div>

                        </div>
                      </div>
                      <div *ngIf="paramsObj.parameterType === 'RL::SC::S3' && paramsObj.key === 'InputDataLocation'"
                           class="s3-search-area-tp">
                        <input placeholder="Search..." (keyup)="searchS3Product()" [(ngModel)]="dynamicS3" required
                               class="inp-txt user-input font-class pp-name s3-select" [ngClass]="paramsObj.key"
                               name="value-{{$indexG}}-{{$index}}" [(ngModel)]="fParams.getKeyObj[$index].value"
                               [value]="paramsObj.value || ''" type="text" name="name" aria-label="searchBox">
                        <em _ngcontent-c9="" *ngIf="s3IOptionImage == false"
                            class="glyphicon glyphicon-search search-icon search-icon-i"></em>
                        <div class="vertical-divider"></div>
                        <div class="dropdown dp-e">
                      <span style="z-index: 2;" class="tenant-cl btn btn-primary dropdown-toggle menu-dropdown-contents"
                            type="button" data-toggle="dropdown">
                        <span class="menu-btn">
                          <span class="menu-btn-text username" title="{{filterS3I}}">{{ filterS3I }}
                             </span>
                        </span>
                        <em class="fa fa-angle-down white menu-cal-f" style="font-size: 20px; margin-left: 15px;"></em>
                      </span>
                          <ul class="dropdown-menu">
                            <button (click)="inputS3DD(data); inSelectS3Select=data;"
                                    [ngClass]="{'filter-log-active': inSelectS3Select === data}"
                                    *ngFor="let data of filterListS3" type="button" title="{{data}}"
                                    class="btn menu-item-btn filter-pp">{{ data }}
                            </button>
                          </ul>
                        </div>
                        <div *ngIf="s3SearchArea == true" class="pipeline-search-area">
                          <div (click)="s3ContainerArea(s3Obj, paramsObj.key)" class="pp-top"
                               *ngFor="let s3Obj of s3Container;let $indexH=index">
                            <div><label class="pp-top-label">Product Name</label> : <label
                              class="pp-top-label">{{ s3Obj.name }}</label></div>
                            <div><label class="pp-top-label">Path</label> : <label
                              class="pp-top-label">{{ s3Obj.s3BucketName }}</label></div>
                          </div>

                          <div class="pp-top" *ngIf="s3Container.length == 0">
                            <div style=" text-align: center; ">
                              <div *ngIf="searchLoader == true" class="lds-facebook">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                              <div *ngIf="searchLoader == false" style=" padding: 5px; " class="pp-top-label">No items
                                matched your search. Try changing your filter or search term.
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div *ngIf="paramsObj.parameterType === 'RL::SC::S3' && paramsObj.key === 'OutputDataLocation'"
                           class="s3-search-area-tp">
                        <input placeholder="Search..." (keyup)="searchS3OutProduct()" [(ngModel)]="dynamicOutS3"
                               required class="inp-txt user-input font-class pp-name s3-select"
                               [ngClass]="paramsObj.key" name="value-{{$indexG}}-{{$index}}"
                               [(ngModel)]="fParams.getKeyObj[$index].value"
                               [value]="paramsObj.value || ''" type="text" name="name" aria-label="searchBox">
                        <em _ngcontent-c9="" *ngIf="s3OOptionImage == false"
                            class="glyphicon glyphicon-search search-icon search-icon-i"></em>
                        <div class="vertical-divider"></div>
                        <div class="dropdown dp-e">
                      <span style="z-index: 2;" class="tenant-cl btn btn-primary dropdown-toggle menu-dropdown-contents"
                            type="button" data-toggle="dropdown">
                        <span class="menu-btn">
                          <span class="menu-btn-text username" title="{{filterS3O}}">
                             {{ filterS3O }}</span>
                        </span>
                        <em class="fa fa-angle-down white menu-cal-f" style="font-size: 20px; margin-left: 15px;"></em>
                      </span>
                          <ul class="dropdown-menu">
                            <button (click)="outputS3DD(data); outSelectS3Select=data"
                                    [ngClass]="{'filter-log-active': outSelectS3Select === data}"
                                    *ngFor="let data of filterListS3" type="button" title="{{data}}"
                                    class="btn menu-item-btn filter-pp">{{ data }}
                            </button>
                          </ul>
                        </div>
                        <div *ngIf="s3OutSearchArea == true" class="pipeline-search-area">
                          <div (click)="s3ContainerArea(s3Obj, paramsObj.key)" class="pp-top"
                               *ngFor="let s3Obj of s3Container;let $indexH=index">
                            <div><label class="pp-top-label">Product Name</label> : <label
                              class="pp-top-label">{{ s3Obj.name }}</label></div>
                            <div><label class="pp-top-label">Path</label> : <label
                              class="pp-top-label">{{ s3Obj.s3BucketName }}</label></div>
                          </div>
                          <div class="pp-top" *ngIf="s3Container.length == 0">
                            <div style=" text-align: center; ">
                              <div *ngIf="searchLoader == true" class="lds-facebook">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                              <div *ngIf="searchLoader == false" style=" padding: 5px; " class="pp-top-label">No items
                                matched your search. Try changing your filter or search term.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <input required aria-label="sshLocationInputBox" class="inp-txt user-input font-class"
                             name="value-{{$indexG}}-{{$index}}" [(ngModel)]="fParams.getKeyObj[$index].value" *ngIf="paramsObj.parameterConstraints.AllowedPattern && paramsObj.select.length == 0 && paramsObj.parameterType != 'AWS::EC2::KeyPair::KeyName'
                && paramsObj.parameterType != 'AWS::EC2::SecurityGroup::Id' && paramsObj.parameterType != 'AWS::EC2::VPC::Id' && paramsObj.parameterType != 'AWS::EC2::Subnet::Id'"
                             pattern="{{paramsObj.parameterConstraints.AllowedPattern}}" [value]="paramsObj.value || ''"
                             type="text" name="name">
                      <span *ngIf="paramsObj.parameterType == 'AWS::EC2::KeyPair::KeyName'" style="display: flex;">
                      <p class="tot-txt font-class"
                         style="width: 100%;margin-top: 10px;">{{ paramsObj.description }}</p>
                  </span>
                      <p class="tot-txt font-class" *ngIf="paramsObj.parameterType != 'AWS::EC2::KeyPair::KeyName' &&
                  paramsObj.parameterType != 'RL::SC::PARAM::HD'">{{ paramsObj.description }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row field-layout" *ngIf="vendor=='AZURE'">
                <app-azure-order *ngIf="researchProducts"
                                 [inputValue]="researchProducts"
                                 [keyPair]="keyPairNames"
                ></app-azure-order>
              </div>

              <div *ngIf="showNoParamFoundMsg" class="noParamsFound">
                This product does not have any additional product configuration parameters.
              </div>

            </div>
            <button class="launch-btn-mobile text-white font-class" (click)="onFormSubmit(formedParams)" name="button"
                    type="button">LAUNCH NOW
            </button>
            <div class="display-none">
              <div class="row">
                <div class="col-sm-12">
                  <p class="setup-title">Ungrouped Parameters
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-2">
                  <label class="label-tit">Notebook</label>
                  <label class="label-tit">InstanceType</label>
                </div>
                <div class="col-sm-10">
                  <div class="s3-search-area-tp">
                    <select class="s3-select">
                      <option value="ml.t2.medium">ml.t2.medium</option>
                      <option value="ml.m4.xlarge">ml.m4.xlarge</option>
                      <option value="ml.p2.xlarge">ml.p2.xlarge</option>
                    </select>
                  </div>
                  <p class="tot-txt">Select Instance type for the SageMaker Notebook
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-2">
                  <label class="label-tit">BucketName</label>
                </div>
                <div class="col-sm-10">
                  <p class="tot-txt">S3 bucket name for sageMaker
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-2">
                  <label class="label-tit">Notebook</label>
                  <label class="label-tit">InstanceName</label>
                </div>
                <div class="col-sm-10">
                  <p class="tot-txt">SageMaker Notebook instance name
                  </p>
                </div>
              </div>
            </div>
            <div class="display-none">
              <div class="row">
                <div class="col-sm-12">
                  <h3>Tags</h3>
                  <label class="tag">The following TagOptions are added by the administrator to either Product or
                    Portfolio. Some TagOptions may have multiple values and you are required to select the most
                    applicable value. Learn more here.</label>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-4">
                  <label>KEY</label>
                </div>
                <div class="col-sm-8">
                  <label>VALUE</label>
                </div>
              </div>
              <div class="row tag-key">
                <div class="col-sm-4">
                </div>
                <div class="col-sm-8">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-sm-12 col-md-3 col-lg-3 col-xs-12">
        <div class="row">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-sm-12 order-item">
                <button  class="order-btn" (click)="onFormSubmit(formedParams)" name="button"
                        type="button">
                  <span class="icon ion-ios-cart"></span>
                  LAUNCH
                </button>
              </div>
            </div>

           </div>
        </div>
      </div> -->
    </div>
  </form>
  <br>
  <br>
  <br>
</div>
<app-loader class="spinner" *ngIf="showSpinner"></app-loader>
