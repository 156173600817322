import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Event as NavigationEvent, NavigationStart } from "@angular/router";
import { ServiceActionsService } from "../_services/service-actions.service";
import { S3DataService } from "../_services/s3-data.service";
import { Location } from '@angular/common';
import { ResearchService } from "../_services/research.service";
import { S3FileUploadComponent } from '../dialog/s3-file-upload/s3-file-upload.component';
import { S3FileDeleteComponent } from '../dialog/s3-file-delete/s3-file-delete.component';
import { S3EmptyFolderComponent } from '../dialog/s3-empty-folder/s3-empty-folder.component';
import { MdlDialogService } from '@angular-mdl/core';
// import 'rxjs/add/operator/pairwise';
// import 'rxjs/add/operator/filter';
import { ToastrService } from 'ngx-toastr';
import { Globals } from "../globals";
import { DashboardService } from "../_services/dashboard.service";
import { ClipboardService } from 'ngx-clipboard';


@Component({
  selector: 'app-explore-s3',
  templateUrl: './study-s3-explore.component.html',
  styleUrls: ['./study-s3-explore.component.css'],
  providers: [DashboardService, Globals]
})
export class studyS3ExploreComponent implements OnInit {

  bucketName: any;
  bucketData: any = [];
  bucketPrefixData: any = [];
  showSpinner = true;
  folderData: any = {};
  exploreS3BucketData: any;
  projectName: any;
  productName: any;
  provisionedProductName: any;
  isShared = false;
  productStatus: any;
  fileType: any;
  selectedOneOrMoreThanOneFolder: any = [];
  folderName: any = "";
  searchKey: any;
  rootFolder = false;
  previousFolder: any;
  previousUrl: any;
  checkBoxValue: any = [];
  selectedFileType: any;
  parametersObservable: any;
  currentPage = 1;
  limit = 20;
  totalSize;
  showLoadMore = false;
  ppId;
  projectStatus;
  account_id;
  prefix;
  type;
  next: string = "";
  content = [];
  studyId;
  name;
  showRootIcon = false;
  studyType;
  studyDetails;
  productDetails;
  accessLevel;
  mainFolder;
  preSignedUrlAccess = false;
  preSignedUrl;
  userLevel;
  projectId;

  constructor(
    private serviceAction: ServiceActionsService,
    private s3DataService: S3DataService,
    private researchService: ResearchService,
    private router: ActivatedRoute,
    private route: Router,
    private location: Location,
    private dialog: MdlDialogService,
    private toastr: ToastrService,
    private globals: Globals,
    private clipboardApi: ClipboardService,
    private dashboardService: DashboardService) {
    // this.route.events.pairwise().subscribe((event) => {
    //   if (event[0]['urlAfterRedirects'] && !event[0]['state'] ) {
    //     this.previousUrl = event[0];
    //   };
    // });
    // this.location = location;
  }

  ngOnInit() {
    this.showSpinner = true;
    this.showRootIcon = true;
    this.name = this.router.snapshot.queryParamMap.get('name');
    this.studyType = this.router.snapshot.queryParamMap.get('type');
    this.account_id = this.router.snapshot.queryParamMap.get('accountId');
    this.parametersObservable = this.router.params.subscribe(params => {
      this.showSpinner = true;
      this.studyId = this.router.snapshot.params['id'];
      this.accessLevel = this.router.snapshot.queryParams['accessLevel'];
      if (this.router.snapshot.queryParams['prefix'] == "/" || this.router.snapshot.queryParams['prefix'] == undefined) {
        this.prefix = "";
      } else {
        this.prefix = this.router.snapshot.queryParams['prefix'];
      }
      this.bucketName = this.router.snapshot.params['bucketName'];
      this.exploreS3BucketData = this.s3DataService.getS3ExploreData();
      this.folderName = this.router.snapshot.queryParams['folder'];
      this.projectName = this.exploreS3BucketData.projectName;
      this.productName = this.exploreS3BucketData.productName;
      this.provisionedProductName = this.exploreS3BucketData.provisionedProductName;
      this.ppId = this.exploreS3BucketData.ppId;
      this.account_id = this.exploreS3BucketData.account_id;
      this.projectStatus = this.exploreS3BucketData.projectStatus;
      this.productStatus = 'Active';
      this.bucketData = [];
      if (params["message"] === "uploadSuccess" || params["message"] === "deleteSuccess") {
        this.bucketName = this.router.snapshot.params['bucketName'];
        this.exploreS3BucketData = this.s3DataService.getS3ExploreData();
        this.projectName = this.exploreS3BucketData.projectName;
        this.productName = this.exploreS3BucketData.productName;
        this.exploreS3BucketData.action = 'explore';
        this.exploreS3BucketData.bucketName = this.bucketName;
        this.provisionedProductName = this.exploreS3BucketData.provisionedProductName;
        this.bucketData = [];
      }
    });
    if (this.studyType !== 'Public') {
      this.researchService.getStudyDetails(this.studyId).subscribe((res: any) => {
        this.studyDetails = res;
        this.mainFolder = this.studyDetails.resourceDetails[0].Prefix;
        this.bucketName = this.studyDetails.resourceDetails[0].BucketName;
        this.account_id = res.accountId

        if(this.mainFolder == "/" ){
          this.mainFolder = "";
        }
      }, error => {
        this.showSpinner = false;
        console.error(error);
        let errBody = error.error;
        if (error && error.status === 401) {
          this.toastr.error(errBody.err, '', this.globals.tosterOverride);
          this.route.navigate(['login']);
          this.toastr.error(`${this.name} - ` + errBody.err, 'Error', this.globals.tosterOverride);
        }else if (error && error.status === 403) {
          const err = error.error;
          this.toastr.error(err.error, 'Error', this.globals.tosterOverride);
          this.route.navigate(['pageNotFound']);
        } else {
          this.route.navigate(['pageNotFound']);
        }
      })
    }
    if (this.studyType !== 'Public' && this.studyType !== 'internal' && this.studyType !== 'external') {
      this.getProductDetail();
    } else {
      this.getS3BucketContent();
    }
    this.userLevel = sessionStorage.getItem('Level');
  }

  getFileType(data) {
    if (data.type != 'folder') {
      if (data.Key.indexOf('.') !== -1) {
        return data.Key.toUpperCase().split('.').pop();
      }
    }
    else {
      this.type = 'folder';
      return 'Folder';
    }

  }

  getProductDetail() {
    this.researchService.getStudyProductDetails(this.studyId).subscribe(res => {
      this.productDetails = res;
      this.setExploreData();
      this.getS3BucketContent();
    }, error => {
      this.showSpinner = false;
      console.error(error);
      let errBody = error.error;
      if (error && error.status === 401) {
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.route.navigate(['login']);
      } else {
        this.toastr.error(`${this.name} - ` + errBody.err, 'Error', this.globals.tosterOverride);
      }
    })
  }

  setExploreData() {
    this.exploreS3BucketData.projectName = this.name;
    this.projectName = this.exploreS3BucketData.projectName;
    this.exploreS3BucketData.productName = this.productDetails.ProductName;
    this.productName = this.exploreS3BucketData.productName;
    this.exploreS3BucketData.provisionedProductName = this.productDetails.ProvisionedProductName;
    this.provisionedProductName = this.exploreS3BucketData.provisionedProductName;
    this.exploreS3BucketData.ppId = this.productDetails.ProvisionedProductId;
    this.ppId = this.exploreS3BucketData.ppId;
    this.exploreS3BucketData.account_id = this.productDetails.account_id;
    this.account_id = this.exploreS3BucketData.account_id;
    this.exploreS3BucketData.status = this.productDetails.Status;
    this.productStatus = this.exploreS3BucketData.status;
    this.exploreS3BucketData.service = "s3";
    if (this.productDetails.Resources.length > 0) {
      for (var i = 0; i < this.productDetails.Resources.length; i++) {
        var resource = this.productDetails.Resources[i];
        this.exploreS3BucketData.bucketName = resource.PhysicalResourceId;
      }
    }
    this.exploreS3BucketData.resource = this.productDetails.Resources;
    //this.exploreS3BucketData.bucketName = this.productDetails.Resources[0].PhysicalResourceId;
    this.bucketName = this.exploreS3BucketData.bucketName;
    this.exploreS3BucketData.type = this.studyType;
    if ('isShared' in this.productDetails) {
      this.exploreS3BucketData.isShared = this.productDetails.isShared;
      this.isShared = this.productDetails.isShared;
    } else {
      this.exploreS3BucketData.isShared = false;
      this.isShared = false;
    }
    this.productStatus = this.exploreS3BucketData.status;
  }

  goToMainMenu() {
    this.route.navigate([`/researcher/studies`]);
  }

  syncS3BucketData() {
    this.currentPage = 1;
    this.showSpinner = true;
    this.serviceAction.serviceAction(this.exploreS3BucketData).subscribe(res => {
      if (res) {
        this.showSpinner = false;
        this.bucketData = [];
        if (this.route.url == '/s3/bucket/' + this.bucketName) {
          this.showSpinner = false;
          this.getS3RootFolder();
        } else {
          this.showSpinner = true;
          this.getFolderData(this.prefix, this.type)
        }
      }
    }, error => {
      this.showSpinner = false;
      console.error(error);
      let errBody = error.error;
      if (error && error.status === 401) {
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.route.navigate(['login']);
      } else {
        this.toastr.error(`${this.name} - ` + errBody.error, 'Error', this.globals.tosterOverride)
      }
    });
  }

  deleteS3File(key) {
    this.exploreS3BucketData.action = 'deletefile';
    this.exploreS3BucketData.key = key;
    this.exploreS3BucketData.isStudy = true,
      this.exploreS3BucketData.id = this.studyId,
      this.exploreS3BucketData.type = this.studyType,
      this.exploreS3BucketData.name = this.name
    this.dashboardService.getDialogBox().subscribe((data: any) => {
      if (data.status === 200) {
        this.dialog.showCustomDialog({
          component: S3FileDeleteComponent,
          providers: [{ provide: 'data', useValue: { s3FileObj: this.exploreS3BucketData } }],
          isModal: true,
          styles: {
            "height": "auto",
            "width": "40%",
            "border": "1px solid #FFFFFF33",
            "border-radius": "10px",
            "background-color": "#07102A",
            "padding": "0px"
          },
          clickOutsideToClose: true,
          enterTransitionDuration: 400,
          leaveTransitionDuration: 400,
        })
      }
    }, error => {
      if (error && error.status === 401) {
        let errBody = error.error;
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.route.navigate(['login']);
      }
    })
  }

  deleteS3Folder(prefix) {
    this.exploreS3BucketData.action = 'deletefolder';
    this.exploreS3BucketData.key = prefix;
    this.exploreS3BucketData.isStudy = true,
      this.exploreS3BucketData.id = this.studyId,
      this.exploreS3BucketData.type = this.studyType,
      this.exploreS3BucketData.name = this.name
    this.dashboardService.getDialogBox().subscribe((data: any) => {
      if (data.status === 200) {
        this.dialog.showCustomDialog({
          component: S3FileDeleteComponent,
          providers: [{ provide: 'data', useValue: { s3Obj: this.exploreS3BucketData } }],
          isModal: true,
          styles: {
            "height": "auto",
            "width": "40%",
            "border": "1px solid #FFFFFF33",
            "border-radius": "10px",
            "background-color": "#07102A",
            "padding": "0px"
          },
          clickOutsideToClose: true,
          enterTransitionDuration: 400,
          leaveTransitionDuration: 400,
        })
      }
    }, error => {
      if (error && error.status === 401) {
        let errBody = error.error;
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.route.navigate(['login']);
      }
    })
  }

  downloadS3(key) {
    this.exploreS3BucketData.action = 'downloads3';
    this.exploreS3BucketData.key = key;
    this.exploreS3BucketData.service = "s3";
    this.exploreS3BucketData.isStudy = true,
      this.exploreS3BucketData.id = this.studyId,
      this.exploreS3BucketData.type = this.studyType,
      this.exploreS3BucketData.name = this.name
    this.serviceAction.serviceAction(this.exploreS3BucketData).subscribe((res: any) => {
      this.showSpinner = false;
      window.open(res.url)
    }, error => {
      console.error(error);
      let errBody = error.error;
      if (error && error.status === 401) {
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.route.navigate(['login']);
      } else {
        this.toastr.error(`${this.bucketName} - ` + errBody.error, 'Error', this.globals.tosterOverride)
      }
    });
  }

  downloadS3FromCheckBoxValue() {
    this.exploreS3BucketData.action = 'downloads3';
    this.exploreS3BucketData.key = '';
    this.exploreS3BucketData.isStudy = true,
      this.exploreS3BucketData.id = this.studyId,
      this.exploreS3BucketData.type = this.studyType,
      this.exploreS3BucketData.name = this.name
    for (var i = 0; i < this.checkBoxValue.length; i++) {
      this.exploreS3BucketData.key = this.checkBoxValue[i].Key
    }
    this.serviceAction.serviceAction(this.exploreS3BucketData).subscribe((res: any) => {
      this.showSpinner = false;
      window.open(res.url)
    }, error => {
      console.error(error);
      let errBody = error.error;
      if (error && error.status === 401) {
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.route.navigate(['login']);
      } else {
        this.toastr.error(`${this.name} - ` + errBody.error, 'Error', this.globals.tosterOverride)
      }
    });
  }

  createCopyPreSignedURL(key){
    this.exploreS3BucketData.account_id = this.account_id;
    this.exploreS3BucketData.bucketName = this.bucketName;
    this.exploreS3BucketData.service = 's3';
    this.exploreS3BucketData.action = 'createPreSignedURL';
    this.exploreS3BucketData.key = key;
    this.exploreS3BucketData.projectId = this.projectId;
    this.exploreS3BucketData.provisionedProductId = this.ppId;
    this.serviceAction.serviceAction(this.exploreS3BucketData).subscribe((res:any) => {
      this.showSpinner = false;
      this.preSignedUrl = res.url.replace(/"/g,'')
      this.clipboardApi.copyFromContent(this.preSignedUrl)
      this.toastr.success(`The pre-signed URL has been created and copied to the clipboard`, '', this.globals.tosterOverride);
    }, error => {
      console.error(error);
      let errBody = error.error;
      if(error && error.status === 401){
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.route.navigate(['login']);
       }else{
        this.toastr.error('The pre-signed URL create and copy Error ', '', this.globals.tosterOverride)
       }
    })
  } 

  createCopyPreSignedURLFromCheckBoxValue(){
    this.exploreS3BucketData.account_id = this.account_id;
    this.exploreS3BucketData.bucketName = this.bucketName;
    this.exploreS3BucketData.service = 's3';
    this.exploreS3BucketData.action = 'createPreSignedURL';
    this.exploreS3BucketData.key = '';
    this.exploreS3BucketData.projectId = this.projectId;
    this.exploreS3BucketData.provisionedProductId = this.ppId;
    for(var i=0;i< this.checkBoxValue.length; i++){
      this.exploreS3BucketData.key = this.checkBoxValue[i].Key;
    }
    this.serviceAction.serviceAction(this.exploreS3BucketData).subscribe((res:any) => {
      this.showSpinner = false;
      this.preSignedUrl = res.url.replace(/"/g,'')
      this.clipboardApi.copyFromContent(this.preSignedUrl)
      this.toastr.success(`The pre-signed URL has been created and copied to the clipboard`, '', this.globals.tosterOverride);
    }, error => {
      console.error(error);
      let errBody = error.error;
      if(error && error.status === 401){
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.route.navigate(['login']);
       }else{
        this.toastr.error('The pre-signed URL create and copy Error ', '', this.globals.tosterOverride)
       }
    })
}

  checkForFileType() {
    for (var i = 0; i < this.checkBoxValue.length; i++) {
      if (this.checkBoxValue[i].fileType == 'Folder') {
        this.selectedFileType = 'Folder';
      } else {
        this.selectedFileType = 'File';
      }
    }
  }


  getFolderData(prefix, type) {
    this.showSpinner = true;
    this.prefix = prefix;
    this.type = type;
    this.rootFolder = false;
    this.folderName = '';
    this.searchKey = '';

    const data = {
      bucketName: this.bucketName,
      prefix: this.prefix,
      limit: this.limit,
      account_id: this.account_id,
      next: this.next,
      folderName: this.folderName
    };
    if (type === 'Folder') {
      this.fileType = 'Folder';
      this.route.navigate(['/s3/bucket/' + this.bucketName], { queryParams: { folder: prefix } })
    }
    this.router.queryParams.subscribe(map => map);
    this.bucketData = [];
    this.bucketPrefixData = [];
    this.showSpinner = true;
    this.serviceAction.getS3Data(data).subscribe((res: any) => {
      if (res) {
        this.showSpinner = false;
        this.showLoadMore = false;
        this.folderName = this.router.snapshot.queryParams['folder'];
        // this.bucketData = res.docs[0].contents;
        this.bucketData = [].concat(this.bucketData, res.docs);
        if (this.bucketData.length < res.total) {
          this.showLoadMore = true;
        }
        //this.bucketData = res.docs;
        this.bucketData = this.bucketData.filter(function (obj) {
          return obj.Key !== prefix;
        });
        var lastChar;
        for (var i = 0; i < this.bucketData.length; i++) {
          lastChar = this.bucketData[i].key.substr(-1);
          if (lastChar == '/') {
            this.bucketData[i].fileType = 'Folder';
          } else {
            this.bucketData[i].fileType = 'File';
          }
        }
        // this.bucketPrefixData = res.docs[0].commonPrefixes;
      }
    }, error => {
      console.error(error);
      this.showSpinner = false;
      let errBody = error.error;
      if (error && error.status === 401) {
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.route.navigate(['login']);
      } else {
        this.toastr.error(`${this.name} - ` + errBody.err, 'Error', this.globals.tosterOverride)
      }
    });
  }

  getS3FolderData(folderName) {
    this.showRootIcon = true;
    this.showLoadMore = false;
    this.showSpinner = true;
    this.folderName = folderName;
    this.bucketData = [];
    const data = {
      studyId: this.studyId,
      limit: this.limit,
      prefix: this.prefix,
      next: null,
      folderName: this.folderName
    };

    this.route.navigate(['/explore/' + this.studyId], { queryParams: { name: this.name, folder: folderName, prefix: this.prefix, type: this.studyType } });

    this.router.queryParams.subscribe(map => map);
    this.serviceAction.getS3BucketData(data).subscribe((res: any) => {
      this.showSpinner = false;
      if (res) {
        //Remove duplicate prefix
        var newS3PrefixArray = res.Content.filter((item) => item.Key !== this.prefix + folderName + '/');
        res.Content = newS3PrefixArray;
        if ('next' in res && res.next !== null) {
          this.next = res.next;
          this.showLoadMore = true;
        } else {
          this.showLoadMore = false;
        }
        if ('Prefix' in res) {
          this.prefix = res.Prefix;
        }
        if ('PreSignedUrlAccess' in res){
          this.preSignedUrlAccess = res.PreSignedUrlAccess
        }
        if('ppId' in res){
           this.ppId = res.ppId
        }
        if('projectId' in res){
          this.projectId = res.projectId
        }
        if ('Content' in res) {
          this.bucketData = [...this.bucketData, ...res.Content];
        }

      }
    }, error => {
      this.showSpinner = false;
      console.error(error);
      if (error && error.status === 401) {
        const errBody = error.error;
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.route.navigate(['login']);
      } else if (error && error.status === 403) {
        const err = error.error;
        this.toastr.error(err.error, 'Error', this.globals.tosterOverride);
        this.route.navigate(['pageNotFound']);
      } else {
        const errBody = error.error;
        this.toastr.error(`${this.name} - ` + errBody.err, 'Error', this.globals.tosterOverride);
      }
    })
  }

  uploadFilesToSpecificFolder(prefix) {
    this.route.navigate(['/s3Upload', { studyId: this.studyId }]);
  }

  createS3EmptyFolder() {
    let body = {
      "service": "s3",
      "account_id": this.exploreS3BucketData.account_id,
      "resources": this.exploreS3BucketData.resource,
      "provisionedProductName": this.exploreS3BucketData.productName,
      "bucketName": this.bucketName,
      "action": 'createfolder',
      "folderName": this.prefix,
      isStudy: true,
      id: this.studyId,
      type: this.studyType,
      name: this.name
    };
    this.dashboardService.getDialogBox().subscribe((data: any) => {
      if (data.status === 200) {
        this.dialog.showCustomDialog({
          component: S3EmptyFolderComponent,
          providers: [{ provide: 'data', useValue: { s3Folderobj: body } }],
          isModal: true,
          styles: {
            "height": "auto",
            "width": "40%",
            "border": "1px solid #FFFFFF33",
            "border-radius": "10px",
            "background-color": "#07102A",
            "padding": "0px"
          },
          clickOutsideToClose: true,
          enterTransitionDuration: 400,
          leaveTransitionDuration: 400,
        })
      }
    }, error => {
      if (error && error.status === 401) {
        let errBody = error.error;
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.route.navigate(['login']);
      }
    })
  }

  getS3BucketContent() {
    this.showSpinner = true;
    const data = {
      studyId: this.studyId,
      limit: this.limit,
      prefix: this.prefix,
      next: this.next,
      folderName: this.folderName
    };

    this.serviceAction.getS3BucketData(data).subscribe((res: any) => {
      this.showSpinner = false;
      if (res) {
        //Remove duplicate prefix
        var newS3PrefixArray = res.Content.filter((item) => item.Key !== this.prefix + this.folderName + '/');
        res.Content = newS3PrefixArray;

        // Remove empty file 
        let noEmptyFilesArray = res.Content.filter((item) => item.Key !== this.prefix);
        res.Content = noEmptyFilesArray;

        if ('next' in res && res.next !== null) {
          this.next = res.next;
          this.showLoadMore = true;
        } else {
          this.showLoadMore = false
        }

        if ('Prefix' in res) {
          this.prefix = res.Prefix;
        }
        if ('PreSignedUrlAccess' in res){
          this.preSignedUrlAccess = res.PreSignedUrlAccess
        }
        if('ppId' in res){
         this.ppId = res.ppId
        }
        if('projectId' in res){
           this.projectId = res.projectId
        }
        if ('Content' in res) {
          this.bucketData = [...this.bucketData, ...res.Content];
        }

      }
    }, error => {
      this.showSpinner = false;
      console.error(error);
      if (error && error.status === 401) {
        const errBody = error.error;
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.route.navigate(['login']);
      } else if (error && error.status === 403) {
        const err = error.error;
        this.toastr.error(err.error, 'Error', this.globals.tosterOverride);
        this.route.navigate(['pageNotFound']);
      } else if (error.status === 500){
        this.toastr.error( "You don't have authorization to access this resource", '', this.globals.tosterOverride);
        this.route.navigate([`pageNotFound`]);
      } else {
        const err = error.error;
        this.toastr.error( err.error, '', this.globals.tosterOverride);
        this.route.navigate(['pageNotFound']);
      }
    })
  }

  uploadFiles() {
    this.route.navigate(['/s3Upload', { studyId: this.studyId }]);
  }

  goBack() {
    this.folderName = "";
    let prefix = this.prefix.split('/');
    prefix.splice(-2, 1);
    this.showRootIcon = true;
    this.bucketData = [];
    if (this.studyType == 'internal') {
      let folder = this.mainFolder.split('/');
      folder.splice(-2, 1);
      this.showRootIcon = true;
      this.bucketData = [];

      if (prefix.length == folder.length) {
        this.routeBack();
      }
      if (prefix.length == 1 ) {
        this.showRootIcon = false;
      } 
    }
    if (prefix.length < 2) {
      this.showRootIcon = false;
    } if (prefix.length > 0) {
      this.prefix = prefix.join('/');
      this.folderName = prefix[-1];
      this.next = null;
      this.getS3BucketContent();
    } else {
      this.prefix = "";
      this.routeBack();
    }
  }

  routeBack() {
    this.route.navigate([`researcher/studies/${this.studyId}`, { studyName: this.name }])
  }

  goBackToRootFolder() {
    this.next = null;
    this.bucketData = [];
    this.folderName = "";
    this.prefix = this.mainFolder;
    this.showRootIcon = false;
    this.getS3BucketContent();
    this.route.navigate(['/explore/' + this.studyId], {queryParams:{name: this.name, accessLevel: this.studyDetails.studyAccess, prefix: this.prefix, type: this.studyDetails.studyType, accountId: this.studyDetails.accountId}});
  }

  getStatus(status) {
    let statusV = status;
    let state = statusV.toUpperCase();
    switch (state) {
      case 'INITIATED':
        statusV = 'Initiated';
        break;
      case 'CREATE_IN_PROGRESS':
        statusV = 'Creating';
        break;
      case 'CREATE_COMPLETE':
        statusV = 'Provisioned';
        break;
      case 'CREATE_FAILED':
        statusV = 'Failed';
        break;
      case 'ROLLBACK_IN_PROGRESS':
        statusV = 'Failed';
        break;
      case 'ROLLBACK_COMPLETE':
        statusV = 'Failed';
        break;
      case 'CREATED':
        statusV = 'Provisioned';
        break;
      case 'AVAILABLE':
        statusV = 'Provisioned';
        break;
      case 'ERROR':
        statusV = 'Failed';
        break;
      case 'DELETE_IN_PROGRESS':
        statusV = 'Terminating';
        break;
      case 'DELETE_COMPLETE':
        statusV = 'Terminated';
        break;
      default:
        break;
    }
    return statusV;
  }

  getStatusColor(status) {
    let statusV = 'status-paused';
    switch (status.toLowerCase()) {
      case 'active':
        statusV = 'status-running';
        break;
      case 'warning':
        statusV = 'status-paused';
        break;
      case 'failed':
        statusV = 'status-stopped';
        break;
      case 'create_in_progress':
        statusV = 'status-paused';
        break;
      case 'create_complete':
        statusV = 'status-running';
        break;
      case 'create_failed':
        statusV = 'status-stopped';
        break;
      case 'rollback_complete':
        statusV = 'status-stopped';
        break;
      case 'available':
        statusV = 'status-running';
        break;
      case 'created':
        statusV = 'status-running';
        break;
      case 'error':
        statusV = 'status-stopped';
        break;
      default:
        statusV = 'status-paused';
        break;
    }
    return statusV;
  }

  getS3RootFolder() {
    this.rootFolder = true;
    this.showSpinner = true;
    this.folderName = '';
    this.serviceAction.getS3Rootfolder(this.bucketName, 'true', this.account_id, this.currentPage, this.limit, this.next).subscribe((res: any) => {
      if (res) {
        this.showSpinner = false;
        this.showLoadMore = false;
        // this.bucketData = res.docs[0].contents;
        this.bucketData = [].concat(this.bucketData, res.docs);
        if (this.bucketData.length < res.total) {
          this.showLoadMore = true;
        }
        var lastChar;
        for (var i = 0; i < this.bucketData.length; i++) {
          this.bucketData[i].isChecked = false;
          lastChar = this.bucketData[i].key.substr(-1);
          if (lastChar == '/') {
            this.bucketData[i].fileType = 'Folder';
          } else {
            this.bucketData[i].fileType = 'File';
          }
        }
        this.totalSize = res.total;
      }
    }, error => {
      this.showLoadMore = false;
      console.error(error);
      let err = error.error;
      this.toastr.error(`${this.name} - ` + err.message, 'Error', this.globals.tosterOverride)
    });
  }

  loadMoreEvents() {
    this.folderName = '';
    this.getS3BucketContent();
  }

  refresh() {
    this.bucketData = [];
    if (this.folderName !== "") {
      this.next = null;
      this.folderName = "";
      this.getS3BucketContent();
    } else {
      this.getS3BucketContent();
    }

  }

  onCheckboxChanged(event, value) {
    const val = { ...value };
    if (event.target.checked) {

      if (val.type === 'folder') {
        val.key = val.name;
        val.fileType = 'Folder';
      } else {
        val.key = value.Key;
        val.fileType = 'File';
      }
      val.key = this.prefix + val.key;
      this.checkBoxValue.push(val);
      for (var i = 0; i < this.checkBoxValue.length; i++) {
        this.checkBoxValue[i].bucketName = this.bucketName;
        this.checkBoxValue[i].isStudy = true;
        this.checkBoxValue[i].id = this.studyId;
        this.checkBoxValue[i].name = this.name;
        this.checkBoxValue[i].type = this.studyType;
      }
      this.selectedOneOrMoreThanOneFolder =  this.checkBoxValue.filter(checkBoxValue => checkBoxValue.fileType == 'Folder')
    }
    if (!event.target.checked) {

      //let index = this.checkBoxValue.indexOf(val);
      let index = this.checkBoxValue.findIndex(obj => {
        if (obj.fileType == 'Folder') {
          return obj.key == this.prefix + value.name;
        } else {
          return obj.Key == value.Key;
        }
      });

      if (index > -1) {

        this.checkBoxValue.splice(index, 1);
      }
    }
  }

  allCheckBoxSelected(event) {

    this.checkBoxValue = [];
    const checked = event.target.checked;

    this.bucketData.forEach(item => {
      item.isChecked = checked;
      const val = Object.assign({}, item);
      val.isChecked = checked;
      if (val.type === 'folder') {
        val.key = this.prefix + val.name;
        val.fileType = 'Folder';
      } else {
        val.fileType = 'File';
      }
      this.checkBoxValue.push(val);
    });

    //this.bucketPrefixData.forEach(item => item.isChecked = checked);
    for (var i = 0; i < this.checkBoxValue.length; i++) {
      this.checkBoxValue[i].bucketName = this.bucketName;
      this.checkBoxValue[i].isStudy = true;
      this.checkBoxValue[i].id = this.studyId;
      this.checkBoxValue[i].name = this.name;
      this.checkBoxValue[i].type = this.studyType;
    }
    if (!event.target.checked) {
      this.checkBoxValue = [];
    }
  }

  deleteMultipleS3Data() {
    const body = {
      isStudy: true,
      id: this.studyId,
      type: this.studyType,
      name: this.name
    };
    this.dashboardService.getDialogBox().subscribe((data: any) => {
      if (data.status === 200) {
        this.dialog.showCustomDialog({
          component: S3FileDeleteComponent,
          providers: [{ provide: 'data', useValue: { s3FileCheckBoxObj: this.checkBoxValue, account_id: this.exploreS3BucketData.account_id, bucketName: this.bucketName, study: true, body: body } }],
          isModal: true,
          styles: {
            "height": "auto",
            "width": "40%",
            "border": "1px solid #FFFFFF33",
            "border-radius": "10px",
            "background-color": "#07102A",
            "padding": "0px"
          },
          clickOutsideToClose: true,
          enterTransitionDuration: 400,
          leaveTransitionDuration: 400,
        })
      }
    }, error => {
      if (error && error.status === 401) {
        let errBody = error.error;
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.route.navigate(['login']);
      }
    })

  }
  getFileSize(size) {
    if (size != 0) {
      let s = (size / 1048576).toFixed(2);
      if (Number(s) > 1024) {
        return (Number(s) / 1024).toFixed(2) + ' GB';
      }
      return s + ' MB'
    } else {
      return 0
    }

  }

  getFiletName(data, display) {
    let fileName = data.split('/');
    fileName = fileName[fileName.length - 1];
    const strLen = fileName.length;
    let str = fileName;
    if (strLen > 15 && display == 'table') {
      str = str.slice(0, 15) + '...';
    }
    return str;
  }

}
