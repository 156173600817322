<div class='s3Upload' id="instanceSSHPage">
  <div class="dialog-header">
  <img class="cancel-icon" (click)="cancel()" src="../../assets/images/cancel@2x.png" 
  alt="Cancel"/>
  <h2 class="modal-title title-header" id="myModalLabel" class="Header">
    Link a Compute Resource
  </h2>
</div>
<div style="display: flex; flex-direction: row; margin-top: 10px;">
  <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
  <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
  <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
  <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
</div>
<app-loader class="spinner" *ngIf="showSpinner"></app-loader>
<div class="modal-body" style="margin-top: 35px;margin-left: 25px;" *ngIf="!showSpinner && activeSageMakerNotebooks.length > 0">
<label class="details">
  Choose a Product that is to be linked <span class="order-st">*</span>
</label>
<label class="details-a">
  Choose a compute resource from the below list of products.
</label>
</div>

<!-- <div class="dropdown" style="margin-top: 30px;margin-left: 35px;cursor: pointer;" data-toggle="dropdown">
      <img class="dropdown-icon" src="../../assets/images/aws_icon/sagemaker.png" alt="../../assets/images/aws_icon/sagemaker.png"/>
      <span class="dropdownContent" id="selected" value="">
        Choose a product from the list to be linked
          <i class="fa fa-angle-down" title="Actions" aria-hidden="true" style="color: #fff;width: 12px;height: 18px;font-size: 20px;float: right;
          margin-top: 15px;
          cursor: pointer;"></i>
      </span>
      <ul class="dropdown-menu">
        <li><a  class="listItems" [(ngModel)]="selectedValue" name="selectedValue" ngDefaultControl *ngFor="let notebook of activeSageMakerNotebooks">{{notebook.name}}</a></li>
      </ul>
</div> -->

<!-- <div class="col-lg-6 col-md-6" style="padding: 35px">
  <select *ngIf="!showSpinner" class="dropdown text-white font-class" required [(ngModel)]="selectedValue">
    <option value="" disabled selected>Choose a product from the list to be linked </option>
    <option *ngFor="let notebook of activeSageMakerNotebooks; let $index=index">{{ notebook.name }}</option>
</select>
</div> -->

<div class="col-lg-12 col-md-12 col-sm-6" style="padding-left: 35px;padding-right: 35px; padding-bottom: 15px; padding-top: 15px;cursor: pointer;" *ngIf="!showSpinner && activeSageMakerNotebooks.length > 0">
<label class="wrap">
  <select  class="dropdown text-white font-class" required [(ngModel)]="selectedValue" #sName="ngModel" required name="selectedValue" id="example">
    <option value="" disabled selected>Choose a product from the list to be linked </option>
    <option style="cursor: pointer;" *ngFor="let notebook of activeSageMakerNotebooks; let $index=index">{{ notebook.productName }}</option>
</select>
</label>
</div>


<div class="col-lg-12 col-md-12 mt-5" style="text-align: center; margin-bottom: 35px !important; margin-top: 0px !important" *ngIf="!showSpinner && activeSageMakerNotebooks.length > 0">
  <div class="marginForButtons l-footer">
    <button   type="button" [disabled]="!selectedValue" class="btn cat-btn-update sshSubmit ssh-btn l-submit" (click)="linkNotebook()">
        Link
    </button>
  </div>
</div>
<div *ngIf="!showSpinner && activeSageMakerNotebooks.length == 0">
<h3 class="noData">
  There is no provisioned Sagemaker product. Please launch a Sagemaker product from the Available Products page first, before linking to an S3 bucket.
</h3>
</div>
</div>