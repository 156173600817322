import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DashboardService } from '../_services/dashboard.service';
import {Globals} from "../globals";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.css'],
  providers: [Globals]
})
export class WelcomePageComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: DashboardService,
    private globals: Globals,private toastr: ToastrService
  ) { }

  welcome_msg = ``;
  showSpinner = true;
  ngOnInit() {
    this.getUserData();
  }

  getUserData() {
    this.apiService.getSAMLUser().subscribe((user:any) => {
      this.showSpinner = false;
      if (user.level === null) {
        this.welcome_msg = `We're excited to help accelerate your research through easily provisioned, scalable, secure resources in AWS. Please contact your admin to assign you to appropriate group.`;
      }else if(user?.changeUserOrgStatus === 'hasActiveProducts'){
        this.welcome_msg = `Your group membership as provided by your authentication provider is different from that received by Research Gateway in an earlier login. And also you have Active products in older projects. Please contact your administrator to correct the issue.`;
      }else if(user?.changeUserOrgStatus === 'hasNoDefaultOU'){
        this.welcome_msg = `Your group membership as provided by your authentication provider is different from that received by Research Gateway in an earlier login. And also you are not part of any organization and current setup doesn't have any default organization. Please contact your administrator to correct the issue.`;
      }else if (user.isGroupsMismatched) {
        this.welcome_msg = `Your group membership as provided by your authentication provider is different from that received by Research Gateway in an earlier login. Please contact your administrator to correct the issue.`;
      }else if (!user.enabled) {
        this.welcome_msg = `The administrator has disabled your access to Research Gateway. Please contact the administrator to re-enable access.`;
      }
    }, error => {
        this.showSpinner = false;
        console.log(error);
          if(error && error.status === 401){
            let errBody = error.error;
             this.toastr.error(errBody.err, '', this.globals.tosterOverride);
             this.router.navigate(['login']);
           }
    });
  }

}
