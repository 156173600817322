<div class="container"  *ngIf="isLoaded">
  <!-- breadcrumb -->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb bc-custom">
      <li class="breadcrumb-item">
        <a class="bc-item" *ngIf="userLevel == '0'" routerLink="/researcher"> My Projects </a>
        <a class="bc-item" *ngIf="userLevel == '1'" routerLink="/principal"> My Projects </a>
      </li>
      <li class="breadcrumb-item" aria-current="page">
        <a class="bc-item" *ngIf="userLevel == '1'" routerLink = "/project-details/{{projectId}}" [queryParams]="{showTab: 'myProducts'}"> {{ projectName }} </a>
        <a class="bc-item" *ngIf="userLevel == '0'" (click)="navigateToProject()"> {{ projectName }} </a>
      </li>
      <li class="breadcrumb-item active bc-item-active" aria-current="page">{{ productName }}</li>
    </ol>
  </nav>
  <!-- project details -->
  <div style="display: flex; justify-content: space-between; align-items: center; margin: 5px 0px;">
    <div style="display: flex;flex: 3;">
      <img style="max-width: 75px; max-height: 75px; border-radius: 10px;"  src="{{productDetails.imageUrl}}" alt="Provisioned Product Icon" />
      <div style="display: flex; flex-direction: column; margin: 10px 15px;">
        <h4 class="text-white font-class" *ngIf="productDetails && productDetails.ProvisionedProductName">{{productDetails.ProvisionedProductName}}</h4>
        <span style="color: #7596FF; font-size: 14px;" class="font-class">{{productDetails.ProductName}}
            <span *ngIf="productDetails && productDetails.isShared === true" class="vl">
                <img class="share-icon" src="../../assets/images/share-white.png" alt="Shared"/>
                <span class="share-icon-text"> Shared </span>
            </span>
            <span *ngIf="productDetails && productDetails.isProjectStorage === true" class="vl">
              <i class="fa fa-hdd-o" style="font-size: 1.4em; color: #fff;margin-left: 20px;vertical-align: middle;" aria-hidden="true"></i>
              <span class="share-icon-text" style="margin-left: 5px !important;"> Project Storage </span>
          </span>
          <span *ngIf="productDetails && productDetails.isIngressStorage === true" class="vl">
            <i class="fa fa-hdd-o" style="font-size: 1.4em; color: #fff;margin-left: 20px;vertical-align: middle;" aria-hidden="true"></i>
            <span class="share-icon-text" style="margin-left: 5px !important;"> Ingress Storage </span>
        </span>
        </span>
      </div>
    </div>
    <div class="product-budget" *ngIf="productDetails && productDetails.budgetConsumed">
      <span style="margin: 0px 10px; font-size: 14px;" class="text-white font-class">Cost : $ {{productDetails.budgetConsumed}}</span>
    </div>
    <div class="product-status">
      <div style="width: 20px; height: 20px; border-radius: 10px;" class="{{getStatusColor(productDetails.Status)}}" *ngIf="productDetails && productDetails.Status"></div>
      <span style="margin: 0px 10px; font-size: 14px;" class="text-white font-class"  *ngIf="productDetails && productDetails.Status">{{getStatus(productDetails.Status)}}</span>
      <a [ngClass]="{'disabled': isRefreshDisabled}" [class.pointer-events-disabled]="isRefreshDisabled" (click)="updateProductStatus(); isRefreshDisabled= true">
        <img alt="Provisioned Product Status Refresh" title="Refresh" style="height: 15px; margin: 10px; cursor: pointer;" src="../../assets/images/refresh.png" />
      </a>
    </div>
  </div>
  <span class="available-prod-time-text" *ngIf="productDetails.Status =='CREATE_IN_PROGRESS'">Live in {{getETADetails}}</span>
  <!-- mobile actions bar -->
  <div class="mbl-control-bar d-flex justify-content-between m-4">
    <div style="height: 45px; display: flex; border: 1px solid #465374; border-radius: 23px; align-items: center; padding: 10px;">
      <div style="width: 20px; height: 20px; border-radius: 10px;" class="{{getStatusColor(productDetails.Status)}}" *ngIf="productDetails && productDetails.Status"></div>
      <span style="margin: 0px 10px; font-size: 14px;" class="text-white font-class" *ngIf="productDetails && productDetails.Status">{{getStatus(productDetails.Status)}}</span>
      <img alt="Provisioned Product Status Refresh" style="height: 15px; margin: 10px; cursor: pointer;" src="../../assets/images/refresh.png" (click)="refreshProduct()" />
    </div>
    <!-- mobile action button -->
    <div *ngIf="showAction && (productDetails.Status !== 'DELETE_COMPLETE' && productDetails.Status !== 'DELETE_IN_PROGRESS') && (productDetails.Status != 'Transitioning') && (productDetails.Status !='CREATE_IN_PROGRESS')" class="mbl-action-control" [ngClass]="productDetails.Status == 'Transitioning' ? 'my-disable' : 'my-enable' && !showProductAction? 'my-disable' : 'my-enable'">
      <div class="links-unordered">
        <a class="toggle-button" href="#">Actions</a>
        <ul style="display:none;">
          <li style="background-color: transparent;text-align: left;margin: 12px 0px 0px 10px;padding-left: 0px;text-align: left;"><a href="#" style="color: #85D4FF; font-size: 14px; font-weight: 600;">CONNECT</a></li>
          <!-- <li *ngFor="let connect of connectMenu"><a href="#">{{connect}}</a></li> -->
          <div class="tab-flex" *ngIf="projectStatus !='Stopped'">
            <div *ngFor="let connect of connectMenu">
              <div class="connect-link" [ngClass]="connect.disable === true ? 'my-disable my-disable-button': 'my-enable'" style="background-color: #15244B; border-radius: 5px; width: 100%; height: 45px; display: flex; align-items: center; margin-top: 10px;margin-right: 9px; " >
                <img  alt="{{connect.menu}} Product"  style="height: 12px;height: 15px;margin-right: 10px;margin-left: 10px" src="{{connect.imageUrl}}" />
                <span (click)="actions(productDetails, connect.menu)" style="font-size: 10px;cursor: pointer;" class="text-white font-class">{{connect.menu}}</span>
              </div>
            </div>
          </div>
          <li style="background-color: transparent;
            text-align: left;
            margin: 12px 0px 0px 10px;padding-left: 0px;text-align: left;"><a href="#" style="color: #85D4FF; font-size: 14px; font-weight: 600;">ACTIONS</a></li>
          <!-- <li *ngFor="let action of actionsMenu"><a href="#">{{action}}</a></li> -->
          <div class="tab-flex" *ngIf="projectStatus !='Stopped'">
            <div class="action-link" *ngIf="showButton == true;" (click)="actions(productDetails, 'Attach Volume');$event.stopPropagation();" style="background-color: #15244B; border-radius: 5px; width: 100%; height: 45px; display: flex; align-items: center; margin-top: 10px;margin-right: 9px;cursor: pointer;" >
              <img alt="Attach EBS Volume" style="height: 12px;height: 15px;margin-right: 15px;margin-left: 15px" src="../../assets/images/plug-solid.svg" />
              <span style="font-size: 12px;cursor: pointer;" class="text-white font-class">Attach Volume</span>
            </div>
            <div *ngFor="let action of actionsMenu">
              <div class="action-link" [ngClass]="action.disable === true ? 'my-disable my-disable-button': 'my-enable'" style="background-color: #15244B; border-radius: 5px; width: 100%; height: 45px; display: flex; align-items: center; margin-top: 10px;margin-right: 9px; " >
                <img alt=" {{action.action}} Product"  style="height: 12px;height: 15px;margin-right: 10px;margin-left: 10px" src="{{action.imageUrl}}" />
                <span  (click)="actions(productDetails, action.action)" style="font-size: 10px;cursor: pointer;" class="text-white font-class">{{action.action}}</span>
              </div>
            </div>
          </div>
          <div class="action-link" *ngIf="productDetails.Status != 'Transitioning' && terminateAction" style="background-color: #15244B; border-radius: 5px; width: 100%; height: 45px; display: flex; align-items: center; margin-top: 10px;margin-right: 9px; ">
            <img alt="Terminate Product" style="height: 12px;height: 15px;margin-right: 10px;margin-left: 10px" src="../../assets/images/no-stopping@2x.png" />
            <span (click)="actions(productDetails, 'Terminate')" style="font-size: 10px;cursor: pointer;" class="text-white font-class">Terminate</span>
          </div>
        </ul>
      </div>
    </div>
  </div>
  <div class="mbl-control-bar d-flex justify-content-between m-4">
      <span class="available-prod-time-text-mob" *ngIf="productDetails.Status =='CREATE_IN_PROGRESS'">Live in {{getETADetails}}</span>
  </div>
  <!-- tab section -->
  <div>
    <tabset>
      <!-- product details tab -->
      <tab class="tab" heading="Product Details" [active]="productDetails && productDetails.Status !== 'CREATE_IN_PROGRESS'" (select)="eventTabAction($event)">
        <div style="display: block; position: relative;">
          <div class="product-details-wrapper">
            <label style="color: #85D4FF; font-size: 16px; font-weight: 500; margin-top: 20px;" class="font-class">Description</label>
            <label style="font-size: 14px; font-weight: 400;max-width: 95%;" class="text-white font-class">{{productDetails.description}}</label>
            <label style="color: #85D4FF; font-size: 16px; font-weight: 500; margin-top: 20px;" class="font-class">Created On</label>
            <label style="font-size: 14px; font-weight: 400;max-width: 95%;" class="text-white font-class">{{productDetails.createdTime | date:'medium'}}</label>
            <div style="display: flex; flex-direction: column;" *ngFor="let parameter of parameters">
              <label style="color: #85D4FF; font-size: 16px; font-weight: 500; margin-top: 20px;" class="font-class">{{parameter.Key}}</label>
              <pre *ngIf="parameter.Key !== 'IamPolicyDocument' && parameter.Key !== 'S3Mounts'" style="font-size: 14px; font-weight: 400;max-width: 95%;background: transparent; border: none; padding-top: 0px; padding-left: 0px" class="text-white font-class">{{parameter.Value}}</pre>
              <pre *ngIf="parameter.Key === 'IamPolicyDocument' || parameter.Key === 'S3Mounts'" style="font-size: 14px; font-weight: 400;max-width: 95%;background: transparent; border: none; padding-top: 0px; padding-left: 0px" class="text-white font-class">{{parameter.Value | json}}</pre>
            </div>
            <div style="display: flex; flex-direction: column;" *ngIf="bucketName">
              <label style="color: #85D4FF; font-size: 16px; font-weight: 500; margin-top: 20px;" class="font-class">Bucket Name</label>
              <label style="font-size: 14px; font-weight: 400;" class="text-white font-class">{{bucketName}}</label>
            </div>
            <div style="display: flex; flex-direction: column;" *ngIf="notebookName">
              <label style="color: #85D4FF; font-size: 16px; font-weight: 500; margin-top: 20px;" class="font-class">Notebook Name</label>
              <label style="font-size: 14px; font-weight: 400;" class="text-white font-class">{{notebookName}}</label>
            </div>
            <div class="linked-product-layout" *ngIf="isLinked == true && showVolume == true">
              <label class="product-key-txt font-class">Attached Volumes</label>
              <div style="display: flex; flex-wrap: wrap;">
                <div class="product-details-wrapper linked-product-wrapper" *ngFor="let volume of listOfLinkedProducts">
                  <div style="display: flex; align-items: center;">
                    <img style="height: 30px;height: 30px;margin-right: 15px;border-radius: 5px;"  src="../../assets/images/aws_icon/Elastic-Block-Store.png" alt="Attached EBS Product"/>
                    <label style="margin-right: 30px; margin-bottom: 0px;" class="product-value-txt text-white font-class" title="{{volume.name}}">{{volume.name}}</label>
                  </div>
                  <span style="align-self: center; position: absolute; right: 5px;">
                      <i class="fa fa-ellipsis-v" data-toggle="dropdown" title="Action" aria-hidden="true" style="color: #f1f1f1;cursor: pointer; font-size: 25px;"></i>
                      <ul class="dropdown-menu" style="cursor:pointer;background: #07102A; position: absolute; top: 0; right: 0 !important; width: max-content">
                        <li><span class="listItems unlink-txt" (click)="detachVolume(volume)" >Detach Volume</span></li>                        
                      </ul>
                  </span>
                </div>
              </div>
            </div>    
        <div class="linked-product-layout" *ngIf="isLinked == true && hideVolume == true">
              <label class="product-key-txt font-class">Linked Products</label>
              <div style="display: flex; flex-wrap: wrap;">
                <div class="product-details-wrapper linked-product-wrapper" *ngFor="let notebook of listOfLinkedProducts">
                  <div style="display: flex; align-items: center;">
                    <img style="height: 30px;height: 30px;margin-right: 15px;border-radius: 5px;"  src="{{notebook.imageUrl}}" alt="Linked Sagemaker"/>
                    <label style="margin-right: 30px; margin-bottom: 0px;" class="product-value-txt text-white font-class" title="{{notebook.productName}}">{{notebook.productName}}</label>
                  </div>
                  <span style="align-self: center; position: absolute; right: 5px;">
                      <i class="fa fa-ellipsis-v" data-toggle="dropdown" title="Action" aria-hidden="true" style="color: #f1f1f1;cursor: pointer; font-size: 25px;"></i>
                      <ul class="dropdown-menu" style="cursor:pointer;background: #07102A; position: absolute; top: 0; right: 0 !important; width: max-content">
                        <li><span class="listItems unlink-txt" (click)="unlinkResource(notebook)" *ngIf="resourceType === 'AWS::S3::Bucket'">Unlink Resource</span></li>
                        <li><span class="listItems unlink-txt" ngxClipboard [cbContent]="notebook.name" (cbOnSuccess)="onCopySuccess()"
                          (cbOnError)="onCopyError()" *ngIf="resourceType === 'AWS::SageMaker::NotebookInstance'">Copy Bucket Name</span></li>
                      </ul>
                  </span>
                </div>
              </div>
            </div>


          </div>
          <!-- actions -->
          <!-- tab action button -->
          <div class="tab-action-control" style="display:none;">
            <div class="tab-action links-unordered">
              <a class="toggle-button" href="#">Actions</a>
              <ul style="display:none;">
                <li style="background-color: transparent;text-align: left;margin: 12px 0px 0px 10px;padding-left: 0px;text-align: left;"><a href="#" style="color: #85D4FF; font-size: 14px; font-weight: 600;">CONNECT</a></li>
                <li *ngFor="let connect of connectMenu"><a href="#">{{connect}}</a></li>
                <li style="background-color: transparent;text-align: left;
            margin: 12px 0px 0px 10px;padding-left: 0px;text-align: left;"><a href="#" style="color: #85D4FF; font-size: 14px; font-weight: 600;">ACTIONS</a></li>
                <li *ngFor="let action of actionsMenu"><a href="#">{{action}}</a></li>
              </ul>
            </div>
          </div>
          <div class="web-actions" *ngIf=" (productDetails.Status != 'DELETE_COMPLETE') && (productDetails.Status != 'DELETE_IN_PROGRESS') && (productDetails.Status != 'Transitioning') && (productDetails.Status !='CREATE_IN_PROGRESS')" style="background-color: #07102A; border-radius: 10px; margin: 20px; padding: 20px;" [ngClass]="productDetails.Status == 'Transitioning' ? 'my-disable' : 'my-enable' && !showProductAction? 'my-disable' : 'my-enable'">
            <div style="display: flex; flex-direction: column">
              <span style="color: #85D4FF; font-size: 14px; font-weight: 600;" class="font-class">CONNECT</span>
              <div class="tab-flex" *ngIf="projectStatus !='Stopped'">
                <div *ngFor="let connect of connectMenu">
                  <div class="connect-link" [ngClass]="connect.disable === true ? 'my-disable my-disable-button': 'my-enable'" (click)="actions(productDetails, connect.menu);$event.stopPropagation();" style="background-color: #15244B; border-radius: 5px; width: 100%; height: 45px; display: flex; align-items: center; margin-top: 10px;margin-right: 9px;cursor: pointer; " >
                    <img alt="{{connect.menu}} Product" style="height: 12px;height: 15px;margin-right: 15px;margin-left: 15px" src="{{connect.imageUrl}}" />
                    <span style="font-size: 12px;cursor: pointer;" class="text-white font-class">{{connect.menu}}</span>
                  </div>
                </div>
              </div>
              <span style="color: #85D4FF; font-size: 14px; font-weight: 600; margin-top: 20px;" class="font-class">ACTIONS</span>
              
              <div class="tab-flex" *ngIf="projectStatus !='Stopped'">
                <div class="action-link" *ngIf="showButton == true;" (click)="actions(productDetails, 'Attach Volume');$event.stopPropagation();" style="background-color: #15244B; border-radius: 5px; width: 100%; height: 45px; display: flex; align-items: center; margin-top: 10px;margin-right: 9px;cursor: pointer;" >
                  <img  alt="Attach EBS Volume"  style="height: 12px;height: 15px;margin-right: 15px;margin-left: 15px" src="../../assets/images/plug-solid.svg" />
                  <span style="font-size: 12px;cursor: pointer;" class="text-white font-class">Attach Volume</span>
              </div>
                <div *ngFor="let action of actionsMenu">
                  <div class="action-link" [ngClass]="action.disable === true ? 'my-disable my-disable-button': 'my-enable'" (click)="actions(productDetails, action.action);$event.stopPropagation();" style="background-color: #15244B; border-radius: 5px; width: 100%; height: 45px; display: flex; align-items: center; margin-top: 10px;margin-right: 9px;cursor: pointer;" >
                    <img  alt="{{action.action}} Product"  style="height: 12px;height: 15px;margin-right: 15px;margin-left: 15px" src="{{action.imageUrl}}" />
                    <span   style="font-size: 12px;cursor: pointer;" class="text-white font-class">{{action.action}}</span>
                  </div>
                </div>
              </div>              
              <div class="action-link" *ngIf="productDetails.Status != 'Transitioning' && !productDetails.isProjectStorage && terminateAction" (click)="actions(productDetails, 'Terminate');$event.stopPropagation();" style="background-color: #15244B; border-radius: 5px; width: 100%; height: 45px; display: flex; align-items: center; margin-top: 10px;margin-right: 9px;cursor: pointer;">
                <img  alt="Terminate Product"  style="height: 12px;height: 15px;margin-right: 15px;margin-left: 15px" src="../../assets/images/no-stopping@2x.png" />
                <span style="font-size: 12px;cursor: pointer;" class="text-white font-class">Terminate</span>
              </div>
            </div>
          </div>
        </div>
      </tab>
      <!-- events tab -->
      <tab class="tab" heading="Events" [active]="productDetails && productDetails.Status === 'CREATE_IN_PROGRESS'" (select)="eventTabAction($event)" >
        <div class="event-tab-header" style="display: flex; justify-content: space-between; align-items: center; margin: 37px 0px;">
          <span style="color: #fff; font-size: 1.75rem; font-weight: 500;" class="font-class">Events</span>
          <div style="display: flex; align-items: center; padding: 5px 10px; border: 1px solid #6E7C9F; background-color: #030A1E; border-radius: 5px; cursor: pointer;" (click)="refreshLogs()">
            <img alt="Provisioned Product Events Refresh" style="height: 12px;" src="../../assets/images/refresh.png" />
            <span style="font-size: 12px; font-weight: 400; margin-left: 10px;" class="text-white font-class">REFRESH</span>
          </div>
        </div>
        <div class="events-wrapper" *ngIf="events.length > 0">
          <div class="event-card" id="event-cards" *ngFor="let log of events">
            <ul class="list-group">
              <div class="d-flex justify-content-between mb-2">
                <div style="color: #85D4FF;width: 50%;"> Logical ID</div>
                <div style="width: 50%;word-break: break-word !important;">{{ log.ResourceType }}</div>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <div style="color: #85D4FF;width: 50%;">Time Stamp</div>
                <div style="width: 50%;word-break: break-word !important;">{{ log.Timestamp | date:'medium' }}</div>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <div style="color: #85D4FF;width: 50%;">Status</div>
                <div style="width: 50%;word-break: break-word !important;">{{ log.Status }}</div>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <div style="color: #85D4FF;width: 50%;">Status reason</div>
                <div style="width: 50%;word-break: break-word !important;">{{ logStatusMessage(log.StatusMessage)  }}</div>
              </div>
            </ul>
          </div>
        </div>
        <div class="table-responsive" id="event-table" *ngIf="events.length > 0">
          <div class="table-header">
            <div class="my-product-header" style="width: 20%;">
              <span class="table-title-text font-class">Logical ID</span>
            </div>
            <div class="my-product-header" style="width: 20%;">
              <span class="table-title-text font-class">Time Stamp</span>
            </div>
            <div class="my-product-header" style="width: 20%;">
              <span class="table-title-text font-class">Status</span>
            </div>
            <div class="my-product-header">
              <span class="table-title-text font-class">Status Reason</span>
            </div>
          </div>
          <table class="table" style="width: 100%; margin-top: 10px; margin-bottom: 60px;">
            <tbody>
              <tr class="table-body-separator" *ngFor="let log of events">
                <td style="width: 20%;">
                  <span class="my-product-value font-class">{{ log.ResourceType }}</span>
                </td>
                <td style="width: 20%;">
                  <span class="my-product-value font-class">{{ log.Timestamp | date:'medium' }}</span>
                </td>
                <td style="width: 20%;">
                  <span class="my-product-value font-class">{{ log.Status }}</span>
                </td>
                <td>
                  <span class="my-product-value font-class" title="{{log.StatusMessage}}">{{ log.StatusMessage }}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="product-avail" style=" border-radius: 10px; ">
            <span class="page-count-text">{{ eventsCurrentPageLowerCount }} - {{ eventsCurrentPageUpperCount }} of {{ eventsTotalRecords }}</span>
            <div class="pagination-wrapper">
              <img alt="First Page" class="pagination-next-btn" [ngClass]="{'disable-first-btn':eventsPage === 1 }" src="../../assets/images/pagination-first-active.png"
                (click)="getNextProductEvents('first')" />
              <img alt="Previous Page" class="pagination-next-btn" [ngClass]="{'disable-prev-btn':eventsPage === 1 }" src="../../assets/images/pagination-prev-active.png"
                (click)="getNextProductEvents('previous')" />
              <img alt="Next Page" class="pagination-next-btn" [ngClass]="{'disable-next-btn':eventsPage === eventsTotalPages}" src="../../assets/images/pagination-next-active.png"
                (click)="getNextProductEvents('next')" />
              <img alt="Last Page" class="pagination-next-btn" [ngClass]="{'disable-last-btn':eventsPage === eventsTotalPages}" src="../../assets/images/pagination-last-active.png"
                (click)="getNextProductEvents('last')" />
            </div>
          </div>
        </div>
        <div style="display: flex; justify-content: center; margin: 40px;" *ngIf="events.length === 0 && !loader" class="empty-my-event-layout-a">
          <span style="font-size: 18px;" class="no-data-found text-white font-class text-center">There are no events to show at this time. Please wait for some time or hit the refresh button to check again.</span>
        </div>
      </tab>

         <!-- output details tab -->
         <tab class="tab" heading="Outputs" *ngIf="recordOutputs.length > 0" (select)="eventTabAction($event)">
          <div style="display: block; position: relative;">
            <div class="product-details-wrapper">
              <div style="display: flex; flex-direction: column;" *ngFor="let output of recordOutputs">
                <label style="color: #85D4FF; font-size: 16px; font-weight: 500; margin-top: 20px;" class="font-class">{{output.OutputKey}}</label>
                <label style="font-size: 14px; font-weight: 400;" class="text-white font-class">{{output.OutputValue}}</label>
              </div>
              </div>
          </div>
         </tab>

         <!--cost details tab -->
      <tab class="tab" heading="Cost">
        <div class="event-tab-header"
          style="display: flex; justify-content: space-between; align-items: center; margin: 16px 0px;">
          <span style="color: #fff; padding-right: 30px; font-size: 1.75rem; font-weight: 500;" class="font-class">Cost
            Trend</span>
          <div style="padding-top: 20px">
            <mat-form-field>
              <label for="dateRangePicker" class="visually-hidden">Select Date Range</label>
              <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [min]="createdDate"  [max]="stopDate" disabled>
                <input id="dateRangePicker" aria-label="costStartDate" matStartDate [(ngModel)]="pastSevenDays" formControlName="start" #dateRangeStart>
                <input id="dateRangePicker" aria-label="costEndDate" matEndDate [(ngModel)]="pastOneDay" formControlName="end" #dateRangeEnd
                  (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker disabled="false"></mat-date-range-picker>
            </mat-form-field>
          </div>
        </div>
        <div style="display: flex; justify-content: center; margin: 40px; margin-top: 10px;"
          *ngIf="!loader && (cost.length == 0 || costdate.length == 0)" class="empty-my-event-layout-a">
          <span style="font-size: 18px;" class="no-data-found text-white font-class text-center">This product has not consumed
            any budget in the selected date range</span>
        </div>
      
        <div [hidden]="!chart && !loader" style="display: block; width: 100%; color: #919EA1;">
          <canvas id="canvas" #canvas height="50">{{ chart }}</canvas>
        </div>
      
        <div class="table-responsive" id="event-table" *ngIf="dailyCost && dailyCost.length > 0">
          <div class="table-header">
            <div class="my-product-header" style="width: 20%;">
              <span class="table-title-text font-class">Date</span>
            </div>
            <div class="my-product-header" style="width: 60%;">
              <span class="table-title-text font-class">Cost (USD)</span>
            </div>
          </div>
      
          <table class="table" style="width: 100%; margin-top: 10px; margin-bottom: 60px;">
            <tbody>
              <tr class="table-body-separator" *ngFor="let details of dailyCost">
                <td style="width: 20%;">
                  <span class="my-product-value font-class">{{ details.date.split("T00:00:00.000Z").join("") }}</span>
                </td>
                <td style="width: 102%;">
                  <span class="my-product-value font-class">{{ details.cost }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </tab>

       <!-- Egress Store tab -->
       <tab class="tab" heading="Egress Store" *ngIf="isEgressStoreEnabled == 'true' " (selectTab)="eventTabAction($event)" >
        <div class="event-tab-header" style="display: flex; justify-content: space-between; align-items: center; margin: 37px 0px;" *ngIf="egressStoreFiles.length > 0">
          <span style="color: #fff; font-size: 1.75rem; font-weight: 500;" class="font-class">Files</span>
          <div class="launch-now-wrapper">
              <button id="submitProject" class="launch-btn text-white font-class" [disabled]="clicked || !isAbleToSubmitEgressRequest" name="button" (click)="submitEgressRequest(); clicked=true" type="submit">Submit Egress Request
              </button>
          </div>
        </div>
        <div class="events-wrapper" *ngIf="events.length > 0 && !egressStoreLoader">
          <div class="event-card" id="event-cards" *ngFor="let file of egressStoreFiles">
            <ul class="list-group">
              <div class="d-flex justify-content-between mb-2">
                <div style="color: #85D4FF;width: 50%;">File Name</div>
                <div style="width: 50%;word-break: break-word !important;">{{ file.Key }}</div>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <div style="color: #85D4FF;width: 50%;">Folder Name</div>
                <div style="width: 50%;word-break: break-word !important;">{{ file.Folder }}</div>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <div style="color: #85D4FF;width: 50%;">ETag</div>
                <div style="width: 50%;word-break: break-word !important;">{{ getEtag(file.ETag) }}</div>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <div style="color: #85D4FF;width: 50%;">Last Modified</div>
                <div style="width: 50%;word-break: break-word !important;">{{ file.LastModified | date:'medium'  }}</div>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <div style="color: #85D4FF;width: 50%;">Size</div>
                <div style="width: 50%;word-break: break-word !important;">{{ file.Size }}</div>
              </div>
            </ul>
          </div>
        </div>
        <div class="table-responsive" id="event-table" *ngIf="egressStoreFiles.length > 0 && !egressStoreLoader">
          <div class="table-header">
            <div class="my-product-header" style="width: 20%;">
              <span class="table-title-text font-class">File Name</span>
            </div>
            <div class="my-product-header" style="width: 20%;">
              <span class="table-title-text font-class">Folder Name</span>
            </div>
            <div class="my-product-header" style="width: 20%;">
              <span class="table-title-text font-class">ETag</span>
            </div>
            <div class="my-product-header" style="width: 20%;">
              <span class="table-title-text font-class">Last Modified</span>
            </div>
            <div class="my-product-header">
              <span class="table-title-text font-class">Size</span>
            </div>
          </div>
          <table class="table" style="width: 100%; margin-top: 10px; margin-bottom: 60px;">
            <tbody>
              <tr class="table-body-separator" *ngFor="let file of egressStoreFiles">
                <td style="width: 20%;">
                  <span class="my-product-value font-class">{{ file.Key }}</span>
                </td>
                <td style="width: 20%;">
                  <span class="my-product-value font-class">{{ file.Folder }}</span>
                </td>
                <td style="width: 20%;">
                  <span class="my-product-value font-class">{{ getEtag(file.ETag) }}</span>
                </td>
                <td style="width: 20%;">
                  <span class="my-product-value font-class">{{ file.LastModified | date:'medium' }}</span>
                </td>
                <td>
                  <span class="my-product-value font-class">{{ file.Size }}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <div>
            <div class="load-more-btn-wrapper" *ngIf="showLoadMore && !egressStoreLoader">
              <button class="load-more-btn" (click)="getEgressStoreFiles()">Load More</button>
           </div>
          </div>
        </div>
        <div style="display: block !important; justify-content: center; padding: 100px; height: auto !important;" *ngIf="egressStoreFiles.length === 0 && !egressStoreLoader" class="empty-my-event-layout-a">
          <h3 style="font-weight: 700; font-size: 22px;" class="no-data-found text-white font-class text-center">No files in the egress store!</h3>
          <h4 style="font-weight: 300; font-size: 20px;" class="no-data-found-subtext text-white font-class text-center"> To make an egress request, first copy the necessary files into the egress store location mounted to your workspace.</h4>
        </div>
      </tab>
    </tabset>
  </div>
</div>
<app-loader class="spinner" *ngIf="loader"></app-loader>
<app-loader class="spinner" *ngIf="egressStoreLoader"></app-loader>
