import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from "@angular/router";
import { ResearchService } from "../_services/research.service";
import { MdlDialogService } from "@angular-mdl/core";
import { ConfirmationDialog } from '../dialog/dialog.component';
import * as $ from 'jquery';
import { InstanceComponent } from "./instance/instance.component";
import { ServiceActionsService } from "../_services/service-actions.service";
import { ToastrService } from 'ngx-toastr';
import { Globals } from "../globals";
import { ProductUtilService } from "../_services/productUtil.service";
import { DashboardService } from "../_services/dashboard.service";

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.css', '../app.component.css'],
  providers: [DashboardService,Globals]
})
export class CatalogComponent implements OnInit {
  availableProducts = 0;
  activeProducts = 0;
  activeProductCount = 0;
  activeProductsCount = 0;
  budgetConsumed : any = 0;
  consumedBudgetPercentage = 0;
  thresholdPercentage = 80;
  availableBudgetPercentage = 0;
  budgetAvailable : any = 0;
  budgetConsumedByResearcher : any = 0;
  monthlyBudgetConsumedByResearcher : any = 0;
  monthlyBudgetCosumedByResearcherAndProject: any = 0;;
  availableProductsList = [];
  activeProductsList = [];
  activeProductsToShow = [];
  availableProductsToShow = [];
  availSlider1 = [];
  availSlider2 = [];
  availSlider3 = [];
  activeSlider1 = [];
  activeSlider2 = [];
  activeSlider3 = [];
  accountId;
  projectName;
  projectId;
  dropOption;
  page = 1;
  limit = 10;
  logLoader = true;
  auditTrail = [];
  loadMore = false;
  provisionIdForLog;
  userName: any;
  researcherList = [];
  servicemenu = [];
  projectStatus;
  showSpinner = false;
  getAllProducts = false;
   
  imageContainer: any = [
    { name: 'sagemaker', url: '/assets/images/aws_icon/sagemaker.png' },
    { name: 'emr', url: '/assets/images/aws_icon/emr.png' },
    { name: 'rds', url: '/assets/images/aws_icon/rds.png' },
    { name: 'machinelearning', url: '/assets/images/aws_icon/machinelearning.png' },
    { name: 'azure-ml', url: '/assets/images/azure_icon/azure-ml.png' },
    { name: 'azure-rstudio', url: '/assets/images/azure_icon/azure.png' },
    { name: 'hpc', url: '/assets/images/aws_icon/openhpc.png' },
    { name: 'workbench', url: '/assets/images/aws_icon/sagemaker.png' },
    { name: 'vpn', url: '/assets/images/aws_icon/vpn.png' },
    { name: 'vpc', url: '/assets/images/aws_icon/vpc.png' },
    { name: 'dynamodb', url: '/assets/images/aws_icon/dynamodb.png' },
    { name: 's3', url: '/assets/images/aws_icon/s3.png' },
    { name: 'blob', url: '/assets/images/azure_icon/azure-blob-block.png' },
    { name: 'workspace', url: '/assets/images/aws_icon/workspaces.png' },
    { name: 'rlcatalyst', url: '/assets/images/logo_rl.png' },
    { name: 'catalyst', url: '/assets/images/logo_rl.png' },
    { name: 'ec2', url: '/assets/images/aws_icon/ec2.png' },
    { name: 'vm', url: '/assets/images/azure_icon/azure-vm.png' },
    { name: 'office', url: '/assets/images/aws_icon/ms_office.png' },
    { name: 'oracle', url: '/assets/images/aws_icon/oracle.png' },
    { name: 'windows', url: '/assets/images/aws_icon/windows.png' },
    { name: 'cisco', url: '/assets/images/aws_icon/cisco.png' },
    { name: 'aws', url: '/assets/images/aws_icon/aws.png' },
    { name: 'linux', url: '/assets/images/aws_icon/linux.png' },
    { name: 'wordpress', url: '/assets/images/aws_icon/wordpress.png' },
    { name: 'cloud9-ide', url: '/assets/images/aws_icon/cloud9.png' },
    { name: 'rdp', url: '/assets/images/aws_icon/rdp.png' },
    { name: 'appstream', url: '/assets/images/aws_icon/appStream.png' },
    { name: 'chime', url: '/assets/images/aws_icon/chime.png' },
    { name: 'connect', url: '/assets/images/aws_icon/connect.png' },
    { name: 'redshift', url: '/assets/images/aws_icon/redshift.png' },
    { name: 'controltower', url: '/assets/images/aws_icon/controltower.png' },
    { name: 'docker', url: '/assets/images/aws_icon/docker.png' },
    { name: 'drupal', url: '/assets/images/aws_icon/drupal.png' },
    { name: 'emailexchange', url: '/assets/images/aws_icon/emailexchange.png' },
    { name: 'instancescheduler', url: '/assets/images/aws_icon/instancescheduler.png' },
    { name: 'mongodb', url: '/assets/images/aws_icon/mongodb.png' },
    { name: 'moodle', url: '/assets/images/aws_icon/moodle.png' },
    { name: 'sharepoint', url: '/assets/images/aws_icon/sharepoint.png' },
    { name: 'shibboleth', url: '/assets/images/aws_icon/shibboleth.png' },
    { name: 'tableau', url: '/assets/images/aws_icon/tableau.png' }
  ];

  menuContainer: any = [
    { name: 'ec2', menu: ['SSH/RDP', 'Start', 'Stop', 'Restart'] },
    { name: 'sagemaker', menu: ['Link', 'Start', 'Stop'] },
    { name: 'workspace', menu: ['Link', 'Start', 'Stop', 'Restart'] },
    { name: 's3', menu: ['Upload', 'Explore', 'Share'] }
  ];

  eventS = this.getEventSource(`/stream_events`);

  constructor(private researchService: ResearchService,
    private serviceAction: ServiceActionsService,
    private dialog: MdlDialogService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private globals: Globals,
    private productUtil: ProductUtilService,
    private apiService: DashboardService
  ) { 
    router.events.subscribe((val) => { 
      const navVal = val instanceof NavigationStart; 
      if(navVal == true) { 
        this.eventS.close();
      }  
    });
  }

  ngOnInit() {
    this.apiService.checkIfResearcher();
    this.serverStreamEvent(); 
    this.projectName = sessionStorage.getItem('projectName');
    this.projectId = sessionStorage.getItem('projectId');
    this.accountId = this.route.snapshot.params['accountId'];

    this.userName = sessionStorage.getItem('currentUser');
    this.getResearcherList(this.projectId, this.accountId);

    let getSessionData = localStorage.getItem(this.accountId);
    getSessionData = JSON.parse(getSessionData);
 
    sessionStorage.setItem('accountId', this.accountId);

    const budget = getSessionData['budget'];
    if (getSessionData['budgetConsumed']['projectBudgetConsumed']) {
      this.budgetConsumed = getSessionData['budgetConsumed']['projectBudgetConsumed'];
    }
    this.budgetConsumed = parseFloat(this.budgetConsumed).toFixed(2);

    if (getSessionData['consumedBudgetPercentage']) {
      this.consumedBudgetPercentage = getSessionData['consumedBudgetPercentage'];
    }
    if (getSessionData['thresholdPercentage']) {
      this.thresholdPercentage = getSessionData['thresholdPercentage'];
    }
    if (getSessionData['availableBudgetPercentage']) {
      this.availableBudgetPercentage = getSessionData['availableBudgetPercentage'];
    }
    this.budgetAvailable = budget - this.budgetConsumed;
    this.budgetAvailable = Math.round(this.budgetAvailable);
    if (getSessionData['budgetConsumed']['budgetConsumedbyResearcher']) {
      this.budgetConsumedByResearcher = getSessionData['budgetConsumed']['budgetConsumedbyResearcher'];
    }
    this.budgetConsumedByResearcher = parseFloat(this.budgetConsumedByResearcher).toFixed(2);
    let today = new Date();
    let currentMonth = today.getMonth() + 1;
    let currentYear = today.getFullYear();
    let currentMonthCostByResearcher : any = 0;
      this.monthlyBudgetCosumedByResearcherAndProject = getSessionData['budgetConsumed']['monthlyProjectBudgetConsumed']
      if (this.monthlyBudgetCosumedByResearcherAndProject.length > 0) {
        let currentMonthProjectCost = this.monthlyBudgetCosumedByResearcherAndProject.filter((monthCostByProject) => {
          return currentMonth == monthCostByProject.month && currentYear == monthCostByProject.year;
        });
        if (currentMonthProjectCost.length > 0) {
          let reseachersCosts = currentMonthProjectCost[0].costByResearcher
          let currentMonthResearcherCost = reseachersCosts.filter((researcherCost) => {
            return this.userName == researcherCost.name;
          });
          if (currentMonthResearcherCost.length > 0) {
            currentMonthCostByResearcher += currentMonthResearcherCost[0].cost;
          }
        }
      }
    this.monthlyBudgetConsumedByResearcher = parseFloat(currentMonthCostByResearcher).toFixed(2);
    this.getAllAvailableProducts();
    this.getAllActiveProducts();
  }

  private serverStreamEvent() {
    this.researchService.getServerSentEvent(this.eventS).subscribe(res => {
      const resObj = res.data;
      if (!resObj) {
        return;
      }
      try {
        const { status, data, payload } = JSON.parse(resObj);
        // server give handshake to the alive connection
        if (data === 'check') {
          return;
        }
        if (data === 'provisionProduct') {
          const { ProvisionedProductId , Type, ResourceType } = payload;
          if(Type === 'ResourceStateChange' && ResourceType === 'aws.s3') {
            return;
          }
          this.getAllActiveProducts();
        }
      } catch (e) {
        console.log(e.message);
      }
    });
  }

  getEventSource(url: string): EventSource {
    return new EventSource(url, { withCredentials: true });
  }

  private getAllActiveProducts() {
    this.showSpinner = true;
    const page1 = 1, limit1 = 10;
    const filterBy = 'Active';
    this.getAllProducts = false;
      this.researchService.getFilteredProvisionedProducts(page1, limit1, this.accountId, this.projectId, filterBy, this.getAllProducts).subscribe((res:any) => {
      this.showSpinner = false;
      this.activeSlider1 = [];
      this.activeSlider2 = [];
      this.activeSlider3 = [];
      this.activeProducts = res.docs.length;
      this.activeProductsCount = res.total;
      this.activeProductsList = res.docs;
      this.activeProductCount = res.total;
      this.dataBindingActiveProduct();
    }, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
});
  }

  private getAllAvailableProducts() {
    const page = 1, limit = 10;
    this.researchService.getListOfResearchProducts(page, limit, this.accountId, this.projectId ,'All').subscribe((res:any) => {
      this.availSlider1 = [];
      this.availSlider2 = [];
      this.availSlider3 = [];
      this.availableProducts = res.docs.length;
      this.availableProductsList = res.docs;
      this.dataBindingAvailableProduct();
    }, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    });
  }

  dataBindingAvailableProduct() {
    for (let i = 0; i < this.availableProducts; i++) {
      try {
        if (this.availableProductsList[i].tags.length > 0) {
          const viewMoreDetails = this.availableProductsList[i].tags.filter(tag => {
            return tag["Key"] === "DetailsLink"
          })
          if (viewMoreDetails.length > 0) {
            const viewMoreLink = viewMoreDetails[0].Value
            this.availableProductsList[i]["detailsLink"] = viewMoreLink
          }
        }
        this.availableProductsList[i]['imageUrl'] = '/assets/images/aws_icon/aws.png';
        const iName = this.productUtil.getServiceFromTags(this.availableProductsList[i].tags);
        const getImgLink = this.productUtil.getImageForProduct(iName);
        if (getImgLink) {
          this.availableProductsList[i]['imageUrl'] = getImgLink['url'];
        }
        if (this.availableProducts > 6) {
          this.availableProductsToShow = this.availableProductsList.slice(0, 6);
        } else {
          this.availableProductsToShow = this.availableProductsList;
        }
      } catch (e) {
        console.log(e);
      }

    }
  }
  getProductETA(data) {
    try {
      let eta = "NA";
      const value = data[1].Value;
      const key = data[1].Key;
      if (key == "EstimatedTimeToProvision") {
        eta = value;
      }
      return eta;
    } catch (error) {
      return "NA";
    }
  }

  dataBindingActiveProduct() {
    for (let i = 0; i < this.activeProducts; i++) {
      try {
        this.activeProductsList[i]['imageUrl'] = '../../assets/images/aws_icon/aws.png';
        const iName = this.productUtil.getServiceFromTags(this.activeProductsList[i].Tags);
        const getImgLink = this.productUtil.getImageForProduct(iName);
        if (getImgLink) {
          this.activeProductsList[i]['imageUrl'] = getImgLink['url'];
        }
        if (this.activeProductsList.length > 3) {
          this.activeProductsToShow = this.activeProductsList.slice(0, 3);
        } else {
          this.activeProductsToShow = this.activeProductsList;
        }
      } 
      // try {
      //   //const iName = this.activeProductsList[i].Tags[0].Value.toLowerCase();
      //   this.activeProductsList[i]['imageUrl'] = '../../assets/images/aws_icon/aws.png';
      //   const serviceName = this.productUtil.getServiceFromTags(this.activeProductsList[i].Tags);
      //   const getImgLinkObj = this.productUtil.getImageForProduct(serviceName);
      //   if (getImgLinkObj) {
      //     this.activeProductsList[i]['imageUrl'] = getImgLinkObj['url'];
      //   }
      //   if (this.activeProductsList.length > 3) {
      //         this.activeProductsToShow = this.activeProductsList.slice(0, 3);
      //       } else {
      //         this.activeProductsToShow = this.activeProductsList;
      //       }
      // }
      catch (e) {
        console.log(e);
      }
    }
  }

  catalogOrder(availPObj) {
    const productId = availPObj.productId;
    const name = encodeURIComponent(availPObj.name);
    const stdCatalogItemId = availPObj.stdCatalogItemId
    localStorage.setItem(productId, JSON.stringify(availPObj));
    const projectName = encodeURIComponent(this.projectName);
    sessionStorage.setItem('projectName',this.projectName);
    this.router.navigate(['/order/' + projectName + '/' + this.accountId + '/' + this.projectId + '/'+ productId +'/' + name + '/' + stdCatalogItemId]);
  }

  getStatus(status) {
    let statusV = status;
    let state = statusV.toUpperCase();
    switch (state) {
      case 'INITIATED':
        statusV = "Initiated";
        break;
      case 'CREATE_IN_PROGRESS':
        statusV = 'Creating';
        break;
      case 'CREATE_COMPLETE':
        statusV = 'Provisioned';
        break;
      case 'CREATE_FAILED':
        statusV = 'Failed';
        break;
      case 'ROLLBACK_IN_PROGRESS':
        statusV = 'Failed';
        break;
      case 'ROLLBACK_COMPLETE':
        statusV = 'Failed';
        break;
      case 'CREATED':
        statusV = 'Provisioned';
        break;
      case 'AVAILABLE':
        statusV = 'Provisioned';
        break;
      case 'ERROR':
        statusV = 'Failed';
        break;
      case 'DELETE_IN_PROGRESS':
        statusV = 'Terminating';
        break;
      case 'DELETE_COMPLETE':
        statusV = 'Terminated';
        break;
      default:
        break;
    }
    return statusV;
  }

  getStatusColor(status) {
    let statusV = 'status-paused';
    switch (status.toLowerCase()) {
      case 'active':
        statusV = 'status-running';
        break;
      case 'warning':
        statusV = 'status-paused';
        break;
      case 'failed':
        statusV = 'status-stopped';
        break;
      case 'create_in_progress':
        statusV = 'status-paused';
        break;
      case 'create_complete':
        statusV = 'status-running';
        break;
      case 'create_failed':
        statusV = 'status-stopped';
        break;
      case 'rollback_complete':
        statusV = 'status-stopped';
        break;
      case 'available':
        statusV = 'status-running';
        break;
      case 'created':
        statusV = 'status-running';
        break;
      case 'error':
        statusV = 'status-stopped';
        break;
      default:
        statusV = 'status-paused';
        break;
    }
    return statusV;
  }

  openKnowMore(detailsLink) {
    if (detailsLink) {
      window.open(detailsLink, '_blank');
    } else {
      return
      // window.open("#", '_blank');
    }
  }

  openProductDetails(activeProduct) {
    const projectName = encodeURIComponent(this.projectName);
    sessionStorage.setItem('projectName',this.projectName);
    this.router.navigate(['/product/' + projectName + '/' + activeProduct.ProvisionedProductName, { ppId: activeProduct.ProvisionedProductId }], { queryParams: { projectStatus: this.projectStatus } });
  }

  getAllAvailProduct() {
    this.getAllAvailableProducts();
  }

  getAllActiveProduct() {
    this.getAllActiveProducts();
  }

  deleteProvisionedProduct(ppId) {
    this.apiService.getDialogBox().subscribe((data:any) => {
      if(data.status === 200){
    this.dialog.showCustomDialog({
      component: ConfirmationDialog,
      providers: [{ provide: 'data', useValue: { deleteObj: ppId, action: 'provisionedProduct', message: `Are you sure you want to delete ${ppId.ProvisionedProductName} product ?` } }],
      isModal: true,
      styles: {
        "height": "auto",
        "width": "40%"
      },
      clickOutsideToClose: true,
      enterTransitionDuration: 400,
      leaveTransitionDuration: 400,
    })}}, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    })
  }

  navigateAllActiveProduct() {
    const projectName = encodeURIComponent(this.projectName);
    sessionStorage.setItem('projectName',this.projectName);
    this.router.navigate(['/product/' + projectName + '/active/' + this.accountId], { queryParams: { projectStatus: this.projectStatus } });
  }

  navigateAllAvailableProduct() {
    const projectName = encodeURIComponent(this.projectName);
    sessionStorage.setItem('projectName',this.projectName);
    this.router.navigate(['/product/' + projectName + '/available/' + this.accountId], { queryParams: { projectStatus: this.projectStatus } });
  }

  navigateToBudgetConsumed() {
    this.router.navigate(['/product/' + this.projectName + '/budgetConsumed/' + this.accountId]);
  }

  navigateToBudgetAvailable() {
    this.router.navigate(['/product/' + this.projectName + '/budgetAvailable/' + this.accountId]);
  }

  productDescription(data) {
    const strLen = data.length;
    let str = data;
    if (strLen > 100) {
      str = str.slice(0, 100) + '...';
    }
    return str;
  }

  productName(data) {
    const strLen = data.length;
    let str = data;
    if (strLen > 15) {
      str = str.slice(0, 15) + '...';
    }
    return str;
  }

  getKnowMoreURL(obj) {
    try {
      const tags = obj[1];
      const tVal = tags.Value || '';
      return tVal;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  openMenu() {
    this.dropOption = 'show-menu';
  }

  getActiveMenu(data, service) {
    const menuI = ['Upload', 'Explore', 'Share'];
    try {
      const serviceName = this.productUtil.getServiceFromTags(data);
      const getMenu = this.productUtil.getMenuForProduct(serviceName);
      if (service === "menu") {
        return getMenu.menu;
      }
      if(getMenu && 'name' in getMenu){
        return getMenu.name;
      }
    } catch (e) {
      console.log(e);
      return menuI;
    }
  }

  getMeunForService(data) {
    let body = {
      id: data.ProvisionedProductId
    };
    this.servicemenu = [];
    this.serviceAction.getActionsMenuForService(body).subscribe((res:any) => {
      if (res.status === "success") {
        this.servicemenu = [].concat(res.actions.connect, res.actions.action);
      } else {
        this.toastr.info(res.message, `Product Actions`, this.globals.tosterOverride)
      }
    }, (error) => {
      let err = error.error
      this.toastr.error(err.message, 'Error', this.globals.tosterOverride);
    })
  }

  getMenuCard(data, availPObj) {
    switch (data) {
      case 'SSH/RDP':
        this.launchInstanceSSH(availPObj);
        break;
      case '':
        break;
      default:
        break;
    }
  }

  launchInstanceSSH(availPObj) {
    this.apiService.getDialogBox().subscribe((data:any) => {
      if(data.status === 200){
    this.dialog.showCustomDialog({
      component: InstanceComponent,
      providers: [{ provide: 'data', useValue: availPObj }],
      isModal: true,
      styles: {
        "height": "auto",
        "width": "40%",
        "padding": "0"
      },
      clickOutsideToClose: true,
      enterTransitionDuration: 400,
      leaveTransitionDuration: 400,
    })}}, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    })
  }

  actions(provProduct, action) {
    let body;
    let service = this.getActiveMenu(provProduct.Tags, "");
    if (service === 'ec2') {
      let instanceId;
      for (var i = 0; i < provProduct.RecordOutputs.length; i++) {
        if (provProduct.RecordOutputs[i]['OutputKey'] === "InstanceId") {
          instanceId = provProduct.RecordOutputs[i]['OutputValue']
        }
      }
      body = {
        "account_id": provProduct.account_id,
        "service": service,
        "action": action.toLowerCase(),
        "instanceId": instanceId
      }
    } else if (service === 'sagemaker') {
      body = {
        "service": service.toLowerCase(),
        "action": action.toLowerCase(),
        ProvisioningParameters: provProduct.ProvisioningParameters,
        "account_id": provProduct.account_id
      };
    } else if (service === 's3' && action === 'Upload') {
      body = {
        "service": service.toLowerCase(),
        "action": action.toLowerCase(),
        ProvisioningParameters: provProduct.ProvisioningParameters,
        "account_id": provProduct.account_id
      };
      this.apiService.getDialogBox().subscribe((data:any) => {
        if(data.status === 200){
      this.dialog.showCustomDialog({
        component: ConfirmationDialog,
        providers: [{ provide: 'data', useValue: { s3Obj: body } }],
        isModal: true,
        styles: {
          "height": "auto",
          "width": "40%"
        },
        clickOutsideToClose: true,
        enterTransitionDuration: 400,
        leaveTransitionDuration: 400,
      })}}, error => {
        if(error && error.status === 401){
          let errBody = error.error;
           this.toastr.error(errBody.err, '', this.globals.tosterOverride);
           this.router.navigate(['login']);
         }
      })
    } else if (service === 's3' && action === 'Share') {
      body = {
        "service": service.toLowerCase(),
        "action": action.toLowerCase(),
        "account_id": provProduct.account_id
      };
      provProduct.action = action.toLowerCase();
      provProduct.service = service.toLowerCase();
      provProduct.user = this.userName;
      provProduct.projectName = this.projectName;
      this.s3Actions(body, provProduct);
    }
    switch (service) {
      case 'sagemaker':
        this.sagemakerActions(body, provProduct);
        break;
      case 'ec2':
        if (body.action === 'ssh/rdp') {
          this.launchInstanceSSH(provProduct);
        }
        if (body.action !== 'ssh/rdp') {
          this.ec2Actions(body, provProduct);
        }
        break;
      default:
        break;
    }

  }

  sagemakerActions(body, provProduct) {
    this.serviceAction.serviceAction(body).subscribe((res:any) => {
      switch (body.action.toLowerCase()) {
        case "link":
          window.open(res.AuthorizedUrl);
          break;
        case "start":
          this.toastr.info(`${provProduct.ProvisionedProductName} start process initiated successfully`, 'Active Products', this.globals.tosterOverride);
          break;
        case 'stop':
          this.toastr.info(`${provProduct.ProvisionedProductName} stop process initiated successfully`, 'Active Products', this.globals.tosterOverride);
          break;
        default:
          break
      }
    }, error => {
      console.error(error);
      let errBody = error.error;
      if(error && error.status === 401){
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
       }else{
        this.toastr.error(errBody.error, 'Error', this.globals.tosterOverride);
       }
    });
  }

  ec2Actions(body, provProduct) {
    this.serviceAction.serviceAction(body).subscribe(res => {
      switch (body.action.toLowerCase()) {
        case "start":
          this.toastr.info(`${provProduct.ProvisionedProductName} start process initiated successfully`, 'Active Products', this.globals.tosterOverride);
          break;
        case 'stop':
          this.toastr.info(`${provProduct.ProvisionedProductName} stop process initiated successfully`, 'Active Products', this.globals.tosterOverride);
          break;
        case 'reboot':
          this.toastr.info(`${provProduct.ProvisionedProductName} reboot process initiated successfully`, 'Active Products', this.globals.tosterOverride);
          break;
        default:
          break
      }
    }, error => {
      console.error(error);
      let errBody = error.error;
      if(error && error.status === 401){
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
       }else{
        this.toastr.error(errBody.error, 'Error', this.globals.tosterOverride);
       }
    });
  }

  s3Actions(body, provProduct) {
    this.apiService.getDialogBox().subscribe((data:any) => {
      if(data.status === 200){
    this.dialog.showCustomDialog({
      component: ConfirmationDialog,
      providers: [{ provide: 'data', useValue: { s3ShareObj: provProduct, researcherList: this.researcherList } }],
      isModal: true,
      styles: {
        "height": "auto",
        "width": "40%"
      },
      clickOutsideToClose: true,
      enterTransitionDuration: 400,
      leaveTransitionDuration: 400,
    })}}, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    })
  }

  updateProductOutput(data) {
    this.serviceAction.productOutputSync(data).subscribe(res => {
      this.toastr.info(`${data.ProvisionedProductName} sync process initiated successfully`, 'Active Products', this.globals.tosterOverride);
    }, error => {
      console.error(error);
      let err = error.error;
      this.toastr.info(err.message, 'Error', this.globals.tosterOverride);
    });
    this.getAllActiveProduct();
  }

  getLogs(id, next) {
    this.provisionIdForLog = id;
    if (next) {
      this.page = this.page + 1;
    } else {
      this.auditTrail = [];
      this.page = 1
    }
    this.researchService.getProvisionedProductsAuditTrail(this.page, this.limit, id).subscribe((data:any) => {
      this.logLoader = false;
      this.auditTrail = [].concat(this.auditTrail, data.docs);
      if (this.page < data.pages) {
        this.loadMore = true
      } else {
        this.loadMore = false
      }
    }, error => {
      this.logLoader = false;
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }else {
          this.toastr.error('Could not fetch bot logs , Please check the history !', 'Error', this.globals.tosterOverride);
       }
    });
  }

  cleanLogs() {
    this.auditTrail = [];
    this.page = 1;
  }

  getResearcherList(projectId, accountId) {
    this.researchService.getResearchersFromProject(projectId, accountId).subscribe((res:any) => {
      if(res.length > 0){
        this.researcherList = res[0].researchers;
        this.projectStatus = res[0].projectStatus;
        this.researcherList = this.researcherList.filter((value) => value != this.userName);
      }
    }, error => {
      let err = error.error;
    });
  }

  nameTrim(data) {
    if (!data) {
      return data;
    }
    const getLen = data.length;
    if (getLen > 23) {
      data = data.slice(0, 23) + '...';
    }
    return data;
  }

  nameTrimData(data) {
    if (!data) {
      return data;
    }
    const getLen = data.length;
    if (getLen > 30) {
      data = data.slice(0, 30) + '...';
    }
    return data;
  }
}



