import { Component, OnInit } from '@angular/core';
import {MdlDialogService} from "@angular-mdl/core";
import {Router} from "@angular/router";
import {ResearchService} from "../_services/research.service";
import {CreateKeypairComponent} from "./create-keypair/create-keypair.component";
import {S3FileDeleteComponent} from "../dialog/s3-file-delete/s3-file-delete.component";
import {Globals} from "../globals";
import {ToastrService} from "ngx-toastr";
import { DashboardService } from "../_services/dashboard.service";

@Component({
  selector: 'app-keypair',
  templateUrl: './keypair.component.html',
  styleUrls: ['./keypair.component.css'],
  providers: [Globals]
})
export class KeypairComponent implements OnInit {

  constructor(private dialog: MdlDialogService, private router: Router,
              private researchService: ResearchService, private toastr: ToastrService, private globals: Globals,
              private apiService: DashboardService
  ) { }
  searchKey = '';
  keypairList = [];
  currentPage = 1;
  limit = 10;
  showSpinner = false;
  checkBoxValue = [];
  searchExecKey = '';
  showLoadMore = false;
  noData = false;
  searchValid = false;
  noDataTemplate = 'No key pairs are configured';
  roles = null;
  ngOnInit() {
    this.apiService.checkIfResearcher();
    this.roles = sessionStorage.getItem('roles');
    // if (!this.roles.includes(0)) {
    //   this.router.navigate(['login']);
    // }
    this.getKeypair();
  }

  private getKeypair() {
    this.showSpinner = true;
    this.noData = false;
    this.researchService.getKeypair(this.currentPage, this.limit, this.searchKey).subscribe((res:any) => {
      if (this.searchKey) {
        this.noDataTemplate = 'No matching key pairs found';
      }
      this.showSpinner = false;
      this.showLoadMore = false;
      const validSearch = this.searchKey.trim();
      if (validSearch && this.currentPage === 1) {
        this.keypairList = [];
      }
      this.keypairList = [].concat(this.keypairList, res.data.docs);
      if (this.keypairList.length < res.data.total) {
        this.showLoadMore = true;
      }
      if (this.keypairList.length === 0) {
        this.noData = true;
      }
    }, error => {
      this.showSpinner = false;
      if(error && error.status === 403){
        const err = error.error;
        this.toastr.error(err.error, 'Error', this.globals.tosterOverride);
        this.router.navigate(['pageNotFound']);
      }else if (error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }else{
        const err = error.error.err;
        this.toastr.error(err, 'Error', this.globals.tosterOverride);
       }
    });
  }

  deleteKeypair() {
    const payload = { keypairList : this.checkBoxValue };
    this.showSpinner = true;
  }

  deleteKeypairOne(data) {
    this.checkBoxValue.push(data);
    const payload = { keypairList : this.checkBoxValue };
    this.apiService.getDialogBox().subscribe((data:any) => {
      if(data.status === 200){
    this.dialog.showCustomDialog({
      component: S3FileDeleteComponent,
      providers: [{ provide: 'data', useValue: payload }],
      isModal: true,
      styles: {
        "height":"auto",
        "width":"40%",
        "border": "1px solid #FFFFFF33",
        "border-radius": "10px",
        "background-color": "#07102A",
        "padding": "0px"
      },
      clickOutsideToClose: true,
      enterTransitionDuration: 400,
      leaveTransitionDuration: 400,
    }) }}, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    })
  }

  getProjectName(data) {
    let projectName = '-';
    if (data) {
      projectName = data['name'] || '-';
    }
    return projectName;
  }

  searchKeypair() {
    const searchKey = this.searchKey.trim() || '';
    if (searchKey.length >= 3 && this.searchExecKey !== searchKey)  {
      this.searchExecKey = searchKey;
      this.searchValid = true;
      this.refresh();
    } else if (searchKey.length === 0 && this.searchExecKey !== searchKey) {
      this.searchExecKey = searchKey;
      this.searchValid = true;
      this.refresh();
    } else {
      this.searchValid = false;
    }
  }

  refresh() {
    this.currentPage = 1;
    this.keypairList = [];
    this.getKeypair();
  }

  addKeypair(): void {
    this.apiService.getDialogBox().subscribe((data:any) => {
      if(data.status === 200){
    this.dialog.showCustomDialog({
      component: CreateKeypairComponent,
      providers: [{ provide: 'data', useValue: { services: '', name: '' } }],
      isModal: true,
      styles: {
        "height":"auto",
        "width":"40%",
        "background": "#07102A",
        "border": "1px solid #FFFFFF33",
        "border-radius": "10px",
        "padding": "0px"
      },
      clickOutsideToClose: true,
      enterTransitionDuration: 400,
      leaveTransitionDuration: 400,
    })}}, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    })
  }

  loadMoreEvents() {
    this.currentPage = this.currentPage + 1;
    this.getKeypair();
  }

  onCheckboxChanged(event, data) {
    if (event.target.checked) {
      this.checkBoxValue.push(data);
    }
    if (!event.target.checked) {
      const index = this.checkBoxValue.indexOf(data);
      if (index > -1) {
        this.checkBoxValue.splice(index, 1);
      }
    }
  }

  allCheckBoxSelected(event) {
    this.checkBoxValue = [];
    const checked = event.target.checked;
    this.keypairList.forEach(item => item.isChecked = checked);
    for (let i = 0; i < this.keypairList.length; i++) {
      this.checkBoxValue.push(this.keypairList[i]);
    }
    if (!event.target.checked) {
      this.checkBoxValue = [];
    }
  }

  projectName(data) {
    const strLen = data?.length;
    let str = data;
    if (strLen > 15) {
      str = str.slice(0, 15) + '...';
    }
    return str;
  }
}
