<div class="container" style="position: absolute; margin-top: 75px">

    <img class="success-icon" src="../../assets/images/link-error.png" alt="Error In Link Study"/>

    <h3 class="successMsg">
        Oops ! Something went wrong
    </h3>

    <h4 class="linkMsg">
        Your Study couldn’t be linked to the Compute Resource. Please try again.
    </h4>

    <div class="modal-footer" style="margin-top: 35px">
        <div class="marginForButtons l-footer">
          <button   type="button"  class="btn cat-btn-update sshSubmit ssh-btn l-submit" (click)="submit()">
              Try again
          </button>
        </div>
      </div>

</div>