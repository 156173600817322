import { CanDeactivate, CanActivate } from '@angular/router';
import { S3FileUploadComponent } from './s3-file-upload.component';
import { MdlDialogService } from '@angular-mdl/core';
import { MdlDialogReference } from '@angular-mdl/core';
import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class DeactivateGuard implements CanDeactivate<S3FileUploadComponent>{

constructor(
    private dialog: MdlDialogService
  ) { }
  canDeactivate(component: S3FileUploadComponent) {
    if (component.files.length > 0 && !component.fileUploadComplete) {
      return window.confirm('Are you sure you want to leave this page? Any uploads in progress or pending will be cancelled!');
    } else {
      return true;
    }
  }

}