import { Component, OnInit, Inject} from '@angular/core';
import { MdlDialogReference } from '@angular-mdl/core';
import { MdlDialogService } from '@angular-mdl/core';
import { ResearchService } from "../../_services/research.service";
import { DataService } from '../../_services/data.service';
import { ToastrService } from 'ngx-toastr';
import {Globals} from "../../globals";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-unlink-resource',
  templateUrl: './unlink-resource.component.html',
  styleUrls: ['./unlink-resource.component.css'],
  providers: [Globals]
})
export class UnlinkResourceComponent implements OnInit {
  
  ppId: any;
  showSpinner: boolean = false;
  projectName;
  productName;
  projectStatus;
  notebookObject: any;
  studyId: string;
  productTitle: string;

  constructor(public dialogRef: MdlDialogReference,
    private dialog: MdlDialogService,
    @Inject('data') public data: any,
    private researchService: ResearchService,
    private toastr: ToastrService,
    private globals: Globals,
    private router: Router,
    private dataService: DataService) { }

  ngOnInit() {

    if(this.data.hasOwnProperty('ppId')){
      this.ppId = this.data.ppId;
    }
    if(this.data.hasOwnProperty('projectName')){
      this.projectName = this.data.projectName;
    }
    if(this.data.hasOwnProperty('productName')){
      this.productName = this.data.productName;
    }
    if(this.data.hasOwnProperty('projectStatus')){
      this.projectStatus = this.data.projectStatus;
    }
    if(this.data.hasOwnProperty('notebookObject')){
      this.notebookObject = this.data.notebookObject;
    }
    if(this.data.hasOwnProperty('studyId')){
      this.studyId = this.data.studyId;
      this.productTitle = this.data.productTitle
    }

  }

  unlinkResource(){
    this.showSpinner = true;
      let unlinkobj = {
          "ppId": this.ppId,
          "notebookObject": this.notebookObject
          }
  
      this.researchService.unlinkStudyWithSagemaker(unlinkobj).subscribe(res => {
        if(res){
          this.showSpinner = false;
          this.dialogRef.hide();
          this.toastr.success(`Unlinked the product successfully`, '', this.globals.tosterOverride);
          this.router.navigate(['/product/' + this.projectName + '/' +
          this.productName + '/' + 'unlinkResource'+ '/' +'Success' , { ppId: this.ppId }],{ queryParams: { projectStatus: this.projectStatus}});
        }
      }, error => {
        this.showSpinner = false;
        this.dialogRef.hide();
        console.error(error);
        let errBody= error.error;
        if(error && error.status === 401){
          this.toastr.error(errBody.err, '', this.globals.tosterOverride);
          this.router.navigate(['login']);
        }else{
        this.toastr.error(`Unlink resource - `+errBody.error, 'Error', this.globals.tosterOverride)
        }
      });
  }

  cancel(): void {
    this.dialogRef.hide();
  }

  unlinkProduct() {
    this.showSpinner = true;
    const data = {
      studyId: this.studyId,
      ppId: this.ppId
    }
    this.researchService.unlinkStudyResource(data).subscribe(res => {
      this.showSpinner = false;
      this.dialogRef.hide();
      this.toastr.success(`Unlinked the product successfully`, '', this.globals.tosterOverride);
      this.dataService.updatePage.next(true);
    }, error => {
      this.showSpinner = false;
      this.dialogRef.hide();
      console.error(error);
      let errBody= error.error;
      if(error && error.status === 401){
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }else{
      this.toastr.error(`Unlink resource - `+errBody.message, 'Error', this.globals.tosterOverride)
       }
    })
  }

}
