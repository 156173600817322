<div class="container">
    <div style="margin-top: 15px;">
        <span *ngIf="userType=='admin'" routerLink="/admin" class="backPage" title="Back to Organizations"> <i class="fa fa-angle-left" style="font-size: 16px; color: #ffff;"></i> &nbsp;&nbsp; Back to Organizations </span>
        <a *ngIf="userType=='principal'" tabindex="0" routerLink="/principal" class="backPage" title="Back to Projects"> <i class="fa fa-angle-left" style="font-size: 16px; color: #ffff;"></i> &nbsp;&nbsp; Back to Projects </a>
        <span *ngIf="userType=='researcher'" routerLink="/researcher" class="backPage" title="Back to Projects"> <i class="fa fa-angle-left" style="font-size: 16px; color: #ffff;"></i> &nbsp;&nbsp; Back to Projects </span>
    </div>
  
    <mat-tab-group mat-align-tabs="start" [selectedIndex]="selectedIndex" (selectedIndexChange)="onTabIndexChanged($event)" animationDuration="0ms" [disableRipple]="true" mat-align-tabs="start">
        <mat-tab style="color: #ffffff; font-family: sans-serif; font-weight: 800;" label="Project Accounts">
            <div class="row">
                <div class="col-lg-12 col-md-12" style="margin-top: 20px;">
                    <div class="box box-border" style="height: auto;">
                        <div>
                            <div class="box-tools pull-right" *ngIf="userType=='admin' || userType=='principal'"
                            style="margin-bottom: 10px; position: relative;">
                            <div class="dropdown">
                                <button type="button"
                                    class="btn btn-lg btn-primary btn-add text-dark font-class mr-2 mb-1"
                                    title="Add Settings" data-toggle="dropdown">Add New
                                    <i class="fa fa-angle-down" title="Actions" aria-hidden="true"
                                        style="color: #000;width: 12px;font-size: 15px; margin: 3px;"></i>
                                </button>
                                <ul class="dropdown-menu dropdown-content" style="cursor: pointer;background: rgb(7, 16, 42);position: absolute;
                                will-change: transform;top: 0px;left: 0px;
                                transform: translate3d(-58px, 17px, 0px); position: relative;">
                                    <li><a class="listItems" (click)="addSettings('AWS')"> AWS
                                            </a>
                                    </li>
                                    <li><a class="listItems" (click)="addSettings('AZURE')"> Azure
                                            </a></li>
                                </ul>
                                <button type="button" class="btn btn-lg btn-primary btn-pi text-white font-class mb-1"
                                title="Refresh" (click)="getSettingsData()">Refresh</button>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="settings?.length === 0" class="empty-my-product-layout">
                        <div>
                            <h3 class="no-data-found text-white font-class text-center">No project accounts have been set up.</h3>
                            <h3 class="no-data-found-subtext text-white font-class text-center">{{settingHelpMessage}}</h3>
                        </div>
                    </div>
                    <app-loader class="spinner" *ngIf="showSpinner"></app-loader>
                    <div class="table-responsive md-layout lg-layout" *ngIf="settings?.length>0 " style="margin-top: 50px;">
                        <table class="table table-striped" style="width: 100%;">
                            <thead>
                                <tr>
                                    <th class="tableHeader">Account Name</th>
                                    <th class="tableHeader">Provider</th>
                                    <th class="tableHeader">Region</th>
                                    <th class="tableHeader">Account/Subscription</th>
                                    <th *ngIf="userType == 'admin'" class="tableHeader">Organization</th>
                                    <th *ngIf="userType == 'principal' || userType == 'researcher'" class="tableHeader">
                                        Project</th>
                                    <th class="tableHeader">Created On</th>
                                    <th *ngIf="orgEnableTREFeature == true" class="tableHeader-logo">SRE</th>
                                    <th class="tableHeader">
                                        <span style="display: none;">-</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody *ngIf="settings?.length>0 ">
                                <tr *ngFor="let setting of settings;" style="background-color: inherit;">
                                    <td class="tableData" [style.color]="setting.status === 'error' ? 'red' : '#FFFFFF'" title="{{setting.name}}">
                                        {{trimSettingsName(setting.name)}}
                                        <span *ngIf="setting.status === 'error'" title="{{setting.data.message+settingDelinkMessage}}" placement="bottom">
                                            <img style="height: 15px; width: 15px;" src="../../assets/images/info.png" alt="Error Info" />
                                        </span>
                                        <span *ngIf="setting.projectId.length > 0 && userType == 'admin'" title="Click here for more details" (click)="getProjectDetails(setting)">
                                            <img style="height: 15px; width: 15px; margin-left: 5px; cursor: pointer;" src="../../assets/images/link@2x.png" alt="Click Here For Project Details" />
                                        </span>
                                    </td>
                                    <td class="tableData" [style.color]="setting.status === 'error' ? 'red' : '#FFFFFF'"
                                        title="{{setting.vendor}}">
                                        {{setting.vendor| uppercase}}
                                    </td>
                                    <td class="tableData" [style.color]="setting.status === 'error' ? 'red' : '#FFFFFF'" title="{{setting.region}}">{{setting.region}}</td>
                                    <td class="tableData" [style.color]="setting.status === 'error' ? 'red' : '#FFFFFF'" title="{{setting.accountNumber}}">{{setting.accountNumber}}</td>
                                    <td class="tableData" [style.color]="setting.status === 'error' ? 'red' : '#FFFFFF'" *ngIf="userType == 'admin'" title="{{getOrgList(setting.orgName)}}">
                                        {{getOrgList(setting.orgName)}}
                                    </td>
                                    <td
                                        class="tableData"
                                        [style.color]="setting.status === 'error' ? 'red' : '#FFFFFF'"
                                        *ngIf="(userType == 'principal' || userType == 'researcher') && setting.project.length > 0"
                                        title="{{setting.project}}">
                                        {{setting.project | filterProduct}}
                                    </td>
                                    <td class="tableData" [style.color]="setting.status === 'error' ? 'red' : '#FFFFFF'" *ngIf="(userType == 'principal' || userType == 'researcher') && setting.project.length == 0">-</td>
                                    <td class="tableData" [style.color]="setting.status === 'error' ? 'red' : '#FFFFFF'" title="{{convertDateToString(setting.created_on)}}">{{convertDateToString(setting.created_on)}}</td>
                                    <td style="color: #FFFFFF; border-bottom: 1px solid #465374; border-top: none !important; text-align: center; overflow: visible !important; position: relative;" *ngIf = "orgEnableTREFeature === true">
                                      <img style="height: 15px; width: 15px;" *ngIf="setting.isTreEnabled == true && orgEnableTREFeature == true" src="../../assets/images/check-mark-11-128.png" alt="SRE Enabled Account"/>
                                      <span *ngIf="setting.isTreEnabled == false && orgEnableTREFeature == true">-</span>
                                    </td> 
                                    <!-- <td style="color: #FFFFFF; border-bottom: 1px solid #465374; border-top: none !important; text-align: center; overflow: visible !important; position: relative;" >
                                      
                                     </td> -->
                                    <td style="border-bottom: 1px solid #465374; border-top: none !important; text-align: center; overflow: visible !important; position: relative;">
                                        <div class="dropdown" *ngIf="setting.isCleanUpStarted" title="De-link process in progress"><i class="fa fa-spinner fa-pulse" style="color: white;"></i></div>
                                        <div class="dropdown" *ngIf="(!setting.isCleanUpStarted) && (userType == 'principal' || userType == 'admin')">
                                            <span *ngIf="(setting.researcherIsPartOfProject) || (userType === 'admin') ">
                                                <i class="fa fa-ellipsis-v" data-toggle="dropdown" title="Action" aria-hidden="true" style="color: #f1f1f1; cursor: pointer; padding: 0px 10px;"></i>
                                                <ul class="dropdown-menu dropdown-content">
                                                    <li><a class="listItems" (click)="assignOrganisation(setting)" *ngIf="getOrgList(setting.orgName) === '-' && setting.status !== 'error'">Assign O.U.</a></li>
                                                    <!-- <li><a  class="listItems" (click)="productSync(setting)" *ngIf="setting.status !== 'error'">Sync Now</a></li> -->
                                                    <li><a class="listItems" (click)="editSettings(setting._id)">Repair</a></li>
                                                    <li><a class="listItems" *ngIf="getOrgList(setting.orgName) !== '-' && setting.isTreEnabled == true" (click)="updateNetworkDetails(setting._id)" > Update network details</a></li>
                                                    <li><a class="listItems" *ngIf="getOrgList(setting.orgName) !== '-' && setting.isTreEnabled == true" (click)="updateEgressStoreDetails(setting)"> Update egress store details</a></li>
                                                    <li><a class="listItems" *ngIf="setting.projectId.length > 0 && userType == 'admin'" title="Click here for more details" (click)="getProjectDetails(setting)">Details</a></li>
                                                    <li><a *ngIf="setting.projectId.length == 0" class="listItems" (click)="deleteSettingById(setting.name,setting._id)">Delete</a></li>
                                                </ul>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="product-avail">
                            <span class="page-count-text">{{ currentPageLowerCount }} - {{ currentPageUpperCount }} of {{ totalRecords }}</span>
                            <div class="pagination-wrapper">
                                <img class="pagination-next-btn" [ngClass]="{'disable-first-btn':page === 1 }" src="../../assets/images/pagination-first-active.png" alt="First Page" (click)="getNextSettings('first')" />
                                <img class="pagination-next-btn" [ngClass]="{'disable-prev-btn':page === 1 }" src="../../assets/images/pagination-prev-active.png" alt="Previous Page" (click)="getNextSettings('previous')" />
                                <img class="pagination-next-btn" [ngClass]="{'disable-next-btn':page === totalPages}" src="../../assets/images/pagination-next-active.png" alt="Next Page"  (click)="getNextSettings('next')" />
                                <img class="pagination-next-btn" [ngClass]="{'disable-last-btn':page === totalPages}" src="../../assets/images/pagination-last-active.png" alt="Last Page" (click)="getNextSettings('last')" />
                            </div>
                        </div>
                    </div>
  
                    <div class="sm-layout xs-layout" *ngIf="settings?.length>0 ">
                        <!-- mobile responsive -->
                        <div class="project-cards-layout" style="margin-top: 40px;">
                            <div class="project-card" style="position: relative;" *ngFor="let setting of settings;">
                                <ul class="list-group">
                                    <div class="card-info-border"></div>
                                    <div class="project-card-project-name card-account-name" [style.color]="setting.status === 'error' ? 'red' : '#FFFFFF'">
                                        {{ setting.name }}
                                        <span *ngIf="setting.status === 'error'" title="{{setting.data.message+settingDelinkMessage}}" placement="bottom">
                                            <img style="height: 15px; width: 15px;" src="../../assets/images/info.png" alt="Error Info" />
                                        </span>
                                        <span *ngIf="setting.projectId.length > 0 && userType == 'admin'" title="Click here for more information" (click)="getProjectDetails(setting)">
                                            <img style="height: 15px; width: 15px;" src="../../assets/images/link@2x.png" alt="Click Here For Project Details" />
                                        </span>
                                    </div>
                                    <div class="project-card-project-name card-account-number" [style.color]="setting.status === 'error' ? 'red' : '#FFFFFF'">{{ setting.accountNumber ? setting.accountNumber : '-' }}</div>
                                    <div class="project-card-project-name card-account-region" [style.color]="setting.status === 'error' ? 'red' : '#FFFFFF'">{{ setting.region ? setting.region : '-' }}</div>
                                    <div class="project-card-project-name card-account-project" [style.color]="setting.status === 'error' ? 'red' : '#FFFFFF'" *ngIf="userType === 'admin'">{{ getOrgList(setting.orgName) }}</div>
                                    <div
                                        class="project-card-project-name card-account-project"
                                        [style.color]="setting.status === 'error' ? 'red' : '#FFFFFF'"
                                        *ngIf="(userType === 'principal' || userType === 'researcher') && setting.project.length > 0" >
                                        {{ setting.project | filterProduct}}
                                    </div>
                                    <div
                                        class="project-card-project-name card-account-project"
                                        [style.color]="setting.status === 'error' ? 'red' : '#FFFFFF'"
                                        *ngIf="(userType === 'principal' || userType === 'researcher') && setting.project.length == 0">
                                        -
                                    </div>
                                    <div class="project-card-project-name card-account-date">{{convertDateToString(setting.created_on)}}</div>
                                    <div class="dropdown-icon" *ngIf="setting.isCleanUpStarted" title="De-link process in progress"><i class="fa fa-spinner fa-pulse" style="color: white;"></i></div>
                                    <div *ngIf="!setting.isCleanUpStarted" class="dropdown-icon">
                                        <div class="dropdown" *ngIf="userType == 'admin' || userType == 'principal'">
                                            <span *ngIf="(setting.researcherIsPartOfProject) || (userType === 'admin') ">
                                                <i class="fa fa-ellipsis-v" data-toggle="dropdown" title="Action" aria-hidden="true" style="color: #f1f1f1; cursor: pointer; padding: 0px 10px;"></i>
                                                <ul class="dropdown-menu dropdown-content">
                                                    <li><a class="listItems" (click)="assignOrganisation(setting)" *ngIf="getOrgList(setting.orgName) === '-'">Assign O.U.</a></li>
                                                    <!-- <li><a  class="listItems" (click)="productSync(setting)" >Sync Now</a></li> -->
                                                    <li><a class="listItems" (click)="editSettings(setting._id)">Repair</a></li>
                                                    <li><a class="listItems" *ngIf="setting.projectId.length > 0 && userType == 'admin'" title="Click here for more details" (click)="getProjectDetails(setting)">Details</a></li>
                                                    <li><a *ngIf="setting.projectId.length == 0" class="listItems" (click)="deleteSettingById(setting.name,setting._id)">Delete</a></li>
                                                </ul>
                                            </span>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                        </div>
                        <div class="product-avail">
                            <span class="page-count-text">{{ currentPageLowerCount }} - {{ currentPageUpperCount }} of {{ totalRecords }}</span>
                            <div class="pagination-wrapper">
                                <img class="pagination-next-btn" [ngClass]="{'disable-first-btn':page === 1 }" src="../../assets/images/pagination-first-active.png" alt="First Page"  (click)="getNextSettings('first')" />
                                <img class="pagination-next-btn" [ngClass]="{'disable-prev-btn':page === 1 }" src="../../assets/images/pagination-prev-active.png" alt="Previous Page"  (click)="getNextSettings('previous')" />
                                <img class="pagination-next-btn" [ngClass]="{'disable-next-btn':page === totalPages}" src="../../assets/images/pagination-next-active.png" alt="Next Page" (click)="getNextSettings('next')" />
                                <img class="pagination-next-btn" [ngClass]="{'disable-last-btn':page === totalPages}" src="../../assets/images/pagination-last-active.png" alt="Last Page" (click)="getNextSettings('last')" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab *ngIf="userType == 'admin' && showSubscriptionTab" label="Subscriptions">
            <div class="row">
                <div class="col-lg-12 col-md-12" style="margin-top: 20px;">
                    <div class="box box-border" style="height: auto;"></div>
                    <div *ngIf="getEntitlementData?.length === 0" style="text-align: center; color: #ffff; margin: 40px;">
                        <h4 align="center" colspan="7">No Subscription Available</h4>
                    </div>
                    <app-loader class="spinner" *ngIf="showSpinner"></app-loader>
                    <div class="table-responsive md-layout lg-layout" *ngIf="getEntitlementData?.length>0 " style="margin-top: 50px;">
                        <table class="table table-striped" style="width: 100%;">
                            <thead>
                                <tr>
                                    <th class="tableHeader" style="width: 15%;">OrgID</th>
                                    <th class="tableHeader" style="width: 10%;">Tier</th>
                                    <th class="tableHeader">Expiration Date</th>
                                    <th class="tableHeader" style="width: 35%;">Support</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="getEntitlementData?.length>0 ">
                                <tr *ngFor="let data of getEntitlementData;" style="background-color: inherit;">
                                    <td class="tableData">{{orgId.orgId}}</td>
                                    <td class="tableData" title="{{data.Dimension}}">{{data.Dimension}}</td>
                                    <td class="tableData" title="{{data.ExpirationDate | date:'medium'}}">{{data.ExpirationDate | date:'medium'}}</td>
                                    <td class="tableData" style="white-space: unset !important; line-height: 2.2rem;">You can contact our support team at rlc.support@relevancelab.com or at https://serviceone.relevancelab.com</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
  
                    <div class="sm-layout xs-layout" *ngIf="getEntitlementData?.length>0 && userType == 'admin' && showSubscriptionTab">
                        <!-- mobile responsive -->
                        <div class="project-cards-layout">
                            <div class="project-card" *ngFor="let data of getEntitlementData;">
                                <ul class="list-group">
                                    <div class="d-flex justify-content-between mb-2">
                                        <div class="project-card-title project-card-line-height font-class">OrgID</div>
                                        <div class="project-card-project-name">{{orgId.orgId}}</div>
                                    </div>
                                    <div class="d-flex justify-content-between mb-2">
                                        <div class="project-card-title project-card-line-height font-class">Tier</div>
                                        <div class="project-card-project-name">{{data.Dimension}}</div>
                                    </div>
                                    <div class="d-flex justify-content-between mb-2">
                                        <div class="project-card-title project-card-line-height font-class">Expiration</div>
                                        <div class="project-card-project-name">{{data.ExpirationDate | date:'medium'}}</div>
                                    </div>
                                    <div class="d-flex justify-content-between mb-2">
                                        <div class="project-card-title project-card-line-height font-class">Support</div>
                                        <div class="project-card-project-name">You can contact our support team at rlc.support@relevancelab.com or at https://serviceone.relevancelab.com</div>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab *ngIf="userType=='principal'"style="color: #ffffff; font-family: sans-serif; font-weight: 800;" label="Study Accounts">
          <div class="row">
              <div class="col-lg-12 col-md-12" style="margin-top: 20px;">
                  <div class="box box-border" style="height: auto;">
                      <div>
                          <!-- <div class="box-tools pull-right" style="margin-bottom: 10px;">
                              <button (click)="addSettings('AWS')" type="button" class="btn btn-lg btn-primary btn-pi text-white font-class mr-2 mb-1" title="Add Settings"><i class="fa fa-plus white" style="margin: 3px;"></i> Add New</button>
                              <button type="button" class="btn btn-lg btn-primary btn-pi text-white font-class mb-1" title="Refresh" (click)="getSettingsData()"><i class="fa fa-refresh white" style="margin: 3px;"></i> Refresh</button>
                          </div> -->
                           <div class="box-tools pull-right" *ngIf="userType=='admin' || userType=='principal'"
                            style="margin-bottom: 10px; position: relative;">
                            <div class="dropdown">
                                <button type="button"
                                    class="btn btn-lg btn-primary btn-add text-dark font-class mr-2 mb-1"
                                    title="Add Settings" data-toggle="dropdown">Add New
                                    <i class="fa fa-angle-down" title="Actions" aria-hidden="true"
                                        style="color: #000;width: 12px;font-size: 15px; margin: 3px;"></i>
                                </button>
                                <ul class="dropdown-menu dropdown-content" style="cursor: pointer;background: rgb(7, 16, 42);position: absolute;
                                will-change: transform;top: 0px;left: 0px;
                                transform: translate3d(-58px, 17px, 0px); position: relative;">
                                    <li><a class="listItems" (click)="addSettings('AWS')"> AWS
                                            </a>
                                    </li>
                                    <li><a class="listItems" (click)="addSettings('AZURE')"> Azure
                                            </a></li>
                                </ul>
                                <button type="button" class="btn btn-lg btn-primary btn-pi text-white font-class mb-1"
                                title="Refresh" (click)="getSettingsData()">Refresh</button>
                            </div>
                            </div>
                      </div>
                  </div>
                  <div *ngIf="settings?.length === 0" class="empty-my-product-layout">
                      <div>
                          <h3 class="no-data-found text-white font-class text-center">No study accounts have been set up.</h3>
                          <h3 class="no-data-found-subtext text-white font-class text-center">Click the Add New button to add a new study account</h3>
                      </div>
                  </div>
                  <app-loader class="spinner" *ngIf="showSpinner"></app-loader>
                  <div class="table-responsive md-layout lg-layout" *ngIf="settings?.length>0 "  style="margin-top: 50px;">
                      <table class="table table-striped" style="width: 100%;">
                          <thead>
                              <tr>
                                  <th class="tableHeader">Account Name</th>
                                  <th class="tableHeader">Region</th>
                                  <th class="tableHeader">Account Number</th>
                                  <th class="tableHeader">Studies</th>
                                  <th class="tableHeader">Created On</th>
                                  <th class="tableHeader">
                                    <span style="display: none;">-</span>
                                  </th>
                              </tr>
                          </thead>
  
                          <tbody *ngIf="settings?.length>0 ">
                              <tr *ngFor="let setting of settings;" style="background-color: inherit;">
                                  <td class="tableData" [style.color]="setting.status === 'error' ? 'red' : '#FFFFFF'" title="{{setting.name}}">
                                      {{trimSettingsName(setting.name)}}
                                      <span *ngIf="setting.status === 'error'" title="{{setting.data.message}}" placement="bottom">
                                          <img style="height: 15px; width: 15px;" src="../../assets/images/info.png" alt="Error Info" />
                                      </span>
                                  </td>
                                  <td class="tableData" [style.color]="setting.status === 'error' ? 'red' : '#FFFFFF'" title="{{setting.region}}">{{setting.region}}</td>
                                  <td class="tableData" [style.color]="setting.status === 'error' ? 'red' : '#FFFFFF'" title="{{setting.accountNumber}}">{{setting.accountNumber}}</td>
                                  <td
                                        class="tableData"
                                        [style.color]="setting.status === 'error' ? 'red' : '#FFFFFF'"
                                        *ngIf="setting.externalStudies.length > 0"
                                        title="{{setting.externalStudies}}">
                                        {{setting.externalStudies | filterProduct}}
                                    </td>
                                 <td class="tableData" [style.color]="setting.status === 'error' ? 'red' : '#FFFFFF'" *ngIf="setting.externalStudies.length == 0">-</td>
                                  <td class="tableData" [style.color]="setting.status === 'error' ? 'red' : '#FFFFFF'" title="{{convertDateToString(setting.created_on)}}">{{convertDateToString(setting.created_on)}}</td>
                                 
                                  <!-- <td style="color: #FFFFFF; border-bottom: 1px solid #465374; border-top: none !important; text-align: center; overflow: visible !important; position: relative;" >
                                    
                                   </td> -->
  
                                   <td style="border-bottom: 1px solid #465374; border-top: none !important; text-align: center; overflow: visible !important; position: relative;">
                                      <div class="dropdown" *ngIf="setting.status === 'creating' || setting.status ===  'terminating' || setting.status ==  'repairing'" ><i class="fa fa-spinner fa-pulse" style="color: white;"></i></div>
                                      <div class="dropdown" *ngIf="setting.status !== 'creating' && (setting.status == 'success' && setting.externalStudies.length == 0 && setting.username == currentUser) || (setting.status == 'error')">
                                          <span >
                                              <i class="fa fa-ellipsis-v" data-toggle="dropdown" title="Action" aria-hidden="true" style="color: #f1f1f1; cursor: pointer; padding: 0px 10px;"></i>
                                              <ul class="dropdown-menu dropdown-content">
                                                  <li><a *ngIf="setting.username == currentUser && setting.externalStudies.length == 0" class="listItems"  (click)="deleteStudyAccount(setting.name,setting._id) ">Delete</a></li>
                                                  <li><a *ngIf="setting.status == 'error'" class="listItems" (click)="editSettings(setting._id)">Repair</a></li>
                                              </ul>
                                          </span>
                                      </div>
                                  </td>
                                 
                              </tr>
                          </tbody>
                                        
                      </table>
                      <div class="product-avail">
                          <span class="page-count-text">{{ currentPageLowerCount }} - {{ currentPageUpperCount }} of {{ totalRecords }}</span>
                          <div class="pagination-wrapper">
                              <img class="pagination-next-btn" [ngClass]="{'disable-first-btn':page === 1 }" src="../../assets/images/pagination-first-active.png" alt="First Page" (click)="getNextSettings('first')" />
                              <img class="pagination-next-btn" [ngClass]="{'disable-prev-btn':page === 1 }" src="../../assets/images/pagination-prev-active.png" alt="Previous Page" (click)="getNextSettings('previous')" />
                              <img class="pagination-next-btn" [ngClass]="{'disable-next-btn':page === totalPages}" src="../../assets/images/pagination-next-active.png" alt="Next Page" (click)="getNextSettings('next')" />
                              <img class="pagination-next-btn" [ngClass]="{'disable-last-btn':page === totalPages}" src="../../assets/images/pagination-last-active.png" alt="Last Page" (click)="getNextSettings('last')" />
                          </div>
                      </div>
                  </div>                
              </div>
          </div>
      </mat-tab>
    </mat-tab-group>
  </div>