<div class="container pi-budget-tracking">
  <!-- breadcrumb -->
  <nav aria-label="breadcrumb">
      <ol class="breadcrumb bc-custom">
        <li class="breadcrumb-item"><a class="bc-item-active"> Budgets </a></li>
      </ol>
  </nav>
    <!-- Page Heading -->
    <div class="header-title">
      <h3 class="text-white">Budgets</h3>
    </div>
    <div class="dividers"></div>
  
    <!-- Budget Cards -->
    <!-- Note: styles used from catalog.component.css -->
    <div class="budget-container">
      <div class="header">
        <div class="container-fluid">
          <div class="header-body">
            <div class="row kpi-container px-5 py-4">
              <div class="col-12 col-sm-4 col-md-4 col-xs-12">
                <div class="card bg-gradient-info border-0 kpi-card-margin">
                  <div class="card-body">
                    <div class="row">
                      <div class="col card-bx">
                        <div class="kpi-card-header">
                          <span class="h2 font-weight-bold mb-0 text-white card-bx-ft card-value">$ 
                            <span class="h2 font-weight-bold mb-0 text-white card-bx-ft card-value" *ngIf="!loader">{{totalBudget}}</span>
                          </span>
                          <img alt="Total Budget Allotted" class="kpi-card-icon" src="../../assets/images/available_budget.png" />
                        </div>
                        <div class="kpi-info">
                          <h5 class="card-title text-muted mb-0 text-white card-bx-se font-class">Total Budget Allotted</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-4 col-md-4 col-xs-12">
                <div class="card bg-gradient-danger border-0 kpi-card-margin">
                  <div class="card-body">
                    <div class="row">
                      <div class="col card-bx">
                        <div class="kpi-card-header">
                          <span class="h2 font-weight-bold mb-0 text-white card-bx-ft card-value">$ 
                            <span class="h2 font-weight-bold mb-0 text-white card-bx-ft card-value" *ngIf="!loader">{{consumedBudget}}</span>
                          </span>
                          <img alt="Total Direct Cost" class="kpi-card-icon" src="../../assets/images/consumed_budget.png" />
                        </div>
                        <div class="kpi-info">
                          <h5 class="card-title text-muted mb-0 text-white card-bx-se font-class">Total Direct Cost</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div  class="col-12 col-sm-4 col-md-4 col-xs-12">
                <div class="card bg-gradient-default border-0 kpi-card-margin">
                  <div class="card-body">
                    <div class="row">
                      <div class="col card-bx">
                        <div class="kpi-card-header">
                          <span class="h2 font-weight-bold mb-0 text-white card-bx-ft card-value">$ 
                            <span class="h2 font-weight-bold mb-0 text-white card-bx-ft card-value" *ngIf="!loader">{{availableBudget}}</span>
                          </span>
                          <img alt="Total Budget Available" class="kpi-card-icon" src="../../assets/images/consumed_project_budget.png" />
                        </div>
                        <div class="kpi-info">
                          <h5 class="card-title text-muted mb-0 text-white card-bx-se font-class">Total Budget Available</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Budget Breakdown -->
    <div class="container budget-breakdown">
      <div class="row px-2 pb-4">
        <div class="col align-self-center text-left font-class">
          <h3 >Organization-wise Budget Breakdown</h3>
        </div>
        <div class="col-auto pr-0 text-right">
          <button class="export-btn font-class" (click)="exportCSV()">Export as CSV</button>
        </div>
      </div>
      <!-- Budget Breakdown Projects list -->
      <div class="project-table-layout">
        <div class="projects-header d-flex w-100">
          <div class="title">
            Organization Name
          </div>
          <div class="title">
            Total Project Budget
          </div>
          <div class="title">
            Direct Cost
          </div>
          <div class="title">
            Available Budget
          </div>
          <div class="title">
            Consumed Budget Percentage
          </div>
        </div>
        <div class="projects" *ngIf="organisations && organisations.length > 0">
          <div class="project-row d-flex w-100 py-4 my-3" *ngFor="let organisation of organisations" (click)="openOrganisationDetails(organisation)">
            <div class="project-column">
              <span class="text-white">{{ organisation.organizationName }}</span>
            </div>
            <!-- Total Project Budget -->
            <div class="project-column">
              <span>{{ currencySymbol }} {{ getRoundOffBudget(organisation.totalBudgetAllocated) }}</span>
            </div>
            <!-- Consumed Budget -->
            <div class="project-column">
              <span>{{ currencySymbol }} {{ getRoundOffBudget(organisation.budgetConsumed) }}</span>
            </div>
            <!--  Available Budget -->
            <div class="project-column">
              <span>{{ currencySymbol }} {{ getAvailableBudget(organisation) }}</span>
            </div>
            <!--  Percentage -->
            <div class="project-column px-4">
              <!--<span>{{  projectAvailableBudgetPercentage(project) }}</span>-->
              <div class="progress">
                <div class="progress-bar" role="progressbar"
                   [style.width]="projectConsumedBudgetPercentage(organisation)+'%'"
                   aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <!-- mobile responsive -->
      <div class="project-cards-layout" *ngIf="organisations && organisations.length > 0">
        <div class="project-card" *ngFor="let organisation of organisations" (click)="openOrganisationDetails(organisation)">
          <ul class="list-group">
            <div class="d-flex justify-content-between mb-2">
              <div class="project-card-title font-class">Organization name</div>
              <div class="project-card-project-name">{{ organisation.organizationName }}</div>
            </div>
            <div class="d-flex justify-content-between mb-2">
                <div class="project-card-title project-card-line-height font-class">Total Project Budget</div>
                <div class="project-card-value">{{ currencySymbol }} {{ getRoundOffBudget(organisation.totalBudgetAllocated) }}</div>
            </div>
            <div class="d-flex justify-content-between mb-2">
                <div class="project-card-title project-card-line-height font-class">Direct Cost</div>
                <div class="project-card-value">{{ currencySymbol }} {{ getRoundOffBudget(organisation.budgetConsumed) }}</div>
            </div>
            <div class="d-flex justify-content-between mb-2">
                <div class="project-card-title project-card-line-height font-class">Available Budget</div>
                <div class="project-card-value">{{ currencySymbol }} {{ getAvailableBudget(organisation) }}</div>
            </div>
            <div class="d-flex justify-content-between mb-2" style="align-items: center;">
              <div class="project-card-title project-card-line-height font-class">Consumed Budget Percentage</div>
              <div class="progress">
                  <div class="progress-bar" role="progressbar"
                       [style.width]="projectConsumedBudgetPercentage(organisation)+'%'"
                       aria-valuemin="0" aria-valuemax="100">
                  </div>
              </div>
            </div>
          </ul>
        </div>
      </div>

      <!-- No projects -->
      <div class="project-row d-flex w-100 py-4 my-3" *ngIf="organisations && organisations.length === 0">
        <div class="project-column w-100 text-center text-warning">No Organisations to show!</div>
      </div>

      <!-- Load more projects -->
      <!-- <div class="row load-more-btn-wrapper" *ngIf="organisations && organisations.length > 0">
        <button class="load-more-btn" >Load More</button>
      </div>
    </div> -->
  </div>
  