import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResearchService} from "../../../_services/research.service";
import { DashboardService } from "../../../_services/dashboard.service";
import {Globals} from "../../../globals";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-budget-management',
  templateUrl: './budget-management.component.html',
  styleUrls: ['./budget-management.component.css', '../../../app.component.css'],
  providers: [Globals]
})
export class BudgetManagementComponent implements OnInit {

  researcher:any;
  projectDetails;
  projectIds;
  projectId;
  budgetAllotted :any = 0;
  budgetConsumed :any = 0;
  currentMonthCost :any = 0;
  currencySymbol: string = '$';
  products = [];
  loader = false;
  budgetDetails = [];
  mergedBudgetDetails;
  projectName;
  displayName;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private researchService: ResearchService,
    private apiService: DashboardService,
    private globals: Globals,private toastr: ToastrService
              ) { }

  ngOnInit() {
    this.apiService.checkIfResearcher();
    this.researcher = this.route.snapshot.queryParams['researcher'];
    this.projectId = this.route.snapshot.queryParams['id'];
    this.displayName = this.route.snapshot.queryParams['displayName'];
    this.getProjects();
    this.getProductsBudget();
    this.getResearcherWiseProjectBudget();
  }

  researcherName(name) {
    let splitName = name.split('/');
    return splitName[1];
  }

  getProductsBudget() {
    this.loader = true;
    const data = {
      projectId: this.projectId,
      researcherName:this.researcher
    }
    this.researchService.getProductBudget(data).subscribe((data:any) => {
      this.loader = false;
      this.products = data;
      // data.forEach(item => {
      //   this.budgetConsumed += parseFloat(item.budgetConsumed);
      // })
      // this.budgetConsumed = Math.ceil(this.budgetConsumed);
      // this.budgetAvailable = this.budgetAllotted - this.budgetConsumed;
    }, error => {
      this.loader = false;
    });
  }

  getData(data) {
    const strLen = data.length;
    let str = data;
    if (strLen > 15) {
      str = str.slice(0, 15) + '...';
    }
    return str;
  }

  getResearcherWiseProjectBudget(){
      this.researchService.getResearcherListFromProjectId(this.projectId).subscribe((data:any) => {
        this.loader = false;
        for(let i=0;i<data.length;i++){
          if(data[i]['name'] == this.researcher){
            this.budgetAllotted = parseFloat(data[i]['budgetAllocated']).toFixed(2);
            this.budgetConsumed = parseFloat(data[i]['budgetConsumed']).toFixed(2);
            this.currentMonthCost = parseFloat(data[i]['monthlyBudgetCosumedByResearcher']).toFixed(2);
          }

        }
      }, error => {
        this.loader = false;
        if(error && error.status === 401){
          let errBody = error.error;
           this.toastr.error(errBody.err, '', this.globals.tosterOverride);
           this.router.navigate(['login']);
         }
      });
  }
  budgetManagement(){
    this.router.navigate(['researcherData/budget-projectData'],{ queryParams: { researcher: this.researcher}});
  }

  openProjectDetails() {
    this.router.navigate(['researcherData/budget-researcherData'],{ queryParams: { researcher: this.researcher, id: this.projectId}});
  }

  getProjects() {
    this.researchService.getPrincipalProjectsUsingId(this.projectId).subscribe(data => {
      // this.projectImg = data[0].img_src;
      this.projectName = data[0].name;
    }, error => {
      this.loader = false;
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }else if (error && error.status === 403) {
        const err = error.error;
        this.toastr.error(err.error, 'Error', this.globals.tosterOverride);
        this.router.navigate(['pageNotFound']);
      }
    });
  }

}
