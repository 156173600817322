
<form>
  <div class="instancetype">
     <div class="dialog-header">
         <img class="cancel-icon" (click)="cancel()" src="../../assets/images/cancel@2x.png" alt="Cancel"/>
         <h3 class="title">Change Instance Type</h3>
    </div>
      <div style="display: flex; flex-direction: row; margin-top: 10px;">
           <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
           <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
           <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
           <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
        </div>
        <div class="col-lg-12 col-md-12" style="padding: 25px" >
            <label class="instance" style="text-align: left;font: normal normal bold 16px/12px Nunito Sans;
    color: #fff9f9;margin-bottom: 15px; position: relative;
    display: block;">Select an instance type from the list below</label>
            <div class="s3-search-area-tp">
              <select class="dropdown text-white font-class s3-select"   placeholder="Choose Instance Type"  required name="instanceType" [(ngModel)]="instanceType" >

              <option value="undefined" disabled selected hidden> Choose instance</option>
              <option  *ngFor="let instanceType of filteredInstance" [value]="instanceType">{{ instanceType }}</option>
            </select>
          </div>
        </div>

        
        <div style="text-align: center;margin-bottom: 35px;margin-top: 20px;" >
          <button  [disabled]="!instanceType" name="button" type="button" class="btn updateButton"
          (click)="updateInstanceType()">Update</button>
        </div>
 
   </div>
</form>
