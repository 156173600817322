import { Component, OnInit, Inject } from '@angular/core';
import { MdlDialogReference } from '@angular-mdl/core';
import { MdlDialogService } from '@angular-mdl/core';
import { OrganizationService } from "../../_services/organization.service";
import { ResearchService } from "../../_services/research.service";
import { ToastrService } from 'ngx-toastr';
import {Globals} from "../../globals";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-assign-catalog',
  templateUrl: './assign-catalog.component.html',
  styleUrls: ['./assign-catalog.component.css'],
  providers: [Globals]
})
export class AssignCatalogComponent implements OnInit {

  projData;
  orgData;
  orgList = [];
  orgId;
  orgName;
  productIds = [];
  selectedFilter = "Choose an Organizational Unit from the list";
  selectedFilterMob = "Choose an Organizational Unit";
  selectedFilterProj = "Choose a project from the list";
  showSpinner = false;
  disableSubmit = true;
  assignType;
  accountId;
  projectName;
  projectId;
  productNames = [];
  selectedValue: any = '';
  projectsList = [];
  isStackCreated = false;


  constructor(public dialogRef: MdlDialogReference,
    private dialog: MdlDialogService,
    @Inject('data') public data: any,
    private orgService: OrganizationService,
  private catalogService: ResearchService,
  private toastr: ToastrService,
  private globals: Globals,
  private router: Router) { }

  ngOnInit() {
    this.showSpinner = true;
    this.assignType = this.data.assignType;
    if(this.assignType === 'Organization'){
      this.orgData = this.data.assignData;
      this.getProductIds(this.orgData);
      this.getOrgList();
    }else {
        this.projData = this.data.assignData;
        this.getProductIds(this.projData);
        this.getProjectsList();
    }
   
  }

  cancel(): void {
    this.dialogRef.hide();
  }

  getOrgList(){
    this.showSpinner = true;
    this.catalogService.getUnassignedOrganizations().subscribe((res:any) => {
      if(res){
      this.showSpinner = false;
      this.orgList = res;
      }
    }, error => {
      // this.showSpinner = false;
      console.error(error);
      let err = error.error;
    });
  }

  getProductIds(orgData){
      for(var i =0; i<orgData.length; i++){
        this.productIds.push(orgData[i]._id);
        this.productNames.push(orgData[i].name);
      }
  }

  assignProducts(){
    this.showSpinner = true;
    let assignData = {};
    assignData['productIds'] =this.productIds;
    assignData['orgId'] = this.orgId;
    assignData['productNames'] = this.productNames;
    assignData['orgName'] = this.orgName;
    assignData['type'] = 'assign';
    this.catalogService.assignProductsToOU(assignData).subscribe(res => {
      if(res){
      this.dialogRef.hide();
      this.showSpinner = false;
      this.toastr.success(``, `Successfully assigned products to ${this.orgName} organization`, this.globals.tosterOverride);
      this.router.navigate(['/admin/catalog'],{ queryParams: { assignedProduct : 'success' }});
      }
    }, error => {
      this.dialogRef.hide();
      this.showSpinner = false;
      console.error(error);
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }else{
        let err = error.error;
        this.toastr.error(err.error, 'Error in assigning products to OU ', this.globals.tosterOverride)
       }
    });
  }

  getOrgId(orgData){
    this.disableSubmit = false;
    this.orgId = orgData._id;
    this.orgName = orgData.org_name;
  }

  getOrgNameMob(data){
    const strLen = data.length;
    let str = data;
    if (strLen > 20) {
      str = str.slice(0, 19) + '...';
    }
    return str;
  }

  getSelectedProjData(proj){
    this.accountId = proj.account_id;
    this.projectName = proj.name;
    this.projectId = proj._id;
    this.disableSubmit = false;
  }

  getCatalogItems(projectData) {
    if(projectData){
      this.isStackCreated = projectData.isStackCreated;
    }
  }

  checkProductAssignment(){
    if(this.isStackCreated === true){
      this.updateProdToProj();
    }else{
      this.assignProdToProj();
    }
  }


  updateProdToProj(){
    this.showSpinner = true;
    let data = {}
    data['account_id'] = this.accountId;
    data['name'] = this.projectName;
    data['projectId'] = this.projectId;
    data['productIds'] = this.productIds;
    let toasterMsg = `The selected products are being updated to your project. This can take some time to complete. You can monitor the progress in the events tab of the project.`
    this.toastr.info(``, toasterMsg, this.globals.tosterOverride);
    this.showSpinner = false;
    this.dialogRef.hide();
    this.catalogService.updateProductsToProject(data).subscribe((res:any) => {
      if(res){
      let resMsg = `Catalog items that are previously assigned are still being created. Please try after sometime`;
      if(res.stackStatus == 'Creating'){
        this.toastr.error(``, res['message'] ? res['message'] : resMsg , this.globals.tosterOverride);
      }
      }
    }, error => {
      this.dialogRef.hide();
      this.showSpinner = false;
      console.error(error);
      let err = error.error;
      this.toastr.error(err.message, 'Error in updating products to project ', this.globals.tosterOverride)
    });
  }
  
  
  assignProdToProj(){
    this.showSpinner = true;
    let data = {}
    data['account_id'] = this.accountId;
    data['name'] = this.projectName;
    data['projectId'] = this.projectId;
    data['productIds'] = this.productIds;
    let toasterMsg = `The selected products are being assigned to your project. This can take some time to complete. You can monitor the progress in the events tab of the project.`
    this.toastr.info(``, toasterMsg, this.globals.tosterOverride);
    this.showSpinner = false;
    this.dialogRef.hide();
    this.catalogService.assignProductsToProject(data).subscribe(res => {      
      if(res){
        this.showSpinner = false;    
        let toasterStatus = res['status'];
        if(toasterStatus == 'info'){
          this.toastr.info(``, toasterMsg, this.globals.tosterOverride);
        }
      }
    }, error => {
      this.dialogRef.hide();
      this.showSpinner = false;
      console.error(error);
      let err = error.error;
      this.toastr.error(err.error, 'Error in assigning products to project ', this.globals.tosterOverride)
    });
  }

  //Get total projects that are created for the org
  getProjectsList() {
    this.showSpinner = true;
    this.catalogService.getProjectsForPrincipal().subscribe((data:any) => {
      let filterProjects = data.filter((project)=> project.project_type != "IngressGateway")
      this.projectsList = filterProjects;
      this.showSpinner = false;
    }, error => {
      this.showSpinner = false;
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    });
  }

}
