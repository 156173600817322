<div>
  <div class="dialog-header">
    <img class="cancel-icon" (click)="cancel()" src="../../assets/images/cancel@2x.png" alt="Cancel"/>
    <h2 class="modal-title title-header" id="myModalLabel" class="Header">
      Link a Compute Resource
    </h2>
  </div>
  <div style="display: flex; flex-direction: row; margin-top: 10px;">
    <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
  </div>
  <app-loader class="spinner" *ngIf="showSpinner"></app-loader>
  <div class="modal-body" *ngIf="!showSpinner && products.length > 0">
    <label class="details">
        Choose a Compute Resource to be linked to this Study<span class="order-st">*</span>
    </label>
  </div>
  
  <div class="dropdown-wrapper" *ngIf="!showSpinner && products.length > 0">
    <label class="wrap">
      <select  class="dropdown text-white font-class" required [(ngModel)]="selectedValue" #sName="ngModel" required name="selectedValue">
        <option [ngValue]="" disabled selected>Choose a product from the list to be linked </option>
        <option style="cursor: pointer;" *ngFor="let product of products; let $index=index" [ngValue]="product" title="{{product.productName}}">{{ trimProductsName(product.productName) }}</option>
      </select>
    </label>
    <label class="details-a">
      Choose a compute resource from the associated project(s)
    </label>
  </div>
  
  
  <div class="btn-wrapper" *ngIf="!showSpinner && products.length > 0">
    <div class="l-footer">
      <button   type="button" [disabled]="!selectedValue" class="btn cat-btn-update sshSubmit ssh-btn l-submit" (click)="linkStudy()">
          Link
      </button>
    </div>
  </div>
  <div *ngIf="!showSpinner && products.length === 0">
    <h3 class="noData">
      There is no provisioned Sagemaker product. Please launch a Sagemaker product from the Available Products page first, before linking to an S3 bucket.
    </h3>
  </div>
</div>