<div class="signupcontainer">
  <div class="signup-content">
    <h2 class="signup-label">Sign up for a new account</h2>
    <div style="display: flex; flex-direction: row; margin-top: 10px;">
      <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
      <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
      <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
      <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
    </div>
    <form #f="ngForm" novalidate style="position: relative; margin-top: 20px;" (ngSubmit)="f.form.valid && signup()">
      <div class="form-group">
        <label class="input-label" for="email">Email<span style="color: #FF7A7A"> *</span> </label>
        <input type="email" class="form-control user-input" name="email" id="email" placeholder="Enter Email ID (Required)"
               maxlength="254" [(ngModel)]="fromData.email" #email="ngModel"
               pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$">
<!--        <span class="length-cal" style="margin-bottom:-24px;padding:0px 20px"><small>{{254-fromData.email.length}} Characters left-->
<!--          </small></span>-->
        <div *ngIf="f.submitted && !email.valid || email.errors?.['pattern']" class="help-block">Please enter a valid email address</div>
      </div>
      <div class="form-group">
        <label class="input-label" for="first_name">First Name</label>
        <input type="text" class="form-control user-input" id="first_name" maxlength="50" name="first_name"
          [(ngModel)]="fromData.first_name" #first_name="ngModel" placeholder="Enter First Name (Optional)">
<!--        <span class="length-cal" style="padding:0px 20px"><small>{{50-fromData.first_name.length}} Characters left </small></span>-->
<!--        <div *ngIf="f.submitted && !first_name.valid" class="help-block">Please enter the First Name</div>-->
      </div><div class="form-group">
        <label class="input-label" for="last_name">Last Name</label>
        <input type="text" class="form-control user-input" id="last_name" maxlength="50" name="last_name"
          [(ngModel)]="fromData.last_name" #last_name="ngModel" placeholder="Enter Last Name (Optional)">
<!--      <span class="length-cal" style="padding:0px 20px"><small>{{50-fromData.last_name.length}} Characters left </small></span>-->
<!--        <div *ngIf="f.submitted && !last_name.valid" class="help-block">Please enter the Last Name</div>-->
      </div>
<!--      <div class="form-group">-->
<!--        <label class="input-label" for="username">Username </label>-->
<!--        <input type="text" class="form-control user-input" id="username" maxlength="50" name="username" required-->
<!--          [(ngModel)]="fromData.username" #username="ngModel" placeholder="Enter Username">-->
<!--        <span class="length-cal" style="padding:0px 20px"><small>{{50-fromData.username.length}} Characters left </small></span>-->
<!--        <div *ngIf="f.submitted && !username.valid" class="help-block">Please enter the Username</div>-->
<!--      </div>-->
      <div class="form-group">
        <label class="input-label" for="phone_number">Phone number</label>
        <input type="email" class="form-control user-input" name="phone_number" id="phone_number" placeholder="Enter phone number (Optional)"
               [(ngModel)]="fromData.phone_number" #phone_number="ngModel"
               >
<!--        <international-phone-number [(ngModel)]="fromData.phone_number" name="phone_number" id="phone_number" placeholder="Enter phone number" [maxlength]="20" [defaultCountry]="'in'"  #phone_number="ngModel" [allowDropdown]="true"></international-phone-number>-->


        <!--        <span class="length-cal" style="margin-bottom:-24px;padding:0px 20px"><small>{{15 - fromData.phone_number.length}} Characters left-->
<!--          </small></span>-->
        <div *ngIf="f.submitted && !phone_number.valid || phone_number.errors?.['pattern']" class="help-block">Please enter a valid phone number</div>
      </div>
      <div  class="form-group" style="margin-left: 20px;text-align: left;"> 
        <label for="license" class="visually-hidden">Agree Teams and Condition Checkbox</label>       
        <input type="checkbox" name="license" #license="ngModel" [(ngModel)]="fromData.license" id="license" required>
        <a  class="a-label" target="_blank" (click)="openTermsAndCondition()"
            title="Click to view terms and condition"> I agree to the terms and conditions. </a>
        <div *ngIf="f.submitted && !license.valid" class="help-block">Please agree to the terms and conditions to register.</div>
      </div>
      <div class="form-group" style="margin-left: 18px" *ngIf="captchaPublicKey">
        <re-captcha [(ngModel)]="fromData.captcha" #captcha="ngModel" name="captcha"  (resolved)="resolved($event)" [siteKey]=captchaPublicKey>
        </re-captcha>
        <div style="padding-left: 0px" *ngIf="f.submitted && !captchaverified" class="help-block">Please verify captcha</div>
      </div>

<!--      <div class="form-group g-recaptcha" data-sitekey="6LfyG00bAAAAAGrZ7PtJPoUIGv9HkUQvfZcKoiTH"></div>-->
<!--      <div class="form-group" style="position: relative;">-->
<!--        <popover-content placement="auto" #myPopover [closeOnClickOutside]="true">-->
<!--          <div id="tagPop"  [innerHTML]="policy()"></div>-->
<!--        </popover-content>-->
<!--        <label class="input-label" for="password">Password </label>-->
<!--        <input type="password" class="form-control user-input" name="Password" id="Password" placeholder="Password"-->
<!--          [(ngModel)]="fromData.password" #password="ngModel" maxlength="16" required-->
<!--          pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,16}$" (change)="passwordEmpty()"-->
<!--          (keypress)="passwordEmpty()" (keyup)="passwordEmpty()" />-->
<!--        <span class="length-cal" style="margin-bottom:-24px;padding:0px 20px"><small>{{16-fromData.password.length}} Characters left-->
<!--          </small></span>-->
<!--        <i title="Click to view Password Policy" class="fa fa-question-circle" [popover]="myPopover"-->
<!--          [popoverCloseOnClickOutside]="true" style=" position: absolute;padding:0px 20px;color: #FFB500; top: 0px; right: 0px; font-size: 17px;"></i>-->
<!--        <div style="position:relative;top: 10px;" *ngIf="f.submitted && !email.valid || password.errors?.pattern"-->
<!--          class="help-block">Password must contain minimum 8 characters, <br> 1 uppercase, 1 numeric & 1 special character-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="form-group">-->
<!--        <label class="input-label" for="repass">Retype password</label>-->
<!--        <input type="password" name="repass" class="form-control user-input" #repass="ngModel"-->
<!--          [(ngModel)]="fromData.repass" id="repass" placeholder="Enter Retype password" maxlength="16" required-->
<!--          pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,16}$" (change)="passwordMatch()"-->
<!--          (keypress)="passwordMatch()" (keyup)="passwordMatch()">-->
<!--        <div *ngIf="f.submitted && !repass.valid" class="help-block">Please re-type the password</div>-->
<!--        <div style="position:relative;top: 10px;padding: 0px 20px" *ngIf="hasError" class="help-block">{{errorMsg}}</div>-->
<!--      </div>-->
      <div class="row" style="padding-top:5px">
        <div class="col-xs-8">
        </div>
        <div class="col-xs-4">
          <button type="submit" class="btn btn-dark btn-block btn-flat submit-btn" [disabled]="!this.fromData.license || !captchaverified || this.fromData.email == ''">Sign Up</button>
        </div>
      </div>
    </form>
    <div style="margin: 25px;">
      <label class="account-label">Already have an account?</label>
      <a class="text-color sign-up-btn" routerLink='/login'>Sign in to your account</a>
    </div>
  </div>
  <div class="singup-wrapper">
    <div class="signup-header">Sign up with one of the below accounts
      <div style="margin-top:30px">
        <button type="submit" class="btn btn-dark btn-block btn-flat social-media-singup-btn" (click)="googleAuth()"><span class="pull-left social-media-singup-label"><img src="../../assets/images/icons/search.svg" alt="Signup With Google" class="social-media-image">Sign up with Google</span></button>
      </div>
<!--      <div style="margin-top:20px">-->
<!--        <button type="submit" class="btn btn-dark btn-block btn-flat social-media-singup-btn"><span class="pull-left social-media-singup-label"><img src="../../assets/images/icons/facebook.svg" class="social-media-image">Sign up with Facebook</span></button>-->
<!--      </div>-->
<!--      <div style="margin-top:20px">-->
<!--        <button type="submit" class="btn btn-dark btn-block btn-flat social-media-singup-btn"><span class="pull-left social-media-singup-label"><img src="../../assets/images/icons/github.svg" class="social-media-image">Sign up with Github</span></button>-->
<!--      </div>-->
    </div>
  </div>
</div>

<!-- <div class="formRegister"  *ngIf="!showmsg">
  <div class="mdl-card__title mdl-color--primary mdl-color-text--white" style="background-color: rgb(0,0,0)!important">
    <h2 class="mdl-card__title-text"> Register </h2>
  </div>
  <div class="marginTop10">
    <div class="" style="padding: 13px;">
      <form  #f="ngForm" novalidate style="position: relative;" (ngSubmit)="f.form.valid && signup()">
        <div class="form-group">
          <label for="company">Organization</label>
          <input type="text" class="form-control" id="company" maxlength="50" name="company" required [(ngModel)]="fromData.company" #company="ngModel" placeholder="Enter Organization">
          <span class="length-cal"  style="margin-bottom:-24px;"  ><small>{{50-fromData.company.length}} Characters left </small></span>
          <div *ngIf="f.submitted && !company.valid" class="help-block">Please Enter Organization</div>
        </div>
        <div class="form-group">
          <label for="username">Username </label>
          <input type="text" class="form-control" id="username" maxlength="50" name="username" required [(ngModel)]="fromData.username" #username="ngModel" placeholder="Enter Username">
          <span class="length-cal"  style="margin-bottom:-24px;"  ><small>{{50-fromData.username.length}} Characters left </small></span>
          <div *ngIf="f.submitted && !username.valid" class="help-block">Please Enter Username</div>
        </div>
        <div class="form-group">
          <label for="email">Email </label>
          <input type="email" class="form-control" name="email" id="email" placeholder="Enter Email ID" maxlength="254" [(ngModel)]="fromData.email" #email="ngModel" pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" required  >
          <span class="length-cal"  style="margin-bottom:-24px;"  ><small>{{254-fromData.email.length}} Characters left </small></span>
          <div *ngIf="f.submitted && !email.valid || email.errors?.pattern" class="help-block">Invalid Email ID</div>
        </div>
        <div class="form-group" style="position: relative">
          <popover-content   placement="auto" #myPopover [closeOnClickOutside]="true">
            <div id="tagPop" [innerHTML]="policy()"></div>
          </popover-content>
          <label for="password">Password </label>
          <input type="password" class="form-control" name="Password" id="Password" placeholder="Password" [(ngModel)]="fromData.password" #password="ngModel" maxlength="16" required pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,16}$" (change)= "passwordEmpty()"  (keypress)="passwordEmpty()" (keyup)="passwordEmpty()"  />
          <span class="length-cal"  style="margin-bottom:-24px;"  ><small>{{16-fromData.password.length}} Characters left </small></span>
          <i title="Click to view Password Policy"  class="fa fa-question-circle" [popover]="myPopover"    [popoverCloseOnClickOutside]="true" style=" position: absolute; top: 0px; right: 0px; font-size: 17px;"></i>
          <div style="position:relative;top: 10px;" *ngIf="f.submitted && !email.valid || password.errors?.pattern" class="help-block">Password must contain minimum 8 characters, 1 uppercase, 1 numeric & 1 special character</div>
        </div>
        <div class="form-group">
          <label for="repass">Retype password</label>
          <input type="password" name="repass" class="form-control" #repass="ngModel" [(ngModel)]="fromData.repass" id="repass" placeholder="Enter Retype password" maxlength="16" required pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,16}$" (change)= "passwordMatch()"  (keypress)="passwordMatch()" (keyup)="passwordMatch()">
          <div *ngIf="f.submitted && !repass.valid" class="help-block">Please Enter Retype password</div>
          <div *ngIf="hasError" class="help-block">{{errorMsg}}</div>
        </div>
        <div class="row">
          <div class="col-xs-8">
          </div>
          <div class="col-xs-4" >
            <button type="submit" class="btn btn-dark btn-block btn-flat " [disabled]="!f.form.valid || hasError">Register</button>
          </div>
        </div>
      </form>
    </div>
    <p>- OR -</p>
  </div>
  <p><a class="text-color" routerLink="/login">I already have a membership </a></p>
</div> -->

<div class="msg" *ngIf="showmsg">
  <h1>Thank You!</h1>
  <p>
    verfication link mail is on it's way to
    <br>{{fromData.email}}
    <br>
    <a class="text-color" routerLink="/login">Login</a>
  </p>
</div>
<app-loader class="spinner" *ngIf="showSpinner"></app-loader>
