<div class='s3Upload' id="instanceSSHPage" *ngIf="assignType === 'Organization'">
  <div class="dialog-header">
    <img class="cancel-icon" (click)="cancel()" src="../../assets/images/cancel@2x.png"
      alt="Cancel" />
    <h2 class="modal-title title-header" id="myModalLabel" class="Header">
      Assign to an Organizational Unit
    </h2>
  </div>
  <div style="display: flex; flex-direction: row; margin-top: 10px;">
    <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
  </div>

  <div class="modal-body" *ngIf="orgList.length > 0">
    <label class="details-a">
      Select an Organizational Unit that you want to assign the selected products to
    </label>
    <label class="details">
      Organizational Unit <span class="order-st">*</span>
    </label>
  </div>

  <app-loader class="spinner" *ngIf="showSpinner"></app-loader>
  <!-- <div class="dropdown" style="padding-left: 35px;">
  <button [disabled]="valFilter" class="btn header-menu-ft dropdown-toggle" type="button" data-toggle="dropdown">
    <span class="menu-btn">
      <span class="menu-btn-text menu-cal-c">{{selectedFilter}}</span>
      <span class="menu-btn-text menu-cal-c-mobile">{{selectedFilterMob}}</span>
      <em class="fa fa-angle-down white faIcon"></em>
    </span>
  </button> 
  <ul class="dropdown-menu filter-dd">
    <button *ngFor="let data of orgList;" type="button" class="btn menu-item-btn filter-log" 
    (click)="getOrgId(data._id, data.org_name);selectedFilter = data.org_name;selectedFilterMob = data.org_name">{{data.org_name}}</button>
  </ul>
</div> -->

  <div class="col-lg-12 col-md-12 col-sm-6" style="padding-left: 35px;padding-right: 35px; cursor: pointer;"
    *ngIf="orgList.length > 0">
    <label class="wrap">
      <select class="dropdown text-white font-class" (ngModelChange)="getOrgId($event);" required
        [(ngModel)]="selectedValue" #sName="ngModel" required name="selectedValue" id="example">
        <option value="" disabled selected>Choose an Organizational Unit </option>
        <option style="cursor: pointer;" *ngFor="let data of orgList" [ngValue]="data">{{ data.org_name }}</option>
      </select>
    </label>
  </div>

  <label class="details"
    style="color: #85D4FF; font-weight: normal; margin-top: 0px;padding-left: 35px;margin-top: 10px;margin-bottom: 42px;"
    *ngIf="orgList.length > 0">
    Select an Organizational Unit to assign selected products
  </label>


  <div class="col-lg-12 col-md-12 mt-5"
    style="text-align: center; margin-bottom: 35px !important; margin-top: 0px !important" *ngIf="orgList.length > 0">
    <div class="marginForButtons l-footer">
      <button [ngClass]="{ 'disableSubmit': disableSubmit , 'l-submit' : !disableSubmit }" type="button"
        class="btn cat-btn-update sshSubmit ssh-btn" (click)="assignProducts()">
        Assign
      </button>
    </div>
  </div>
  <div *ngIf="!showSpinner && orgList.length == 0">
    <h3 class="noData">
      All organizations have been assigned with products. Please update if you need extra products in your organization.
    </h3>
  </div>
</div>

<div *ngIf="assignType === 'Project'">
  <div class="dialog-header">
    <img class="cancel-icon" (click)="cancel()" src="../../assets/images/cancel@2x.png"
      alt="Cancel" />
    <h2 class="modal-title title-header" id="myModalLabel" class="Header">
      Assign to project
    </h2>
  </div>
  <div style="display: flex; flex-direction: row; margin-top: 10px;">
    <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
  </div>

  <div class="modal-body" *ngIf="!showSpinner && projectsList.length > 0">
    <label class="details-a">
      Select a project that you want to assign the selected products to
    </label>
    <label class="details">
      Project name <span class="order-st">*</span>
    </label>
  </div>

  <app-loader class="spinner" *ngIf="showSpinner"></app-loader>

  <div class="col-lg-12 col-md-12 col-sm-6" style="padding-left: 35px;padding-right: 35px; cursor: pointer;"
    *ngIf="!showSpinner && projectsList.length > 0">
    <label class="wrap">
      <select class="dropdown text-white font-class" (ngModelChange)="getSelectedProjData($event); getCatalogItems($event)" required
        [(ngModel)]="selectedValue" #sName="ngModel" required name="selectedValue" id="example">
        <option value="" disabled selected>Choose a project from the list </option>
        <option style="cursor: pointer;" *ngFor="let data of projectsList" [ngValue]="data">{{ data.name }}</option>
      </select>
    </label>
  </div>

  <label *ngIf="!showSpinner && projectsList.length > 0" class="details"
    style="color: #85D4FF; font-weight: normal; margin-top: 0px;padding-left: 35px;margin-bottom: 42px;">
    Select a project to assign selected products
  </label>


  <div *ngIf="!showSpinner && projectsList.length > 0" class="col-lg-12 col-md-12 mt-5"
    style="text-align: center; margin-bottom: 35px !important; margin-top: 0px !important">
    <div class="marginForButtons l-footer">
      <button [ngClass]="{ 'disableSubmit': disableSubmit , 'l-submit' : !disableSubmit }" type="button"
        class="btn cat-btn-update sshSubmit ssh-btn" (click)="checkProductAssignment()">
        Assign
      </button>
    </div>
  </div>
  <div *ngIf="!showSpinner && projectsList.length == 0">
    <h3 class="noData">
      There are no projects. Please create a project first.
    </h3>
  </div>
</div>