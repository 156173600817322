import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthGuardService } from "../../guard/auth-guard.service";
import { DashboardService } from "../../_services/dashboard.service";
import { ResearchService } from "../../_services/research.service";
import { Globals } from "../../globals";
import { ToastrService } from "ngx-toastr";
// import { MdlDialogService } from '@angular-mdl/core';
// import { ShowRenewalDateComponent } from "./show-renewal-date/show-renewal-date.component";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
  providers: [HeaderComponent, Globals]
})
export class HeaderComponent implements OnInit {
  userType;
  userRoles;
  showUserName = true;
  showAboutMenu = false;
  showSpinner = false;
  user = null;
  username = "";
  email = "";
  displayName = "";
  photo: string;
  showChatAssist = false;
  chatAssistantURL;
  chatAssistantLogo;

  constructor(
    private router: Router,
    private apiService: DashboardService,
    public authGuard: AuthGuardService,
    private researchService: ResearchService,
    private globals: Globals,
    private toastr: ToastrService,
    // private dialog: MdlDialogService
  ) {
    this.router.events.subscribe((path) => {
      this.checkUserType(); 
      this.username = sessionStorage.getItem("currentUser");
      let role = sessionStorage.getItem("5b1647bf469e0b0cf9dcacca");
      let level = sessionStorage.getItem("Level");
      this.email = sessionStorage.getItem("currentUserEmail");
      this.displayName = sessionStorage.getItem("displayName");
      this.photo = sessionStorage.getItem("photo");
      this.userRoles = JSON.parse(sessionStorage.getItem("roles"));
      let org_id = sessionStorage.getItem("org_id");
      if (role === "landlord" || level == "2") {
        this.userType = "admin";
      } else if (level == "1") {
        this.userType = "principal";
      } else if (level == "0") {
        this.userType = "researcher";
      } else {
        this.userType = null;
      }
      if(org_id.length > 0){
        this.showAboutMenu = true;
      }else {
        this.showAboutMenu = false;
      }
    });
  }

  async ngOnInit() {    
    this.user = await this.apiService.showMenu();
    this.getChatAssist(); 
  }

  checkUserType() {
    let org_id = sessionStorage.getItem("org_id");
    let level = sessionStorage.getItem("Level");
    if(level == "2" ) {
      this.showUserName = true;        
    }else if(level != "2" && (org_id == "" || org_id == null || org_id == undefined)) {
      this.showUserName = false;        
    } else{
      this.showUserName = true;    
    }
    let subscriptionDate = sessionStorage.getItem("subscriptionRenewalDate")
    if(subscriptionDate ! == '' || subscriptionDate !== null) {
      const date = new Date();       
      const todayDate = date.toISOString();  
      if(todayDate > subscriptionDate && (this.userType === 'principal' || this.userType === 'researcher')) {
        this.showUserName = false;           
      }
    } 
  }
  logout() {
    this.apiService.logout();
  }

  // navigateToShowRenewalDate() {   
  //   this.dialog.showCustomDialog({
  //     component: ShowRenewalDateComponent,
  //     providers: [{ provide: "data", useValue: '' }],
  //     isModal: true,
  //     styles: {
  //       height: "auto",
  //       width: "520px",
  //       border: "1px solid #FFFFFF33",
  //       "border-radius": "10px",
  //       "background-color": "#07102A",
  //       padding: "0px",
  //     },
  //     clickOutsideToClose: true,
  //     enterTransitionDuration: 400,
  //     leaveTransitionDuration: 400,
  //   });
  // }

  navigateToResearch() {
    let role = sessionStorage.getItem("5b1647bf469e0b0cf9dcacca");
    let level = sessionStorage.getItem("Level");
    if (!this.showMenu()) {
      this.router.navigate(["welcome"]);
    } else if (role === "landlord" || level == "2") {
      this.router.navigate(["admin"]);
    } else if (level == "1") {
      this.router.navigate(["principal"]);
    } else if (level == "0") {
      this.router.navigate(["researcher"]);
    } else {
      this.router.navigate(["welcome"]);
    }
  }

  switchUserRole(role) {
    this.userType = role;
    if (role === "principal") {
      sessionStorage.setItem("Level", "1");
    } else {
      sessionStorage.setItem("Level", "0");
    }
  }

  showMenu() {
    if (
      this.user &&
      (this.user.level === null ||
        this.user.isGroupsMismatched ||
        !this.user.enabled)
    ) {
      return false;
    } else {
      return true;
    }
  }

  getHelp() {
    this.researchService.getHelp().subscribe(
      (data: any) => {
        if (data.value) {
          window.open(data.value, "_blank");
        }
      },
      (error) => {
        if (error && error.status === 401) {
          let errBody = error.error;
          this.toastr.error(errBody.err, "", this.globals.tosterOverride);
          this.router.navigate(["login"]);
        }
        // console.log("Read the docs URL error"+ error);
      }
    );
  }


getChatAssist() {
  this.apiService.getApplicationMode().subscribe((data: any) => {
    //only for B2C mode we should show chat assist
    if (data.enableB2CMode) {
      this.showChatAssist = true;
      this.researchService.getConfigData('chatAssistantConfig').subscribe((data: any) => {
        if (data && data?.value?.chatAssistantURL) {
          this.chatAssistantURL = data.value.chatAssistantURL || "https://chat.rlcatalyst.com/Documentation_Bot/";  // hardcode Prod chatBot link 
          this.chatAssistantLogo = data?.value?.chatAssistantLogo || "../assets/images/chatAssistant1.png";
        }
      })
    } else {
      this.showChatAssist = false;
    }
  })
}

openChatAssistant(){
  if(this.chatAssistantURL != null){
    window.open(this.chatAssistantURL, "_blank");
  }
}
}