import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Globals } from "../../globals";
import { ResearchService } from "../../_services/research.service";
import { DashboardService } from '../../_services/dashboard.service';
import { OrganizationService} from "../../_services/organization.service";
@Component({
  selector: 'app-add-study',
  templateUrl: './add-study.component.html',
  styleUrls: ['./add-study.component.css'],
  providers: [Globals]

})
export class AddStudyComponent implements OnInit {

  studyName;
  studyDescription;
  studyType;
  selectedAccessLevel;
  selectedStudyType;
  accessLevel;
  keyValue;
  tags = [];
  bucket;
  region;
  prefix = "/";
  awsAccount;
  encrypted = false;
  encryptValue = "No";
  kmsArn;
  kmsArnScope = false;
  selectedAccount;
  selectedStudyScope = "project";
  studyScope;
  fAccountIdList = [];
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  settings: any;
  principleProjData;
  projectList = [];
  selectedProjectId = [];
  showProjectList = [];
  // accountNumber = [];
  projectScope;
  nameScope;
  descriptionScope;
  tagScope;
  mode;
  studyDetails;
  studyId;
  username;
  studyProject = [];
  studyTags = [];
  isDisabled = false;
  defaultHelpClassStudyName = 'studyNameHelpText';
  defaultHelpClassBucketName = 'bucketNameHelpText';
  defaultHelpClassPrefix = 'prefixHelpText';
  defaultHelpClassKmsArn = 'kmsArnHelpText';
  accountType: string;
  organizations: any;
  orgId: any;
  vendor='aws';

  constructor(
    private route: ActivatedRoute, private router: Router, private _formBuilder: FormBuilder,
    private researchService: ResearchService,
    private globals: Globals,
    private toastr: ToastrService,
    private serviceApi: ResearchService,
    private dashboardService: DashboardService,
    private organizationService: OrganizationService
  ) { }

  ngOnInit() {
    this.dashboardService.checkIfPI();
    this.mode = this.route.snapshot.queryParams['mode'];
    this.vendor = this.route.snapshot.queryParams['vendor'].toLowerCase();
    this.studyId = this.route.snapshot.queryParams['studyId'];
    this.username = sessionStorage['currentUser'];
    this.orgId = sessionStorage['org_id'];
    if (this.mode === '1') {
      this.isDisabled = true;
      this.getProjectDetails();
    }
    this.firstFormGroup = this._formBuilder.group({
      studyNameCtrl: ['', Validators.required],
      descriptionCtrl: ['', Validators.required],
      accessCtrl: ['', Validators.required],
      studyTypeCtrl:['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      buckctNameCtrl: [{ value: '', disabled: this.isDisabled }, Validators.required],
      prefixCtrl: [{ value: '', disabled: this.isDisabled }, Validators.required],
      encryptCtrl: [{ value: '', disabled: this.isDisabled }],
      kmsArnCtrl: ['']
    });
    this.thirdFormGroup = this._formBuilder.group({
      projectAccountCtrl: [{ value: '', disabled: this.isDisabled }, Validators.required]
      // scopeCtrl: ['', Validators.required],
      // projectCtrl:['', Validators.required],
    });
    this.fAccountIdList = [];
    this.getSettingsData();

  }

  getProjectDetails() {
    this.researchService.getPrincipalProjects('1', '9999').subscribe((principleProjData: any) => {
     this.principleProjData = principleProjData.docs;
     this.principleProjData.forEach((num1, index) => {
       const projectObj = {
        'accountId': this.principleProjData[index].account_id,
         'projectId': this.principleProjData[index]._id,
         'projectName': this.principleProjData[index].name,
         'researchers': this.principleProjData[index].researchers
        }
       this.projectList.push(projectObj);
      });
      this.getStudyDetails();
    }, error => {
     if (error && error.status === 401) {
       let errBody = error.error;
       this.toastr.error(errBody.err, '', this.globals.tosterOverride);
       this.router.navigate(['login']);
      }
    })
  }

  getStudyDetails() {
      this.researchService.getStudyDetails(this.studyId).subscribe((res: any) => {
        console.log(res)
      this.studyDetails = res;
        const el = document.getElementById("bucketNameId");
        // @ts-ignore
        el?.style?.borderLeft = '5px solid #42A948';

        const dl = document.getElementById("accountId");
        // @ts-ignore
        dl?.style?.borderLeft = '5px solid #42A948';

        const al = document.getElementById("prefixId");
        // @ts-ignore
        al?.style?.borderLeft = '5px solid #42A948';
      this.studyName = this.studyDetails.name;
      this.studyDescription = this.studyDetails.description;
      this.bucket = this.studyDetails.resourceDetails[0].BucketName;
      this.prefix = this.studyDetails.resourceDetails[0].Prefix;
      this.tags = this.studyDetails.tags;
      this.selectedAccount = (this.studyDetails.accountId).toString();
      this.selectedAccessLevel = (this.studyDetails.studyAccess == 'readwrite') ? "Read and Write" : (this.studyDetails.studyAccess == 'readonly') ? "Read Only" : undefined
      if (this.selectedAccessLevel != undefined){
        const al = document.getElementById("acessLevelId");
        al.style.borderLeft = '5px solid #42A948';
      }
      if (this.studyDetails.studyType == 'internal') {
        this.studyType = "internal";
        this.selectedStudyType = "Internal Study";
        this.accountType = 'project';
      } else {
        // Handle the 'external' case if needed
        this.selectedStudyType = "External Study";
        this.accountType = 'study';
      }
      if (this.selectedStudyType != undefined){
        const al = document.getElementById("studyTypeId");
        al.style.borderLeft = '5px solid #42A948';
      }
      this.getSettingsData();
      this.selectedProjectId = [];
      this.showProjectList = [];
      for (let i = 0; i < this.projectList.length; i++) {
        if(this.studyDetails.studyType == "internal") {
          if (this.studyDetails.accountId == this.projectList[i].accountId) {
            const studyProjectIds = this.studyDetails.projectId;
            const validateProjectId = studyProjectIds.some(obj => obj._id == this.projectList[i].projectId)
            const showProjectObj = {
              'projectId': this.projectList[i].projectId,
              'projectName': this.projectList[i].projectName,
              'checked': validateProjectId
            }
            let result = this.showProjectList.some(obj => obj.projectId == this.projectList[i].projectId)
            if (result == false) {
            this.showProjectList.push(showProjectObj);
            }
          }
        } else if(this.studyDetails.studyType == "external")
          this.listAllProjects();
        }
      for (let i = 0; i < this.studyDetails.projectId.length; i++) {
        this.selectedProjectId.push(this.studyDetails.projectId[i]._id);
        this.studyProject.push(this.studyDetails.projectId[i]._id);
      }
      for (let i = 0; i < this.studyDetails.tags.length; i++) {
        this.studyTags.push(this.studyDetails.tags[i]);
      }
      if (this.username !== this.studyDetails.createdBy) {
      }
    },error => {
      if (sessionStorage['Level'] == 1 && error.status === 500) {
        this.toastr.error('Not a valid study', '', this.globals.tosterOverride);
        this.router.navigate([`researcher/studies/`]);
       }
     });
  }

  routeBack() {
    this.router.navigate([`researcher/studies/${this.studyId}`, { studyName: this.studyName }])
  }
  checkStudyName(event) {
    const dataLeng = event.target.value.length;
    const regexPattern = /^[a-zA-Z0-9][a-zA-Z0-9_-]*$/
    const getRegValue = event.target.value.match(regexPattern);
    if (dataLeng == 0) {
      this.defaultHelpClassStudyName = 'studyNameHelpText';
    } else if (getRegValue === null) {
      this.defaultHelpClassStudyName = 'studyNameError';
    } else if (getRegValue.length > 0) {
      this.defaultHelpClassStudyName = 'studyNameHelpText';
    }
    if (this.mode == '1') {
      if (this.studyName == this.studyDetails.name) {
        this.nameScope = false;
      }
      else {
        this.nameScope = true;
      }
    }
  }

  checkDescription() {
    if (this.mode == '1') {
      if (this.studyDescription == this.studyDetails.description) {
        this.descriptionScope = false;
      }
      else {
        this.descriptionScope = true;
      }
    }
  }
  checkBucketName(event) {
    const dataLeng = event.target.value.length;
    const regexPattern = /^[a-z0-9][a-z0-9.-]*$/
    const getRegValue = event.target.value.match(regexPattern);
    if (dataLeng == 0) {
      this.defaultHelpClassBucketName = 'bucketNameHelpText';
    } else if (getRegValue === null) {
      this.defaultHelpClassBucketName = 'bucketNameError';
    } else if (getRegValue.length > 0) {
      this.defaultHelpClassBucketName = 'bucketNameHelpText';
    }

  }
  validateKmsArnValue() {
    let kms_arn_value = this.kmsArn;
    let regex = /^arn:aws:kms:\w+(?:-\w+)+:\d{12}:key\/[A-Za-z0-9]+(?:-[A-Za-z0-9]+)+$/;
    let result = regex.test(kms_arn_value);
    if (result == true) {
      const al = document.getElementById("arnId");
      al.style.borderLeft = '5px solid #42A948';
      this.kmsArnScope = false;
      this.defaultHelpClassKmsArn = 'kmsArnHelpText';
    } else {
      const al = document.getElementById("arnId");
      al.style.borderLeft = '5px solid #a94442';
      this.kmsArnScope = true;
      this.defaultHelpClassKmsArn = 'kmsArnError';
    }
  }
  checkPrefix(event) {
    const dataLeng = event.target.value.length;
    const regexPattern = "^([a-zA-Z0-9./_-]*)$";
    const getRegValue = event.target.value.match(regexPattern);
    if (dataLeng == 0) {
      this.defaultHelpClassPrefix = 'prefixHelpText';
    } else if (getRegValue === null) {
      this.defaultHelpClassPrefix = 'prefixError';
    } else if (getRegValue.length > 0) {
      this.defaultHelpClassPrefix = 'prefixHelpText';
    }
  }
  getSettingsData() {
    this.fAccountIdList = [];
    this.dashboardService.getSettingsDetail('1', '999', 'created_on', 'asc', 'principal',"project").subscribe((settingsDetail: any) => {
      this.settings = settingsDetail.settings.map(obj => {
        const accountObj = {
          'accountId': obj._id,
          'name': obj.name,
          'region': obj.region,
          'accountNumber': obj.accountNumber,
          'orgName': [],
          'project': [],
          'created_on': obj.created_on,
          '_id': obj._id,
          'isCleanUpStarted': obj.isCleanUpStarted,
          'status': obj.status,
          'data': obj.data,
          'username': obj.username,
          'projectId': obj.projectId,
          'orgId': obj.orgId,
          'isShared': obj.isShared,
          'vendor': obj.vendor,
        };
        this.fAccountIdList.push(accountObj);
        return accountObj;
      });

      if(this.accountType == 'study'){
        this.fAccountIdList = this.fAccountIdList.filter(accountObj => accountObj.status == 'success' );
        let studyAccount = [];
        for(let account of this.fAccountIdList) {
          if(account.isShared == true && account.orgId.includes(this.orgId)) {
            studyAccount.push(account);
          } else if(account.isShared == false && account.username === this.username) {
            studyAccount.push(account);
          }
        }
        this.fAccountIdList = studyAccount;
      }
      if (this.fAccountIdList.length == 1) {
        this.selectedAccount = this.fAccountIdList[0].accountId;
        this.awsAccount = this.fAccountIdList[0].accountNumber;
        this.region = this.fAccountIdList[0].region;
        if(this.accountType == 'project' && this.mode == '2'){
          this.projectAccount()
        }
      }

      if (this.accountType == 'study' && this.fAccountIdList.length  == 0){
        this.toastr.error('At least one study account has to be added to the Settings page to create an External study.', '', this.globals.tosterOverride);
      }else if (this.accountType == 'project' && this.fAccountIdList.length == 0){
        this.toastr.error('At least one project account has to be added to the Settings page to create an Internal study.', '', this.globals.tosterOverride);
      }

      this.researchService.getPrincipalProjects('1', '9999').subscribe((principleProjData: any) => {
        this.principleProjData = principleProjData.docs;
        this.principleProjData.forEach((num1, index) => {
          const projectObj = {
            'accountId': this.principleProjData[index].account_id,
            'projectId': this.principleProjData[index]._id,
            'projectName': this.principleProjData[index].name,
            "researchers":this.principleProjData[index].researchers
          }
         // Check if the project with the same projectId exists before pushing
        const existingProject = this.projectList.find(proj => proj.projectId === projectObj.projectId);
        if (!existingProject) {
            this.projectList.push(projectObj);
        }
        });
      }, error => {
        if (error && error.status === 401) {
          let errBody = error.error;
          this.toastr.error(errBody.err, '', this.globals.tosterOverride);
          this.router.navigate(['login']);
        }else if (error && error.status === 403) {
          const err = error.error;
          this.toastr.error(err.error, 'Error', this.globals.tosterOverride);
          this.router.navigate(['pageNotFound']);
        }
      })
    })

  }

  projectAccount() {
    let accountId = this.selectedAccount.toString();
    this.selectedProjectId = [];
    this.showProjectList = [];
    for (let i = 0; i < this.projectList.length; i++) {
      if (accountId == this.projectList[i].accountId) {
        const showProjectObj = {
          'projectId': this.projectList[i].projectId,
          'projectName': this.projectList[i].projectName,
        }
        this.showProjectList.push(showProjectObj);
      }
    }
    for (let i = 0; i < this.fAccountIdList.length; i++) {
      if (this.selectedAccount == this.fAccountIdList[i].accountId) {
        this.awsAccount = this.fAccountIdList[i].accountNumber;
        this.region = this.fAccountIdList[i].region;
      }
    }
  }

  listAllProjects(){
    this.organizationService.getOrganizationsList().subscribe(data =>{
      this.organizations = data;
      this.organizations.forEach((org => {
        if(org._id == this.orgId){
          if (!org.data_admin.includes(this.username)) {
            this.projectList = this.projectList.filter(project => project.researchers.includes(this.username));
          }
          this.showProjectList = [];
          if(this.mode == '1') {
            this.studyType = "external"
            for (let i = 0; i < this.projectList.length; i++) {
              const validateProjectId = this.studyDetails.projectId.some(obj => obj._id == this.projectList[i].projectId)
              const showProject = {
                'projectId': this.projectList[i].projectId,
                'projectName': this.projectList[i].projectName,
                'checked': validateProjectId
              }
              this.showProjectList.push(showProject);
            }
          } else {
            for (let i = 0; i < this.projectList.length; i++) {
              const showProjectObj = {
                'projectId': this.projectList[i].projectId,
                'projectName': this.projectList[i].projectName,
              }
              this.showProjectList.push(showProjectObj);
            }
          }
        }}
      ))
    })

  }

  selectedStudyAccount(){
    for (let i = 0; i < this.fAccountIdList.length; i++) {
      if (this.selectedAccount == this.fAccountIdList[i].accountId) {
        this.awsAccount = this.fAccountIdList[i].accountNumber;
        this.region = this.fAccountIdList[i].region;
      }
    }
  }

  selectProjectIds(projectId, index, event) {
    debugger;
    if (event.target.checked) {
      this.selectedProjectId.push(projectId);
    } else {
      this.selectedProjectId = this.selectedProjectId.filter(function (item) {
        return item !== projectId
      })
    }
    const validateProject = [];
    for (let i = 0; i < this.selectedProjectId.length; i++) {
      const studyProjectIds = this.studyDetails.projectId;
      const validateProjectId = studyProjectIds.some(obj => obj._id == this.selectedProjectId[i])
      validateProject.push(validateProjectId);
    }
    if (this.mode == '1') {
      if (this.selectedProjectId.length == 0) {
        this.projectScope = true;
      }
      if (this.studyProject.length == this.selectedProjectId.length) {
        if (validateProject.includes(false)) {
          this.projectScope = true;
        }
        else {
          this.projectScope = false;
        }
      }
      else {
        this.projectScope = true;
      }
    }
  }


  accessLevelType() {
    if (this.selectedAccessLevel == undefined) {
      const al = document.getElementById("acessLevelId");
      al.style.borderLeft = '5px solid #a94442';
    } else {
      const al = document.getElementById("acessLevelId");
      al.style.borderLeft = '5px solid #42A948';
    }
    if (this.selectedAccessLevel == "Read Only") {
      this.accessLevel = "readonly";
    }
    else if (this.selectedAccessLevel == "Read and Write") {
      this.accessLevel = "readwrite";
    }
  }

  accessStudyType(){
    if (this.selectedStudyType == undefined) {
      const al = document.getElementById("studyTypeId");
      al.style.borderLeft = '5px solid #a94442';
    } else {
      const al = document.getElementById("studyTypeId");
      al.style.borderLeft = '5px solid #42A948';
    }
    this.selectedAccount = undefined;
    if (this.selectedStudyType == "Internal Study") {
      this.studyType = "internal";
      this.accountType = 'project';
      this.showProjectList = [];

    }
    else if (this.selectedStudyType == "External Study") {
      this.studyType = "external";
      this.accountType = 'study';
      this.listAllProjects()

    }
    this.getSettingsData()
  }

  addTag(formValidationValue) {
    if (!this.keyValue.trim() || !formValidationValue) {
      return;
    }
    let obj = (this.keyValue).toLowerCase();
    let result = this.tags.includes(obj);
    if (result == false) {
      this.tags.push(obj);
    }
    this.keyValue = '';
    const validateTagsArray = [];
    for (let i = 0; i < this.tags.length; i++) {
      if(this.studyTags.includes(this.tags[i])){
      var validateTags = true;
      } else {
        var validateTags = false;

      }
    }
    validateTagsArray.push(validateTags);

    if (this.mode == '1') {
      if (this.studyTags.length == this.tags.length) {
        if (validateTagsArray.includes(false)) {
          this.tagScope = true;
        }
        else {
          this.tagScope = false;
        }
      } else{
        this.tagScope = true;

      }
    }
    }


  removeTag(tag) {
    const index = this.tags.indexOf(tag);
    if (index > -1) {
      this.tags.splice(index, 1);
    }
    const validateTagsArray = [];
    for (let i = 0; i < this.tags.length; i++) {
      if(this.studyTags.includes(this.tags[i])){
      var validateTags = true;
    }
      else{
        var validateTags = false;

      }
      validateTagsArray.push(validateTags);

      }

    if (this.mode == '1') {
      if (this.studyTags.length == this.tags.length) {
        if (validateTagsArray.includes(false)) {
          this.tagScope = true;
        }
        else {
          this.tagScope = false;
        }
      }
      else{
        this.tagScope = true;

      }
    }
  }

  checkTagValue(formValidationValue) {
    let tagKeyValue = (this.keyValue).toLowerCase();
    let length = tagKeyValue.length;
    let result = this.tags.includes(tagKeyValue);
    if (result == false && formValidationValue) {
      const el = document.getElementById("tagValueId");
      el.style.borderLeft = '5px solid #42A948';
      const al = document.getElementById("buttonClickId");
      al.style.background = '#85D4FF';
    } else {
      const el = document.getElementById("tagValueId");
      el.style.borderLeft = '5px solid #a94442';
      const al = document.getElementById("buttonClickId");
      al.style.background = '#465374';
    }
  }

  checkStudyAccountValue() {
    if (this.awsAccount == undefined) {
      const el = document.getElementById("studyAccountId");
      el.style.borderLeft = '5px solid #a94442';
    } else {
      const el = document.getElementById("studyAccountId");
      el.style.borderLeft = '5px solid #42A948';
    }
  }


  // studyScopeType() {
  //   if (this.selectedStudyScope == undefined) {
  //     const al = document.getElementById("studyScopeId");
  //     al.style.borderLeft = '5px solid #a94442';
  //   } else {
  //     const al = document.getElementById("studyScopeId");
  //     al.style.borderLeft = '5px solid #42A948';
  //   } if (this.selectedStudyScope == "My Study") {
  //     this.studyScope = "personal";
  //     const al = document.getElementById("hideProjectId");
  //     al.style.display = 'none';
  //     this.projectScope = true;
  //   } else if (this.selectedStudyScope == "Project Level Study") {
  //     this.studyScope = "project";
  //     const al = document.getElementById("hideProjectId");
  //     al.style.display = 'block';
  //     this.projectScope = (this.thirdFormGroup.value.projectCtrl);
  //   }
  // }

  bucketEncrypted() {
    if (this.encrypted) {
      this.encryptValue = "Yes";
      this.kmsArnScope = true;
      this.secondFormGroup.controls['kmsArnCtrl'].enable();
    } else {
      this.encryptValue = "No";
      this.kmsArnScope = false;
      this.kmsArn = undefined;
      this.secondFormGroup.controls['kmsArnCtrl'].disable();
    }
  }

  createStudy() {
    if (this.vendor === 'azure') {
      this.studyType = "internal";

    }
    const last = (this.prefix).charAt((this.prefix).length - 1);
    if (last !== "/") {
      this.prefix = this.prefix + "/";
    }
    const first = this.prefix.charAt(0);
    if((this.prefix).length > 2) {
      const first = (this.prefix).charAt(0);
      if(first == "/") {
        this.prefix = this.prefix.substring(1);
      }
    }
    let studyData;
    studyData = {
      studyName: this.studyName,
      studyDescription: this.studyDescription,
      studyType: this.studyType,
      selectedAccessLevel: this.accessLevel,
      tags: this.tags,
      bucket: this.bucket,
      region: this.region,
      prefix: this.prefix,
      awsAccount: this.awsAccount,
      kmsArn: this.kmsArn,
      selectedStudyScope: this.selectedStudyScope,
      accountId: (this.selectedAccount).toString(),
      projectId: this.selectedProjectId, //TODO:: Check if this was ommitted by design
      vendor: this.vendor,
    }
    let t = new Date().getTime();
    this.researchService.createStudy(studyData).subscribe((response : any) => {
      this.router.navigate([`researcher/studies`], { queryParams: { showTab: 'studies', updatedData: t, filter: studyData.studyType } });
      this.toastr.success(response.message, '', this.globals.tosterOverride);
    }, error => {
      let errorMes = error.error;
      if (error && error.status === 401) {
        let errBody = error.error;
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
      } else if (errorMes && (errorMes.message)) {
        this.toastr.error('', errorMes.message, { tapToDismiss: false, timeOut: 0, extendedTimeOut: 0, closeButton: true });
      } else if (error && error.status === 500) {
        this.toastr.error(errorMes.error, '', this.globals.tosterOverride);
      } else {
        this.toastr.error('Error in add study to the Project', '', this.globals.tosterOverride);
      }
    });
  }

  updateStudy() {
    let studyData;
    studyData = {
      studyId:this.studyId,
      studyName: this.studyName,
      studyDescription: this.studyDescription,
      studyType: this.studyType,
      tags: this.tags,
      projectId: this.selectedProjectId,
      accountId: (this.selectedAccount).toString()
    }
    let t = new Date().getTime();
    this.researchService.updateStudy(studyData).subscribe(response => {
      if(response) {
        this.router.navigate([`researcher/studies`], { queryParams: { showTab: 'studies', updatedData: t } });
        this.toastr.success('The study has been updated successfully', '', this.globals.tosterOverride);
      }
    }, error => {
      let errorMes = error.error;
      if (error && error.status === 401) {
        let errBody = error.error;
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
      } else if (errorMes && (errorMes.message)) {
        this.toastr.error('', errorMes.message, { tapToDismiss: false, timeOut: 0, extendedTimeOut: 0, closeButton: true });
      } else if (error && error.status === 500) {
        this.toastr.error(errorMes.error, '', this.globals.tosterOverride);
      } else {
        this.toastr.error('Error in edit study to the Project', '', this.globals.tosterOverride);
      }
    });
  }
}
