<div class="container">
<div class="project-container" style="margin-bottom: 20px;">
<div class="empty-my-product-layout">
  <div>
    <h3 class="no-data-found text-white font-class text-center">Welcome to RLCatalyst Research Gateway! </h3>
    <h3 class="no-data-found-subtext text-white font-class">{{welcome_msg}}</h3>
    <h3 class="no-data-found-subtext text-white font-class"> </h3>
  </div>
</div>
</div>
</div>
