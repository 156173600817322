import { Component, OnInit, Inject } from '@angular/core';
import { MdlDialogReference } from '@angular-mdl/core';
import { Router } from "@angular/router";
import { ResearchService } from "../../_services/research.service";
import { ToastrService } from 'ngx-toastr';
import { Globals } from "../../globals";

@Component({
  selector: 'app-assign-public-study',
  templateUrl: './assign-public-study.component.html',
  styleUrls: ['./assign-public-study.component.css'],
  providers:[Globals]
})
export class AssignPublicStudyComponent implements OnInit {

  studyId;
  studyDetails;
  studyName;
  projectList=[];
  showProjectList=[];
  selectedProjectId=[];
  studyProjectIds=[];
  disabled = true;
  loader = true;

  constructor(
    @Inject('data') public data: any,
    private dialogRef : MdlDialogReference,
    private researchService : ResearchService,
    private toastr : ToastrService,
    private globals: Globals,
    private router : Router
  ) { }

  ngOnInit(): void {
    if (this.data != undefined) {
      this.studyId = this.data.studyId;
      this.getProjectDetails();
    }
  }

  cancel(){
    this.dialogRef.hide();
  }
  
  getProjectDetails(){
    this.researchService.getPrincipalProjects('1', '9999').subscribe((principleProjData: any) => {
      let ProjectData = principleProjData.docs;      
      let username = sessionStorage.getItem('currentUser');
      for(let project of ProjectData){
        let checkIfUserIsPartOfProject = project.researchers.includes(username)
        if(checkIfUserIsPartOfProject) {
          let projectObj ={
            'projectId' : project._id,
            'projectName' : project.name
          }
          this.showProjectList.push(projectObj);
          this.projectList.push(projectObj);
        }
      }           
      this.getStudyDetails();
    },error => {
      this.loader = false;
      if (error && error.status === 401) {
        let errBody = error.error;
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
       }
     })
  }

  getStudyDetails() {
    this.loader = true;
    this.researchService.getStudyDetails(this.studyId).subscribe((res: any) => {
      this.studyDetails = res;    
      this.studyName = this.studyDetails.name;
      this.studyProjectIds = this.studyDetails.projectId;
      this.showProjectList = [];
      if(this.projectList.length == 0){
        this.loader = false;
      }
      for (let i = 0; i < this.projectList.length; i++) {
        const validateProjectId = this.studyProjectIds.some(obj => obj._id == this.projectList[i].projectId)
        if(validateProjectId){
          this.selectedProjectId.push(this.projectList[i].projectId);
        }
        const showProjectObj = {
          'projectId': this.projectList[i].projectId,
          'projectName': this.projectList[i].projectName,
          'checked': validateProjectId
        }
        this.showProjectList.push(showProjectObj);  
        this.loader = false;    
      }
    },error => {
      this.loader = false;
      if (sessionStorage['Level'] == 1 && error.status === 500) {
        this.toastr.error('Not a valid study', '', this.globals.tosterOverride);
        this.router.navigate([`researcher/studies/`]);
      }
    });
  } 

  selectProjectIds(projectId, event) {
    if (event.target.checked) {
      this.selectedProjectId.push(projectId);
    } else {
      this.selectedProjectId = this.selectedProjectId.filter(function (item) {
        return item !== projectId
      })
    }   
    if(this.studyProjectIds.length == this.selectedProjectId.length){
      const validateProject = [];
      for (let i = 0; i < this.selectedProjectId.length; i++) {
        const studyProjectIds = this.studyDetails.projectId;
        const validateProjectId = studyProjectIds.some(obj => obj._id == this.selectedProjectId[i])
        validateProject.push(validateProjectId);
      }
      if (validateProject.includes(false)) {
        this.disabled = false;
      }           
      else {
        this.disabled = true;
      }      
    } else {
      this.disabled = false; 
    }
  }

  assignStudy() {   
    let studyData;
    studyData = {
      studyId:this.studyId,    
      studyName:this.studyName,  
      projectId: this.selectedProjectId
    }
    let t = new Date().getTime();
    this.researchService.assignStudyToProjects(studyData).subscribe(response => {
      if(response) {
        this.dialogRef.hide();        
      }
      this.toastr.success('The study has been assigned to the project successfully', '', this.globals.tosterOverride);
      this.router.navigate(['researcher/studies']);
    }, error => {
      this.dialogRef.hide();
      let errorMes = error.error;
      if (error && error.status === 401) {
        let errBody = error.error;
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
      } else if (errorMes && (errorMes.message)) {
        this.toastr.error('', errorMes.message, { tapToDismiss: false, timeOut: 0, extendedTimeOut: 0, closeButton: true });
      } else if (error && error.status === 500) {
        this.toastr.error(errorMes.error, '', this.globals.tosterOverride);
      } else {
        this.toastr.error('Error in assign study to the Project', '', this.globals.tosterOverride);
      }
    });
  }
}
