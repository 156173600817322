import { Component, OnInit, Inject } from '@angular/core';
import { MdlDialogReference } from '@angular-mdl/core';
import { ResearchService } from "../../_services/research.service";
import { DashboardService } from "../../_services/dashboard.service";
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {Globals} from "../../globals";

@Component({
  selector: 'app-update-network-details',
  templateUrl: './update-network-details.component.html',
  styleUrls: ['./update-network-details.component.css'],
  providers: [DashboardService,Globals]
})

export class UpdateNetworkDetailsComponent implements OnInit {
  
  settingId;
  vpc;
  publicSubnet1;
  publicSubnet2;
  privateSubnet;
  entryPointSG;
  workspaceSG;
  interfaceEndpointSG;
  networkDetails;
  disable = true;

  constructor( @Inject('data') public data: any,
  public dialogRef: MdlDialogReference,
  private researchService: ResearchService,
  private router: Router,
  private toastr: ToastrService,
  private globals: Globals,
  private dashboardService: DashboardService 
  ) { }

  ngOnInit(): void {
    if (this.data != undefined) {
      this.settingId = this.data.accountId;
      this.populateData();
    }
  }

  cancel(): void {
    this.dialogRef.hide();
  }

  populateData(){
    this.dashboardService.getSettingsDetails(this.settingId).subscribe((res:any) => {
      this.networkDetails = res.networkDetails;
      if(this.networkDetails.vpc) {
        this.vpc = this.networkDetails.vpc;
      }
      if(this.networkDetails.publicSubnet1) {
        this.publicSubnet1 = this.networkDetails.publicSubnet1;
      }
      if(this.networkDetails.publicSubnet2) {
        this.publicSubnet2 = this.networkDetails.publicSubnet2;
      }
      if(this.networkDetails.privateSubnet) {
        this.privateSubnet = this.networkDetails.privateSubnet;
      }
      if(this.networkDetails.entryPointSG) {
        this.entryPointSG = this.networkDetails.entryPointSG;
      }
      if(this.networkDetails.workspaceSG) {
        this.workspaceSG = this.networkDetails.workspaceSG;
      }
      if(this.networkDetails.interfaceEndpointSG) {
        this.interfaceEndpointSG = this.networkDetails.interfaceEndpointSG;      
      }         
    }) 
  }

  checkVPC(event){
    let data = event.target.value;
    let VPCPrefix = data.slice(0,4);
    let VPCId = data.slice(4)
    let regexPattern = "^[a-f0-9]*$"
    const getRegValue = VPCId.match(regexPattern);
    if(VPCPrefix != "vpc-" || getRegValue == null || data.length !== 21){
      this.disable = true;
      const el = document.getElementById("VPCId");
      el.style.borderLeft = '5px solid #F32561';
    }else {
      this.disable = false;
      const el = document.getElementById("VPCId");
      el.style.borderLeft = '5px solid #42A948';
    }
  }
  checkPublicSubnet1(event){
    let data = event.target.value;
    let publicSubnet1 = data.slice(0,7);
    let publicSubnet2 = data.slice(7);
    let regexPattern = "^[a-f0-9]*$"
    const getRegValue = publicSubnet2.match(regexPattern);
    if(publicSubnet1 != "subnet-" || getRegValue == null  || data.length !== 24){
      this.disable = true;
      const el = document.getElementById("PublicSubnet1Id");
      el.style.borderLeft = '5px solid #F32561';
    }else {
      this.disable = false;
      const el = document.getElementById("PublicSubnet1Id");
      el.style.borderLeft = '5px solid #42A948';
    }
  }
  checkPublicSubnet2(event){
    let data = event.target.value;
    let publicSub1 = data.slice(0,7);
    let publicSub2 = data.slice(7);
    let regexPattern = "^[a-f0-9]*$"
    const getRegValue = publicSub2.match(regexPattern);
    if(publicSub1 != "subnet-" || getRegValue == null || data.length !== 24){
      this.disable = true;
      const el = document.getElementById("PublicSubnet2Id");
      el.style.borderLeft = '5px solid #F32561';
    }else {
      this.disable = false;
      const el = document.getElementById("PublicSubnet2Id");
      el.style.borderLeft = '5px solid #42A948';
    }
  }
  checkPrivateSubnet(event){
    let data = event.target.value;
    let PrivateSubnet1 = data.slice(0,7);
    let PrivateSubnet2 = data.slice(7);
    let regexPattern = "^[a-f0-9]*$"
    const getRegValue = PrivateSubnet2.match(regexPattern);
    if(PrivateSubnet1 != "subnet-" || getRegValue == null  || data.length !== 24){
      this.disable = true;
      const el = document.getElementById("PrivateSubnetId");
      el.style.borderLeft = '5px solid #F32561';
    }else {
      this.disable = false;
      const el = document.getElementById("PrivateSubnetId");
      el.style.borderLeft = '5px solid #42A948';
    }
  }
  checkEntryPointSG(event){
    let data = event.target.value;
    let entryPoint1 = data.slice(0,3);
    let entryPoint2 = data.slice(3)
    let regexPattern = "^[a-f0-9]*$"
    const getRegValue = entryPoint2.match(regexPattern);
    if(entryPoint1 != "sg-" || getRegValue == null || data.length !== 20){
      this.disable = true;
      const el = document.getElementById("EntryPointSGId");
      el.style.borderLeft = '5px solid #F32561';
    }else {
      this.disable = false;
      const el = document.getElementById("EntryPointSGId");
      el.style.borderLeft = '5px solid #42A948';
    }
  }
  checkWorkspaceSG(event){
    let data = event.target.value;
    let WorkspaceSG1 =  data.slice(0,3);
    let WorkspaceSG2 =  data.slice(3);
    let regexPattern = "^[a-f0-9]*$"
    const getRegValue = WorkspaceSG2.match(regexPattern);
    if(WorkspaceSG1 != "sg-" || getRegValue == null  || data.length !== 20){
      this.disable = true;
      const el = document.getElementById("WorkspaceSGId");
      el.style.borderLeft = '5px solid #F32561';
    }else {
      this.disable = false;
      const el = document.getElementById("WorkspaceSGId");
      el.style.borderLeft = '5px solid #42A948';
    }
  }
  checkInterfaceEndpointSG(event){
    let data = event.target.value;
    let InterfaceEndpointSG1 = data.slice(0,3);
    let InterfaceEndpointSG2 = data.slice(3);
    let regexPattern = "^[a-f0-9]*$"
    const getRegValue = InterfaceEndpointSG2.match(regexPattern);
    if(InterfaceEndpointSG1 != "sg-" || getRegValue == null  || data.length !== 20 ){
      this.disable = true;
      const el = document.getElementById("InterfaceEndpointSGId");
      el.style.borderLeft = '5px solid #F32561';
    }else {
      this.disable = false;
      const el = document.getElementById("InterfaceEndpointSGId");
      el.style.borderLeft = '5px solid #42A948';
    }     
  } 

  updateSettings(){
    let updateObject = {  
      settingId: this.settingId,     
      vpc : this.vpc,
      publicSubnet1: this.publicSubnet1,
      publicSubnet2: this.publicSubnet2,
      privateSubnet: this.privateSubnet,
      entryPointSG: this.entryPointSG,
      workspaceSG: this.workspaceSG,
      interfaceEndpointSG: this.interfaceEndpointSG
    };
    let t = new Date().getTime();
    this.researchService.updateSettings(updateObject, "updateNetworkDetails").subscribe( response =>{
      this.dialogRef.hide();
      this.router.navigate([`/setting`],{ queryParams: { showTab:'setting', updatedData: t}});
      this.toastr.success('The network details has been updated successfully', '', this.globals.tosterOverride);
    }, error => {
      this.dialogRef.hide();
      let errorMes = error.error;
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
        }else if (errorMes && (errorMes.message)) {
        this.toastr.error('', errorMes.message, { tapToDismiss: false, timeOut: 0, extendedTimeOut: 0, closeButton : true });
      }else{
        this.toastr.error('Error in update network details to the setting', '', this.globals.tosterOverride);
      }
    }); 
  }
}