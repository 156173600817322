import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../_services/dashboard.service';
import { ResearchService } from '../../_services/research.service';
import {Globals} from "../../globals";
import { ToastrService } from 'ngx-toastr';
import {  Router} from "@angular/router";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  providers: [Globals]
})
export class FooterComponent implements OnInit {

  version;

  constructor(private apiService: DashboardService, private router :Router, private researchService: ResearchService,
    private globals: Globals,private toastr: ToastrService) { }

  ngOnInit() {
    this.apiService.getVersion().subscribe((data:any) => {
      this.version = data.version
    })
  }

  getHelp(){
    this.researchService.getHelp().subscribe((data:any) => {
      if(data.value){
        window.open(data.value, '_blank');
      }
    }, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
      // console.log("Read the docs URL error"+ error);
    });
  }

}
