import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from "@angular/router";
import { ResearchService} from "../../../_services/research.service";
import { ActivatedRoute } from '@angular/router';
import { DashboardService } from "../../../_services/dashboard.service";
import {Globals} from "../../../globals";
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-budget-product',
  templateUrl: './budget-product.component.html',
  styleUrls: ['./budget-product.component.css', '../budget-details.component.css', '../budget-tracking.component.css', '../../../app.component.css'],
  providers: [Globals]

})
export class AdminBudgetProductComponent implements OnInit {

  researcherDetails;
  projectDetails;
  organisationDetails;
  budgetAllotted : any = 0;
  budgetConsumed : any = 0;
  budgetAvailable : any = 0;
  currencySymbol: string = '$';
  products = [];
  loader = false;
  private sub: any;
  researcherBudgetConsumed : any = 0;
  researcherBudgetAvailable : any = 0;
  researchers = [];
  orgId;
  projectId;
  reseacherName;
  orgName;
  organisations;
  projectName;
  displayName;

  constructor(private researchService: ResearchService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private apiService: DashboardService,
    private globals: Globals,private toastr: ToastrService
    ) { }

  ngOnInit() {
    this.apiService.checkIfAdmin();
    this.orgId = this.route.snapshot.queryParams['id'];
    this.projectId = this.route.snapshot.queryParams['projectId'];
    this.projectName = this.route.snapshot.queryParams['projectName'];
    this.displayName = this.route.snapshot.queryParams['displayName'];
    this.reseacherName = this.route.snapshot.params['id'];
    this.getProductsBudget();
    this.getResearcherList(this.reseacherName);
    this.getOrganization();
  }

  goBack() {
    this.location.back();
  }

  researcherName(name) {
    // let splitName = name.split('/');
    return name;
  }

  getProductsBudget() {
    this.loader = true;
    const data = {
      projectId:this.projectId,
      researcherName:this.reseacherName
    }
    this.researchService.getProductBudget(data).subscribe((data:any) => {
      this.loader = false;
      this.products = data;
      // data.forEach(item => {
      //   this.budgetConsumed += parseFloat(item.budgetConsumed);
      // })
      // this.budgetConsumed = Math.ceil(this.budgetConsumed);
      // this.budgetAvailable = this.budgetAllotted - this.budgetConsumed;
    }, error => {
      this.loader = false;
      console.log(error)
    });
  }

  getResearcherList(researcherName) {
    this.loader = true;
    this.researchService.getResearcherListFromProjectId(this.projectId).subscribe((data:any) => {
      this.loader = false;
      this.researchers = data;
      this.researchers.forEach(item => {
        if(item.name === researcherName){
          this.budgetAllotted = parseFloat(item.budgetAllocated).toFixed(2);
          this.researcherBudgetConsumed = parseFloat(item.budgetConsumed).toFixed(2);
          this.researcherBudgetAvailable = parseFloat(item.budgetAvailable).toFixed(2);
        }
      })
      // this.loader = false;
    }, error => {
      this.loader = false;
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
      // this.loader = false;
    });
  }

  openOrganisationDetails() {
    this.router.navigate(['/admin/organisation/'+ this.orgId +'/details']);
  }

  openProjectDetails() {
    this.router.navigate(['/admin/project/'+ this.projectId+'/details'], { queryParams: { id: this.orgId, projectName: this.projectName}});
  }

  getOrganization() {
    this.loader = true;
    this.researchService.getOrganizations().subscribe(data => {
      this.loader = false;
      this.organisations = data;
      this.organisations.forEach((item => {
        if(item.organizationID === this.orgId){
        this.orgName = item.organizationName;
        }
      }))
    }, error => {
      this.loader = false;
      if(error && error.status === 403){
        const err = error.error;
        this.toastr.error(err.error, 'Error', this.globals.tosterOverride);
        this.router.navigate(['pageNotFound']);
      }else if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    });
  }

}
