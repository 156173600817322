import {ActivatedRoute, Router} from "@angular/router";
import { Component, OnInit, ViewChild } from '@angular/core';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { MdlDialogService } from '@angular-mdl/core';
import { ToastrService } from 'ngx-toastr';
import { ResearchService } from "../_services/research.service";
import { LinkStudyComponent } from '../dialog/link-study/link-study.component';
import { DataService } from '../_services/data.service';
import { S3FileDeleteComponent } from '../dialog/s3-file-delete/s3-file-delete.component';
import { ShareStudyComponent } from '../dialog/share-study/share-study.component';
import { ProductUtilService } from "../_services/productUtil.service";
import { UnlinkResourceComponent } from '../dialog/unlink-resource/unlink-resource.component';
import { DashboardService } from "../_services/dashboard.service";
import { AssignPublicStudyComponent } from '../studies/assign-public-study/assign-public-study.component';

import {Globals} from "../globals";

@Component({
  selector: 'app-study-details',
  templateUrl: './study-details.component.html',
  styleUrls: ['./study-details.component.css', '../app.component.css'],
  providers: [Globals]
})
export class StudyDetailsComponent implements OnInit {

  @ViewChild('studyTabs') studyTabs: TabsetComponent;

  studyId;
  studyName;
  studyDetails;
  bucketName;
  prefix;
  loader = false;
  productDetails = [];
  connectMenu = [];
  actionMenu = [];
  linkedResources = [];
  parametersObservable: any;
  updateStudy = false;
  resourceTab = false;
  productObject = null;
  action;
  principleProjData;
  projectList = [];
  showProjectList = [];
  studyprojectId = [];
  showAssignStudyButton = false;
  eventS = this.getEventSource(`/stream_events`);
  private fAccountIdList: any[];

  constructor(
    private productUtil: ProductUtilService,
    private route: ActivatedRoute,
    private router: Router,
    private researchService: ResearchService,
      private dialog: MdlDialogService, private dataService: DataService,
      private toastr: ToastrService,
    private apiService: DashboardService,
    private globals: Globals,
    private dashboardService: DashboardService,

  ) {
    this.dashboardService.checkIfPI();

    this.dataService.updatePage.subscribe(event => {
          if(event){
            this.resourceTab = true;
            this.studyTabs.tabs[1].active = true;
            this.getStudyDetails();
          }
        })
      }

  ngOnInit() {
    this.apiService.checkIfResearcher();
    this.serverStreamEvent();
    let level = sessionStorage.getItem('Level');
    if(level == '1'){
      this.showAssignStudyButton = true;
    }
    this.parametersObservable = this.route.params.subscribe(params => {
      this.studyId = params['studyId'];
      this.studyName = params['studyName'];
      this.getProjectDetails();
    });

    // Toggle study actions button for mobile view
    $(document).on("click", ".toggle-button", function(e) {
      e.stopImmediatePropagation();
      $(this).parent().find("ul").slideToggle();
    });
    $(document).on("click", ".action-link", function(e) {
      e.stopImmediatePropagation();
      if(window.innerWidth <= 1024){
        $(this).parent().parent().parent().parent().find("ul").slideToggle();
      }
    });

    // Toggle tags
    $(document).on("click", ".more-tag-content", function(e) {
      e.stopImmediatePropagation();
      $(this).parent().find("ul").slideToggle();
    });

    this.getAccounts();
  }
  getProjectDetails() {
    // get assigned projectName for study
    this.researchService.getPrincipalProjects('1', '9999').subscribe((principleProjData: any) => {
     this.principleProjData = principleProjData.docs;
     this.principleProjData.forEach((num1, index) => {
       const projectObj = {
         'projectId': this.principleProjData[index]._id,
         'projectName': this.principleProjData[index].name
        }
       this.projectList.push(projectObj);
      });
      this.getStudyDetails();
    }, error => {
     if (error && error.status === 401) {
       let errBody = error.error;
       this.toastr.error(errBody.err, '', this.globals.tosterOverride);
       this.router.navigate(['login']);
      }else if (error && error.status === 403) {
        const err = error.error;
        this.toastr.error(err.error, 'Error', this.globals.tosterOverride);
        this.router.navigate(['pageNotFound']);
      }
    })
  }

  getStudyDetails() {
    this.loader = true;
    this.productDetails = [];
    this.linkedResources = [];
    this.studyprojectId = [];
    this.showProjectList = [];
    this.researchService.getStudyDetails(this.studyId).subscribe((res:any) => {
      this.loader = false;
      this.studyDetails = res;

      // Tags
      if(window.innerWidth <= 576){
        // For Mobile view
        if(this.studyDetails.tags.length > 2) {
          this.studyDetails.showTags = this.studyDetails.tags.slice(0, 2);
          this.studyDetails.moreTagsCount = this.studyDetails.tags.length - 2;
          this.studyDetails.moreTags = this.studyDetails.tags.slice(2);
        }else {
          this.studyDetails.showTags = this.studyDetails.tags;
        }
      }else if(window.innerWidth <= 1024) {
        // For Tablet view
        if(this.studyDetails.tags.length > 3) {
          this.studyDetails.showTags = this.studyDetails.tags.slice(0, 3);
          this.studyDetails.moreTagsCount = this.studyDetails.tags.length - 3;
          this.studyDetails.moreTags = this.studyDetails.tags.slice(3);
        }else {
          this.studyDetails.showTags = this.studyDetails.tags;
        }
      }else {
        // For Desktop view
        if(this.studyDetails.tags.length > 4) {
          this.studyDetails.showTags = this.studyDetails.tags.slice(0, 4);
          this.studyDetails.moreTagsCount = this.studyDetails.tags.length - 4;
          this.studyDetails.moreTags = this.studyDetails.tags.slice(4);
        }else {
          this.studyDetails.showTags = this.studyDetails.tags;
        }
      }

      //product deatils mapping
      if(res.resourceDetails && res.resourceDetails.length > 0){
        const productObject = res.resourceDetails[0];
        this.productObject = productObject;
        const productKeys = Object.keys(productObject);
        productKeys.forEach(item => {
          let product = {
            key : "",
            value: ""
          };

          product.key = item;
          product.value = productObject[item]
          if(product.key == "BucketName"){
            product.key = "Bucket Name";
          }
          if(product.key == "AwsAccount"){
            product.key = "AWS Account";
          }
          if(product.key !== "Region"){
            this.productDetails.push(product);
          }
          if(product.key == "kmsArn" && product.value == null){
            product.value = "Null";
          }
          this.bucketName = this.productObject.BucketName;
          this.prefix = this.productObject.Prefix;
         })
      }
    // get assigned projectId for study
    for (let i = 0; i < this.studyDetails.projectId.length; i++) {
    this.studyprojectId.push(this.studyDetails.projectId[i]._id);
    }

    for (var i = 0; i < this.projectList.length; i++) {
      for (var j = 0; j < this.studyprojectId.length; j++) {
        if (this.projectList[i].projectId == this.studyprojectId[j]) {
          this.showProjectList.push(this.projectList[i].projectName);
        }
      }
    }
      //Shared researcher
      let shared = [];
      this.studyDetails.sharedTo.forEach(researcher => {
        // const researcher = item.split("/")[1];
        shared.push(researcher)
      })
      this.studyDetails.shared = shared;
      if(shared.length > 1) {
        this.studyDetails.showShared = this.studyDetails.shared.slice(0, 1);
        this.studyDetails.moreShared = this.studyDetails.shared.slice(1);
        this.studyDetails.moreSharedCount = this.studyDetails.shared.length - 1;
      }else {
        this.studyDetails.showShared = this.studyDetails.shared;
      }

      this.connectMenu = res.actions.connect;
      this.actionMenu = res.actions.action;
      this.linkedResources = res.linkedResources;

      // linked product image
      if(this.linkedResources){
        for(var i=0; i< this.linkedResources.length ; i++){
          const linkedProductName = this.productUtil.getServiceFromTags(this.linkedResources[i].Tags);
          // this.linkedResources[i].listOfLinkedProducts[0].productType.toLowerCase();
          this.linkedResources[i].imageUrl = '/assets/images/aws_icon/aws.png';
          const getImgLinkObj = this.productUtil.getImageForProduct(linkedProductName);
          // const getLinkImg = this.linkedResources.find(img => {
          //   const regex = new RegExp(img.listOfLinkedProducts[0].name, 'g');
          //   const match = regex.exec(linkedProductName);
          //   if (match) if (match[0])
          //   return img.name == match[0];
          // });
          if (getImgLinkObj) this.linkedResources[i]['imageUrl'] = getImgLinkObj['url'];
        }
      }
    },  error => {
      this.loader = false;
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
      } else if (error && error.status === 403) {
        const err = error.error;
        this.toastr.error(err.error, 'Error', this.globals.tosterOverride);
        this.router.navigate(['pageNotFound']);
      } else if (error.status === 500){
        this.router.navigate([`/researcher/studies`]);
      } else {
        const err = error.error;
        this.toastr.error(err.error, '', this.globals.tosterOverride);
        this.router.navigate([`pageNotFound`]);
      }
    })
  }

  actions(actionType) {
    switch (actionType.toLowerCase()) {
      case 'link':
        this.apiService.getDialogBox().subscribe((data:any) => {
          if(data.status === 200){
          this.dialog.showCustomDialog({
          component: LinkStudyComponent,
          providers: [{ provide: 'data', useValue: {action: 'linkStudy', studyId: this.studyId, resourceDetail: this.productObject} }],
          isModal: true,
          styles: {
            "height":"auto",
            "width":"600px",
            "border": "1px solid #FFFFFF33",
            "border-radius": "10px",
            "background-color": "#07102A",
            "padding": "0px"
          },
          clickOutsideToClose: true,
          enterTransitionDuration: 400,
          leaveTransitionDuration: 400,
        })}}, error => {
          if(error && error.status === 401){
            let errBody = error.error;
             this.toastr.error(errBody.err, '', this.globals.tosterOverride);
             this.router.navigate(['login']);
           }
        })
        break;
      case 'share':
        // this.getResearcherListToShare();
        this.apiService.getDialogBox().subscribe((data:any) => {
          if(data.status === 200){
        this.dialog.showCustomDialog({
          component: ShareStudyComponent,
          providers: [{ provide: 'data', useValue: { action: "shareStudy", studyId: this.studyId } }],
          isModal: true,
          styles: {
            "height":"auto",
            "width":"500px",
            "border": "1px solid #FFFFFF33",
            "border-radius": "10px",
            "background-color": "#07102A",
            "padding": "0px"
          },
          clickOutsideToClose: true,
          enterTransitionDuration: 400,
          leaveTransitionDuration: 400,
        })}}, error => {
          if(error && error.status === 401){
            let errBody = error.error;
             this.toastr.error(errBody.err, '', this.globals.tosterOverride);
             this.router.navigate(['login']);
           }
        })
        break;
      case 'delete':
        if(this.studyDetails.linkedResources.length != 0){
          this.toastr.error( "Please unlink all Sagemaker instances from this study before you terminate it.", '', this.globals.tosterOverride);
        }else {
        this.apiService.getDialogBox().subscribe((data:any) => {
          if(data.status === 200){
        this.dialog.showCustomDialog({
          component: S3FileDeleteComponent,
          providers: [{ provide: 'data', useValue: { action: "deleteStudy", studyId: this.studyId, studyDetails : this.studyDetails } }],
          isModal: true,
          styles: {
            "height":"auto",
            "width":"500px",
            "border": "1px solid #FFFFFF33",
            "border-radius": "10px",
            "background-color": "#07102A",
            "padding": "0px"
          },
          clickOutsideToClose: true,
          enterTransitionDuration: 400,
          leaveTransitionDuration: 400,
        })}}, error => {
          if(error && error.status === 401){
            let errBody = error.error;
             this.toastr.error(errBody.err, '', this.globals.tosterOverride);
             this.router.navigate(['login']);
           }
        })}
        break;
      case 'explore':
        if(this.studyDetails.studyType == "internal" && this.studyDetails.studyAccess == "readwrite"){
          this.router.navigate(['/explore/' + this.studyId], {queryParams:{name: this.studyName, accessLevel: this.studyDetails.studyAccess, prefix: this.prefix, type: this.studyDetails.studyType, accountId: this.studyDetails.accountId}});
        }
        else {
          this.router.navigate(['/explore/' + this.studyId], {queryParams:{name: this.studyName, accessLevel: this.studyDetails.studyAccess, prefix: this.prefix, type: this.studyDetails.studyType, accountId: this.studyDetails.accountId}});
        }
        break;
      case 'edit':
        this.action = actionType + "study";
        const awsAccountToFilter = this.studyDetails.resourceDetails[0].AwsAccount;
        const account = this.fAccountIdList.filter(setting => setting.accountNumber === awsAccountToFilter)[0];
       this.router.navigate(['researcher/studies/actions' ],{ queryParams :{studyId: this.studyId, mode: "1",vendor: account.vendor}});
    }
  }

  assignStudyToProject(){
    this.apiService.getDialogBox().subscribe((data:any) => {
      if(data.status === 200){
      this.dialog.showCustomDialog({
      component: AssignPublicStudyComponent,
      providers: [{ provide: 'data', useValue: {studyId: this.studyId} }],
      isModal: true,
      styles: {
        "height": "auto",
        "width":"600px",
        "border": "1px solid #FFFFFF33",
        "border-radius": "10px",
        "background-color": "#07102A",
        "padding": "0px"
      },
      clickOutsideToClose: true,
      enterTransitionDuration: 400,
      leaveTransitionDuration: 400,
    })}}, error => {
      if(error && error.status === 401){
        let errBody = error.error;
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
      }
    })
  }
  unlinkResorce(resource) {
    this.apiService.getDialogBox().subscribe((data:any) => {
      if(data.status === 200){
    this.dialog.showCustomDialog({
      component: UnlinkResourceComponent,
      providers: [{ provide: 'data', useValue: { action: 'unlinkStudy', studyId: this.studyId, productTitle: resource.ProvisionedProductName, ppId: resource.ProvisionedProductId } }],
      isModal: true,
      styles: {
        "height":"auto",
        "width":"520px",
        "border": "1px solid #FFFFFF33",
        "border-radius": "10px",
        "background-color": "#07102A",
        "padding": "0px"
      },
      clickOutsideToClose: true,
      enterTransitionDuration: 400,
      leaveTransitionDuration: 400,
    })}}, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    })
  }

  trimProductName(data) {
    const strLen = data.length;
    let str = data;
    if (strLen > 85) {
      str = str.slice(0, 85) + '...';
    }
    return str;
  }

  getStatusColor(status) {
    let statusV = 'status-paused';
    switch (status.toLowerCase()) {
      case 'success':
        statusV = 'status-running';
        break;
      case 'creating':
        statusV = 'status-paused';
        break;
      case 'error':
        statusV = 'status-stopped';
        break;
      case 'terminating':
          statusV = 'status-paused';
          break;
      default :
        break;
    }
    return statusV;
  }

  getStatus(status) {
    let statusV = status;
    let state = statusV.toUpperCase();
    switch (state) {
      case 'CREATING':
        statusV = 'Creating';
        break;
      case 'ERROR':
        statusV = 'Failed';
        break;
      case 'SUCCESS':
          statusV = 'Active';
          break;
      case 'TERMINATING':
          statusV = 'Deleting';
          break;
      default :
        break;
    }
    return statusV;
  }

  getEventSource(url: string): EventSource {
    return new EventSource(url, { withCredentials: true });
  }

  serverStreamEvent() {
    this.researchService.getServerSentEvent(this.eventS).subscribe(res => {
      const resObj = res.data;
      if (!resObj) {
        return;
      }
      try {
        const { status, data, payload } = JSON.parse(resObj);
        // server give handshake to the alive connection
        if (data === 'check') {
          return;
        }
        if (data === 'externalStudy') {
          this.getStudyDetails();
        }
      } catch (e) {
        console.log("Error in SES events for externalStudy", e.message);
      }
    });
  }


  getAccounts() {
    this.loader = true;
    this.fAccountIdList = [];
    this.dashboardService.getSettingsDetail('1', '999', 'created_on', 'asc', 'principal','project').subscribe(
      (res:any) => {
        this.loader = false;
        // this.accountIdList = [];
        res.settings.filter(obj => {
          if(obj.status === 'success'){
            const fObj = {
              'value': obj._id,
              'viewValue': obj.name,
              'vendor': obj.vendor,
              'projectId': obj.projectId,
              'accountNumber': obj.accountNumber,

              'checked' : false
            };
            console.log(res)
            this.fAccountIdList.push(fObj);
          }
        });

      },error=>{
        this.loader = false;
        if(error && error.status === 401){
          let errBody = error.error;
          this.toastr.error(errBody.err, '', this.globals.tosterOverride);

        }
      });
  }
}
