import { Component, Inject, OnInit } from '@angular/core';
import { MdlDialogReference } from '@angular-mdl/core';
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { ResearchService } from "../../_services/research.service";
import {Globals} from "../../globals";

@Component({
  selector: 'app-detach-volume',
  templateUrl: './detach-volume.component.html',
  styleUrls: ['./detach-volume.component.css'],
  providers: [Globals]

})
export class DetachVolumeComponent implements OnInit {

  constructor(
    @Inject('data') public data: any,
    public dialogRef: MdlDialogReference,private toastr: ToastrService,private router: Router, private researchService: ResearchService, private globals: Globals,
  ) { }
  productName;
  ppId;
  volumeObj;
  projectName;
  projectStatus;
  showSpinner: boolean = false;
  loader = false;

  ngOnInit(): void {
    if (this.data != undefined) {
      this.productName = this.data.productName;  
      this.ppId = this.data.ppId;
      this.volumeObj = this.data.volumeObj;
      this.projectName = this.data.projectName;
      this.projectStatus = this.data.projectStatu
    }
  } 
  cancel(): void {
    this.dialogRef.hide();
  }
  detachVolume(){
    let volToDetach = {
      instancePPID : this.ppId,
      volPPID  : this.volumeObj.ppId,
      device : this.volumeObj.device
    }
    let t = new Date().getTime();
    let resp = this.researchService.detachVolume(volToDetach).subscribe((res:any) => {
      this.loader = true;
      if(res.status === "success"){
        this.toastr.success(res.data.message);
        this.dialogRef.hide();
        this.router.navigate(['/product/' + this.projectName + '/' + this.productName, { ppId: this.ppId }],{ queryParams: { showTab:'productDetails', updatedData: t}});
      
      }else {
        this.dialogRef.hide();
        this.toastr.error('Detachment of Volume failed', 'Error', this.globals.tosterOverride)
      }
    }, error => {
      if(error && error.status === 401){
        let errBody= error.error.error;
         this.toastr.error(errBody, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }else {
        let errBody= error.error.error;
        this.toastr.error(errBody, '', this.globals.tosterOverride);
       } 
    });  
  }
}