<div id="popupBoxId" #AddUserAction tabindex="-1" >
  <div class="dialog-header">
    <img class="cancel-icon" tabindex="0" (click)="cancel()" (keypress)="cancel()" src="../../assets/images/cancel@2x.png" alt="Cancel"/>
    <h2 class="modal-title title-header" id="myModalLabel" class="Header">
    Update Egress Store Details
    </h2>       
  </div>    
  <div style="display: flex; flex-direction: row; margin-top: 10px;">
    <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>    
  </div>
  <form #f="ngForm" class="form-wrapper" >
    <span class="helpText">
      Enter the information about your egress store. You can find these details in the 'Outputs' section of your CloudFormation stack that created the egress store stack.
    </span>
    <div class="col-lg-6 col-md-6" style="margin-top: 10px">
      <label class="formLabel">Egress Store Bucket Arn</label>
      <span class="order-st">*</span>
      <div class="">
        <input class="form-control" aria-label="egressStoreBucketArnInputValue" id="egressStoreBucketArnId" required minlength="3"  maxlength="76" name="egressStoreBucketArn" 
          placeholder="Please enter egress store bucket Arn" style="color: #ffffff" type="text" [(ngModel)]="egressStoreBucketArn" 
          (keyup)="checkEgressStoreBucketArn();" (keydown.space)="$event.preventDefault()">
      </div>
    </div>
    <div class="col-lg-12 col-md-12">
      <div class="row">
        <div class="col-lg-6 col-md-6" style="margin-top: 10px">
          <label class="formLabel">Egress Store Kms Arn</label>
          <span class="order-st">*</span>
          <div class="">
            <input  class="form-control" aria-label="egressStoreKmsArnInputValue" id="egressStoreKmsArnId" name="egressStoreKmsArn"
              placeholder="Please enter egress store kms arn" [(ngModel)]="egressStoreKmsArn" required style="color: #ffffff" 
              type="text" (keyup)="checkEgressStoreKmsArn()" (keydown.space)="$event.preventDefault()">
          </div>
        </div>
        <div class="col-lg-6 col-md-6" style="margin-top: 10px">
          <label class="formLabel">Egress Store Bucket Name</label>
          <span class="order-st">*</span>
          <div class="">
            <input class="form-control" aria-label="egressStoreBucketNameInputValue" id="egressStoreBucketNameId" minlength="3" maxlength="63" name="egressStoreBucketName"
              [(ngModel)]="egressStoreBucketName" placeholder="Please enter egress store bucket name" required style="color: #ffffff" 
              type="text" (keyup)="checkEgressStoreBucketName();" (keydown.space)="$event.preventDefault()">
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12 col-md-12">
      <div class="row">
        <div class="col-lg-6 col-md-6" style="margin-top: 10px">
          <label class="formLabel">Egress Store Notification Bucket Name</label>
          <span class="order-st">*</span>
          <div class="">
            <input  class="form-control" aria-label="egressStoreNotificationBucketNameInputValue" id="egressStoreNotificationBucketNameId" minlength="3" maxlength="63" name="egressStoreNotificationBucketName"
              placeholder="Please enter egressStore notification bucket name" [(ngModel)]="egressStoreNotificationBucketName" required style="color: #ffffff" 
              type="text" (keyup)="checkEgressStoreNotificationBucketName();" (keydown.space)="$event.preventDefault()">
          </div>
        </div>
        <div class="col-lg-6 col-md-6" style="margin-top: 10px">
          <label class="formLabel">Egress Store Name</label>
          <span class="order-st">*</span>
          <div class="">
            <input class="form-control" aria-label="egressStoreNameInputValue" id="egressStoreNameId"  minlength="1" maxlength="255" name="egressStoreName"
              placeholder="Please enter egress store name" [(ngModel)]="egressStoreName" required style="color: #ffffff"
              type="text" (keyup)="checkEgressStoreName();" (keydown.space)="$event.preventDefault()">
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12 col-md-12">
      <div class="row">
        <div class="col-lg-6 col-md-6" style="margin-top: 10px">
          <label class="formLabel">Egress SNS Topic</label>
          <span class="order-st">*</span>
          <div class="">
            <input  class="form-control" aria-label="egressSNSTopicInputValue" id="egressSNSTopicId" minlength="1" maxlength="256" name="egressSNSTopic" 
            type="text" placeholder="Please enter egress SNS topic" [(ngModel)]="egressSNSTopic" required 
              style="color: #ffffff" (keyup)="checkEgressSNSTopic();" (keydown.space)="$event.preventDefault()">
          </div>
        </div>
        <div class="col-lg-6 col-md-6" style="margin-top: 10px">
          <label class="formLabel">Region</label>
          <span class="order-st">*</span>
          <div class="">
            <input class="form-control" aria-label="regionInputValue" id="regionId" minlength="1" name="region" 
              placeholder="Please enter region" [(ngModel)]="egressRegion" style="color: #ffffff"
              required type="text" (keyup)="checkRegion();" (keydown.space)="$event.preventDefault()">
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12 col-md-12 " style="margin-top: 20px;text-align: center; margin-bottom: 15px;">
      <button class="btn btn-primary disable-btn" *ngIf="showDisableEgressButton" id="disableButtonId" type="submit" (click)="openConformationDialog();">  Disable Egress Application </button>
      <button class="btn btn-primary sub-btn" id="submitButtonId" type="submit" [disabled]="!(f.valid && !disable)" (click)="updateEgressStoreDetails()">  Update </button>
    </div>
  </form>
</div>