import { Component, Inject,OnInit } from '@angular/core';
import { MdlDialogReference } from '@angular-mdl/core';
import { ResearchService } from "../../_services/research.service";
import { ToastrService } from 'ngx-toastr';
import { Globals } from "../../globals";
import {Router} from "@angular/router";
@Component({
  selector: 'app-instance-type',
  templateUrl: './instance-type.component.html',
  styleUrls: ['./instance-type.component.css'],
  providers: [Globals]
  
})
export class InstanceTypeComponent implements OnInit {
 instanceType:any;
 ppId:any;
 filteredInstance=[];
 instance:string;
 instanceId;
 accountId;

 
  constructor( private globals: Globals,
    public dialogRef: MdlDialogReference,
    private researchService: ResearchService,
    private toastr: ToastrService,
    private router: Router,
    @Inject('data') public data: any
    ) {}

    ngOnInit(){
      if(this.data.hasOwnProperty('ppId')){
          this.ppId = this.data.ppId;
          this.accountId=this.data.accountId;
          this.instanceId=this.data.instanceId;
        }
      this.getCurrentInstance()
    }

    getCurrentInstance() {
      this.researchService.getProvisionedProductAllowedInstanceTypes(this.ppId).subscribe((res:any)=> {
        this.filteredInstance= res.filteredInstance;
      } ,error => {
       if(error && error.status === 401){
               let errBody = error.error;
               this.toastr.error(errBody.err, '', this.globals.tosterOverride);
               this.router.navigate(['login']);
               }
               
    })
   }

    cancel() {
      this.dialogRef.hide();
    }
    updateInstanceType(){

      const values = {
        'instanceType':this.instanceType,
        'instanceId' :  this.instanceId,
        'accountId' : this.accountId,
        'ppId': this.ppId
      };
      
      this.researchService.updatedInstanceType(values).subscribe((res:any) =>{
        const resData = res.data;
        this.dialogRef.hide();
        this.toastr.success(`Updated Instance Type to ${this.instanceType} successfully`, '', this.globals.tosterOverride);
      }, error => {
        this.dialogRef.hide();
        let errBody = error.error;
        if(error && error.status === 401){
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
       }else {
        this.toastr.error('Error : ',errBody.error);
        }
      }); 
    }
 }


    
  


















