import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class DataService {
  public ITSMState:string;
  public ITSMPeriod:string;
  public updatePage = new Subject();

  constructor() {
    this.ITSMState = "p1";
    this.ITSMPeriod = "daily";
  }

  setITSMState (state) {
    this.ITSMState = state;
  }
  setITSMPeriod (period) {
    this.ITSMPeriod = period;
  }
  getITSMState () {
    return this.ITSMState;
  }
  getITSMPeriod () {
    return this.ITSMPeriod;
  }
}
