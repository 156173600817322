<div class="signup-container">
    <div class="container successMsg">
      <img class="success-icon" src="../../assets/images/icons/error@2x.png" alt="Page Not Found"/>
      <h3 class="successDataMsg">
        Page not found
      </h3>
      <h4 class="linkMsg" style="margin-top: 15px !important">
        We can’t seem to find the page you’re looking for. Try going back to the previous page or see our 
        <a target="_blank" style="color: #0039FA !important; cursor: pointer;" (click)="getHelpLink()">Help Center</a> for more information
      </h4>
      <div class="row" style="margin-bottom: 30px">
        <div class="col-xs-8">
        </div>
        <div class="col-xs-4">
          <button type="submit" class="btn btn-dark btn-block btn-flat submit-btn" (click)="goToHomePage()">Home</button>
        </div>
      </div>
    </div>
</div>
