import { Component, OnInit } from '@angular/core';
import { OrganizationService } from '../../_services/organization.service';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationEnd, ActivatedRoute } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { Globals } from "../../globals";
import { MdlDialogService } from '@angular-mdl/core';
import { AddUserComponent } from '../../dialog/add-user/add-user.component';
import { ResearchService } from "../../_services/research.service";
import { DashboardService } from '../../_services/dashboard.service';

@Component({
  selector: 'app-add-org',
  templateUrl: './add-org.component.html',
  styleUrls: ['./add-org.component.css', '../../principal/add-project/add-project.component.css'],
  providers: [DashboardService,Globals]
})
export class AddOrgComponent implements OnInit {
  fAccountIdList = [];
  principalIdList = [];
  accountIdList = [];
  accountIdArr = [];
  idArr = [];
  organizationData = [];
  accountIdname = [];
  accountNames: string;
  viewAccountIdname: string;
  organizationName: string = '';
  organizationDescription;
  principalIds: any = [];
  accountIds = [];
  accountListFound;
  loader;
  gotAccount = false;
  gotPIList;
  currentUrl;
  fromWhichPage;
  showSpinner;
  helpTextData;
  sectionWiseHelpdata;
  cur: number = 0;
  curx: number = 0;
  defaultClass = 'listValue';
  filedType;
  linkedActiveDirectoryGroup;
  loginStrategy;
  showAddUser = true;
  showLinkedExternalGroup = false;
  isDefaultOU = false;
  constructor(private orgService: OrganizationService,public route: Router,private router: ActivatedRoute,
    private toastr: ToastrService,
    private globals: Globals,private dialog: MdlDialogService,
    private serviceApi: ResearchService,private dashboardService: DashboardService,) {
    this.route.routeReuseStrategy.shouldReuseRoute = () => false;
   }

  ngOnInit() {
    this.getOrganizationListmethod();
    this.getPrincipalListmethod();
    this.currentUrl = this.route.url;
    this.loginStrategy = sessionStorage.getItem('strategy');
    if (this.loginStrategy === 'saml' || this.loginStrategy === 'azure-ad-oidc') {
      this.showAddUser = false;
      this.showLinkedExternalGroup = true;
    }
    this.fromWhichPage = this.router.snapshot.params['from'];
    if(this.fromWhichPage == 'addUser' || this.fromWhichPage == 'fromSettings'){
      this.currentUrl = this.route.url.split('?')[0] ;
    }
    if(this.currentUrl == '/addOrganization/addUser' || this.currentUrl == '/addOrganization/fromSettings'){
      if((sessionStorage.getItem('organizationName') !=='null') && (sessionStorage.getItem('organizationName') !=='undefined') && (sessionStorage.getItem('organizationName') !== '')){
        this.organizationName = sessionStorage.getItem('organizationName');
      }
      if((sessionStorage.getItem('organizationDescription') !=='null') && (sessionStorage.getItem('organizationDescription') !=='undefined') && (sessionStorage.getItem('organizationDescription') !== '')){
        this.organizationDescription = sessionStorage.getItem('organizationDescription');
      }
    }else{
      sessionStorage.removeItem('organizationName');
      sessionStorage.removeItem('organizationDescription');
      this.organizationName = '';
      this.organizationDescription = '';
    }
    this.getAllHelptextdata('all', 'create_org_helpText');
  }

  //Get Principal User List
  private getPrincipalListmethod() {
    this.gotPIList = false;
    this.orgService.getPrincipalList().subscribe((res:any) => {
      //this.loader = false;
      this.gotPIList = true;
      this.principalIdList = res.map(obj => {
        const fObj = {
          'value': obj.username,
          'viewValue': `${obj.fullName} (${obj.email})`,
          'disabled': false,
          'org_id': obj.org_id
        };
        return fObj;
      });
    }, error =>{
      this.gotPIList = true;
    });
  }

    //Get Org Data
    private getOrganizationListmethod() {
      this.loader = true;
      this.orgService.getOrganizationsList().subscribe((res:any) => {
        this.organizationData = res;
        for (let i = 0; i < this.organizationData.length; i++) {
          this.accountIdArr.push(...this.organizationData[i].account_id);
        }
        this.viewAccountIdname = this.accountIdname.toString();
        this.getAccountlistMethod();
      }, error=>{
        if(error && error.status === 401){
          let errBody = error.error;
           this.toastr.error(errBody.err, '', this.globals.tosterOverride);
           this.route.navigate(['login']);
         }else if (error && error.status === 403){
          const err = error.error;
          this.toastr.error(err.error, 'Error', this.globals.tosterOverride);
          this.route.navigate(['pageNotFound']);
        }
      });
  
    }

  //Get Account List
  private getAccountlistMethod() {
    this.loader = true;
    this.fAccountIdList = [];
    this.accountIdList = [];
    this.orgService.getAccountList().subscribe((res:any) => {
      this.accountIdList = res.map(obj => {
        const fObj = {
          'value': obj._id,
          'viewValue': obj.name
        };
        return fObj;
      });

      for (let i = 0; i < this.accountIdList.length; i++) {
        let data = this.accountIdArr.some(element => element == this.accountIdList[i].value);
        if (!data) {
          this.fAccountIdList.push(this.accountIdList[i]);
          this.accountListFound = true;
        }
      }

      try {
        let accountNameArray = [];
        this.idArr.forEach(account => {
          const found = this.accountIdList.find(element => element.value == account);
          accountNameArray.push(found.viewValue);
        });
        this.accountNames = accountNameArray.toString().replace(/,/g, ", ");
      } catch (error) {

      }
      if (this.fAccountIdList.length == 0) {
        this.accountListFound = false;
      }
      if(this.fAccountIdList.length == 1){
        this.fAccountIdList[0].checked = true;
        this.accountIds.push(this.fAccountIdList[0].value);
        this.gotAccount = true;
      }
      this.loader = false;
    },error=>{
      this.loader = false;
    });
  }

  //create Org
  addOrganization() {
    this.showSpinner = true;
    const ids = this.principalIds.map(str => {
      const strArr = str.split("/");
      return strArr[0];
    });
    const formObj:any = {
      'org_name': this.organizationName,
      'org_id': ids,
      'description': this.organizationDescription,
      'account_id': this.accountIds,
      'principal': this.principalIds,
      'img_src': "https://upload.wikimedia.org/wikipedia/commons/thumb/8/80/DNA_icon.svg/1200px-DNA_icon.svg.png" 
    };
    if(this.showLinkedExternalGroup){
      formObj.linkedActiveDirectoryGroup = this.linkedActiveDirectoryGroup;
      formObj.isDefaultOU = this.isDefaultOU;
    }
    this.orgService.addOrganization(formObj).subscribe(
      (res:any) => {
        this.showSpinner = false;
        if (res.status === 'Success') {
          //On successful addition of org, remove the session data of org
          sessionStorage.removeItem('organizationName');
          sessionStorage.removeItem('organizationDescription');

          this.route.navigate(['/admin']);
          this.toastr.success('', 'Organization saved successfully', this.globals.tosterOverride);
        } else if (res.status === 'Failed') {
          this.toastr.error('', res.message, this.globals.tosterOverride);
        }
      }, error=>{
        this.showSpinner = false;
        let errBody = error.error;
        if(error && error.status === 401){
          this.toastr.error(errBody.err, '', this.globals.tosterOverride);
          this.route.navigate(['login']);
         }else{
          let errMsg = errBody.err ? errBody.err : error.err;
          this.toastr.error('', errMsg, this.globals.tosterOverride);
         }
      });
  }


  highlightHelpText(projectData, fieldValue){
    this.filedType = fieldValue;
    let dataLeng;
    if(projectData !=null || projectData!=undefined){
      dataLeng = projectData.length;
      this.defaultClass = 'listValue';
    }
    if(dataLeng == 0){
      this.defaultClass = 'listValue';
    }
  }


  accordianCtrlNodeX(j) {
    this.cur = 999;
    if (this.curx == j)
      return this.curx = 999;
    else return this.curx = j;
  }

  accordianCtrlNode(j) {
    this.curx = 999;
    if (this.cur == j)
      return this.cur = 999;
    else return this.cur = j;
  }

  getAllHelptextdata(key, configType){
    this.serviceApi.getAddAccountHelpText(key, 'create_org_helpText').subscribe(res => {
      this.helpTextData = res[0].helpText;
 }, error => {
  if(error && error.status === 401){
    let errBody = error.error;
     this.toastr.error(errBody.err, '', this.globals.tosterOverride);
     this.route.navigate(['login']);
   }
})
  }

  getSectionWiseHelp(key){
    this.getAllHelptextdata('all', 'create_org_helpText')
    this.serviceApi.getAddAccountHelpText(key, 'create_org_helpText').subscribe(res => {
      this.sectionWiseHelpdata = res[0].helpText;
      const idToRemove = res[0].helpText[0].key;
      this.helpTextData = this.helpTextData.filter((item) => item.key !== idToRemove);

    }, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.route.navigate(['login']);
       }
})
  }

  onFocus(keyValue){
    this.cur = 999;
    this.curx = 0;
    this.getSectionWiseHelp(keyValue)
  }

  //Add Account
  addAccounts(){
    sessionStorage.setItem('organizationName', this.organizationName);
    sessionStorage.setItem('organizationDescription', this.organizationDescription);
    sessionStorage.setItem('accountType','project');
    this.route.navigate(['/addAccount']);
  }

  selectAccountIds(acc, event) {
    if(event.target.checked){
      this.accountIds.push(acc.value);
      this.gotAccount = true;
    }else {
      this.accountIds = this.accountIds.filter((item) => item !== acc.value)
      if(this.accountIds.length == 0){
        this.gotAccount = false;
      }
    }
  }

  selectDefaultOu(event){
    if(event.target.checked){
      this.isDefaultOU = true;
    }else {
      this.isDefaultOU = false;
    }
  }

  //Create PI user
  addUser(){
    sessionStorage.setItem('organizationName', this.organizationName);
    sessionStorage.setItem('organizationDescription', this.organizationDescription);
    this.dashboardService.getDialogBox().subscribe((data:any) => {
      if(data.status === 200){
    this.dialog.showCustomDialog({
      component: AddUserComponent,
      providers: [{ provide: 'data', useValue: {action: 'addUserFromOrgPage'} }],
      isModal: true,
      styles: {
        "height": "auto",
        "overflow": "auto",
        "width":"400px",
        "border": "1px solid #FFFFFF33",
        "border-radius": "10px",
        "background-color": "#07102A",
        "padding": "0px",
    //     "overflow-x": "hidden",
    // "overflow-y": "scroll"
      },
      clickOutsideToClose: true,
      enterTransitionDuration: 400,
      leaveTransitionDuration: 400,
    })}}, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.route.navigate(['login']);
       }
    })
  }

  //Select PI 
  selectPrincipalIds(p, event) {
    if(event.target.checked){
      this.principalIds.push(p.value)
    }else {
      this.principalIds = this.principalIds.filter((item) => {
        item !== p.value
      })
    }

    if (this.principalIds && this.principalIds.length > 0){
      //const org = this.principalIds[0].split('/')[0];
      this.principalIdList.forEach(item => {
        const principalOrg = item.org_id;
        if(!principalOrg){
          item.disabled = false;
        }else {
          item.disabled = true;
        }
      })
    }

    if(this.principalIds.length === 0) {
      this.principalIdList.forEach(item => {
        item.disabled = false;
      });
    }
  }

}
