import { Component, OnInit } from '@angular/core';
import { MdlDialogService } from '@angular-mdl/core';
import { ResearchService } from "../_services/research.service";
import { OrganizationService } from "../_services/organization.service";
import { ProductUtilService } from "../_services/productUtil.service";
import { ToastrService } from 'ngx-toastr';
import { Globals } from "../globals";
import { ActivatedRoute, Router, Event, NavigationEnd } from "@angular/router";

import { AssignCatalogComponent } from '../catalog-management/assign-catalog/assign-catalog.component';
import { DashboardService } from "../_services/dashboard.service";

@Component({
  selector: 'app-catalog-management',
  templateUrl: './catalog-management.component.html',
  styleUrls: ['./catalog-management.component.css'],
  providers: [DashboardService,Globals]
})
export class CatalogManagementComponent implements OnInit {

  checkBoxValue: any = [];
  disableAssign = true;
  availableProductsToShow = [];
  showSpinner = true;
  orgList = [];
  categoryFilter = "Standard Catalog";
  selectedFilter = "All"
  OrgCatalogs = [];
  filteredData;
  catalogCount = 0;
  productIds = [];
  productNames = [];
  orgId;
  assignStatus;
  parametersObservable: any;
  productTypes;
  searchKey;
  searchValueTemp = '';
  searchExecKey = '';
  searchValid = false;
  noDataTemplate = ""

  constructor(private dialog: MdlDialogService, private catalogService: ResearchService,
    private orgService: OrganizationService,
    private productUtil: ProductUtilService,
    private toastr: ToastrService,
    private globals: Globals,
    private activatedRoute: ActivatedRoute,
    private route: Router,
    private apiService:DashboardService) {
    this.route.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (event['url'] === '/admin/catalog?assignedProduct=success') {
          this.route.navigate(['/admin/catalog']);
          this.getCatalogItems(this.orgId);
        }
      }
    });
  }

  ngOnInit() {

    document.querySelector('.custom-select-wrapper').addEventListener('click', function () {
      this.querySelector('.custom-select').classList.toggle('open');
    })
    window.addEventListener('click', function (e) {
      const select = document.querySelector('.custom-select')
      if (select && !select.contains(e.target as Node)) {
        select.classList.remove('open');
      }
    });


    // mobile responsive dropdown
    document.querySelector('.mobile-custom-select-wrapper').addEventListener('click', function () {
      this.querySelector('.mobile-custom-select').classList.toggle('open');
    })
    window.addEventListener('click', function (e) {
      const select = document.querySelector('.mobile-custom-select')
      if (select && !select.contains(e.target as Node)) {
        select.classList.remove('open');
      }
    });

    this.parametersObservable = this.activatedRoute.params.subscribe(params => {
      this.getCatalogItems('all');
      this.getOrgList();
      this.getProductTypes();
    })
  }

  onCheckboxChanged(event, value) {
    if (event.target.checked) {
      this.checkBoxValue.push(value);
      if (this.checkBoxValue.length > 0) {
        this.disableAssign = false;
      }
    }
    if (!event.target.checked) {
      let index = this.checkBoxValue.indexOf(value);

      if (index > -1) {

        this.checkBoxValue.splice(index, 1);
      }

      if (this.checkBoxValue.length == 0) {
        this.disableAssign = true;
      }

    }
  }

  assignToOrg() {
    this.apiService.getDialogBox().subscribe((data:any) => {
      if(data.status === 200){
    this.dialog.showCustomDialog({
      component: AssignCatalogComponent,
      providers: [{ provide: 'data', useValue: { assignData: this.checkBoxValue, assignType: 'Organization' } }],
      isModal: true,
      styles: {
        "height": "445px",
        "width": "450px",
        "border": "1px solid #FFFFFF33",
        "border-radius": "10px",
        "background-color": "#07102A",
        "padding": "0px"
      },
      clickOutsideToClose: true,
      enterTransitionDuration: 400,
      leaveTransitionDuration: 400,
    })}}, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.route.navigate(['login']);
       }
    })
  }

  updateToOrg() {
    this.productIds = [];
    this.getProductIds(this.checkBoxValue);
    let assignData = {};
    assignData['productIds'] = this.productIds;
    assignData['orgId'] = this.orgId;
    assignData['productNames'] = this.productNames;
    assignData['orgName'] = this.categoryFilter;
    assignData['type'] = 'update';
    this.catalogService.assignProductsToOU(assignData).subscribe(res => {
      if (res) {
        this.toastr.success(``, `Successfully updated products to ${this.categoryFilter} organization`, this.globals.tosterOverride);
        //this.route.navigate(['/admin/catalog'], { queryParams: { assignedProduct: 'success' } })
        this.onChangeFilter(this.selectedFilter);
      }
    }, error => {
      this.showSpinner = false;
      console.error(error);
      let errBody = error.error;
      if(error && error.status === 401){
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.route.navigate(['login']);
      }else{
     this.toastr.error(errBody.error, 'Error in updating products to OU ', this.globals.tosterOverride)
      }
    });
  }

  getCatalogItems(orgId) {
    this.showSpinner = true;
    this.orgId = orgId;
    this.disableAssign = true;
    this.checkBoxValue = [];
    this.searchKey = '';
    if (orgId == 'all') {
      this.selectedFilter = 'All';
      this.getAllCatalogItems(orgId);
    } else {
      this.selectedFilter = 'All';
      this.getOrgSpecificCatalogs(orgId);
    }
  }

  getOrgList() {
    this.orgService.getOrganizationsList().subscribe((res:any) => {
      if (res) {
        this.orgList = res;
      }
    }, error => {
      this.showSpinner = false;
      console.error(error);
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.route.navigate(['login']);
       }else if (error && error.status === 403){
        const err = error.error;
        this.toastr.error(err.error, 'Error', this.globals.tosterOverride);
        this.route.navigate(['pageNotFound']);
    }
    });
  }

  openKnowMore(detailsLink) {
    if (detailsLink) {
      window.open(detailsLink, '_blank');
    } else {
      return
      // window.open("#", '_blank');
    }
  }

  getAllCatalogItems(orgId) {
    this.noDataTemplate = "There are no items configured in the Standard Catalog. Please contact our support team at rlc.support@relevancelab.com"
    this.showSpinner = true;
    this.availableProductsToShow = [];
    this.catalogService.getCatalogItems(orgId).subscribe((res:any) => {
      if (res) {
        this.showSpinner = false;
        this.availableProductsToShow = res;
        this.catalogCount = res.length;
        for (var i = 0; i < this.availableProductsToShow.length; i++) {
          this.availableProductsToShow[i].selected = false;
          this.availableProductsToShow[i]['imageUrl'] = '/assets/images/aws_icon/aws.png';
          if (this.availableProductsToShow[i].tags.length > 0) {
            const serviceName = this.productUtil.getServiceFromTags(this.availableProductsToShow[i].tags);
            const getImgLinkObj = this.productUtil.getImageForProduct(serviceName);

            if (getImgLinkObj) {
              this.availableProductsToShow[i]['imageUrl'] = getImgLinkObj['url'];
            }
            const viewMoreDetails = this.availableProductsToShow[i].tags.filter(tag => {
              return tag["Key"] === "DetailsLink"
            })
            if (viewMoreDetails.length > 0) {
              const viewMoreLink = viewMoreDetails[0].Value;
              this.availableProductsToShow[i]["detailsLink"] = viewMoreLink
            }
          }
        }
      }
    }, error => {
      this.showSpinner = false;
      console.error(error);
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.route.navigate(['login']);
       }
     else if(error && error.status === 403){
        const err = error.error;
        this.toastr.error(err.error, 'Error', this.globals.tosterOverride);
        this.route.navigate(['pageNotFound']);
      }else {
        let err = error.error;
        this.toastr.error(err.error, 'Error', this.globals.tosterOverride);
      }
    });
  }

  getOrgSpecificCatalogs(orgId) {
    this.showSpinner = true;
    this.catalogService.getCatalogItems(orgId).subscribe((res:any) => {
      if (res) {
        this.showSpinner = false;
        this.availableProductsToShow = res;
        this.catalogCount = 0;
        this.availableProductsToShow.forEach(item => {
          if (item.isSelectedOUAssigned) {
            item.selected = true;
            item.isDisabled = true;
            this.catalogCount++;
          } else {
            item.selected = false;
            item.isDisabled = false;
          }

        });
        for (var i = 0; i < this.availableProductsToShow.length; i++) {
          this.availableProductsToShow[i]['imageUrl'] = '/assets/images/aws_icon/aws.png';
          if (this.availableProductsToShow[i].tags.length > 0) {
            const serviceName = this.productUtil.getServiceFromTags(this.availableProductsToShow[i].tags);
            const getImgLinkObj = this.productUtil.getImageForProduct(serviceName);

            if (getImgLinkObj) {
              this.availableProductsToShow[i]['imageUrl'] = getImgLinkObj['url'];
            }
            const viewMoreDetails = this.availableProductsToShow[i].tags.filter(tag => {
              return tag["Key"] === "DetailsLink"
            })
            if (viewMoreDetails.length > 0) {
              const viewMoreLink = viewMoreDetails[0].Value;
              this.availableProductsToShow[i]["detailsLink"] = viewMoreLink
            }
          }
        }
      }
    }, error => {
      this.showSpinner = false;
      let err = error.error;
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.route.navigate(['login']);
       }
      if(error && error.status === 403){
        const err = error.error;
        this.toastr.error(err.error, 'Error', this.globals.tosterOverride);
        this.route.navigate(['pageNotFound']);
      }
    });
  }

  getProductIds(orgData) {
    for (var i = 0; i < orgData.length; i++) {
      this.productIds.push(orgData[i]._id);
      this.productNames.push(orgData[i].name);
    }
  }

  getProductTypes() {
    this.catalogService.listProductType().subscribe(res => {
      this.productTypes = res;
    }, error => {
      console.error(error);
      let errBody = error.error;
      if(error && error.status === 401){
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.route.navigate(['login']);
       }else {
        this.toastr.error(errBody.error, '', this.globals.tosterOverride);
       }
      })
  }

  onChangeFilter(filter) {
    this.showSpinner = true;
    this.selectedFilter = filter;
    this.noDataTemplate = "We could not find any products that matched your search."
    this.disableAssign = true;
    this.checkBoxValue = [];
    this.catalogService.filterCatalogItems(filter, this.orgId).subscribe((res:any) => {
      this.showSpinner = false;
      this.availableProductsToShow = res;
      this.catalogCount = res.length;
      this.infoMapping();
    }, error => {
      console.error(error);
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.route.navigate(['login']);
       }
    }
    )
  }

  searchCatalogItems() {
    const searchKey = this.searchKey.trim() || '';
    if (searchKey.length >= 3 && this.searchExecKey !== searchKey)  {
      this.searchExecKey = searchKey;
      this.searchValid = true;
      this.catalogService.searchCatalogItems(this.searchKey, this.selectedFilter, this.orgId).subscribe((res:any) => {
        this.noDataTemplate = "We could not find any products that matched your search."
        this.availableProductsToShow = res;
        this.catalogCount = res.length;
        this.infoMapping();
      }, error => {
        console.error(error);
        let err = error.error;
        this.availableProductsToShow = [];
        if(error && error.status === 401){
          let errBody = error.error;
           this.toastr.error(errBody.err, '', this.globals.tosterOverride);
           this.route.navigate(['login']);
         }
      })
    } else if (searchKey.length === 0 && this.searchExecKey !== searchKey) {
      this.searchExecKey = searchKey;
      this.searchValid = true;
      this.onChangeFilter(this.selectedFilter)
    } else {
      this.searchValid = false;
    }
  }

  orgName(data) {
    const strLen = data.length;
    let str = data;
    if (strLen > 18) {
      str = str.slice(0, 17) + '...';
    }
    return str;
  }

  getOrgName(data) {
    const strLen = data.length;
    let str = data;
    if (strLen > 25) {
      str = str.slice(0, 24) + '...';
    }
    return str;
  }

  getOrgNameMobile(data) {
    const strLen = data.length;
    let str = data;
    if (strLen > 22) {
      str = str.slice(0, 21) + '...';
    }
    return str;
  }

  getOrgNameTab(data){
    const strLen = data.length;
    let str = data;
    if (strLen > 13) {
      str = str.slice(0, 12) + '...';
    }
    return str;
  }

  /* This function is used to get product icons and know more link*/
  infoMapping() {
    for (var i = 0; i < this.availableProductsToShow.length; i++) {
      if (this.availableProductsToShow[i].isSelectedOUAssigned) {
        this.availableProductsToShow[i].selected = true;
        this.availableProductsToShow[i].isDisabled = true;
      } else {
        this.availableProductsToShow[i].selected = false;
        this.availableProductsToShow[i].isDisabled = false;
      }
      this.availableProductsToShow[i]['imageUrl'] = '/assets/images/aws_icon/aws.png';
      if (this.availableProductsToShow[i].tags.length > 0) {
        const serviceName = this.productUtil.getServiceFromTags(this.availableProductsToShow[i].tags);
        const getImgLinkObj = this.productUtil.getImageForProduct(serviceName);

        if (getImgLinkObj) {
          this.availableProductsToShow[i]['imageUrl'] = getImgLinkObj['url'];
        }
        const viewMoreDetails = this.availableProductsToShow[i].tags.filter(tag => {
          return tag["Key"] === "DetailsLink"
        })
        if (viewMoreDetails.length > 0) {
          const viewMoreLink = viewMoreDetails[0].Value;
          this.availableProductsToShow[i]["detailsLink"] = viewMoreLink
        }
      }
    }
  }

  getDescription(data) {
    const strLen = data.length;
    let str = data;
    if (strLen > 135) {
      str = str.slice(0, 134) + '...';
    }
    return str;
  }

  getFilterName(data){
    if (!data) {
      return data;
    }
    const getLen = data.length;
    if (getLen > 10) {
      data = data.slice(0, 10) + '...';
    }
    return data;
  }

}
