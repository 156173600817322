import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { DashboardService } from "../_services/dashboard.service";
import {Globals} from "../globals";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.css'],
  providers: [Globals]
})
export class DefaultComponent implements OnInit {

  constructor(
    private router: Router,
    private apiService: DashboardService,
    private globals: Globals,private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.checkSAMLRouting();
  }

  checkSAMLRouting(){
    this.apiService.getLoginStrategy().subscribe((data:any) =>{
        this.apiService.getSAMLUser().subscribe((current:any) => {
            if (!current){
              if(data.strategy === 'saml') {
                console.log("Routing to saml");
                window.location.href =  window.location.origin + '/saml';
              }else{
                this.router.navigate(['login']);
              }
            }else{
              this.router.navigate(['login']);
            }
        }, error => {
          console.log(error);
          if(error && error.status === 401){
            let errBody = error.error;
             this.toastr.error(errBody.err, '', this.globals.tosterOverride);
             this.router.navigate(['login']);
           }else if(data.strategy === 'saml') {
            console.log("Routing to saml");
            window.location.href =  window.location.origin + '/saml';
          }else{
            this.router.navigate(['login']);
          }
        });
    },error => {
      console.log(error);
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }});
  }
}
