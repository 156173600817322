import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DashboardService } from '../_services/dashboard.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(
    private router: Router,
    private route : ActivatedRoute,
    private apiService: DashboardService,
  ) { }
  checkUnSubscribeUser = false;
  checkUnassignedUser = false;
  subscriptionExpried;
  
  ngOnInit() {
    let level = sessionStorage.getItem("Level");
    this.subscriptionExpried =  this.route.snapshot.queryParams['subscriptionExpired'];
    if(this.subscriptionExpried == "true"){
      this.checkUnSubscribeUser = true;    
    } else if(level) {
      let org_id = sessionStorage.getItem("org_id");
      let subscriptionDate = sessionStorage.getItem("subscriptionRenewalDate")
      const date = new Date();       
      const todayDate = date.toISOString(); 
      if((org_id == "" || org_id == null || org_id == undefined) && (level == "1" || level == "0")) {
        this.checkUnassignedUser = true;
        this.apiService.logoutForDashboard({}).subscribe(data => {
          sessionStorage.removeItem('currentUser');
          sessionStorage.removeItem("currentUserEmail");
          sessionStorage.removeItem("org_id");
          sessionStorage.removeItem('subscriptionRenewalDate');      
          sessionStorage.removeItem('Level');       
          sessionStorage.removeItem('roles');
          sessionStorage.removeItem('displayName');   
          sessionStorage.removeItem("5b1647bf469e0b0cf9dcacca");
          sessionStorage.removeItem('tenantid');
          sessionStorage.removeItem('landlordUser');   
        });   
      }else if(todayDate > subscriptionDate && (level == "0" || level == "1")) {
        this.checkUnSubscribeUser = true;    
        this.apiService.logoutForDashboard({}).subscribe(data => {
          sessionStorage.removeItem('currentUser');
          sessionStorage.removeItem('tenantid');
          sessionStorage.removeItem("5b1647bf469e0b0cf9dcacca");
          sessionStorage.removeItem("landlordUser");
          sessionStorage.removeItem("currentUserEmail");
          sessionStorage.removeItem("org_id");
          sessionStorage.removeItem('subscriptionRenewalDate');      
          sessionStorage.removeItem('Level');       
          sessionStorage.removeItem('roles');
          sessionStorage.removeItem('displayName');      
        });   
      }    
  }else {
    this.router.navigate(['login']);
  }
}

  goToLoginPage(){
    this.router.navigate(['default']);
  }
}
