import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Router } from "@angular/router";
import { ResearchService} from "../../../_services/research.service";
import { DashboardService } from '../../../_services/dashboard.service';
import {Globals} from "../../../globals";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-budget-product',
  templateUrl: './budget-product.component.html',
  styleUrls: ['./budget-product.component.css', '../budget-details.component.css', '../budget-tracking.component.css', '../../../app.component.css'],
  providers: [Globals]
})
export class BudgetProductComponent implements OnInit {

  researcherDetails;
  projectDetails;
  projectId;
  budgetAllotted : any = 0;
  budgetConsumed : any = 0;
  budgetAvailable : any = 0;
  currencySymbol: string = '$';
  products = [];
  loader = false;
  private sub: any;
  researchers = [];
  researcherBudgetConsumed : any = 0;
  currentMonthCostByResearcher : any = 0;
  researcher;
  projectName;
  displayName;

  constructor(private researchService: ResearchService, private route: ActivatedRoute, private location: Location, private router: Router, 
    private globals: Globals,
    private apiService: DashboardService,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.apiService.checkIfPI();
    this.sub = this.route.params.subscribe(params => {
      this.researcher =  this.route.snapshot.queryParams['researcher'];
      this.displayName =  this.route.snapshot.queryParams['displayName'];
      // this.projectDetails = JSON.parse(params['project']);
      this.projectId = this.route.snapshot.params['id']
      //this.budgetAllotted = Math.round(this.researcherDetails.budgetAllocated);
      this.getProductsBudget();
      this.getProjects();
      this.getResearcherList(this.researcher);
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  goBack() {
    this.location.back();
  }

  researcherName(researcher) {
    // let splitName = name.split('/');
    if ('displayName' in researcher) {
      return researcher.displayName;
    }
    return researcher.name;
  }

  getProductsBudget() {
    this.loader = true;
    const data = {
      projectId:this.projectId,
      researcherName:this.researcher
    }
    this.researchService.getProductBudget(data).subscribe((data:any) => {
      this.loader = false;
      this.products = data;
    }, error => {
      this.loader = false;
      console.log(error)
    });
  }

  getResearcherList(researcherName) {
    this.loader = true;
    this.researchService.getResearcherListFromProjectId(this.projectId).subscribe((data:any) => {
      this.loader = false;
      this.researchers = data;
      this.researchers.forEach(item => {
        if(item.name === researcherName){
          this.budgetAllotted = parseFloat(item.budgetAllocated).toFixed(2);
          this.researcherBudgetConsumed = parseFloat(item.budgetConsumed).toFixed(2);
          this.currentMonthCostByResearcher = parseFloat(item.monthlyBudgetCosumedByResearcher).toFixed(2);
        }
      })
      // this.loader = false;
    }, error => {
      this.loader = false;
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    });
  }

  openProjectDetails() {
    this.router.navigate(['/principal/project/'+ this.projectId+'/details']);
  }

  getProjects() {
    this.researchService.getPrincipalProjectsUsingId(this.projectId).subscribe(data => {
      this.projectName = data[0].name;
    }, error => {
      this.loader = false; 
       if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }else if (error && error.status === 403) {
          const err = error.error;
          this.toastr.error(err.error, 'Error', this.globals.tosterOverride);
          this.router.navigate(['pageNotFound']);
      }
    });
  }

}
