import { Injectable } from '@angular/core';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/do';
// import { HttpInterceptor } from './httpInterceptor.service';
import { HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

const headers = new HttpHeaders();
headers.append('Content-Type', 'application/json');
const options = { headers: headers };

@Injectable()
export class ServiceActionsService {

  constructor(private httpclient: HttpClient) { }

  serviceAction(data) {
    return this.httpclient.post(`/actions`, data);
  }

  serviceConnect(data) {
    return this.httpclient.post(`/connect`, data);
  }

  productOutputSync(data){
    return this.httpclient.post(`/activeProductOutput`, data);
  }

  getActionsMenuForService(data) {
    return this.httpclient.get(`/actions/${data.id}`, options);

  }

  getS3FileSizeMaxLimit() {
    return this.httpclient.get(`/getS3MaxFileSize`, options);

  }

  fileUpload(imageForm: FormData, accountId, projectName, bucketName): Observable<any> {
  return this.httpclient.post(`/api/v1/upload/${accountId}/${projectName}/${bucketName}`, imageForm, {
    reportProgress: true,
    observe: 'events'});
  }

  folderUploadToS3(imageForm: FormData, accountId, projectName, bucketName): Observable<any> {
    return this.httpclient.post(`/uploadFolderToS3/${accountId}/${projectName}/${bucketName}`, imageForm, {
      reportProgress: true,
      observe: 'events'
    });
    }

  //list s3Data folders and files data
  getS3Data(data) {
    return this.httpclient.put(`/getS3Data`, data);
  }

   //list s3Data root folders and files
   getS3Rootfolder(bucketName, rootFolder, account_id, page, limit, next) {
    return this.httpclient.get(`/getS3Data?bucketName=${bucketName}&rootFolder=${rootFolder}&account_id=${account_id}&page=${page}&limit=${limit}&next=${next}`, options);
  }

 //check s3 bucket for files/folders
 checkS3Bucket(bucketName, account_id) {
  return this.httpclient.get(`/checkS3Bucket?bucketName=${bucketName}&account_id=${account_id}`, options);
}  

  //search files in s3 bucket
  searchFilesInS3(bucketName, searchKey, folderName, account_id, page, limit, projectId){
    return this.httpclient.get(`/getS3Data/${searchKey}?folderName=${folderName}&bucketName=${bucketName}&account_id=${account_id}&page=${page}&limit=${limit}&projectId=${projectId}`, options);
  }

  //delete multiple files in s3 bucket
  deleteMultipleFilesInS3(bucketArrayData, account_id ,projectId,ppId){
    return this.httpclient.put(`/deleteMultipleS3Data?account_id=${account_id}&projectId=${projectId}&ppId=${ppId}`, bucketArrayData);
  }

  deleteKeypair(data) {
    return this.httpclient.post(`/researchProducts/deleteKeypair`, data);
  }

  getS3BucketData(data){
    return this.httpclient.put(`/studies/explore`, data);
  }

  getS3PPId(s3BucketName) {
    return this.httpclient.get(`/researchProducts/getPPId/${s3BucketName}`, options); 
  }

  getPclusterPublicIP(ClusterName,PPID){
    let resp = this.httpclient.get(`/getPclusterHeadNodePublicIP/${ClusterName}/${PPID}`, options);
    return resp;
  }
  getPclusterDcvConnectionUrl(PPID,headNodeInstanceId,headNodePublicIP){
    let resp = this.httpclient.get(`/getDcvUrl/${PPID}/${headNodeInstanceId}/${headNodePublicIP}`);
    return resp;
  }

}
