<div class='projectDetails' *ngIf="!showSpinner">
  <div class="dialog-header">
    <img class="cancel-icon" (click)="cancel()" src="../../assets/images/cancel@2x.png" alt="Cancel"/>
    <h2 class="header">
      {{ settingName }}
    </h2>
  </div>
  <div style="display: flex; flex-direction: row; margin-top: 10px;">
    <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
  </div>
  <div  class="table-responsive md-layout lg-layout project-list" style="margin-bottom: 20px; margin-bottom: -4px; padding: 6px; height:300px;">
    <table class = "table table-striped" style="width: 100%; margin-bottom: unset">
           <thead style="background-color: #15244B">
           <tr>
             <th class="tableHeader" style="width: 25%" >Project Name</th>
             <th class="tableHeader" style="width: 25%"  >Created On</th>
             <th class="tableHeader" style="width: 39%" >Project Owner</th>
           </tr>
           </thead>
           <tbody style="background-color: #060d24;">
            <tr style="background-color:inherit" *ngFor="let project of projects">
              <td class="tableData" style="width: 25%;font-size: 14px" >{{ project.name }}</td>
              <td class="tableData" style="width: 25%;font-size: 14px" > {{ convertDateToString(project.created_on)}}</td>
              <td class="tableData" style="width: 39%;font-size: 14px" >{{ project.created_by }}</td>
              </tr>
            </tbody>
    </table>
</div>
</div>
<app-loader class="spinner" *ngIf="showSpinner"></app-loader>

