
<div #projectActionWindow tabindex="-1">
  <div class="dialog-header">
      <img class="cancel-icon" (click)="cancel()" src="../../assets/images/cancel@2x.png" alt="Cancel"/>
    <h3 class="title">Archive project <h3 class="content-2">"{{projectName}}"</h3></h3>
  </div>
  <div style="display: flex; flex-direction: row; margin-top: 0px;">
    <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
  </div>
  <div class="content">
    <h2 class="content-1">Do you want to archive the  project <h3 class="content-2"> "{{ projectName}}"</h3> which is linked to the account <h3 class="content-2">"{{accountName}}"</h3></h2>
  </div>
  <div class="warningData">
    <h2 class="war-msg">Archiving a project delinks the project from the account. Any resources created in the project will continue to run and may incur charges.</h2>
  </div>
  <!-- <div class="warning">
      <h2 class="delete-msg"> This operation cannot be undone. </h2>
      <p style="color: white">Choosing this option will only remove the association of Research Gateway from your AWS account. All the services and resources used via Research Gateway will keep running and consume the budget.</p>
  </div> -->
  <div class="content">
    <input aria-label="conformationCheckboxForArchiveProject" type="checkbox" name="confirm" id="1" (change)="confirmDeleteBox()">
    <label style="color:white; font-size: 15px; margin-left: 5px;">Are you sure you want to archive project?</label>
  </div>
  <div class="confirmPs">
    <input aria-label="conformationCheckboxForDeleteIngressProjectStorage" *ngIf="storageType === 'ingress'" type="checkbox" name="confirmProjectStorage" id="confirmProjectStorage" checked [disabled]="true" >
    <input aria-label="conformationCheckboxForDeleteProjectStorage" *ngIf="storageType !== 'ingress'" type="checkbox" name="confirmProjectStorage" id="confirmProjectStorage" [disabled]="!confirmBox" (change)="confirmDeleteProjectStorage($event)">
    <ng-container >
      <label style="color:white; font-size: 15px; margin-left: 5px;">Delete the {{ storageType }} storage. I agree all data in the bucket will be deleted and irrecoverable.</label>
    </ng-container>
  </div>
  
  <div class="mdl-dialog__actions" style="margin-top: 15px;margin-bottom: 15px;">
    <button class="btn btn-primary dele-btn" type="button" (click)="archiveProject()" [disabled]="!confirmBox" ><h2 class="del-text">Archive  <i *ngIf="showSpinner" class="fa fa-refresh fa-spin"></i></h2></button>
    <button class="btn  can-btn" type="button"  (click)="cancel()"><h2 class="cal-text">Cancel</h2></button>
  </div>
</div>
