import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/do';
// import { HttpInterceptor } from './httpInterceptor.service';

const headers = new HttpHeaders();
headers.append('Content-Type', 'application/json');
let options = {
  headers: headers
}

@Injectable()
export class OrganizationService {

  constructor(private http: HttpClient) { }

  getOrganizationsList() {
    return this.http.get('/getOrganizations', options) 
  }

  getOrganizationsListWithPagination(query) {
    return this.http.get(`/organizations?limit=${query.limit}&page=${query.page}`, options) 
  }

  addOrganization(data) {
    return this.http.post(`/addOrganization`, data) 
  }

  getPrincipalList() {
    return this.http.get(`/getPrincipals`, options) 
  }

  getAccountList() {
    return this.http.get(`/getAccounts`, options) 
  }
}
