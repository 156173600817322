import {Component, Input, OnChanges, OnInit, Renderer2, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ResearchService} from '../../_services/research.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CreateKeypairComponent} from "../../keypair/create-keypair/create-keypair.component";
import {MdlDialogService} from "@angular-mdl/core";
import {ToastrService} from "ngx-toastr";
import {Globals} from "../../globals";
import {DashboardService} from "../../_services/dashboard.service";

@Component({
  selector: 'app-azure-order',
  templateUrl: './azure-order.component.html',
  styleUrls: ['./azure-order.component.css']
})
export class AzureOrderComponent implements OnInit, OnChanges {
  @Input() inputValue: Object;
  @Input() keyPair: any;
  form: FormGroup;
  parameters: { key: string; value: any }[] = [];

  constructor(
    private fb: FormBuilder,
    private researchService: ResearchService,
    private dialog: MdlDialogService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private globals: Globals,
    private dashboardService: DashboardService) {
    this.researchService.getKeypairCreateEvent().subscribe(() => {
      this.getKeyName();
    });


  }

  ngOnChanges(changes: SimpleChanges) {
    // @ts-ignore
    if (changes.inputValue && !changes.inputValue.firstChange) {
      this.createForm();

    }
    // @ts-ignore
    if (changes.keyPair && !changes.keyPair.firstChange) {
      console.log('Data updated:', this.keyPair);
    }

  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const productId = params['productId'];
      const stdCatalogitemId = params['stdCatalogitemId'];
      const productName = decodeURIComponent(params['name']);
      const projectId = params['projectId'];
      this.fetchParameters(productId, stdCatalogitemId, productName, projectId);
    });
  }

  fetchParameters(productId: string, stdCatalogitemId: string, productName: string, projectId: string): void {
    this.researchService.getResearchProductsParameters(productId, productName, projectId, stdCatalogitemId)
      .subscribe(
        res => {

          // Extract names from PreProvisioning
          // @ts-ignore
          const preProvisioningNames = res.params.PreProvisioning.map(pre => pre.name);

          // Filter ProvisioningArtifactParameters
          // @ts-ignore
          const parameters = res.params.ProvisioningArtifactParameters.filter(
            param => !preProvisioningNames.includes(param.ParameterKey)
          );

          this.parameters = parameters.map(param => ({key: param.ParameterKey, value: param}));

          this.createForm();
        },
        error => {
          console.error('Error fetching parameters:', error);
          // Handle error
        }
      );
  }

  createForm(): void {
    const formControls = {};
    this.parameters.forEach(parameter => {
      const validators = [];
      if (parameter.value.ParameterConstraints && parameter.value.ParameterConstraints.allowedValues.length > 0) {
        validators.push(Validators.required);
      }
      if (parameter.value.ParameterType === 'string') {
        if (parameter.value.minLength) {
          validators.push(Validators.minLength(parameter.value.minLength));
        }
        if (parameter.value.maxLength) {
          validators.push(Validators.maxLength(parameter.value.maxLength));
        }
      }
      formControls[parameter.key] = [parameter.value.DefaultValue || '', validators];
    });
    this.form = this.fb.group(formControls);
  }

  onSubmit(): void {
    if (this.form && this.form.valid) {
      console.log('Form submitted with values:', this.form.value);
      // Submit the form data
    } else {
      console.error('Form invalid. Please fill in all required fields correctly.');
    }
  }

  isDropdown(parameter: any): boolean {
    // parameter.value.ParameterType = "AWS::EC2::KeyPair::KeyName"
    if (
      (parameter &&
        parameter.value &&
        parameter.value.ParameterConstraints &&
        parameter.value.ParameterConstraints.allowedValues &&
        parameter.value.ParameterConstraints.allowedValues.length > 0) ||
      (parameter &&
        parameter.value &&
        parameter.value.ParameterKey === "KeyName")
    ) {
      return true;
    }
    return false;
  }

  isDateField(parameter: any): boolean {
    return parameter && parameter.value && parameter.value.ParameterType === 'date';
  }

  isSecureStringField(parameter: any): boolean {
    return parameter && parameter.value && parameter.value.ParameterType === 'securestring';
  }

  isTextInput(parameter: any): boolean {
    return parameter && parameter.value &&
      (!parameter.value.ParameterConstraints ||
        !parameter.value.ParameterConstraints.allowedValues ||
        parameter.value.ParameterConstraints.allowedValues.length === 0) &&
      parameter.value.ParameterType !== 'securestring' &&
      parameter.value.ParameterType !== 'date';
  }

  isInvalid(key: string): boolean {
    const control = this.form.get(key);
    return control && control.invalid && control.touched;
  }

  isRequired(key: string): boolean {
    const control = this.form.get(key);
    // @ts-ignore
    return control && control.errors && control.errors.required;
  }

  isMinLength(key: string): boolean {
    const control = this.form.get(key);
    // @ts-ignore
    return control && control.errors && control.errors.minlength;
  }

  isMaxLength(key: string): boolean {
    const control = this.form.get(key);
    // @ts-ignore
    return control && control.errors && control.errors.maxlength;
  }

  addKeyPairs(): void {
    this.dashboardService.getDialogBox().subscribe((data: any) => {
      if (data.status === 200) {
        this.dialog.showCustomDialog({
          component: CreateKeypairComponent,
          providers: [{provide: 'data', useValue: {services: 'order', name: ''}}],
          isModal: true,
          styles: {
            "height": "auto",
            "width": "40%",
            "background": "#07102A",
            "border": "1px solid #FFFFFF33",
            "border-radius": "10px",
            "padding": "0px"
          },
          clickOutsideToClose: true,
          enterTransitionDuration: 400,
          leaveTransitionDuration: 400
        })
      }
    }, error => {
      if (error && error.status === 401) {
        let errBody = error.error;
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
      }
    })
  }


  private getKeyName() {

  }
}
