import { AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import * as $ from 'jquery';
import * as Terminal from '../../../assets/js/terminal.js';
// import * as io from '../../../assets/js/socket.io.min.js';
import * as io from 'socket.io-client';
import {MdlDialogReference} from "@angular-mdl/core";

declare var instanceSSH: any;
@Component({
  selector: 'app-instance',
  templateUrl: './instance.component.html',
  styleUrls: ['./instance.component.css']
})

export class InstanceComponent implements OnInit {

  constructor(public dialogRef: MdlDialogReference, @Inject('data') public data: any) { }
  usernameF = 'ec2-user';
  passwordF = '';
  pemFileCheckboxF = 'pemFile';
  instanceDetails;
  pemfileInputF = '';
  instanceId = '';
  getInstanceIp = null;
  ngOnInit() {
    try {
      this.instanceDetails = this.data;
      const getInstanceDetails = this.instanceDetails.RecordOutputs;

      for (let i = 0; i < getInstanceDetails.length; i++) {
        if (getInstanceDetails[i].OutputKey === 'InstanceIPAddress') {
          this.getInstanceIp = getInstanceDetails[i].OutputValue;
        }
      }
    } catch (e) {
      console.log(e);
      return null;
    }

    var $sshContainer = $("#instanceSSHPage .modal-body");
    $sshContainer.find(".passwordCheck").hide();
    $sshContainer.find(".chooseAuthenticationtype").change(function(e) {
      // if (this.value === "Password") {
      //   $sshContainer.find(".pemFileCheck").hide();
      //   $sshContainer.find(".passwordCheck").show();
      // } else {
      //   $sshContainer.find(".passwordCheck").hide();
      //   $sshContainer.find(".pemFileCheck").show();
      // }
    });

    $("#instanceSSHPage #ssh-pemfileInput").change(function() {
      // $(this).next().val(this.files[0].name);
      // if (this.files[0].name) {
      //   let fName = this.files[0].name;
      //   const getLen = fName.length;
      //   if (getLen > 50) {
      //     fName = fName.slice(0, 50) + '...';
      //   }
      //   $("#ssh-fileName").text(fName);
      // } else {
      //   $("#ssh-fileName").text('No file selected');
      // };
      // $("#ssh-pemfileInput-lab").removeClass('file-lab-red');
      // $("#ssh-pemfileInput-lab").addClass('file-lab');
    });

    var $sshContainer = $("#instanceSSHPage");
    var $pemFileCheckBox = $sshContainer.find("#ssh-pemFileCheckbox");
    var $errorArea = $sshContainer.find("#ssh-errorArea");
    var $terminalContainer = $("#ssh-terminalContainer");
    var $loadingContainer = $sshContainer.find("#ssh-loadingContainer");
    var $loginForm = $sshContainer.find("#ssh-login-form");
    var $terminateBtn = $sshContainer.find(".ssh-terminateBtn");
    var $sshActionLogIdInput = $sshContainer.find("#ssh-actionLogId");
    var term;
    var sshOpened = false;
    var sshSocket;
    $loadingContainer.hide();

    function openSSh(reqBody) {
      $sshContainer.find('.sshSubmit').hide();
      $errorArea.hide();
      $terminalContainer.hide();
      $loginForm.hide();
      $loadingContainer.show();

      sshSocket = io.connect('/sshShell', {
        // 'force new connection': true,
        reconnection: true
      });

      sshSocket.on('connect', function() {
        sshSocket.emit('open', reqBody);
      });
      sshSocket.on('conErr', function(data) {
        $errorArea.empty().append(data.message);
        $errorArea.show();
        $loginForm.show();
        $sshContainer.find('.sshSubmit').show();
        $terminalContainer.hide();
        $loadingContainer.hide();
        if (data.actionLogId) {
          $sshActionLogIdInput.val(data.actionLogId);
        }
      });
      sshSocket.on('opened', function(data) {
        $errorArea.hide();
        $loginForm.hide();
        $loadingContainer.hide();
        $terminalContainer.show();
        sshOpened = true;
        term = new Terminal({
          cols: 80,
          rows: 24,
          screenKeys: true,
          cursorBlink: true,
          useStyle: true
        });

        term.on('data', function(data) {
          if (sshOpened) {
            sshSocket.emit('cmd', data);
          }
        });

        term.open($terminalContainer.get(0));

        if (data.actionLogId) {
          $sshActionLogIdInput.val(data.actionLogId);
        }

      });
      sshSocket.on('out', function(data) {
        if (term) {
          term.write(data.res);
        }
      });
      sshSocket.on('disconnect', function() {
        destroyTerminal();
      });
      sshSocket.on('close', function() {
        destroyTerminal();
        $sshContainer.find('.sshSubmit').show();
      });
      sshSocket.on('error', function(err) {

        if (err && err.reason) {
          $errorArea.empty().append(err.reason);
        } else {
          $errorArea.empty().append('Error occured while making a connection');
        }
        $errorArea.show();
        $loginForm.show();
        $sshContainer.find('.sshSubmit').show();
        $terminalContainer.hide();
        $loadingContainer.hide();
        // if (data.actionLogId) {
        //   $sshActionLogIdInput.val(data.actionLogId);
        // }
      });
    }

    function destroyTerminal() {
      if (term) {
        term.destroy();
      }
      $terminalContainer.hide();
      $loginForm.show();
      $errorArea.hide();
      $loadingContainer.hide();
    }

    $sshContainer.find('.sshSubmit').click(function(e) {
      var reqBody = {
        id : '',
        sessionUser : '',
        username : 'ubuntu',
        password : '',
        pemFileData : ''
      };

      /*Values set from external page*/
      // reqBody.id = $sshContainer.find("#ssh-instanceId").val();
      // reqBody.sessionUser = $sshContainer.find("#ssh-sessionUser").val();
      // /*Values entered by user*/
      // reqBody.username = $sshContainer.find("#ssh-username").val();
      if (!reqBody.id) {
        return;
      }
      if ($pemFileCheckBox.val() === 'Password') {
        // reqBody.password = $sshContainer.find("#ssh-password").val();

        if (!reqBody.username) {
          alert('Please Enter Username');
          return;
        }
        if (!reqBody.password) {
          alert('Please Enter Password or Choose a Pem file');
          return;
        }
        openSSh(reqBody);
      } else {
        // var pemFileInput = $sshContainer.find("#ssh-pemfileInput").get(0);
        let BR = (<HTMLInputElement>document.getElementById('ssh-pemfileInput')).files[0];

        if (!reqBody.username) {
          alert('Please Enter Username');
          return;
        }
        reqBody.pemFileData = '';
        // if (!pemFileInput.files.length) {
        //   alert('Please Choose a Pem file');
        //   return;
        // }
        var reader = new FileReader();
        // Closure to capture the file information.
        reader.onload = function(e: any) {
          // Render thumbnail.
          reqBody.pemFileData = e.target.result;
          openSSh(reqBody);
        };
        // Read in the image file as a data URL.
        reader.readAsText(BR);
      }
      return false;
    });

    $terminateBtn.click(function() {
      if (sshOpened) {
        if (sshSocket) {
          sshSocket.disconnect();
        }
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.hide();
  }

}
