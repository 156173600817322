<div class='s3Upload' id="instanceSSHPage">
  <div class="dialog-header">
    <img class="cancel-icon" (click)="cancel()" src="../../assets/images/cancel@2x.png" alt="Cancel"/>
    <h2 class="modal-title title-header" id="myModalLabel" class="Header">
      Assign to an Organizational Unit
    </h2>
  </div>
  <div style="display: flex; flex-direction: row; margin-top: 10px;">
    <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
  </div>
  <div class="modal-body modal-title" *ngIf="organisationData.length > 0 && !showSpinner">
    <label class="details" *ngIf="userInfo">Select an Organizational Unit that you want to map 
      <span style="color: #85d4ff">{{userInfo.first_name}} {{userInfo.last_name}}</span> 
      user to.
    </label>
    <label class="details" *ngIf="accountInfo">Select an Organizational Unit that you want to map 
      <span style="color: #85d4ff; word-break: break-word;">{{accountInfo.name}}</span> 
      account to.
    </label>
  </div>

  <div class="col-lg-12 col-md-12 col-sm-6 dropdown-container" *ngIf="organisationData.length > 0 && !showSpinner">
    <label class="dropdown-label">
      Organizational Unit <span class="order-st">*</span>
    </label>
    <label class="wrap">
      <select  class="dropdown font-class" [ngClass]="{'placeholder-text': selectedOrganisation === undefined}" required [(ngModel)]="selectedOrganisation" name="selectedOrganisation">
        <option [ngValue]="undefined" disabled selected>Choose an Organizational Unit</option>
        <option style="cursor: pointer;" *ngFor="let organisation of organisationData; let $index=index" [ngValue]="organisation">{{ organisation.value }}</option>
      </select>
    </label>
    <label class="details-a">
      Select an Organizational Unit to be linked with this account
    </label>
  </div>

  <div class="col-lg-12 col-md-12 mt-5" style="text-align: center; margin-bottom: 35px !important; margin-top: 0px !important" *ngIf="organisationData.length > 0 && !showSpinner">
    <div class="marginForButtons l-footer">
      <button   type="button" [disabled]="!selectedOrganisation" class="btn cat-btn-update sshSubmit ssh-btn l-submit" (click)="submit()">
        Assign
      </button>
    </div>
  </div>
  <div *ngIf="organisationData.length === 0 && !showSpinner">
    <h3 class="noData">
      There are no Organizational Units. Please create some Organizational Units.
    </h3>
  </div>
</div>
<app-loader class="spinner" *ngIf="showSpinner"></app-loader>
