<div *ngIf="projectName">
  <div class="dialog-header">
      <img class="cancel-icon" (click)="cancel()" src="../../assets/images/cancel@2x.png" alt="Cancel"/>
      <h3 class="title">Unlink Sagemaker</h3>
  </div>
  <div style="display: flex; flex-direction: row; margin-top: 0px;">
    <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
  </div>
  <app-loader class="spinner" *ngIf="showSpinner"></app-loader>
  <div class="content" *ngIf="!showSpinner">
    <h2 class="content-1">Are you sure you want to unlink <h2 class="content-2"> Sagemaker </h2> from this <h2 class="content-2"> S3 bucket</h2> ?</h2>
  </div>
  <div class="warningData" *ngIf="!showSpinner">
    <h2 class="war-msg">You will no longer be able to read the data from this S3 bucket in the sagemaker notebook.</h2>
  </div>
  <div class="mdl-dialog__actions" style="margin-top: 15px; margin-bottom: 15px;" *ngIf="!showSpinner">
    <button class="btn btn-primary dele-btn" type="button" (click)="unlinkResource()"><h2 class="del-text">Unlink</h2></button>
    <button class="btn  can-btn" type="button"  (click)="cancel()"><h2 class="cal-text">Cancel</h2></button>
  </div>
</div>

<div *ngIf="studyId">
  <div class="dialog-header">
    <img class="cancel-icon" (click)="cancel()" src="../../assets/images/cancel@2x.png" alt="Cancel"/>
    <h3 class="title">Unlink Study</h3>
  </div>
  <div style="display: flex; flex-direction: row; margin-top: 0px;">
    <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
  </div>
  <app-loader class="spinner" *ngIf="showSpinner"></app-loader>
  <div class="content" *ngIf="!showSpinner">
    <h2 class="content-1">Are you sure you want to unlink <h2 class="content-2"> {{productTitle}} </h2> from this study ?</h2>
  </div>
  <div class="warningData" *ngIf="!showSpinner">
    <h2 class="war-msg">You will no longer be able to read the data from this study.</h2>
  </div>
  <div class="mdl-dialog__actions" style="margin-top: 15px; margin-bottom: 15px;" *ngIf="!showSpinner">
    <button class="btn btn-primary dele-btn" type="button" (click)="unlinkProduct()"><h2 class="del-text">Unlink</h2></button>
    <button class="btn  can-btn" type="button"  (click)="cancel()"><h2 class="cal-text">Cancel</h2></button>
  </div>
</div>