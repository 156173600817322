import {Component, HostListener, OnInit} from '@angular/core';
import {ResearchService} from "../_services/research.service";
import {ActivatedRoute, NavigationStart, Router} from "@angular/router";
import {MdlDialogService} from "@angular-mdl/core";
import {ToastrService} from 'ngx-toastr';
import {Globals} from "../globals";
import {OrganizationService} from '../_services/organization.service';
import {DashboardService} from '../_services/dashboard.service';
import {HeaderComponent} from '../components/header/header.component';

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  // using already implemented researcher css for projects listing
  styleUrls: ['../researcher/researcher.component.css', './principal.component.css', '../app.component.css'],
  providers: [Globals, HeaderComponent]
})
export class PrincipalComponent implements OnInit {
  private parametersObservable: any;
  researchIds;
  projects = [];
  projectsAdded = [{
    project_name: "Atmos",
    description: "Atmosphere Dynamics and Composition studies based on AIRS data from NASA",
    img_src: "../assets/images/rocket.png"
  }, {
    project_name: "Neptune",
    description: "Sea and Ice-level studies using ECCO2 and ISSM data.",
    img_src: "../assets/images/project.png"
  }, {
    project_name: "Earth",
    description: "Plate tectonics study using data from NASA including Terra, Aqua and Aura Missions.",
    img_src: "../assets/images/project-management.png"
  }];
  loader: boolean = true;
  disableAssign = false;
  organizationData = [];
  accountIdArr = [];
  accountIdname = [];
  viewAccountIdname: string;
  fAccountIdList = [];
  accountIdList;
  accountListFound;
  accountNames: string;
  b2cEnabled = false;
  idArr = [];
  accounts = [];
  ProjectData;
  backgroundImage = ['fa-empire', 'fa-snowflake-o', 'fa-sun-o', 'fa-cog', 'fa-compass', 'fa-certificate', 'fa-star-half-o', 'fa-life-ring', 'fa-link', 'fa-arrows', 'fa-delicious', 'fa-envira', 'fa-codepen', 'fa-empire', 'fa-snowflake-o', 'fa-sun-o', 'fa-cog', 'fa-compass', 'fa-certificate', 'fa-star-half-o', 'fa-life-ring', 'fa-link', 'fa-arrows', 'fa-delicious', 'fa-envira', 'fa-codepen'];
  fullVideoLink;
  addAccountLink;
  addProjectLink;
  assignProductsLink;
  userLink;
  eventS = this.getEventSource(`/stream_events`);
  page = 1;
  limit = 10;
  totalProjectCount;
  orgId;
  hasNextPage = false;
  sseRefreshTimer;


  private researchService: ResearchService;

  constructor(private dialog: MdlDialogService, private router: Router,
              researchService: ResearchService,
              private activatedRoute: ActivatedRoute,
              private toastr: ToastrService,
              private globals: Globals,
              private orgService: OrganizationService,
              private dashboardService: DashboardService,
              private callHelpComp: HeaderComponent
  ) {
    this.researchService = researchService;
    router.events.subscribe((val) => {
      const navVal = val instanceof NavigationStart;
      if (navVal == true) {
        this.eventS.close();
      }
    });
  }

  ngOnInit() {
    this.dashboardService.checkIfPI();
    this.orgId = sessionStorage.getItem('org_id');
    let level = sessionStorage.getItem("Level");
    if ((this.orgId == "" || this.orgId == null || this.orgId == undefined) && (level == "1" || level == "0")) {
      this.router.navigate(['logout']);
    }
    this.serverStreamEvent();
    this.getApplicationMode();
    this.getHelpVideoLink();

    this.parametersObservable = this.activatedRoute.params.subscribe(params => {
      this.getProjects();
    }, error => {
      if (error && error.status === 401) {
        let errBody = error.error;
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
      }
    })
  }

  getProjects() {
    this.loader = true;
    this.researchService.getPrincipalProjects(this.page, this.limit).subscribe((data: any) => {
      this.projects = data.docs;

      this.totalProjectCount = data.total;
      this.hasNextPage = this.page !== data.pages;
      this.loader = false;
      for (let i = 0; i < this.projects.length; i++) {
        this.accountIdArr.push(this.projects[i].account_id);
      }
      this.viewAccountIdname = this.accountIdname.toString();
      this.getAccounts();
    }, error => {
      this.loader = false;
      this.accountListFound = false;
      this.fAccountIdList = [];
      if (error && error.status === 401) {
        let errBody = error.error;
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
      } else if (error && error.status === 403) {
        const err = error.error;
        this.toastr.error(err.error, 'Error', this.globals.tosterOverride);
        this.router.navigate(['pageNotFound']);
      }
    });
  }

  refreshProjects() {
    this.page = 1;
    this.limit = 10;
    this.getProjects();
  }

  loadMore() {
    this.loader = true;
    this.page += 1;
    this.researchService.getPrincipalProjects(this.page, this.limit).subscribe((data: any) => {
      this.loader = false;
      this.projects = this.projects.concat(data.docs);
      this.totalProjectCount = data.total;
      this.hasNextPage = this.page !== data.pages;
      for (let i = 0; i < this.projects.length; i++) {
        this.accountIdArr.push(this.projects[i].account_id);
      }
      this.viewAccountIdname = this.accountIdname.toString();
      this.getAccounts();
    }, error => {
      this.loader = false;
      this.accountListFound = false;
      this.fAccountIdList = [];
      if (error && error.status === 401) {
        let errBody = error.error;
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
      } else if (error && error.status === 403) {
        const err = error.error;
        this.toastr.error(err.error, 'Error', this.globals.tosterOverride);
        this.router.navigate(['pageNotFound']);
      }
    });
  }

  getProjectThresholdClass(project) {
    if (project.budgetConsumedPercentage >= project.thresholdPercentage) {
      return {'threshold-cross-above': true, 'threshold-cross-below': false};
    } else {
      return {'threshold-cross-above': false, 'threshold-cross-below': true};
    }
  }

  projectDescription(data) {
    const strLen = data.length;
    let str = data;
    if (strLen > 245) {
      str = str.slice(0, 245) + '...';
    }
    return str;
  }

  projectTeam(data) {
    this.loader = false;
    const research = [];
    for (const name of data) {
      if (name) {
        research.push(name.displayName);
      }
    }
    let team;
    // if(research.length>0){
    //   team = research.toString().replace(/,/g, ", ");
    // }
    if (research.length > 0) {
      let getLen = research.length;
      if (getLen > 1) {
        getLen = getLen - 1;
      }
      let append_name = ' other';
      if (getLen > 2) {
        append_name = ' others';
      }
      if (research.length > 1) {
        team = research[0] + ' and ' + getLen + append_name;
      } else {
        team = research[0];
      }
    }
    return team;
  }

  addProject() {
    if (this.accountIdList && this.accountIdList.length > 0 && this.orgId) {
      this.router.navigate(['/addProject/project']);
    } else if (this.orgId) {
      sessionStorage.setItem('accountType', 'project');
      this.router.navigate(['/addAccount']);
    }
  }

  viewProject(data) {
    const projectStatus = data.projectStatus.toLowerCase();
    this.router.navigate([`/project-details/${data._id.toString()}`], {queryParams: {showTab: projectStatus}})
  }

  getEventSource(url: string): EventSource {
    return new EventSource(url, {withCredentials: true});
  }

  private serverStreamEvent() {
    // let sseRefreshTimer;
    let timer_exists;
    this.researchService.getServerSentEvent(this.eventS).subscribe(res => {
      const resObj = res.data;
      if (!resObj) {
        return;
      }
      try {
        if (timer_exists == false) {
          this.sseRefreshTimer = undefined;
          timer_exists = undefined;
        }
        const {status, data, projectOrgId} = JSON.parse(resObj);
        // server give handshake to the alive connection
        if (data === 'check') {
          // console.log('check');
          return;
        }
        if (data === 'project' && projectOrgId == this.orgId) {
          if (this.sseRefreshTimer == undefined) {
            this.sseRefreshTimer = setTimeout(() => {
              this.page = 1;
              this.limit = 10;
              timer_exists = false;
              this.getProjects();
            }, 5000);
          }
        }
      } catch (e) {
        console.log(e.message);
      }
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
  }

  getAccounts() {
    this.loader = true;
    this.fAccountIdList = [];
    this.dashboardService.getSettingsDetail('1', '999', 'created_on', 'asc', 'principal', 'project').subscribe(
      (res: any) => {
        console.log(res)
        this.accounts = res.settings;
        this.accountIdList = res.settings.map(obj => {
          const fObj = {
            'value': obj._id,
            'viewValue': obj.name,
            'vendor': obj.vendor,
          };
          return fObj;
        });
        for (let i = 0; i < this.accountIdList.length; i++) {
          let data = this.accountIdArr.find(obj => obj == this.accountIdList[i].value)
          if (!data) {
            this.fAccountIdList.push(this.accountIdList[i]);
            this.accountListFound = true;
          }
        }
        if (this.fAccountIdList.length == 0) {
          this.accountListFound = false;
        }
        this.loader = false;
      }, error => {
        this.loader = false;
        if (error && error.status === 401) {
          let errBody = error.error;
          this.toastr.error(errBody.err, '', this.globals.tosterOverride);
          this.router.navigate(['login']);
        }
      });
  }

  getProjectWorkflowStatus(data) {
    let statusV = 'md-step active unknown';
    switch (data.toLowerCase()) {
      case 'complete':
        statusV = 'md-step active done';
        break;
      case 'in-progress':
        statusV = 'md-step active inprogress';
        break;
      case 'failed':
        statusV = 'md-step active failed';
        break;
    }
    return statusV;
  }

  nameTrim(data) {
    if (!data) {
      return data;
    }
    const getLen = data.length;
    if (getLen > 28) {
      data = data.slice(0, 28) + '...';
    }
    return data;
  }

  getApplicationMode() {
    this.dashboardService.getApplicationMode().subscribe((data: any) => {
      if (data.enableB2CMode) {
        this.b2cEnabled = true;
      } else {
        this.b2cEnabled = false;
      }
    }, error => {
      if (error && error.status === 401) {
        let errBody = error.error;
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
      }
    });
  }

  getHelpVideoLink() {
    this.researchService.getConfigData('rgSetupVideo').subscribe((data: any) => {
      try {
        if (data.value) {
          this.fullVideoLink = data.value.fullVideo.link;
          this.addAccountLink = data.value.addAccount.link;
          this.addProjectLink = data.value.addProject.link;
          this.assignProductsLink = data.value.assignProducts.link;
          this.userLink = data.value.users.link;
        }
      } catch (e) {
        // console.log("Error in getting rg setup videos");
      }
    }, error => {
      if (error && error.status === 401) {
        let errBody = error.error;
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
      }
    });
  }

  getVendorImage(project: any): string {

    const account = this.accounts.find(acc => acc._id === project.account_id);
  
    if (account && account.vendor.toLowerCase() != 'aws') {
      return 'assets/images/azure_icon/azure.png';
    } else {
      return '/assets/images/aws_icon/aws.png';
    }
  }
}
