import { Component, OnInit, Inject } from '@angular/core';
import { MdlDialogReference } from '@angular-mdl/core';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-s3-sync-info',
  templateUrl: './s3-sync-info.component.html',
  styleUrls: ['./s3-sync-info.component.css']
})
export class S3SyncInfoComponent implements OnInit {

  bucketName;

  constructor(
    @Inject('data') public data: any,
    public dialogRef: MdlDialogReference,
    private router: Router
  ) { }

  ngOnInit() {

    if(this.data.hasOwnProperty('s3BucketName')){
        this.bucketName = this.data.s3BucketName;
    }  
}

  cancel(): void {
    this.dialogRef.hide();
  }

  syncS3(){
    this.dialogRef.hide();
    this.router.navigate([`s3/bucket/${this.bucketName}/success/sync`]);
  }

}
