import { Component, OnInit, Inject } from '@angular/core';
import { MdlDialogReference } from '@angular-mdl/core';
import { Location } from '@angular/common';
import { ResearchService } from "../../_services/research.service";
import { ServiceActionsService} from "../../_services/service-actions.service";
import { DataService } from '../../_services/data.service';
import { Router,ActivatedRoute } from '@angular/router';
import { S3DataService } from "../../_services/s3-data.service";
import { ToastrService } from 'ngx-toastr';
import {Globals} from "../../globals";

@Component({
  selector: 'app-s3-file-delete',
  templateUrl: './s3-file-delete.component.html',
  styleUrls: ['./s3-file-delete.component.css'],
  providers: [Globals]
})
export class S3FileDeleteComponent implements OnInit {

  s3Deleteobj: any;
  service: string = "s3";
  showSpinner: boolean = false;
  folderName: any;
  fileName: any;
  account_id: any;
  isMultiple: boolean = false;
  bucketName: any;
  isKeypair= false;
  keypairPayload: any;
  keypairName: any;
  isStudyDelete: boolean = false;
  isInternalStudyDelete: boolean = false;
  studyName : any;
  studyType : any;
  studyId: string;
  isStudy: boolean = false;
  projectArray: any;
  unassignedProjects : boolean = false;
  confirmBox:boolean = false;
  body;
  projectId: string;
  ppId: string;
  filePath: any;
  isExternalStudyDelete: boolean = false;

  constructor(
  @Inject('data') public data: any,
  public dialogRef: MdlDialogReference,
  private serviceAction: ServiceActionsService,
  private toastr: ToastrService,
  private router: Router,
  private globals: Globals,
  private location: Location,
  private s3DataService: S3DataService,
  private researchService: ResearchService,
  private dataService: DataService) { }

  ngOnInit() {
    if(this.data.hasOwnProperty('s3Obj')){
      this.s3Deleteobj = this.data.s3Obj;
      this.folderName = this.data.s3Obj.Key || this.data.s3Obj.name;
      this.service = this.data.s3Obj.service?this.data.s3Obj.service : this.service;
    }
    if(this.data.hasOwnProperty('service')){
      this.service = this.data.service;
    }
    if(this.data.hasOwnProperty('s3FileObj')){
      this.s3Deleteobj = this.data.s3FileObj;
      this.fileName = this.data.s3FileObj.key;
      this.filePath = this.data.s3FileObj.filePath;
      this.service = this.data.s3FileObj.service?this.data.s3FileObj.service : this.service;
    }
    if(this.data.hasOwnProperty('s3FileCheckBoxObj')){
      this.isMultiple = true;
      this.s3Deleteobj = this.data.s3FileCheckBoxObj;
      this.account_id = this.data.account_id;
      this.bucketName = this.data.bucketName;
      if('study' in this.data){
        this.isStudy = true;
        this.s3Deleteobj.id = this.data.body.id;
        this.s3Deleteobj.name = this.data.body.name;
        this.s3Deleteobj.type = this.data.body.type;
      }

    }
    if (this.data.hasOwnProperty('keypairList')) {
      this.isKeypair = true;
      this.keypairPayload = this.data.keypairList;
      this.keypairName = this.keypairPayload[0].keypairName;
    }
    if (this.data.hasOwnProperty('studyId')) {
      this.studyType = this.data.studyDetails.studyType;
      if ( this.studyType == 'Personal'){
      this.isStudyDelete = true;
      this.studyId = this.data.studyId;
      }else if ( this.studyType == 'internal'){
        this.isInternalStudyDelete = true;
        this.studyId = this.data.studyId;
        this.projectArray = this.data.studyDetails.projectId
        this.studyName = this.data.studyDetails.name
      }else if ( this.studyType == 'external'){
        this.isExternalStudyDelete = true;
        this.studyId = this.data.studyId;
        this.projectArray = this.data.studyDetails.projectId
        this.studyName = this.data.studyDetails.name
      }
    }
    if(this.s3Deleteobj && 'isStudy' in this.s3Deleteobj ){
      this.isStudy = true;
    }
    this.projectId = sessionStorage.getItem('projectId');
    this.ppId = sessionStorage.getItem('ppId')
  }

  cancel(): void {
    this.dialogRef.hide();
  }

  confirmDeleteBox(){
    this.confirmBox = !this.confirmBox;
  }

  navigateTo(){
    if(this.isStudy){
      this.router.navigate([`/explore/${this.s3Deleteobj.id}/success/deleteSuccess`], {queryParams:{name: this.s3Deleteobj.name, type: this.s3Deleteobj.type, accountId: this.account_id, delete:'success'}});
    }else {
      if( this.s3Deleteobj.length > 0){
        this.router.navigate([`s3/bucket/${this.s3Deleteobj[0].bucketName}/success/deleteSuccess`]);
      }else{
        this.router.navigate([`s3/bucket/${this.s3Deleteobj.bucketName}/success/deleteSuccess`]);
      }
      //this.toastr.success(`Deleted S3 folder ${this.s3Deleteobj.key} successfully`, '', this.globals.tosterOverride);
    }
  }

  deleteS3Folder(){
    this.showSpinner = true;
    this.serviceAction.serviceAction(this.s3Deleteobj).subscribe(res => {
    this.showSpinner = false;
    this.dialogRef.hide();
    this.s3DataService.setFolderPrefix({folderName: this.s3Deleteobj.folderName, rootFolder: this.s3Deleteobj.rootFolder});
    this.navigateTo();
      // this.router.navigate([`s3/bucket/${this.s3Deleteobj.bucketName}/success/deleteSuccess`]);
      if(this.isStudy){
        this.toastr.success(`Deleted Study folder successfully`, '', this.globals.tosterOverride);
      }else{
        this.toastr.success(`Deleted ${this.service} folder successfully`, '', this.globals.tosterOverride);
      }
    }, error => {
      this.dialogRef.hide();
      this.showSpinner = false;
      console.error(error);
      let errBody = error.error;
      if(error && error.status === 401){
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
       }else{
          this.toastr.error(`${this.s3Deleteobj.bucketName} - `+errBody.error, 'Error', this.globals.tosterOverride)
       }
    });
  }

  deleteS3File(){
    this.showSpinner = true;
    this.serviceAction.serviceAction(this.s3Deleteobj).subscribe(res => {
    this.showSpinner = false;
    this.dialogRef.hide();
    this.s3DataService.setFolderPrefix({folderName: this.s3Deleteobj.folderName, rootFolder: this.s3Deleteobj.rootFolder});
    if(this.isStudy){
        this.toastr.success(`Deleted Study file successfully`, '', this.globals.tosterOverride);
      }else{
        this.toastr.success(`Deleted ${this.service} file successfully`, '', this.globals.tosterOverride);
      }
      this.navigateTo();
    }, error => {
      this.dialogRef.hide();
      this.showSpinner = false;
      console.error(error);
      let errBody = error.error;
      if(error && error.status === 401){
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
       }else{
      this.toastr.error(`${this.s3Deleteobj.bucketName} - `+errBody.error, 'Error', this.globals.tosterOverride)
       }
    });
  }

  deleteS3MultipleData(){
    this.showSpinner = true;
    this.serviceAction.deleteMultipleFilesInS3(this.s3Deleteobj, this.account_id ,this.projectId ,this.ppId).subscribe(res => {
    this.showSpinner = false;
    let folderName="";
    this.dialogRef.hide();
    let rootFolder = true;
    folderName = this.s3Deleteobj[0].folderName ? this.s3Deleteobj[0].folderName+'/' : "" ;
    if(folderName != "" && folderName != undefined){
      rootFolder = false;
    }else{
      rootFolder = true;
    }
    this.s3DataService.setFolderPrefix({folderName: folderName, rootFolder: rootFolder});
      this.navigateTo();
      if(this.isStudy){
        this.toastr.success(`Deleted Study data successfully`, '', this.globals.tosterOverride);
      }else{
        this.toastr.success(`Deleted ${this.service} data successfully`, '', this.globals.tosterOverride);
      }
    }, error => {
      this.dialogRef.hide();
      this.showSpinner = false;
      console.error(error);

      let errBody = error.error;
      if(error && error.status === 401){
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
     else{ let err = error.error.error;
      this.toastr.error(`${this.bucketName } - `+err, 'Error', this.globals.tosterOverride);
     }
    });
  }

  deleteKeypair() {
    this.showSpinner = true;
    const payload = { keypairList : this.keypairPayload };
    this.serviceAction.deleteKeypair(payload).subscribe(res => {
      this.showSpinner = false;
      this.dialogRef.hide();
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.navigate([`keypair/${this.keypairName}/success/deleteSuccess`]);
      this.toastr.success(`Deleted keypair successfully`, '', this.globals.tosterOverride);
    }, error => {
      this.dialogRef.hide();
      this.showSpinner = false;
      console.error(error);
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
      else {const err = error.error.error;
      this.toastr.error(`${this.keypairName } - `+err, 'Error', this.globals.tosterOverride);
      }
    });
  };

  deleteStudy(){
    const data = {
      studyId: this.studyId
    }
    this.researchService.deleteStudy(data).subscribe((res:any) => {
      if(res.status.toLowerCase() === 'success'){
        this.toastr.success(res.message)
        this.dialogRef.hide();
        this.location.back();
      }else {
        this.toastr.error(res.message)
      }
    })
  }

deleteInternalStudy(){
  this.showSpinner = true;
  
  const data = {
    studyId: this.studyId
  }
  this.researchService.deleteStudy(data).subscribe((res:any) => {
      this.showSpinner = false;
      this.dialogRef.hide();
      this.router.navigate([`/researcher/studies`]); 
      this.toastr.success(` '${this.studyName}' study deleted succesfully `, '', this.globals.tosterOverride);
    },error => {
      this.dialogRef.hide();
      this.showSpinner = false;
      let errorMes = error.error;
      if(error && error.status === 401){
        let errBody= error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }else if (errorMes && (errorMes.code && errorMes.message)) {
        this.toastr.error('', errorMes.message, { tapToDismiss: false, timeOut: 0, extendedTimeOut: 0, closeButton : true });
      }else{
        this.toastr.error(`Error in delete '${this.studyName}' Study`, '', this.globals.tosterOverride);
      }
    })
}

deleteExternalStudy(){
  this.showSpinner = true;
  
  const data = {
    studyId: this.studyId,
  }
  this.researchService.deleteExternalStudy(data).subscribe((res:any) => {
      this.dialogRef.hide();
      this.showSpinner = false;
      this.router.navigate([`/researcher/studies`]); 
      this.toastr.success(` Study deletion has been initiated `, '', this.globals.tosterOverride);
    },error => {
      this.dialogRef.hide();
      this.showSpinner = false;
      let errorMes = error.error;
      if(error && error.status === 401){
        let errBody= error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }else if (errorMes && (errorMes.code && errorMes.message)) {
        this.toastr.error('', errorMes.message, { tapToDismiss: false, timeOut: 0, extendedTimeOut: 0, closeButton : true });
      }else{
        this.toastr.error('', errorMes.error, this.globals.tosterOverride);
      }
    })
}

  name(data) {
    const strLen = data.length;
    let str = data;
    if (strLen > 15) {
      str = str.slice(0, 15) + '...';
    }
    return str;
  }
}
