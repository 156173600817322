import { Component, OnInit, Inject } from '@angular/core';
import { MdlDialogReference } from '@angular-mdl/core';
import { ResearchService } from "../../_services/research.service";
import { DataService } from '../../_services/data.service';

@Component({
  selector: 'app-share-study',
  templateUrl: './share-study.component.html',
  styleUrls: ['./share-study.component.css']
})
export class ShareStudyComponent implements OnInit {

  studyId;
  researcherList=[];
  researcher;
  loader=false;

  constructor(@Inject('data') public data: any, public dialogRef: MdlDialogReference, 
        private researchService: ResearchService, private dataService: DataService) { }

  ngOnInit() {
    if(this.data.hasOwnProperty('studyId')){
      this.studyId = this.data.studyId;
    }
    this.getResearcherListToShare();
  }

  cancel() {
    this.dialogRef.hide();
  }

  getResearcherListToShare() {
    this.loader = true;
    this.researchService.getResearcherListToShare(this.studyId).subscribe((res:any) => {
      this.loader = false;
      this.researcherList = res.researcherList;
    })
  }

  shareStudy() {
    const data = {
      studyId: this.studyId,
      user: this.researcher
    }
    this.researchService.shareStudy(data).subscribe((res:any) => {
      if(res.status.toLowerCase() === 'success') {
        this.dialogRef.hide();
        this.dataService.updatePage.next(true);
      }else {
        this.dialogRef.hide();
        this.dataService.updatePage.next(false);
      }
    })
  }

}
