<div *ngIf="!loader" class="login-container">
  <div class="login-content">
    <div class="login-image-wrapper">
      <img class="login-image" [ngClass]="showSelfRegister ? 'login-image' : 'login-image-enterprise'" src="../../assets/images/login.png"  alt="Login to Research Gateway"/>
    </div>
    <div class="login-form-wrapper">
        <h1 class="sign-in-label">Sign in</h1>
        <div style="display: flex; flex-direction: row; margin-top: 10px;">
          <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
          <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
          <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
          <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
        </div>
        <form  #f="ngForm" novalidate class="form-wrapper" (ngSubmit)="f.form.valid && login($event)">
          <button *ngIf="showSelfRegister" type="button" class="btn btn-dark btn-block btn-flat social-media-singup-btn" (click)="googleAuth($event)"><span class="social-media-singup-label" style="padding-right: 80px"><img src="../../assets/images/icons/search.svg" alt ="Signup With Google" class="pull-left social-media-image" style="margin-left: 16px;">Sign in with Google</span></button>
          <div class="separator" *ngIf="showSelfRegister"></div>
    <!--      <div class="form-group" *ngIf="!this.org || this.org.length === 0">-->
    <!--        <label class="input-label" for="company">Organization</label>-->
    <!--        <input type="text" class="form-control user-input" id="company" maxlength="50" name="company" required [(ngModel)]="fromData.company" #company="ngModel" placeholder="Please enter your organization name">-->
    <!--        <div *ngIf="f.submitted && !company.valid" class="help-block">Please enter your organization name</div>-->
    <!--      </div>-->
<!--          <div class="form-group">-->
<!--            <label class="input-label" for="username">Username </label>-->
<!--            <input type="text" class="form-control user-input" id="username" maxlength="50" name="username" required [(ngModel)]="fromData.username" #username="ngModel" placeholder="Please enter your username">-->
<!--            <div *ngIf="!f.submitted" class="info-block">Please enter the username created for you by your administrator</div>-->
<!--            <div *ngIf="f.submitted && username.valid" class="info-block">Please enter the username created for you by your administrator</div>-->
<!--            <div *ngIf="f.submitted && !username.valid" class="help-block">Please enter the username created for you by your administrator</div>-->
<!--          </div>-->
          <div class="form-group">
            <label class="input-label" for="email">Email<span style="color: #FF7A7A"> *</span> </label>
            <input type="email" class="form-control user-input" name="email" id="email" placeholder="Please enter your Email ID"
                   maxlength="320" [(ngModel)]="fromData.email" #email="ngModel" required
                   pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$">
<!--            <span class="length-cal" style="margin-bottom:-24px;padding:0px 20px"><small>{{254-fromData.email.length}} Characters left-->
<!--          </small></span>-->
<!--            <div *ngIf="!f.submitted" class="info-block">Please enter the email</div>-->
            <div *ngIf="f.submitted && !email.valid || email.errors?.['pattern']" class="help-block">Please enter a valid email address</div>
          </div>
          <div class="form-group">
            <label class="input-label" for="password">Password<span style="color: #FF7A7A"> *</span></label>
            <div style="padding: 0px !important; border-top-right-radius: 3px !important;border-bottom-right-radius: 3px !important;" class="input-group user-input">
                <input [type]="showPassword ? 'text' : 'password'" name="password" class="form-control password-input" #password="ngModel" data-toggle="fromData.password"  required [(ngModel)]="fromData.password" minlength="8" maxlength="16" id="password" placeholder="Please enter your password" >
                <button type="button" class="button" (click)="togglePassword(true)" style="background-color: #07102a; border: solid #07102a" *ngIf="showPassword === false">
                    <img class="show-icon" src="../../assets/images/pass_inactive_icon.png" alt="Show Password"/>
                </button>
                <button type="button" class="button" *ngIf="showPassword === true" style="background-color: #07102a; border: solid #07102a; " (click)="togglePassword(false)">
                    <img class="show-icon" src="../../assets/images/pass_active_icon.png" alt="Hide Password" />
                </button>
            </div>
<!--            <div *ngIf="!f.submitted" class="info-block">Please enter your password</div>-->
<!--            <div *ngIf="f.submitted && password.valid" class="info-block">Please enter your password</div>-->
            <div *ngIf="f.submitted && !password.valid" class="help-block">Please enter your password</div>
          </div>
          <div class="row">
            <div class="col-xs-4">
<!--              <button type="submit" class="btn btn-dark btn-block btn-flat submit-btn">Sign In</button>-->
              <button type="submit" class='btn btn-dark btn-block btn-flat ' [ngClass]="{'submit-btn': showSelfRegister, 'submit-btn-enterprise': !showSelfRegister}">Sign In</button>
            </div>
          </div>
        </form>
        <div style="margin: 15px;">
          <a class="forgot-pass-label" routerLink='/reset-password'>Forgot Password?</a>
          <p id="account-label" class="account-label" *ngIf="showSelfRegister">Don't have an account?</p>
          <a class="text-color sign-up-btn" routerLink='/register' *ngIf="showSelfRegister">Sign up for new account</a>
        </div>
    </div>
  </div>
</div>
<app-loader class="spinner" *ngIf="showSpinner"></app-loader>
