import { Component, OnInit, Inject } from '@angular/core';
import {ActivatedRoute, Router, NavigationEnd, Event as NavigationEvent, NavigationStart} from "@angular/router";
import { ServiceActionsService} from "../_services/service-actions.service";
import { S3DataService } from "../_services/s3-data.service";
import { Location } from '@angular/common';
import { S3FileDeleteComponent } from '../dialog/s3-file-delete/s3-file-delete.component';
import { S3EmptyFolderComponent } from '../dialog/s3-empty-folder/s3-empty-folder.component';
import { MdlDialogService } from '@angular-mdl/core';
import { ToastrService } from 'ngx-toastr';
import {Globals} from "../globals";
import { DashboardService } from "../_services/dashboard.service";
import { ResearchService } from "../_services/research.service";
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-s3-explore',
  templateUrl: './s3-explore.component.html',
  styleUrls: ['./s3-explore.component.css'],
  providers: [DashboardService,Globals]
})
export class S3ExploreComponent implements OnInit {
  eventS = this.getEventSource(`/stream_events`);
  bucketName:any;
  bucketData:any = [];
  bucketPrefixData:any =[];
  ingressStoreLoader = false;
  showSpinner = false;
  folderData:any = {};
  exploreS3BucketData: any;
  projectName: any;
  productName: any;
  provisionedProductName: any;
  isShared:any;
  productStatus: any;
  fileType: any;
  folderName: any;
  searchKey: any;
  searchValueTemp = '';
  searchValue = false;
  currentPageLowerCount = 1;
  currentPageUpperCount = 10;
  totalRecords = 0;
  totalPages = 1;
  rootFolder = false;
  previousFolder:any;
  previousUrl: any;
  checkBoxValue:any = [];
  selectedFileType: any;
  parametersObservable: any;
  currentPage = 1;
  limit = 50;
  showLoadMore=false;
  ppId;
  projectStatus;
  account_id;
  prefix;
  type;
  userLevel;
  projectId;
  autoRefreshInfo;
  searchInS3 = false;
  resourceType;
  productDetails;
  isOwner;
  isProjectStorage;
  isIngressStorage;
  next:string = "";
  showRootIcon = false;
  resources = [];
  preSignedUrl;
  preSignedUrlAccess = false;
  clicked = false;
  isAbleToSubmitIngressRequest;
  ingressPendingRequests = [];
  service: string = "s3";
  imageUrl: string = "../../assets/images/aws_icon/s3.png";
  constructor(
    private serviceAction: ServiceActionsService,
    private s3DataService: S3DataService,
    private router: ActivatedRoute,
    private route: Router,
    private location: Location,
    private dialog: MdlDialogService,
    private toastr: ToastrService,
    private globals: Globals,
    private apiService: DashboardService,
    private researchService: ResearchService,
    private clipboardApi: ClipboardService
    ) {
      this.location = location;
      route.events.subscribe((val) => { 
        const navVal = val instanceof NavigationStart; 
        if(navVal == true) { 
          this.eventS.close();
        }  
      });
    }

  ngOnInit() {
    this.service = this.router.snapshot.params['service'];
    this.imageUrl = this.service === 's3' ? "../../assets/images/aws_icon/s3.png" : "../../assets/images/azure_icon/azure-blob-block.png" ;
    this.apiService.checkIfResearcher();
    this.parametersObservable = this.router.params.subscribe(params => {
      this.showSpinner = true;
      //this.bucketName = this.router.snapshot.params['bucketName'];
      this.exploreS3BucketData = this.s3DataService.getS3ExploreData();
      this.projectName = this.exploreS3BucketData.projectName || sessionStorage.getItem('projectName');
      this.ppId = this.exploreS3BucketData.ppId || sessionStorage.getItem('ppId');
      this.showSpinner = true;
      
      this.researchService.getProvisionedProductDetails(this.ppId).subscribe((res:any) => {
        this.productDetails = res;
        this.productName = res.ProductName;
        this.provisionedProductName = res.ProvisionedProductName;
        this.ppId = res.ProvisionedProductId;
        this.productStatus = res.Status;
        this.account_id = res.account_id;
        this.resources = res.Resources;
        this.isOwner = res.isOwner;
        this.isShared = res.isShared;
        this.isProjectStorage = res.isProjectStorage;
        this.isIngressStorage = res.isIngressStorage;
        if(this.isIngressStorage){
          this.listIngressPendingRequests();
          this.serverStreamEvent();
        }
        this.showSpinner = false;
        this.bucketName = res.Resources[0].PhysicalResourceId;
        sessionStorage.setItem('bucketName',this.bucketName);
        this.router.queryParams.subscribe(map => map);
        this.folderName = this.router.snapshot.queryParams['folder'];
        this.productDetails.budgetConsumed = this.productDetails.budgetConsumed.toFixed(2);
        this.prefix = this.router.snapshot.queryParams['prefix'];
        if(params["message"]==="uploadSuccess" || params["message"]==="deleteSuccess"){
          this.bucketName = this.router.snapshot.params['bucketName'];
          this.exploreS3BucketData = this.s3DataService.getS3ExploreData();
          this.projectName = this.exploreS3BucketData.projectName;
          this.productName = this.exploreS3BucketData.productName;
          this.exploreS3BucketData.action = 'explore';
          this.exploreS3BucketData.bucketName = this.bucketName;
          this.provisionedProductName = this.exploreS3BucketData.provisionedProductName;
          this.autoRefreshInfo = this.s3DataService.getS3FolderPrefix();
          if(this.autoRefreshInfo.rootFolder == true){
            this.route.navigate([`s3/bucket/${this.bucketName}`]);
          }else{  
              this.route.navigate(['/s3/bucket/' + this.bucketName ],{ queryParams: { folder: this.autoRefreshInfo.folderName}})
          }
          } else if(this.route.url == '/s3/bucket/'+this.bucketName){
          this.getS3BucketContent();
        }else{
          this.showSpinner = false;
          if(this.folderName){
            this.getS3FolderData(this.folderName)
          }else{
            this.getS3BucketContent();
          }  
        }
      }, error => {
        this.showSpinner = false;
        let errBody = error.error;
        if(error && error.status === 401){
           this.toastr.error(errBody.err, '', this.globals.tosterOverride);
           this.route.navigate(['login']);
         }else if (error && error.status === 403) {
          const err = errBody.error ? errBody.error: errBody;
          this.toastr.error(err, 'Error', this.globals.tosterOverride);
          this.route.navigate(['pageNotFound']);
        }else{
          this.toastr.error(errBody, 'Error', this.globals.tosterOverride);
          }
      });

      sessionStorage.setItem('projectName',this.exploreS3BucketData.projectName || this.projectName);
      sessionStorage.setItem('productName',this.exploreS3BucketData.productName || this.productName);
      sessionStorage.setItem('provisionedProductName',this.exploreS3BucketData.provisionedProductName || this.provisionedProductName);
      sessionStorage.setItem('productStatus',this.exploreS3BucketData.status || this.productStatus);
      sessionStorage.setItem('ppId',this.exploreS3BucketData.ppId || this.ppId);
      sessionStorage.setItem('projectStatus',this.exploreS3BucketData.projectStatus || this.projectStatus);
      sessionStorage.setItem('account_id',this.exploreS3BucketData.account_id || this.account_id);
      sessionStorage.setItem('isShared',this.exploreS3BucketData.isShared || this.isShared);
      sessionStorage.setItem('isProjectStorage',this.exploreS3BucketData.isProjectStorage || this.isProjectStorage);
    })
    this.userLevel = sessionStorage.getItem('Level');
    this.projectId = sessionStorage.getItem('projectId');
  }

  getEventSource(url: string): EventSource {
    return new EventSource(url, { withCredentials: true });
  }

  getS3BucketContent(type='getData', isSearch=false){
    this.showSpinner = true;
    this.rootFolder = true;
    const data = {
      bucketName: this.bucketName,
      prefix: this.prefix,
      limit: this.limit,
      account_id: this.account_id,
      next: this.next,
      projectId : this.projectId
    };
    if(isSearch==true){
        this.bucketData = [];
    }
    this.serviceAction.getS3Data(data).subscribe((res:any) => {
      this.showSpinner = false;
      if(res){
        //Remove duplicate prefix
        if(type == 'back'){
          var newS3PrefixArray = res.Content.filter((item) => item.Key !== this.prefix);
          res.Content = newS3PrefixArray;
        }
        if('next' in res && res.next !== null){
          this.next = res.next;
          this.showLoadMore = true;
        }else{
          this.showLoadMore = false
        }

        if('Prefix' in res){
          this.prefix = res.Prefix;
        }
        if ('PreSignedUrlAccess' in res){
          this.preSignedUrlAccess = res.PreSignedUrlAccess
        }

        if('Content' in res){
          this.bucketData = [...this.bucketData, ...res.Content];
        }
      }
    }, error => {
      this.showSpinner = false;
      console.error(error);
      let errBody = error.error;
      if(error && error.status === 401){
       this.toastr.error(errBody.err, '', this.globals.tosterOverride);
       this.route.navigate(['login']);
      }else{
      this.toastr.error(errBody, 'Error', this.globals.tosterOverride);
      }
    })
  }

  getS3FolderData(folderName, isSearch=false){
    if(folderName.substr(-1) == '/') {
      folderName = folderName.substr(0, folderName.length - 1);
    }
    const data = {
      bucketName: this.bucketName,
      prefix: this.prefix || "",
      limit: this.limit,
      account_id: this.account_id,
      next: this.next,
      folderName: folderName,
      projectId : this.projectId
    };
    this.showRootIcon = true;
    this.showLoadMore = false;
    this.showSpinner = true;
    this.folderName = folderName;
    if(this.prefix){
      this.folderName = this.prefix+folderName;
    }
    if(isSearch==true){
      this.bucketData = [];
    }
    this.route.navigate(['/s3/bucket/' + this.bucketName ],{ queryParams: { folder: folderName, prefix: this.prefix}})
    this.router.queryParams.subscribe(map => map);
    this.serviceAction.getS3Data(data).subscribe((res:any) => {
      this.showSpinner = false;
      if(res){ 
        //Remove duplicate prefix
        let newS3PrefixArray
        if(this.prefix){
          newS3PrefixArray = res.Content.filter((item) => item.Key !== this.prefix+folderName+'/');
        }else{
          newS3PrefixArray = res.Content.filter((item) => item.Key !== folderName+'/');
        }
        res.Content = newS3PrefixArray;
        if('next' in res && res.next !== null){
          this.next = res.next;
          this.showLoadMore = true;
        }else{
          this.showLoadMore = false;
        }
        if('Prefix' in res){
          this.prefix = res.Prefix;
        }
        if ('PreSignedUrlAccess' in res){
          this.preSignedUrlAccess = res.PreSignedUrlAccess
        }

        if('Content' in res){
          this.bucketData = [...this.bucketData, ...res.Content];
        }
      }
    }, error => {
      this.showSpinner = false;
      console.error(error);
      let errBody = error.error;
     if(error && error.status === 401){
      this.toastr.error(errBody.err, '', this.globals.tosterOverride);
      this.route.navigate(['login']);
      }else{
      this.toastr.error(errBody.err, 'Error', this.globals.tosterOverride);
      }
    })
  }

  
  getFileType(data){
    if(data.type != 'folder' && data.Key){
      if(data.Key.indexOf('.') !== -1){
        return data.Key.toUpperCase().split('.').pop();
      }
    }
    else{
      this.type = 'folder';
      return 'Folder';
    }
  }

 onCopySuccess(){
    this.toastr.success(`Copied path successfully`, '', this.globals.tosterOverride);
  }

  onCopyError(){
    this.toastr.error('Copy Error', '', this.globals.tosterOverride)
  }

  deleteS3File(key){
    this.exploreS3BucketData.action = 'deletefile';
    this.exploreS3BucketData.key = key;
    this.exploreS3BucketData.folderName = this.folderName;
    this.exploreS3BucketData.rootFolder = this.rootFolder;
    this.exploreS3BucketData.account_id = this.account_id;
    this.exploreS3BucketData.service = this.service;
    this.exploreS3BucketData.bucketName = this.bucketName;
    this.exploreS3BucketData.projectId = this.projectId;
    this.exploreS3BucketData.provisionedProductId = this.ppId;
    if(this.isIngressStorage){
      //Getting only filename if it is IngressStorage
      this.exploreS3BucketData.filePath = key.split('/').pop()
    }else{
      this.exploreS3BucketData.filePath = key
    }
    this.apiService.getDialogBox().subscribe((data:any) => {
      if(data.status === 200){
    this.dialog.showCustomDialog({
      component: S3FileDeleteComponent,
      providers: [{ provide: 'data', useValue: { s3FileObj: this.exploreS3BucketData} }],
      isModal: true,
      styles: {
        "height":"auto",
        "width":"40%",
        "border": "1px solid #FFFFFF33",
        "border-radius": "10px",
        "background-color": "#07102A",
        "padding": "0px"
      },
      clickOutsideToClose: true,
      enterTransitionDuration: 400,
      leaveTransitionDuration: 400,
    })}}, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.route.navigate(['login']);
       }
    })
  }

  deleteS3Folder(prefix){
    this.exploreS3BucketData.account_id = this.account_id;
    this.exploreS3BucketData.bucketName = this.bucketName;
    this.exploreS3BucketData.service = this.service;
    this.exploreS3BucketData.action = 'deletefolder';
    this.exploreS3BucketData.name = prefix;
    this.exploreS3BucketData.projectId = this.projectId;
    this.exploreS3BucketData.provisionedProductId = this.ppId;
    if(this.folderName){
      this.exploreS3BucketData.name = this.folderName+'/'+prefix;
    }
    this.exploreS3BucketData.folderName = this.folderName;
    this.exploreS3BucketData.rootFolder = this.rootFolder;
    this.apiService.getDialogBox().subscribe((data:any) => {
      if(data.status === 200){
    this.dialog.showCustomDialog({
      component: S3FileDeleteComponent,
      providers: [{ provide: 'data', useValue: { s3Obj: this.exploreS3BucketData } }],
      isModal: true,
      styles: {
        "height":"auto",
        "width":"40%",
        "border": "1px solid #FFFFFF33",
        "border-radius": "10px",
        "background-color": "#07102A",
        "padding": "0px"
      },
      clickOutsideToClose: true,
      enterTransitionDuration: 400,
      leaveTransitionDuration: 400,
    })}}, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.route.navigate(['login']);
       }
    })
  }

  downloadS3(key){
    this.exploreS3BucketData.account_id = this.account_id;
    this.exploreS3BucketData.bucketName = this.bucketName;
    this.exploreS3BucketData.service = 's3';
    this.exploreS3BucketData.action = 'downloads3';
    this.exploreS3BucketData.key = key;
    this.exploreS3BucketData.projectId = this.projectId;
    this.exploreS3BucketData.provisionedProductId = this.ppId;
    this.serviceAction.serviceAction(this.exploreS3BucketData).subscribe((res:any) => {
      this.showSpinner = false;
      window.open(res.url)
    }, error => {
      console.error(error);
        let errBody = error.error;
      if(error && error.status === 401){
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.route.navigate(['login']);
       }else {
      this.toastr.error(`${this.bucketName} - `+errBody.error, 'Error', this.globals.tosterOverride)
       }
    });
  }

  downloadS3FromCheckBoxValue(){
    this.exploreS3BucketData.account_id = this.account_id;
    this.exploreS3BucketData.bucketName = this.bucketName;
    this.exploreS3BucketData.service = 's3';
    this.exploreS3BucketData.action = 'downloads3';
    this.exploreS3BucketData.key = '';
    this.exploreS3BucketData.projectId = this.projectId;
    this.exploreS3BucketData.provisionedProductId = this.ppId;
    for(var i=0;i< this.checkBoxValue.length; i++){
      this.exploreS3BucketData.key = this.checkBoxValue[i].Key;
    }
    this.serviceAction.serviceAction(this.exploreS3BucketData).subscribe((res:any) => {
      this.showSpinner = false;
      window.open(res.url)
    }, error => {
      console.error(error);
      let errBody = error.error;
      if(error && error.status === 401){
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.route.navigate(['login']);
       }else{
      this.toastr.error(`${this.bucketName} - `+errBody.error, 'Error', this.globals.tosterOverride)
    }
    });
  }
 
  createCopyPreSignedURL(key){
    this.exploreS3BucketData.account_id = this.account_id;
    this.exploreS3BucketData.bucketName = this.bucketName;
    this.exploreS3BucketData.service = 's3';
    this.exploreS3BucketData.action = 'createPreSignedURL';
    this.exploreS3BucketData.key = key;
    this.exploreS3BucketData.projectId = this.projectId;
    this.exploreS3BucketData.provisionedProductId = this.ppId;
    this.serviceAction.serviceAction(this.exploreS3BucketData).subscribe((res:any) => {
      this.showSpinner = false;
      this.preSignedUrl = res.url.replace(/"/g,'')
      this.clipboardApi.copyFromContent(this.preSignedUrl)
      this.toastr.success(`The pre-signed URL has been created and copied to the clipboard`, '', this.globals.tosterOverride);
    }, error => {
      console.error(error);
      let errBody = error.error;
      if(error && error.status === 401){
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.route.navigate(['login']);
       }else{
        this.toastr.error('The pre-signed URL create and copy Error ', '', this.globals.tosterOverride)
       }
    })
  } 

  createCopyPreSignedURLFromCheckBoxValue(){
    this.exploreS3BucketData.account_id = this.account_id;
    this.exploreS3BucketData.bucketName = this.bucketName;
    this.exploreS3BucketData.service = 's3';
    this.exploreS3BucketData.action = 'createPreSignedURL';
    this.exploreS3BucketData.key = '';
    this.exploreS3BucketData.projectId = this.projectId;
    this.exploreS3BucketData.provisionedProductId = this.ppId;
    for(var i=0;i< this.checkBoxValue.length; i++){
      this.exploreS3BucketData.key = this.checkBoxValue[i].Key;
    }
    this.serviceAction.serviceAction(this.exploreS3BucketData).subscribe((res:any) => {
      this.showSpinner = false;
      this.preSignedUrl = res.url.replace(/"/g,'')
      this.clipboardApi.copyFromContent(this.preSignedUrl)
      this.toastr.success(`The pre-signed URL has been created and copied to the clipboard`, '', this.globals.tosterOverride);
    }, error => {
      console.error(error);
      let errBody = error.error;
      if(error && error.status === 401){
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.route.navigate(['login']);
       }else{
        this.toastr.error('The pre-signed URL create and copy Error ', '', this.globals.tosterOverride)
       }
    })
}

  copyClipboard(){
    let copyPaths = this.checkBoxValue.map((checkBox) =>{
      let key;
      if(checkBox.type == 'folder'){
       key = checkBox.Key || checkBox.name;
       let folder = this.folderName ? this.folderName+'/' : ""
      return (this.service === 'AWS') ? 's3://'+checkBox.bucketName+'/'+folder+key : 'https://'+checkBox.bucketName+'.blob.core.windows.net/'+folder+key
      }else{
        key = checkBox.Key || checkBox.name;
      return (this.service === 'AWS') ? 's3://'+checkBox.bucketName+'/'+key : 'https://'+checkBox.bucketName+'.blob.core.windows.net/'+key
      }
    })
    let copy = copyPaths.toString().replace(/ *, */g, '\n');
    return copy;
   }
  

   copyPath(data){
    let key;
    let path;
    if(data.type == 'folder'){
    key = data.Key || data.name;
    let folder = this.folderName ? this.folderName+'/' : ""
     path =  (this.service === 'AWS') ? ('s3://'+this.bucketName+'/'+folder+key) : ('https://'+this.bucketName+'.blob.core.windows.net/'+folder+key) ;
    }else {
      key = data.Key || data.name;
      path = (this.service === 'AWS') ? ('s3://'+this.bucketName+'/'+key) : ('https://'+this.bucketName+'.blob.core.windows.net/'+key);
    }
    return path;
   }

  checkForFileType(){
    for(var i=0; i<this.checkBoxValue.length; i++){
      if(this.checkBoxValue[i].type == 'folder'){
          this.selectedFileType = 'folder';
      }else{
        this.checkBoxValue[i].type = 'file';
        this.selectedFileType = 'file';
      }
    }
  }

 uploadFilesToSpecificFolder(prefix){
  this.rootFolder = false;
  let body = {
    "service": "s3",
    "action": "upload",
    "account_id": this.account_id,
    "resources": this.resources,
    "provisionedProductName": this.provisionedProductName,
    "productName": this.productName,
    "rootFolder": this.rootFolder,
    "projectName": this.projectName,
    "prefix": prefix
  };
  if(this.folderName){
    body.prefix = this.folderName+'/'+prefix+'/';
    prefix = this.folderName+'/'+prefix+'/';
  }else{
    body.prefix = prefix+'/';
    prefix = prefix+'/';
  }
  sessionStorage.setItem('prefix',prefix);
  this.s3DataService.setS3UploadData(body);
  this.route.navigate(['/s3Upload',{ service: this.service}]);
}

createS3EmptyFolder(){
  this.searchKey = "";
  let body = {
    "service": this.service,
    "account_id": this.account_id,
    "resources": this.resources,
    "provisionedProductName": this.provisionedProductName,
    "bucketName": this.bucketName,
    "action": 'createfolder',
    "folderName": this.folderName,
    "rootFolder": this.rootFolder,
    "provisionedProductId": this.ppId,
    "projectId": this.projectId,
    "checkProductType": true
  };
  this.apiService.getDialogBox().subscribe((data:any) => {
    if(data.status === 200){
  this.dialog.showCustomDialog({
    component: S3EmptyFolderComponent,
    providers: [{ provide: 'data', useValue: { s3Folderobj: body} }],
    isModal: true,
    styles: {
      "height":"auto",
        "width":"40%",
        "border": "1px solid #FFFFFF33",
        "border-radius": "10px",
        "background-color": "#07102A",
        "padding": "0px"
    },
    clickOutsideToClose: true,
    enterTransitionDuration: 400,
    leaveTransitionDuration: 400,
  })}}, error => {
    if(error && error.status === 401){
      let errBody = error.error;
       this.toastr.error(errBody.err, '', this.globals.tosterOverride);
       this.route.navigate(['login']);
     }
  })
}

uploadFiles(){
  let body = {
    "service": "s3",
    "action": "upload",
    "account_id": this.account_id,
    "resources": this.resources,
    "provisionedProductName": this.provisionedProductName,
    "productName": this.productName,
    "rootFolder": this.rootFolder,
    "projectName": this.projectName,
    "prefix": this.folderName
  };
  if(this.folderName){
    body.prefix = this.folderName+'/'
  }else{
    body.prefix = "";
  }
  sessionStorage.setItem('prefix',this.folderName);
  this.s3DataService.setS3UploadData(body);
  this.route.navigate(['/s3Upload',{ service: this.service}]);
}

goBack() {
  this.folderName = "";
  if(this.prefix){
    let prefix = this.prefix.split('/');
    prefix.splice(-2, 1);
    this.showRootIcon = true;
    this.bucketData = [];
    if(prefix.length < 2){
      this.showRootIcon = false;
    }
    if(prefix.length > 0){
      this.folderName = prefix.join('/');
      this.prefix = prefix[-1];
      this.next = null;
      //this.route.navigate(['/s3/bucket/' + this.bucketName ],{ queryParams: { folder: this.folderName, prefix: this.prefix}})
      this.redirectToFolder();
    }else{
      this.prefix = "";
      this.routeBack();
    }
  }else{
    this.prefix = "";
    this.bucketData = [];
    this.routeBack();
  }
}

redirectToFolder(){
  this.route.navigateByUrl('/', {skipLocationChange: true}).then(()=>
  this.route.navigate(['/s3/bucket/' + this.bucketName ],{ queryParams: { folder: this.folderName, prefix: this.prefix}}));
}

routeBack(){
  const projectName = encodeURIComponent(this.projectName);
  this.route.navigate(['/product/' + projectName + '/' +
    this.productName, { ppId: this.ppId }],{ queryParams: { projectStatus: this.projectStatus}});
}

goBackToRootFolder(){
  this.route.navigate([`s3/bucket/${this.bucketName}`]);
  this.next = null;
  this.bucketData = [];
  this.folderName = "";
  this.prefix = "";
  this.showRootIcon = false;
  this.getS3BucketContent();
}

getStatus(status) {
  let statusV = status;
  let state = statusV.toUpperCase();
  switch (state) {
    case 'INITIATED':
      statusV = 'Initiated';
      break;
    case 'CREATE_IN_PROGRESS':
      statusV = 'Creating';
      break;
    case 'CREATE_COMPLETE':
      statusV = 'Provisioned';
      break;
    case 'CREATE_FAILED':
      statusV = 'Failed';
      break;
    case 'ROLLBACK_IN_PROGRESS':
      statusV = 'Failed';
      break;
    case 'ROLLBACK_COMPLETE':
      statusV = 'Failed';
      break;
    case 'CREATED':
      statusV = 'Provisioned';
      break;
    case 'AVAILABLE':
      statusV = 'Provisioned';
      break;
    case 'ERROR':
      statusV = 'Failed';
      break;
    case 'DELETE_IN_PROGRESS':
      statusV = 'Terminating';
      break;
    case 'DELETE_COMPLETE':
      statusV = 'Terminated';
      break;
    case 'Synchronizing':
      statusV = 'Synchronizing';
      break;
    default :
      break;
  }
  return statusV;
}

getStatusColor(status) {
  let statusV = 'status-paused';
  switch (status.toLowerCase()) {
    case 'active':
      statusV = 'status-running';
      break;
    case 'warning':
      statusV = 'status-paused';
      break;
    case 'failed':
      statusV = 'status-stopped';
      break;
    case 'create_in_progress':
      statusV = 'status-paused';
      break;
    case 'create_complete':
      statusV = 'status-running';
      break;
    case 'create_failed':
      statusV = 'status-stopped';
      break;
    case 'rollback_complete':
      statusV = 'status-stopped';
      break;
    case 'available':
      statusV = 'status-running';
      break;
    case 'created':
      statusV = 'status-running';
      break;
    case 'error':
      statusV = 'status-stopped';
      break;
    case 'Synchronizing':
      statusV = 'status-paused';
      break;
    default :
      statusV = 'status-paused';
      break;
  }
  return statusV;
}

loadMoreObjects(){
  this.getS3BucketContent();
}

refresh(){
  this.bucketData = [];
  this.next = '';
  this.getS3BucketContent();
 }

searchFilesInS3(){
  //After 2 seconds search would be trigged
  setTimeout(() => {
    this.currentPage = 1;
    this.next = "";
    const searchValue = this.searchKey.trim();
    this.searchInS3 = true;
    if (this.searchValueTemp === this.searchKey) {
      return;
    }
    if (searchValue.length < 3 && searchValue.length > 0) {
      return;
    }
    if (searchValue.length > 2) {
      this.searchValue = true;
      this.searchValueTemp = this.searchKey;
      this.getSearchData();
    } else {
      if (this.searchValue === true) {
        this.searchValueTemp = this.searchKey;
        if(this.folderName !=undefined && this.folderName != ""){
          this.router.queryParams.subscribe(map => map);
          this.folderName = this.router.snapshot.queryParams['folder'];
          this.prefix = this.router.snapshot.queryParams['prefix'];
          this.getS3FolderData(this.folderName, true);
        }else{
          this.getS3BucketContent('getData', true);
        }
      }
    }   
  }, 2000);
}

  onCheckboxChanged(event, value = {}) {
    if (event.target.checked) {
      this.checkBoxValue.push(value);
      for (var i = 0; i < this.checkBoxValue.length; i++) {
        this.checkBoxValue[i].bucketName = this.bucketName;
        this.checkBoxValue[i].folderName = this.folderName;
      }
    }
    if (!event.target.checked) {
      let index = this.checkBoxValue.indexOf(value);
      if (index > -1) {
        this.checkBoxValue.splice(index, 1);
      }
    }
  }

allCheckBoxSelected(event) {
  this.checkBoxValue = [];
  const checked = event.target.checked;
  this.bucketData.forEach(item => item.isChecked = checked);
  this.bucketPrefixData.forEach(item => item.isChecked = checked);
  for(var i=0; i<this.bucketData.length; i++){
    this.bucketData[i].bucketName = this.bucketName;
    this.bucketData[i].folderName = this.folderName;
    this.checkBoxValue.push(this.bucketData[i]);
  }

  if (!event.target.checked) {
    this.checkBoxValue = [];
  }
}

deleteMultipleS3Data(){
  for(let i=0;i< this.checkBoxValue.length; i++){
   if(this.checkBoxValue[i].name != undefined && this.folderName != undefined && this.folderName != ""){
        this.checkBoxValue[i].name = this.folderName+'/'+this.checkBoxValue[i].name;
      }else{
        let prefixVal = this.folderName || this.checkBoxValue[i].name;
        if(prefixVal!= undefined && prefixVal != "" && prefixVal !=null){
          this.checkBoxValue[i].prefix = prefixVal +'/';
        }else{
          this.checkBoxValue[i].prefix = "";
        }
        
      }
  }
  this.apiService.getDialogBox().subscribe((data:any) => {
    if(data.status === 200){
  this.dialog.showCustomDialog({
    component: S3FileDeleteComponent,
    providers: [{ provide: 'data', useValue: { s3FileCheckBoxObj: this.checkBoxValue, account_id:this.account_id, bucketName: this.bucketName, rootFolder: this.rootFolder, service: this.service } }],
    isModal: true,
    styles: {
      "height":"auto",
      "width":"40%",
      "border": "1px solid #FFFFFF33",
      "border-radius": "10px",
      "background-color": "#07102A",
      "padding": "0px"
    },
    clickOutsideToClose: true,
    enterTransitionDuration: 400,
    leaveTransitionDuration: 400,
  })}}, error => {
    if(error && error.status === 401){
      let errBody = error.error;
       this.toastr.error(errBody.err, '', this.globals.tosterOverride);
       this.route.navigate(['login']);
     }
  })
}

getFileSize(size){
    var sizes = [' Bytes', ' KB', ' MB', ' GB', ' TB', ' PB', ' EB', ' ZB', ' YB'];
    for (var i = 1; i < sizes.length; i++)
    {
        if (size < Math.pow(1024, i)) return (Math.round((size/Math.pow(1024, i-1))*100)/100) + sizes[i-1];
    }
    return size;
}

getFiletName(data, display) {
  if(data){
    let fileName = data.split('/');
    fileName = fileName[fileName.length - 1];
    const strLen = fileName.length;
    let str = fileName;
    if (strLen > 33 && display=='table') {
      str = str.slice(0, 33) + '...';
    }
    return str; 
  }else{
    return
  }
}

getSearchData(){
  this.showSpinner = true;
  this.searchInS3 = true;
  this.bucketData = [];
  this.showLoadMore = false;
  let folderVal = this.folderName ? this.folderName+'/' : "";
  this.serviceAction.searchFilesInS3(this.bucketName, this.searchKey, folderVal, this.account_id, this.currentPage, this.limit ,this.projectId).subscribe((s3Data:any) => {
    if(s3Data){
      this.showSpinner = false;
      if('Content' in s3Data){
        this.bucketData = s3Data.Content;
      }
  }
  }, error => {
    this.showSpinner = false;
    this.searchInS3 = false;
    console.error(error);
    let errBody = error.error;
    if(error && error.status === 401){
    this.toastr.error(errBody.err, '', this.globals.tosterOverride);
    this.route.navigate(['login']);
     } else {
    this.toastr.error(`${this.bucketName} - `+errBody.message, 'Error', this.globals.tosterOverride)
   }
  });
}

navigateToProject(){
  const projectName = encodeURIComponent(this.projectName);
  sessionStorage.setItem('projectName', this.projectName);
  this.route.navigate(['/catalog/' + projectName + '/' + this.account_id ]);
}

submitIngressRequest(){
  this.clicked = true;
  this.isAbleToSubmitIngressRequest = false;
  this.ingressStoreLoader = true;
  let reqBody = {
    projectId: this.projectId
  }
  this.researchService.sendIngressRequest(reqBody).subscribe((res:any) => {
    this.ingressStoreLoader = false;
    this.isAbleToSubmitIngressRequest = true;
    this.toastr.success(`Ingress request submitted successfully`, '', this.globals.tosterOverride)
  }, error => {
    this.clicked = false;
    this.ingressStoreLoader = false;
    let errBody = error.error;
    if(error && error.status === 401){
      this.toastr.error(errBody.err, '', this.globals.tosterOverride);
      this.route.navigate(['login']);
     }else{
        this.toastr.error(errBody.message, 'Error in ingress request', this.globals.tosterOverride);
     }
  });
}

listIngressPendingRequests(){
  //If there are any pending ingress request for the user, then ingress request submission would be disabled
  this.researchService.listIngressPendingRequests(this.projectId).subscribe((res:any) => {
    this.ingressPendingRequests = res.ingressRequestList;
    if(this.ingressPendingRequests.length > 0 ){
      this.isAbleToSubmitIngressRequest = false;
    }else if (res.isFileFolderExist != true){
      this.isAbleToSubmitIngressRequest = false;
    }else{
      this.isAbleToSubmitIngressRequest = true;
    }
  }, error => {
    this.isAbleToSubmitIngressRequest = true;
    let errBody = error.error;
    if(error && error.status === 401){
      this.toastr.error(errBody.err, '', this.globals.tosterOverride);
      this.route.navigate(['login']);
     }else{
        this.toastr.error(errBody.message, 'Error in get ingress requests', this.globals.tosterOverride);
     }
  });
}

 //SSE for latest s3 events
 private serverStreamEvent() {
  let user = sessionStorage.getItem('currentUserEmail');
  this.researchService.getServerSentEvent(this.eventS).subscribe(res => {
    const resObj = res.data;
    if (!resObj) {
      return;
    }
    try {
      const { status, data, payload } = JSON.parse(resObj);
      // server give handshake to the alive connection
      if (data === 'check') {
        return;
      }
      if (data === 'provisionProduct') {
        console.log("Got provisionProduct SSE event"+ JSON.stringify(payload));
        const { ProvisionedProductId , Type, ResourceType, userPrefix } = payload;
        if(Type === 'IngressRequestComplete' && ResourceType === 'aws.s3' && userPrefix == user) {
          console.log(`Got SSE event for ingress request complete ${userPrefix}`);
          this.clicked = false;
          this.listIngressPendingRequests();
        }else{
          return;
        }
      }
    } catch (e) {
      console.log(e.message);
    }
  });
}

}