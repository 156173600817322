<div class="container">
  <!-- breadcrumb -->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb bc-custom">
      <li class="breadcrumb-item"><a class="bc-item" href="researcher/studies">Studies</a></li>
      <li class="breadcrumb-item"><a class="bc-item-active">{{studyName}}</a></li>
    </ol>
  </nav>
  <div style="position: relative;">
    <div class="study-info-wrapper">
      <h3 class="study-title font-class">{{studyName}}</h3>
      <div class="tags-wrapper" *ngIf="studyDetails">
        <div class="tag-content" *ngFor="let tag of studyDetails.showTags">
          <span class="tag-txt">{{tag}}</span>
        </div>
        <div class="more-tags-container">
          <span class="more-tag-content more-tag-txt" *ngIf="studyDetails.moreTagsCount">+ {{studyDetails.moreTagsCount}} more tags</span>
          <ul style="display:none;">
            <div style="padding: 15px; background: #06153E; border-radius: 5px; width: max-content;">
              <span style="color: white; font-size: 12px; display: block;" *ngFor="let tag of studyDetails.moreTags">{{tag}}</span>
            </div>
          </ul>
        </div>
      </div>
    </div>
    <div class="study-type-container" *ngIf="studyDetails">
      <span class="study-type-txt font-class">{{studyDetails.studyType[0].toUpperCase() + studyDetails.studyType.substring(1) }} Study</span>   
         <div>
        <!-- <div style="display: flex; align-items: center; margin-top: 20px;" *ngIf="studyDetails.studyType !== 'Public' ">
          <img class="study-type-project-icon" src="../../assets/images/study_product.png"/>
          <span class="study-type-project-name font-class" *ngIf="studyDetails.studyType !== 'Public'">{{studyDetails.projectName}}</span>
        </div> -->
        <div style="display: flex; align-items: center; margin-top: 20px;" *ngIf="studyDetails.studyType === 'Public'">
          <img class="study-type-project-icon" src="../../assets/images/product.svg" alt="Public Study"/>
          <span class="study-type-project-name font-class" *ngIf="studyDetails.studyType === 'Public'">{{studyDetails.repositoryName}}</span>
        </div>
        
        <div *ngIf="studyDetails.studyType == 'external'" style="height: 45px; display: flex; margin-top: 20px;border: 1px solid #465374; border-radius: 23px; align-items: flex-start; padding: 10px;
           margin-right: 75px; margin-left: 10px; justify-content: center;">
          <div style="width: 20px; height: 20px; border-radius: 10px;" class="{{getStatusColor(studyDetails.status)}}" *ngIf="studyDetails.studyType === 'external'"></div>
          <span style="margin: 0px 10px; font-size: 14px;" class="text-white font-class" >{{getStatus(studyDetails.status)}}</span>
        </div>
      
        <div class="more-tags-container" style="display: flex; align-items: center; margin-top: 10px; cursor: default;" *ngIf="studyDetails.studyType !== 'Public' && studyDetails.shared && studyDetails.shared.length > 0" >
          <img class="study-type-project-icon" src="../../assets/images/study_share.png" alt="share Study"/>
          <span class="study-type-project-name font-class" *ngIf="studyDetails.shared.length > 1">{{studyDetails.showShared}} and
            <span style="text-decoration: none" tooltip="{{studyDetails.moreShared}}" placement="bottom" *ngIf="studyDetails.shared.length > 2">{{studyDetails.moreSharedCount}} others</span>
            <span style="text-decoration: none" tooltip="{{studyDetails.moreShared}}" placement="bottom" *ngIf="studyDetails.shared.length === 2">{{studyDetails.moreSharedCount}} other</span>
          </span>
<!--          <ul style="display:none;">-->
<!--            <div style="padding: 15px; background: #06153E; border-radius: 5px; width: max-content;">-->
<!--              <span style="color: white; font-size: 12px; display: block;" *ngFor="let tag of studyDetails.moreShared">{{tag}}</span>-->
<!--            </div>-->
<!--          </ul>-->
          <span class="study-type-project-name font-class" *ngIf="studyDetails.shared.length === 1">{{studyDetails.showShared}}</span>
        </div>
      </div>
      <!-- mobile actions -->
      <div class="mobile-action-control">
        <div class="tab-action links-unordered">
          <a class="toggle-button">Actions</a>
          <ul style="display:none;">
            <div style="display: flex; flex-direction: column">
              <span class="action-title font-class">CONNECT</span>
              <div class="tab-flex">
                <div class="action-link" *ngFor="let connect of connectMenu" (click)="actions(connect.menu)">
                  <img class="action-icon" src="{{connect.imageUrl}}" alt="{{connect.menu}} Study"/>
                  <span class="action-value text-white font-class">{{connect.menu}}</span>
                </div>
                <div *ngIf="showAssignStudyButton && studyDetails.studyType === 'Public'" class="action-link" (click)="assignStudyToProject()">
                  <img class="action-icon" src="../../assets/images/link@2x.png" alt="Assign Public Study to Project"/>
                  <span class="action-value text-white font-class">Assign To Projects</span>
                </div>
              </div>
              <span style="margin-top: 30px;" class="action-title font-class" *ngIf="actionMenu && actionMenu.length > 0">ACTIONS</span>
              <div class="tab-flex">
                <div class="action-link" *ngFor="let action of actionMenu" (click)="actions(action.action)">
                  <img class="action-icon" src="{{action.imageUrl}}" alt="{{action.action}} Study"/>
                  <span class="action-value text-white font-class" [ngStyle]="{'margin-left': (action.action === 'Terminate') ? '5px' : '0px'}">{{action.action}}</span>
                </div>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- tab section -->
  <div class="tab-container" *ngIf="studyDetails">
    <tabset #studyTabs>
      <!-- product details tab -->
      <tab class="tab" heading="Study Details">
        <div class="product-details-layout">
          <div class="product-details-wrapper">
            <label *ngIf="studyDetails.studyType == 'external' && studyDetails.status == 'Error' " style ="margin-top: 14px; font-weight: 400; font-size: 14px; text-align: justify;  color: #f32561;">{{ studyDetails.errorMessage}}</label>
            <label class="product-key-txt font-class">Description</label>
            <label class="product-value-txt text-white font-class" >{{studyDetails.description}}</label>    
            <label class="product-key-txt font-class">Assigned Projects</label>
            <div *ngIf="showProjectList.length == 0">
              <label class="product-value-txt text-white font-class">
                No projects are assigned to this study
              </label>
            </div>
            <div *ngFor="let projectName of showProjectList">
              <span class="product-value-txt text-white font-class" *ngIf="showProjectList.length !== 0" >{{projectName}}</span>
          </div>
           </div>
       
<!--          <div [innerHTML]="studyDetails.moreInformation"></div>-->
        </div>
      </tab>
      <!-- resource details tab for public study -->
      <tab class="tab" heading="{{studyDetails.studyType === 'Public' || 'Internal' ? 'Resource Details' : 'Product Details'}}">
        <div class="product-details-layout">
          <div class="product-details-wrapper" *ngFor="let product of productDetails">
            <label class="product-key-txt font-class">{{product.key}}</label>
            <label class="product-value-txt text-white font-class">{{product.value}}</label>
          </div>
          <div class="linked-product-layout" *ngIf="linkedResources && linkedResources.length > 0">
            <label class="product-key-txt font-class">Linked Products</label>
            <div style="display: flex; flex-wrap: wrap;">
              <div class="product-details-wrapper linked-product-wrapper" *ngFor="let linkedResource of linkedResources">
                <div style="display: flex; align-items: center;">
                  <img style="height: 30px;height: 30px;margin-right: 15px;border-radius: 5px;"  src="{{linkedResource.imageUrl}}" alt="Unlink Sagemaker"/>
                  <label style="margin-right: 30px; margin-bottom: 0px;" class="product-value-txt text-white font-class" title="{{linkedResource.ProvisionedProductName}}">{{trimProductName(linkedResource.ProvisionedProductName)}}</label>
                </div>
                <span style="align-self: center; position: absolute; right: 15px; padding: 5px;">
                    <i class="fa fa-ellipsis-v" data-toggle="dropdown" title="Action" aria-hidden="true" style="color: #f1f1f1;cursor: pointer; font-size: 25px;background: #07102A;"></i>
                    <ul class="dropdown-menu" style="cursor:pointer;background: #07102A; position: absolute; top: 0; right: 0 !important; width: 150px;">
                      <li><span class="listItems unlink-txt" (click)="unlinkResorce(linkedResource)">Unlink Resource</span></li>
                    </ul>
                </span>
              </div>
            </div>
          </div>
        </div>
      </tab>
    </tabset>
    <!-- actions -->
    <!-- tab action button -->
    <div class="tab-action-control">
      <div class="tab-action links-unordered">
        <a class="toggle-button">Actions</a>
        <ul style="display:none;">
          <div style="display: flex; flex-direction: column">
              <span class="action-title font-class">CONNECT</span>
              <div class="tab-flex">
                <div class="action-link" *ngFor="let connect of connectMenu" (click)="actions(connect.menu);$event.stopPropagation()">
                  <img class="action-icon" src="{{connect.imageUrl}}" alt="{{connect.menu}} Study"/>
                  <span class="action-value text-white font-class">{{connect.menu}}</span>
                </div>
                <div *ngIf="showAssignStudyButton && studyDetails.studyType === 'Public'" class="action-link" (click)="assignStudyToProject()">
                  <img class="action-icon" src="../../assets/images/link@2x.png" alt="Assign Public Study to Project"/>
                  <span class="action-value text-white font-class">Assign To Projects</span>
                </div>
              </div>
              <span style="margin-top: 30px;" class="action-title font-class" *ngIf="actionMenu && actionMenu.length > 0">ACTIONS</span>
              <div class="tab-flex">
                <div class="action-link" *ngFor="let action of actionMenu" (click)="actions(action.action);$event.stopPropagation()">
                  <img class="action-icon" src="{{action.imageUrl}}" alt="{{action.action}} Study"/>
                  <span class="action-value text-white font-class" [ngStyle]="{'margin-left': (action.action === 'Terminate') ? '5px' : '0px'}">{{action.action}}</span>
                </div>
              </div>
          </div>
        </ul>
      </div>
    </div>
    <div *ngIf= "studyDetails.studyType != 'external'" class="web-actions">
      <div style="display: flex; flex-direction: column">
        <span class="action-title font-class">CONNECT</span>
        <div class="tab-flex">
          <div class="action-link" *ngFor="let connect of connectMenu" (click)="actions(connect.menu);$event.stopPropagation()">
            <img class="action-icon" src="{{connect.imageUrl}}" alt="{{connect.menu}} Study"/>
            <span class="action-value text-white font-class">{{connect.menu}}</span>
          </div>
          </div>
          <div *ngIf="showAssignStudyButton && (studyDetails.studyType === 'Public')" class="action-link" (click)="assignStudyToProject()">
            <img class="action-icon" src="../../assets/images/link@2x.png" alt="Assign Public Study to Project"/>
            <span class="action-value text-white font-class">Assign To Projects</span>
          </div>
        <span style="margin-top: 30px;" class="action-title font-class" *ngIf="actionMenu && actionMenu.length > 0">ACTIONS</span>
        <div class="tab-flex">
          <div class="action-link" *ngFor="let action of actionMenu" (click)="actions(action.action);$event.stopPropagation()">
            <img class="action-icon" src="{{action.imageUrl}}" alt="{{action.action}} Study"/>
            <span class="action-value text-white font-class" [ngStyle]="{'margin-left': (action.action === 'Delete') ? '5px' : '0px'}">{{action.action}}</span>
          </div>
        </div>
              <!-- <div class="action-link" *ngIf="productDetails.Status != 'Transitioning'" (click)="actions(productDetails, 'Terminate')" style="background-color: #15244B; border-radius: 5px; width: 100%; height: 45px; display: flex; align-items: center; margin-top: 10px;margin-right: 9px;cursor: pointer;">
                <img style="height: 12px;height: 15px;margin-right: 15px;margin-left: 15px" src="../../assets/images/no-stopping@2x.png" />
                <span style="font-size: 12px;cursor: pointer;" class="text-white font-class">Terminate</span>
              </div> -->
      </div>
    </div>
    <div *ngIf="studyDetails.studyType == 'external' && (studyDetails.status != 'Creating' && studyDetails.status != 'Terminating')" class="web-actions">
      <div style="display: flex; flex-direction: column">
        <span style="margin-top: 0px;" class="action-title font-class" *ngIf="actionMenu && actionMenu.length > 0">ACTIONS</span>
        <div class="tab-flex" *ngFor="let action of actionMenu">
          <div class="action-link" *ngIf="action.action == 'Delete' || studyDetails.status != 'Error' "  (click)="actions(action.action);$event.stopPropagation()">
            <img class="action-icon" src="{{action.imageUrl}}" alt="{{action.action}} Study"/>
            <span class="action-value text-white font-class" [ngStyle]="{'margin-left': (action.action === 'Delete') ? '5px' : '0px'}">{{action.action}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-loader class="spinner" *ngIf="loader"></app-loader>
