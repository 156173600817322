<div>
    <div class="dialog-header">
    <img class="cancel-icon" (click)="cancel()" src="../../assets/images/cancel@2x.png" alt="Cancel"/>
    <h3 class="title">About Research Gateway</h3>
    </div>
    <div class="content">
    </div>   
    <h2 class="prov-product-field text-white font-class" style="margin-bottom: 20px; margin-right: 10px;margin-left: 25px; font-size: 16px;">
        Version : {{version}}
    </h2>    
    <h2 class="prov-product-field text-white font-class" style="margin-bottom: 20px; margin-right: 10px;margin-left: 25px; font-size: 16px;">
        Renewal Date : {{subscriptionRenewalDate}}
    </h2>  
   <div class="mdl-dialog__actions" style="margin-top: 15px;margin-bottom: 15px;">
    <button class="btn btn-primary btn-default sub-btn" type="button" style="cursor:hand" (click)="cancel()"><h2 class="sub-text">Close</h2></button>
    </div>
</div>
  