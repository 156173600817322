import { Component, OnInit, Inject } from '@angular/core';
import * as $ from 'jquery';
import * as Terminal from '../../assets/js/terminal.js';
import * as io from '../../assets/js/socket.io'; 
import {ActivatedRoute, Router} from "@angular/router";
import {ResearchService} from "../_services/research.service";
import { ProductUtilService} from "../_services/productUtil.service";
import { ToastrService } from 'ngx-toastr';
import {Globals} from "../globals";

declare var instanceSSH: any;
@Component({
  selector: 'app-instance-ssh',
  templateUrl: './instance-ssh.component.html',
  styleUrls: ['./instance-ssh.component.css']
})
export class InstanceSshComponent implements OnInit {

  constructor(private route: ActivatedRoute, 
    private checkForRoute: ResearchService,private toaster: ToastrService,
    private productUtil: ProductUtilService,
    private router: Router,
    private globals: Globals) {
    this.checkForRoute.sendEvent();
   }
  usernameF = 'ec2-user';
  passwordF = '';
  pemFileCheckboxF = 'pemFile';
  instanceDetails;
  pemfileInputF = '';
  instanceId = '';
  getInstanceIp = '';
  ppId;
  service;
  productDetails;

  ngOnInit() {
    this.ppId = this.route.snapshot.params['ppId'];
    this.service = this.route.snapshot.params['service'];
    this.usernameF = (this.service === 'vm') ? 'azureuser' : 'ec2-user';
    /*Check whether user has product access*/
    this.getProductDetails();
    $('#ssh-win-box').hide();
    var $sshContainer = $("#instanceSSHPage .modal-body");
    $sshContainer.find(".passwordCheck").hide();
    $sshContainer.find(".chooseAuthenticationtype").on('change', function(e) {
      if ((e.target as HTMLInputElement).value === "Password") {
        $sshContainer.find(".pemFileCheck").hide();
        $sshContainer.find(".passwordCheck").show();
      } else {
        $sshContainer.find(".passwordCheck").hide();
        $sshContainer.find(".pemFileCheck").show();
      }
    });

    $("#instanceSSHPage #ssh-pemfileInput").on('change', function(e) { 
      const fileName = (e.target as HTMLInputElement).files[0].name; 
      $(this).next().val(fileName);
      if (fileName) {
        let fName = fileName;
        const getLen = fName.length;
        if (getLen > 50) {
          fName = fName.slice(0, 50) + '...';
        }
        const getWWidth = $( window ).width();
        if ( getWWidth < 500 ) {
          if (getLen > 15) {
            fName = fName.slice(0, 15) + '...';
          }
        }
        $("#ssh-fileName").text(fName);
      } else {
        $("#ssh-fileName").text('No file selected');
      };
      $("#ssh-pemfileInput-lab").removeClass('file-lab-red');
      $("#ssh-pemfileInput-lab").addClass('file-lab');
    });

    var $sshContainer = $("#instanceSSHPage");
    var $pemFileCheckBox = $sshContainer.find("#ssh-pemFileCheckbox");
    var $errorArea = $sshContainer.find("#ssh-errorArea");
    var $terminalContainer = $("#ssh-terminalContainer");
    var $loadingContainer = $sshContainer.find("#ssh-loadingContainer");
    var $loginForm = $sshContainer.find("#ssh-login-form");
    var $terminateBtn = $sshContainer.find(".ssh-terminateBtn");
    var $sshActionLogIdInput = $sshContainer.find("#ssh-actionLogId");
    var term;
    var sshOpened = false;
    var sshSocket;
    $loadingContainer.hide();

    function openSSh(reqBody) {
      $sshContainer.find('.sshSubmit').hide();
      $errorArea.hide();
      $terminalContainer.hide();
      $loginForm.hide();
      $loadingContainer.show();

      sshSocket = io('/sshShell', {
        // 'force new connection': true,
        reconnection: true
      });
      console.log(sshSocket);

      sshSocket.on('connect', function() { 
        sshSocket.emit('open', reqBody);
      });
      sshSocket.on('conErr', function(data) {
        
        sshSocket.disconnect();
        // term.destroy();  
        
        $errorArea.empty().append(data.message);
        $errorArea.show();
        $loginForm.show();
        $sshContainer.find('.sshSubmit').show();
        $terminalContainer.hide();
        $loadingContainer.hide();
        if (data.actionLogId) {
          $sshActionLogIdInput.val(data.actionLogId);
        }
      });
      sshSocket.on('opened', function(data) {
        $('#ssh-pemfileInput').val(null);
        $('#ssh-fileName').text('No file selected');
        $('#ssh-pemfileInput-lab').addClass('file-lab-red');
        $errorArea.hide();
        $loginForm.hide();
        $loadingContainer.hide();
        $('#ssh-conn-header').hide();
        $terminalContainer.show();
        $('#ssh-win-box').show();
        sshOpened = true;
        term = new Terminal({
            cols: 80,
            rows: 24,
            screenKeys: true,
            cursorBlink: true,
            useStyle: true
        });
        term.on('data', function(data) {
          if (sshOpened) {
            sshSocket.emit('cmd', data);
          }
        });

        term.open($terminalContainer.get(0));

        if (data.actionLogId) {
          $sshActionLogIdInput.val(data.actionLogId);
        }

      });
      sshSocket.on('out', function(data) {
        if (term) {
          term.write(data.res);
        }
      });
      sshSocket.on('disconnect', function() {
        $('#ssh-conn-header').show();
        $('#ssh-win-box').hide();
        destroyTerminal();
        $sshContainer.find('.sshSubmit').show();
      });
      sshSocket.on('close', function() {
        $('#ssh-conn-header').show();
        $('#ssh-win-box').hide();
        destroyTerminal();
        $sshContainer.find('.sshSubmit').show();
      });
      sshSocket.on('error', function(err) {
        if (err && err.reason) {
          $errorArea.empty().append(err.reason);
        } else {
          $errorArea.empty().append('Error occured while making a connection');
        }
        $errorArea.show();
        $loginForm.show();
        $sshContainer.find('.sshSubmit').show();
        $terminalContainer.hide();
        $loadingContainer.hide();
      });
    }

    function destroyTerminal() {
      if (term) {
        term.destroy();
      }
      $terminalContainer.hide();
      $loginForm.show();
      $errorArea.hide();
      $loadingContainer.hide();
    }

    $sshContainer.find('.sshSubmit').on('click',function(e) {
      let reqBody = {
        id : '' as any,
        sessionUser : '' as any ,
        username : 'ubuntu' as any,
        password : '' as any,
        pemFileData : '' as any
      };

      /*Values set from external page*/
      reqBody.id = $sshContainer.find("#ssh-instanceId").val();
      reqBody.sessionUser = $sshContainer.find("#ssh-sessionUser").val();
      // /*Values entered by user*/
      reqBody.username = $sshContainer.find("#ssh-username").val();
      if (!reqBody.id) {
        return;
      }
      if ($pemFileCheckBox.val() === 'Password') {
        reqBody.password = $sshContainer.find("#ssh-password").val();

        if (!reqBody.username) {
          console.log('Please Enter Username');
          return;
        }
        if (!reqBody.password) {
          console.log('Please Enter Password or Choose a Pem file');
          return;
        }
        openSSh(reqBody);
      } else {
        try {
          const BR = (<HTMLInputElement>document.getElementById('ssh-pemfileInput')).files[0];
          if (!reqBody.username) {
            console.log('Please Enter Username');
            return;
          }
          reqBody.pemFileData = '';
          const reader = new FileReader();
          reader.onload = function(e: any) {
            reqBody.pemFileData = e.target.result;
            openSSh(reqBody);
          };
          // Read in the image file as a data URL.
          reader.readAsText(BR);
        } catch (e) {
          $errorArea.empty().append('Please choose the Pem File');
        }
      }
      return false;
    });

    $terminateBtn.on('click', function() {
      if (sshOpened) {
        if (sshSocket) {
          sshSocket.disconnect();
        }
      }
    });
  }

  terminate(): void {
    window.close();
  }

  /* This is to check whether user has access to product and also get product IP address */
  getProductDetails() {
    try{
      this.checkForRoute.getProvisionedProductDetails(this.ppId).subscribe((res:any) => {
        this.productDetails = res;
        const getInstanceDetails = this.productDetails.RecordOutputs;
        for (let i = 0; i < getInstanceDetails.length; i++) {
          if (getInstanceDetails[i].OutputKey === 'InstanceIPAddress') {
             this.getInstanceIp = getInstanceDetails[i].OutputValue;
          }
        }
        /*If service is P-cluster get the ip from resources.*/
        const serviceName = this.productUtil.getServiceFromTags(this.productDetails.Tags);
        if(serviceName === 'pcluster'){
          const resourceDetails = (this.productDetails.Resources.find(resource => resource.LogicalResourceId == "HeadNode") || "").Details || [];
          this.instanceId = (resourceDetails.find(detail => detail.Name == "InstanceId") || "").Value || '';
          this.getInstanceIp = (resourceDetails.find(detail => detail.Name == "PublicIpAddress") || "").Value || '';    
        }
        /*If IP address is not available, then show toaster msg with page not found*/
        if (!this.getInstanceIp) {
          this.toaster.error(`${this.productDetails.ProvisionedProductName} - `+ "This action cannot be completed as some of the outputs are not yet available. Please retry the action after some time.", 'Error', this.globals.tosterOverride)
          this.router.navigate(['pageNotFound']);
        }
      }, error => {
        let errBody = error.error;
        if(error && error.status === 401){
           this.toaster.error(errBody.err, '', this.globals.tosterOverride);
           this.router.navigate(['login']);
         }else if (error && error.status === 403) {
          const err = errBody.error ? errBody.error : errBody;
          this.toaster.error(err, 'Error', this.globals.tosterOverride);
          this.router.navigate(['pageNotFound']);
        }else{
          this.toaster.error(errBody, 'Error', this.globals.tosterOverride);
          }
  });
    }catch(error){
      let errBody = error.error;
      this.toaster.error(errBody, 'Error', this.globals.tosterOverride);
    }
  }

}
