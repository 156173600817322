import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { ResearchService} from "../../_services/research.service";
import { DashboardService } from "../../_services/dashboard.service";
import {Globals} from "../../globals";

@Component({
  selector: 'app-budget-tracking',
  templateUrl: './budget-tracking.component.html',
  styleUrls: ['../../catalog/catalog.component.css', './budget-tracking.component.css', '../../app.component.css'],
  providers: [Globals]
})
export class AdminBudgetTrackingComponent implements OnInit {

  currencySymbol: string = '$';
  organisations;
  totalBudget : any = 0;
  consumedBudget : any = 0;
  availableBudget : any = 0;
  gAvailBud : any = 0;
  loader: boolean = false;

  constructor(private router: Router, private toastr: ToastrService, private researchService: ResearchService,
              private apiService: DashboardService, private globals: Globals) { }

  ngOnInit() {
    this.apiService.checkIfAdmin();
    this.getOrganization();
  }

  getOrganization() {
    this.loader = true;
    this.researchService.getOrganizations().subscribe(data => {
      this.loader = false;
      this.organisations = data;
      this.organisations.forEach((item => {
        this.totalBudget += item.totalBudgetAllocated
        this.consumedBudget += item.budgetConsumed
      }))


      this.availableBudget = parseFloat(this.totalBudget) - parseFloat(this.consumedBudget);
      this.availableBudget = parseFloat(this.availableBudget).toFixed(2);
      this.totalBudget = parseFloat(this.totalBudget).toFixed(2);
      this.consumedBudget = parseFloat(this.consumedBudget).toFixed(2);
    }, error => {
      this.loader = false;
      if(error && error.status === 403){
        const err = error.error;
        this.toastr.error(err.error, 'Error', this.globals.tosterOverride);
        this.router.navigate(['pageNotFound']);
      }else if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    });
  }

  openOrganisationDetails(organisation) {
    this.router.navigate(['/admin/organisation/'+ organisation.organizationID +'/details']);
  }

  getAvailableBudget(organisation){
    const tBudgetAllocated = organisation.totalBudgetAllocated;
    const budgetConsumed = organisation.budgetConsumed;
    this.gAvailBud = parseFloat(tBudgetAllocated) - parseFloat(budgetConsumed);
    this.gAvailBud = parseFloat(this.gAvailBud).toFixed(2);
    return this.gAvailBud;
  }

  projectConsumedBudgetPercentage(organisation){
    if(organisation.budgetConsumed){
      let consumed = organisation.budgetConsumed;
      return ((consumed/organisation.totalBudgetAllocated)*100).toFixed(0)
    }else {
      return 0;
    }
 }

  exportCSV(){
    this.researchService.exportAdminBudgetAsCSV().subscribe((data:any) => {
      const csvData = document.createElement("a");
      document.body.appendChild(csvData);
      let blob = new Blob([data], {type: "data:text/csv;charset=utf-8,"}),
      url = window.URL.createObjectURL(blob);
      csvData.href = url;
      csvData.download = 'BudgetOverview.csv';
      csvData.click();
      window.URL.revokeObjectURL(url);
    },error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    }
    )
  }

  getRoundOffBudget(data){
    return parseFloat(data).toFixed(2)
  }

}
