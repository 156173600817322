<div class="container" *ngIf="!loader && projects && projects.length > 0">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb bc-custom">
      <li class="breadcrumb-item">
        <a class="bc-item-active"> My Projects </a>
      </li>
    </ol>
  </nav>
  <div class="project-title-layout pb-3">
    <h1 class="box-title">My Projects
      <h2 class="box-title" *ngIf="!loader">&nbsp;( {{ totalProjectCount }} )</h2>
    </h1>
    <div class="text-right">

      <div style="display: flex;margin-bottom: 5px;">
        <button (click)="addProject()" type="button"
                class="btn btn-lg btn-primary btn-add text-white font-class mr-2 mb-1" title="Add Project">
          Add New
        </button>
        <!-- <div class="create-study-container" (click)="addProject()" [ngClass]="{ 'create-study-container-disabled': disableAssign,  'create-study-container': ! disableAssign}">
          <div class="create-study-select-wrapper">
            <div class="create-study-select">
                <div class="create-study__trigger">
                  <span class="assignOU">Add New</span>
                </div>
            </div>
          </div>
        </div> -->
      <button type="button" class="btn btn-lg btn-primary btn-pi text-white font-class mb-1" title="Refresh" (click)="refreshProjects()">
        Refresh</button>
      </div>
    </div>

  </div>
  <div class="project-container" style="margin-bottom: 20px;">
    <div class="col-md-6 col-sm-12 item" *ngFor="let project of projects">

      <div class="card item-card card-body" tabindex="0" title="View Project" (click)="viewProject(project);"
           (keypress)="viewProject(project);">
        <div class="status-info status-running" *ngIf="project.projectStatus === 'Active'">
          <span class="font-class status-text">{{ project.projectStatus }}</span>
        </div>
        <div class="status-info status-paused"
             *ngIf="project.projectStatus === 'Paused' || project.projectStatus === 'Creating'">
          <span class="font-class status-text">{{ project.projectStatus }}</span>
        </div>
        <div class="status-info status-stopped"
             *ngIf="project.projectStatus === 'Stopped' || project.projectStatus === 'Failed'">
          <span class="font-class status-text">{{ project.projectStatus }}</span>
        </div>
        <div class="project-header-layout">
          {{project.vendor}}
          <img class="project-image" src={{getVendorImage(project)}} alt="{{project.project_type}} Project">

          <h3 class="card-title  mt-3 mb-3 project-name text-white font-class"
              title="{{project.name}}">{{ nameTrim(project.name) }}</h3>
        </div>
        <div class="project-info">
          <div class="project-principal-info" style="width: 65%">
            <p class="project-principal-header font-class" *ngIf="project.researcherDetails.length > 0">Team</p>
            <p class="project-principal-value text-white font-class"
               *ngIf="project.researcherDetails.length > 0">{{ projectTeam(project.researcherDetails) }}</p>
          </div>
          <div class="project-principal-info">
            <p class="project-principal-header font-class">Project Budget</p>
            <p class="project-budget-value text-white font-class">
              $ {{ project.budget }}
              <span class="budget-consumed-txt per-text"
                    [ngClass]="getProjectThresholdClass(project)">( {{ project.budgetConsumedPercentage }}
                % used ) </span>
            </p>
          </div>
        </div>
        <p *ngIf="project.projectStatus != 'Creating'"
           class="card-text text-white font-class">{{ projectDescription(project.description) }}</p>
        <div class="md-stepper-horizontal orange" *ngIf="project.projectStatus == 'Creating'">
          <div [ngClass]="getProjectWorkflowStatus(steps.status)"
               *ngFor="let steps of project.projectWorkflow;let i = index;">
            <div class="md-step-circle"><span>{{ i + 1 }}</span></div>
            <div class="md-step-title">{{ steps.step }}</div>
            <div class="md-step-optional">({{ steps.status }})</div>
            <div class="md-step-bar-left"></div>
            <div class="md-step-bar-right"></div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="load-more-btn-wrapper" *ngIf="hasNextPage">
    <button class="load-more-btn font-class" (click)="loadMore()">Load More</button>
  </div>
  <!-- <div *ngIf="projects && projects.length === 0 && !loader" class="empty-my-product-layout">
    <div>
      <h3 class="no-data-found text-white font-class text-center">Welcome to RLCatalyst Research Gateway! </h3>
      <h3 class="no-data-found-subtext text-white font-class">We're excited to help accelerate your research through easily provisioned, scalable, secure resources in AWS. To start
        your journey you will need to configure project.</h3>
      <h3 class="no-data-found-subtext text-white font-class">To configure project click on the Add New button above, provide project name and configure your budget for the project,
        select an AWS account for the project, and add researchers who will work under the project.</h3>
      <h3 class="no-data-found-subtext text-white font-class">
        Support is included as part of your subscription. Contact us via email (rlcatalyst@relevancelab.com) or web (https://serviceone.relevancelab.com)
        for support. Our support hours are Monday - Friday (except holidays) 8am to 5pm (IST)
      </h3>
      <h3 class="no-data-found-subtext text-white font-class">
        Watch a video on how to setup your system.
        <a target="_blank" href="https://rlcatalyst-researchportal.s3.us-east-2.amazonaws.com/RLCatalyst-Research-Gateway.mp4" style="color: #ffffff">
          Click here
        </a>
      </h3>
    </div>
  </div> -->
</div>


<div class="container" *ngIf=" !loader && orgId == undefined || orgId == null || orgId == ''">
  <div class="empty-my-product-layout-a">
    <div>
      <h3 class="no-data-found text-white font-class text-center">You are not assigned to an organization. Please
        contact your administrator.</h3>
    </div>
  </div>
</div>

<div *ngIf="projects.length == 0 && accounts.length > 0 && !loader" class="container">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb bc-custom">
      <li class="breadcrumb-item">
        <a class="bc-item-active"> My Projects </a>
      </li>
    </ol>
  </nav>
  <div class="project-title-layout pb-3">
    <h3 class="box-title">My Projects
      <h3 class="box-title" *ngIf="!loader">&nbsp;( {{ totalProjectCount }} )</h3>
    </h3>
    <div class="text-right">
      <button (click)="addProject()" type="button" class="btn btn-lg btn-primary btn-pi text-white font-class mr-2 mb-1"
              title="Add Project">
        <i class="fa fa-plus white"></i> Add New
      </button>
      <button type="button" class="btn btn-lg btn-primary btn-pi text-white font-class mb-1" title="Refresh"
              (click)="refreshProjects()">
        <i class="fa fa-refresh white"></i> Refresh
      </button>
    </div>

  </div>
  <div *ngIf="projects && projects.length === 0 && !loader" class="empty-my-product-layout-a">
    <div>
      <h1 class="no-data-found text-white font-class">
        You don't seem to have any projects yet! Would you like to add a project?
      </h1>
      <div class="col-sm-12" style="text-align: center;margin-top: 25px;">
        <button class="launch-btn text-white font-class" (click)="addProject()" name="button" type="button">Add
          Project
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Welcome -->

<div class="container" style="margin-top: 15px"
     *ngIf="!loader && accounts.length == 0 && orgId != undefined && orgId != null && orgId != ''">
  <div class="helpHeader">
    <div class="welcomeSection">
      <img class="wlcimg" alt="welcome" src="../../assets/images/welcome@2x.png"/>
      <h3 class="welcomeNote"> Welcome to Research Gateway!</h3>
      <h3 class="wlcTxt">
        We’re excited to help accelerate your research through easily-provisioned, scalable, and secure resources in
        AWS.</h3>
      <h3 class="wlcTxt" style="margin-bottom: 30px;">
        Using Research Gateway you can easily manage your projects, budgets, users, accounts and catalogs. You can also
        interact
        with the provisioned products.</h3>
      <h3 class="wlcTxt-B">Support is included in your current subscription.</h3>
      <h3 class="wlcTxt">
        You can always reach us via
        <a class="wlcTxt" href="mailto: rlcatalyst@relevancelab.com">email</a> ( rlcatalyst@relevancelab.com ) or visit
        our
        <a class="wlcTxt"
           target="_blank" href="https://serviceone.relevancelab.com" style="color: #85A2FF!important">website</a> to
        raise a service ticket. </h3>
      <h3 class="wlcTxt">
        Watch a video on how to setup your system.
        <a target="_blank" href="{{fullVideoLink}}" class="wlcTxt" style="color: #85A2FF !important">
          Click here.
        </a>
      </h3>
    </div>
    <div class="helpSection">
      <h3 class="help-a">Kickstart your journey with Research Gateway.</h3>
      <h3 class="help-b"> Everything starts with creating your first Project.</h3>
      <ul>
        <li class="listValue">
          <img class="help-img" alt="Account Help" src="../../assets/images/accounts.png"/>
          <popover-content placement="bottom" #myPopover1 [closeOnClickOutside]="true" [animation]="false">
            <div id="tagPop">
              <ul>
                <li class="info-popup">
                  <a target="_blank" href="{{addAccountLink}}" class="wlcTxt" style="color: #3e6bff !important">
                    Watch a video
                  </a>
                </li>
                <li class="info-popup">
                  <a target="_blank"
                     href="https://researchgateway.readthedocs.io/en/latest/GettingStarted.html#adding-an-aws-account-to-use-in-a-project"
                     class="wlcTxt" style="color: #3e6bff !important">
                    Read the docs
                  </a>
                </li>
              </ul>
            </div>
          </popover-content>
          <p class="info-p">Account: An AWS account is needed for each project. This account is combination of account
            number and region. All
            the resources would be created in this account.
            <i title="Help" class="fa fa-info-circle info-icon" aria-hidden="true" [popover]="myPopover1"
               popoverTitle="How to add an account"
               [popoverCloseOnClickOutside]="true" [popoverOnHover]="false"></i>
          </p>
        </li>

        <li class="listValue">
          <img class="help-img" alt="Project Help" src="../../assets/images/addProject.png"/>
          <popover-content placement="bottom" #myPopover2 [closeOnClickOutside]="true" [animation]="false">
            <div id="tagPop">
              <ul>
                <li class="info-popup">
                  <a target="_blank" href="{{addProjectLink}}" class="wlcTxt" style="color: #3e6bff !important">
                    Watch a video
                  </a>
                </li>
                <li class="info-popup">
                  <a target="_blank"
                     href="https://researchgateway.readthedocs.io/en/latest/GettingStarted.html#adding-a-new-project"
                     class="wlcTxt" style="color: #3e6bff !important">
                    Read the docs
                  </a>
                </li>
              </ul>
            </div>
          </popover-content>
          <p class="info-p">Project: Project is a designated area created to support the research requirements of your
            Users. As a Principal
            Investigator ( PI ) user, you can create any number of projects, each of which is linked to an AWS account.
            <i title="Help" class="fa fa-info-circle info-icon" aria-hidden="true" [popover]="myPopover2"
               popoverTitle="How to add a project"
               [popoverCloseOnClickOutside]="true" [popoverOnHover]="false"></i>
          </p>
        </li>
        <li class="listValue">
          <img class="help-img" alt="Catalog Help" src="../../assets/images/catalog.png"/>
          <popover-content placement="bottom" #myPopover3 [closeOnClickOutside]="true" [animation]="false">
            <div id="tagPop">
              <ul>
                <li class="info-popup">
                  <a target="_blank" href="{{assignProductsLink}}" class="wlcTxt" style="color: #3e6bff !important">
                    Watch a video
                  </a>
                </li>
                <li class="info-popup">
                  <a target="_blank" href="https://researchgateway.readthedocs.io/en/latest/Features.html#catalog"
                     class="wlcTxt" style="color: #3e6bff !important">
                    Read the docs
                  </a>
                </li>
              </ul>
            </div>
          </popover-content>
          <p class="info-p"> Catalog: Once a Project is created, the next step is to assign products from the catalog to
            the Project. You can
            use the standard catalog that comes with Research Gateway or you can bring your own AWS Service Catalog
            portfolio
            by synchronizing your account.
            <i title="Help" class="fa fa-info-circle info-icon" aria-hidden="true" [popover]="myPopover3"
               popoverTitle="How to add a catalog"
               [popoverCloseOnClickOutside]="true" [popoverOnHover]="false"></i>
          </p>
        </li>
        <li class="listValue">
          <img class="help-img" alt="Users Help" src="../../assets/images/addUsers.png"/>
          <popover-content placement="bottom" #myPopover4 [closeOnClickOutside]="true" [animation]="false">
            <div id="tagPop">
              <ul>
                <li class="info-popup">
                  <a target="_blank" href="{{userLink}}" class="wlcTxt" style="color: #3e6bff !important">
                    Watch a video
                  </a>
                </li>
                <li class="info-popup">
                  <a target="_blank" href="https://researchgateway.readthedocs.io/en/latest/Features.html#users"
                     class="wlcTxt" style="color: #3e6bff !important">
                    Read the docs
                  </a>
                </li>
              </ul>
            </div>
          </popover-content>
          <p class="info-p"> Users: You can invite additional collaborators to your projects from the users screen.
            <i title="Help" class="fa fa-info-circle info-icon" aria-hidden="true" [popover]="myPopover4"
               popoverTitle="How to invite users" [popoverCloseOnClickOutside]="true" [popoverOnHover]="false"></i>
          </p>
        </li>
      </ul>
      <div class="col-lg-12 col-md-12" style="text-align: center;margin-top: 30px">
        <button class="launch-btn text-white font-class" (click)="addProject()" name="button" type="button">Let's get
          Started
        </button>
      </div>
    </div>
  </div>

  <!-- Mobile view -->
  <div class="helpHeader-mob">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <img class="wlcimg" alt="Welcome" src="../../assets/images/welcome@2x.png"/>
      <h3 class="welcomeNote"> Welcome to Research Gateway!</h3>
      <h3 class="wlcTxt">
        We’re excited to help accelerate your research through easily-provisioned, scalable, and secure resources in
        AWS.</h3>
      <h3 class="wlcTxt" style="margin-bottom: 30px;">
        Using Research Gateway you can easily manage your projects, budgets, users, accounts and catalogs. You can also
        interact
        with the provisioned products.</h3>
      <h3 class="wlcTxt-B">Support is included in your current subscription.</h3>
      <h3 class="wlcTxt">
        You can always reach us via
        <a class="wlcTxt" href="mailto: rlcatalyst@relevancelab.com">email</a> ( rlcatalyst@relevancelab.com ) or visit
        our
        <a class="wlcTxt"
           target="_blank" href="https://serviceone.relevancelab.com" style="color: #85A2FF !important">website</a> to
        raise a service ticket. </h3>
      <h3 class="wlcTxt">
        Watch a video on how to setup your system.
        <a target="_blank" href="{{fullVideoLink}}" class="wlcTxt" style="color: #85A2FF !important">
          Click here.
        </a>
      </h3>
    </div>

    <div class="helpSection-mob">
      <h3 class="help-a">Kickstart your journey with Research Gateway.</h3>
      <h3 class="help-b"> Everything starts with creating your first Project.</h3>
      <ul>
        <li class="listValue">
          <img class="help-img" alt="Account Help" src="../../assets/images/accounts.png"/>
          <p class="info-p">Account: An AWS account is needed for each project. This account is combination of account
            number and region. All
            the resources would be created in this account.
            <!-- <i title="Help" class="fa fa-info-circle info-icon" aria-hidden="true" [popover]="myPopover" popoverTitle="Help"
            [popoverCloseOnClickOutside]="true" [popoverOnHover]="false"></i> -->
          </p>
        </li>

        <li class="listValue">
          <img class="help-img" alt="Project Help" src="../../assets/images/addProject.png"/>
          <p class="info-p">Project: Project is a designated area created to support the research requirements of your
            Users. As a Principal
            Investigator ( PI ) user, you can create any number of projects, each of which is linked to an AWS account.
            <!-- <i title="Help" class="fa fa-info-circle info-icon" aria-hidden="true" [popover]="myPopover" popoverTitle="Help"
            [popoverCloseOnClickOutside]="true" [popoverOnHover]="false"></i> -->
          </p>
        </li>
        <li class="listValue">
          <img class="help-img" alt="Catalog Help" src="../../assets/images/catalog.png"/>
          <p class="info-p"> Catalog: Once a Project is created, the next step is to assign products from the catalog to
            the Project. You can
            use the standard catalog that comes with Research Gateway or you can bring your own AWS Service Catalog
            portfolio
            by synchronizing your account.
            <!-- <i title="Help" class="fa fa-info-circle info-icon" aria-hidden="true" [popover]="myPopover2" popoverTitle="Help"
            [popoverCloseOnClickOutside]="true" [popoverOnHover]="false"></i> -->
          </p>
        </li>
        <li class="listValue" style="margin-bottom: 25px;">
          <img class="help-img" alt="Users Help" src="../../assets/images/addUsers.png"/>
          <p class="info-p"> Users: You can invite additional collaborators to your projects from the users screen.
            <!-- <i title="Help" class="fa fa-info-circle info-icon" aria-hidden="true" [popover]="myPopover3" popoverTitle="Help" [popoverCloseOnClickOutside]="true" [popoverOnHover]="false">
            </i> -->
          </p>
        </li>
      </ul>
      <div class="col-sm-12" style="text-align: center;">
        <button class="launch-btn text-white font-class" (click)="addProject()" name="button" type="button">Let's get
          Started
        </button>
      </div>
    </div>
  </div>


</div>
<app-loader class="spinner" *ngIf="loader"></app-loader>
