import { Component, EventEmitter, OnInit, Output, Inject } from '@angular/core';
import { MdlDialogReference } from '@angular-mdl/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OrganizationService } from '../../_services/organization.service';
import { DashboardService } from "../../_services/dashboard.service";
import { MdlDialogService } from '@angular-mdl/core';
import { ConfigComponent } from '../../settings/config.component';
import {Globals} from "../../globals";
import * as moment from 'moment';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.css'],
  providers: [OrganizationService,Globals]
})
export class OrganizationComponent implements OnInit {

  organizationName: string = '';
  submitBTN: any = true;
  accountId;
  accountIdList = [];
  principal: string = '';
  principalIdList = [];
  showSpinner = false;
  organizationDescription;
  principalIds: any = [];
  organizationId;
  isValid: boolean = true;
  organizationAccId: any;
  accountIdArr = [];
  testarr = [];
  accountIdArr1 = [];
  viewValueArr = [];
  accountIds = [];
  idArr = [];
  organizationData = [];
  accountIdname = [];
  viewAccountIdname: string;
  fAccountIdList = [];
  accountListFound;
  accountNames: string;
  loadForm;
  loadProjForm;
  orgNameErrDesc = 'Enter a unique O.U name made of alphanumeric, hyphen and underscore characters';
  defaultClass = 'helpText'
  subscriptionDate;
  linkedActiveDirectoryGroup;
  loginStrategy;
  showLinkedExternalGroup = false;

  @Output() childEvent = new EventEmitter();
  constructor(
    public dialogRef: MdlDialogReference,
    private router: Router,
    private orgService: OrganizationService,
    private toastr: ToastrService,
    private apiService: DashboardService,
    private dialog: MdlDialogService,
    private globals: Globals,
    @Inject('data') public data: any
  ) { }

  ngOnInit() {
    this.loadForm = true;
    this.loadProjForm = true;
    this.apiService.checkIfAdmin();
    this.loginStrategy = sessionStorage.getItem('strategy');
    if (this.loginStrategy === 'saml' || this.loginStrategy === 'azure-ad-oidc') {
      this.showLinkedExternalGroup = true;
    }
    try {
      if (this.data.org_name) {
        this.organizationName = this.data.org_name;
        const subscriptionDate = (this.data.subscriptionRenewalDate.split("T"));     
        const renewalDate = subscriptionDate[0];
        const month = moment(new Date(renewalDate)).format("MMM");
        const day = moment(new Date(renewalDate)).format("DD");
        const year = moment(new Date(renewalDate)).format("yyyy");
        this.subscriptionDate = month + " " + day + "," + " " + year;
      }
      this.organizationDescription = this.data.description;
      this.linkedActiveDirectoryGroup = this.data?.linkedActiveDirectoryGroup ? this.data.linkedActiveDirectoryGroup : '-';
      // this.accountIds = this.data.account_id
      if (this.data.principalDetails){
        for ( const name of this.data.principalDetails){
          if (name) {
            this.principalIds.push(name.displayName);
          }
        }
        this.principalIds = this.principalIds.toString().replace(/,/g, ", ");
      }
      if (this.organizationName) {
        this.isValid = true;
      }
      if (this.data.account_id) {
        for (let i = 0; i < this.data.account_id.length; i++) {
          this.idArr.push(this.data.account_id[i]);
        }
      }
    } catch (e) {
       
    }
    this.getPrincipalListmethod();
    this.getOrganizationListmethod();
  }

  private getOrganizationListmethod() {
    this.orgService.getOrganizationsList().subscribe((res:any) => {
      this.organizationData = res;
      for (let i = 0; i < this.organizationData.length; i++) {
        this.accountIdArr.push(...this.organizationData[i].account_id);
      }
      this.viewAccountIdname = this.accountIdname.toString();
      this.getAccountlistMethod();
    }, error=>{
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    });

  }

  private getPrincipalListmethod() {
    this.loadProjForm = true;
    this.orgService.getPrincipalList().subscribe((res:any) => {
      this.loadProjForm = false;
      this.principalIdList = res.map(obj => {
        const fObj = {
          'value': obj.username,
          'viewValue': `${obj.fullName} (${obj.email})`,
          'disabled': false,
          'org_id': obj.org_id
        };
        return fObj;
      });
    });
  }

  private getAccountlistMethod() {
    this.loadForm = true;
    this.fAccountIdList = [];
    this.accountIdList= [];
    this.orgService.getAccountList().subscribe((res:any) => {
      this.accountIdList = res.map(obj => {
        const fObj = {
          'value': obj._id,
          'viewValue': obj.name,
          'accountType' : obj.accountType
        };
        return fObj;
      });

      for (let i = 0; i < this.accountIdList.length; i++) {
        let data = this.accountIdArr.some(element => element == this.accountIdList[i].value);
        if (!data) {
          this.fAccountIdList.push(this.accountIdList[i]);
          this.accountListFound = true;
        }
      }

      try {
        let accountNameArray = [];
        let orgAccount = [];
        for(let account of this.accountIdList) {
          let result = (this.idArr).includes(account.value);          
          if(result && account.accountType == 'project') {
            orgAccount.push(account.value);
          }         
        }        
        orgAccount.forEach(account => {          
          accountNameArray.push(this.accountIdList.find(element => element.value == account).viewValue);
        });
        this.accountNames = accountNameArray.toString().replace(/,/g, ", ");
      } catch (error) {

      }
      if(this.fAccountIdList.length == 0){
        this.accountListFound = false;
      }
      this.loadForm = false;
    });
  }

  cancel(): void {
    this.dialogRef.hide();
  }

  selectAccountIds(acc, event) {
    if(event.target.checked){
      this.accountIds.push(acc.value)
    }else {
      this.accountIds = this.accountIds.filter((item) => {
        item !== acc.value
      })
    }
  }

  selectPrincipalIds(p, event) {
    if(event.target.checked){
      this.principalIds.push(p.value)
    }else {
      this.principalIds = this.principalIds.filter((item) => {
        item !== p.value
      })
    }

    if (this.principalIds && this.principalIds.length > 0){
      //const org = this.principalIds[0].split('/')[0];
      this.principalIdList.forEach(item => {
        const principalOrg = item.org_id;
        if(!principalOrg){
          item.disabled = false;
        }else {
          item.disabled = true;
        }
      })
    }

    if(this.principalIds.length === 0) {
      this.principalIdList.forEach(item => {
        item.disabled = false;
      });
    }
  }

  addOrganization() {
    // if (this.accountIds.length === 0){
    //   return;
    // }
    // if (this.principalIds.length === 0){
    //   return;
    // }
    const ids = this.principalIds.map(str => {
      const strArr = str.split("/");
      return strArr[0];
    });
    this.submitBTN = false;
    const formObj = {
      'org_name': this.organizationName,
      'org_id': ids,
      'description': this.organizationDescription,
      'account_id': this.accountIds,
      'principal': this.principalIds,
      'img_src': "https://upload.wikimedia.org/wikipedia/commons/thumb/8/80/DNA_icon.svg/1200px-DNA_icon.svg.png"
    };
    this.orgService.addOrganization(formObj).subscribe(
      (res:any) => {
        console.log("Add organisation response - ", res);
        if (res.status === 'Success') {
          this.dialogRef.hide();
          this.submitBTN = false;
          this.router.navigate(['admin/success/Saved successfully']);
        } else if (res.status === 'Failed') {
          this.submitBTN = true;
          this.toastr.error(res.message);
        } else {
          this.router.navigate(['admin/error/Error in updating']);
        };
      }, error=>{
        this.dialogRef.hide();
        let errBody = error.error;
        if(error && error.status === 401){
          this.toastr.error(errBody.err, '', this.globals.tosterOverride);
          this.router.navigate(['login']);
         }else{
          this.toastr.error('', error.err, this.globals.tosterOverride);
         }
      });
  }

  firstDropDownChanged(data) {
    const sorgIds = data.split("/");
    this.organizationId = sorgIds[0];
  }

  checkOrgName(){
    const dataLeng = this.organizationName.length;
    const regexPattern = /^[-_ a-zA-Z0-9]+$/
    const getRegValue = this.organizationName.match(regexPattern);
    if(dataLeng == 0){
      this.defaultClass = 'helpText';
      this.orgNameErrDesc = 'Enter a unique O.U name made of alphanumeric, hyphen and underscore characters';
    }else if(getRegValue === null){
      this.defaultClass = 'errorText';
      this.orgNameErrDesc = 'Only alphanumeric characters, hyphens and underscores are allowed';
    }else if(dataLeng < 3){
        this.defaultClass = 'errorText';
        this.orgNameErrDesc = "Organization name must be at least 3 characters";
    }else this.orgNameErrDesc = '';

  }

  getHelpTextClass(value: string) {
    switch (value) {
      case "helpText":
          return "helpText";
      case "errorText":
          return "errorText";
    }
  }

  routeToSettings(){
    this.dialogRef.hide();
    this.router.navigate(['/setting']);
    this.apiService.getDialogBox().subscribe((data:any) => {
      if(data.status === 200){
    this.dialog.showCustomDialog({
      component: ConfigComponent,
      providers: [{ provide: 'data', useValue: { services: '', name: '' } }],
      isModal: true,
      styles: {
        "height":"auto",
        "width":"40%",
        "border": "1px solid #FFFFFF33",
        "border-radius": "10px",
        "background-color": "#07102A",
        "padding": "0px"
      },
      clickOutsideToClose: true,
      enterTransitionDuration: 400,
      leaveTransitionDuration: 400,
    })}}, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    })
  }


}
