<div class="container pi-budget-tracking">
  <!-- breadcrumb -->
  <nav aria-label="breadcrumb">
      <ol class="breadcrumb bc-custom">
        <li class="breadcrumb-item"><a class="bc-item-active"> Budgets </a></li>
      </ol>
  </nav>
  <!-- Page Heading -->
  <div class="header-title">
    <h3 class="text-white">Budgets</h3>
  </div>
  <div class="dividers"></div>

  <!-- Budget Cards -->
  <!-- Note: styles used from catalog.component.css -->
  <div class="budget-container">
    <div class="header">
      <div class="container-fluid">
        <div class="header-body">
          <div class="row kpi-container">
            <div class="col-12 col-sm-4 col-md-4 col-xs-12">
              <div class="card bg-gradient-info border-0 kpi-card-margin">
                <div class="card-body">
                  <div class="row">
                    <div class="col card-bx">
                      <div class="kpi-card-header">
                        <span class="h2 font-weight-bold mb-0 text-white card-bx-ft card-value">{{ currencySymbol }}
                          <span class="h2 font-weight-bold mb-0 text-white card-bx-ft card-value" *ngIf="!loader">{{ totalBudgetAllotted }}</span>
                        </span>
                        <img alt="Total Project Direct Cost" class="kpi-card-icon" src="../../assets/images/available_budget.png" />
                      </div>
                      <div class="kpi-info">
                        <h5 class="card-title text-muted mb-0 text-white card-bx-se font-class">Total Direct Cost Budget</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4 col-md-4 col-xs-12">
              <div class="card bg-gradient-danger border-0 kpi-card-margin">
                <div class="card-body">
                  <div class="row">
                    <div class="col card-bx">
                      <div class="kpi-card-header">
                        <span class="h2 font-weight-bold mb-0 text-white card-bx-ft card-value">{{ currencySymbol }}
                          <span class="h2 font-weight-bold mb-0 text-white card-bx-ft card-value" *ngIf="!loader">{{ totalBudgetConsumed }}</span>
                        </span>
                        <img alt="My Total Direct Cost" class="kpi-card-icon" src="../../assets/images/consumed_budget.png" />
                      </div>
                      <div class="kpi-info">
                        <h5 class="card-title text-muted mb-0 text-white card-bx-se font-class">Total Direct Cost</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div  class="col-12 col-sm-4 col-md-4 col-xs-12">
              <div class="card bg-gradient-default border-0 kpi-card-margin">
                <div class="card-body">
                  <div class="row">
                    <div class="col card-bx">
                      <div class="kpi-card-header">
                        <span class="h2 font-weight-bold mb-0 text-white card-bx-ft card-value">{{ currencySymbol }}
                          <span class="h2 font-weight-bold mb-0 text-white card-bx-ft card-value" *ngIf="!loader">{{ totalCurrentMonthCost }}</span>
                        </span>
                        <img alt="My Current Month Direct Cost" class="kpi-card-icon" src="../../assets/images/consumed_project_budget.png" />
                      </div>
                      <div class="kpi-info">
                        <h5 class="card-title text-muted mb-0 text-white card-bx-se font-class">Current Month Total Direct Cost</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Budget Breakdown -->
  <div class="container budget-breakdown">
    <div class="row budget-breakdown-header" style="margin: 25px 0px !important">
      <div class="col align-self-center text-left font-class budget-header">
        <h3 >Project-wise Budget Breakdown</h3>
      </div>
      <!-- <div class="col-auto pr-0 text-right">
        <button class="export-btn font-class" (click)="exportCSV()">Export as CSV</button>
      </div> -->
    </div>

    <div class="project-table-layout">
      <div class="projects-header d-flex w-100">
        <div class="title">
          PROJECT NAME
        </div>
        <div class="title">
          Direct Cost Budget
        </div>
        <div class="title">
          Direct Cost
        </div>
        <div class="title">
          Current Month Direct Cost
        </div>
        <div class="title">
          Consumed Budget Percentage
        </div>
      </div>
      <div class="projects">
        <div class="project-row d-flex w-100 py-4 my-3" *ngFor="let project of projects"
             [id] = "'project-'+project._id" (click)="openProjectDetails(project.projectId)" style="height: 60px !important">
          <div class="project-column">
            <span class="text-white" title="{{project.projectName}}">{{ getData(project.projectName) }}</span>
          </div>

          <div class="project-column">
            <span>{{ currencySymbol }} {{ getRoundOffBudget(project.budgetAllocated) }}</span>
          </div>

          <div class="project-column">
            <span [ngStyle]="{'color':getColor(project)}">{{ currencySymbol }} </span>
            <span [ngStyle]="{'color':getColor(project)}">{{ getRoundOffBudget(projectConsumedBudget(project)) }}</span>
          </div>

          <div class="project-column">
            <span>{{ currencySymbol }} {{ getRoundOffBudget(projectMonthBudget(project)) }}</span>
          </div>

          <div class="project-column px-4">
              <div class="progress">
                <div class="progress-bar" role="progressbar"
                     [style.width]="projectConsumedBudgetPercentage(project)+'%'"
                     [style.background-color]="getColor(project)"
                     aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            <div class="arrow" [style.margin-right]="97 - project.projectStopThresholdPercentage+'%'" >
              <i class="fa fa-caret-down" aria-hidden="true"></i><div class="marker"></div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="project-cards-layout" *ngIf="projects !== null && projects.length > 0">
      <div class="project-card" *ngFor="let project of projects" (click)="openProjectDetails(project.projectId)">
        <ul class="list-group">
          <div class="d-flex justify-content-between mb-2">
            <div class="project-card-title font-class">Project name</div>
            <div class="project-card-project-name">{{ getData(project.projectName) }}</div>
          </div>
          <div class="d-flex justify-content-between mb-2">
              <div class="project-card-title project-card-line-height font-class">Total Project Budget</div>
              <div class="project-card-value">{{ currencySymbol }} {{ getRoundOffBudget(project.budgetAllocated) }}</div>
          </div>
          <div class="d-flex justify-content-between mb-2">
              <div class="project-card-title project-card-line-height font-class">Direct Cost</div>
              <div class="project-card-value" [ngStyle]="{'color':getColor(project)}">{{ currencySymbol }} {{ projectConsumedBudget(project) }}
              </div>
          </div>
          <div class="d-flex justify-content-between mb-2">
              <div class="project-card-title project-card-line-height font-class">Current Month Direct Cost</div>
              <div class="project-card-value">{{ currencySymbol }} {{ getRoundOffBudget(projectMonthBudget(project)) }}</div>
          </div>
          <div class="d-flex justify-content-between mb-2" style="align-items: center;">
              <div class="project-card-title project-card-line-height font-class">Consumed Budget Percentage</div>
            <div id="container">
              <div  id="bar"  class="progress">
                <div class="progress-bar" role="progressbar"
                     [style.width]="projectConsumedBudgetPercentage(project)+'%'"
                     [style.background-color]="getColor(project)"
                     aria-valuemin="0" aria-valuemax="100">
                </div>
              </div>
              <div id="marker" class="arrow" [style.margin-right]="97 - project.thresholdPercentage+'%'" >
                <i class="fa fa-caret-down" aria-hidden="true"></i><div class="marker"></div>
              </div>
            </div>
          </div>
        </ul>
      </div>
    </div>

    <div class="project-row d-flex w-100 py-4 my-3" *ngIf="projects === null && !loader">
      <div class="project-column w-100 text-center text-warning" style="color:white !important" >You are not assigned to any projects. Please contact your principal investigator.</div>
    </div>

</div>
</div>
<app-loader class="spinner" *ngIf="loader"></app-loader>
