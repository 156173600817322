import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../../_services/dashboard.service';

import { MdlDialogReference } from '@angular-mdl/core';
import * as moment from 'moment';

@Component({
  selector: 'app-show-renewal-date',
  templateUrl: './show-renewal-date.component.html',
  styleUrls: ['./show-renewal-date.component.css']
})
export class ShowRenewalDateComponent implements OnInit {

  constructor(
    private apiService: DashboardService,
    public dialogRef: MdlDialogReference
  ) { }
  subscriptionRenewalDate;
  version;

  ngOnInit(): void {
    this.apiService.getVersion().subscribe((data:any) => {
      this.version = data.version
    })
    const renewalDate = sessionStorage.getItem("subscriptionRenewalDate").split("T"); 
    const subscriptionDate = renewalDate[0];     
    const month = moment(new Date(subscriptionDate)).format("MMM");
    const day = moment(new Date(subscriptionDate)).format("DD");
    const year = moment(new Date(subscriptionDate)).format("yyyy");
    this.subscriptionRenewalDate = month + " " + day + "," + " " + year;
  }
  
  cancel() {
    this.dialogRef.hide();
  }

}
