<div class="container" *ngIf="organizations && organizations.length > 0">
  <!-- breadcrumb -->
  <nav aria-label="breadcrumb">
      <ol class="breadcrumb bc-custom" style="padding-left: 0px !important">
        <li class="breadcrumb-item"><a class="bc-item-active"> My Organizations </a></li>
      </ol>
  </nav>
  <div class="project-title-layout pb-3">
    <h3 class="box-title">My Organizations <h3 class="box-title" *ngIf="!loader">&nbsp;( {{organizationLength}} )</h3></h3>
    <div class="text-right">
      <button (click)="addOrganization()"  type="button" class="btn btn-lg btn-primary btn-pi text-white font-class mr-2 mb-1" title="Add Organization">
        <i class="fa fa-plus white" style="margin: 3px;"></i> Add New</button>
      <button type="button" class="btn btn-lg btn-primary btn-pi text-white font-class mb-1" title="Refresh" (click)="refreshOrganization()">
        <i class="fa fa-refresh white" style="margin: 3px;"></i>  Refresh</button>
      <!--<div class="dropdown">
        <button class="tenant-cl btn btn-primary dropdown-toggle menu-dropdown-content text-white font-class" type="button" data-toggle="dropdown">
          Sort by
        </button>
        <ul class="dropdown-menu">
          <button type="button" class="btn menu-item-btn" routerLink='/setting'>Settings</button>
          <button type="button" class="btn menu-item-btn" (click)='logout()'>Sign out</button>
        </ul>
      </div>-->
    </div>
  </div>
  <div class="project-container" style="margin-bottom: 20px;">
    <div class="col-md-6 col-sm-12 item" *ngFor="let org of organizations">
      <div class="card item-card card-body" title="View Organization" (click)="viewOrganization(org)">
        <div class="status-info status-running" style="visibility: hidden;">
          <span class="font-class status-text">Active</span>
        </div>
        <div class="project-header-layout">
          <img class="project-image" src={{org.img_src}} alt="{{org.org_name}} Organization">
          <h3 class="card-title  mt-3 mb-3 project-name text-white font-class pr-4">{{org.org_name}}</h3>
        </div>
        <div class="project-info">
          <div class="project-principal-info">
            <label class="project-principal-header font-class">Owner</label>
            <p class="project-principal-value text-white font-class">{{orgOwner(org.principalDetails)}}</p>
          </div>
          <div class="project-principal-info" style="visibility: hidden;">
            <label class="project-principal-header font-class">Project Budget</label>
            <label class="project-budget-value text-white font-class">$ {{org.budget}}</label>
          </div>
        </div>
        <p title="{{org.description}}" class="card-text text-white font-class" style="-webkit-box-orient: vertical !important;">{{org.description}}</p>
      </div>
    </div>
  </div>
  <div class="load-more-btn-wrapper" *ngIf="hasNextPage">
    <button class="load-more-btn font-class" (click)="loadMore()">Load More</button>
  </div>
    <!-- <div *ngIf="organizations && organizations.length === 0 && !loader" class="empty-my-product-layout">
      <div>
        <h3 class="no-data-found text-white font-class text-center">Welcome to RLCatalyst Research Gateway! </h3>
        <h3 class="no-data-found-subtext text-white font-class">We're excited to help accelerate your research through easily provisioned, scalable, secure resources in AWS. To start your journey you will need to configure:</h3>
        <ul class="no-data-found-subtext text-white font-class" style="list-style-type: none;">
          <li>1. An AWS account. This will be the account where you will consume your AWS resources.</li>
          <li> 2. A user (Principal Investigator) who will create and manage projects.</li>
          <li>3. An organizational unit (managed by the Principal Investigator) where one or more projects will be created.</li>
        </ul>
        <h3 class="no-data-found-subtext text-white font-class">
            Take the tour to see how you can set up your system.
            <button type="button" class="btn btn-lg btn-primary btn-pi text-white font-class mr-2 mb-1">START THE TOUR</button>
        </h3>
        <h3 class="no-data-found-subtext text-white font-class">
            Support is included as part of your subscription. Contact us via email (rlcatalyst@relevancelab.com) or web (https://serviceone.relevancelab.com) for support.
            Our support hours are Monday - Friday (except holidays) 8am to 5pm (IST)
        </h3>
        <h3 class="no-data-found-subtext text-white font-class">
            Watch a video on how to setup your system.
            <a target="_blank" href="https://rlcatalyst-researchportal.s3.us-east-2.amazonaws.com/RLCatalyst-Research-Gateway.mp4" style="color: #ffffff">
                click here
            </a>
        </h3>
      </div>
    </div> -->
  </div>
  <div *ngIf="!loader && organizations && organizations.length === 0 && accounts.length > 0" class="container">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bc-custom" style="padding-left: 0px !important">
            <li class="breadcrumb-item"><a class="bc-item-active"> My Organizations </a></li>
          </ol>
      </nav>
      <div class="project-title-layout pb-3">
        <h3 class="box-title">My Organizations <h3 class="box-title" *ngIf="!loader">&nbsp;( {{organizations.length}} )</h3></h3>
        <div class="text-right">
          <button (click)="addOrganization()"  type="button" class="btn btn-lg btn-primary btn-pi text-white font-class mr-2 mb-1" title="Add Organization">
            <i class="fa fa-plus white" style="margin: 3px;"></i> Add New</button>
          <button type="button" class="btn btn-lg btn-primary btn-pi text-white font-class mb-1" title="Refresh" (click)="getOrganizations()">
            <i class="fa fa-refresh white" style="margin: 3px;"></i>  Refresh</button>
        </div>
      </div>
        <div *ngIf="organizations && organizations.length === 0 && !loader" class="empty-my-product-layout-a">
            <div>
          <h3 class="no-data-found text-white font-class">
              You don't seem to have any organization yet! Would you like to add a organization?
          </h3>
          <div class="col-sm-12" style="text-align: center;margin-top: 25px;margin-bottom: 25px">
            <button class="launch-btn text-white font-class" (click)="addOrganization()" name="button" type="button">Add Organization</button>
          </div>
          </div>
        </div>
  </div>

    <!-- welcome screen -->
    <div class="container" style="margin-top: 15px" *ngIf="!loader && organizations && organizations.length === 0 && accounts.length == 0">
      <div class="helpHeader">
        <div class="welcomeSection">
          <img class="wlcimg" alt="Welcome"  src="../../assets/images/welcome@2x.png" />
          <h3 class="welcomeNote"> Welcome to Research Gateway!</h3>
          <h3 class="wlcTxt">
            We’re excited to help accelerate your research through easily-provisioned, scalable, and secure resources in AWS.</h3>
          <h3 class="wlcTxt" style="margin-bottom: 30px;">
            Using Research Gateway you can easily manage your organizations, projects, budgets, users, accounts and catalogs. You can also interact
            with the provisioned products.</h3>
          <h3 class="wlcTxt-B">Support is included in your current subscription.</h3>
          <h3 class="wlcTxt">
            You can always reach us via
            <a class="wlcTxt" href="mailto: rlcatalyst@relevancelab.com">email</a> ( rlcatalyst@relevancelab.com ) or visit our
            <a class="wlcTxt"
              target="_blank" href="https://serviceone.relevancelab.com" style="color: #85A2FF !important">website</a> to raise a service ticket. </h3>
          <h3 class="wlcTxt">
            Watch a video on how to setup your system.
            <a target="_blank" href="https://rlcatalyst-researchportal.s3.us-east-2.amazonaws.com/RLCatalyst-Research-Gateway.mp4" class="wlcTxt" style="color: #85A2FF !important">
              Click here.
            </a>
          </h3>
        </div>
        <div class="helpSection">
          <h3 class="help-a">Kickstart your journey with Research Gateway.</h3>
          <h3 class="help-b"> Everything starts with creating your first Organizational Unit.</h3>
          <ul>
            <li class="listValue">
              <img class="help-img" alt="Account Help" src="../../assets/images/accounts.png" />
              <popover-content placement="bottom" #myPopover1 [closeOnClickOutside]="true">
                <div id="tagPop">
                    <ul>
                      <li class="info-popup">
                          <a target="_blank" href="https://rlcatalyst-researchportal.s3.us-east-2.amazonaws.com/RLCatalyst-Research-Gateway.mp4" class="wlcTxt" style="color: #3e6bff !important">
                            Watch a video
                          </a>
                      </li>
                      <li class="info-popup">
                          <a target="_blank" href="https://researchgateway.readthedocs.io/en/latest/GettingStarted.html#adding-an-aws-account-to-use-in-a-project" class="wlcTxt" style="color: #3e6bff !important">
                            Read the docs
                          </a>
                       </li>
                    </ul>
                </div>
              </popover-content>
              <p class="info-p">Account: An AWS account is needed for each project. This account is combination of account number and region. All
                the resources would be created in this account.
                <i title="Help" class="fa fa-info-circle info-icon" aria-hidden="true" [popover]="myPopover1" popoverTitle="How to add an account"
                [popoverCloseOnClickOutside]="true" [popoverOnHover]="false"></i>
              </p>
            </li>

            <li class="listValue">
              <img class="help-img" alt="Project Help" src="../../assets/images/addProject.png" />
              <popover-content placement="bottom" #myPopover2 [closeOnClickOutside]="true">
                <div id="tagPop">
                    <ul>
                      <li class="info-popup">
                          <a target="_blank" href="https://rlcatalyst-researchportal.s3.us-east-2.amazonaws.com/RLCatalyst-Research-Gateway.mp4" class="wlcTxt" style="color: #3e6bff !important">
                            Watch a video
                          </a>
                      </li>
                      <li class="info-popup">
                          <a target="_blank" href="https://researchgateway.readthedocs.io/en/latest/GettingStarted.html#adding-organizational-units" class="wlcTxt" style="color: #3e6bff !important">
                            Read the docs
                          </a>
                      </li>
                    </ul>
                </div>
              </popover-content>
              <p class="info-p">Organizational Unit: An Organizational Unit represents a department or business unit within your organization.
                Create an O.U. to group projects together.
                A Principal Investigator owns the O.U. and can administer all aspects of the O.U. You will be able to get an overview of the budget and costs for this group.
                <i title="Help" class="fa fa-info-circle info-icon" aria-hidden="true" [popover]="myPopover2" popoverTitle="How to add an organization"
                [popoverCloseOnClickOutside]="true" [popoverOnHover]="false"></i>
              </p>
            </li>
            <li class="listValue">
              <img class="help-img" alt="Catalog Help" src="../../assets/images/catalog.png" />
              <popover-content placement="bottom" #myPopover3 [closeOnClickOutside]="true">
                <div id="tagPop">
                    <ul>
                      <li class="info-popup">
                          <a target="_blank" href="https://rlcatalyst-researchportal.s3.us-east-2.amazonaws.com/RLCatalyst-Research-Gateway.mp4" class="wlcTxt" style="color: #3e6bff !important">
                            Watch a video
                          </a>
                      </li>
                      <li class="info-popup">
                          <a target="_blank" href="https://researchgateway.readthedocs.io/en/latest/Features.html#catalog" class="wlcTxt" style="color: #3e6bff !important">
                        Read the docs
                        </a>
                      </li>
                    </ul>
                </div>
              </popover-content>
              <p class="info-p"> Catalog: Once a Organization is created, the next step is to assign products from the catalog to the Organization. You can
                use the standard catalog that comes with Research Gateway or you can bring your own AWS Service Catalog portfolio
                by synchronizing your account.
                <i title="Help" class="fa fa-info-circle info-icon" aria-hidden="true" [popover]="myPopover3" popoverTitle="How to add a catalog"
                [popoverCloseOnClickOutside]="true" [popoverOnHover]="false"></i>
              </p>
            </li>
            <li class="listValue">
              <img class="help-img" alt="Users Help" src="../../assets/images/addUsers.png" />
              <popover-content placement="bottom" #myPopover4 [closeOnClickOutside]="true">
                <div id="tagPop">
                    <ul>
                      <li class="info-popup">
                          <a target="_blank" href="https://rlcatalyst-researchportal.s3.us-east-2.amazonaws.com/RLCatalyst-Research-Gateway.mp4" class="wlcTxt" style="color: #3e6bff !important">
                            Watch a video
                          </a>
                      </li>
                      <li class="info-popup">
                          <a target="_blank" href="https://researchgateway.readthedocs.io/en/latest/Features.html#users" class="wlcTxt" style="color: #3e6bff !important">
                        Read the docs
                        </a>
                      </li>
                    </ul>
                </div>
              </popover-content>
              <p class="info-p"> Users: You can invite additional collaborators to your projects from the users screen.
                <i title="Help" class="fa fa-info-circle info-icon" aria-hidden="true" [popover]="myPopover4" popoverTitle="How to invite users" [popoverCloseOnClickOutside]="true" [popoverOnHover]="false"></i>
              </p>
            </li>
          </ul>
          <div class="col-lg-12 col-md-12" style="text-align: center;margin-top: 30px;margin-bottom: 130px;">
            <button class="launch-btn text-white font-class" (click)="addOrganization()" name="button" type="button">Let's get Started</button>
          </div>
        </div>
    </div>

    <!-- Mobile view -->
<div class="helpHeader-mob">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <img class="wlcimg" alt="Welcome"  src="../../assets/images/welcome@2x.png" />
      <h3 class="welcomeNote"> Welcome to Research Gateway!</h3>
      <h3 class="wlcTxt">
        We’re excited to help accelerate your research through easily-provisioned, scalable, and secure resources in AWS.</h3>
      <h3 class="wlcTxt" style="margin-bottom: 30px;">
        Using Research Gateway you can easily manage your organizations, projects, budgets, users, accounts and catalogs. You can also interact
        with the provisioned products.</h3>
      <h3 class="wlcTxt-B">Support is included in your current subscription.</h3>
      <h3 class="wlcTxt">
        You can always reach us via
        <a class="wlcTxt" href="mailto: rlcatalyst@relevancelab.com">email</a> ( rlcatalyst@relevancelab.com ) or visit our
        <a class="wlcTxt"
          target="_blank" href="https://serviceone.relevancelab.com" style="color: #85A2FF !important">website</a> to raise a service ticket. </h3>
      <h3 class="wlcTxt">
        Watch a video on how to setup your system.
        <a target="_blank" href="https://rlcatalyst-researchportal.s3.us-east-2.amazonaws.com/RLCatalyst-Research-Gateway.mp4" class="wlcTxt" style="color: #85A2FF !important">
          Click here.
        </a>
      </h3>
    </div>

    <div class="helpSection-mob">
      <h3 class="help-a">Kickstart your journey with Research Gateway.</h3>
      <h3 class="help-b"> Everything starts with creating your first Organizational Unit.</h3>
      <ul>
        <li class="listValue">
          <img class="help-img" alt="Account Help" src="../../assets/images/accounts.png" />
          <p class="info-p">Account: An AWS account is needed for each project. This account is combination of account number and region. All
            the resources would be created in this account.
            <!-- <i title="Help" class="fa fa-info-circle info-icon" aria-hidden="true" [popover]="myPopover" popoverTitle="Help"
            [popoverCloseOnClickOutside]="true"></i> -->
          </p>
        </li>

        <li class="listValue">
          <img class="help-img" alt="Project Help" src="../../assets/images/addProject.png" />
          <p class="info-p">Organizational Unit: An Organizational Unit represents a department or business unit within your organization.
            Create an O.U. to group projects together.
            A Principal Investigator owns the O.U. and can administer all aspects of the O.U. You will be able to get an overview of the budget and costs for this group.
            <!-- <i title="Help" class="fa fa-info-circle info-icon" aria-hidden="true" [popover]="myPopover" popoverTitle="Help"
            [popoverCloseOnClickOutside]="true"></i> -->
          </p>
        </li>
        <li class="listValue">
          <img class="help-img" alt="Catalog Help" src="../../assets/images/catalog.png" />
          <p class="info-p"> Catalog: Once a Project is created, the next step is to assign products from the catalog to the Project. You can
            use the standard catalog that comes with Research Gateway or you can bring your own AWS Service Catalog portfolio
            by synchronizing your account.
            <!-- <i title="Help" class="fa fa-info-circle info-icon" aria-hidden="true" [popover]="myPopover2" popoverTitle="Help"
            [popoverCloseOnClickOutside]="true"></i> -->
          </p>
        </li>
        <li class="listValue" style="margin-bottom: 25px;">
          <img class="help-img" alt="Users Help" src="../../assets/images/addUsers.png" />
          <p class="info-p"> Users: You can invite additional collaborators to your projects from the users screen.
            <!-- <i title="Help" class="fa fa-info-circle info-icon" aria-hidden="true" [popover]="myPopover3" popoverTitle="Help" [popoverCloseOnClickOutside]="true"></i> -->
          </p>
        </li>
      </ul>
      <div class="col-sm-12" style="text-align: center;">
        <button class="launch-btn text-white font-class" (click)="addOrganization()" name="button" type="button">Let's get Started</button>
      </div>
    </div>
  </div>
    </div>
  <!-- <div class="load-more-btn-wrapper" *ngIf="organizations.length > 4">
    <button class="load-more-btn font-class">Load More</button>
  </div> -->

  <app-loader class="spinner" *ngIf="loader"></app-loader>
