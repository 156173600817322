import { Component, OnInit, Inject } from '@angular/core';
import { MdlDialogReference } from '@angular-mdl/core';
import { ServiceActionsService} from "../../_services/service-actions.service";
import { S3DataService } from "../../_services/s3-data.service";
import { Router,ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {Globals} from "../../globals";

@Component({
  selector: 'app-s3-empty-folder',
  templateUrl: './s3-empty-folder.component.html',
  styleUrls: ['./s3-empty-folder.component.css'],
  providers: [Globals]
})
export class S3EmptyFolderComponent implements OnInit {
  s3Folderobj: any;
  service: string = "s3";
  folderName: any;
  nestedFolder: any;
  showSpinner: boolean = false;
  showErrorMsg;

  constructor(
    @Inject('data') public data: any,
    public dialogRef: MdlDialogReference,
    private serviceAction: ServiceActionsService,
    private s3DataService: S3DataService,
    private toastr: ToastrService,
    private router: Router,
    private globals: Globals) { }

  ngOnInit() {
    if(this.data.hasOwnProperty('s3Folderobj')){
      this.s3Folderobj = this.data.s3Folderobj;
      this.service = this.data.s3Folderobj.service ? this.data.s3Folderobj.service : this.service;
    }
    this.nestedFolder = this.s3Folderobj.folderName;
  }

  cancel(): void {
    this.dialogRef.hide();
  }

  createS3EmptyFolder(){
    if(this.s3Folderobj.folderName){
      this.s3Folderobj.key = this.s3Folderobj.folderName+'/'+this.folderName;
      this.s3DataService.setFolderPrefix({folderName:this.s3Folderobj.folderName, rootFolder: false});
    }else{
      this.s3Folderobj.key = this.folderName;
      this.s3DataService.setFolderPrefix({ folderName:this.s3Folderobj.key, rootFolder: true} );
    }
    this.showSpinner = true;
    this.serviceAction.serviceAction(this.s3Folderobj).subscribe(res => {
    this.dialogRef.hide();
    this.showSpinner = false;
    if(this.s3Folderobj.isStudy){
      this.router.navigate([`/explore/${this.s3Folderobj.id}/success/uploadSuccess`], {queryParams:{name: this.s3Folderobj.name, type: this.s3Folderobj.type, accountId: this.s3Folderobj.account_id}});
    }else{
      this.router.navigate([`s3/bucket/${this.s3Folderobj.bucketName}/success/uploadSuccess`]);
    }
    this.toastr.success(`Created new folder ${this.folderName} successfully`, '', this.globals.tosterOverride);

    }, error => {
      this.dialogRef.hide();
      this.showSpinner = false;
      console.error(error);
      let errBody = error.error;
      if(error && error.status === 401){
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
       }else{
        this.toastr.error(`${this.s3Folderobj.bucketName} - `+errBody.error, 'Error', this.globals.tosterOverride)
       }
      });
  }

  validateForwardSlash(event) {
    if (event.includes('/')) {
      this.showErrorMsg = true;
    } else {
      this.showErrorMsg = false;
    }
  };


}
