import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from "@angular/router";
import { ResearchService } from "../_services/research.service";
import { S3DataService } from "../_services/s3-data.service";
import { ServiceActionsService} from "../_services/service-actions.service";
import { MdlDialogService } from '@angular-mdl/core';
import { ConfirmationDialog } from '../dialog/dialog.component';
import { LinkSagemakerComponent } from '../dialog/link-resource/link-resource.component';
import { UnlinkResourceComponent } from '../dialog/unlink-resource/unlink-resource.component';
import { InstanceComponent } from '../catalog/instance/instance.component';
import { ToastrService } from 'ngx-toastr';
import { TabDirective } from 'ngx-bootstrap/tabs';
import {Globals} from "../globals";
import { ProductUtilService} from "../_services/productUtil.service";
import { S3UnshareComponent } from '../dialog/s3-unshare/s3-unshare.component';
import {DashboardService} from "../_services/dashboard.service";
import { InstanceTypeComponent } from '../dialog/instance-type/instance-type.component';
import {FormGroup, FormControl} from '@angular/forms';
import { Chart, registerables } from "chart.js";
import { AttachVolumeComponent } from '../product-details/attach-volume/attach-volume.component';
import { DetachVolumeComponent } from '../product-details/detach-volume/detach-volume.component';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css', '../app.component.css'],
  providers: [Globals]
})
export class ProductDetailsComponent implements OnInit {
  public chart: Chart;
  projectName;
  productName;
  instanceType:any
  filteredInstance:any
  accountId;
  ppId;
  productDetails;
  parameters=[];
  currentPage=1;
  limit=10;
  events=[];
  getETADetails= "";
  loadMore = false;
  imageUrl;
  connectMenu=[];
  actionsMenu=[];
  public loader = false;
  egressStoreLoader = false;
  isLoaded = false;
  isLinked;
  isOwner = false;
  listOfLinkedProducts=[];
  userName: any;
  researcherList = [];
  sharedResearcherList = [];
  recordOutputs = [];
  Researchervalue;
  terminateAction;
  bucketName;
  notebookName;
  websiteURL;
  rStudioURL;
  dcvUrl;
  getInstanceIp;
  projectStatus:any;
  s3BucketTotalrecords;
  activeSageMakerTotalrecords;
  activeSageMakerNotebooks = [];
  showAction = true;
  parametersObservable: any;
  resourceType;
  showProductAction = true;
  instancePublicIp;
  instanceId;
  platformType;
  routeBackUrl;
  userLevel;
  projectId;
  instanceDNSName;
  applicationPort;
  dailyCost: any[];
  cost=[];
  costdate=[];
  filterSearchDate = '';
  noDataTemplate = 'Product Has Not Consumed';
  toTime;
  fromTime;
  dateChanged: boolean = false;
  valFilter = true;
  public date: any = {};
  range = new FormGroup({
    start: new FormControl(null),
    end: new FormControl(null),
  });
  pastOneDay;
  pastSevenDays;
  createdDate;
  status;
  stopDate;
  gotEventsData = true;
  eventsCurrentPageLowerCount = 1;
  eventsCurrentPageUpperCount = 5;
  eventsTotalRecords = 0;
  eventsPage = 1;
  eventsTotalPages = 1;
  eventsLimit = 10;
  serviceName;
  showVolume = false;
  hideVolume = false;
  volumeName = [];
  serviceArray = ['ec2','rstudio', 'nextflow', 'cromwell', 'ec2-dcv'];
  showButton =  false;
  showLoadMore = false;
  egressStoreFiles = [];
  paginationToken = "";
  pageSize = 20;
  egressStorePrefix;
  isEgressStoreEnabled:any = false;
  isAbleToSubmitEgressRequest;
  clicked = false;
  isRefreshDisabled = false;

  public model: any = {
    // beginDate: { year: new Date().getFullYear(), month: new Date().getMonth(), day: new Date().getDate() },
    // endDate: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }
  };
  menuContainer: any = [
    { name: 'ec2', menu: ['SSH/RDP', 'Start', 'Stop', 'Reboot','Instance Type', 'Share'] },
    { name: 'sagemaker', menu: ['Open Notebook', 'Start', 'Stop', 'Share'] },
    { name: 'workspace', menu: ['Link', 'Start', 'Stop', 'Restart', 'Share'] },
    { name: 's3', menu: ['Upload', 'Explore', 'Share'] },
    { name: 'wordpress', menu: ['Website-URL'] }
  ];
  imageContainer: any = [
    { name: 'sagemaker', url: '/assets/images/aws_icon/sagemaker.png' },
    { name: 'emr', url: '/assets/images/aws_icon/emr.png' },
    { name: 'rds', url: '/assets/images/aws_icon/rds.png' },
    { name: 'machinelearning', url: '/assets/images/aws_icon/machinelearning.png' },
    { name: 'azure-ml', url: '/assets/images/azure_icon/azure-ml.png' },
    { name: 'azure-rstudio', url: '/assets/images/azure_icon/azure.png' },
    { name: 'hpc', url: '/assets/images/aws_icon/openhpc.png' },
    { name: 'workbench', url: '/assets/images/aws_icon/sagemaker.png' },
    { name: 'vpn', url: '/assets/images/aws_icon/vpn.png' },
    { name: 'vpc', url: '/assets/images/aws_icon/vpc.png' },
    { name: 'dynamodb', url: '/assets/images/aws_icon/dynamodb.png' },
    { name: 's3', url: '/assets/images/aws_icon/s3.png' },
    { name: 'blob', url: '/assets/images/azure_icon/azure.png' },
    { name: 'workspace', url: '/assets/images/aws_icon/workspaces.png' },
    { name: 'rlcatalyst', url: '/assets/images/logo_rl.png' },
    { name: 'catalyst', url: '/assets/images/logo_rl.png' },
    { name: 'ec2', url: '/assets/images/aws_icon/ec2.png' },
    { name: 'vm', url: '/assets/images/azure_icon/azure.png' },
    { name: 'office', url: '/assets/images/aws_icon/ms_office.png' },
    { name: 'oracle', url: '/assets/images/aws_icon/oracle.png' },
    { name: 'windows', url: '/assets/images/aws_icon/windows.png' },
    { name: 'cisco', url: '/assets/images/aws_icon/cisco.png' },
    { name: 'aws', url: '/assets/images/aws_icon/aws.png' },
    { name: 'linux', url: '/assets/images/aws_icon/linux.png' },
    { name: 'wordpress', url: '/assets/images/aws_icon/wordpress.png' },
    { name: 'cloud9-ide', url: '/assets/images/aws_icon/cloud9.png' },
    { name: 'rdp', url: '/assets/images/aws_icon/rdp.png' },
    { name: 'appstream', url: '/assets/images/aws_icon/appStream.png' },
    { name: 'chime', url: '/assets/images/aws_icon/chime.png' },
    { name: 'connect', url: '/assets/images/aws_icon/connect.png' },
    { name: 'redshift', url: '/assets/images/aws_icon/redshift.png' },
    { name: 'controltower', url: '/assets/images/aws_icon/controltower.png' },
    { name: 'docker', url: '/assets/images/aws_icon/docker.png' },
    { name: 'drupal', url: '/assets/images/aws_icon/drupal.png' },
    { name: 'emailexchange', url: '/assets/images/aws_icon/emailexchange.png' },
    { name: 'instancescheduler', url: '/assets/images/aws_icon/instancescheduler.png' },
    { name: 'mongodb', url: '/assets/images/aws_icon/mongodb.png' },
    { name: 'moodle', url: '/assets/images/aws_icon/moodle.png' },
    { name: 'sharepoint', url: '/assets/images/aws_icon/sharepoint.png' },
    { name: 'shibboleth', url: '/assets/images/aws_icon/shibboleth.png' },
    { name: 'tableau', url: '/assets/images/aws_icon/tableau.png' }
  ];
  nextflowOutputPath;
  eventS = this.getEventSource(`/stream_events`);

  constructor(private route: ActivatedRoute, private researchService: ResearchService,
              private serviceAction: ServiceActionsService,private dialog: MdlDialogService,
              private toastr: ToastrService,
              private globals: Globals,
              private s3DataService: S3DataService,
              private router: Router,
              private productUtil: ProductUtilService,
              private apiService: DashboardService) { 
                router.events.subscribe((val) => { 
                  const navVal = val instanceof NavigationStart; 
                  if(navVal == true) { 
                    this.eventS.close();
                  }  
                });
                Chart.register(...registerables);
                
              }

  ngOnInit() {
    //this.apiService.checkIfResearcher();
    this.parametersObservable = this.route.params.subscribe(params => {
    this.route.queryParams.subscribe(map => map);
    this.projectStatus = this.route.snapshot.queryParams['projectStatus'];
    this.userName = sessionStorage.getItem('currentUser');
    this.userLevel = sessionStorage.getItem('Level');
    this.projectId = sessionStorage.getItem('projectId');
    if(this.userLevel == '1'){
      this.routeBackUrl = `/project-details/${this.projectId}?showTab=myProducts`
    }else if(this.userLevel == '0'){
      this.routeBackUrl = `/catalog/${this.projectName }/${this.accountId}`
    }
    // this.projectName = this.route.snapshot.params['projectName'];
    this.projectName = sessionStorage.getItem('projectName');
    this.productName = this.route.snapshot.params['productName'];
    this.accountId = this.route.snapshot.params['accountId'];
    this.ppId = this.route.snapshot.paramMap.get('ppId');
    this.getProductDetails();
    setTimeout(() => {
      this.getDailyCost();
    }, 500);
    this.isEgressStoreEnabled = sessionStorage.getItem('isEgressStoreEnabled');
    // Toggle product actions button for mobile view
    $(document).on("click", ".toggle-button", function(e) {
      e.preventDefault();
      $(this).parent().find("ul").slideToggle();
    });

    $(document).on("click", ".connect-link", function(e) {
      e.preventDefault();
      $(this).parent().parent().parent().parent().find("ul").slideToggle();
    });

    $(document).on("click", ".action-link", function(e) {
      e.preventDefault();
      $(this).parent().parent().parent().parent().find("ul").slideToggle();
    });

    this.serverStreamEvent(); 
    if(params["linkResource"]==="linkResource" || params['linkResource']==="unlinkResource"){
      this.router.navigate(['/product/' + this.projectName + '/' +
      this.productName , { ppId: this.ppId }],{ queryParams: { projectStatus: this.projectStatus}});
    }
  })
}

refresh() {
  this.getDailyCost();
}

getpast7days(){
  const date = new Date();
  const day = new Date(date)
  day.setDate(day.getDate() - 7)
  day.setHours(0)
  day.setSeconds(0)
  day.setMinutes(0)
  return day
}

getPastDay(){
  const date = new Date();
  const yesterday = new Date(date)
  yesterday.setDate(yesterday.getDate() -1)
  yesterday.setHours(23)
  yesterday.setSeconds(59)
  yesterday.setMinutes(59)
  return yesterday
}

getCurrentDate(){
  const date = new Date();
  var splitDate = date.toISOString().split('T');
  let currentDate = splitDate[0];
  return currentDate
}

getTodayDate(){
  const date = new Date();
  const todayDate = new Date(date)
  todayDate.setDate(todayDate.getDate())
  todayDate.setHours(23)
  todayDate.setSeconds(59)
  todayDate.setMinutes(59)
  return todayDate
}
getDefaultDate(res){
  let pastOneday = this.getPastDay();
  this.pastOneDay = pastOneday.toISOString();
  let day =this.getpast7days();
  this.pastSevenDays = day.toISOString();
  //getting product created and terminated date
  this.status = this.getStatus(res.Status);
  let terminatedDate = res.updated_on;
  let terDate = new Date(terminatedDate);
  let productCreatedDate = res.createdTime;
  let productDate = new Date(productCreatedDate);
  let proDate = new Date(productCreatedDate);
  proDate.setDate(proDate.getDate())
  proDate.setHours(0)
  proDate.setSeconds(0)
  proDate.setMinutes(0)
  var splitArray = productDate.toISOString().split('T');
  let splitProdDate =splitArray[0]
  var splitTerminateArr = terDate.toISOString().split('T');
  let splitTerminateDate =splitTerminateArr[0]
  let date = this.getCurrentDate();
  let currentDate =this.getTodayDate()
  if(splitProdDate == date){
    this.pastSevenDays = currentDate.toISOString();
    this.pastOneDay = currentDate.toISOString();
  }
  else if (proDate > day){
    this.pastSevenDays = proDate.toISOString();
  }
  if(this.status == 'Terminated' || this.status == 'Terminating' || this.status == 'Failed'){
    let convDate = new Date(terminatedDate);
    convDate.setHours(23)
    convDate.setSeconds(59)
    convDate.setMinutes(59)
    this.pastOneDay = convDate.toISOString();
    let lastSevenDays =new Date(convDate);
    lastSevenDays.setDate(lastSevenDays.getDate() - 6)
    lastSevenDays.setHours(0)
    lastSevenDays.setSeconds(0)
    lastSevenDays.setMinutes(0)
    if(splitProdDate == date){
      this.pastSevenDays = currentDate.toISOString();
      this.pastOneDay = currentDate.toISOString();
    }
    else if(splitTerminateDate ==date ){
      this.pastOneDay = pastOneday.toISOString();
    }else if(proDate > day || proDate > lastSevenDays){
      this.pastSevenDays = proDate.toISOString();       
    }else{
      this.pastSevenDays = lastSevenDays.toISOString();
    }} 
    this.filterSearchDate = `&startDate=${this.pastSevenDays}&endDate=${this.pastOneDay}`;
  }

  getProductDetails() {
    try{
      let keys = ['storageAccount','customData','dnsLabelPrefix','location','readerRoleAssignmentId','blobReaderRoleAssignmentId'];
      this.showProductAction = true;
      this.isLoaded = false;
      this.researchService.getProvisionedProductDetails(this.ppId).subscribe((res:any) => {
        this.productDetails = res;
        this.isLoaded = true;
        this.accountId = res.account_id;
        this.getResearcherList();
        this.getDatepickerRange(res);
        this.getDefaultDate(res);
        this.sharedResearcherList = res.sharedTo;
        this.recordOutputs = res.RecordOutputs;
        this.isOwner = res.isOwner;
        //this.parameters = res.ProvisioningParameters;
        this.parameters = [];
        res.ProvisioningParameters.forEach((element) => {
        if(!keys.includes(element.Key)){
          this.parameters.push(element);
        }});
        this.egressStorePrefix = res?.egressStorePrefix;
        this.isAbleToSubmitEgressRequest = res?.isAbleToSubmitEgressRequest;
        this.productDetails.budgetConsumed = this.productDetails.budgetConsumed.toFixed(2);   
        if(this.productDetails.isShared === true){
          if(this.userName === this.productDetails.user && this.productDetails.isOwner){
          this.terminateAction = true;
          }
        }
        if(this.productDetails.isShared === false){
          if(this.userName === this.productDetails.user){
         this.terminateAction = true;
        }
     }
        
        this.parameters.forEach((item) => {
          if(item.Key.toLowerCase().includes("password")){
            item.Value = "*".repeat(item.Value.length);
          };
          if(item.Key.toLowerCase().includes("outputdatalocation")){ 
            this.nextflowOutputPath = item.Value;
          }
          //This is to pretty print the json value
          if(item.Key === 'IamPolicyDocument' || item.Key === 'S3Mounts'){
            item.Value = JSON.parse(item.Value);
          }
        })
        this.getProductETA(this.productDetails.Tags);
        this.getMeunForService();
        this.getLogs();
        //This is to get the S3 bucket name and notebook name from the resources post-provisioning
        if(res.Resources.length > 0){
          this.resourceType = res.Resources[0].Type;
          switch (this.resourceType) {
            case 'AWS::S3::Bucket':
              this.bucketName = res.Resources[0].PhysicalResourceId;
              this.getS3RootFolder();
              this.getActiveSagemakerNotebooks();
              break;
            case 'AWS::SageMaker::NotebookInstance':
              let resourceId = res.Resources[0].PhysicalResourceId;
              let resourceARNArray = resourceId.split(":");
              let instanceName = resourceARNArray[resourceARNArray.length-1];
              this.notebookName = instanceName.split("/")[1];
              break;
            default:
              break;
          }
        }
        if(res.isLinked){
          this.isLinked = res.isLinked;
          //This is to show the loggedIn researchers notebooks only when the one S3 bucket is shared
          for(var i=0; i< res.listOfLinkedProducts.length ; i++){
            if(res.listOfLinkedProducts[i].user === this.userName){
              this.listOfLinkedProducts.push(res.listOfLinkedProducts[i]);
              this.hideVolume = true;
              this.volumeName.push(res.listOfLinkedProducts[i].productName)
              if(this.volumeName.includes("Amazon EBS volume")){
                this.showVolume = true;
                this.hideVolume = false;
              }
            }
          }
        }
        if(res.ProductName == "Amazon EBS volume"){
          this.hideVolume = false;
        }
        this.dataBindingAvailableProduct();
        if(this.serviceArray.includes(this.serviceName) && this.isLinked !== true && this.productDetails.availableVolumes.length > 0){
        this.showButton =  true;
        }
      }, error => {
        this.loader = false;
        let errBody = error.error;
        if(error && error.status === 401){
           this.toastr.error(errBody.err, '', this.globals.tosterOverride);
           this.router.navigate(['login']);
         }else if (error && error.status === 403) {
          const err = errBody.error ? errBody.error : errBody;
          this.toastr.error(err, 'Error', this.globals.tosterOverride);
          this.router.navigate(['pageNotFound']);
        }else{
          this.toastr.error(errBody, 'Error', this.globals.tosterOverride);
          }
  });
    }catch(error){
      //console.log(error);
      // this.toastr.error(error, 'Error', this.globals.tosterOverride);
    }
  }


  getDatepickerRange(res){
      this.createdDate = res.createdTime ? res.createdTime : new Date();
      this.status = this.getStatus(res.Status);
      this.stopDate = new Date();
      if(this.status == 'Terminated' || this.status == 'Terminating' || this.status == 'Failed'){
        this.stopDate = res.updated_on ? res.updated_on : new Date();
      }

  }

  getStatus(status) {
    let statusV = status;
    let state = statusV.toUpperCase();
    switch (state) {
      case 'INITIATED':
        statusV = 'Initiated';
        break;
      case 'CREATE_IN_PROGRESS':
        statusV = 'Creating';
        break;
      case 'CREATE_COMPLETE':
        statusV = 'Provisioned';
        break;
      case 'CREATE_FAILED':
        statusV = 'Failed';
        break;
      case 'ROLLBACK_IN_PROGRESS':
        statusV = 'Failed';
        break;
      case 'ROLLBACK_COMPLETE':
        statusV = 'Failed';
        break;
      case 'CREATED':
        statusV = 'Provisioned';
        break;
      case 'AVAILABLE':
        statusV = 'Provisioned';
        break;
      case 'ERROR':
        statusV = 'Failed';
        break;
      case 'DELETE_IN_PROGRESS':
        statusV = 'Terminating';
        break;
      case 'DELETE_COMPLETE':
        statusV = 'Terminated';
        break;
      default :
        break;
    }
    return statusV;
  }

  getStatusColor(status) {
    let statusV = 'status-paused';
    switch (status.toLowerCase()) {
      case 'active':
        statusV = 'status-running';
        break;
      case 'warning':
        statusV = 'status-paused';
        break;
      case 'failed':
        statusV = 'status-stopped';
        break;
      case 'create_in_progress':
        statusV = 'status-paused';
        break;
      case 'create_complete':
        statusV = 'status-running';
        break;
      case 'create_failed':
        statusV = 'status-stopped';
        break;
      case 'rollback_complete':
        statusV = 'status-stopped';
        break;
      case 'available':
        statusV = 'status-running';
        break;
      case 'created':
        statusV = 'status-running';
        break;
      case 'error':
        statusV = 'status-stopped';
        break;
      default :
        statusV = 'status-paused';
        break;
    }
    return statusV;
  }

  refreshProduct() {
    this.loader = true;
    this.researchService.getProvisionedProductDetails(this.ppId).subscribe((res:any)  => {
      this.loader = false;
      this.productDetails = res;
      this.productDetails.Status = res.Status;
      this.recordOutputs = [];
      this.recordOutputs = res.RecordOutputs;
      this.getMeunForService();
      this.getResearcherList();
      this.dataBindingAvailableProduct();
      this.getDatepickerRange(res);
      this.getDefaultDate(res);
      this.productDetails.budgetConsumed = this.productDetails.budgetConsumed.toFixed(2);
      if(res.Resources.length > 0){
        this.resourceType = res.Resources[0].Type;
        switch (this.resourceType) {
          case 'AWS::S3::Bucket':
            this.bucketName = res.Resources[0].PhysicalResourceId;
            this.getActiveSagemakerNotebooks();
            break;
          case 'AWS::SageMaker::NotebookInstance':
            let resourceId = res.Resources[0].PhysicalResourceId;
            let resourceARNArray = resourceId.split(":");
            let instanceName = resourceARNArray[resourceARNArray.length-1];
            this.notebookName = instanceName.split("/")[1];
            break;
          default:
            break;
        }
      }
    }, error => {
      this.loader = false;
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
});
  }

  getProductETA(data) {
    try {
      const getETA = data.find((obj) => obj.Key === 'EstimatedTimeToProvision');
      this.getETADetails = getETA['Value'] || 'NA';
    } catch (error) {
      this.getETADetails = 'NA';
    }
  }

  getMeunForService(){
    this.connectMenu = [];
    this.loader = true;
    let body = {
      id: this.productDetails.ProvisionedProductId
    };
    this.serviceAction.getActionsMenuForService(body).subscribe((res:any) => {
      this.loader = false;
      if(res.status === "success"){
        this.connectMenu = [];
        for(var i=0;i< res.actions.connect.length;i++){
          this.connectMenu.push(res.actions.connect[i])
        }
        this.actionsMenu = res.actions.action;
        if(this.resourceType === "AWS::S3::Bucket"){
          if(this.isOwner === true){
            if(this.productDetails.isShared === false){
              const idx1 = this.actionsMenu.findIndex(action => action.action === "Unshare");
              this.actionsMenu.splice(idx1, 1);
            }}else{
            const idx1 = this.actionsMenu.findIndex(action => action.action === "Unshare");
            const idx2 = this.actionsMenu.findIndex(action => action.action === "Share");
            this.actionsMenu.splice(idx1, 1);
            this.actionsMenu.splice(idx2, 1);
            }
            if(this.productDetails.isProjectStorage || this.productDetails?.isIngressStorage){
              const idx1 = this.actionsMenu.findIndex(action => action.action === "Share");
              this.actionsMenu.splice(idx1, 1);
              const id = this.connectMenu.findIndex(connect => connect.action === "Link");
              this.connectMenu.splice(id, 1);
            }
            if(this.productDetails?.isIngressStorage){
              const idx1 = this.actionsMenu.findIndex(action => action.action === "Upload");
              this.actionsMenu.splice(idx1, 1);
            }
        }
        if(this.serviceName == "ec2-secure-desktop"){
            const idx1 = this.actionsMenu.findIndex(action => action.action === "Unshare");
            const idx2 = this.actionsMenu.findIndex(action => action.action === "Share");
            this.actionsMenu.splice(idx1, 1);
            this.actionsMenu.splice(idx2, 1);
        }
  }
    }, (error) => {
      let err = error.error;
    })
  }

  getLogs(){
    this.loader = true;
    this.researchService.getProvisionedProductsAuditTrail(this.eventsPage, this.eventsLimit, this.productDetails.ProvisionedProductId).subscribe( (data:any) => {
      this.loader = false;
      this.gotEventsData = true;
      this.events = data.docs;
        if(this.events.length == 0){
          this.gotEventsData = false;
        }
        this.eventsTotalRecords = data.total || 0;
        this.eventsPage = data.page;
        this.eventsTotalPages = data.pages;
        this.eventsLimit = data.limit;
        this.eventsCurrentPageLowerCount = 1 + ((this.eventsPage - 1) * this.eventsLimit);
        if (this.events.length < 10) {
          this.eventsCurrentPageUpperCount = this.events.length + (this.eventsLimit * (this.eventsPage - 1));
        }else {
          this.eventsCurrentPageUpperCount = this.eventsLimit * this.eventsPage;
        }
    }, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    });
  }

  getNextProductEvents(action) {
    if (action === 'first' && this.eventsPage !== 1) {
      this.eventsPage = 1;
      this.getLogs();
    }
    if (action === 'previous' && this.eventsPage > 1) {
      this.eventsPage = this.eventsPage - 1;
      this.getLogs();

    }
    if (action === 'next' && this.eventsPage !== this.eventsTotalPages) {
      this.eventsPage = this.eventsPage + 1;
      this.getLogs();

    }
    if (action === 'last' && this.eventsPage !== this.eventsTotalPages) {
      this.eventsPage = this.eventsTotalPages;
      this.getLogs();
    }
  }



  getDailyCost() {
    this.loader = true;
    this.costdate = [];
    this.cost = [];
    this.dailyCost=[];
    this.researchService.getDailyBudget(this.ppId, this.filterSearchDate).subscribe((res: any) => {
      this.dailyCost = res;
      this.loader = false;
      for (let costDetails of res) {
        this.costdate.push(costDetails.date.slice(5).split("T00:00:00.000Z").join(""))
        this.cost.push(costDetails.cost)
      }
      if (this.chart != undefined)
        this.chart.destroy();
      if (this.costdate.length != 0 || this.cost.length != 0) {
        this.chart = new Chart("canvas", {
          type: "bar",
          data: {
            labels: this.costdate,
            datasets: [
              {
                backgroundColor: "#57c1db",
                borderWidth: 1,
                hoverBackgroundColor: "#57c1db",
                hoverBorderColor: "#57c1db",
                data: this.cost
              }
            ]
          },
          options: {
            plugins: {
              legend: {
                display: false
              }
            },
            responsive: true,
            scales: {
              x: {
                display: true,
                title: {
                  display: true,
                  text: 'Date',
                  color: 'white',
                  font: {
                    family: 'Nunito Sans',
                    size: 12,
                    style: 'normal',
                    lineHeight: 1.2,
                  },
                },
                grid: {
                  color: 'white',
                  lineWidth: 0.1,
                  borderColor: 'white',
                },
                ticks: { color: 'white' }
              },
              y: {
                title: {
                  display: true,
                  text: 'Cost (USD)',
                  color: 'white',
                  font: {
                    family: 'Nunito Sans',
                    size: 12,
                    style: 'normal',
                    lineHeight: 1.2
                  },
                },
                grid: {
                  color: 'white',
                  lineWidth: 0.1,
                  borderColor: 'white',
                },
                ticks: { color: 'white' }
              }
            }
          }

        });
      }
    }, error => {
      this.loader = false;
      if (error && error.status === 401) {
        let errBody = error.error;
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
      }

    })
  }

 setDate(event) {
  this.dateChanged = true;
  if (event.beginJsDate == null && event.endJsDate == null) {
    this.date["startDate"] = null;
    this.date["endDate"] = null;
  } else {
    this.date["startDate"] = new Date(event.beginDate.year + "-" + event.beginDate.month + "-" + event.beginDate.day).toISOString();
    const endDate = new Date(event.endDate.year + "-" + event.endDate.month + "-" + event.endDate.day);
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);
    this.date["endDate"] = endDate.toISOString();
  }
  const reqDate = this.date;
  if (reqDate) {
    if (reqDate.startDate && reqDate.endDate) {
      this.filterSearch(reqDate, 'date');
    } 
  }
}

dateRangeChange(dateRangeStart, dateRangeEnd) {
  if(!dateRangeStart.value || !dateRangeEnd.value){
    // console.log('Invalid Input parameter');
    return 
  }
  this.dateChanged = true;
  let startDate :any;
  this.date["startDate"] = new Date(dateRangeStart.value).toISOString();
  let endDate :any;
  endDate = new Date(dateRangeEnd.value);
  endDate.setHours(23);
  endDate.setMinutes(59);
  endDate.setSeconds(59);
  this.date["endDate"] = endDate.toISOString();
  const reqDate = this.date;
  if (reqDate) {
    if (reqDate.startDate && reqDate.endDate) {
      this.filterSearch(reqDate, 'date');
    } else {
      this.filterSearchDate = '';
      this.refresh();
  
    }
  } else {
    this.filterSearchDate = '';
    this.refresh();
   
  }
}

filterSearch(reqDate, type) {
  this.valFilter = false;
  if (type === 'date') {
    this.filterSearchDate = `&startDate=${reqDate.startDate}&endDate=${reqDate.endDate}`;
    this.refresh(); 
  } 
}


  refreshLogs(){
    this.events = [];
    this.currentPage = 1;
    this.getLogs();
  }

  loadMoreEvents(){
    this.currentPage = this.currentPage + 1;
    this.getLogs();
  }

  actions(provProduct, action) {
    let body;
    let instanceId;
    let service = this.getActiveMenu(provProduct.Tags, "");   
    if(service === 'ec2' || service === 'vm' || service === 'rstudio' || service === 'nextflow'  || service === 'cromwell' || service === 'ec2-dcv'|| service === 'ec2-igv' || service === 'ec2-secure-desktop' || service === 'ec2-vscode' || service === 'ec2-jupyterlab' || service === 'ec2-spyder'){
      for(var i=0;i< provProduct.RecordOutputs.length;i++){
        if(provProduct.RecordOutputs[i]['OutputKey'] === "InstanceId"){
            instanceId = provProduct.RecordOutputs[i]['OutputValue']
        }
        if (provProduct.RecordOutputs[i]['OutputKey'] === 'InstanceIPAddress') {
          this.getInstanceIp = provProduct.RecordOutputs[i]['OutputValue'];
        }
      }
      //Get platform type of instance
      if( provProduct.Resources.length > 0 ){
        for(var i=0;i< provProduct.Resources[0].Details.length;i++){
          if(provProduct.Resources[0].Details[i]['Name'] === 'Platform'){
            this.platformType = provProduct.Resources[0].Details[i]['Value']
          }
        }
      }
      body = {
        "account_id" : provProduct.account_id,
        "provisionedProductId": provProduct.ProvisionedProductId,
        "service":service,
        "action": action.toLowerCase(),
        "instanceId": instanceId,
        "instanceIp": this.getInstanceIp,
        "projectId": this.projectId
      }
    } else if(service === 'pcluster'){
        const resourceDetails = (provProduct.Resources.find(resource => resource.LogicalResourceId == "HeadNode") || "").Details || [];
        const pclusterInstanceId = (resourceDetails.find(detail => detail.Name == "InstanceId") || "").Value || '';
        const pclusterInstanceIp = (resourceDetails.find(detail => detail.Name == "PublicIpAddress") || "").Value || '';
        body = {
          "account_id" : provProduct.account_id,
          "provisionedProductId": provProduct.ProvisionedProductId,
          "service":service,
          "action": action.toLowerCase(),
          "instanceId": pclusterInstanceId,
          "instanceIp": pclusterInstanceIp,
          "projectId": this.projectId
        }
    } else if(service === 'sagemaker') {
      body = {
      "service": service.toLowerCase(),
      "action": action.toLowerCase(),
      "ProvisioningParameters": provProduct.ProvisioningParameters,
      "account_id": provProduct.account_id,
      "resources": provProduct.Resources,
      "projectId": this.projectId,
      "provisionedProductId": provProduct.ProvisionedProductId,
    };
    }else if(service === 's3' && action === 'Upload'){
      body = {
        "service": service.toLowerCase(),
        "action": action.toLowerCase(),
        "ProvisioningParameters": provProduct.ProvisioningParameters,
        "account_id": provProduct.account_id,
        "resources": provProduct.Resources,
        "provisionedProductName": provProduct.ProvisionedProductName,
        "rootFolder": true,
        "projectName":this.projectName,
        "bucketName": provProduct.Resources[0].PhysicalResourceId,
        "isShared": this.productDetails.isShared,
        "status": provProduct.Status,
        "ppId": this.ppId,
        "projectStatus": this.projectStatus,
        "productName":this.productName,
        "userLevel": this.userLevel,
        "projectId": this.projectId,
        "productStatus": provProduct.Status,
        "provisionedProductId": provProduct.ProvisionedProductId
      };
      sessionStorage.setItem('prefix', '');
      this.s3DataService.setS3UploadData(body);
      this.router.navigate(['/s3Upload']);
    }else if(service === 's3' && action === 'Share' || action === 'Unshare') {
      body = {
        "service": service.toLowerCase(),
        "action": action.toLowerCase(),
        "account_id": provProduct.account_id,
        "projectId": this.projectId,
        "provisionedProductId": provProduct.ProvisionedProductId,
        };
        provProduct.action = action.toLowerCase();
        provProduct.service = service.toLowerCase();
        provProduct.user = this.userName;
        provProduct.projectName =  this.projectName;
        this.s3Actions(body, provProduct);
      }
      else if((service === 's3' || service === 'blob') && action === 'Explore'){
        body = {
        "service": service.toLowerCase(),
        "action": action.toLowerCase(),
        "account_id": provProduct.account_id,
        "bucketName": provProduct.Resources[0].PhysicalResourceId,
        "resources": provProduct.Resources,
        "provisionedProductName": provProduct.ProductName,
        "projectName":this.projectName,
        "productName":this.productName,
        "isShared": this.productDetails.isShared,
        "status": provProduct.Status,
        "ppId": this.ppId,
        "projectStatus": this.projectStatus,
        "projectId": this.projectId,
        "provisionedProductId": provProduct.ProvisionedProductId
        };
        this.s3Actions(body, provProduct);
    }
    else {
      body = {
        "action": action.toLowerCase(),
        "account_id": provProduct.account_id,
        "resources": provProduct.Resources,
        "projectId": this.projectId,
        "provisionedProductId": provProduct.ProvisionedProductId
      }
    }
    action = action.toLowerCase();
    if(action === 'attach volume') {
      this.apiService.getDialogBox().subscribe((data:any) => {
        if(data.status === 200){
      this.dialog.showCustomDialog({
        component: AttachVolumeComponent,
        providers: [{ provide: 'data', useValue: { productName : provProduct.ProvisionedProductName, ppId  : provProduct.ProvisionedProductId, projectName : this.projectName, projectStatus : this.projectStatus}}],                
        isModal: true,
        styles: {
          "height":"auto",
          "width":"520px",
          "border": "1px solid #FFFFFF33",
          "border-radius": "10px",
          "background-color": "#07102A",
          "padding": "0px"
        },
        clickOutsideToClose: true,
        enterTransitionDuration: 400,
        leaveTransitionDuration: 400,
      })}}, error => {
        if(error && error.status === 401){
          let errBody= error.error;
           this.toastr.error(errBody.err, '', this.globals.tosterOverride);
           this.router.navigate(['login']);
         }
      })
    }
    if(action === 'terminate'){
        if(service === 's3' && this.bucketName){
          //this is to check s3 bucket before deleting the bucket
          this.serviceAction.checkS3Bucket(this.bucketName, this.accountId).subscribe((res:any) => {
            if(res){
              if(res.Contents.length>0){
                this.s3BucketTotalrecords = res.Contents.length;
              }else if(res.CommonPrefixes.length>0){
                this.s3BucketTotalrecords = res.CommonPrefixes.length;
              }else{
                this.s3BucketTotalrecords = 0;
              }
              provProduct.s3BucketTotalSize = this.s3BucketTotalrecords;
              this.apiService.getDialogBox().subscribe((data:any) => {
                if(data.status === 200){
              this.dialog.showCustomDialog({
                component: ConfirmationDialog,
                providers: [{ provide: 'data', useValue: { deleteObj:provProduct, action: 'provisionedProduct', message: `Are you sure you want to delete ${provProduct.ProvisionedProductName} product ?` } }],
                isModal: true,
                styles: {
                  "height":"auto",
                  "width":"520px",
                  "border": "1px solid #FFFFFF33",
                  "border-radius": "10px",
                  "background-color": "#07102A",
                  "padding": "0px"
                },
                clickOutsideToClose: true,
                enterTransitionDuration: 400,
                leaveTransitionDuration: 400,
              })}}, error => {
                if(error && error.status === 401){
                  let errBody= error.error;
                   this.toastr.error(errBody.err, '', this.globals.tosterOverride);
                   this.router.navigate(['login']);
                 }
              })
            }
          }, error => {
            if(error && error.status === 401){
              let errBody= error.error;
              this.toastr.error(errBody.err, '', this.globals.tosterOverride);
              this.router.navigate(['login']);
            }else {
            this.toastr.error(error._body, 'Error', this.globals.tosterOverride);
            }
          });
        }else{
          this.apiService.getDialogBox().subscribe((data:any) => {
            if(data.status === 200){
          this.dialog.showCustomDialog({
            component: ConfirmationDialog,
            providers: [{ provide: 'data', useValue: { deleteObj:provProduct, action: 'provisionedProduct', message: `Are you sure you want to delete ${provProduct.ProvisionedProductName} product ?` } }],
            isModal: true,
            styles: {
              "height":"auto",
              "width":"520px",
              "border": "1px solid #FFFFFF33",
              "border-radius": "10px",
              "background-color": "#07102A",
              "padding": "0px"
            },
            clickOutsideToClose: true,
            enterTransitionDuration: 400,
            leaveTransitionDuration: 400,
          })}}, error => {
            if(error && error.status === 401){
              let errBody= error.error;
               this.toastr.error(errBody.err, '', this.globals.tosterOverride);
               this.router.navigate(['login']);
             }
          })
        }
      }else {
        switch (service) {
          case 'sagemaker':
           action = action.toLowerCase(); 
           if (body.action == 'open notebook' || body.action == "start" || body.action == "stop" || body.action == "reboot") {
              this.sagemakerActions(body, provProduct);
           }
           break;
          case 'nextflow':
          case 'rstudio':
          case 'cromwell':
          case 'ec2':          
          case 'vm':          
          case 'pcluster':
          case 'ec2-dcv':
          case 'ec2-igv':
          case 'ec2-secure-desktop':    
          case 'ec2-vscode':
          case 'ec2-jupyterlab':
          case 'ec2-spyder':
            if (body.action === 'remote desktop' || body.action === 'open igv') {
              if(service == "pcluster") {
                this.pclusterDcvConnect(body.instanceId,body.instanceIp);
              } else{
                this.connectDCV(body);
              }
            } 
            if (body.action === 'ssh terminal') {
              this.launchPclusterHeadnode(body.instanceIp);
                
            } 
            if (body.action === 'ssh/rdp' || body.action === 'ssh to server') {
              if(this.platformType === 'windows'){
                this.launchRDP();
              }else{
              this.launchSSH();
              }
            }
            if (body.action == 'instance type'){
              this.apiService.getDialogBox().subscribe((data:any) => {
                   if(data.status === 200){
                           this.dialog.showCustomDialog({
                             component: InstanceTypeComponent,
                             providers: [{ provide: 'data', useValue:{ppId:this.ppId,instanceId:instanceId, accountId:this.accountId}}],
                             isModal: true,
                             styles: {
                               "height": "auto",
                               "width": "35%",
                               "padding": "0px",
                               "border-radius": "10px"
                             },
                             clickOutsideToClose: true,
                             enterTransitionDuration: 400,
                             leaveTransitionDuration: 400,
                           })
                }
              }, error => {
                if (error && error.status === 401) {
                  let errBody = error.error;
                  this.toastr.error(errBody.err, '', this.globals.tosterOverride);
                  this.router.navigate(['login']);
                }
              })

            }
            if (service == "pcluster" && body.action == "stop") {
              this.apiService.getDialogBox().subscribe((data: any) => {
                if (data.status === 200) {
                  this.dialog.showCustomDialog({
                    component: ConfirmationDialog,
                    providers: [{ provide: 'data', useValue: { stopObj: provProduct, action: 'stop', body: body } }],
                    isModal: true,
                    styles: {
                      "height": "auto",
                      "width": "520px",
                      "border": "1px solid #FFFFFF33",
                      "border-radius": "10px",
                      "background-color": "#07102A",
                      "padding": "0px"
                    },
                    clickOutsideToClose: true,
                    enterTransitionDuration: 400,
                    leaveTransitionDuration: 400,
                  })
                }
              }, error => {
                if (error && error.status === 401) {
                  let errBody = error.error;
                  this.toastr.error(errBody.err, '', this.globals.tosterOverride);
                  this.router.navigate(['login']);
                }
              })
            }else if (body.action == 'start' || body.action == "stop" || body.action == "reboot") {
              this.ec2Actions(body, provProduct);
            }
            
            if(body.action === 'open link' || body.action === 'monitor pipeline') {
              if(service == "nextflow" || service == "rstudio" || service == "ec2-vscode" || service == "ec2-jupyterlab") {
                this.getApplicationURL(body, provProduct)
              } 
            }
            if(body.action === 'view outputs') { 
              if(service == "nextflow"  || service == "cromwell") {
                const validPath = this.nextflowOutputPath;
                if(validPath) {
                  const check = validPath.charAt(0);
                  // Validate the first character for the s3 bucket 
                  if((check == 's' || check == 'r') && provProduct) {
                    const pParams = provProduct.ProvisioningParameters || [];
                    const paramOutput = pParams.find(el=> el.Key == "OutputDataLocation") || "";  
                    const bucketName = paramOutput.Value;
                    this.getS3PPId(bucketName, provProduct, body);   
                  } else {
                    this.getApplicationURL(body, provProduct)
                  }
                } else {
                  this.getApplicationURL(body, provProduct)  
                } 
              }
            }
            if(body.action === 'explore'){
              this.productExplore(service, provProduct, body);
            }
            break;
          case 'wordpress':
            for(var i=0;i< provProduct.RecordOutputs.length;i++){
              if(provProduct.RecordOutputs[i]['OutputKey'] === "WebsiteURL"){
                this.websiteURL = provProduct.RecordOutputs[i]['OutputValue']
                }
              }
             this.wordPressActions(action.toLowerCase(),this.websiteURL);
             break;
          case 's3':
              if(action === 'link'){
                body = {
                  "service": service.toLowerCase(),
                  "action": action.toLowerCase(),
                  "account_id": provProduct.account_id,
                  "bucketName": provProduct.Resources[0].PhysicalResourceId,
                  "resources": provProduct.Resources,
                  "provisionedProductName": provProduct.ProductName,
                  "projectName":this.projectName,
                  "productName":this.productName,
                  "isShared": this.productDetails.isShared,
                  "status": provProduct.Status,
                  "ppId": this.ppId,
                  "projectStatus": this.projectStatus
                }
                this.s3Actions(body, provProduct)
              };
            break;
          default:
            break;
        }
        // Perform Generic actions
        this.genericActions(body.action, provProduct, service);
      }
  }

  detachVolume(volume){
    this.dialog.showCustomDialog({
      component: DetachVolumeComponent,
      providers: [{ provide: 'data', useValue: { ppId: this.ppId, productName: this.productName, volumeObj: volume, projectName : this.projectName, projectStatus : this.projectStatus } }],
      isModal: true,
      styles: {
        "height":"auto",
        "width":"520px",
        "border": "1px solid #FFFFFF33",
        "border-radius": "10px",
        "background-color": "#07102A",
        "padding": "0px"
      },
      clickOutsideToClose: true,
      enterTransitionDuration: 400,
      leaveTransitionDuration: 400,
    })
  }

  private getS3PPId(bucketName, provProduct, body) {
    if(!bucketName) {
      this.toastr.error("This action cannot be completed as some of the outputs are not yet available. Please retry the action after some time.", 'Error', this.globals.tosterOverride);
      return;
    }
    
    this.serviceAction.getS3PPId(bucketName).subscribe((res:any) => {
      const {data, status } = res
      body = {
        "service": 's3',
        "action": 'explore',
        "account_id": provProduct.account_id,
        "bucketName": bucketName,
        "resources": [{
          PhysicalResourceId:bucketName, 
          Status: "ACTIVE", 
          Type: "AWS::S3::Bucket",
          Details: [{Name: "BucketName", Value: bucketName }]}],
        "provisionedProductName": "Amazon S3",
        "projectName":this.projectName,
        "productName": "Amazon S3",
        "isShared": this.productDetails.isShared,
        "status": provProduct.Status,
        "ppId": data,
        "projectStatus": this.projectStatus
      }  
      this.s3DataService.setS3ExploreData(body); 
      this.router.navigate([`/s3/bucket/${bucketName}`]);
    }, error => {
      if(error && error.status === 401){
        let errBody= error.error;
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
      }
    });
  }

  private launchSSH() {
    const getSshIpAddress = this.productDetails;
    const getInstanceDetails = getSshIpAddress.RecordOutputs;

    for (let i = 0; i < getInstanceDetails.length; i++) {
      if (getInstanceDetails[i].OutputKey === 'InstanceIPAddress') {
        this.getInstanceIp = getInstanceDetails[i].OutputValue;
      }
    }
    if (!this.getInstanceIp) {
      // this.toastr.error(`${this.productDetails.ProvisionedProductName} - `+ "The instance-id of the product is not available. Please try after some time", 'Error', this.globals.tosterOverride)
      this.toastr.error(`${this.productDetails.ProvisionedProductName} - `+ "This action cannot be completed as some of the outputs are not yet available. Please retry the action after some time.", 'Error', this.globals.tosterOverride)
      return;
    }
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/instance-ssh/' + this.productDetails.ProvisionedProductId, {service: 'vm'}])
    );
    window.open(url, '_blank');
  }

  private launchPclusterHeadnode(instanceIp){
    if (!instanceIp) {
      this.toastr.error(`${this.productDetails.ProvisionedProductName} - `+ "This action cannot be completed as some of the outputs are not yet available. Please retry the action after some time.", 'Error', this.globals.tosterOverride)
      return;
    }
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/instance-ssh/' + this.productDetails.ProvisionedProductId])
    );
    window.open(url, '_blank');
  }

  private pclusterDcvConnect(headNodeInstanceId,headNodePublicIP) {
    this.loader = true;
    // const ClusterName = (this.productDetails.RecordOutputs.find(output => output.OutputKey == "ClusterName") || "").OutputValue || null;   
    this.serviceAction.getPclusterDcvConnectionUrl(this.ppId,headNodeInstanceId,headNodePublicIP).subscribe((res:any) => {
      if(res){
        let PclusterDcvConnectionUrl = res.connectionUrl;
        if(!PclusterDcvConnectionUrl){
          // this.toastr.error(`${this.productDetails.ProvisionedProductName} - `+ "The instance-id of the product is not available. Please try after some time", 'Error', this.globals.tosterOverride)
          this.toastr.error(`${this.productDetails.ProvisionedProductName} - ${res.message}`, 'Error', this.globals.tosterOverride)
          return;
        }        
        this.loader = false;
        window.open(PclusterDcvConnectionUrl, '_blank');
      }
    }, error => {
      this.loader = false;      
      this.toastr.error(error._body, error.error.message, this.globals.tosterOverride);
    });
  }

  private launchRDP() {
    const getSshIpAddress = this.productDetails;
    const getInstanceDetails = getSshIpAddress.RecordOutputs;

    for (let i = 0; i < getInstanceDetails.length; i++) {
      if (getInstanceDetails[i].OutputKey === 'InstanceIPAddress') {
        this.instancePublicIp = getInstanceDetails[i].OutputValue;
      }
      if (getInstanceDetails[i].OutputKey === 'InstanceId') {
        this.instanceId = getInstanceDetails[i].OutputValue;
      }
    }
    if (!this.instancePublicIp) {
      //this.toastr.error(`${this.productDetails.ProvisionedProductName} - `+ "The instance-id of the product is not available. Please try after some time", 'Error', this.globals.tosterOverride)
      this.toastr.error(`${this.productDetails.ProvisionedProductName} - `+ "This action cannot be completed as some of the outputs are not yet available. Please retry the action after some time.", 'Error', this.globals.tosterOverride)
      return;
    }
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/instance-rdp/' + this.productDetails.account_id + '/' + this.productDetails.ProvisionedProductId])
    );
    window.open(url, '_blank');
  }

  private connectDCV(body){
    let provProduct = this.productDetails;
    this.getApplicationURL(body, provProduct);
  }

  getActiveMenu(data, service) {
    const menuI = ['Upload', 'Explore', 'Share'];
    try {
      const serviceName = this.productUtil.getServiceFromTags(data);
      const getMenu = this.productUtil.getMenuForProduct(serviceName);
      if (service === "menu") {
        return getMenu.menu;
      }
      if(getMenu && 'name' in getMenu){
        return getMenu.name;
      }
    } catch (e) {
      console.log(e);
      return  menuI;
    }
  }

  sagemakerActions(body, provProduct){
    this.loader = true;
    this.serviceAction.serviceAction(body).subscribe((res:any) => {
      this.loader = false;
      switch (body.action.toLowerCase()){
        case "open notebook":
          window.open(res.AuthorizedUrl);
          break;
        case "start":
          this.showProductAction = false;
          this.toastr.success(`${provProduct.ProvisionedProductName} : start process initiated successfully`, '', this.globals.tosterOverride);
          break;
        case 'stop':
            this.showProductAction = false;
            this.toastr.success(`${provProduct.ProvisionedProductName} : stop process initiated successfully`, '', this.globals.tosterOverride);
          break;
        default:
          break
      }
    }, error => {
      this.loader = false;
      console.error(error);
      let errBody = error.error;
      if(error && error.status === 401){
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
       }else{
          this.toastr.error(`${provProduct.ProvisionedProductName} - `+errBody.error, 'Error', this.globals.tosterOverride)
       }
    });
  }

  ec2Actions(body, provProduct){
    this.loader = true;
    this.serviceAction.serviceAction(body).subscribe(res => {
      this.loader = false;
      switch (body.action.toLowerCase()) {
        case "start":
          this.showProductAction = false;
          this.toastr.success(`${provProduct.ProvisionedProductName} : start process initiated successfully`, '', this.globals.tosterOverride);
          break;
        case 'stop':
          this.showProductAction = false;
          this.toastr.success(`${provProduct.ProvisionedProductName} : stop process initiated successfully`, '', this.globals.tosterOverride);
          break;
        case 'reboot':
          this.toastr.success(`${provProduct.ProvisionedProductName} : reboot process initiated successfully`, '', this.globals.tosterOverride);
          break;
        default:
          break
      }
    }, error => {
      this.loader = false;
      let errBody = error.error;
      if(error && error.status === 401){
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
       }else{
          this.toastr.error(`${provProduct.ProvisionedProductName} - `+ errBody.error, 'Error', this.globals.tosterOverride)
       }
    });
  }

  genericActions(action, provProduct, service) {
    switch (action) {
      case "connect url":
        let connectionUrl = (provProduct.RecordOutputs.find(output => output.OutputKey == "RGConnectionURL") || "").OutputValue || null;
        if (connectionUrl) {
          window.open(connectionUrl);
        } else {
          this.toastr.error('Output RGConnectionURL not found', 'Error', this.globals.tosterOverride)
        }
        break;
      case "share":
        action = action.toLowerCase();
        let productSharingMetadata = { userLevel: this.userLevel, projectId: this.projectId, projectName: this.projectName, service: service, action: action }
        if (service != 's3') {
          this.apiService.getDialogBox().subscribe((data: any) => {
            if (data.status === 200) {
              this.dialog.showCustomDialog({
                component: ConfirmationDialog,
                providers: [{ provide: 'data', useValue: { shareObj: provProduct, productSharingMetadata: productSharingMetadata } }],
                isModal: true,
                styles: {
                  "height": "auto",
                  "width": "35%",
                  "padding": "0px",
                  "border-radius": "10px"
                },
                clickOutsideToClose: true,
                enterTransitionDuration: 400,
                leaveTransitionDuration: 400,
              })
            }
          }, error => {
            if (error && error.status === 401) {
              let errBody = error.error;
              this.toastr.error(errBody.err, '', this.globals.tosterOverride);
              this.router.navigate(['login']);
            }
          })
        }
        break;
      default:
        break;
    }
  }

  getApplicationURL(body, provProduct) {
    this.loader = true;
    body.ppId = provProduct.ProvisionedProductId;
    this.serviceAction.serviceConnect(body).subscribe((res:any) => {  
      const {status, data} = res;
      if(status == true) {
        const {applicationURL} = data;
        window.open(applicationURL, "_blank")
        this.loader = false;
      }
    }, error => {
      this.loader = false;
      let err = error.error;
      this.toastr.error(`${body.service} - `+ err.error, 'Error', this.globals.tosterOverride)
    });
  }

  wordPressActions(action,websiteURL){
        switch (action) {
          case "website-url":
            window.open(websiteURL);
            break;
          default:
            break;
        }
  }

  s3Actions(body, provProduct) {
    switch (body.action.toLowerCase()) {
        case 'share':
        this.dialog.showCustomDialog({
          component: ConfirmationDialog,
          providers: [{ provide: 'data', useValue: { s3ShareObj: provProduct, researcherList: this.researcherList, projectId: this.projectId, userLevel : this.userLevel} }],
          isModal: true,
          styles: {
            "height": "auto",
            "width": "35%",
            "padding": "0px",
            "border-radius": "10px"
          },
          clickOutsideToClose: true,
          enterTransitionDuration: 400,
          leaveTransitionDuration: 400,
        })
          break;
          case 'unshare':
          this.dialog.showCustomDialog({
            component: S3UnshareComponent,
            providers: [{ provide: 'data', useValue: { s3ShareObj: provProduct, researcherList: this.sharedResearcherList} }],
            isModal: true,
            styles: {
              "height":"auto",
              "width":"500px",
              "border": "1px solid #FFFFFF33",
              "border-radius": "10px",
              "background-color": "#07102A",
              "padding": "0px"
            },
            clickOutsideToClose: true,
            enterTransitionDuration: 400,
            leaveTransitionDuration: 400,
          })
            break;
      case 'explore':
      this.s3DataService.setS3ExploreData(body);
      this.router.navigate(['/s3/bucket/' + body.bucketName,{ service: body.service}]);
      break;

      case 'link':
      this.dialog.showCustomDialog({
        component: LinkSagemakerComponent,
        providers: [{ provide: 'data', useValue: { ppId: this.ppId, action: 'linkSagemaker', activeSageMakerNotebooks: this.activeSageMakerNotebooks, prodDetails: body } }],
        isModal: true,
        styles: {
          "height":"auto",
          "width":"500px",
          "border": "1px solid #FFFFFF33",
          "border-radius": "10px",
          "background-color": "#07102A",
          "padding": "0px"
        },
        clickOutsideToClose: true,
        enterTransitionDuration: 400,
        leaveTransitionDuration: 400,
      })
    }
  }

  getResearcherList(){
    this.researchService.getResearcherListForSharedS3(this.ppId).subscribe((res:any) => {
      this.researcherList = res.researcherList;
    }, error => {
      if(error && error.status === 401){
        let errBody= error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    });
  }

  launchInstanceSSH() {
    this.dialog.showCustomDialog({
      component: InstanceComponent,
      providers: [{provide: 'data', useValue: this.productDetails }],
      isModal: true,
      styles: {
        "height": "auto",
        "width": "40%",
        "background": "#07102A",
        "border": "1px solid #FFFFFF33",
        "border-radius": "10px",
        "padding": "0px",
        "color" : "#fff"
      },
      clickOutsideToClose: true,
      enterTransitionDuration: 400,
      leaveTransitionDuration: 400,
    });
  }

  productConnectMenu(connect) {
    if (connect === 'SSH/RDP') {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['/instance-ssh'])
      );
      window.open(url, '_blank');
    }
  }

  private serverStreamEvent() {
    this.researchService.getServerSentEvent(this.eventS).subscribe(res => {
      const resObj = res.data;
      if (!resObj) {
        return;
      }
      try {
        const {status, data, payload } = JSON.parse(resObj);
        // server give handshake to the alive connection
        if (data === 'check') {
          console.log('check');
          return;
        }
        if (data === 'provisionProduct') {
          const { ProvisionedProductId , Type, ResourceType } = payload;
          if(Type === 'ResourceStateChange' && ResourceType === 'aws.s3') {
            return;
          }
          this.showProductAction = true;
          if (this.ppId === ProvisionedProductId) {
            this.getProductDetails();
            this.refreshProduct();
          }
        }
      } catch (e) {
        console.log(e.message);
      }
    });
  }

  getEventSource(url: string): EventSource {
    return new EventSource(url, { withCredentials: true });
  }

  getS3RootFolder(){
    this.serviceAction.checkS3Bucket(this.bucketName, this.accountId).subscribe((res:any) => {
      if(res){
        if(res.Contents.length>0){
          this.s3BucketTotalrecords = res.Contents.length;
        }else if(res.CommonPrefixes.length>0){
          this.s3BucketTotalrecords = res.CommonPrefixes.length;
        }else{
          this.s3BucketTotalrecords = 0;
        }
      }
    }, error => {
      //this.toastr.error(error._body, 'Error', this.globals.tosterOverride);
    });
  }

  getActiveSagemakerNotebooks(){
    this.researchService.getActiveSagemakerNotebooks(this.projectId, this.bucketName).subscribe((res:any) => {
      this.loader = false;
      if(res){
        this.activeSageMakerTotalrecords = res.length;
        this.activeSageMakerNotebooks = res;
      }
    }, error => {
      console.error(error);
      let err = error.error;
    });
  }

  unlinkResource(notebook){
    this.dialog.showCustomDialog({
      component: UnlinkResourceComponent,
      providers: [{ provide: 'data', useValue: { ppId: this.ppId, action: 'unlinkSagemaker', projectName: this.projectName, productName: this.productName, projectStatus: this.projectStatus, notebookObject: notebook } }],
      isModal: true,
      styles: {
        "height":"auto",
        "width":"520px",
        "border": "1px solid #FFFFFF33",
        "border-radius": "10px",
        "background-color": "#07102A",
        "padding": "0px"
      },
      clickOutsideToClose: true,
      enterTransitionDuration: 400,
      leaveTransitionDuration: 400,
    })
  }

  checkEgressStoreSubmission(){
    this.researchService.getProvisionedProductDetails(this.ppId).subscribe((res:any) => {
      this.isAbleToSubmitEgressRequest = res?.isAbleToSubmitEgressRequest || false;
    })
  }

  eventTabAction(e: TabDirective) {
    if (e.heading === 'Product Details') {
      this.showAction = true;
    } else {
      this.showAction = false;
    }
    if (e.heading === 'Egress Store'){
      if(this.isEgressStoreEnabled == 'true'){
        this.egressStoreFiles = [];
        this.paginationToken = "";
        this.checkEgressStoreSubmission();
        this.getEgressStoreFiles();
      }
    }
  }

  dataBindingAvailableProduct() {
    try {
      this.productDetails['imageUrl'] = (this.productDetails['ProvisionedProductType'] === 'ARM_TEMPLATE')? '/assets/images/azure_icon/azure.png' : '../../assets/images/aws_icon/aws.png';;
      this.serviceName = this.productUtil.getServiceFromTags(this.productDetails.Tags);
      let getImgLinkObj = this.productUtil.getImageForProduct(this.serviceName);
      if (getImgLinkObj) {
        this.productDetails['imageUrl'] = getImgLinkObj['url'];
      }
      if(this.listOfLinkedProducts){
        for(var i=0; i< this.listOfLinkedProducts.length ; i++){
          const linkedProductName = this.listOfLinkedProducts[i].productType.toLowerCase();
          this.listOfLinkedProducts[i].imageUrl = '/assets/images/aws_icon/aws.png';
          getImgLinkObj = this.productUtil.getImageForProduct(linkedProductName);
          if (getImgLinkObj) this.listOfLinkedProducts[i]['imageUrl'] = getImgLinkObj['url'];
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  logStatusMessage(data) {
    let formData = data;
    if (!data) {
      formData = 'Nil';
    }
    return formData;
  }

  onCopySuccess(){
    this.toastr.success(`Copied Bucket Name successfully`, '', this.globals.tosterOverride);
  }

  onCopyError(){
    this.toastr.error('Linked product not found', 'Copy Error', this.globals.tosterOverride)
  }

  navigateToProject(){
    const projectName = encodeURIComponent(this.projectName);
    sessionStorage.setItem('projectName', this.projectName);
    this.router.navigate(['/catalog/' + projectName + '/' + this.accountId ]);
  }

  productExplore(service, provProduct, body) {
    const pParams = provProduct.ProvisioningParameters || [];
    const paramOutput = pParams.find(el=> el.Key == "S3Mounts") || ""; 
    const getS3MountArr = paramOutput.Value || [];
    const getS3MountObj = getS3MountArr.find(el=> el.id == "ProjectStorage") || ""; 
    this.getS3PPId(getS3MountObj.bucket, provProduct, body);
  }

  getEgressStoreFiles(){
    this.egressStoreLoader = true;
    let reqBody = {
      paginationToken : this.paginationToken,
      pageSize: this.pageSize
    }
    this.researchService.getEgressStoreFiles(this.ppId, reqBody).subscribe((res:any) => {
      if(res && res?.objectList){
        this.egressStoreFiles = [...this.egressStoreFiles, ...res?.objectList];
        this.egressStoreLoader = false;
        if('paginationToken' in res && res?.paginationToken !== null){
          this.paginationToken = res?.paginationToken;
          this.showLoadMore = true;
        }else{
          this.showLoadMore = false
        }
      }
    }, error => {
      this.egressStoreLoader = false;
      let errBody = error.error;
      if(error && error.status === 401){
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
       }else{
          this.toastr.error(errBody.message, 'Egress Store', this.globals.tosterOverride)
       }
    });
  }

  submitEgressRequest(){
    this.loader = true;
    let reqBody = {
      account_id : this.accountId,
      egressStorePrefix: this.egressStorePrefix,
      projectName: this.projectName,
      projectId: this.projectId,
      ppId: this.ppId
    }
    this.researchService.sendEgressRequest(reqBody).subscribe((res:any) => {
      this.loader = false;
      this.toastr.success(`Egress request submitted successfully`, '', this.globals.tosterOverride)
    }, error => {
      this.loader = false;
      this.clicked = false;
      let errBody = error.error;
      if(error && error.status === 401){
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
       }else{
          this.toastr.error(errBody.message, 'Error in egress request', this.globals.tosterOverride);
       }
    });
  }

  getEtag(etag){
    return etag.replace(/"/g, '')
  }

  getProductLatestStatus(){
    this.loader = true;
    let reqBody = {
      ppId: this.ppId
    }
    this.researchService.updateProductStatus(reqBody).subscribe((res:any) => {
      this.loader = false;
      this.toastr.success(`Product Status will be updated shortly. Please wait for sometime.`, '', this.globals.tosterOverride)
    }, error => {
      this.loader = false;
      let errBody = error.error;
      if(error && error.status === 401){
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
       }else{
          this.toastr.error(errBody.message, 'Error in get product latest status', this.globals.tosterOverride);
       }
    });
  }

  updateProductStatus(){
    let terminatedStates = ['Terminated', 'Failed'];
    let ec2TypeProducts = ['ec2', 'rstudio', 'nextflow', 'cromwell', 'ec2-dcv', 'ec2-igv', 'ec2-secure-desktop', 'ec2-vscode', 'ec2-jupyterlab', 'ec2-spyder'];
    let productServiceName = this.getActiveMenu(this.productDetails?.Tags, "");
    //Only 1 time product refresh would happen. Again User should do page refresh to try this action again as per requirement. 
    if(!this.isRefreshDisabled){
      if( ! ec2TypeProducts.includes(productServiceName) || terminatedStates.includes(this.status)){
        this.refreshProduct();
      }else{
        //Only for EC2 related products we have this update product status option. TODO : Include all products
        this.getProductLatestStatus();
      }
    }
  }
  
}