import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ResearchService} from "../../_services/research.service";
import { Router } from "@angular/router";
import { MdlDialogService } from "@angular-mdl/core";
import { DashboardService } from "../../_services/dashboard.service";
import {Globals} from "../../globals";

@Component({
  selector: 'app-principal',
  templateUrl: './budget-tracking.component.html',
  // existing catalog.component.css used for Budget cards(allotted, consumed, available)
  styleUrls: ['../../catalog/catalog.component.css', './budget-tracking.component.css', '../../app.component.css'],
  providers: [Globals]
})
export class BudgetTrackingComponent implements OnInit {
  projects = [];
  totalBudgetAllotted: any = 0;
  totalBudgetConsumed: any = 0;
  totalCurrentMonthCost: any = 0;
  currencySymbol: string = '$';
  loader: boolean = true;
  consumedBudgetTextColor = {
      passed: '#73E2CF',
      threshold: 'red',
      pauseThreshold: '#F5AF19'
  }
  constructor(private dialog: MdlDialogService, private toastr: ToastrService,
              private router: Router, private researchService: ResearchService,
              private apiService: DashboardService,
              private globals: Globals
  ) {}

  ngOnInit() {
    this.apiService.checkIfPI();
    this.loader = true;
    this.getProjects();
  }

  getProjects() {
    this.researchService.getPrincipalProjects('1', '9999').subscribe((data:any) => {
      this.projects = data.docs;
      this.loader = false;
      // after loading all projects data, get budget cards data(total-> allotted, consumed, available)
      this.getBudgetCardsData();
    }, error => {
      this.loader = false;
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }else if(error && error.status === 403){
          const err = error.error;
          this.toastr.error(err.error, 'Error', this.globals.tosterOverride);
          this.router.navigate(['pageNotFound']);
      }
    });
  }

  // totalBudgetAllotted = total of each project's budget
  // totalBudgetConsumed = total of each project's projectBudgetConsumed
  // totalBudgetAvailable = totalBudgetAllotted - totalBudgetConsumed
  getBudgetCardsData(){
    let allotted : any = 0;
    let consumed : any = 0;
    let currentMonthCost : any = 0;
    this.projects.forEach((project) => {
      // Safe navigation on all keys
      if(project.budget){
        allotted += project.budget
      }
      let budgetConsumed = project.budgetConsumed;
      if (budgetConsumed) {
        let projectBudgetConsumed = budgetConsumed['projectBudgetConsumed']
        if (projectBudgetConsumed) {
          consumed += projectBudgetConsumed;
        }
      }
      let today = new Date();
      let currentMonth = today.getMonth() + 1;
      let currentYear = today.getFullYear();
      if (budgetConsumed.monthlyProjectBudgetConsumed.length > 0) {
        let currentMonthProjectCost = budgetConsumed.monthlyProjectBudgetConsumed.filter((monthCostByProject) => {
          return currentMonth == monthCostByProject.month && currentYear == monthCostByProject.year;
        });
        if (currentMonthProjectCost.length > 0) {
          currentMonthCost += currentMonthProjectCost[0].cost;
        }
      }
      this.totalBudgetAllotted = parseFloat(allotted).toFixed(2); 
      this.totalBudgetConsumed = parseFloat(consumed).toFixed(2);
      this.totalCurrentMonthCost = parseFloat(currentMonthCost).toFixed(2);
    });
  }

  // Project related info --------
  projectConsumedBudget(project){
    if(project.budgetConsumed){
      if('budgetConsumedPercentage' in project){
        return `${parseFloat(project.budgetConsumed['projectBudgetConsumed']).toFixed(2)} ( ${project.budgetConsumedPercentage} % )`;
      }
      return `${parseFloat(project.budgetConsumed['projectBudgetConsumed']).toFixed(2)}`;
    }else{
      return 'NA'
    }
  }

  projectAvailableBudget(project){
    let value;
    if(project.budgetConsumed){
      value = project.budget - project.budgetConsumed['projectBudgetConsumed']
    }else{
      value = project.budget
    }
    return parseFloat(value).toFixed(2);
  }

  projectMonthBudget(project){
    let value: any = 0;
    if(project.budgetConsumed){
      let today = new Date();
      let currentMonth = today.getMonth() + 1;
      let currentYear = today.getFullYear();
      if (project.budgetConsumed.monthlyProjectBudgetConsumed.length > 0) {
        let currentMonthProjectCost = project.budgetConsumed.monthlyProjectBudgetConsumed.filter((monthCostByProject) => {
          return currentMonth == monthCostByProject.month && currentYear == monthCostByProject.year;
        });
        if (currentMonthProjectCost.length > 0) {
          value = currentMonthProjectCost[0].cost;
        }
      }
    }
    return parseFloat(value).toFixed(2);
  }

  projectAvailableBudgetPercentage(project){
     if(project.budgetConsumed){
       let available = project.budget - project.budgetConsumed['projectBudgetConsumed']
       return ((available/project.budget)*100).toFixed(0)
     }else {
       return 100;
     }
  }

  getColor(project){
    if(project.budgetConsumedPercentage >= project.projectStopThresholdPercentage){
      return this.consumedBudgetTextColor.threshold;
    }if(project.budgetConsumedPercentage >= project.projectPauseThresholdPercentage){
      return this.consumedBudgetTextColor.pauseThreshold;
    }else{
      return this.consumedBudgetTextColor.passed;
    }
  }

  projectConsumedBudgetPercentage(project){
    if(project.budgetConsumed){
      let consumed = project.budgetConsumed['projectBudgetConsumed']
      return ((consumed/project.budget)*100).toFixed(0)
    }else {
      return 0;
    }
 }

 getMarkerWidth(project){
   let markerWidth = 80
    if('projectStopThresholdPercentage' in project && 'budgetConsumedPercentage' in project){
       markerWidth = project.thresholdPercentage - project.budgetConsumedPercentage;
    }
    return markerWidth+'%';
 }

  // GET /principal/project/:id/details
  openProjectDetails(projectId) {
    this.router.navigate(['/principal/project/'+ projectId +'/details']);
  }

  exportCSV(){
    this.researchService.exportPIBudgetAsCSV().subscribe((data:any) => {
      const csvData = document.createElement("a");
      document.body.appendChild(csvData);
      let blob = new Blob([data], {type: "data:text/csv;charset=utf-8,"}),
      url = window.URL.createObjectURL(blob);
      csvData.href = url;
      csvData.download = 'BudgetOverview.csv';
      csvData.click();
      window.URL.revokeObjectURL(url);
    },
    error => {
      if(error && error.status === 401){
        let errBody = JSON.parse(error.error);
        console.log(errBody.err);
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    })
  }

  getRoundOffBudget(data){
    return parseFloat(data).toFixed(2)
  }

}
