import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {Globals} from "../../globals";
import { ResearchService } from "../../_services/research.service";
import { MdlDialogReference } from '@angular-mdl/core';

@Component({
  selector: 'app-add-budget',
  templateUrl: './add-budget.component.html',
  styleUrls: ['./add-budget.component.css'],
  providers: [Globals]
})
export class AddBudgetComponent implements OnInit, AfterViewInit {
  @ViewChild('projectActionWindow') projectActionWindow: ElementRef;
  constructor(
    @Inject('data') public data: any,
    public dialogRef: MdlDialogReference,
    private researchService: ResearchService,
    private globals: Globals,
    private toastr: ToastrService,
    private router: Router
  ) { }
  projectName;
  addBudgetValue;
  projectId;
  budgetValueMaxCount;
  clicked = true;
  
  ngOnInit() {
    if (this.data != undefined) {
      this.projectName = this.data.projectName;
      this.projectId = this.data.projectId;
      this.budgetValueMaxCount = 50000;
      this.getConfigBudgetValue();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => this.projectActionWindow.nativeElement.focus(), 0);
  }

  cancel() {
    this.dialogRef.hide();
  }

  getConfigBudgetValue(){
    this.researchService.getConfigData('projectCostControl').subscribe((data:any) => {
      try{
        if(data.value){
          this.budgetValueMaxCount  = data.value.budgetMaxAllowedValue;
        }
      }
      catch(e){
          // console.log("Error in getting rg setup videos");
      }
    });
  }
  submitBudget() {
    let projectData = { projectId : this.projectId, addBudgetValue: parseFloat(this.addBudgetValue)};
    let t = new Date().getTime();
    this.researchService.submitBudget(projectData).subscribe( response => {
      this.dialogRef.hide();
      this.router.navigate([`/project-details/${this.projectId}`],{ queryParams: { showTab:'projectDetails', updatedData: t}});
      this.toastr.success('The budget has been updated successfully', '', this.globals.tosterOverride);
    }, error => {
      this.dialogRef.hide();
      let errorMes = error.error;
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
        }else if (errorMes && (errorMes.message)) {
        this.toastr.error('', errorMes.message, { tapToDismiss: false, timeOut: 0, extendedTimeOut: 0, closeButton : true });
      }else{
        this.toastr.error('Error in add budget value to the Project', '', this.globals.tosterOverride);
      }
    });  
  }

  checkBudgetValue(){
    let budValue = parseFloat(this.addBudgetValue);
    let result = Number.isInteger(budValue);    
    if(budValue >= 1 && budValue <= this.budgetValueMaxCount && result == true){
      this.clicked = false;
      const el = document.getElementById("budgetValueId");
      el.style.borderLeft = '5px solid #42A948';
    }else{
      this.clicked = true;
      const el = document.getElementById("budgetValueId");
      el.style.borderLeft = '5px solid #a94442';   
    }
  }
}

