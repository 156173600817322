import { Component, Inject, NgModule, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { MdlDialogReference } from '@angular-mdl/core';
import { DashboardService } from '../_services/dashboard.service';
import { ResearchService } from "../_services/research.service";
import { ServiceActionsService} from "../_services/service-actions.service";
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormBuilder, FormGroup } from "@angular/forms";
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Globals } from "../globals";
import * as $ from 'jquery';

/*@NgModule({
  imports: [BrowserAnimationsModule, ToasterModule],
  declarations: [],
  providers: [],
  bootstrap: []
})*/

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css'],
  providers: [DashboardService, Globals]
})
export class ConfirmationDialog {
  @ViewChild('productStopTerminationWindow') productStopTerminationWindow: ElementRef;

  form: FormGroup;
  progress: number = 0;
  showSpinner = false;
  showConfirmDeleteBox: boolean = false;
  showConfirmDeactivateBox: boolean = true;
  body: any;
  stopData: any;

  constructor(
    private globals: Globals,
    public fb: FormBuilder,
    public dialogRef: MdlDialogReference,
    public dialogConfirm: DashboardService,
    private router: Router,
    private researchService: ResearchService,
    private location: Location,
    private serviceAction: ServiceActionsService,
    private toastr: ToastrService,
    @Inject('data') public data: any
  ) {
    this.form = this.fb.group({
      name: [''],
      avatar: [null]
    })
  }

  public confirmMessage: string;
  public action: string;
  public id: string;
  public jsonData: object;
  public groupName: string;
  groupId;
  workflowId;
  deleteType;
  groupEmails;
  secondaryContacts;
  selectedRow: any = [];
  selectedRowData: any = [];
  deleteObj: Object = {};
  serviceType:any;
  s3Bucket:any;
  imageObj: File;
  imageUrl: string;
  accId: any;
  shloader: boolean = false;
  fileUpload: boolean = false;
  s3ShareData: any;
  shareData: any;
  researcherList: any = [];
  userType : any;
  productSharingMetadataObj: object = {};
  researcher: string = ''
  accountName;
  ProvisionedProductName:any;
  s3BucketTotalSize;
  public isDisabled = true;
  listOfLinkedProducts;
  showProductAction = true;
  confirmBox:boolean = false;
  settingsDeleteOption = {type: 'delink'};
  projectId;
  idofProject;
  studiesAssigned;
  ngOnInit() {
    this.confirmMessage = this.data.message;
    this.accountName = this.data.accountName;
    this.action = this.data.action;
    this.id = this.data.id;
    this.jsonData = this.data.jsonData;
    this.groupName = this.data.groupName;
    this.groupId = this.data.groupId;
    this.deleteType = this.data.deleteType;
    this.groupEmails = this.data.groupEmails;
    this.workflowId = this.data.workflowId;
    if(this.accountName != undefined || this.id != undefined){
    this.researchService.assignedStudies(this.accountName, this.id, 'internal' ).subscribe((studyRes) => { 
      this.studiesAssigned = studyRes;
    })
  }
    this.deleteObj = {
      ProvisionedProductId: 'deleteObj' in this.data ? this.data.deleteObj.ProvisionedProductId: '',
      account_id: 'deleteObj' in this.data ? this.data.deleteObj.account_id : ''
    };
    // this.getContactDetailsById(this.groupId);
    if(this.data.hasOwnProperty('s3Obj')){
      this.accId = this.data.s3Obj.account_id;
      this.serviceType = 's3'
      for(var i=0;i< this.data.s3Obj['ProvisioningParameters'].length;i++){
        if(this.data.s3Obj['ProvisioningParameters'][i].Key == 'BucketName'){
          this.s3Bucket = this.data.s3Obj['ProvisioningParameters'][i].Value;
        }
      }
    }
    if(this.data.hasOwnProperty('s3ShareObj')){
      this.s3ShareData = this.data.s3ShareObj;
      this.researcherList = this.data.researcherList;
      this.projectId = this.data.projectId;
      if (this.data.userLevel == "0"){
        this.userType = 'researcher'
      }else if(this.data.userLevel == "1"){
        this.userType = 'principle investigator'
        console.log('userType:', this.userType,)
      }else if(this.data.userLevel == "2"){
        this.userType = 'admin'
      }
    }
    if(this.data.hasOwnProperty('shareObj')){
      this.shareData = this.data.shareObj;
      this.idofProject = this.data.projectId;
      this.productSharingMetadataObj = this.data.productSharingMetadata;
    }
    if(this.data.hasOwnProperty('deleteObj')){
      this.ProvisionedProductName = this.data.deleteObj.ProvisionedProductName;
      this.listOfLinkedProducts = this.data.deleteObj.listOfLinkedProducts;
      if(this.data.deleteObj.s3BucketTotalSize){
        this.s3BucketTotalSize = this.data.deleteObj.s3BucketTotalSize;
      }
    }

    if(this.data.hasOwnProperty('stopObj')){
      this.serviceType = 'pcluster'
      this.stopData = this.data.stopObj
      this.ProvisionedProductName = this.data.stopObj.ProvisionedProductName;
      this.body = this.data.body
    }
  }

  ngAfterViewInit() {
    setTimeout(() => this.productStopTerminationWindow.nativeElement.focus(), 0);
  }

  cancelSettingDelete(){
    //this.showConfirmDeactivateBox = this.showConfirmDeleteBox = false;
    this.dialogRef.hide();
  }

  confirmDeleteBox(){
    this.confirmBox = !this.confirmBox;
  }

  cancel(): void {
    this.dialogRef.hide();
    this.showConfirmDeactivateBox = this.showConfirmDeleteBox = false;
    this.settingsDeleteOption.type = '';
  }

  deleteSettings(){
    if(this.settingsDeleteOption.type === 'delete'){
      this.showConfirmDeleteBox = true;
      this.showConfirmDeactivateBox = false;
    }
    if(this.settingsDeleteOption.type === 'delink'){
      this.showConfirmDeactivateBox = true;
      this.showConfirmDeleteBox = false;
    }
  }
  confirmRequest() {
    this.isDisabled = true;
    if (this.action === 'setting') {
      this.showSpinner = true;
      this.dialogConfirm.deleteSettingById(this.id, this.settingsDeleteOption.type).subscribe(
        (data:any) => {
          this.dialogRef.hide();
          this.showSpinner = false;
          // this.router.navigate(['setting']);
          let d = new Date();
          let t = d.getTime();
          this.router.navigate(['setting/update/' + t]);
          this.settingsDeleteOption.type = '';
          this.showConfirmDeactivateBox = this.showConfirmDeleteBox = false;
          // location.reload();
          let message = `The process to de-link has started successfully, it will take around 5 minutes`;
          if ('roleArn' in data) {
            if (('crossAccountRole' in data.roleArn) && ('crossAccountRoleArn' in data.roleArn.crossAccountRole)) {
              const roleName = data.roleArn.crossAccountRole.crossAccountRoleArn.split('/')[1];
              message += `Please remove the role ${roleName} from your AWS account`;
            }
          }
          this.toastr.success( message, 'Settings', this.globals.tosterOverride);
        }, error => {
          this.showSpinner = false;
          this.dialogRef.hide();
          if(error && error.status === 401){
            let errBody = error.error;
             this.toastr.error(errBody.err, '', this.globals.tosterOverride);
             this.router.navigate(['login']);
           }else{
          let errorMes = error.error;
          this.toastr.error( errorMes.message, 'Cannot delete settings', this.globals.tosterOverride);
          let d = new Date();
          let t = d.getTime();
          this.settingsDeleteOption.type = '';
          this.showConfirmDeactivateBox = this.showConfirmDeleteBox = false;
          this.router.navigate(['setting/ ' + t]);
           }
          });
    } else if(this.action === 'provisionedProduct'){
      if(this.s3BucketTotalSize > 0){
        this.dialogRef.hide();
        this.toastr.error('The bucket is not empty. Please delete all contents from the bucket and try again', 'Product Delete')
      }else if(this.listOfLinkedProducts.length > 0){
        this.dialogRef.hide();
        this.toastr.error(`This product is shared with other researchers and is linked to a ${this.listOfLinkedProducts[0].productType} called "${this.listOfLinkedProducts[0].name}". Please unlink this product from all other products and studies then try again.`, 'Product Delete')
      }else{
        this.researchService.deleteProvisionedProducts(this.deleteObj).subscribe(
          (data:any) => {
            this.dialogRef.hide();
            this.toastr.success('Product Delete', data.message);
          },
          error => {
            let errorMes = error.error.message;
            this.dialogRef.hide();
            if(error && error.status === 401){
              let errBody= error.error;
               this.toastr.error(errBody.err, '', this.globals.tosterOverride);
               this.router.navigate(['login']);
            }else  if(errorMes.includes('not authorized')){
              this.toastr.error('User is not authorized to perform terminate action. Please contact your administrator.', 'Product Delete', this.globals.tosterOverride);
            }else{
              this.toastr.error(errorMes, 'Product Delete', this.globals.tosterOverride);
            }
          }
        )
      }
    }else if(this.action === 'studyAccount'){
      this.showSpinner = true;
      const data = {
        id: this.id
      }
      this.researchService.deleteStudyAccount(data).subscribe(
        (data:any) => {
          this.dialogRef.hide();
          this.showSpinner = false;
          this.router.navigate(['/setting'] ,{
            queryParams: { selectedTab: 'studyAccount' }
          });     
          this.toastr.success(`Study account delete process intiated`, '',  this.globals.tosterOverride);
        }, error => {
          this.showSpinner = false;
          this.dialogRef.hide();
          if(error && error.status === 401){
            let errBody = error.error;
             this.toastr.error(errBody.err, '', this.globals.tosterOverride);
             this.router.navigate(['login']);
           }else{
          let errorMes = error.error;
          this.toastr.error( errorMes.message, 'Cannot delete study account', this.globals.tosterOverride);
          this.router.navigate(['/setting'] ,{
            queryParams: { selectedTab: 'studyAccount' }
          });
           }
          });
    }
  }

  onImagePicked(event: Event): void {
    const FILE = (event.target as HTMLInputElement).files[0];
    this.imageObj = FILE;
    this.form.patchValue({
      avatar: FILE
    });
    this.form.get('avatar').updateValueAndValidity()
  }

  onImageUpload() {
    this.progress = 0;
    const imageForm = new FormData();
    imageForm.append('account_id', this.accId);
    imageForm.append('bucketName', this.s3Bucket);
    imageForm.append('image', this.imageObj);
    this.serviceAction.fileUpload(imageForm, this.accId, this.s3Bucket, this.s3ShareData.projectName).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          //console.log('Request has been made!');
          break;
        case HttpEventType.ResponseHeader:
          //console.log('Response header has been received!');
          break;
        case HttpEventType.UploadProgress:
          this.progress = Math.round(event.loaded / event.total * 100);
          //console.log(`Uploaded! ${this.progress}%`);
          break;
        case HttpEventType.Response:
          //console.log('File uploaded successfully!', event.body);
          this.fileUpload = true;
          setTimeout(() => {
            this.progress = 0;
          }, 200);
      }
    }, error => {
      if(error && error.status === 401){
      let errBody = error.error;
      this.toastr.error(errBody.err, '', this.globals.tosterOverride);
      this.router.navigate(['login']);
      }
    });
  }

  shareS3Product(){
    this.researchService.updateProvisionProduct({'_id':this.s3ShareData['_id']}).subscribe(res =>{
    })
    let s3Data = {}
    s3Data['owner'] = this.s3ShareData.user;
    s3Data['user'] = this.researcher;
    s3Data['isShared'] = true;
    s3Data['Status'] = "AVAILABLE";
    s3Data['provisionedProductId'] = this.s3ShareData.ProvisionedProductId;
    s3Data['account_id'] = this.s3ShareData.account_id;
    s3Data['action'] = 'share';
    s3Data['service'] = 'S3';
    s3Data['projectId'] = this.projectId;
    this.serviceAction.serviceAction(s3Data).subscribe(res => {
      switch (this.s3ShareData.action.toLowerCase()) {
        case "share":
          this.toastr.success(`${this.s3ShareData.ProvisionedProductName} : share process initiated successfully`, '',  this.globals.tosterOverride);
          this.router.navigate(['/product/' + this.s3ShareData.projectName + '/' +
          this.s3ShareData.ProvisionedProductName + '/' + 'linkResource'+ '/' +'Success' , { ppId: this.s3ShareData.ProvisionedProductId }],{ queryParams: { projectStatus: this.s3ShareData.Status}});
          break;
        default:
          break
      }
      this.dialogRef.hide();
    }, error => {
      this.dialogRef.hide();
      console.error(error);
      let errBody = error.error;
      if(error && error.status === 401){
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
       }else{
          this.toastr.error('Error : ',errBody.error);
       }
    });
  }
  
  shareProvisionedProduct(productSharingMetadataObj){
    let data = {}
    data['_id'] = this.shareData._id
    data['owner'] = this.shareData.user;
    data['isShared'] = true;
    data['Status'] = "AVAILABLE";
    data['provisionedProductId'] = this.shareData.ProvisionedProductId;
    data['account_id'] = this.shareData.account_id;
    data['action'] = 'share';
    data['service'] = productSharingMetadataObj.service;
    data['projectId'] = productSharingMetadataObj.projectId;
    data['projectName'] = productSharingMetadataObj.projectName;
    this.serviceAction.serviceAction(data).subscribe(res => {
     switch (productSharingMetadataObj.action.toLowerCase()) {
      case "share":
        this.toastr.success(`${this.shareData.ProvisionedProductName} : share process initiated successfully`, '',  this.globals.tosterOverride);
        this.router.navigate(['/product/' + this.shareData.projectName + '/' +
        this.shareData.ProvisionedProductName + '/' + 'linkResource'+ '/' +'Success' , { ppId: this.shareData.ProvisionedProductId }],{ queryParams: { projectStatus: this.shareData.Status}});
        break;
      default:
        break
    }
      this.dialogRef.hide();
    }, error => {
      this.dialogRef.hide();
      console.error(error);
      let errBody = error.error;
      if(error && error.status === 401){
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
       }else{
          this.toastr.error(`Error in sharing ${this.shareData.ProvisionedProductName} product:`, errBody.error);
       }
    });
  }

  stopProduct() {
    if (this.action === "stop")
      this.showSpinner = true;
    this.serviceAction.serviceAction(this.body).subscribe(
      res => {
        this.dialogRef.hide();
        this.showSpinner = false;
        this.showProductAction = false;
        this.toastr.success(`${this.stopData.ProvisionedProductName} : stop process initiated successfully`, '', this.globals.tosterOverride);
      },
      error => {
        this.dialogRef.hide();
        console.error(error);
        let errBody = error.error;
        if (error && error.status === 401) {
          this.toastr.error(errBody.err, '', this.globals.tosterOverride);
          this.router.navigate(['login']);
        } else {
          this.toastr.error(` ${this.stopData.ProvisionedProductName}- ` + errBody.error, 'Error', this.globals.tosterOverride)
        }
      }
    );
  }
}  
