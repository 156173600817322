import {MatTabsModule} from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MdlModule } from '@angular-mdl/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentModule } from 'angular2-moment';
import { ToastrModule } from 'ngx-toastr';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ClipboardModule } from 'ngx-clipboard';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuardService } from './guard/auth-guard.service';
import { DataService } from './_services/data.service';
import { DashboardService } from './_services/dashboard.service';
import { PreviousRouteService } from './_services/url.service';
import { AppComponent } from './app.component';
import { SettingsComponent } from './settings/settings.component';
import { ConfigComponent } from './settings/config.component'
import { LoginComponent } from './login/login.component';
import { ConfirmationDialog } from './dialog/dialog.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoaderComponent } from './dashboard/loader.component'
import { SocketService } from './_services/socket.service';
import { RegisterComponent } from './register/register.component';
import { OnCreate } from './_directives/onCreate';
import { IpconvertPipe } from './ipconvert.pipe';
import { ReversePipe } from './reverse.pipe';
import { CatalogComponent } from './catalog/catalog.component';
import { ResearcherComponent } from './researcher/researcher.component';
import { OrderComponent } from './order/order.component';
import { ResearchService } from "./_services/research.service";
import { ActiveProductComponent } from './active-product/active-product.component';
import { AdminComponent } from './admin/admin.component';
import { AdminBudgetTrackingComponent } from './admin/budget-tracking/budget-tracking.component';
import { AdminBudgetDetailsComponent } from './admin/budget-tracking/budget-details.component';
import { PrincipalComponent } from './principal/principal.component';
import { BudgetTrackingComponent } from './principal/budget/budget-tracking.component';
import { BudgetDetailsComponent } from './principal/budget/budget-details.component';
import { OrganizationService } from "./_services/organization.service";
import { OrganizationComponent } from './admin/organization/organization.component';
import { InvestigatorComponent } from './principal/investigator/investigator.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { InstanceComponent } from './catalog/instance/instance.component';
import { ServiceActionsService } from "./_services/service-actions.service";
import { HttpClientModule } from '@angular/common/http';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { S3FileUploadComponent } from './dialog/s3-file-upload/s3-file-upload.component';
import { ProjectActionComponent } from './principal/project-action/project-action.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ProductsPipe } from './principal/budget/pipe/products.pipe';
import { InstanceSshComponent } from './instance-ssh/instance-ssh.component';
import { S3ExploreComponent } from './s3-explore/s3-explore.component';
import { S3DataService } from './_services/s3-data.service';
import { BudgetProductComponent } from './principal/budget/budget-product/budget-product.component';
import { S3FileDeleteComponent } from './dialog/s3-file-delete/s3-file-delete.component';
import { S3EmptyFolderComponent } from './dialog/s3-empty-folder/s3-empty-folder.component';
import { BudgetResearcherComponent } from './admin/budget-tracking/budget-researcher/budget-researcher.component';
import { AdminBudgetProductComponent } from './admin/budget-tracking/budget-product/budget-product.component';
import { LinkSagemakerComponent } from './dialog/link-resource/link-resource.component';
import { SuccessMessageComponent } from './dialog/success-message/success-message.component';
import { UnlinkResourceComponent } from './dialog/unlink-resource/unlink-resource.component';
import { KeypairComponent } from './keypair/keypair.component';
import { CreateKeypairComponent } from './keypair/create-keypair/create-keypair.component';
import { ResendVerificationComponent } from './resend-verification/resend-verification.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { StudiesComponent } from './studies/studies.component';
import { StudyDetailsComponent } from './study-details/study-details.component';
import { BudgetManagementComponent } from './researcher/budget/budget-management/budget-management.component';
import { BudgetProjectComponent } from './researcher/budget/budget-project/budget-project.component';
import { LinkStudyComponent } from './dialog/link-study/link-study.component';
import { ErrorMessageComponent } from './dialog/error-message/error-message.component';
import { ShareStudyComponent } from './dialog/share-study/share-study.component';
import { ProductUtilService } from "./_services/productUtil.service";
import { studyS3ExploreComponent } from './study-s3-explore/study-s3-explore.component';
import { AuditTrailComponent } from './audit-trail/audit-trail.component';
import { AuditDetailsComponent } from './audit-trail/audit-details/audit-details.component';
import { CatalogManagementComponent } from './catalog-management/catalog-management.component';
import { AssignCatalogComponent } from './catalog-management/assign-catalog/assign-catalog.component';
import { CatalogManagementPrincipalComponent } from './catalog-management/catalog-management-principal/catalog-management-principal.component';
import { UserComponent } from './user/user.component';
import { AddUserComponent } from './dialog/add-user/add-user.component';
import { AssignOrganisationComponent } from './dialog/assign-organisation/assign-organisation.component';
import { EnableUserComponent } from './dialog/enable-user/enable-user.component';
import { S3UnshareComponent } from './dialog/s3-unshare/s3-unshare.component';
import { WelcomePageComponent } from './welcome-page/welcome-page.component';
import { LogoutComponent } from './logout/logout.component';
import { DefaultComponent } from './default/default.component';
import { InstanceRdpComponent } from './instance-rdp/instance-rdp.component';
import { RecaptchaModule } from "ng-recaptcha";
import { AccountNotFoundComponent } from './principal/account-not-found/account-not-found.component';
import { AddAccountComponent } from './add-account/add-account.component';
import { AddProjectComponent } from './principal/add-project/add-project.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { AddOrgComponent } from './admin/add-org/add-org.component';
import { LinkifyPipe } from "./_services/hrefGenerator.service";
import { BudgetResearcherwiseComponent } from './researcher/budget/budget-researcherwise/budget-researcherwise.component';
import { S3SyncInfoComponent } from './dialog/s3-sync-info/s3-sync-info.component';
import { DeactivateGuard } from './dialog/s3-file-upload/deactivate-guard';
import { ShowProjectDetailsComponent } from './admin/show-project-details/show-project-details.component';
import { CostControlComponent } from './dialog/cost-control/cost-control.component';
import { ArchiveProjectComponent } from './project-details/archive-project/archive-project.component';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {PopoverModule} from "ngx-smart-popover";
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { InstanceTypeComponent } from './dialog/instance-type/instance-type.component';
import { AddBudgetComponent } from './project-details/add-budget/add-budget.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { AddStudyComponent } from './studies/add-study/add-study.component';
import {MatStepperModule} from '@angular/material/stepper';
import {MatIconModule} from '@angular/material/icon';
import { AttachVolumeComponent } from './product-details/attach-volume/attach-volume.component';
import { DetachVolumeComponent } from './product-details/detach-volume/detach-volume.component';
import { BillingAccountComponent } from './billing-account/billing-account.component';
import { ShowRenewalDateComponent } from './components/header/show-renewal-date/show-renewal-date.component';
import { AssignPublicStudyComponent } from './studies/assign-public-study/assign-public-study.component';
import { UpdateNetworkDetailsComponent } from './settings/update-network-details/update-network-details.component';
import { UpdateEgressStoreDetailsComponent } from './settings/update-egress-store-details/update-egress-store-details.component';
import { ConformationDialogComponent } from './settings/conformation-dialog/conformation-dialog.component';
import {AzureOrderComponent} from "./order/azure-order/azure-order.component";
import {ProperNamePipe} from "./proper-name.pipe";

declare var require: any;

@NgModule({
  declarations: [
    AppComponent,
    SettingsComponent,
    LoginComponent,
    ConfigComponent,
    ConfirmationDialog,
    LoaderComponent,
    RegisterComponent,
    OnCreate,
    IpconvertPipe,
    ProperNamePipe,
    ReversePipe,
    CatalogComponent,
    ResearcherComponent,
    OrderComponent,
    ActiveProductComponent,
    AdminComponent,
    AdminBudgetTrackingComponent,
    AdminBudgetDetailsComponent,
    PrincipalComponent,
    BudgetTrackingComponent,
    BudgetDetailsComponent,
    OrganizationComponent,
    InvestigatorComponent,
    HeaderComponent,
    FooterComponent,
    InstanceComponent,
    ProductDetailsComponent,
    S3FileUploadComponent,
    ProjectActionComponent,
    ProductsPipe,
    InstanceSshComponent,
    S3ExploreComponent,
    BudgetProductComponent,
    S3FileDeleteComponent,
    S3EmptyFolderComponent,
    BudgetResearcherComponent,
    AdminBudgetProductComponent,
    LinkSagemakerComponent,
    SuccessMessageComponent,
    UnlinkResourceComponent,
    ResetPasswordComponent,
    KeypairComponent,
    CreateKeypairComponent,
    ResendVerificationComponent,
    StudiesComponent,
    StudyDetailsComponent,
    BudgetManagementComponent,
    BudgetProjectComponent,
    LinkStudyComponent,
    ErrorMessageComponent,
    ShareStudyComponent,
    studyS3ExploreComponent,
    AuditTrailComponent,
    AuditDetailsComponent,
    CatalogManagementComponent,
    AssignCatalogComponent,
    CatalogManagementPrincipalComponent,
    UserComponent,
    AddUserComponent,
    AssignOrganisationComponent,
    EnableUserComponent,
    S3UnshareComponent,
    WelcomePageComponent,
    LogoutComponent,
    DefaultComponent,
    InstanceRdpComponent,
    AccountNotFoundComponent,
    AddAccountComponent,
    AddProjectComponent,
    ProjectDetailsComponent,
    AddOrgComponent,
    LinkifyPipe,
    BudgetResearcherwiseComponent,
    S3SyncInfoComponent,
    ShowProjectDetailsComponent,
    CostControlComponent,
    ArchiveProjectComponent,
    InstanceTypeComponent,
    AddBudgetComponent,
    AddStudyComponent,
    PageNotFoundComponent,
    AttachVolumeComponent,
    DetachVolumeComponent,
    BillingAccountComponent,
    ShowRenewalDateComponent,
    AssignPublicStudyComponent,
    UpdateNetworkDetailsComponent,
    UpdateEgressStoreDetailsComponent,
    ConformationDialogComponent,
    AzureOrderComponent
  ],
  imports: [
    MatTabsModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MdlModule,
    BrowserAnimationsModule,
    // ToasterModule,
    MomentModule,
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    ClipboardModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      preventDuplicates: true
    }),
    PaginationModule.forRoot(),
    TooltipModule.forRoot(),
    RecaptchaModule,
    // NgbModule,
    PopoverModule,
    MatDatepickerModule,
    MatRippleModule,
    MatInputModule,
    MatNativeDateModule,
    MatStepperModule,
    MatExpansionModule,
    MatIconModule
  ],
  providers: [
    AuthGuardService,
    DataService,
    DashboardService,
    PreviousRouteService,
    SocketService,
    ResearchService,
    OrganizationService,
    ServiceActionsService,
    S3DataService,
    ProductUtilService,
    DeactivateGuard,
    MatNativeDateModule,
    MatDatepickerModule,
    MatStepperModule,
    MatIconModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
