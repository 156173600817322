<div class="container">

  <!-- breadcrumb -->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb bc-custom">
      <li class="breadcrumb-item"><a class="bc-item" (click)="goToMainMenu()"> Studies </a></li>
<!--      <li class="breadcrumb-item" aria-current="page"><a class="bc-item" href="/catalog/{{ projectName }}/{{ account_id }}"> {{ projectName }} </a></li>-->
      <li class="breadcrumb-item " aria-current="page" style="cursor: pointer;" (click)="routeBack()"><a class="bc-item">{{ name }} </a></li>
      <li class="breadcrumb-item active bc-item-active" aria-current="page">Explore Details</li>
    </ol>
  </nav>
  <!-- details -->
  <div style="display: flex; justify-content: space-between; align-items: center; margin: 20px 0px;">
    <div style="display: flex;">
      <img style="max-width: 75px; max-height: 75px;border-radius: 10px;" src="../../assets/images/aws_icon/s3.png" alt="S3 Bucket"/>
      <div style="display: flex; flex-direction: column; margin: 0px 15px;">
        <h4 class="text-white font-class">{{ name }}</h4>
        <span style="color: #7596FF; font-size: 14px;" class="font-class">Amazon S3
          <span *ngIf="isShared === true" class="vl">
              <img class="share-icon" src="../../assets/images/share-white.png" alt="Shared"/>
              <span class="share-icon-text"> Shared </span>
          </span>
      </span>
      </div>
    </div>
      
    <div class="web-status" style="height: 45px; display: flex; border: 1px solid #465374; border-radius: 23px; align-items: center; padding: 10px;">
      <img style="height: 15px; margin: 10px; cursor: pointer;" src="../../assets/images/refresh.png" alt="Refresh Study Explore" (click)="refresh()" />
      <span style="margin: 0px 10px; font-size: 14px;" class="text-white font-class">Refresh</span>
    </div>
  </div>

  <!-- mobile actions bar -->


  <div class="divider"></div>

  <div class='s3Explore'>
    <div class="primary-flex">
<!--      <div class="search-div" style="margin-right: auto">-->
<!--        <em class="glyphicon glyphicon-search search-icon"></em>-->
<!--        <input (keyup)="searchFilesInS3()" [(ngModel)]="searchKey" class="form-control search-inp search-inp-add" placeholder="Find files by name" type="text">-->
<!--      </div>-->

      <div class="dropdown" style="margin-right: 30px" *ngIf="checkBoxValue.length == 1 && preSignedUrlAccess && selectedOneOrMoreThanOneFolder.length == 0">
        <button class="tenant-cl btn dropdown-toggle menu-dropdown-content text-white font-class" type="button" (click)="checkForFileType()" data-toggle="dropdown">
          Actions
          <i class="fa fa-angle-down" title="Actions" aria-hidden="true" style="color: #fff;width: 12px;height: 18px;font-size: 20px;    margin-left: 10px;"></i>
        </button>
        <ul class="dropdown-menu" style="cursor: pointer;background: rgb(7, 16, 42);position: absolute;will-change: transform;top: 0px;left: 0px;
          transform: translate3d(-58px, 17px, 0px);">
          <li><a  class="listItems" *ngIf="checkBoxValue.length == 1 && selectedFileType =='File' && (studyType !== 'Public' && studyType !== 'internal')" (click)="downloadS3FromCheckBoxValue()" >Download</a></li>
          <li><a  class="listItems"  *ngIf="checkBoxValue.length > 0 && (studyType !== 'Public' && studyType !== 'internal')" (click)="deleteMultipleS3Data()">Delete</a></li>
          <li><a  class="listItems" *ngIf="checkBoxValue.length == 1  && studyType !== 'Public' && studyType === 'internal' && preSignedUrlAccess && userLevel != '0' && selectedFileType =='File'" (click)="createCopyPreSignedURLFromCheckBoxValue()" >Create pre-signed URL</a></li>
        </ul>
      </div>     

    </div>

    <!-- Responsive actions -->
<!--    <div class="search-div-responsive">-->
<!--      <em class="glyphicon glyphicon-search search-icon"></em>-->
<!--      <input (keyup)="searchFilesInS3()" [(ngModel)]="searchKey" class="form-control search-inp search-inp-add" placeholder="Find files by name" type="text">-->
<!--    </div>-->

    <div class="actionMenu" >
  

      <div style="height: 30px; display: flex; border: 1px solid #465374; border-radius: 23px; align-items: center; padding: 10px;background: #07102A;">
        <img style="height: 15px; margin: 10px; cursor: pointer;" src="../../assets/images/refresh.png" alt="Refresh Study Explore" (click)="refresh()" />
        <span style="margin: 0px 10px; font-size: 10px;" class="text-white font-class">Refresh</span>
      </div>

      <!-- <div class="dropdown" style="margin-right: 10px" *ngIf="checkBoxValue.length > 0">
        <button class="tenant-cl btn dropdown-toggle menu-dropdown-content text-white font-class" type="button" (click)="checkForFileType()" data-toggle="dropdown">
          Actions
          <i class="fa fa-angle-down" title="Actions" aria-hidden="true" style="color: #fff;width: 12px;height: 18px;font-size: 15px;    margin-left: 10px;"></i>
        </button>
        <ul class="dropdown-menu" style="cursor: pointer;background: rgb(7, 16, 42);position: absolute;will-change: transform;top: 0px;left: 0px;
        transform: translate3d(-58px, 17px, 0px);">
          <li><a  class="listItems" *ngIf="checkBoxValue.length == 1 && selectedFileType =='File' " (click)="downloadS3FromCheckBoxValue()" >Download</a></li>
          <li><a  class="listItems"  *ngIf="checkBoxValue.length > 0" (click)="deleteMultipleS3Data()">Delete</a></li>
        </ul>
      </div> -->

    </div>


    <div style="margin-top: 10px">
      <div class="back-layout" (click)="goBackToRootFolder()" style="text-align: center;float: left;" *ngIf="!showSpinner && showRootIcon">
        <img class="back-icon" style="margin-right: 10px;" src="../../../assets/images/pagination-prev-active.png" alt="Go To Root" />
        <img class="back-icon" src="../../../assets/images/pagination-prev-active.png" alt="Go To Root"/>
        <h3 class="back-text text-white font-class">Root</h3>
      </div>
      <div class="back-layout" (click)="goBack()" style="text-align: center;float: left;margin-left: 20px;">
        <img class="back-icon" src="../../../assets/images/pagination-prev-active.png" alt="Go Back"/>
        <h3 class="back-text text-white font-class">Back</h3>
      </div>
      <div style="text-align: center" *ngIf="bucketData.length > 0 && !showSpinner && !folderName">
        <h2 class="modal-title title-header" id="myModalLabel" class="Header" style="text-align: center;
  font: normal normal bold 20px/12px Nunito Sans;
  letter-spacing: 0px;
  color: #85D4FF;">
          Available Items
        </h2>
      </div>
      <div style="text-align: center" *ngIf="!showSpinner && folderName">
        <h2 class="modal-title title-header" id="myModalLabel" class="Header" style="text-align: center;
      font: normal normal bold 20px/12px Nunito Sans;
      letter-spacing: 0px;
      color: #85D4FF;">
          {{folderName}}
        </h2>
      </div>
    </div>

    <app-loader class="spinner" *ngIf="showSpinner"></app-loader>

    <div *ngIf="bucketPrefixData.length == 0 && bucketData.length==0 && !showSpinner">
      <h4 style=" color: #fff;text-align: center;padding: 100px; font-size: 22px">
        No Data Available
      </h4>
    </div>
    <div class="table-responsive md-layout lg-layout" style="margin-bottom: 25px;">
      <table class = "gfg" style="width: 100%" *ngIf="bucketPrefixData.length > 0 || bucketData.length > 0 && !showSpinner">
        <thead style="background-color: #15244B">
        <tr>
          <th>
            <label for="allCheckBoxSelected" class="visually-hidden"> allCheckBoxSelected </label>
            <input [disabled]="studyType === 'Public' || preSignedUrlAccess == false" type="checkbox" 
            name="allCheckBoxSelected" [value]="" (change)="allCheckBoxSelected($event)" id="allCheckBoxSelected">
          </th>
          <th class="tableHeader" style="width: 25%;">FILE NAME</th>
          <th class="tableHeader" style="width: 15%;">SIZE</th>
          <th class="tableHeader" style="width: 10%;">TYPE</th>
          <th class="tableHeader" style="width: 25%;">LAST UPDATED ON</th>
          <th class="tableHeader" style="width: 15%;">STORAGE CLASS</th>
          <th class="visually-hidden">-</th>
        </tr>
        </thead>
        <tbody style="background-color: #07102A">
        <tr *ngFor="let data of bucketData" style="background-color:inherit">
          <td style="width: 10px;" >
            <input [disabled]="studyType === 'Public' || preSignedUrlAccess == false"  type="checkbox" aria-label="data.name"
              [(ngModel)]="data.isChecked" [checked]="data.isChecked" (change)="onCheckboxChanged($event, data)">
          </td>
          <td class="tableData" style="width: 25%;cursor: pointer;color: #73E2CF;text-decoration: underline;" *ngIf="data.type == 'folder'" title="{{data.name}}" (click)="getS3FolderData(data.name)">{{getFiletName(data.name, '')}}</td>
          <td class="tableData" style="width: 25%;color: #73E2CF;text-decoration: underline;" *ngIf="data.type != 'folder'" title="{{getFiletName(data.Key, 'title')}}" >{{getFiletName(data.Key, 'table')}}</td>
          <td class="tableData" style="width: 15%;font-size: 14px" *ngIf="data.Size != 0 && data.type != 'folder'">{{getFileSize(data.Size)}}</td>
          <td class="tableData" style="width: 15%;font-size: 14px" *ngIf="data.Size == 0 && data.type != 'folder'">{{data.Size }}</td>
          <td class="tableData" style="width: 15%;" *ngIf="data.type == 'folder'"> - </td>
          <td class="tableData" style="width: 10%;font-size: 14px">{{getFileType(data)}}</td>
          <td class="tableData" style="width: 25%;font-size: 14px" *ngIf="data.LastModified">{{data.LastModified | date:'medium'}}</td>
          <td class="tableData" style="width: 25%;" *ngIf="!data.LastModified"> - </td>
          <td class="tableData" style="width: 15%;font-size: 14px" *ngIf="data.StorageClass">{{data.StorageClass}}</td>
          <td class="tableData" style="width: 15%;" *ngIf="!data.StorageClass" > - </td>
          <!-- <td>
              <i class="fa fa-download" aria-hidden="true" (click)="downloadS3(data.Key)" style="margin-right: 10px;cursor: pointer;"></i>
              <i class="fa fa-trash" aria-hidden="true" (click)="deleteS3File(data.Key)" style="cursor: pointer;"></i>
          </td> -->
          <td class="tableData" style="text-align: center;overflow: visible !important;position:relative;">
            <div class="dropdown" *ngIf="studyType !== 'Public' && studyType === 'internal'">
                  <span>
                  <i class="fa fa-ellipsis-v" data-toggle="dropdown" title="Action" aria-hidden="true" style="color: #f1f1f1;cursor: pointer;"></i>
                  <ul class="dropdown-menu" style="cursor:pointer;background: #07102A;">
                    <li><a  class="listItems" *ngIf="data.type != 'folder' && studyType !== 'internal'" (click)="downloadS3(data.Key)" >Download</a></li>
                    <li><a  class="listItems"  *ngIf="data.type != 'folder' && studyType !== 'internal'" (click)="deleteS3File(data.Key)">Delete</a></li>
                    <li><a  class="listItems"  *ngIf="data.type == 'folder' && studyType !== 'internal'" (click)="deleteS3Folder(data.name)">Delete</a></li>
                    <li><a  class="listItems"  *ngIf="data.type == 'folder'  && studyType !== 'internal'" (click)="uploadFilesToSpecificFolder(data.name)">Upload</a></li>
                    <li><a class="listItems" *ngIf="studyType === 'internal' && data.type != 'folder' && preSignedUrlAccess && userLevel != '0'" (click)="createCopyPreSignedURL(data.Key)">Create pre-signed URL</a></li>
                  </ul>
                </span>
            </div>
          </td>
        </tr>
        <tr *ngFor="let prefixData of bucketPrefixData" style="background-color:inherit">
          <td style="width: 10px;"><input type="checkbox" [(ngModel)]="prefixData.isChecked" [checked]="prefixData.isChecked" (change)="onCheckboxChanged($event, prefixData)"></td>
          <td class="tableData geeks" style="width: 25%;cursor: pointer;" *ngIf="prefixData.prefix"><a (click)="getFolderData(prefixData.Prefix,'Folder')"> {{prefixData.prefix}} </a></td>
          <td class="tableData geeks " style="width: 25%;" *ngIf="prefixData.key"><a (click)="getFolderData(prefixData.Key,'File')"> {{prefixData.key}} </a></td>
          <td class="tableData geeks" style="width: 15%;">Folder</td>
          <td class="tableData geeks" style="width: 30%;"> - </td>
          <td class="tableData geeks" style="width: 10%;"> - </td>
          <td class="tableD/usr/share/code/resources/app/out/vs/code/electron-sandbox/workbench/workbench.htmlata geeks" style="width: 10%;"> - </td>
          <!-- <td>
              <i class="fa fa-upload" (click)="uploadFilesToSpecificFolder(prefixData.Prefix)" aria-hidden="true" style="margin-right: 10px"></i>
              <i class="fa fa-trash" (click)="deleteS3Folder(prefixData.Prefix)" aria-hidden="true"></i>
          </td> -->
          <td class="tableData" style="text-align: center;overflow: visible !important;position:relative">
            <div class="dropdown">
                  <span>
                  <i class="fa fa-ellipsis-v" data-toggle="dropdown" title="Action" aria-hidden="true" style="color: #f1f1f1;cursor: pointer;"></i>
                  <ul class="dropdown-menu" style="cursor:pointer;background: #07102A;">
                    <li><a  class="listItems" *ngIf="studyType !== 'Public' || studyType !== 'internal'" (click)="uploadFilesToSpecificFolder(prefixData.Prefix)" >Upload</a></li>
                    <li><a  class="listItems" *ngIf="studyType !== 'Public' || studyType !== 'internal'" (click)="deleteS3Folder(prefixData.Prefix)">Delete</a></li>
                  </ul>
                </span>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="load-more-btn-wrapper" *ngIf="showLoadMore">
        <button class="load-more-btn" (click)="loadMoreEvents()">Load More</button>
      </div>
    </div>

    <div class="sm-layout xs-layout" *ngIf="bucketData.length > 0 && !showSpinner">
      <!-- mobile responsive -->
      <div class="project-cards-layout">
        <div class="project-card" *ngFor="let data of bucketData;">
          <ul class="list-group">
            <div class="d-flex justify-content-between mb-2">
              <div class="project-card-title project-card-line-height font-class">File Name</div>
              <div class="project-card-project-name" style="text-decoration: underline;" *ngIf="data.type == 'folder'" title="{{data.name}}" (click)="getFolderData(data.name,'Folder')">{{getFiletName(data.name, '')}}</div>
              <div class="project-card-project-name" *ngIf="data.type != 'folder'" title="{{data.Key}}">{{getFiletName(data.Key, '')}}</div>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <div class="project-card-title project-card-line-height font-class">Size</div>
              <div class="project-card-project-name" *ngIf="data.Size != 0 && data.type != 'folder'">{{getFileSize(data.Size)}}</div>
              <div class="project-card-project-name" *ngIf="data.Size == 0 && data.type != 'folder'">{{data.Size}}</div>
              <div class="project-card-project-name" *ngIf="data.type == 'folder'"> - </div>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <div class="project-card-title project-card-line-height font-class">Type</div>
              <div class="project-card-project-name">{{getFileType(data)}}</div>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <div class="project-card-title project-card-line-height font-class">Last Updated On</div>
              <div class="project-card-project-name" *ngIf="data.LastModified">{{ data.LastModified | date:'medium' }}</div>
              <div class="project-card-project-name" *ngIf="!data.LastModified"> - </div>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <div class="project-card-title project-card-line-height font-class"> Storage Class</div>
              <div class="project-card-project-name" *ngIf="data.StorageClass">{{data.StorageClass}}</div>
              <div class="project-card-project-name" *ngIf="!data.StorageClass" > - </div>
            </div>
            <div class="d-flex justify-content-between mb-2" style="margin-top: 10px;" *ngIf="data.type != 'folder' && this.studyType !== 'Public' && this.studyType === 'internal'" >
              <button type="button" style="width: 40%;" class="btn  can-btn" *ngIf ="this.studyType !== 'internal'"(click)="downloadS3(data.Key)" >Download</button>
              <button type="button" style="width: 40%;" class="btn  can-btn" *ngIf ="this.studyType !== 'internal'" (click)="deleteS3File(data.Key)">Delete</button>
              <button type="button" style="width: 40%;" class="btn  can-btn" *ngIf="this.studyType === 'internal' && data.type != 'folder' && preSignedUrlAccess && userLevel != '0'" (click)="createCopyPreSignedURL(data.Key)">Create pre-signed URL</button>
            </div>

            <div class="d-flex justify-content-between mb-2" style="margin-top: 10px;" *ngIf="data.type == 'folder' && this.studyType !== 'Public' && this.studyType === 'internal'" >
              <button type="button" style="width: 40%;" class="btn  can-btn" *ngIf ="this.studyType !== 'internal'" (click)="deleteS3Folder(data.name)" >Delete</button>
              <button type="button" style="width: 40%;" class="btn  can-btn" *ngIf ="this.studyType !== 'internal'" (click)="uploadFilesToSpecificFolder(data.name)">Upload</button>
            </div>
          </ul>
        </div>
      </div>
      <div class="load-more-btn-wrapper" *ngIf="showLoadMore">
        <button class="load-more-btn" (click)="loadMoreEvents()">Load More</button>
      </div>
      <!-- <div class="product-avail">
        <span class="page-count-text">{{ currentPageLowerCount }} - {{ currentPageUpperCount }} of {{ totalRecords }}</span>
        <div class="pagination-wrapper">
          <img class="pagination-next-btn" [ngClass]="{'disable-first-btn':page === 1 }" src="../../assets/images/pagination-first-active.png" (click)="getNextSettings('first')"/>
          <img class="pagination-next-btn" [ngClass]="{'disable-prev-btn':page === 1 }" src="../../assets/images/pagination-prev-active.png" (click)="getNextSettings('previous')"/>
          <img class="pagination-next-btn" [ngClass]="{'disable-next-btn':page === totalPages}" src="../../assets/images/pagination-next-active.png" (click)="getNextSettings('next')"/>
          <img class="pagination-next-btn" [ngClass]="{'disable-last-btn':page === totalPages}" src="../../assets/images/pagination-last-active.png" (click)="getNextSettings('last')"/>
        </div>
      </div> -->
    </div>

  </div>
</div>
