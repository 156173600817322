import {Injectable} from '@angular/core';
// import {Http, Response, RequestOptions, Headers} from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/do';
// import {HttpInterceptor} from './httpInterceptor.service';

import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";

let headers = new HttpHeaders();
headers.append('Cache-Control', 'no-cache');
headers.append('Cache-Control', 'no-cache, no-store, must-revalidate');
headers.append('Pragma', 'no-cache');
headers.append('Expires', '-1');
headers.append('Content-Type', 'application/json; charset=utf-8');
let options = {
  headers: headers
}
// var options = new RequestOptions({headers: headers});


@Injectable({  
  providedIn: 'root'  
  })
export class DashboardService {
  constructor(
    private router: Router,
    private http: HttpClient,
    private toastr: ToastrService,
  ) {
  }

  role = null;
  loginForDashboard(data) {
    return this.http.post('/user/login', data);
  }

  getApplicationMode() {
    return this.http.get('/mode', options);
  }

  getTermsAndCondition() {
    return this.http.get('/license', options);
  }

  getPublicKey() {
    return this.http.get('/captchakey', options);
  }

  getSAMLUser(){
    return this.http.get('/user', options);
  }

  getLoginStrategy(){
    return this.http.get('/strategy', options);
  }

  verifyCaptcha(data) {
    return this.http.post('/user/verifycaptcha', data);
  }

  signup(data) {
    return this.http.post('/user/signup', data);
  }

  resendVerification(data) {
    return this.http.post('/resend-verification', data);
  }

  logoutForDashboard(data) {
    return this.http.get('/user/logout', data);
  }

  resetCognitoPassword(data){
    return this.http.post('/cognitoForgotPassword', data);
  }

  verifyCognitoPassword(data){
    return this.http.post('/verifyCognitoUserPassword', data);
  }

  forceResetPassword(data){
    return this.http.post('/forceResetPassword', data);
  }

  updateHeader(token) {
    options.headers.set('x-dashboard-auth', token);
  }

  getSettingsDetail(page, pageSize, sortBy, sortOrder, userType, accountType) {
    return this.http.get(`/settings?page=` + page + `&pageSize=` + pageSize + `&sortBy=` + sortBy + `&sortOrder=` + sortOrder+'&userType='+userType+'&accountType='+accountType, options);
      
  }

  saveBusinessService(jsonData) {
    return this.http.post(`/business_service`, jsonData);
  }

  savePlanOutage(jsonData) {
    return this.http.post(`/planOutage`, jsonData);
  }

  updatePlanOutage(jsonData, plannedOutageId) {
    return this.http.patch(`/planOutage?id=` + plannedOutageId, jsonData);
  }

  getPlannedOutageList(page, pageSize) {

    return this.http.get(`/planOutage?page=` + page + `&pageSize=` + pageSize, options);
  }

  getPlannedOutageBy(id) {
    return this.http.get(`/planOutage?id=` + id, options);
      
  }

  deletePlannedOutage(id, deleteType) {
    return this.http.delete(`/planOutage?id=` + id, options);
   
  }

  editBusinessService(jsonData) {
    return this.http.patch(`/business_service/` + jsonData.serviceId, jsonData);
    
  }

  updateBusinessService(checkId, data) {
    return this.http.put(`/business_service/` + checkId, data);
  }

  saveServiceStatus(jsonData: object) {
    return this.http.post(`/services`, jsonData);
  }


  getBusinessService(page, pageSize, sortBy, sortOrder) {
    return this.http.get(`/business_service?page=` + page + `&pageSize=` + pageSize + `&sortBy=` + sortBy + `&sortOrder=` + sortOrder, options);
      
  }

  getAllIncidentDetails(data) {
    let da: any = {
      outageIds: data
    };
    return this.http.post(`/getIncidentCommunication`, da, options);
      
  }

  saveSettings(jsonData) {
    return this.http.post(`/settings`, jsonData, options);
  }

  verifySettings(jsonData) {
    return this.http.post(`/verifySettings`, jsonData, options);
  }

  deleteSettingById(settingId, deleteType) {
    return this.http.delete(`/settings/${settingId}/${deleteType}`);
  }

  deleteBusinessServiceById(serviceId: string): void {
    const req = this.http.delete(`/business_service/` + serviceId);
    req.subscribe();
  }
  updateSettingById(settingId, jsonData) {
    return this.http.patch(`/settings/` + settingId, jsonData, options);
  }

  repairStudyAccount(jsonData){
  return this.http.patch(`/repairStudyAccount`, jsonData, options);
}

  getAssetDetails(url, page, pageSize, sortBy, sortOrder, period) {
    return this.http.get('/assets?page=' + page + '&pageSize=' + pageSize + '&sortBy=' + sortBy + '&sortOrder=' + sortOrder + url + '&period=' + period, options);
       
  }

  getBusinessGroups() {
    return this.http.get(`/apica/groups`, options);
       
  }

  updateBusinessGroups(id: string, jsonData: object) {
    return this.http.patch(`/apica/groups/` + id, jsonData);
       
  }

  addBusinessGroupsBack(jsonData: object) {
    return this.http.patch(`/apica/groups/add`, jsonData);
       
  }

  addBusinessGroups() {
    return this.http.get(`/apica/groups/meta-deta`, options);
       
  }

  getAllBusinessServices() {
    return this.http.get(`/apica/groups/meta-deta`, options);
       

  }

  getAllNodes() {
    return this.http.get(`/apica/groups/meta-deta`, options);
       
  }

  getBusinessGroupsStatus(status: string) {
    return this.http.get(`/apica/groups/groupsByStatus?status=` + status, options);
       
  }

  getServicePlannedStatus() {
    return this.http.get(`/planOutage/status`, options);
  }

  getSettings() {
    return this.http.get('/settings', options);
       
  }

  // getSettingByQuery(params) {
  //   if (params) {
  //     options.params = params;
  //   }
  //   return this.http.get('/settings/getSettingByQuery', options)
  //      
  // }

  getSettingsDetails(id) {
    return this.http.get('/settingsDetail/' + id, options);
       
  }

  getVendorsList() {
    return this.http.get('/settings/meta-data', options);
       

  }

  postSettings() {
    return this.http.post('/settings', options);
       
  }

  getBusinessServiceDetail(groupId) {
    return this.http.get('/apica/groups/' + groupId, options);
       
  }


  getBusinessUpdateDetails(id) {
    return this.http.get('/business_service/service/' + id, options);
       

  }

  searchBusinessService(page, pageSize, groupName) {
    return this.http.get('/business_service/search/?page=' + page + '&pageSize=' + pageSize + '&sortBy=asc&search=' + groupName, options);
       
  }

  addContactDetails(jsonData: object) {
    return this.http.post('/apica/userDetail', jsonData);
       
  }

  deleteContactDeatils(jsonData: object) {
    return this.http.patch(`/contacts_details/updateDetails`, jsonData);
       

  }

  editContactDetails(jsonData: object) {
    return this.http.patch('/apica/userDetail', jsonData);
       

  }

  getSnowDetails(groupId) {
    return this.http.get('/apica/snowDetails?groupId=' + groupId, options);
       

  }

  getServiceFilter(groupId, enable) {
    return this.http.get(`/apica/groups/enabled?groupId=` + groupId + `&enable=` + enable, options);
    //return this.http.get('/assets/data.json', options) 
  }

  getAllOutagesDetails(fromTime, timeZone) {
        return this.http.get(`/history_outage/trend?fromTime=${fromTime}&timeZone=${timeZone}`, options);
       
  }

  getAllServiceslist() {
    return this.http.get(`/business_service/trend`, options); 
  }

  sendEmailWithAttachement(jsonData) {
    return this.http.post(`/reports/email`, jsonData);
       
  }


  sendReport(jsonData) {
    return this.http.post(`/reports/sendReport?startDate=${jsonData.startDate}&endDate=${jsonData.endDate}&openTime=${jsonData.openTime}&closeTime=${jsonData.closeTime}&serviceSelectedValue=${jsonData.serviceSelectedValue}`, jsonData);
       
  }

  getAllBsmOutageData(checkId, fromTime, toTime) {
    return this.http.get(`/reports/outageData?check_id=` + checkId + `&fromTime=` + fromTime + `&toTime=` + toTime, options);
       
  }


  getReportData(checkId, fromTime, toTime, period) {
    return this.http.get(`/reports?check_id=` + checkId + `&fromTime=` + fromTime + `&toTime=` + toTime + '&period='+period, options);
       
  }

  createSnowTickets(groupId, jsonData: object) {
    return this.http.post('/apica/snowDetails?groupId=' + groupId, jsonData);
       
  }

  searchAsset(type, selectedProvider, searchValue, page, pageSize, sortBy, sortOrder) {
    return this.http.get('/assets?page=' + page + '&pageSize=' + pageSize + '&sortBy=' + sortBy + '&sortOrder=' + sortOrder + '&filterBy=account_name:' + selectedProvider + ',class_name:' + type + '&search=' + searchValue, options);
       
  }

  getAssetById(assetId) {
    return this.http.get(`/assets/` + assetId + `/assetDetails`, options);
       
  }

  getAssetSummary(accountName, vendor) {
    return this.http.get(`/assets/summary?period=total&account_name=${accountName}&vendor=${vendor}`, options);
       
  }

  getServices(page, pageSize, sortBy, sortOrder) {
    return this.http.get(`/services?page=` + page + `&pageSize=` + pageSize + `&sortBy=` + sortBy + `&sortOrder=` + sortOrder, options);
       
  }

  getThirdPartyServices() {
    return this.http.get(`/services`, options);
       
  }

  getServiceDetails(service) {
    return this.http.get(`/services/` + service, options);
       
  }

  //Changes due to Bot Library implementation

  getAllSettings() {
    return this.http.get('/settings?pageSize=999&sortBy=created_on&sortOrder=asc');
       
  }

  getrlCatalystBotSummary(jsonData) {
    return this.http.post(`/rlCatalyst/botSummary`, jsonData);
       
  }

  getMetaData() {
    return this.http.get('/events?filter=meta', options);
  }

  getAlertData(url) {
    return this.http.get('/events/?query=' + url, options); 
  }

  getDayMetrix(prd, checks) {
    return this.http.get('/outages?period=' + prd + '&checks=' + checks, options); 
  }

  getMatrixByDate(fromDate,toDate,checks){
    return this.http.get('/outages?fromDate=' + fromDate+'&toDate='+toDate+ '&checks=' + checks, options);
  }

  getDayMetrixChart(fromTime, toTime, checks, page, pageSize) {
    if (toTime) {
      return this.http.get('/outages?fromTime=' + fromTime + '&toTime='+ toTime +'&checks=' + checks + '&page='+ page +'&pageSize='+pageSize, options); 
    } else {
      return this.http.get('/outages?fromTime=' + fromTime + '&checks=' + checks + '&page='+ page +'&pageSize='+pageSize, options); 
    }
  }
  getScatterChartByDate(fromDate,toDate,groupName, checkId) {
    return this.http.get('/outages/scatterChart?fromDate=' + fromDate+'&toDate='+toDate+ '&groupName=' + groupName + '&checkId=' + checkId, options);
  }


  getBusinessServiceName() {
    return this.http.get('/business_service?page=1&pageSize=100&sortBy=created_on&sortOrder=asc', options);
       
  }

  getVersion() {
    return this.http.get('/getVersion', options);
       
  }
  getDialogBox() {
    return this.http.get('/getDialogBox', options);
  }
  
  addCommunication(data) {
    return this.http.post('/incidentCommunication', data);
       
  }

  InfoCommunication(data) {
    return this.http.post('/getIncidentCommunication', data)
       
  }

  getOutage(checks, id, page, pageSize) {
    if (id) {
      return this.http.get('/outages?&checks=' + checks + '&faultId=' + id + '&page=' + page + '&pageSize=' + pageSize, options);
    } else {
      return this.http.get('/outages?&checks=' + checks + '&page=' + page + '&pageSize=' + pageSize, options);
    }

  }

  getOutageByDate(grpDate, id, page, pageSize) {
    if (id) {
      return this.http.get('/outages?&grpDt=' + grpDate + '&faultId=' + id + '&page=' + page + '&pageSize=' + pageSize, options);
    } else {
      return this.http.get('/outages?&grpDt=' + grpDate + '&page=' + page + '&pageSize=' + pageSize, options);
    }
  }

  getOutageSummary(fromTime, period){
    return this.http.get('/outages/summary?&fromTime=' + fromTime + '&period='+ period, options);
  }

  getAllTenant() {
    return this.http.get('/getTenant', options);
  }

  selected_tenant(data) {
    return this.http.post('/user/selected_tenant', data);
  }

  addFault(data) {
    return this.http.post('/fault_outages', data);
  }

  getFault(pageSize, page, id) {
    if (id) {
      return this.http.get('/fault_outages?pageSize=' + pageSize + '&page=' + page + '&id=' + id);
    } else {
      return this.http.get('/fault_outages?pageSize=' + pageSize + '&page=' + page);
    }
  }

  editFault(data, id) {
    return this.http.post('/fault_outages/edit?id=' + id, data);
  }

  getEditFault(id) {
    return this.http.get('/fault_outages/edit?id=' + id);
  }

  remediation(address, type, serviceName) {
    return this.http.post('/remediation', {
      address: address,
      type: type,
      serviceName: serviceName
    });
  }

  getELKIndex() {
    return this.http.get('/outages/ELKIndex');
  }

  // getSecondaryContacts(groupId) {
  //   return this.http.get('/contacts_details?get=contacts&type=Secondary&type=Escalation&groupId=' + groupId)
  //     .map((response: Response) => response.json());
  // }

  getSecondaryContact() {
    return this.http.get('/contacts_details?get=contacts&type=Secondary');
  }

  getPrimaryContacts(groupId) {
    return this.http.get('/contacts_details?get=contacts&type=Primary&groupId=' + groupId);
  }

  getContactsGroupId() {
    return this.http.get('/contacts_details/list');
  }

  getAllContactsWithId(groupId) {
    return this.http.get('/contacts_details?get=contacts&type=Secondary&groupId=' + groupId + '&type=Primary&type=Escalation');
  }

  getAllPrimaryContacts(page, pageSize, sortOrder, sortBy) {
    return this.http.get('/contacts_details/userDetail/get=contacts?page=' + page + '&pageSize=' + pageSize + '&sortOrder=' + sortOrder + '&sortBy=' + sortBy + '&filterBy=type:Primary,active:true');
  }

  updateContactsType(jsonData) {
    return this.http.patch('/contacts_details/updateType', jsonData);
  }

  addContacts(jsonData: object) {
    return this.http.post('/contacts_details/userDetail?add=contacts', jsonData);
       
  }

  updateContacts(jsonData: object) {
    return this.http.patch('/contacts_details/userDetail?update=contacts', jsonData);
       
  }

  updateTypes(jsonData: object) {
    return this.http.patch('/contacts_details/contactType?update=contacts', jsonData);
       
  }

  checkAWS(vendor,id) {
    return this.http.get('/settings/verifyCredential/check?vendor=' + vendor + '&id=' + id);
  }

  getELKMetrics(){
    return this.http.get('/metrics');
  }


  scheduledReports(jsonData) {
    return this.http.post(`/scheduledReports`, jsonData);
       
  }
  getTopologyDetails(groupName) {
    return this.http.get('/topology?groupName=' + groupName);
       
  }


  logout() {
    this.logoutForDashboard({}).subscribe((data:any) => {
      sessionStorage.removeItem('currentUser');
      sessionStorage.removeItem('tenantid');
      sessionStorage.removeItem("5b1647bf469e0b0cf9dcacca");
      sessionStorage.removeItem("landlordUser");
      sessionStorage.removeItem("roles");
      sessionStorage.removeItem("strategy");
      sessionStorage.removeItem('currentUserEmail');
      sessionStorage.removeItem('photo');
      sessionStorage.removeItem('displayName');
      sessionStorage.removeItem("Selected");
      let externalLogoutUrl = data?.logoutUrl; //Azure Ad and SAML will have this logoutUrl. For Cognito we would navigate to logout page.
      if(!externalLogoutUrl){
         this.router.navigate(['/logout']);
      }else{
        window.location.href = externalLogoutUrl;
      }
    }, error => {
        console.log(error);
        let err = '';
      try {
        let errBody = error.error
        if(error && error.status === 401){
           this.toastr.error(errBody.err, '');
           this.router.navigate(['login']);
         } else if(error && error.status !=401)
         {
          this.toastr.error(errBody.error, '');
          }
      } catch (e) {
        err = `Something went wrong while logging you out, we are working on this`;
      }
     
    });
  }

  async showMenu() {
    return await this.getSAMLUser().toPromise();
  }

  async allowSignUp(){
    const data = await this.getApplicationMode().toPromise();
    console.log(data);
    return data;
    //return data.enableB2CMode;
  }

  async checkIfB2CMode(){
    const data = await this.getApplicationMode().toPromise();
    console.log(data);
    return data;
    //return data.enableB2CMode;
  }

  checkIfResearcher(){
    let level = sessionStorage.getItem('Level');
    this.role = sessionStorage.getItem('roles');
    if (!this.role.includes(0)) {
    this.router.navigate(['pageNotFound']);
    }
    // if (Number(level) > 0) {
    //   this.router.navigate(['login']);
    // }
  }

  checkIfPI(){
    let level = sessionStorage.getItem('Level');
    if (Number(level) !== 1  ) {
      this.router.navigate(['pageNotFound']);
      this.toastr.error( "You don't have authorization to access this resource" );
    }
  }

  checkIfAdmin(){
    let level = sessionStorage.getItem('Level');
    if (Number(level) !== 2  ) {
      this.router.navigate(['pageNotFound']);
    }
  }
}
