import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {Globals} from "../../globals";
import { MdlDialogReference } from '@angular-mdl/core';

import { ResearchService } from "../../_services/research.service";

@Component({
  selector: 'app-archive-project',
  templateUrl: './archive-project.component.html',
  styleUrls: ['./archive-project.component.css'],
  providers: [Globals]
})
export class ArchiveProjectComponent implements OnInit, AfterViewInit {
  @ViewChild('projectActionWindow') projectActionWindow: ElementRef;
  constructor(
    @Inject('data') public data: any,
    public dialogRef: MdlDialogReference,
    private researchService: ResearchService,
    private globals: Globals,private toastr: ToastrService,
    private router: Router
  ) { }

  projectName;
  projectId;
  accountName;
  confirmBox:boolean = false;
  showSpinner:boolean = false;
  deleteProjectStorage:boolean = false;
  storageType: any;
  
  ngOnInit() {
    if (this.data != undefined) {
      this.projectName = this.data.projectName;
      this.projectId = this.data.projectId;
      this.accountName = this.data.accountName;
      this.storageType = this.data.storageType
    }
  }

  ngAfterViewInit() {
    setTimeout(() => this.projectActionWindow.nativeElement.focus(), 0);
  }

  cancel(){
    this.dialogRef.hide();
  }

  confirmDeleteBox(){
    this.confirmBox = !this.confirmBox;
  }

  confirmDeleteProjectStorage(event){
      this.deleteProjectStorage = event.target.checked;
  }

  archiveProject() {
    this.showSpinner = true;
    if(this.storageType == "ingress"){
      this.deleteProjectStorage = true;
    }
    let projectData = { projectId : this.projectId, deleteProjectStorage: this.deleteProjectStorage};
    this.router.navigate(['/principal']);
    this.researchService.archiveProject(projectData).subscribe( response => {
      this.showSpinner = false;
      this.dialogRef.hide();
      this.toastr.success('Archiving project started', '', this.globals.tosterOverride);
    }, error => {
      this.dialogRef.hide();
      this.showSpinner = false;
      let errorMes = error.error;
      if(error && error.status === 401){
        let errBody= error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }else if (errorMes && (errorMes.code && errorMes.message)) {
        this.toastr.error('', errorMes.message, { tapToDismiss: false, timeOut: 0, extendedTimeOut: 0, closeButton : true });
      }else{
        this.toastr.error('Error in Archiving Project', '', this.globals.tosterOverride);
      }
    });

  }


}
