import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ipconvert'
})
export class IpconvertPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if(value){
      var ip= value.split('.').join('-');
      return 'ip-'+ip;
    }
    else return '""';

  }

}
