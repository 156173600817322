<form #f="ngForm">
  <div class="form-group" id="formData" >
    <button class="close close-btn" data-dismiss="modal" type="button" title="Close" (click)="cancel()">
      <i class="fa fa-times-circle close-btn-icon"></i></button>
    <h3 *ngIf="!isValid" class="Header header-title">Add Project</h3>
    <h3 *ngIf="isValid" class="Header header-title">Project Details</h3>
    <div style="display: flex; flex-direction: row; margin-top: 10px;">
      <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
      <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
      <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
      <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
    </div>
    <!-- Project  Details Section -->
    <div class="pi-project-details-modal" *ngIf="isValid">
      <div class="web-responsive">
        <div class="col-lg-12 col-md-12" style="padding: 0px">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <h2>Project Name</h2>
              <p>{{ projectName }}</p>
            </div>
            <div class="col-lg-6 col-md-6" *ngIf="projectStatus =='Active'">
              <h2>Actions</h2>
              <div class="row">
                <div title="Resume" *ngIf="projectStatus === 'Paused' " class="connect-link"
                  (click)="projectActions('Active', projectStatus)"
                  style="background-color: #15244B; border-radius: 5px; width: 55%; height: 45px; display: flex; align-items: center; margin-top: 10px;margin-right: 9px; cursor: pointer;">
                  <img style="height: 15px;margin-right: 15px;margin-left: 15px;" src="../../assets/images/play@2x.png"
                    alt="Resume Project" />
                  <span style="font-size: 12px;" class="text-white font-class">Resume</span>
                </div>
                <div title="Resume" *ngIf="projectStatus === 'Stopped' " class="connect-link"
                  (click)="projectActions('Active', projectStatus)"
                  style="background-color: #15244B; border-radius: 5px; width: 55%; height: 45px; display: flex; align-items: center; margin-top: 10px;margin-right: 9px; cursor: pointer;">
                  <img style="height: 15px;margin-right: 15px;margin-left: 15px;" src="../../assets/images/play@2x.png"
                    alt="Resume Project" />
                  <span style="font-size: 12px;" class="text-white font-class">Resume</span>
                </div>
                <div title="Pause" *ngIf="projectStatus === 'Active' " class="connect-link"
                  (click)="projectActions('Paused', projectStatus)"
                  style="background-color: #15244B; border-radius: 5px; width: 39%; height: 45px; display: flex; align-items: center; margin-top: 10px;margin-right: 9px; cursor: pointer;">
                  <i class="fa fa-pause" aria-hidden="true"
                    style="color: #FFFFFF;width: 3px;height: 11px;margin-right: 20px;margin-left: 15px; margin-top: -10px;"></i>
                  <span style="font-size: 12px;" class="text-white font-class">Pause</span>
                </div>
                <div title="Stop" *ngIf="projectStatus === 'Active' " class="connect-link"
                  (click)="projectActions('Stopped', projectStatus)"
                  style="background-color: #15244B; border-radius: 5px; width: 39%; height: 45px; display: flex; align-items: center; margin-top: 10px;margin-right: 9px; cursor: pointer;">
                  <img style="height: 15px;margin-right: 15px;margin-left: 15px;"
                    src="../../assets/images/no-stopping@2x.png" alt="Stop Project" />
                  <span style="font-size: 12px;" class="text-white font-class">Stop</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="col-lg-12 col-md-12" style="padding: 0px">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <h2 class="formLabel">Project Status</h2>
              <p>{{ projectStatus }}</p>
            </div>
          </div>
        </div> -->

        <div class="col-lg-12 col-md-12" style="padding: 0px">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <h2 class="formLabel">Account ID</h2>
              <p>{{ accountId }}</p>
            </div>
            <!-- <div class="col-lg-6 col-md-6" style="">
                  <div class="row">
                      <div class="connect-link" style="background-color: #15244B; border-radius: 5px; width: 45%; height: 45px; display: flex; align-items: center; margin-top: 10px;margin-right: 9px; ">
                          <img style="height: 15px;margin-right: 15px;margin-left: 15px" src="../../assets/images/play@2x.png" alt="../../assets/images/play@2x.png" />
                        <span (click)="actions(productDetails, connect.menu)" style="font-size: 12px;cursor: pointer;" class="text-white font-class">Pause</span>
                      </div>
                    </div>
              </div> -->
          </div>
        </div>
        <div class="col-lg-12 col-md-12" style="padding: 0px">
          <h2 class="formLabel">Assigned Researchers
            <span (click)="updateBTN=false" *ngIf="updateBTN"  class="add-researcher-manage">
                  <i style=" width: auto; " class="fa fa-pencil" aria-hidden="true"></i>
              Manage
            </span>
          </h2>
          <p *ngIf="updateBTN">{{ researchName }}</p>
          <div class="form-control researcher-selection-container" *ngIf="!updateBTN">
            <span class="selection-header">Select users from the list</span>
            <div class="selection" *ngFor="let researcher of researcherIdList; let i = index">
              <input type="checkbox" [id]="researcher.value+i" [checked]="validateResearcher(researcher.value)"
                [disabled]="validateProvisionedResearcher(researcher.value)" name="researcher.value"
                (change)="EditResearcherId(researcher, $event)">
              <label [ngClass]="{'uncheck-item': validateProvisionedResearcher(researcher.value) === true}" [for]="researcher.value+i" class="selection-label">{{researcher.viewValue}}</label>
            </div>
          </div>
          <span *ngIf="!updateBTN" class="add-researcher-title">Researchers who have associated products cannot be unchecked.</span>
          <div *ngIf="!updateBTN" >
            <button class="btn btn-primary edit-btn" (click)="insertResearchers()" type="button" [disabled]="updateBTN">
              Update list <em class="fa fa-refresh fa-spin" *ngIf="showSpinner"></em>
            </button>
            <button class="btn btn-primary edit-cancel-btn" type="button" (click)="updateBTN=true" [disabled]="updateBTN">
              Cancel  <em class="fa fa-refresh fa-spin" *ngIf="showSpinner"></em>
            </button>
          </div>
        </div>

        <h2 class="formLabel" style="padding-top: 20px;">Budget</h2>
        <p>USD {{ budgetAvailable }}<span [ngStyle]="getThresholdCrossRes()"> ( {{budgetConsumedPercentage}} % used )</span></p>
        <h2 class="formLabel">Project Description</h2>
        <p>{{ projectDescription }}</p>
        <h3 class="form-info" *ngIf="projectStatus =='Active'">
            To view product catalog and provisioned products switch role to researcher from the role drop-down in the top bar.
        </h3>
        <h3 class="form-info" *ngIf="projectStatus =='Creating'">
          Please wait for some time, your project is being created.
      </h3>
        <div class="md-stepper-horizontal orange" *ngIf="projectStatus == 'Creating'">
          <div class="md-step active done">
            <div class="md-step-circle"><span>1</span></div>
            <div class="md-step-title">Attach Account</div>
            <div class="md-step-optional">(Complete)</div>
            <div class="md-step-bar-left"></div>
            <div class="md-step-bar-right"></div>
          </div>
          <div class="md-step active inprogress">
            <div class="md-step-circle"><span>2</span></div>
            <div class="md-step-title">Create Budget</div>
            <div class="md-step-optional">(In-progress)</div>
            <div class="md-step-bar-left"></div>
            <div class="md-step-bar-right"></div>
          </div>
          <div class="md-step active inprogress">
            <div class="md-step-circle"><span>3</span></div>
            <div class="md-step-title">Add Products</div>
            <div class="md-step-optional">(In-progress)</div>
            <div class="md-step-bar-left"></div>
            <div class="md-step-bar-right"></div>
          </div>
          <!-- <div class="md-step">
            <div class="md-step-circle"><span>4</span></div>
            <div class="md-step-title">Create Project Complete</div>
            <div class="md-step-bar-left"></div>
            <div class="md-step-bar-right"></div>
          </div> -->
        </div>
      </div>
      <div class="mobile-responsive">
        <div style="display: flex; justify-content: flex-end; margin: 20px 0px;">
          <div class="links-unordered" style="display: flex; justify-content: center;">
            <div class="action-button">Actions</div>
            <ul style="display:none;">
              <div title="Resume" *ngIf="projectStatus === 'Paused' " class="connect-link"
                (click)="projectActions('Active', projectStatus)"
                style="background-color: #15244B; border-radius: 5px; height: 45px; display: flex; align-items: center; margin-top: 10px;margin-right: 9px; cursor: pointer;">
                <img style="height: 15px;margin-right: 15px;margin-left: 15px;" src="../../assets/images/play@2x.png"
                  alt="Resume Project" />
                <span style="font-size: 12px; margin-right: 20px;" class="text-white font-class">Resume</span>
              </div>
              <div title="Resume" *ngIf="projectStatus === 'Stopped' " class="connect-link"
                (click)="projectActions('Active', projectStatus)"
                style="background-color: #15244B; border-radius: 5px; height: 45px; display: flex; align-items: center; margin-top: 10px;margin-right: 9px; cursor: pointer;">
                <img style="height: 15px;margin-right: 15px;margin-left: 15px;" src="../../assets/images/play@2x.png"
                  alt="Resume Project" />
                <span style="font-size: 12px; margin-right: 20px;" class="text-white font-class">Resume</span>
              </div>
              <div title="Pause" *ngIf="projectStatus === 'Active' " class="connect-link"
                (click)="projectActions('Paused', projectStatus)"
                style="background-color: #15244B; border-radius: 5px; height: 45px; display: flex; align-items: center; margin-top: 10px;margin-right: 9px; cursor: pointer;">
                <i class="fa fa-pause" aria-hidden="true"
                  style="color: #FFFFFF;width: 3px;height: 11px;margin-right: 20px;margin-left: 15px; margin-top: -10px;"></i>
                <span style="font-size: 12px;margin-right: 20px;" class="text-white font-class">Pause</span>
              </div>
              <div title="Stop" *ngIf="projectStatus === 'Active' " class="connect-link"
                (click)="projectActions('Stopped', projectStatus)"
                style="background-color: #15244B; border-radius: 5px; height: 45px; display: flex; align-items: center; margin-top: 10px;margin-right: 9px; cursor: pointer;">
                <img style="height: 15px;margin-right: 15px;margin-left: 15px;"
                  src="../../assets/images/no-stopping@2x.png" alt="Stop Project" />
                <span style="font-size: 12px; margin-right: 20px;" class="text-white font-class">Stop</span>
              </div>
            </ul>
          </div>
        </div>
        <!-- <div class="col-lg-6 col-md-6">
          <h2>Actions</h2>
          <div class="row">
            <div title="Resume" *ngIf="projectStatus === 'Paused' " class="connect-link"
              (click)="projectActions('Active', projectStatus)"
              style="background-color: #15244B; border-radius: 5px; width: 55%; height: 45px; display: flex; align-items: center; margin-top: 10px;margin-right: 9px; cursor: pointer;">
              <img style="height: 15px;margin-right: 15px;margin-left: 15px;" src="../../assets/images/play@2x.png"
                alt="../../assets/images/play@2x.png" />
              <span style="font-size: 12px;" class="text-white font-class">Resume</span>
            </div>
            <div title="Resume" *ngIf="projectStatus === 'Stopped' " class="connect-link"
              (click)="projectActions('Active', projectStatus)"
              style="background-color: #15244B; border-radius: 5px; width: 55%; height: 45px; display: flex; align-items: center; margin-top: 10px;margin-right: 9px; cursor: pointer;">
              <img style="height: 15px;margin-right: 15px;margin-left: 15px;" src="../../assets/images/play@2x.png"
                alt="../../assets/images/play@2x.png" />
              <span style="font-size: 12px;" class="text-white font-class">Resume</span>
            </div>
            <div title="Pause" *ngIf="projectStatus === 'Active' " class="connect-link"
              (click)="projectActions('Paused', projectStatus)"
              style="background-color: #15244B; border-radius: 5px; width: 39%; height: 45px; display: flex; align-items: center; margin-top: 10px;margin-right: 9px; cursor: pointer;">
              <i class="fa fa-pause" aria-hidden="true"
                style="color: #FFFFFF;width: 3px;height: 11px;margin-right: 20px;margin-left: 15px"></i>
              <span style="font-size: 12px;" class="text-white font-class">Pause</span>
            </div>
            <div title="Stop" *ngIf="projectStatus === 'Active' " class="connect-link"
              (click)="projectActions('Stopped', projectStatus)"
              style="background-color: #15244B; border-radius: 5px; width: 39%; height: 45px; display: flex; align-items: center; margin-top: 10px;margin-right: 9px; cursor: pointer;">
              <img style="height: 15px;margin-right: 15px;margin-left: 15px;"
                src="../../assets/images/no-stopping@2x.png" alt="../../assets/images/no-stopping@2x.png" />
              <span style="font-size: 12px;" class="text-white font-class">Stop</span>
            </div>
          </div>
        </div> -->
        <div>
          <h2>Project Name</h2>
          <p>{{ projectName }}</p>
          <h2 class="formLabel">Account ID</h2>
          <p>{{ accountId }}</p>

          <div class="col-lg-12 col-md-12" style="padding: 0px">
              <h2 class="formLabel">Assigned Researchers
                <span (click)="updateBTN=false" *ngIf="updateBTN"  class="add-researcher-manage">
                  <i style=" width: auto; " class="fa fa-pencil" aria-hidden="true"></i>
              Manage
            </span>
              </h2>
              <p *ngIf="updateBTN">{{ researchName }}</p>
              <div class="form-control researcher-selection-container" *ngIf="!updateBTN">
                <span class="selection-header">Select users from the list</span>
                <div class="selection" *ngFor="let researcher of researcherIdList; let i = index">
                  <input type="checkbox" [id]="i" [checked]="validateResearcher(researcher.value)"
                    [disabled]="validateProvisionedResearcher(researcher.value)" name="researcher.value"
                    (change)="EditResearcherId(researcher, $event)">
                  <label [for]="i" class="selection-label">{{researcher.viewValue}}</label>
                </div>
              </div>
            <span *ngIf="!updateBTN" class="add-researcher-title">Researchers who have associated products cannot be unchecked.</span>
            <div *ngIf="!updateBTN" >
              <button class="btn btn-primary edit-btn" (click)="insertResearchers()" type="button" [disabled]="updateBTN">
                Update list <em class="fa fa-refresh fa-spin" *ngIf="showSpinner"></em>
              </button>
              <button class="btn btn-primary edit-cancel-btn" type="button" (click)="updateBTN=true" [disabled]="updateBTN">
                Cancel  <em class="fa fa-refresh fa-spin" *ngIf="showSpinner"></em>
              </button>
            </div>
            </div>

          <h2 class="formLabel" style="padding-top: 20px;">Budget</h2>
          <p>USD {{ budgetAvailable }} <span [ngStyle]="getThresholdCrossRes()">( {{budgetConsumedPercentage}} % used )</span></p>
          <h2 class="formLabel">Project Description</h2>
          <p>{{ projectDescription }}</p>
        </div>
      </div>
    </div>
    <!--  Add New Project Section -->
    <div class="add-new-project-form" *ngIf="!isValid">
      <div class="col-lg-6 col-md-12 field-container">
        <label class="formLabel">Project Name</label>
        <span class="after">*</span>
        <input class="form-control name-input" placeholder="Please enter your project name" type="text"
          value="Project Name" [(ngModel)]="projectName" #sName="ngModel" required name="projectName" minlength="3" 
          pattern="^[-_a-zA-Z0-9]+$" (keypress)="checkProjName()" (keyup)="checkProjName()" (keydown.space)="$event.preventDefault()">
          <span [ngClass]="getHelpTextClass(defaultClass)">{{orgNameErrDesc}}</span>
        <!-- <div class="name-helper-container">
          <span class="name-helper-text">{{orgNameErrDesc}}</span>
          <span class="name-left-text"><small>{{50-projectName.length}} Characters left </small></span>
        </div> -->
        <!-- <span *ngIf="projectName && sName.errors?.pattern" style="font-size:10px;color:red">Account Name should not contain special characters</span> -->
        <!-- <span class="length-cal" style="margin-bottom:-24px;"></span> -->
      </div>
      <div class="col-lg-6 col-md-12 field-container">
        <label class="formLabel">Budget Available</label>
        <span class="after">*</span>
        <input class="form-control name-input" type="number" min="1" value="5" placeholder="Please enter your budget"
          [(ngModel)]="budgetAvailable" #rCEvery="ngModel" required pattern="^([0-9]*[1-9][0-9]*)$"
          name="budgetAvailable">
        <span class="budget-helper-text">Numbers should be greater than 0</span>
      </div>
      <div class="col-lg-12 col-md-12 field-container">
        <label class="formLabel">Account ID</label>
        <span class="after">*</span>
        <!-- <div *ngIf="loadForm" style="text-align: center; ">
          <img src="/assets/images/barspreloaders.svg" style="height: 40px">
        </div> -->
        <app-loader class="spinner" *ngIf="loadForm"></app-loader>
        <select *ngIf="!isValid && !loadForm && accountListFound" class="form-control" required placeholder="Select an account name" [(ngModel)]="accountId" name="accountId"
          style="appearance: appearance !important;">
          <option value="undefined" disabled selected >Select an account name</option>
          <option *ngFor="let acc of fAccountIdList" [value]="acc.value">{{acc.viewValue}}</option>
        </select>
        <span *ngIf="!isValid && !loadForm && !accountListFound" class="account-helper-text">
          No AWS accounts available. Please contact your administrator to add AWS accounts to your organization
        </span>
      </div>
      <div class="col-lg-12 col-md-12 field-container">
        <label class="formLabel">Project Description</label>
        <span class="after">*</span>
        <textarea class="form-control description-content" placeholder="Add your project description" type="text"
          value="Project Description" [(ngModel)]="projectDescription" #sdName="ngModel" required
          name="projectDescription" maxlength="256" rows="4"> </textarea>
        <!-- <span style="font-size:12px; color:rebeccapurple">Project Description should be 256 character</span> -->
        <!--      <span *ngIf="projectDescription && sdName.errors?.pattern" style="font-size:10px;color:red">Account Name should not contain special characters</span>-->
        <!--        <span class="length-cal" style="margin-bottom:-24px;"></span> -->
      </div>
      <div class="col-lg-12 col-md-12 field-container">
        <label class="formLabel">Add Users</label>
<!--        <span class="after">*</span>-->
        <div class="form-control researcher-selection-container" *ngIf="researchIds.length > 0">
          <span class="selection-header" *ngIf="researchIds.length === 0">Select users from the list</span>
          <span class="selection-header" *ngIf="researchIds.length !== 0">Selected Users ( {{researchIds.length}}
            )</span>
          <div class="selection" *ngFor="let researcher of researcherIdList; let i = index">
            <input type="checkbox" [id]="researcher.value+i" name="researcher.value"
              (change)="selectResearcherIds(researcher, $event)">
            <label [for]="researcher.value+i" class="selection-label">{{researcher.viewValue}}</label>
          </div>
        </div>
        <div *ngIf="researchIds.length == 0">
          <span class="account-helper-text">
            You can invite additional collaborators later from the Users menu.
          </span>
        </div>
        <!-- <select class="form-control" placeholder="Discover" [(ngModel)]="researchIds" required name="researchIds"
          multiple>
          <option *ngFor="let acc of researcherIdList" [ngValue]="acc.value" [value]="acc.value">
            {{acc.viewValue}}
          </option>
        </select> -->
      </div>
      <div class="col-lg-12 col-md-12 add-btn">
        <!-- <button style="float:right;margin-left: 10px" mdl-button mdl-button-type="raised" mdl-ripple (click)="addSettings()">Submit</button> -->
        <!-- <button class="btn btn-default" type="button" (click)="cancel()">Close</button> -->
        <button *ngIf="!isValid"
          style="background-color: #3E6BFF;border-radius:23px !important;opacity: 1 !important;width: 165px; height:35px;"
          class="btn btn-primary " data-dismiss="modal" type="button" (click)="insertProject()"
          [disabled]="!f.valid">
          Add Project <em class="fa fa-refresh fa-spin" *ngIf="showSpinner"></em>
        </button>
        <!-- <button *ngIf="!submitBTN && !isValid"
          style="background-color: #3E6BFF !important;border-radius:23px !important;opacity: 1 !important;width: 165px; height:35px;"
          class="btn btn-primary " data-dismiss="modal" type="button"
          [disabled]="!submitBTN">
          Add Project <em class="fa fa-refresh fa-spin" *ngIf="showSpinner"></em>
        </button> -->
      </div>
    </div>
  </div>
</form>
