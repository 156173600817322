import { Component, OnInit } from '@angular/core';
import { ResearchService} from "../_services/research.service";
import {DashboardService} from "../_services/dashboard.service";
import {Router} from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { Globals } from "../globals";

declare var $: any;  // jquery
@Component({
  selector: 'app-researcher',
  templateUrl: './researcher.component.html',
  styleUrls: ['./researcher.component.css', '../app.component.css'],
  providers: [Globals]
})
export class ResearcherComponent implements OnInit {

  projects = [];
  loader: boolean = true;
  orgId;
  private researchService: ResearchService;
  constructor( private router: Router,
              researchService: ResearchService,
              private toastr: ToastrService,
              private globals: Globals,
              private apiService: DashboardService
              ) {
    this.researchService = researchService;
  }

  ngOnInit() {
    this.apiService.checkIfResearcher();
    this.loader = true;
    this.getProjects();
    this.orgId = sessionStorage.getItem('org_id');
    let level = sessionStorage.getItem("Level");
    if((this.orgId == "" || this.orgId == null || this.orgId == undefined) && (level == "1" || level == "0")) {
      this.router.navigate(['logout']);    
    } 
  }

  getProjects() {
    this.researchService.getProjects().subscribe((data:any) => {
      this.projects = data;
      this.loader = false
    }, error => {
      this.loader = false;
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }else if (error && error.status === 403){
          const err = error.error;
          this.toastr.error(err.error, 'Error', this.globals.tosterOverride);
          this.router.navigate(['pageNotFound']);
      }
    })
  }

  navigateToProduct(project) {
    if(project.projectStatus === 'Creating'){
      this.toastr.info(`Project "${project.name}" is being created. Please try after some time.`, '', this.globals.tosterOverride);
    }else if (project.projectStatus === 'Failed') {
      this.toastr.error(`Project creation failed for "${project.name}" project. Please contact your Administrator.`, '', this.globals.tosterOverride);
    }else{
      const accountId = project.account_id;
      //const name = project.name;
      const projectName = encodeURIComponent(project.name);
      sessionStorage.setItem('projectName',project.name);
      sessionStorage.setItem('projectId',project._id);
      sessionStorage.setItem('isEgressStoreEnabled', project?.isEgressStoreEnabled || false);
      localStorage.setItem(accountId, JSON.stringify(project));
      if (accountId) {
        this.router.navigate(['/catalog/' + projectName + '/' + accountId ]);
      } else {
        // console.log(project);
      }
    }
}

  projectDescription(data) {
    const strLen = data.length;
    let str = data;
    if (strLen > 245) {
      str = str.slice(0, 245) + '...';
    }
    return str;
  }

  getProjectThresholdClass(project){
    if(project.consumedBudgetPercentage >= project.thresholdPercentage){
      return {'threshold-cross-above': true, 'threshold-cross-below': false};
    }else{
      return {'threshold-cross-above': false, 'threshold-cross-below': true};
    }
  }

  nameTrim(data) {
    if (!data) {
      return data;
    }
    const getLen = data.length;
    if (getLen > 28) {
      data = data.slice(0, 28) + '...';
    }
    return data;
  }
  
}
