<div >
    <div class="dialog-header">
        <img class="cancel-icon" (click)="cancel()" (keypress)="cancel()" src="../../assets/images/cancel@2x.png" alt="Cancel"/>
      <h3 class="title"> Disable egress store </h3>
    </div>
    <div style="display: flex; flex-direction: row; margin-top: 0px;">
      <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
      <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
      <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
      <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
    </div>
    <div class="content">
      <h2  class="content-1">Are you sure you want to disable the egress store for this secure account? </h2>
    </div>
    <div class="warningData" >
      <h2 class="war-msg">All egress store information associated to this account will be permanently deleted from DB.</h2>
    </div>
    <div class="warning">
        <h2 class="delete-msg"> This operation cannot be undone. </h2>
        
    </div>
    <div class="content">
      <input type="checkbox" name="confirm" id="1" (click)="confirmCheckBox();">
      <label style="color:white; font-size: 15px; margin-left: 5px;">Are you sure you want to disable egress store for the account ?</label>
    </div>
    <div class="mdl-dialog__actions" style="margin-top: 15px;margin-bottom: 15px;">
      <button class="btn btn-primary dele-btn" type="button" [disabled]="!confirmBox" (click)="disableEgressStoreDetails();" ><h2 class="del-text"> Disable </h2></button>
      <button class="btn  can-btn" type="button"  (click)="cancel()"><h2 class="cal-text">Cancel</h2></button>
    </div>
</div>