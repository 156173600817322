<div class="container" id="scrollId">
  <div class="fixed-header">
      <nav aria-label="breadcrumb" class="breadcrumb-a">
          <ol class="breadcrumb bc-custom">
            <li class="breadcrumb-item">
              <a class="bc-item" routerLink={{previousURLVal}}> {{backPage}} </a>
            </li>
            <li class="breadcrumb-item active bc-item-active" aria-current="page">Add Account</li>
          </ol>
        </nav>
      <div class="product-name-layout">
          <h3 class="product-name text-white font-class">Add {{ vendor == 'AWS' ? (vendor| uppercase) : (vendor | titlecase) }} Account</h3>
          <div class="launch-now-wrapper">
            <!-- <button class="launch-btn-cancel text-white font-class" (click)="addAccount()" name="button" type="button">Cancel</button> -->
            <button class="launch-btn text-white font-class" style="margin-right: 10px;" *ngIf="!isVerified" [disabled]="!f.valid" tabindex="0" (keypress)="verifyAccount()" (click)="verifyAccount()" name="button" type="submit">
              Verify
              <i *ngIf="showSpinnerVerify" class="fa fa-refresh fa-spin" style="margin-left: 10px;"></i></button>
            <button id="submitAccount" class="launch-btn text-white font-class" [disabled]="!f.valid || !showAddAcc || clicked" tabindex="0" (keypress)="addAccount($event); clicked=true" (click)="addAccount($event); clicked=true" name="button" type="submit">
              Add Account
              <i *ngIf="showSpinner" class="fa fa-refresh fa-spin" style="margin-left: 10px;"></i></button>
          </div>
        </div>
  </div>

  <form #f="ngForm" (ngSubmit)="addAccount($event);f.valid" style="margin-top: 115px;">
  <div class="addAccountHeader">
      <div class="addAccountSection">
          <div style="margin-top: 20px;margin-bottom: 20px; border-bottom: 1px solid #465374;">
              <h3 class="product-desc font-class">Account Details
              <p class="verified" *ngIf="isVerified"> Verified account
                <i class="fa fa-check" style="font-size: 18px;color: #3FCB57;" aria-hidden="true"></i>
              </p>
              </h3>
              <h3 class="prod-c">Add the details of your {{ vendor == 'AWS' ? (vendor| uppercase) : (vendor | titlecase) }} account.
                For Research Gateway to create the roles required to manage resources on your behalf, the credentials need to have
                <h3 class="prod-c-inline">{{vendor=='AWS'?'IAMFullAccess':'Contributor'}}</h3> {{vendor=='AWS'?'permissions':'Role'}}. <h3 class="prod-c-inline">Research Gateway does not store these credentials.</h3></h3>
          </div>

        <div style="display: flow-root !important">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="padding: 0px;margin-right: 15px;margin-bottom: 10px;">
              <label for="accountName" class="prov-product-field text-white font-class" style="margin-bottom: 10px;">Account Name <span class="order-st">*</span></label>
                <input #sName="ngModel" [(ngModel)]="accountName" pattern="^\S.*$" (focus)="onFocus('account_name');" class="inp-txt user-input" minlength="3" name="accountName"
                placeholder="Please enter your account name - (required)" required type="text"
                value="Service Name" (keypress)="highlightHelpText('accountName')"
                (keyup)="highlightHelpText('accountName')" autocomplete="new-password" id="accountName">
              </div>
        <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="padding: 0px;margin-top: 10px; margin-bottom: 10px;">
          <label for="accessKey" class="prov-product-field text-white font-class">{{vendor=='AWS'?'Access Key':'Client ID'}} <span class="order-st">*</span></label>
                  <input [(ngModel)]="accessKey" (focus)="onFocus('access_key')" class="inp-txt user-input" minlength="16" maxlength="128" name="accessKey"
                     placeholder="Please enter your access key - (required)"
                     required style="color: #ffffff" type="password" (keypress)="highlightHelpText('accessKey')"
                     (keyup)="highlightHelpText('accessKey')" (keydown.space)="$event.preventDefault()" autocomplete="new-password" id="accessKey">
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="padding: 0px;margin-top: 10px;margin-bottom: 10px;">
          <label for="secretKey" class="prov-product-field text-white font-class">{{vendor=='AWS'?'Secret Key':'Client Secret'}} <span class="order-st">*</span></label>
          <input [(ngModel)]="secretKey" (focus)="onFocus('secret_key')" class="inp-txt user-input" minlength="16" maxlength="128" name="secretKey"
             placeholder="Please enter your secret key - (required)"
             required style="color: #ffffff" type="password"  (keypress)="highlightHelpText('secretKey')"
             (keyup)="highlightHelpText('secretKey')" (keydown.space)="$event.preventDefault()" id="secretKey">
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12 focus-highlight" style="padding: 0px;margin-top: 10px;margin-bottom: 10px;">
          <label for="region" class="prov-product-field text-white font-class">Region <span class="order-st">*</span></label>
          <!-- <input [(ngModel)]="region" [disabled]="isEdit" class="inp-txt user-input" maxlength="14" name="region"
            placeholder="Please enter your region - (required)" required pattern="(eu|sa|ca|ap|us)-(east|west|north|south|central)(east)*-[0-9]{1}"
            style="color: #ffffff" type="text" (keypress)="highlightHelpText('region')"
            (keyup)="highlightHelpText('region')" (keydown.space)="$event.preventDefault()"> -->
            <select class="inp-txt user-input" required placeholder="Region selection" [(ngModel)]="region" name="region"
            style="appearance: appearance !important;" (change)="highlightHelpText('region')" (focus)="onFocus('region')"  id="region" >
            <option value="" disabled selected>Select a region - (required)</option>
            <option *ngFor="let region of regionList" [value]="region.value">{{region.key}}</option>
          </select>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="padding: 0px;margin-top: 10px;margin-bottom: 20px;">
          <label for="AccountNumber" class="prov-product-field text-white font-class">{{vendor=='AWS'?'Account Number':'Subscription ID'}} <span class="order-st">*</span></label>
          <input [(ngModel)]="accountNumber"  (focus)="onFocus('account_number')" class="inp-txt user-input" 
          [minlength]="vendor == 'AWS' ? 12 : 36"
          [maxlength]="vendor == 'AWS' ? 12 : 36"
          name="accountNumber"
          [pattern]="vendor == 'AWS' ? '^\d{12}$' : '^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}$'"
          placeholder="Please enter your {{vendor=='AWS'?'account number':'subscription ID'}}- (required)" 
          required style="color: #ffffff" type="text" (keypress)="highlightHelpText('accountNumber')"
          (keyup)="highlightHelpText('accountNumber')" (keydown.space)="$event.preventDefault()"  id="AccountNumber">
        </div>
        <div *ngIf="vendor == 'AZURE'" class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="padding: 0px;margin-top: 10px;margin-bottom: 20px;">
          <label for="tenantID" class="prov-product-field text-white font-class">Tenant ID <span class="order-st">*</span></label>
          <input [(ngModel)]="tenantID"  (focus)="onFocus('account_number')" class="inp-txt user-input" minlength="36" maxlength="36" name="tenantID"
          placeholder="Please enter your tenant id - (required)"
          required style="color: #ffffff" type="text" (keypress)="highlightHelpText('tenantID')"
          (keyup)="highlightHelpText('tenantID')" (keydown.space)="$event.preventDefault()"  id="tenantID">
        </div>
        <div *ngIf="vendor == 'AZURE'" class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="padding: 0px;margin-top: 10px;margin-bottom: 20px;">
          <label for="objectID" class="prov-product-field text-white font-class">Object ID <span class="order-st">*</span></label>
          <input [(ngModel)]="objectID"  (focus)="onFocus('objectID')" class="inp-txt user-input" minlength="36" maxlength="36" name="objectID"
          placeholder="Please enter your tenant id - (required)"
          required style="color: #ffffff" type="text" (keypress)="highlightHelpText('objectID')"
          (keyup)="highlightHelpText('objectID')" (keydown.space)="$event.preventDefault()"  id="objectID">
        </div>
        <div *ngIf="accountType == 'study' " style="margin-top: 10px;margin-bottom: 10px; border-bottom: 1px solid #465374;">
          <h3 class="product-desc font-class"> Share account <span class="order-st"></span></h3>
        </div>
        <div *ngIf="accountType == 'study'" class="form-control account-selection-content" style="margin-bottom: 20px;min-height: 140px !important">
          <div class="selection">
            <input class="shareAccount" [checked]="isShared" type="checkbox" [id]="isShared"
              name="shareAccount" (change)="checkShareAccount($event)">
            <label class="selection-label" [for]="isShared"
            style="display: block; margin-bottom: 5px;font-family: 'Nunito Sans', sans-serif; ;cursor: default;"> Allow all Principals in this OU to use this study account. </label>
            <span class="network-config">
              Select this option if you want other principals in this OU to be able to create external studies using this setting.
            </span>             
          </div>
        </div>
        <div *ngIf="accountType == 'project' " style="margin-top: 10px;margin-bottom: 10px; border-bottom: 1px solid #465374;">
            <h3 class="product-desc font-class">Network Configuration  <span class="order-st"></span></h3>
        </div>

          <div *ngIf="accountType == 'project' " class="form-control account-selection-content" style="margin-bottom: 20px;min-height: 300px !important;">
            <div>
            <div *ngIf="userLevel == '1' && enableTREFeature == false">
            <div class="selection" *ngFor="let network of networkConfig; let i = index" >
              <input class="" [checked]="network.checked" required type="checkbox"  [id]="network.value" name="network.value"
              (change)="selectNetworkConfig(network, $event, i); onFocus('vpc')" disabled>
              <label class="selection-label" [for]="network.value"
              style="display: block; margin-bottom: 5px;font-family: 'Nunito Sans', sans-serif;cursor: default;">{{ vendor == 'AWS' ? 'Use default VPC' : 'Use default VNet'}}</label>
              <span class="network-config">
                  Research Gateway uses the default {{ vendor == 'AWS' ? 'VPC' : 'VNet'}} for provisioning resources from the Standard Catalog.
                  If you check this option, Research Gateway will check if a default {{ vendor == 'AWS' ? 'VPC' : 'VNet'}} exists and will create one if it does not exist.
                  If you do not check this option, provisioning resources from Standard Catalog may fail.
                  You may still be able to provision resources which allow explicit selection of networking parameters like {{ vendor == 'AWS' ? 'VPC' : 'VNet'}}, Subnets, Security Groups etc. <span (click)="vpcReadMore()" (keypress)="vpcReadMore()" tabindex="0" class="read-more">Read more</span>
              </span>
            </div>
            </div>
            <div *ngIf="(userLevel == '1' && enableTREFeature == true) || userLevel == '2'">
              <div>
                <div class="selection-networkConfigType" *ngFor="let network of networkConfigType; let i = index" >
                  <input class="" [checked]="network.checked" required type="radio"  [id]="network.value+1" name="network.value"
                  (change)="selectNetworkConfigType(network, $event, i)" >
                  <label class="selection-label" [for]="network.value+1"  
                  style="display: block; margin-bottom: 5px;font-family: 'Nunito Sans', sans-serif;">{{network.viewValue}}</label>
                </div>
              </div>
              <span  class="network-configType" *ngIf="selectedNetworkType != 'Secure Research Environment'">
                Research Gateway uses the default VPC for provisioning resources from the Standard Catalog.
                If you check this option, Research Gateway will check if a default VPC exists and will create one if it does not exist.
                If you do not check this option, provisioning resources from Standard Catalog may fail.
                You may still be able to provision resources which allow explicit selection of networking parameters like VPC, Subnets, Security Groups etc.<span (click)="vpcReadMore()"  (keypress)="vpcReadMore()" tabindex="0" class="read-more">Read more</span>
             </span>
            <span class="network-configType" *ngIf="selectedNetworkType == 'Secure Research Environment'">
                Enabling the Secure Research option creates your resources in a secure, private VPC with no access to the internet. 
                The resources will use VPC endpoints to communicate with AWS services and this will have some associated costs. 
                If you choose this option, there are some manual steps that need to be run in your AWS account by your AWS Administrator. 
                Please contact support to get the details.
                <span (click)="treReadMOre()" (keypress)="treReadMOre()" tabindex="0" class="read-more">Read more</span>
            </span> 
            </div>
            <div class="selection" *ngIf="selectedNetworkType == 'Use default VPC'">
              <input class="" [checked]="needsALB" required type="checkbox"  [id]="needsALBName" name="needsALB"
              (change)="selectNeedsALBConfig($event);">
              <label class="selection-label" [for]="needsALBName"
              style="display: block; margin-bottom: 5px;font-family: 'Nunito Sans', sans-serif; ">Use SSL with ALB</label>
              <span class="network-config">
                Research Gateway can set up secure connections to your resources by putting them behind an Application Load Balancer with SSL connections using certificates managed by {{vendor}} Certificate Manager. Check this box if you would like to create an ALB for this project. An ALB will incur costs irrespective of traffic passing through it. <span (click)="sslReadMore()"  (keypress)="sslReadMore()" tabindex="0"  class="read-more">Read more</span>.
              </span>
            </div>
            <div class="selection" *ngIf="selectedNetworkType == 'Secure Research Environment'" >
              <input class="" [checked]="needsALB" required type="checkbox"  [id]="needsALBName" name="needsALB"
              (change)="selectNeedsALBConfig($event);" disabled>
              <label class="selection-label" [for]="needsALBName"
              style="display: block; margin-bottom: 5px;font-family: 'Nunito Sans', sans-serif; ;cursor: default;">Use SSL with ALB</label>
              <span class="network-config">
                Research Gateway can set up secure connections to your resources by putting them behind an Application Load Balancer with SSL connections using certificates managed by {{vendor}} Certificate Manager. Check this box if you would like to create an ALB for this project. An ALB will incur costs irrespective of traffic passing through it. <span (click)="sslReadMore()"  (keypress)="sslReadMore()" tabindex="0" class="read-more">Read more</span>.
              </span>
            </div>
          </div>
        </div>

        <!-- <div style="margin-top: 10px;margin-bottom: 10px; border-bottom: 1px solid #465374;">
          <h3 class="product-desc font-class">Storage Configuration  <span class="order-st"></span></h3>
      </div>

        <div class="form-control account-selection-content" style="margin-bottom: 515px;min-height: 150px !important;">
        <div>
          <div class="selection">
            <input class="form-check-input" [checked]="createProjectStorage" required type="checkbox"  [id]="createProjectStorageVal" name="createProjectStorage"
            (change)="checkCreateProjectStorage($event);" disabled>
            <label class="selection-label" [for]="createProjectStorageVal"
            style="display: block; margin-bottom: 5px;font-family: 'Nunito Sans', sans-serif;cursor: default;">Use Project Storage</label>
            <span class="network-config">
              Research Gateway will setup a shared S3 bucket (project storage) where the team members can store data. This shared storage will be mounted into all supported workspaces. Storage costs will be accounted at the project level. <span (click)="projectStorageReadMore()" class="read-more">Read more</span>
            </span>
          </div>
        </div>
      </div> -->

        </div>

      </div>
    <div class="helpsection">
      <h3 style="color: #3FCB57; font: normal normal 800 18px/32px Nunito Sans;">Help</h3>
      <!-- <ul style="padding: 0px;">
          <li [ngClass]="filedType == 'accountName' ? 'highlightText' : 'listValue-a'">Account Name : Enter a unique and descriptive account name.
            <br>
            <h3 class="listValue-a" style="color: #3FCB57 !important; margin-top: 10px;"> Example : Research account </h3>
          </li>
          <li [ngClass]="filedType == 'accessKey' ? 'highlightText' : 'listValue-a'">Access Key: Add access key of your AWS account.
              <h3 class="listValue-a" style="color: #3FCB57 !important; margin-top: 10px;">  Example : AKIAIOSFODNN7EXAMPLE.</h3>
            </li>
          <li [ngClass]="filedType == 'secretKey' ? 'highlightText' : 'listValue-a'" style="margin-top: 20px;">Secret Key: Add secret key of your AWS account.
            <h3 class="listValue-a" style="color: #3FCB57 !important; margin-top: 10px;">  Example : wJalrXUtnFEMI/K7M/bPxRfiCYEXAMPLEKEY </h3>
          </li>
          <li [ngClass]="filedType == 'region' ? 'highlightText' : 'listValue-a'" >Region: Select a region of your AWS account.
            <h3 class="listValue-a" style="color: #3FCB57 !important; margin-top: 10px;">  Example : us-east-1 </h3>
          </li>
          <li [ngClass]="filedType == 'accountNumber' ? 'highlightText' : 'listValue-a'" >Account Number: Add your AWS account number.
            <h3 class="listValue-a" style="color: #3FCB57 !important; margin-top: 10px;">  Example : 071938519317 </h3>
          </li>
        </ul> -->

        <div class="panel panel-default" *ngFor="let item of sectionWiseHelpdata; let k = index">
            <div class="panel-heading heading" (click)="accordianCtrlNodeX(k)"  (keypress)="accordianCtrlNodeX(k)" tabindex="0">
              <h5 class="panel-title">
                <table id="BotList-node" class="table table-striped" style="width: 100%;margin-bottom: unset">
                  <thead>
                    <tr>
                      <th class="accordian-header" style="width:18%;text-align: initial;"
                        [ngStyle]="{'background-color': '#06153E'}">
                        <span style="color: white; font: normal normal normal 13px/18px Nunito Sans;font-weight: 900">{{item.key}}</span></th>
                        <td class="accordian-header" style="width:1%"
                        [ngStyle]="{'background-color': '#06153E' }">
                        <em class='glyphicon' style="font-size: 20px;font-weight: 900;color: white"
                          [class.glyphicon-plus]="k != curx" [class.glyphicon-minus]="k == curx"
                          class="accordion-toggle"></em></td>
                    </tr>
                  </thead>
                </table>
              </h5>
            </div>
            <div id="collapse-node" class="panel-collapse" [class.collapse]='k != curx'>
              <table id="topology-info-node" class="table table-striped" style="width: 100%;">
                <tbody class="txt-align">
                  <tr>
                    <td style="padding-top:10px;width:20%;background:#030A1E;border: 1px solid #85d4ff;font: normal normal normal 13px/18px Nunito Sans;
                    white-space: pre-wrap !important;
                    color: #FFFFFF;" [innerHTML]="item.value">
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        <div class="panel panel-default" *ngFor="let item of helpTextData; let j = index">
          <div class="panel-heading heading" (click)="accordianCtrlNode(j)"  (keypress)="accordianCtrlNode(j)" tabindex="0">
            <h5 class="panel-title">
              <table id="BotList-node" class="table table-striped" style="width: 100%;margin-bottom: unset">
                <thead>
                  <tr>
                    <th class="accordian-header" style="width:17%;text-align: initial;"
                      [ngStyle]="{'background-color': '#06153E'}">
                      <span style="color: white; font: normal normal normal 13px/18px Nunito Sans;font-weight: 900">{{item.key}}</span></th>
                      <td class="accordian-header" style="width:1%"
                      [ngStyle]="{'background-color': '#06153E' }">
                      <em class='glyphicon' style="font-size: 20px;font-weight: 900;color: white"
                        [class.glyphicon-plus]="j != cur" [class.glyphicon-minus]="j == cur"
                        class="accordion-toggle"></em></td>
                  </tr>
                </thead>
              </table>
            </h5>
          </div>
          <div id="collapse-node" class="panel-collapse" [class.collapse]='j != cur'>
            <table id="topology-info-node" class="table table-striped" style="width: 100%;">
              <tbody class="txt-align">
                <tr>
                  <td style="padding-top:10px;width:20%;background:#030A1E;border: 1px solid #85d4ff;font: normal normal normal 13px/18px Nunito Sans;
                  white-space: pre-wrap !important;
                  color: #FFFFFF;" [innerHTML]="item.value">
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

    </div>
  </div>

   <!-- Mobile view -->
   <div class="addAccountHeader-mob">
    <tabset #addAccountTabs>
        <tab class="tab" heading="Add Account">
            <div class="addAccountSection">
                <div style="margin-top: 20px;margin-bottom: 20px; border-bottom: 1px solid #465374;">
                    <h3 class="product-desc font-class">Account Details</h3>
                    <h3 class="prod-c">Add the details of your AWS account.
                      For Research Gateway to create the roles required to manage resources on your behalf, the credentials need to have
                      <h3 class="prod-c-inline">IAMFullAccess</h3> permissions.</h3>
                </div>

              <div style="display: flow-root !important">
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="padding: 0px;margin-right: 15px;margin-bottom: 10px;">
                      <h3 class="prov-product-field text-white font-class" style="margin-bottom: 10px;">Account Name <span class="order-st">*</span>
                        <i class="fa fa-question-circle" aria-hidden="true" style="color: #FFB500;margin-left: 12px;"
                        (click)= "changeTab();onFocus('account_name')"></i>
                      </h3>
                      <input #sName="ngModel" [(ngModel)]="accountName" pattern="^\S.*$" class="inp-txt user-input" minlength="3" name="accountName"
                      placeholder="Please enter your account name - (required)" required type="text"
                      value="Service Name" (keypress)="highlightHelpText('accountName')"
                      (keyup)="highlightHelpText('accountName')" autocomplete="off" aria-label="AccountName">
                    </div>
              <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="padding: 0px;margin-top: 10px; margin-bottom: 10px;">
                        <h3 class="prov-product-field text-white font-class">Access Key <span class="order-st">*</span>
                          <i class="fa fa-question-circle" aria-hidden="true" style="color: #FFB500;margin-left: 12px;"
                          (click)= "changeTab();onFocus('access_key')"></i>
                        </h3>
                        <input [(ngModel)]="accessKey" class="inp-txt user-input" minlength="16" maxlength="128" name="accessKey"
                           placeholder="Please enter your access key - (required)"
                           required style="color: #ffffff" type="password" (keypress)="highlightHelpText('accessKey')"
                           (keyup)="highlightHelpText('accessKey')" (keydown.space)="$event.preventDefault()" autocomplete="off" aria-label="AccessKey">
              </div>
              <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="padding: 0px;margin-top: 10px;margin-bottom: 10px;">
                <h3 class="prov-product-field text-white font-class">Secret Key <span class="order-st">*</span>
                  <i class="fa fa-question-circle" aria-hidden="true" style="color: #FFB500;margin-left: 12px;"
                  (click)= "changeTab();onFocus('secret_key')"></i>
                </h3>
                <input aria-label="SecretKey" [(ngModel)]="secretKey" class="inp-txt user-input" minlength="16" maxlength="128" name="secretKey"
                   placeholder="Please enter your secret key - (required)"
                   required style="color: #ffffff" type="password"  (keypress)="highlightHelpText('secretKey')"
                   (keyup)="highlightHelpText('secretKey')" (keydown.space)="$event.preventDefault()">
              </div>
              <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="padding: 0px;margin-top: 10px;margin-bottom: 10px;">
                <h3 class="prov-product-field text-white font-class">Region <span class="order-st">*</span>
                  <i class="fa fa-question-circle" aria-hidden="true" style="color: #FFB500;margin-left: 12px;"
                  (click)= "changeTab();onFocus('region')"></i>
                </h3>
                <!-- <input [(ngModel)]="region" [disabled]="isEdit" class="inp-txt user-input" maxlength="14" name="region"
                  placeholder="Please enter your region - (required)" required pattern="(eu|sa|ca|ap|us)-(east|west|north|south|central)(east)*-[0-9]{1}"
                  style="color: #ffffff" type="text" (keypress)="highlightHelpText('region')"
                  (keyup)="highlightHelpText('region')" (keydown.space)="$event.preventDefault()"> -->
                  <select class="inp-txt user-input" required placeholder="Region selection" [(ngModel)]="region" name="region"
                   (change)="highlightHelpText('region')">
                  <option value="undefined" disabled selected >Region selection</option>
                  <option *ngFor="let region of regionList" [value]="region.value">{{region.key}}</option>
                </select>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="padding: 0px;margin-top: 10px;margin-bottom: 10px;">
                <h3 class="prov-product-field text-white font-class">Account Number <span class="order-st">*</span>
                  <i class="fa fa-question-circle" aria-hidden="true" style="color: #FFB500;margin-left: 12px;"
                  (click)= "changeTab();onFocus('account_number')"></i>
                </h3>
                <input [(ngModel)]="accountNumber"  class="inp-txt user-input" 
                
                [minlength]="vendor == 'AWS' ? 12 : 36"
                [maxlength]="vendor == 'AWS' ? 12 : 36"
                [pattern]="vendor == 'AWS' ? '^\d{12}$' : '^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}$'"
                name="accountNumber"
                placeholder="Please enter your account number - (required)" 
            
                required style="color: #ffffff" type="text" (keypress)="highlightHelpText('accountNumber')"
                (keyup)="highlightHelpText('accountNumber')" (keydown.space)="$event.preventDefault()" aria-label="AccountNumber">
              </div>
              <div class="form-control account-selection-content" style="margin-bottom: 25px;min-height: 170px !important;">
                  <div>
                    <div class="selection" *ngFor="let network of networkConfig; let i = index" >
                      <input class="" [checked]="network.checked" required type="checkbox"  [id]="network.value+2" name="network.value"
                      (change)="selectNetworkConfig(network, $event, i)" disabled>
                      <label class="selection-label" [for]="network.value+2"
                      style="display: block; margin-bottom: 15px;font-family: 'Nunito Sans', sans-serif;">{{ vendor == 'AWS' ? 'Use default VPC' : 'Use default VNet'}}</label>
                      <span class="network-config">
                          Research Gateway uses the default VPC for provisioning resources from the Standard Catalog.
                          If you check this option, Research Gateway will check if a default {{ vendor == 'AWS' ? 'VPC' : 'VNet'}} exists and will create one if it does not exist.
                          If you do not check this option, provisioning resources from Standard Catalog may fail.
                          You may still be able to provision resources which allow explicit selection of networking parameters like {{ vendor == 'AWS' ? 'VPC' : 'VNet'}}, Subnets, Security Groups etc.
                      </span>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </tab>
          <tab class="tab" heading="Help">
              <div class="helpSection-mob">
                  <h3 style="color: #3FCB57; font: normal normal 800 18px/32px Nunito Sans;">Help</h3>
                  <!-- <ul style="padding: 0px;">
                      <li [ngClass]="filedType == 'accountName' ? 'highlightText' : 'listValue-a'">Account Name : Enter a unique and descriptive account name.
                        <br>
                        <h3 class="listValue-a" style="color: #3FCB57 !important; margin-top: 10px;"> Example : Research account </h3>
                      </li>
                      <li [ngClass]="filedType == 'accessKey' ? 'highlightText' : 'listValue-a'">Access Key: Add access key of your AWS account.
                          <h3 class="listValue-a" style="color: #3FCB57 !important; margin-top: 10px;">  Example : AKIAIOSFODNN7EXAMPLE.</h3>
                        </li>
                      <li [ngClass]="filedType == 'secretKey' ? 'highlightText' : 'listValue-a'" style="margin-top: 20px;">Secret Key: Add secret key of your AWS account.
                        <h3 class="listValue-a" style="color: #3FCB57 !important; margin-top: 10px;">  Example : wJalrXUtnFEMI/K7M/bPxRfiCYEXAMPLEKEY </h3>
                      </li>
                      <li [ngClass]="filedType == 'region' ? 'highlightText' : 'listValue-a'" >Region: Select a region of your AWS account.
                        <h3 class="listValue-a" style="color: #3FCB57 !important; margin-top: 10px;">  Example : us-east-1 </h3>
                      </li>
                      <li [ngClass]="filedType == 'accountNumber' ? 'highlightText' : 'listValue-a'" >Account Number: Add your AWS account number.
                        <h3 class="listValue-a" style="color: #3FCB57 !important; margin-top: 10px;">  Example : 071938519317 </h3>
                      </li>
                    </ul> -->

                    <div class="panel panel-default" *ngFor="let item of sectionWiseHelpdata; let k = index">
                        <div class="panel-heading heading" (click)="accordianCtrlNodeX(k)">
                          <h5 class="panel-title">
                            <table id="BotList-node" class="table table-striped" style="width: 100%;margin-bottom: unset">
                              <thead>
                                <tr>
                                  <th class="accordian-header" style="width:18%;text-align: initial;"
                                    [ngStyle]="{'background-color': '#06153E'}">
                                    <span style="color: white; font: normal normal normal 13px/18px Nunito Sans;font-weight: 900">{{item.key}}</span></th>
                                    <td class="accordian-header" style="width:1%"
                                    [ngStyle]="{'background-color': '#06153E' }">
                                    <em class='glyphicon' style="font-size: 20px;font-weight: 900;color: white"
                                      [class.glyphicon-plus]="k != curx" [class.glyphicon-minus]="k == curx"
                                      class="accordion-toggle"></em></td>
                                </tr>
                              </thead>
                            </table>
                          </h5>
                        </div>
                        <div id="collapse-node" class="panel-collapse" [class.collapse]='k != curx'>
                          <table id="topology-info-node" class="table table-striped" style="width: 100%;">
                            <tbody class="txt-align">
                              <tr>
                                <td style="padding-top:10px;width:20%;background:#030A1E;border: 1px solid #85d4ff;font: normal normal normal 13px/18px Nunito Sans;
                                white-space: pre-wrap !important;
                                color: #FFFFFF;" [innerHTML]="item.value">
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                    <div class="panel panel-default" *ngFor="let item of helpTextData; let j = index">
                      <div class="panel-heading heading" (click)="accordianCtrlNode(j)">
                        <h5 class="panel-title">
                          <table id="BotList-node" class="table table-striped" style="width: 100%;margin-bottom: unset">
                            <thead>
                              <tr>
                                <th class="accordian-header" style="width:17%;text-align: initial;"
                                  [ngStyle]="{'background-color': '#06153E'}">
                                  <span style="color: white; font: normal normal normal 13px/18px Nunito Sans;font-weight: 900">{{item.key}}</span></th>
                                  <td class="accordian-header" style="width:1%"
                                  [ngStyle]="{'background-color': '#06153E' }">
                                  <em class='glyphicon' style="font-size: 20px;font-weight: 900;color: white"
                                    [class.glyphicon-plus]="j != cur" [class.glyphicon-minus]="j == cur"
                                    class="accordion-toggle"></em></td>
                              </tr>
                            </thead>
                          </table>
                        </h5>
                      </div>
                      <div id="collapse-node" class="panel-collapse" [class.collapse]='j != cur'>
                        <table id="topology-info-node" class="table table-striped" style="width: 100%;">
                          <tbody class="txt-align">
                            <tr>
                              <td style="padding-top:10px;width:20%;background:#030A1E;border: 1px solid #85d4ff;font: normal normal normal 13px/18px Nunito Sans;
                              white-space: pre-wrap !important;
                              color: #FFFFFF;" [innerHTML]="item.value">
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                </div>
          </tab>
      </tabset>
   </div>
   </form>
</div>
