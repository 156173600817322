<div class="container">
  <!-- breadcrumb -->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb bc-custom">
      <li class="breadcrumb-item"><a class="bc-item" href="researcher/studies">Studies</a></li>
      <li *ngIf="mode === '2'" class="breadcrumb-item"><a class="bc-item-active">Create New Study</a></li>
      <li *ngIf="mode === '1'"class="breadcrumb-item"(click)="routeBack()"><a class="bc-item-active">{{studyName}}</a></li>
      <li *ngIf="mode === '1'"class="breadcrumb-item"><a class="bc-item-active">Edit Study</a></li>
    </ol>
  </nav>
  <div class="product-name-layout">
    <h3 *ngIf="mode === '2'" class="product-name text-white font-class">Create New Study</h3>
    <h3 *ngIf="mode === '1'" class="product-name text-white font-class">Edit Study</h3>
  </div>
  <mat-stepper class="mat-stepper col-sm-10 col-md-10 col-lg-10 col-xs-10" [linear]="!isLinear" #stepper>
    <ng-template matStepperIcon="edit">
      <mat-icon style="font-weight: 600;">done</mat-icon>
    </ng-template>
    <mat-step [stepControl]="firstFormGroup">
      <form [formGroup]="firstFormGroup">
        <ng-template matStepLabel>
          <div class="stepper-label"> Study Details </div>
        </ng-template>
        <div class="col-sm-4 col-md-6 col-lg-10 col-xs-3" style="padding: 0px;margin-top: 10px;margin-right: 15px">
          <h3 class="prov-product-field text-white font-class" style="margin-top: 10px;margin-bottom: 10px;">Study Name
            <span class="order-st">*</span>
          </h3>
          <label for="studyName" style="visibility: hidden;">studyName</label>
          <input type="text" class="inp-txt user-input" [(ngModel)]="studyName" formControlName="studyNameCtrl"
            placeholder="Please enter Name of the study (required)" (keypress)="checkStudyName($event)" (keyup)="checkStudyName($event)"
            required  pattern="^([a-zA-Z0-9_-]*)$" minlength="3" maxlength="70" id="studyName">
          <div [ngClass]="defaultHelpClassStudyName" >
            Provide a name to help you easily identify this study. Only alphanumeric characters, hyphens and underscores are allowed. Spaces and special characters are not allowed.
          </div>

        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" id="descriptionId"
          style="padding: 0px;margin-top: 10px;margin-right: 15px">
          <h3 class="prov-product-field text-white font-class" style="margin-bottom: 10px;margin-top: 10px;">Description
            <span class="order-st">*</span>
          </h3>
          <label for="studyDescriptionId" class="visually-hidden">studyDescription</label>
          <input class="inp-txt user-input" id="studyDescriptionId"
            formControlName="descriptionCtrl" placeholder="Please enter description of study (required)"
            [(ngModel)]="studyDescription" required maxlength="256" rows="4" (keypress)="checkDescription()" (keyup)="checkDescription()">
          <div class="help-block"> Provide a description about the contents of this study </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="padding: 0px;margin-right: 15px;margin-top: 10px">
          <h3 class="prov-product-field text-white font-class" style="margin-bottom: 10px;">Study Type <span
              class="order-st">*</span></h3>
          <label class="wrap">
            <select [disabled]="isDisabled" class=" inp-txt user-input" id="studyTypeId" (change)="accessStudyType()"
            formControlName="studyTypeCtrl"
                    [ngClass]="{
            'placeholder-text': !selectedStudyType,
            'invalid-border': !firstFormGroup.get('studyTypeCtrl').valid ,
            'valid-border': firstFormGroup.get('studyTypeCtrl').valid
          }"

           required [(ngModel)]="selectedStudyType"
            name="selectedStudyType"  >

              <option   [ngValue]="undefined" disabled selected>Select a study type - (required)</option>
              <option selectedStudyType selected>Internal Study </option>
              <option *ngIf="vendor.toLocaleLowerCase()=='aws'" selectedStudyType selected>External Study</option>
            </select>
          </label>
          <div class="help-block" style="margin: 0px;"> An internal study uses an S3 bucket within the same AWS account as the project where the study is consumed. An external study uses an S3 bucket in a different AWS account from the project where it is consumed.</div>

        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="padding: 0px;margin-right: 15px;margin-top: 10px">
          <h3 class="prov-product-field text-white font-class" style="margin-bottom: 10px;">Access Level <span
              class="order-st">*</span></h3>
          <label class="wrap">
            <select [disabled]="isDisabled" class=" inp-txt user-input" id="acessLevelId" (change)="accessLevelType()"
              formControlName="accessCtrl" [ngClass]="{'placeholder-text': selectedAccessLevel === undefined}"
              style="border-left: 5px solid #a94442" required [(ngModel)]="selectedAccessLevel"
              name="selectedAccessLevel">
              <option [ngValue]="undefined" disabled selected>Select Access Level - (required)</option>
              <option selectedAccessLevel selected>Read Only</option>
              <option selectedAccessLevel selected>Read and Write</option>
            </select>
          </label>
          <div class="help-block" style="margin: 0px;"> Choose the desired access level for your studies. </div>

        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="padding: 0px;margin-right: 15px;margin-top: 10px">
          <h3 class="prov-product-field text-white font-class" style="margin-bottom:10px;"> Tags for this study

          </h3>
          <form #f="ngForm" novalidate (ngSubmit)="f.valid">
            <div *ngIf="tags.length < 15" class="col-lg-12 col-md-12" style="padding-left: 0px;">
              <label for="tagValueId" class="visually-hidden">tagValueId</label>
              <input minlength="3" maxlength="32" id="tagValueId" class="user-input kp-lab-text"
                pattern="^([a-zA-Z0-9+@._-]*)$" name="keyValue" style="border-left: 5px solid #42A948; color: #FFFFFF"
                type="text" placeholder="Enter value - (optional)" [(ngModel)]="keyValue"
                (keyup)="checkTagValue(f.valid)">
              <span> <i (click)="addTag(f.valid)" id="buttonClickId" class="fa fa-arrow-right kp-tag-ent"
                  (keyup)="checkTagValue(f.valid)"></i></span>
            </div>
          </form>
          <div class="col-lg-12 col-md-12" style="margin-top:5px;padding-left: 0px;margin-top: 10px;">
            <div *ngFor="let tag of tags" class="tag-t-s">
              <span class="tag-text" [title]="tag" *ngIf="tag.length > 6; else elseBlock"
                style="font-size: 14px;">{{tag.slice(0,6).concat("...")}}</span>
              <ng-template #elseBlock>
                <span class="tag-text" [title]="tag" style="font-size: 14px">{{tag}}</span>
              </ng-template>
              <i class="fa fa-times-circle tag-span-i" (click)="removeTag(tag)"></i>
            </div>
          </div>
        </div>
          <!-- <button class="launch-btn-sub text-white font-class" type="submit" style="cursor:hand" mat-button
            matStepperNext>Next</button> -->
          <button class="launch-btn-sub text-white font-class btn btn-primary btn-default"
            [disabled]="!(studyName && studyDescription && selectedAccessLevel)" mat-button matStepperNext
            name="button" type="submit"> Next </button>
      </form>
    </mat-step>
    <!-- This is for Bucket Details stepper -->
    <mat-step [stepControl]="secondFormGroup">

     <div *ngIf="vendor=='aws'">
      <form [formGroup]="secondFormGroup">
        <ng-template matStepLabel>
          <div class="stepper-label"> Bucket Details </div>
        </ng-template>
        <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="padding: 0px;margin-top: 10px;margin-right: 15px">
          <h3 class="prov-product-field text-white font-class" style="margin-top: 10px;margin-bottom: 10px;">

            Bucket Name
            <span class="order-st">*</span>
          </h3>
          <label for="bucketNameId" class="visually-hidden">bucketNameId</label>
          <input [disabled]="isDisabled" type="text" class="inp-txt user-input" id="bucketNameId"  [(ngModel)]="bucket" formControlName="buckctNameCtrl"
            placeholder="Please enter Name of the bucket (required)" required (keypress)="checkBucketName($event)" (keyup)="checkBucketName($event)"
            minlength="3" maxlength="63"  pattern="^([a-z0-9.-]*)$">
          <div [ngClass]="defaultHelpClassBucketName" style="margin: 0px;">
            Provide a bucket name that hosts the data. The bucket should already exist in AWS. Only lowercase letters, numbers, dots, hyphens are allowed. Spaces and special characters are not allowed.
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12"
          style="padding: 0px;margin-right: 15px;margin-top: 5px;display: flex;">
          <h3 class="prov-product-field text-white font-class" style="margin-bottom: 10px;">Use Custom Encryption
            <span class="order-st">*</span>
          </h3>
          <div id="selectYesId" class="active-btn-wrapper">
            <input [disabled]="isDisabled" class="form-check-input" type="checkbox" id="yes" name="yes" (change)="bucketEncrypted()" formControlName="encryptCtrl" [(ngModel)]="encrypted">
            <label class="selection-yes" for="yes">yes</label>
        </div>
        </div>
        <div class="help-block" style="margin: 0px;"> Select the checkbox to use customer encryption.</div>
        <div id="enterkeyId" *ngIf="encrypted" class="col-sm-12 col-md-12 col-lg-12 col-xs-12"
          style="padding: 0px;margin-right: 15px;">
          <h3 class="prov-product-field text-white font-class" style="margin-bottom: 10px;margin-top: 20px;">KMS Arn (In
            Study Account)<span class="order-st">*</span></h3>
          <input class="inp-txt user-input" id="arnId" [(ngModel)]="kmsArn" [required]="kmsArnScope" type="text" name="encryptedKey"
            placeholder="Please give the encrypted key" style="padding-left: 20px;padding-top: 10px;"  (keypress)="validateKmsArnValue()" (keyup)="validateKmsArnValue()"
             formControlName="kmsArnCtrl" >
          <div [ngClass]="defaultHelpClassKmsArn" style="margin-bottom: 10px;">
            Provide a valid KMS ARN value for the custom key. e.g. arn:aws:kms:us-east-1:111122223333:key/123456789-abcdef           </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="padding: 0px;margin-right: 15px; margin-top: 10px;">
          <h3 class="prov-product-field text-white font-class" style="margin-bottom: 10px;">Prefix <span
              class="order-st">*</span>
          </h3>
          <label for="prefixId" class="visually-hidden">prefixId</label>
          <input [disabled]="isDisabled" style="padding-left: 10px;padding-top: 10px;padding-bottom: 10px" id="prefixId"
            [(ngModel)]="prefix" class="inp-txt user-input" type="text" name="prefix" required
            placeholder="Please give that directory path (required)" (keypress)="checkPrefix($event)" (keyup)="checkPrefix($event)"
            formControlName="prefixCtrl" maxlength="63"  pattern="^([a-zA-Z0-9./_-]*)$">
          <div [ngClass]="defaultHelpClassPrefix" style="margin: 0px;">
            Provide a location within the bucket to which access is provided. Only Alphanumeric, underscore, hyphen, dot and forward slash are allowed. spaces and special characters are not allowed. Prefix should end with a forward slash character (/). The prefix should not correspond to an object name in the bucket. If no prefix is provided, the entire bucket will be accessible.          </div>
        </div>
              <!-- <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="padding: 0px;margin-right: 15px">
          <h3 class="prov-product-field text-white font-class" style="margin-bottom: 10px;margin-top: 20px;">Study
            Account
            <span class="order-st">*</span>
          </h3>
          <label class="wrap">
            <select class=" inp-txt user-input " [ngClass]="{'placeholder-text': selectedAccount === undefined}"
              style="border-left: 5px solid #a94442" required [(ngModel)]="awsAccount" id="studyAccountId"
              formControlName="accountCtrl" name="selectedAccount" (change)="checkStudyAccountValue();">
              <option [ngValue]="undefined" disabled selected>Select an account - (required)</option>
              <option *ngFor="let accNumber of accountNumber" [value]="accNumber">{{accNumber}}</option>
            </select>
          </label>
          <div class="help-block" style="margin: 0px;"> Enter the AWS account number of the above S3 bucket. The account number must be same as the Account you will be choosing the next page.
          </div>
        </div> -->
        <div>
          <button class="launch-btn-cancel text-white font-class" style="margin-right: 10px;" name="button" mat-button
            matStepperPrevious>Back</button>
          <button class="launch-btn-sub text-white font-class btn btn-primary btn-default" id = "buttonId"
            [disabled]="!(bucket && prefix && !kmsArnScope)" mat-button matStepperNext name="button"
            type="submit"> Next </button>
        </div>
      </form>
     </div>
     <div *ngIf="vendor=='azure'">
       <form [formGroup]="secondFormGroup">
         <ng-template matStepLabel>
           <div class="stepper-label">Azure Blob Storage Detail </div>
         </ng-template>
         <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="padding: 0px;margin-top: 10px;margin-right: 15px">
           <h3 class="prov-product-field text-white font-class" style="margin-top: 10px;margin-bottom: 10px;">

             Azure Blob Storage Name
             <span class="order-st">*</span>
           </h3>
           <label for="bucketNameId" class="visually-hidden">AzureNameId</label>
           <input [disabled]="isDisabled" type="text" class="inp-txt user-input" id="azurebucketNameId"  [(ngModel)]="bucket" formControlName="buckctNameCtrl"
                  placeholder="Please enter Name of the bucket (required)" required (keypress)="checkBucketName($event)" (keyup)="checkBucketName($event)"
                  minlength="3" maxlength="63"  pattern="^([a-z0-9.-]*)$">
           <div [ngClass]="defaultHelpClassBucketName" style="margin: 0px;">
             Provide detail,Only lowercase letters, numbers, dots, hyphens are allowed. Spaces and special characters are not allowed.
           </div>
         </div>


         <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="padding: 0px;margin-right: 15px; margin-top: 10px;">
           <h3 class="prov-product-field text-white font-class" style="margin-bottom: 10px;">Container <span
             class="order-st">*</span>
           </h3>
           <label for="prefixId" class="visually-hidden">Id</label>
           <input [disabled]="isDisabled" style="padding-left: 10px;padding-top: 10px;padding-bottom: 10px" id="azureprefixId"
                  [(ngModel)]="prefix" class="inp-txt user-input" type="text" name="prefix" required
                  placeholder="Please give that directory path (required)"
                  (keypress)="checkPrefix($event)" (keyup)="checkPrefix($event)"
                  formControlName="prefixCtrl" maxlength="63"  pattern="^([a-zA-Z0-9./_-]*)$">
           <div [ngClass]="defaultHelpClassPrefix" style="margin: 0px;">
             Only Alphanumeric, underscore, hyphen, dot and forward slash are allowed.
             spaces and special characters are not allowed.
           </div>

           <div>
             <button class="launch-btn-cancel text-white font-class" style="margin-right: 10px;" name="button" mat-button
                     matStepperPrevious>Back</button>
             <button class="launch-btn-sub text-white font-class btn btn-primary btn-default" id = "azurebuttonId"
                     [disabled]="!(bucket && prefix && !kmsArnScope)" mat-button matStepperNext name="button"
                     type="submit"> Next </button>
           </div>
         </div>
       </form>

     </div>
      <!-- stepper for Account Details -->
    </mat-step>
    <mat-step [stepControl]="thirdFormGroup">
      <form #f="ngForm" novalidate (ngSubmit)="f.valid" [formGroup]="thirdFormGroup">
        <ng-template matStepLabel>
          <div class="stepper-label"> Account Details </div>
        </ng-template>
        <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="padding: 0px;margin-right: 15px;margin-top: 10px">
          <h3 class="prov-product-field text-white font-class" style="margin-bottom: 10px;">{{ studyType == 'internal' ? 'Project Account' : 'Study Account' }} <span
              class="order-st">*</span></h3>
          <label class="wrap">
            <select [disabled]="isDisabled" class="inp-txt user-input"
                    [ngClass]="{'placeholder-text': selectedAccount === undefined}"
                    required [(ngModel)]="selectedAccount" id="accountId"
                    (change)="studyType == 'internal' ? projectAccount() : selectedStudyAccount()"
                    formControlName="projectAccountCtrl" name="selectedAccount">
              <ng-container *ngIf="fAccountIdList.length !== 1">
                <option [ngValue]="undefined" disabled selected>Select an account - (required)</option>
                <ng-container *ngFor="let acc of fAccountIdList; let i=index">
                  <option *ngIf="acc.vendor.toLocaleLowerCase() === vendor.toLocaleLowerCase()" [value]="acc.accountId">{{ acc.name }}</option>
                </ng-container>
              </ng-container>
              <option *ngIf="fAccountIdList.length === 1 && fAccountIdList[0].vendor.toLocaleLowerCase() === vendor.toLocaleLowerCase()"
                      [value]="fAccountIdList[0].accountId" selected>{{ fAccountIdList[0].name }}</option>
            </select>
          </label>
          <div class="help-block" style="margin: 0px;"> Choose the account configured as settings in RG to which you want the study to be mapped to.</div>
        </div>

        <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="padding: 0px;margin-right: 15px;margin-top: 5px">
          <h3 class="prov-product-field text-white font-class" style="margin-bottom: 10px;">Study Scope<span
              class="order-st">*</span></h3>
          <label class="wrap">
            <select class=" inp-txt user-input" id="studyScopeId"
             [ngClass]="{'placeholder-text': selectedStudyScope === undefined}"
              style="border-left: 5px solid #42A948" required [(ngModel)]="selectedStudyScope"
              name="selectedStudyScope">
              <!-- <option [ngValue]="undefined" disabled >Select a study Scope - (required)</option> -->
              <!-- <option selectedStudyScope="personal">My Study</option> -->
              <option [ngValue]="undefined">Project Level Study</option>
            </select>
          </label>
          <div class="help-block" style="margin: 0px;">
            Leave this as Project level. All members of the selected projects will have access to this study
          </div>
        </div>
        <div id="hideProjectId" class="col-sm-12 col-md-12 col-lg-12 col-xs-12"
          style="padding-left:0px;margin-right: 15px;margin-top: 5px;">
          <h3 class="prov-product-field text-white font-class" style="margin-bottom: 10px;">Projects
            <span class="order-st">*</span>
          </h3>
          <div class="form-control account-selection-content" style="margin-bottom: 10px;">
            <span class="selection-header">Select Projects from the list</span>
            <div *ngIf="showProjectList.length == 0" class="selection">
              <h3 class="noData">
                You have no projects. Create a project from the My Projects screen first.
              </h3>
            </div>
            <div class="selection" *ngFor="let project of showProjectList; let i=index">
              <input type="checkbox" [checked]="project.checked" [id]="project.projectId" name="project" required
               (change)="selectProjectIds(project.projectId, i, $event);"  >
              <label [for]="project.projectId"  class="selection-label">{{project.projectName}}</label>
            </div>
          </div>
          <div class="help-block" style="margin-bottom:10px;"> Choose the projects to which the study needs to be assigned to. Users assigned to these projects will be able to access this study.</div>
        </div>
        <div>
          <button class="launch-btn-cancel text-white font-class" style="margin-right: 10px;" name="button" mat-button
            matStepperPrevious>Back</button>
          <button *ngIf="mode === '2'" class="launch-btn-sub text-white font-class btn btn-primary btn-default" (click)="createStudy()"
            [disabled]="!(selectedAccount)" name="button" type="submit"
            style="cursor:hand">Register Study</button>
            <button *ngIf="mode === '1'" class="launch-btn-sub text-white font-class btn btn-primary btn-default" (click)="updateStudy()"
            [disabled]="!(nameScope || descriptionScope || tagScope || projectScope)" name="button" type="submit"
            style="cursor:hand">Update Study</button>
        </div>
      </form>
    </mat-step>
  </mat-stepper>
</div>
