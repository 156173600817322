import { Component, OnInit, Inject} from '@angular/core';
import { MdlDialogReference } from '@angular-mdl/core';
import { MdlDialogService } from '@angular-mdl/core';
import { ResearchService } from "../../_services/research.service";
import { SuccessMessageComponent } from '../../dialog/success-message/success-message.component';
import { ToastrService } from 'ngx-toastr';
import {Globals} from "../../globals";
import { Router} from '@angular/router';

@Component({
  selector: 'app-link-sagemaker',
  templateUrl: './link-resource.component.html',
  styleUrls: ['./link-resource.component.css'],
  providers: [Globals]
})
export class LinkSagemakerComponent implements OnInit {

  accountId: any;
  activeSageMakerTotalrecords;
  activeSageMakerNotebooks = [];
  selectedValue: any = '';
  ppId: any;
  prodDetails;
  showSpinner: boolean = false;

  constructor(public dialogRef: MdlDialogReference,
    private dialog: MdlDialogService,
    @Inject('data') public data: any,
    private researchService: ResearchService,
    private toastr: ToastrService,
    private router : Router,
    private globals: Globals) { }

  ngOnInit() {

    if(this.data.hasOwnProperty('activeSageMakerNotebooks')){
      this.activeSageMakerNotebooks = this.data.activeSageMakerNotebooks;
    }

    if(this.data.hasOwnProperty('ppId')){
      this.ppId = this.data.ppId;
    }

    if(this.data.hasOwnProperty('prodDetails')){
      this.prodDetails = this.data.prodDetails;
    }

  }

  cancel(): void {
    this.dialogRef.hide();
  }

  linkNotebook(){
    this.showSpinner = true;
    let provisionedProductId = this.activeSageMakerNotebooks.filter(x => x.productName == this.selectedValue)[0].provisionedProductId;
    let productName = this.activeSageMakerNotebooks.filter(x => x.productName == this.selectedValue)[0].name;
    let linkobj = {
        "ppId": this.ppId,
        "selectedInstanceObject":{
        "productName": this.selectedValue,
        "provisionedProductId": provisionedProductId,
        "name": productName,
        "productType":"SageMaker"
        }

    };
    let prodDetails = {
      "ppId": this.ppId,

    }

    this.researchService.linkStudyWithSagemaker(linkobj).subscribe(res => {
      if(res){
        this.showSpinner = false;
        this.dialogRef.hide();
        this.dialog.showCustomDialog({
          component: SuccessMessageComponent,
          providers: [{ provide: 'data', useValue: { ppId: this.ppId, action: 'linkSagemaker', prodDetails: this.prodDetails} }],
          isModal: true,
          styles: {
            "height":"450px",
            "width":"400px",
            "border": "1px solid #FFFFFF33",
            "border-radius": "10px",
            "background-color": "#07102A",
            "padding": "0px"
          },
          clickOutsideToClose: true,
          enterTransitionDuration: 400,
          leaveTransitionDuration: 400,
        })
      }
    }, error => {
      this.showSpinner = false;
      this.dialogRef.hide();
      let errBody = error.error;
      if(error && error.status === 401){
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }else {
          this.toastr.error(`Link resource - `+errBody.message, 'Error', this.globals.tosterOverride)
       }
    });
  }

}
