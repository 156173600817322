import { Component, OnInit, Inject } from '@angular/core';
import { MdlDialogService } from '@angular-mdl/core';
import { MdlDialogReference } from '@angular-mdl/core';
import { ResearchService } from "../../_services/research.service";
import { DashboardService } from "../../_services/dashboard.service";
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {Globals} from "../../globals";
import { ConformationDialogComponent } from './../conformation-dialog/conformation-dialog.component';

@Component({
  selector: 'app-update-egress-store-details',
  templateUrl: './update-egress-store-details.component.html',
  styleUrls: ['./update-egress-store-details.component.css'],
  providers: [DashboardService,Globals]
})
export class UpdateEgressStoreDetailsComponent implements OnInit {

  settingId;
  egressStoreBucketArn;
  egressStoreKmsArn;
  egressStoreBucketName;
  egressStoreNotificationBucketName;
  egressStoreName;
  egressSNSTopic;
  egressRegion;
  egressStoreDetails;
  disable = true;
  showDisableEgressButton = false;
  regionList = [];
  accountNumber;
  region;

  constructor( @Inject('data') public data: any,
  public dialogRef: MdlDialogReference,
  private researchService: ResearchService,
  private dialog: MdlDialogService,
  private router: Router,
  private toastr: ToastrService,
  private globals: Globals,
  private dashboardService: DashboardService 
  ) { }

  ngOnInit(): void {
    this.getRegionsList();
    if (this.data != undefined) {
      this.settingId = this.data.accountId;
      this.accountNumber = this.data.accountNumber;
      this.region = this.data.region;
      this.populateData();
    }
  }

  cancel(): void {
    this.dialogRef.hide();
  }

  populateData(){
    this.dashboardService.getSettingsDetails(this.settingId).subscribe((res:any) => {
      this.egressStoreDetails = res.egressStoreDetails;
      if(this.egressStoreDetails.egressStoreBucketArn || this.egressStoreDetails.egressStoreKmsArn || this.egressStoreDetails.egressStoreBucketName ||
        this.egressStoreDetails.egressStoreNotificationBucketName || this.egressStoreDetails.egressStoreName || 
        this.egressStoreDetails.egressSNSTopic || this.egressStoreDetails.region || res.isEgressStoreEnabled == true) {
        this.showDisableEgressButton = true
      }
      if(this.egressStoreDetails.egressStoreBucketArn) {
        this.egressStoreBucketArn = this.egressStoreDetails.egressStoreBucketArn;
      }
      if(this.egressStoreDetails.egressStoreKmsArn) {
        this.egressStoreKmsArn = this.egressStoreDetails.egressStoreKmsArn;
      }
      if(this.egressStoreDetails.egressStoreBucketName) {
        this.egressStoreBucketName = this.egressStoreDetails.egressStoreBucketName;
      }
      if(this.egressStoreDetails.egressStoreNotificationBucketName) {
        this.egressStoreNotificationBucketName = this.egressStoreDetails.egressStoreNotificationBucketName;
      }
      if(this.egressStoreDetails.egressStoreName) {
        this.egressStoreName = this.egressStoreDetails.egressStoreName;
      }
      if(this.egressStoreDetails.egressSNSTopic) {
        this.egressSNSTopic = this.egressStoreDetails.egressSNSTopic;
      }
      if(this.egressStoreDetails.region) {
        this.egressRegion = this.egressStoreDetails.region;      
      }         
    }) 
  }

  getRegionsList(){
    this.researchService.getAddAccountHelpText('', 'region').subscribe(res => {
      let regionArray = res[0].helpText;
      for(let region of regionArray){
        this.regionList.push(region.value);
      }     
    }, error => {
      if(error && error.status === 401){
        let errBody = error.error;
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
      }
    })
  }

  checkEgressStoreBucketArn(){
    let bucketArn = this.egressStoreBucketArn.split(":::");
    let bucketArnPrefix = bucketArn[0];
    let bucketName = bucketArn[1];
    let bucketNameRegexFormat = /^[a-z0-9][a-z0-9.-]*$/;
    let isRegexFormatMatch = bucketNameRegexFormat.test(bucketName);
    if (bucketArnPrefix !== "arn:aws:s3" || !isRegexFormatMatch) {
      const al = document.getElementById("egressStoreBucketArnId");
      al.style.borderLeft = '5px solid #F32561';
      this.disable = true;
    } else {  
      const al = document.getElementById("egressStoreBucketArnId");
      al.style.borderLeft = '5px solid #42A948';
      this.disable = false;
    }
  }

  checkEgressStoreKmsArn() {
    let kmsArnRegexFormat = /^arn:aws:kms:\w+(?:-\w+)+:\d{12}:key\/[A-Za-z0-9]+(?:-[A-Za-z0-9]+)+$/;;
    let isRegexFormatMatch = kmsArnRegexFormat.test(this.egressStoreKmsArn);
    // Extract the region from egressStoreKmsArn input field
    let kmsArnRegion = this.egressStoreKmsArn.match(/([a-z]+-[a-z]+-\d+)/);
    // To extract the account number from egressStoreKmsArn input field
    let kmsArnAccountNumber = this.egressStoreKmsArn.match(/(\d{12})/);
    if (isRegexFormatMatch == true && kmsArnAccountNumber[0] == this.accountNumber && kmsArnRegion[0] == this.region) {
      const al = document.getElementById("egressStoreKmsArnId");
      al.style.borderLeft = '5px solid #42A948';
      this.disable = false;
    } else {
      const al = document.getElementById("egressStoreKmsArnId");
      al.style.borderLeft = '5px solid #F32561';
      this.disable = true;
    }
  }

  checkEgressStoreBucketName(){
    let bucketNameRegexFormat = /^[a-z0-9][a-z0-9.-]*$/;
    let isRegexFormatMatch = bucketNameRegexFormat.test(this.egressStoreBucketName);
    if (!isRegexFormatMatch) {
      const al = document.getElementById("egressStoreBucketNameId");
      al.style.borderLeft = '5px solid #F32561';
      this.disable = true;
    } else {  
      const al = document.getElementById("egressStoreBucketNameId");
      al.style.borderLeft = '5px solid #42A948';
      this.disable = false;
    }
  }

  checkEgressStoreNotificationBucketName(){
    let notificationBucketNameRegexFormat = /^[a-z0-9][a-z0-9.-]*$/;
    let isRegexFormatMatch = notificationBucketNameRegexFormat.test(this.egressStoreNotificationBucketName);
    if (!isRegexFormatMatch) {
      const al = document.getElementById("egressStoreNotificationBucketNameId");
      al.style.borderLeft = '5px solid #F32561';
      this.disable = true;
    } else {  
      const al = document.getElementById("egressStoreNotificationBucketNameId");
      al.style.borderLeft = '5px solid #42A948';
      this.disable = false;
    }
  }

  checkEgressStoreName(){
    let egressStoreNameRegexFormat = /^[A-Za-z0-9][A-za-z0-9_.-]*$/;
    let isRegexFormatMatch = egressStoreNameRegexFormat.test(this.egressStoreName);
    if (!isRegexFormatMatch) {
      const al = document.getElementById("egressStoreNameId");
      al.style.borderLeft = '5px solid #F32561';
      this.disable = true;
    } else {  
      const al = document.getElementById("egressStoreNameId");
      al.style.borderLeft = '5px solid #42A948';
      this.disable = false;
    }
  }

  checkEgressSNSTopic(){
    let snsTopicRegexFormat = /^arn:aws:sns:\w+(?:-\w+)+:\d{12}:[a-zA-Z0-9_-]+$/;
    let isRegexFormatMatch = snsTopicRegexFormat.test(this.egressSNSTopic);
     // Extract the region from SNS topic input field
     let SNSTopicRegion = this.egressSNSTopic.match(/([a-z]+-[a-z]+-\d+)/);
     // To extract the account number from SNS topic input field
     let SNSTopicAccountNumber = this.egressSNSTopic.match(/(\d{12})/);
     if (isRegexFormatMatch == true && SNSTopicAccountNumber[0] == this.accountNumber && SNSTopicRegion[0] == this.region) {
      const al = document.getElementById("egressSNSTopicId");
      al.style.borderLeft = '5px solid #42A948';
      this.disable = false;
    } else {
      const al = document.getElementById("egressSNSTopicId");
      al.style.borderLeft = '5px solid #F32561';
      this.disable = true;
    }
  }

  checkRegion(){
    if(this.regionList.includes(this.egressRegion)){
      const al = document.getElementById("regionId");
      al.style.borderLeft = '5px solid #42A948';
      this.disable = false;  
    } else {
      const al = document.getElementById("regionId");
      al.style.borderLeft = '5px solid #F32561';
      this.disable = true;  
    }     
  }

  openConformationDialog(){
    this.dashboardService.getDialogBox().subscribe((data: any) => {
      if (data.status === 200) {
        this.dialogRef.hide();
        this.dialog.showCustomDialog({
          component: ConformationDialogComponent,
          providers: [{ provide: 'data', useValue: { accountId: this.settingId } }],
          isModal: true,
          styles: {
            "height":"auto",
            "width":"40%",
            "border": "1px solid #FFFFFF33",
            "border-radius": "10px",
            "background-color": "#07102A",
            "padding": "0px"
          },
          clickOutsideToClose: true,
          enterTransitionDuration: 400,
          leaveTransitionDuration: 400,
        })
      }
    }, error => {
      if (error && error.status === 401) {
        let errBody = error.error;
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
      }
    })    
  }

  updateEgressStoreDetails(){
    let updateObject = {  
      settingId: this.settingId, 
      isEgressStoreEnabled : true,    
      egressStoreBucketArn : this.egressStoreBucketArn,
      egressStoreKmsArn: this.egressStoreKmsArn,
      egressStoreBucketName: this.egressStoreBucketName,
      egressStoreNotificationBucketName: this.egressStoreNotificationBucketName,
      egressStoreName: this.egressStoreName,
      egressSNSTopic: this.egressSNSTopic,
      region: this.egressRegion
    };
    let t = new Date().getTime();
    this.researchService.updateSettings(updateObject, "updateEgressStoreDetails").subscribe( response =>{
      this.dialogRef.hide();
      this.router.navigate([`/setting`],{ queryParams: { showTab:'setting', updatedData: t}});
      this.toastr.success('The egress store details has been updated successfully', '', this.globals.tosterOverride);
    }, error => {
      this.dialogRef.hide();
      let errorMes = error.error;
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
        }else if (errorMes && (errorMes.message)) {
        this.toastr.error('', errorMes.message, { tapToDismiss: false, timeOut: 0, extendedTimeOut: 0, closeButton : true });
      }else{
        this.toastr.error('Error in update egress store details to the setting', '', this.globals.tosterOverride);
      }
    }); 
  }  
  
}