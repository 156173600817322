import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import 'rxjs/add/operator/map';
import { Observable, Subject } from 'rxjs';
// import { HttpInterceptor } from './httpInterceptor.service';


const headers = new HttpHeaders();
headers.append('Content-Type', 'application/json');
const options = {
  headers: headers
}
const csvHeaders = new HttpHeaders();
headers.append('Content-Type', 'application/ms-excel');
const csvOptions = {
  headers: csvHeaders, responseType: 'text' as 'json'
}

@Injectable()
export class ResearchService {
  private keypairListRefresh = new Subject();
  private routeChanges = new Subject();
  private checkSelection = new Subject();

  constructor(private http: HttpClient, private _zone: NgZone) { }

  sendKeypairCreateEvent() {
    this.keypairListRefresh.next('');
  }

  getKeypairCreateEvent(): Observable<any>{
    return this.keypairListRefresh.asObservable();
  }
  getProjects() {
    return this.http.get('/getProjects', options);
  }

  getPrincipals() {
    return this.http.get('/getPrincipals', options);
  }

  getAccounts() {
    return this.http.get('/getAccounts', options);
  }

  getPrincipalAccounts(){
    return this.http.get('/getAccountList', options);
  }

  getResearcherList() {
    return this.http.get('/getResearcherList', options);
  }

  getResearcherListByProject(projecId) {
    return this.http.get('/getResearcherList/'+projecId, options);
  }

  getValidateResearcherList(projectId) {
    return this.http.get(`/researchProjects/getValidateResearcherList/${projectId}`, options);
  }

  getResearcherListFromProjectId(projectId) {
    return this.http.get(`/getResearcherWiseBudgetByProjectId/${projectId}`, options);
  }

  getPrincipalProjects(page, limit) {
    return this.http.get(`/getPrincipalProjects?page=${page}&limit=${limit}`, options);
  }

  getPrincipalProjectsUsingId(projectId) {
    return this.http.get(`/getPrincipalProjectUsingId/${projectId}`, options);
  }
  
  getPrincipalProjectsUsingIdForAdmin(accountId, orgId) {
    return this.http.get(`/getPrincipalProjectUsingIdForAdmin/${accountId}/${orgId}`, options);
  }
  getPrincipalProjectsBudget(projectId, orgId) {
    return this.http.get(`/getPrincipalProjectBudget/${projectId}/${orgId}`, options);
  }

  getOrganizations() {
    return this.http.get('/getOrganisationBudget', options);
  }

  exportAdminBudgetAsCSV() {
    return this.http.get('/exportcsvforadmin', csvOptions);
  }

  downloadRdpFile(ipAddress, userName){
    return this.http.get(`/instances/rdp/${ipAddress}/${userName}`, csvOptions);
  }

  exportPIBudgetAsCSV() {
    return this.http.get('/exportcsvforPI', csvOptions);
  }

  getOrganizationProjects(orgId) {
    return this.http.get(`/getProjectWiseBudgetOrgId/${orgId}`, options);
  }

  getResearcherProducts() {
    return this.http.get('/getResearcherProducts', options);
  }

  getResearcherBudget() {
    return this.http.get('/getResearcherBudgetSplit', options);
  }

  getProductBudget(data){
    return this.http.post(`/getProductWiseBudget`, data);
  }

  getResearcherWiseBudget(){
    return this.http.get(`/getResearcherLevelBudget`, options);
  }

  getListOfResearchProducts(page, limit, accountId, projectId, productType) {
    return this.http.get(`/getResearchProducts?page=` + page + `&limit=` + limit + `&id=` + accountId +`&projectId=`+ projectId +`&productType=` + productType, options);
  }

  getListOfSearchResearchProducts(searchKey , page, limit, accountId, projectId, productType) {
    return this.http.get(`/getSearchResearchProducts?searchData=` + searchKey + `&page=` + page + `&limit=` + limit + `&id=` + accountId + `&projectId=` + projectId +`&productType=`+productType, options);
  }

  getSearchProvisionedProducts(page, limit, accountId, projectId, filterBy, searchKey, getAllProducts) {
    return this.http.get(`/getSearchedProvisionedProducts?page=` + page + `&limit=` + limit + `&id=` + accountId + `&projectId=` + projectId +`&filterBy=`+ filterBy +`&searchKey=` + searchKey + `&getAllProducts=` + getAllProducts, options);
  }

  getResearchProductsParameters(productId, productName, projectId, stdCatalogitemId) {
    return this.http.get(`/getResearchProductsParameters?productId=` + productId + `&productName=` + productName + `&projectId=` + projectId + '&stdCatalogitemId=' + stdCatalogitemId, options);
  }
  postProvisionProduct(data) {
    return this.http.post(`/provisionProduct`, data);
  }

  updateProvisionProduct(data) {
    return this.http.put(`/updateProvisionedProduct`, data);
  }
  
  postProject(data) {
    return this.http.post(`/addProject`, data);
  }

  getProvisionedProducts(page, limit, accountId, projectId) {
    return this.http.get(`/getProvisionedProducts?page=` + page + `&limit=` + limit + `&id=` + accountId + `&projectId=` + projectId, options);
  }

  getFilteredProvisionedProducts(page, limit, accountID, projectId, filterBy, getAllProducts) {
    return this.http.get(`/filterProvisionedProducts?page=` + page + `&limit=` + limit + `&id=` + accountID + `&projectId=`+ projectId + `&filterBy=` + filterBy + `&getAllProducts=` + getAllProducts, options);
  }

  deleteProvisionedProducts(deleteObj){
    return this.http.delete(`/research-product?ProvisionedProductId=${deleteObj.ProvisionedProductId}&account_id=${deleteObj.account_id}`,);
  }

  syncProduct(settingDetails){
    return this.http.get(`/sync-product/${settingDetails._id}/${settingDetails.projectId}`, options);
  }

  getProvisionedProductsAuditTrail(page, limit, provisionedProductID) {
    return this.http.get(`/provisionedProductLogs?page=` + page + `&limit=` + limit + `&id=` + provisionedProductID, options);
  }

  getProvisionedProductDetails(ppId){
    return this.http.get(`getProvisionedProducts?ppId=${ppId}`);
  }

  updateProductStatus(data){
    return this.http.post(`/updateProductStatus`, data);
  }

  getResearchersFromProject(projectId, accountId) {
    return this.http.get(`/getProjectResearcherList?projectId=`+ projectId +`&account_id=`+accountId, options);
  }

  updateProjectStatus(projectData){
    return this.http.put(`/projectActions`, projectData);
  }

  updateAddProductForProject(data){
    return this.http.put(`/updateAddProduct`, data);
  }

  updateResearchers(payload){
    return this.http.put(`/researchProjects/projectResearchers`, payload);
  }

  updateProjectName(data){
    return this.http.put(`/researchProjects/projectName`, data);
  }
  
  getParameterAllowedValue(data) {
    return this.http.post(`/researchProducts/getParameterAllowedValue`, data);
  }

  getSecurityGroupsAndSubnet(vpcId) {
    return this.http.get(`/researchProducts/securityGroupsAndSubnet/${vpcId}`, options);
  }

  getServerSentEvent(eventSource) {
    return Observable.create(observer => { 
      eventSource.onopen = function (evt) {
        // handle newly opened connection 
      }
      eventSource.onmessage = event => {
        this._zone.run(() => {
          observer.next(event);
        });
      };
      eventSource.onerror = error => {
        this._zone.run(() => {          
          eventSource.close();
          observer.error(error);
        });
      };  
    });
  }

  getEventSource(url: string): EventSource {
    return new EventSource(url, { withCredentials: true });
  }

  getResearcherListForSharedS3(ppId){
    return this.http.get(`/getListOfResearcherForShared/?ppId=`+ppId, options);
  }

  getActiveSagemakerNotebooks(projectId, bucketName){
    return this.http.get(`/get-active-notebooks/`+ projectId +`/`+ bucketName, options);
  }

  linkStudyWithSagemaker(data){
    return this.http.post(`/link-study-with-sagemaker`, data);
  }

  unlinkStudyWithSagemaker(data) {
    return this.http.post(`/unlink-study-with-sagemaker`, data);
  }

  createKeypair(data) {
    return this.http.post(`/researchProducts/keypair`, data);
  }

  getKeypair(currentPage, limit, searchKey) {
    return this.http.get(`/researchProducts/keypair?page=${currentPage}&limit=${limit}&search=${searchKey}`, options);
  }

  getStudies(currentPage, limit) {
    return this.http.get(`/studies?page=${currentPage}&limit=${limit}`, options);
  }
  getExternalStudies() {
    return this.http.get(`/externalStudies`, options);
  }

  assignedStudies(name, id, studyType) {
    return this.http.get(`/assignedStudies?id=${id}&name=${name}&type=${studyType}`, options);
  }

  getFilterList() {
    return this.http.get(`/studies/filterList`, options);
  }

  bookmarkStudy(id) {
    return this.http.patch(`/studies/${id}`, null , options);
  }

  filterStudies(page, limit, filterBy) {
    return this.http.get(`/studies?page=${page}&limit=${limit}&filterBy=${filterBy}`, options);
  }

  searchStudies(page, limit, search, filterBy) {
    return this.http.get(`/studies/search?page=${page}&limit=${limit}&search=${search}&filterBy=${filterBy}`, options);
  }

  getStudyDetails(studyId) {
    return this.http.get(`/study/${studyId}`, options);
  }

  getStudyProductDetails(studyId) {
    return this.http.get(`/studies/studyProductDetails?id=${studyId}`, options);
  }

  getStudyProject(projectId) {
    return this.http.get(`/getStudyProject?projectId=` + projectId,options);
  }

  getStudyResources(reqObject) {
    return this.http.get(`/studies/computeResources?bucket=${reqObject.arn}`, options);
  }

  linkStudyResource(data) {
    return this.http.put(`/studies/link`, data, options);
  }

  unlinkStudyResource(data) {
    return this.http.put(`/studies/unlink`, data, options);
  }

  deleteStudy(data) {
    return this.http.put(`/studies/terminate`, data, options);
  }

  getAuditOrganisation(currentPage, limit, searchKey) {
    return this.http.get(`/getAuditTrail?page=${currentPage}&limit=${limit}&search=${searchKey}`, options);
  }

  getAuditDetails(currentPage, limit, searchKey, filSearch, filCat, filVal) {
    return this.http.get(`/getAuditTrail/getOrgAuditTrail?page=${currentPage}&limit=${limit}&search=${encodeURIComponent(searchKey)}
    ${filSearch}${filCat}${filVal}`, options);
  }

  getEntitlement(data) {
    return this.http.post(`/getEntitlement`, data);
  }

  getRegisterServiceUrl(orgId) {
    return this.http.get(`/getRegisterServiceUrl?orgId=` + orgId, options);
  }

  getResearcherListToShare(studyId){
    return this.http.get(`/studies/researchersList?id=${studyId}`, options);
  }

  shareStudy(data){
    return this.http.put(`/studies/share`, data, options);
  }

  //User Management Related APIs calls
  getOrganisationFilter() {
    return this.http.get(`/account/filter/organization`, options);
  }

  getRoleFilter() {
    return this.http.get(`/account/filter/role`, options);
  }

  getSortFilter() {
    return this.http.get(`/account/filter/sort`, options);
  }

  getUsers(currentPage, limit, filterParams) {
    return this.http.get(`/account/list?page=${currentPage}&limit=${limit}&${filterParams}`);
  }

  getSearchedUsers(currentPage, limit, serachTxt, filterParams) {
    return this.http.get(`/account/search?page=${currentPage}&limit=${limit}&search=${encodeURIComponent(serachTxt)}&${filterParams}`);
  }

  getOrganisationDropdown() {
    return this.http.get(`/account/filter/organization/add`, options);
  }

  addUser(data){
    return this.http.post(`/userCreation`, data);
  }

  updateUser(data){
    return this.http.post(`/editUser`, data);
  }
  addBulkUser(data){
    return this.http.post(`/userCreation/bulkUser`, data);
  }

  downloadSampleCSV(){
    return this.http.get(`/downloadSampleCSV`, options);
  }

  getUserList(orgId) {
    return this.http.get(`/researchProjects/getUserList?orgId=${orgId}`, options);
  }

  getCatalogItems(orgId){
    return this.http.get(`/listcatalogitems/${orgId}`, options);
  }

  filterCatalogItems(type, orgId){
    return this.http.get(`/filtercatalogitems/${type}?orgId=${orgId}`, options);
  }

  assignProductsToOU(data){
    return this.http.post(`/assignOrganization`, data);
  }

  listProductType(){
    return this.http.get(`/listtypeofproducts`, options)
      ;
  }

  getListOfProductTypes(){
    return this.http.get(`/getListOfProductTypes`, options)
      ;
  }

  getUnassignedOrganizations(){
    return this.http.get(`/getunassignedorganizations`, options);
  }

  searchCatalogItems(searchValue, filterType, orgId){
    return this.http.get(`/searchcatalogitems?search=${searchValue}&filterType=${filterType}&orgId=${orgId}`, options);
  }

  getAssignedProductsForProjects(projectId){
    return this.http.get(`/getassignedproductsforproject/${projectId}`, options);
  }

  searchPICatalogItems(searchValue, filterType, projectId){
    return this.http.get(`searchproductsforprincipal?productType=${filterType}&projectId=${projectId}&search=${searchValue}`, options);
  }

  assignProductsToProject(data){
    return this.http.post(`/assignProductsToProject`, data);
  }

  updateProductsToProject(data){
    return this.http.post(`/updateProductsToProject`, data);
  }

  assignOrg(userId, data) {
    return this.http.patch(`/account/${userId}/assign_org`, data , options);
  }

  enableUser(userId) {
    return this.http.patch(`/account/${userId}/enable`, null , options);
  }

  resendVerificationMail(userId) {
    return this.http.patch(`/account/${userId}/resend_verification_link`, null, options);
  }

  disableUser(userId) {
    return this.http.patch(`/account/${userId}/disable`, null , options);
  }

  getProjectsForPrincipal(){
    return this.http.get(`/getprojectsforprincipal`, options);
  }

  getUnassignedProjectsForPrincipal(){
    return this.http.get(`/getunassignedprojectsforprincipal`, options);
  }

  assignOrgToAccount(accountId, data) {
    return this.http.patch(`/settings/${accountId}/assign_org`, data , options);
  }

  getAddAccountHelpText(filterValue, configType){
    return this.http.get(`/getHelpText?key=${filterValue}&configType=${configType}`, options);
  }

  checkIfUserIsPI(){
    let level = sessionStorage.getItem('Level');
    if (Number(level) === 1  ) {
      return true;
    }else{
      return false;
    }
  }

  repairProject(projectId){
    return this.http.get(`/repairProject/${projectId}`, options);
  }

  archiveProject(projectId){
    return this.http.post(`/archiveProject`, projectId);
  }

  submitBudget(projectId){
    return this.http.post(`/submitBudget`, projectId);
  }

  createStudy(studyId){
    return this.http.post(`/createStudies`,studyId);
  }

  updateStudy(studyId){
    return this.http.post(`/editStudies`,studyId);
  }

  assignStudyToProjects(studyId){
    return this.http.post(`/assignStudyToProject`,studyId);
  }

  getNFCorePipeline() {
    return this.http.get('/getNFCorePipeline', options)
  }
  getCromwellPipeline() {
    return this.http.get('/researchProducts/cromwell/pipeline', options);
  }

  getResearchProduct(productId, productName, type, searchKey, projectId) {
    return this.http.get(`/researchProduct/` + projectId + `/` + productName + `/` + productId+`?type=${type}&searchKey=${searchKey}`, options);
  }

  getHelp() {
    return this.http.get('/getHelp', options);
  }

  getConfigData(configKey){
    return this.http.get(`/getConfigData/${configKey}`, options);
  }

  sendEvent() {
    this.routeChanges.next('');
  }

  getEvent(): Observable<any>{
    return this.routeChanges.asObservable();
  }

  sendSelectionEvent() {
    this.checkSelection.next('');
  }

  getSelectionEvent(): Observable<any>{
    return this.checkSelection.asObservable();
  }

  searchUsers(searchTxt) {
    return this.http.get(`/searchInResearcherList?search=${encodeURIComponent(searchTxt)}`);
  }
  
  getProvisionedProductAllowedInstanceTypes(ppId){
    return this.http.get(`/getProvisionedProductAllowedInstanceTypes/${ppId}`);  
  }
  getDailyBudget(provisionedProductId,filterSearchDate){
    return this.http.get(`/getDailyProductCosts/${provisionedProductId}?filterSearchDate=${filterSearchDate}`, options);  
  }

  updatedInstanceType(data) {
    return this.http.post(`/updateInstanceType`,data);
  }

  getAvailableAndProvisionedProductCount(page, limit, accountID, projectId, filterBy) {
    return this.http.get(`/getAvailableAndProvisionedProductCount?page=` + page + `&limit=` + limit + `&id=` + accountID + `&projectId=`+ projectId + `&filterBy=` + filterBy + `&productType=All`, options);
  }

  attachVolume(data){
    return this.http.post(`/attachVolume`, data);   
  }
  detachVolume(data){
    return this.http.post(`/detachVolume`, data);   
  }

  getAccountBills(page, limit,){
    return this.http.get(`/getAccountBills?page=` + page + `&limit=` + limit, options);
  }

  getEgressStoreFiles(ppId, reqBody){
    return this.http.put(`/egressStore/listFiles/${ppId}`, reqBody);
  }

  sendEgressRequest(reqBody){
    return this.http.post(`/egressStore/sendEgressRequest`, reqBody);
  }

  sendIngressRequest(reqBody){
    return this.http.put(`/sendIngressRequest`, reqBody);
  }

  listIngressPendingRequests(projectId){
    return this.http.get(`/listIngressPendingRequests?projectId=`+projectId, options);
  }

  getProjectTypeData(filterValue, configType){
    return this.http.get(`/getProjectTypeValue?key=${filterValue}&configType=${configType}`, options);
  }

  saveStudySettings(jsonData) {
    return this.http.post(`/createStudyAccount`, jsonData, options);
  }

  deleteExternalStudy(data) {
    return this.http.put(`/deleteExternalStudy`, data, options);
  }
   
  deleteStudyAccount(data) {
    return this.http.put(`/deleteStudyAccount`,data,  options);
  }
  
  updateSettings(updateObject,updateAction) {
    return this.http.patch(`/updateAccount/${updateAction}`, updateObject, options);
  }
}