import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ResearchService } from "../_services/research.service";
import { DashboardService } from "../_services/dashboard.service";
import { AddStudyComponent } from './add-study/add-study.component';
import { MdlDialogService } from '@angular-mdl/core';
import { Globals } from "../globals";

@Component({
  selector: 'app-studies',
  templateUrl: './studies.component.html',
  styleUrls: ['./studies.component.css', '../app.component.css'],
  providers: [Globals]
})
export class StudiesComponent implements OnInit {


  filters = [];
  studies = [];
  loader: boolean = false;
  pageLimit = 10;
  currentPage = 1;
  hasNextPage = false;
  selectedFilter = "All Studies";
  filterBy = "studyType:All Studies";
  searchKey = "";
  searchValueTemp;
  noDataTemplate = "No Studies Available";
  totalDoc;
  projectId;
  accountId;
  userType: any;
  defaultHelpClass = 'principal-login';
  defaultClass = 'principal-searchBar';
  defaultSearchClass = 'principal-mobile-search-div';
  defaultSearchInputClass = 'principal-mobile-search-inp-add';
  eventS = this.getEventSource(`/stream_events`);

  constructor(private route: ActivatedRoute, private researchService: ResearchService, private dialog: MdlDialogService, private router: Router, private toastr: ToastrService, private dashboardService: DashboardService, private apiService: DashboardService, private globals: Globals) { }

  ngOnInit() {
    this.apiService.checkIfResearcher();
    this.serverStreamEvent();
    if(this.route.snapshot.queryParams['filter']) {
      let selectedFilter = this.route.snapshot.queryParams['filter'];
      this.selectedFilter = selectedFilter.charAt(0).toUpperCase() + selectedFilter.slice(1);
      let filterBy = this.route.snapshot.queryParams['filter'];  
      this.filterBy = `studyType:${filterBy}`
      console.log ("filterBy = " , this.filterBy)

    }
   
    document.querySelector('.custom-select-wrapper').addEventListener('click', function () {
      this.querySelector('.custom-select').classList.toggle('open');
    })

    window.addEventListener('click', function (e) {
      const select = document.querySelector('.custom-select')
      if (select && !select.contains(e.target as Node)) {
        select.classList.remove('open');
      }
    });

    // mobile responsive dropdown
    document.querySelector('.mobile-custom-select-wrapper').addEventListener('click', function () {
      this.querySelector('.mobile-custom-select').classList.toggle('open');
    })
    window.addEventListener('click', function (e) {
      const select = document.querySelector('.mobile-custom-select')
      if (select && !select.contains(e.target as Node)) {
        select.classList.remove('open');
      }
    });
    // document.querySelector('.create-study-select-wrapper').addEventListener('click', function() {
    //   this.querySelector('.create-study-select').classList.toggle('open');
    // })
    // window.addEventListener('click', function(e) {
    //   const select = document.querySelector('.create-study-select')
    //   if (!select.contains(e.target as Node)) {
    //       select.classList.remove('open');
    //   }
    // });
    this.getFilterList();
    this.filterStudies();
    let userLevel = sessionStorage.getItem('Level');
    if (userLevel == "1") {
      this.userType = 'principal';
    } else {
      this.userType = 'researcher';
    }
    this.checkUserType();
    this.searchBarAllignment();
    this.mobilSearchBarAllignment();
    this.mobilSearchInputAllignment();
  }

  checkUserType() {
    if (this.userType == 'principal') {
      this.defaultHelpClass = 'principal-login';
    } else {
      this.defaultHelpClass = 'researcher-login';
    }
  }

  searchBarAllignment() {
    if (this.userType == 'principal') {
      this.defaultClass = 'principal-searchBar';
    } else {
      this.defaultClass = 'researcher-searchBar';
    }
  }

  mobilSearchBarAllignment() {
    if (this.userType == 'principal') {
      this.defaultSearchClass = 'principal-mobile-search-div';
    } else {
      this.defaultSearchClass = 'mobile-search-div';
    }
  }

  mobilSearchInputAllignment() {
    if (this.userType == 'principal') {
      this.defaultSearchInputClass = 'principal-mobile-search-inp-add';
    } else {
      this.defaultSearchInputClass = 'mobile-search-inp-add';
    }
  }
  detectMob() {
    return (window.innerWidth <= 800);
  }

  onChangeFilter(filter) {
    this.selectedFilter = filter.value;
    this.filterBy = `${filter.key}:${filter.value}`
    if (this.searchKey && this.searchKey.length > 2) {
      this.searchStudies();
      return;
    }
    this.filterStudies();
  }

  showToast() {
    this.toastr.error("Coming soon!");
  }

  getFilterList() {
    // this.loader = true;
    this.researchService.getFilterList().subscribe((data: any) => {
      //this.loader = false;
      this.filters = data.filterList;
    }, error => {
      //this.loader = false;
    })
  }

  // getStudies() {
  //   this.loader = true;
  //   this.researchService.getStudies(this.currentPage, this.pageLimit).subscribe(data => {
  //     this.loader = false
  //     this.studies = data.docs;
  //     this.hasNextPage = data.hasNextPage;
  //     this.studies.forEach((item) => {
  //       if(item.tags.length > 4) {
  //         item.showTags = item.tags.slice(0, 4);
  //         item.moreTagsCount = item.tags.length - 4;
  //         item.moreTags = item.tags.slice(4);
  //       }else {
  //         item.showTags = item.tags;
  //       }
  //     })
  //   }, error => {
  //     this.loader = false
  //   })
  // }

  loadMoreStudies() {
    this.currentPage++;
    this.loader = true;
    if (this.searchKey && this.searchKey.length > 2) {
      this.researchService.searchStudies(this.currentPage, this.pageLimit, this.searchKey, this.filterBy).subscribe((data: any) => {
        this.loader = false;
        this.hasNextPage = data.hasNextPage;
        const isMobile = this.detectMob();
        data.docs.forEach((item) => {
          if (isMobile) {
            if (item.tags.length > 3) {
              item.showTags = item.tags.slice(0, 2);
              item.moreTagsCount = item.tags.length - 2;
              item.moreTags = item.tags.slice(2);
            } else {
              item.showTags = item.tags;
            }
          } else {
            if (item.tags.length > 4) {
              item.showTags = item.tags.slice(0, 3);
              item.moreTagsCount = item.tags.length - 3;
              item.moreTags = item.tags.slice(3);
            } else {
              item.showTags = item.tags;
            }
          }
        })
        this.studies = this.studies.concat(data.docs);
      }, error => {
        this.loader = false
        if (error && error.status === 401) {
          let errBody = error.error;
          this.toastr.error(errBody.err, '', this.globals.tosterOverride);
          this.router.navigate(['login']);
        }
      })
    } else {
      this.researchService.filterStudies(this.currentPage, this.pageLimit, this.filterBy).subscribe((data: any) => {
        this.loader = false;
        this.hasNextPage = data.hasNextPage;
        const isMobile = this.detectMob();
        data.docs.forEach((item) => {
          if (isMobile) {
            if (item.tags.length > 3) {
              item.showTags = item.tags.slice(0, 2);
              item.moreTagsCount = item.tags.length - 2;
              item.moreTags = item.tags.slice(2);
            } else {
              item.showTags = item.tags;
            }
          } else {
            if (item.tags.length > 4) {
              item.showTags = item.tags.slice(0, 3);
              item.moreTagsCount = item.tags.length - 3;
              item.moreTags = item.tags.slice(3);
            } else {
              item.showTags = item.tags;
            }
          }
        })
        this.studies = this.studies.concat(data.docs);
      }, error => {
        this.loader = false
        if (error && error.status === 401) {
          let errBody = error.error;
          this.toastr.error(errBody.err, '', this.globals.tosterOverride);
          this.router.navigate(['login']);
        }
      })
    }
  }

  bookmarkStudy(study) {
    this.loader = true;
    this.researchService.bookmarkStudy(study._id).subscribe(data => {
      this.loader = false;
      study.isBookmarked = !study.isBookmarked;
    }, error => {
      this.loader = false;
      if (error && error.status === 401) {
        let errBody = error.error;
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
      }
    })
  }

  filterStudies() {
    this.currentPage = 1;
    this.loader = true;
    this.researchService.filterStudies(this.currentPage, this.pageLimit, this.filterBy).subscribe((data: any) => {
      this.loader = false;
      this.studies = data.docs;
      this.hasNextPage = data.hasNextPage;
      this.totalDoc = data.totalDocs;
      this.noDataTemplate = `No Studies Available`;
      const isMobile = this.detectMob();
      this.studies.forEach((item) => {
        if (isMobile) {
          if (item.tags.length > 3) {
            item.showTags = item.tags.slice(0, 2);
            item.moreTagsCount = item.tags.length - 2;
            item.moreTags = item.tags.slice(2);
          } else {
            item.showTags = item.tags;
          }
        } else {
          if (item.tags.length > 4) {
            item.showTags = item.tags.slice(0, 3);
            item.moreTagsCount = item.tags.length - 3;
            item.moreTags = item.tags.slice(3);
          } else {
            item.showTags = item.tags;
          }
        }
      })
    }, error => {
      this.loader = false
      if (error && error.status === 401) {
        let errBody = error.error;
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
      } else if (error && error.status === 403) {
          const err = error.error;
          this.toastr.error(err.error, 'Error', this.globals.tosterOverride);
          this.router.navigate(['pageNotFound']);
      }
    })
  }

  searchStudies() {
    const searchKey = this.searchKey.trim();
    if (this.searchValueTemp === searchKey) {
      return;
    }
    this.currentPage = 1;
    this.searchValueTemp = searchKey;
    if (searchKey.length > 2) {
      this.loader = true;
      this.researchService.searchStudies(this.currentPage, this.pageLimit, this.searchKey, this.filterBy).subscribe((data: any) => {
        this.loader = false;
        this.studies = data.docs;
        this.hasNextPage = data.hasNextPage;
        this.totalDoc = data.totalDocs;
        this.noDataTemplate = `No matching studies found`;
        const isMobile = this.detectMob();
        this.studies.forEach((item) => {
          if (isMobile) {
            if (item.tags.length > 3) {
              item.showTags = item.tags.slice(0, 2);
              item.moreTagsCount = item.tags.length - 2;
              item.moreTags = item.tags.slice(2);
            } else {
              item.showTags = item.tags;
            }
          } else {
            if (item.tags.length > 4) {
              item.showTags = item.tags.slice(0, 3);
              item.moreTagsCount = item.tags.length - 3;
              item.moreTags = item.tags.slice(3);
            } else {
              item.showTags = item.tags;
            }
          }
        })
      }, error => {
        this.loader = false
        if (error && error.status === 401) {
          let errBody = error.error;
          this.toastr.error(errBody.err, '', this.globals.tosterOverride);
          this.router.navigate(['login']);
        }
      })
    } else if (searchKey.length === 0) {
      this.filterStudies()
    }
  }

  openStudyDetails(study) {
    const studyId = study._id;
    const studyName = study.name;
    this.router.navigate([`researcher/studies/${studyId}`, { studyName: studyName }]);
  }
  createStudy(vendor:string) {
    this.router.navigate(['researcher/studies/actions' ],{ queryParams :{mode:"2",vendor: vendor}});  
  } 

  getEventSource(url: string): EventSource {
    return new EventSource(url, { withCredentials: true });
  }

  //SSE for add external study events
  serverStreamEvent() {
    this.researchService.getServerSentEvent(this.eventS).subscribe(res => {
      const resObj = res.data;
      if (!resObj) {
        return;
      }
      try {
        const { status, data, payload } = JSON.parse(resObj);
        // server give handshake to the alive connection
        if (data === 'check') {
          return;
        }
        if (data === 'externalStudy') {
          this.filterStudies();
        }
      } catch (e) {
        console.log("Error in SES events for externalStudy", e.message);
      }
    });
  }
}