import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MdlDialogReference } from '@angular-mdl/core';
import { Router,ActivatedRoute } from '@angular/router';
import { ResearchService} from "../../_services/research.service";

@Component({
  selector: 'app-cost-control',
  templateUrl: './cost-control.component.html',
  styleUrls: ['./cost-control.component.css']
})
export class CostControlComponent implements OnInit {

  @ViewChild('CostControlUndoAction') CostControlUndoAction: ElementRef;
  constructor(@Inject('data') public data: any,
  public dialogRef: MdlDialogReference,
  private router: Router,
  private serviceApi: ResearchService) { }

  ngOnInit() {
  }

  cancel(): void {
    this.serviceApi.sendSelectionEvent();
    this.dialogRef.hide();
  }

  close() {
    this.dialogRef.hide();
  }

  ngAfterViewInit() {
    setTimeout(() => this.CostControlUndoAction.nativeElement.focus(), 0);
  }

}