<div class="container" style="position: absolute; margin-top: 75px">

    <img class="success-icon" src="../../assets/images/success@2x.png" alt="Link Study Successfully"/>

    <h3 class="successMsg">
        Link Successful
    </h3>

    <h4 class="linkMsg">
        {{message}}
        <br>
        to the Sagemaker successfully.
    </h4>

    <div class="modal-footer" style="margin-top: 35px">
        <div class="marginForButtons l-footer">
          <button   type="button"  class="btn cat-btn-update sshSubmit ssh-btn l-submit" (click)="submit()">
              View details
          </button>
        </div>
      </div>

</div>
