<div class="share-container">
  <div class="share-header">
      <img class="cancel-icon" (click)="cancel()" src="../../assets/images/cancel@2x.png" alt="Cancel"/>
      <h3 class="title">Share Study</h3>
  </div>
  <div style="display: flex; flex-direction: row; margin-top: 10px;">
      <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
      <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
      <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
      <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
  </div>
  <div class="col-lg-12 col-md-12" style="padding: 25px" *ngIf="!loader && researcherList.length > 0">
      <label class="input-label">Researcher</label>
      <select class="form-control selectDropdown" required placeholder="researcher" name="researcher" [(ngModel)]="researcher">
        <option value="" disabled selected hidden> Choose researcher</option>
        <option *ngFor="let researcher of researcherList" [value]="researcher">{{researcher}}</option>
      </select>
      <h5 style="color: #dd0e26;margin-top: 12px;font-size: 14px;font-family: Nunito Sans">When you share this study, all contents in it, current and future will be visible to the user selected above.</h5>
  </div>
  <div class="btn-wrapper" *ngIf="!loader && researcherList.length > 0">
      <button  [disabled]="!researcher" name="button" type="button" class="btn shareButton"
      (click)="shareStudy()">Share</button>
      <!-- <button class="custom-btn pull-right" name="button" type="button" (click)="cancel()">Close</button> -->
  </div>
  <div *ngIf="!loader && researcherList.length == 0">
      <h3 class="noData">
        No researchers are available. Please add a new researcher to share study.
      </h3>
  </div>
</div>
<app-loader class="spinner" *ngIf="loader"></app-loader>
