import { Injectable } from '@angular/core';

@Injectable()
export class S3DataService {

  s3ExploreData: any = {};
  folderPrefix: any = {};
  s3UploadData: any = {};
  s3SyncData: any = {};

  constructor() { }

  setS3ExploreData (state) {
    this.s3ExploreData = state;
  }

  getS3ExploreData () {
    return this.s3ExploreData;
  }

  setFolderPrefix(folderName){
    this.folderPrefix = folderName;
  }

  getS3FolderPrefix () {
    return this.folderPrefix;
  }

  setS3UploadData(data){
    this.s3UploadData = data;
  }

  getS3UploadData(){
    return this.s3UploadData;
  }

  setS3SyncData(data){
    this.s3SyncData = data;
  }

  getS3SyncData(){
    return this.s3SyncData;
  }

}
