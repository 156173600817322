<div #projectActionWindow tabindex="-1">
  <div class="dialog-header">
  <img class="cancel-icon" (click)="cancel()" src="../../assets/images/cancel@2x.png" alt="Cancel"/>
  <h3 class="title">Add Budget to project <h3 class="content-2">"{{projectName}}"</h3></h3>
  </div>
  <div style="display: flex; flex-direction: row; margin-top: 0px;">
    <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
  </div>
  <div class="content">
  </div>
 
  <form #f="ngForm" novalidate (ngSubmit)="f.valid" >
  <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" style="padding: 0px;margin-right: 15px; margin-top: 10px;">
  <h2 class="prov-product-field text-white font-class" style="margin-bottom: 20px; margin-right: 10px;margin-left: 25px; font-size: 16px;">Enter the amount you would like to add to the current project budget</h2>
  <input id="budgetValueId" aria-label="addBudgetValue" class="inp-txt user-input" type="number" minlength="1" maxlength="5" placeholder="Enter a non-zero amount" [(ngModel)]="addBudgetValue" #rCEvery="ngModel" required pattern="^([0-9]*[1-9][0-9]*)$"
  name="addBudgetValue" (keyup)="checkBudgetValue()" (keydown.space)="$event.preventDefault()">
 </div>
  </form>
 <div class="mdl-dialog__actions" style="margin-top: 15px;margin-bottom: 15px;">
  <button class="btn btn-primary btn-default sub-btn" [disabled]="clicked" name="button" (click)="submitBudget(); clicked=true" type="submit" style="cursor:hand"><h2 class="sub-text">Submit</h2></button>
  <button class="btn can-btn" type="button" (click)="cancel()"><h2 class="cal-text">Cancel</h2></button>
  </div>
  </div>
