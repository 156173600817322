<div>
    <div class="dialog-header">
        <img class="cancel-icon" src="../../assets/images/cancel@2x.png" (click)="cancel();" alt="Cancel"/>
        <img class="unplug-icon" src="../../assets/images/unplug-solid.svg"alt="Detach EBS Volume"/>
        <h3 class="title">Detach EBS Volume</h3>
    </div>
    <div style="display: flex; flex-direction: row; margin-top: 0px;">
      <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
      <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
      <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
      <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
    </div>
    <app-loader class="spinner" *ngIf="showSpinner"></app-loader>
    <div class="content" *ngIf="!showSpinner">      
      <h2 class="content-1">Are you sure you want to detach <h2 class="content-2"> EBS Volume </h2> from this 
      <h2 class="content-2"> {{productName}} </h2> ?</h2>
    </div>
     <div class="mdl-dialog__actions" style="margin-top: 15px; margin-bottom: 15px;" *ngIf="!showSpinner">
      <button class="btn btn-primary dele-btn" type="button" (click)="detachVolume();"><h2 class="del-text">Detach</h2></button>
      <button class="btn  can-btn" type="button"><h2 class="cal-text">Cancel</h2></button>
    </div>
  </div>