import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './guard/auth-guard.service';
import { LoginComponent } from './login/login.component';
import { SettingsComponent } from './settings/settings.component';
import { ConfigComponent } from './settings/config.component';
import { ConfirmationDialog } from './dialog/dialog.component';
import { S3FileUploadComponent } from './dialog/s3-file-upload/s3-file-upload.component';
import { DeactivateGuard } from  './dialog/s3-file-upload/deactivate-guard'
import { S3ExploreComponent } from './s3-explore/s3-explore.component';
import { S3FileDeleteComponent } from './dialog/s3-file-delete/s3-file-delete.component';
import { S3EmptyFolderComponent } from './dialog/s3-empty-folder/s3-empty-folder.component';
import { S3UnshareComponent } from './dialog/s3-unshare/s3-unshare.component';
import { RegisterComponent } from './register/register.component';
import {CatalogComponent} from "./catalog/catalog.component";
import { ResearcherComponent} from "./researcher/researcher.component";
import {OrderComponent} from "./order/order.component";
import {ActiveProductComponent} from "./active-product/active-product.component";
import { AdminComponent } from "./admin/admin.component";
import { PrincipalComponent } from "./principal/principal.component";
import { AccountNotFoundComponent } from './principal/account-not-found/account-not-found.component';
import { BudgetTrackingComponent } from "./principal/budget/budget-tracking.component";
import { BudgetDetailsComponent } from "./principal/budget/budget-details.component";
import { OrganizationComponent } from './admin/organization/organization.component';
import {InvestigatorComponent} from "./principal/investigator/investigator.component";
import { InstanceComponent } from './catalog/instance/instance.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { ProjectActionComponent } from './principal/project-action/project-action.component';
import { AdminBudgetTrackingComponent } from './admin/budget-tracking/budget-tracking.component';
import { AdminBudgetDetailsComponent } from './admin/budget-tracking/budget-details.component';
import {InstanceSshComponent} from "./instance-ssh/instance-ssh.component";
import { InstanceRdpComponent } from './instance-rdp/instance-rdp.component';
import { BudgetProductComponent } from './principal/budget/budget-product/budget-product.component';
import { BudgetResearcherComponent } from './admin/budget-tracking/budget-researcher/budget-researcher.component';
import { AdminBudgetProductComponent } from './admin/budget-tracking/budget-product/budget-product.component';
import { LinkSagemakerComponent } from './dialog/link-resource/link-resource.component';
import { SuccessMessageComponent } from './dialog/success-message/success-message.component';
import { UnlinkResourceComponent } from './dialog/unlink-resource/unlink-resource.component';
import {KeypairComponent} from "./keypair/keypair.component";
import {CreateKeypairComponent} from "./keypair/create-keypair/create-keypair.component";
import { ResendVerificationComponent } from './resend-verification/resend-verification.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { StudiesComponent } from './studies/studies.component';
import { StudyDetailsComponent } from './study-details/study-details.component';
import { BudgetManagementComponent } from './researcher/budget/budget-management/budget-management.component';
import { BudgetProjectComponent } from './researcher/budget/budget-project/budget-project.component';
import { BudgetResearcherwiseComponent } from './researcher/budget/budget-researcherwise/budget-researcherwise.component';
import { LinkStudyComponent } from './dialog/link-study/link-study.component';
import { ErrorMessageComponent } from './dialog/error-message/error-message.component';
import { ShareStudyComponent } from './dialog/share-study/share-study.component';
import {studyS3ExploreComponent} from "./study-s3-explore/study-s3-explore.component";
import { AuditTrailComponent } from './audit-trail/audit-trail.component';
import {AuditDetailsComponent} from "./audit-trail/audit-details/audit-details.component";
import { CatalogManagementComponent } from './catalog-management/catalog-management.component';
import { AssignCatalogComponent } from './catalog-management/assign-catalog/assign-catalog.component';
import { CatalogManagementPrincipalComponent } from './catalog-management/catalog-management-principal/catalog-management-principal.component';
import { AddAccountComponent } from './add-account/add-account.component';
import { AddProjectComponent } from './principal/add-project/add-project.component';
import { CostControlComponent } from './dialog/cost-control/cost-control.component';
import { UserComponent } from './user/user.component';
import { AddUserComponent } from './dialog/add-user/add-user.component';
import { AssignOrganisationComponent } from './dialog/assign-organisation/assign-organisation.component';
import { EnableUserComponent } from './dialog/enable-user/enable-user.component';
import { WelcomePageComponent } from "./welcome-page/welcome-page.component";
import {LogoutComponent} from "./logout/logout.component";
import {DefaultComponent} from "./default/default.component";
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { AddOrgComponent } from './admin/add-org/add-org.component';
import { ShowProjectDetailsComponent } from './admin/show-project-details/show-project-details.component';
import { S3SyncInfoComponent } from './dialog/s3-sync-info/s3-sync-info.component';
import { ArchiveProjectComponent } from './project-details/archive-project/archive-project.component';
import { InstanceTypeComponent } from './dialog/instance-type/instance-type.component';
import { AddBudgetComponent } from './project-details/add-budget/add-budget.component';
import { AddStudyComponent } from './studies/add-study/add-study.component';
import { AttachVolumeComponent } from './product-details/attach-volume/attach-volume.component';
import { DetachVolumeComponent } from './product-details/detach-volume/detach-volume.component';
import { BillingAccountComponent } from './billing-account/billing-account.component';
import { ShowRenewalDateComponent } from './components/header/show-renewal-date/show-renewal-date.component';
import { AssignPublicStudyComponent } from './studies/assign-public-study/assign-public-study.component';
import { UpdateNetworkDetailsComponent } from './settings/update-network-details/update-network-details.component';
import { UpdateEgressStoreDetailsComponent } from './settings/update-egress-store-details/update-egress-store-details.component';
import { ConformationDialogComponent } from './settings/conformation-dialog/conformation-dialog.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

const routes: Routes = [
  { path: '', redirectTo: '/default', pathMatch: 'full' },
  { path: 'default', component: DefaultComponent},
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'welcome', component: WelcomePageComponent , canActivate: [AuthGuardService]},
  { path: 'researcher', component: ResearcherComponent, canActivate: [AuthGuardService]},
  { path: 'explore/:id', component: studyS3ExploreComponent, canActivate: [AuthGuardService]},
  { path: 'explore/:id/success/:message', component: studyS3ExploreComponent, canActivate: [AuthGuardService]},
  { path: 'admin', component: AdminComponent, canActivate:[AuthGuardService],
    children: [
      { path: 'organization' , component : OrganizationComponent },
    ]},
  { path: 'admin/error/:message', component: AdminComponent, canActivate:[AuthGuardService]},
  { path: 'admin/success/:message', component: AdminComponent, canActivate:[AuthGuardService]},
  { path: 'principal', component: PrincipalComponent, canActivate:[AuthGuardService]},
  { path: 'admin', component: AdminComponent, canActivate:[AuthGuardService]},
  { path: 'audittrail', component: AuditDetailsComponent, canActivate:[AuthGuardService]},
  { path: 'admin/catalog', component: CatalogManagementComponent, canActivate:[AuthGuardService]},
  { path: 'principal/catalog', component: CatalogManagementPrincipalComponent, canActivate:[AuthGuardService]},
  { path: 'admin/catalogData/assign', component: AssignCatalogComponent, canActivate:[AuthGuardService]},
  { path: 'admin/budget-tracking', component: AdminBudgetTrackingComponent, canActivate:[AuthGuardService]},
  { path: 'admin/organisation/:id/details', component: AdminBudgetDetailsComponent, canActivate:[AuthGuardService]},
  { path: 'admin/project/:id/details', component: BudgetResearcherComponent, canActivate:[AuthGuardService]},
  { path: 'admin/researcher/:id/details', component: AdminBudgetProductComponent, canActivate:[AuthGuardService]},
  { path: 'admin/settings/showProjectDetails', component: ShowProjectDetailsComponent, canActivate:[AuthGuardService]},
  { path: 'principal', component: PrincipalComponent, canActivate:[AuthGuardService],
    children: [
      { path: 'investigator' , component : InvestigatorComponent },
      { path: 'project-action' , component : ProjectActionComponent }
    ]},
  { path: 'principal/budget-tracking', component: BudgetTrackingComponent, canActivate:[AuthGuardService]},
  { path: 'principal/accountNotFound', component: AccountNotFoundComponent, canActivate:[AuthGuardService]},
  { path: 'project-details/:projectId', component: ProjectDetailsComponent, canActivate:[AuthGuardService]},
  { path: 'archiveProject', component: ArchiveProjectComponent, canActivate:[AuthGuardService]},
  { path: 'addAccount', component: AddAccountComponent, canActivate:[AuthGuardService]},
  { path: 'addProject/:from', component: AddProjectComponent, canActivate:[AuthGuardService]},
  { path: 'addOrganization/:from', component: AddOrgComponent, canActivate:[AuthGuardService]},
  { path: 'principal/project/:id/details', component: BudgetDetailsComponent, canActivate:[AuthGuardService]},
  { path: 'principal/project/researcher/:id/details', component: BudgetProductComponent, canActivate:[AuthGuardService]},
  { path: 'principal/error/:message', component: PrincipalComponent, canActivate: [AuthGuardService]},
  { path: 'principal/success/:message', component: PrincipalComponent, canActivate: [AuthGuardService]},
  { path: 'setting', component: SettingsComponent, canActivate: [AuthGuardService],
    children:[
      { path:'config',component:ConfigComponent},
      { path:'dialog',component:ConfirmationDialog},
      { path:'s3FileDelete',component:S3FileDeleteComponent},
      { path:'s3CreateEmptyFolder',component:S3EmptyFolderComponent}
    ]},
  { path:'s3Unshare',component:S3UnshareComponent,canActivate: [AuthGuardService]},
  { path:'s3Upload',component:S3FileUploadComponent,canActivate: [AuthGuardService], canDeactivate: [DeactivateGuard]},
  { path:'s3Sync',component:S3SyncInfoComponent,canActivate: [AuthGuardService]},
  { path:'s3/bucket/:bucketName',component:S3ExploreComponent,canActivate: [AuthGuardService]},
  { path:'s3/bucket/:bucketName/success/:message',component:S3ExploreComponent,canActivate: [AuthGuardService]},
  { path: 'setting/error/:message', component: SettingsComponent,canActivate: [AuthGuardService]},
  { path: 'setting/success/:message', component: SettingsComponent,canActivate: [AuthGuardService]},
  { path: 'setting/update/:time', component: SettingsComponent, canActivate: [AuthGuardService]},
  { path: 'setting/contacts/:contactDetails', component: SettingsComponent,canActivate: [AuthGuardService]},
  { path: 'setting/workflow/:workflowDetails', component: SettingsComponent,canActivate: [AuthGuardService]},
  { path: 'setting/:vendor', component: SettingsComponent,canActivate: [AuthGuardService]},
  { path: 'catalog/:name/:accountId', component: CatalogComponent, canActivate: [AuthGuardService], children: [
      { path: 'instance' , component : InstanceComponent },
    ]},
  { path: 'order/:projectName/:accountId/:projectId/:productId/:name/:stdCatalogitemId/:vendor', component: OrderComponent , canActivate: [AuthGuardService]},
  { path: 'product/:projectName/:category/:accountId', component: ActiveProductComponent , canActivate: [AuthGuardService]},
  { path: 'product/:projectName/:productName', component: ProductDetailsComponent, canActivate: [AuthGuardService]},
  { path: 'product/:projectName/:productName/:linkResource/:message', component: ProductDetailsComponent, canActivate: [AuthGuardService]},
  { path: 'instance-ssh/:ppId', component: InstanceSshComponent, canActivate: [AuthGuardService]},
  { path: 'instance-rdp/:accountId/:ppId', component: InstanceRdpComponent, canActivate: [AuthGuardService]},
  { path: 'linkSagemaker', component: LinkSagemakerComponent, canActivate: [AuthGuardService]},
  { path: 'linkSagemakerSuccess', component: SuccessMessageComponent, canActivate: [AuthGuardService]},
  { path: 'unlinkResource', component: UnlinkResourceComponent, canActivate: [AuthGuardService]},
  { path: 'keypair', component: KeypairComponent, canActivate: [AuthGuardService],
    children: [
      { path: 'createKeypair' , component : CreateKeypairComponent }
    ]},
  { path: 'keypair/:keypairName/success/:message' , component: KeypairComponent, canActivate: [AuthGuardService]},
  { path: 'resend-verification', component: ResendVerificationComponent},
  { path: 'reset-password', component: ResetPasswordComponent},
  { path: 'researcher/studies/actions',component:AddStudyComponent,canActivate: [AuthGuardService]},
  { path: 'researcher/studies', component: StudiesComponent, canActivate: [AuthGuardService]},
  { path: 'researcher/studies/:studyId', component: StudyDetailsComponent, canActivate: [AuthGuardService]},
  { path: 'researcherData/budget-management', component: BudgetManagementComponent, canActivate:[AuthGuardService]},
  { path: 'researcherData/budget-projectData', component: BudgetProjectComponent, canActivate:[AuthGuardService]},
  { path: 'researcherData/budget-researcherData', component: BudgetResearcherwiseComponent, canActivate:[AuthGuardService]},
  { path: 'linkStudy', component: LinkStudyComponent, canActivate: [AuthGuardService]},
  { path: 'shareStudy', component: ShareStudyComponent, canActivate: [AuthGuardService]},
  { path: 'linkStudyError', component: ErrorMessageComponent, canActivate: [AuthGuardService]},
  { path: 'users', component: UserComponent, canActivate: [AuthGuardService]},
  { path: 'add-users', component: AddUserComponent, canActivate: [AuthGuardService]},
  { path: 'assign-organisation', component: AssignOrganisationComponent, canActivate: [AuthGuardService]},
  { path: 'enable-user', component: EnableUserComponent, canActivate: [AuthGuardService]},
  { path:'costControlInfo',component:CostControlComponent,canActivate: [AuthGuardService]},
  { path:'instanceType',component:InstanceTypeComponent,canActivate: [AuthGuardService]},
  { path:'addBudget',component:AddBudgetComponent,canActivate: [AuthGuardService]},
  { path:'AttachVolume',component:AttachVolumeComponent,canActivate: [AuthGuardService]},
  { path:'DetachVolume',component:DetachVolumeComponent,canActivate: [AuthGuardService]},
  { path:'BillingAccount',component:BillingAccountComponent,canActivate: [AuthGuardService]},
  { path:'ShowRenewalDate',component:ShowRenewalDateComponent,canActivate: [AuthGuardService]},
  { path:'AssignPublicStudy',component:AssignPublicStudyComponent,canActivate: [AuthGuardService]},
  { path:'UpdateNetworkDetails',component:UpdateNetworkDetailsComponent,canActivate: [AuthGuardService]},
  { path:'UpdateEgressStoreDetails',component:UpdateEgressStoreDetailsComponent,canActivate: [AuthGuardService]},
  { path:'ConformationDialog',component:ConformationDialogComponent,canActivate: [AuthGuardService]},
  { path: 'pageNotFound', component: PageNotFoundComponent, canActivate: [AuthGuardService]},
  { path: '**', component: PageNotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
