<div class="container">
  <!-- breadcrumb -->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb bc-custom">
      <li class="breadcrumb-item"><a class="bc-item-active">Users</a></li>
    </ol>
  </nav>
  <div class="header-layout">
    <h3 class="header-title">Users<h3 *ngIf="totalPages">&nbsp;( {{totalUsers}} )</h3>
    </h3>
    <div class="search-layout">
      <div class="search-div">
        <em class="glyphicon glyphicon-search search-icon"></em>
        <label for="searchKeyForUser" class="visually-hidden">searchKeyForUser</label>   
        <input (keyup)="searchUsers()" [(ngModel)]="searchKey" class="form-control search-inp search-inp-add"
          placeholder="Search" type="text" id="searchKeyForUser">
      </div>
      <div class="add-new-btn-container" *ngIf="showAddButton">
        <div class="add-new-btn-select-wrapper">
          <div class="add-new-btn-select">
            <div class="add-new-btn-trigger"><span class="add-new-txt">+ Add New</span>
              <div class="arrow"></div>
            </div>
            <div class="add-new-btn-dropdown-options">
              <span class="filter-dropdown-option" data-value="add" (click)="addUser()">Add New User</span>
              <span class="filter-dropdown-option upload-btn-wrapper btn" style="padding-left: 10px !important;" flow-btn (click)="fInput.click()">Import Users via CSV</span>
              <label for="ImportUsers" class="visually-hidden">ImportUsers</label>   
              <input #fInput type="file" style="visibility: hidden; position: absolute;" id="ImportUsers" name="myfile" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onclick="this.value = null" (change)="importUsers($event)">
              <span class="filter-dropdown-option" data-value="download" (click)="downloadSampleCSV()">Download CSV format</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divider"></div>
  <div class="filter-container">
    <div class="filter-layout" (scroll)="onScroll($event)">
      <div id="filter-dropdown-wrapper" class="filter-dropdown-wrapper" *ngIf="!checkIfPrincipal()">
        <div id="filter-dropdown" class="filter-dropdown">
          <div class="filter-dropdown-trigger">
            <span style="color: #6E7C9F; font-size: 12px;"
              *ngIf="selectedOrganisationFilter.value">{{selectedOrganisationFilter.value.slice(0, 22)}}</span>
            <span style="color: #6E7C9F; font-size: 12px;" *ngIf="!selectedOrganisationFilter.value">Filter By OU</span>
            <div class="arrow"></div>
          </div>
          <div class="filter-dropdown-options mobile-organisation-filter"
            [ngStyle]="{'left.px': organisationFilterLeft}">
            <span class="filter-dropdown-option" [ngClass]="{'selected': filter.key === selectedOrganisationFilter.key}"
              *ngFor="let filter of organisationFilterData" data-value="filter.value"
              (click)="onChangeFilter('Organisation', filter)">{{filter.value}}</span>
          </div>
        </div>
      </div>
      <div id="filter-user-role-dropdown-wrapper" class="filter-dropdown-wrapper" style="margin-left: 20px;">
        <div id="filter-user-role-dropdown" class="filter-dropdown">
          <div class="filter-dropdown-trigger">
            <span style="color: #6E7C9F; font-size: 12px;"
              *ngIf="selectedRoleFilter.value">{{selectedRoleFilter.value}}</span>
            <span style="color: #6E7C9F; font-size: 12px;" *ngIf="!selectedRoleFilter.value">Filter By Role</span>
            <div class="arrow"></div>
          </div>
          <div class="filter-dropdown-options mobile-role-filter" [ngStyle]="{'left.px': roleFilterLeft}">
            <span class="filter-dropdown-option" [ngClass]="{'selected': filter.key === selectedRoleFilter.key}"
              *ngFor="let filter of roleFilterData" data-value="filter.value"
              (click)="onChangeFilter('Role', filter)">{{filter.value}}</span>
          </div>
        </div>
      </div>
      <div id="filter-sort-dropdown-wrapper" class="filter-dropdown-wrapper" style="margin-left: 20px;">
        <div id="filter-sort-dropdown" class="filter-dropdown">
          <div class="filter-dropdown-trigger">
            <span style="color: #6E7C9F; font-size: 12px;" *ngIf="selectedSortFilter.value">
              {{selectedSortFilter.value}}
              <span *ngIf="selectedSortFilter.order === 'asc'">(&uarr;Asc)</span>
              <span *ngIf="selectedSortFilter.order === 'desc'">(&darr;Desc)</span>
            </span>
            <span style="color: #6E7C9F; font-size: 12px;" *ngIf="!selectedSortFilter.value">Sort By</span>
            <div class="arrow"></div>
          </div>
          <div class="filter-dropdown-options mobile-sort-filter" [ngStyle]="{'left.px': sortFilterLeft}">
            <span class="filter-dropdown-option"
              [ngClass]="{'selected': (filter.key === selectedSortFilter.key && filter.order === selectedSortFilter.order)}"
              *ngFor="let filter of sortFilterData" data-value="filter.value" (click)="onChangeFilter('Sort', filter)">
              {{filter.value}}
              <span *ngIf="filter.order === 'asc'">(&uarr;Asc)</span>
              <span *ngIf="filter.order === 'desc'">(&darr;Desc)</span>
            </span>
          </div>
        </div>
      </div>
      <div class="reset-btn" (click)="resetFilter()">
        <span class="reset-btn-txt">Reset Filters</span>
      </div>
    </div>
    <div class="active-container">
      <div class="active-btn-wrapper">
        <span style="font-size: 12px; color: #6E7C9F;" *ngIf="!isActiveUser">Active Users</span>
        <span style="font-size: 12px; color: #3FCB57;" *ngIf="isActiveUser">Active Users</span>
        <label for="toggleButton" class="visually-hidden">toggleButton for see active and inactive users</label>   
        <mdl-switch style="width: auto; margin-top: 5px;" [(ngModel)]="isActiveUser" 
          (click)="onChangeFilter('Enabled', '')" mdl-ripple aria-label="toggleButton"></mdl-switch>
      </div>
      <div class="card-toggle">
        <img class="card-toggle-btn" *ngIf="isCardView" src="../../assets/images/table_view_inactive.png" alt="Switch To Table View"
          (click)="toggleView()" />
        <img class="card-toggle-btn" *ngIf="!isCardView" src="../../assets/images/card_view_inactive.png" alt="Switch To Card View"
          (click)="toggleView()" />
      </div>
    </div>
  </div>
  <div *ngIf="isCardView">
    <div class="users-container">
      <div class="col-md-4 col-sm-12 item" *ngFor="let user of users">
        <div class="user-card">
          <div class="user-active-layout">
            <div class="user-active-color" *ngIf="user.enabled"></div>
            <div class="user-inactive-color" *ngIf="!user.enabled"></div>
            <span class="user-active-txt">{{user.enabled ? 'Active' : 'Inactive'}}</span>
          </div>
          <div class="user-name-layout">
            <div class="user-icon">
              <span class="user-icon-txt" *ngIf="user.photo && user.photo !== ''"><img style="border-radius: 50%;height: 25px" src={{user.photo}}></span>
              <span class="user-icon-txt" *ngIf="!user.photo || user.photo === ''">{{getUserInitials(user)}}</span>
            </div>
            <span class="user-name font-class line-clamp"
              title="{{user.first_name}} {{user.last_name}}">{{user.first_name}} {{user.last_name}}</span>
          </div>
          <div style="display: flex; flex-direction: column;">
            <span class="user-role line-clamp" title="{{user.role}}">{{user.role}}</span>
            <span class="user-email line-clamp" title="{{user.email}}">{{user.email}}</span>
            <span *ngIf="user.org_id && user.org_id !== null && !checkIfPrincipal() " class="user-org line-clamp"
              title="{{user.org_id.org_name}}">{{user.org_id.org_name}}</span>
          </div>
          <span class="card-menu" *ngIf="showAddButton">
            <i class="fa fa-ellipsis-v" data-toggle="dropdown" title="Action" aria-hidden="true"
              style="color: #85D4FF;cursor: pointer; font-size: 25px;background: #07102A;"></i>
            <ul class="dropdown-menu card-menu-dropdown">
              <li class="card-menu-dropdown-item" *ngIf="!user.verified" (click)="resendVerificationMail(user)"><span
                  class="dropdown-item-text">Resend Verification Mail</span></li>
              <li class="card-menu-dropdown-item" *ngIf="!user.org_id && user.level < 2" (click)="assignUser(user)">
                <span class="dropdown-item-text">Assign O.U.</span></li>
              <li class="card-menu-dropdown-item" *ngIf="user.enabled" (click)="disableUser(user)"><span
                  class="dropdown-item-text">Disable</span></li>
              <li class="card-menu-dropdown-item" *ngIf="!user.enabled" (click)="enableUser(user)"><span
                  class="dropdown-item-text">Enable</span></li>
              <li class="card-menu-dropdown-item" (click)="editUser(user)"><span
                  class="dropdown-item-text">Edit</span></li>
            </ul>
          </span>
        </div>
      </div>
    </div>
    <div class="empty-user-list" *ngIf="users.length === 0 && !loader">
      <span class="empty-user-txt text-white font-class" style="font-size: 22px">{{noDataTemplate}}</span>
    </div>
    <div class="load-more-btn-wrapper" *ngIf="hasNextPage">
      <button class="load-more-btn font-class" (click)="loadMoreUsers()">Load More</button>
    </div>
  </div>
  <div *ngIf="!isCardView">
    <div class="table-responsive" *ngIf="users.length !== 0 && !loader">
      <div class="table-header">
        <div class="table-title-layout" style="width: 16%;">
          <span class="table-title-text font-class">Users</span>
        </div>
        <div class="table-title-layout" style="width: 16%;">
          <span class="table-title-text font-class">Role</span>
        </div>
        <div class="table-title-layout" style="width: 16%;">
          <span class="table-title-text font-class">Username</span>
        </div>
        <div class="table-title-layout" [ngStyle]="{'width': checkIfPrincipal() ? '32%' : '16%'}">
          <span class="table-title-text font-class">Email</span>
        </div>
        <div class="table-title-layout" style="width: 16%;" *ngIf="!checkIfPrincipal()">
          <span class="table-title-text font-class">Organizational Unit</span>
        </div>
        <div class="table-title-layout" style="width: 15%;">
          <span class="table-title-text font-class">User status</span>
        </div>
        <div class="table-title-layout" style="width: 5%;">
          <span class="table-title-text font-class"></span>
        </div>
      </div>
      <table class="table" style="width: 100%; margin-top: 10px;">
        <tbody>
          <tr class="table-body-separator" *ngFor="let user of users">
            <td style="width: 16%; padding: 20px 10px !important; border-top: none;">
              <span class="table-body-name font-class"
                title="{{user.first_name}} {{user.last_name}}">{{user.first_name}} {{user.last_name}}</span>
            </td>
            <td style="width: 16%; padding: 20px 10px !important; border-top: none;">
              <span class="table-body-name font-class" title="{{user.role}}">{{user.role}}</span>
            </td>
            <td style="width: 16%; padding: 20px 10px !important; border-top: none;">
              <span class="table-body-name font-class" title="{{user.username}}">{{user.username}}</span>
            </td>
            <td style="padding: 20px 10px !important; border-top: none;"
              [ngStyle]="{'width': checkIfPrincipal() ? '32%' : '16%'}">
              <span class="table-body-name font-class" title="{{user.email}}">{{user.email}}</span>
            </td>
            <td style="width: 16%; padding: 20px 10px !important; border-top: none;" *ngIf="!checkIfPrincipal()">
              <span class="table-body-name font-class" *ngIf="user.org_id && user.org_id !== null"
                title="{{user.org_id.org_name}}">{{user.org_id.org_name}}</span>
              <span class="table-body-name font-class" *ngIf="user.org_id === null"> - </span>
            </td>
            <td style="width: 15%; padding: 20px 10px !important; border-top: none;">
              <div style="display: flex; align-items: center; margin-left: 10px;">
                <div class="user-active-color" *ngIf="user.enabled"></div>
                <div class="user-inactive-color" *ngIf="!user.enabled"></div>
                <span class="table-body-name font-class">{{user.enabled ? 'Active' : 'Inactive'}}</span>
              </div>
            </td>
            <td style="border-top: none !important;text-align: center;overflow: visible !important;position:relative">
              <div class="dropdown" style="text-align: right; margin: 10px;" *ngIf="showAddButton">
                <span>
                  <i class="fa fa-ellipsis-v" data-toggle="dropdown" title="Action" aria-hidden="true"
                    style="color: white;cursor: pointer; font-size: 25px;"></i>
                  <ul class="dropdown-menu dropdown-content">
                    <li class="card-menu-dropdown-item" *ngIf="!user.verified" (click)="resendVerificationMail(user)"><a
                        class="dropdown-item-text">Resend Verification Mail</a></li>
                    <li class="card-menu-dropdown-item" *ngIf="!user.org_id && user.level < 2"
                      (click)="assignUser(user)"><a class="dropdown-item-text">Assign O.U.</a></li>
                    <li class="card-menu-dropdown-item" *ngIf="user.enabled" (click)="disableUser(user)"><a
                        class="dropdown-item-text">Disable</a></li>
                    <li class="card-menu-dropdown-item" *ngIf="!user.enabled" (click)="enableUser(user)"><a
                        class="dropdown-item-text">Enable</a></li>
                    <li class="card-menu-dropdown-item" (click)="editUser(user)"><a
                      class="dropdown-item-text">Edit</a></li>
                  </ul>
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="product-avail" *ngIf="users.length !== 0 && !loader">
      <span class="page-count-text">{{ currentPageLowerCount }} - {{ currentPageUpperCount }} of {{ totalUsers }}</span>
      <div class="pagination-wrapper">
        <img class="pagination-next-btn" [ngClass]="{'disable-first-btn':currentPage === 1 }"
          src="../../assets/images/pagination-first-active.png"  alt="First Page" (click)="getNextUsers('first')" />
        <img class="pagination-next-btn" [ngClass]="{'disable-prev-btn':currentPage === 1 }"
          src="../../assets/images/pagination-prev-active.png"  alt="Previous Page" (click)="getNextUsers('previous')" />
        <img class="pagination-next-btn" [ngClass]="{'disable-next-btn':currentPage === totalPages}"
          src="../../assets/images/pagination-next-active.png"  alt="Next Page" (click)="getNextUsers('next')" />
        <img class="pagination-next-btn" [ngClass]="{'disable-last-btn':currentPage === totalPages}"
          src="../../assets/images/pagination-last-active.png"  alt="Last Page" (click)="getNextUsers('last')" />
      </div>
    </div>
    <div class="empty-user-list" *ngIf="users.length === 0 && !loader">
      <span class="empty-user-txt text-white font-class" style="font-size: 22px">{{noDataTemplate}}</span>
    </div>
  </div>
</div>
<app-loader class="spinner" *ngIf="loader"></app-loader>
