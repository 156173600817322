import { Component, OnInit, Inject } from '@angular/core';
import { MdlDialogReference } from '@angular-mdl/core';
import { ResearchService } from "../../_services/research.service";
import { DashboardService } from "../../_services/dashboard.service";
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {Globals} from "../../globals";

@Component({
  selector: 'app-conformation-dialog',
  templateUrl: './conformation-dialog.component.html',
  styleUrls: ['./conformation-dialog.component.css'],
  providers: [DashboardService,Globals]
})

export class ConformationDialogComponent implements OnInit {

  confirmBox:boolean = false;
  settingId;

  constructor( @Inject('data') public data: any,
    private dialogRef: MdlDialogReference,
    private researchService: ResearchService,
    private router: Router,
    private toastr: ToastrService,
    private globals: Globals
  ) { }

  ngOnInit(): void {
    if (this.data != undefined) {
      this.settingId = this.data.accountId;
    }
  }

  cancel(): void {
    this.dialogRef.hide();
  }

  confirmCheckBox(){
    this.confirmBox = !this.confirmBox;
  }

  disableEgressStoreDetails(){
    let updateObject = {  
      settingId: this.settingId, 
      isEgressStoreEnabled : false      
    };
    let t = new Date().getTime();
    this.researchService.updateSettings(updateObject, "updateEgressStoreDetails").subscribe( response =>{
      this.dialogRef.hide();
      this.router.navigate([`/setting`],{ queryParams: { showTab:'setting', updatedData: t}});
      this.toastr.success('The egress store has been disabled successfully', '', this.globals.tosterOverride);
    }, error => {
      this.dialogRef.hide();
      let errorMes = error.error;
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
        }else if (errorMes && (errorMes.message)) {
        this.toastr.error('', errorMes.message, { tapToDismiss: false, timeOut: 0, extendedTimeOut: 0, closeButton : true });
      }else{
        this.toastr.error('Error in disable egress store for the setting', '', this.globals.tosterOverride);
      }
    }); 
  }  
}
