<div>
  <form #f="ngForm">
    <div class="form-group" id="formData" style="overflow-y: auto !important;max-height: unset !important;">
      <button class="close close-btn" data-dismiss="modal" type="button" title="Close" (click)="cancel()">
        <i class="fa fa-times-circle close-btn-icon"></i></button>
      <!-- <h3 *ngIf="!isValid" class="Header ml-3 header-title">Add Organization</h3> -->
      <h3 class="Header ml-3 header-title">Organization Details</h3>
      <div style="display: flex; flex-direction: row; margin-top: 10px; margin-bottom: 5px;">
        <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
        <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
        <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
        <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
      </div>
      <!-- Organisation  Details Section -->
      <div class="org-details-modal" style="margin-top: 15px;padding: 2.5rem;" *ngIf="isValid">
        <h2>Organization Name</h2>
        <p>{{ organizationName }}</p>
        <h2 class="formLabel">Account ID</h2>
        <p>{{ accountNames }}</p>
        <h2 class="formLabel">Assigned Users</h2>
        <p>{{ principalIds }}</p>
        <h2 class="formLabel">Organization Description</h2>
        <p>{{ organizationDescription }}</p>
        <h2 class="formLabel">Renewal Date</h2>
        <p>{{ subscriptionDate }}</p>
        <h2 *ngIf="showLinkedExternalGroup" class="formLabel">Linked Active Directory Group</h2>
        <p *ngIf="showLinkedExternalGroup">{{ linkedActiveDirectoryGroup }}</p>
      </div>
      <!--  Add New Organisation Section -->
      <!-- <div class="add-new-org-form" *ngIf="!isValid">
        <div class="name-content field-container">
          <label class="formLabel">Organization Name</label>
          <span class="after">*</span>
          <input class="form-control name-input" placeholder="Please enter your organization name" type="text"
                 value="Organization Name" [(ngModel)]="organizationName" #sName="ngModel" required name="organizationName"
                 minlength="3" pattern="^[-_a-zA-Z0-9]+$" (keypress)="checkOrgName()" (keyup)="checkOrgName()" (keydown.space)="$event.preventDefault()">
          <div class="name-helper-container">
            <span [ngClass]="getHelpTextClass(defaultClass)">{{orgNameErrDesc}}</span>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 field-container">
          <label class="formLabel">Account ID</label>
          <div *ngIf="loadForm" style="text-align: center; ">
            <img src="/assets/images/barspreloaders.svg" style="height: 60px">
        </div>
          <div class="form-control account-selection-content" *ngIf="!loadForm && accountListFound">
            <span class="selection-header" *ngIf="fAccountIdList.length !== 0 && accountIds.length === 0 && !loadForm">Select accounts from the list</span>
            <span class="selection-header" *ngIf="accountIds.length !== 0 && !loadForm">Selected Accounts ( {{accountIds.length}} )</span>
            <div *ngIf="accountListFound && !loadForm ">
              <div class="selection" *ngFor="let acc of fAccountIdList" >
                <input type="checkbox" [id]="acc.value" name="acc.value" (change)="selectAccountIds(acc, $event)">
                <label [for]="acc.value" class="selection-label">{{acc.viewValue}}</label>
              </div>
            </div>
          </div>
          <span *ngIf="!loadForm && !accountListFound" class="name-helper-text error-text">
          No AWS accounts available. Please add an AWS account from the Settings page or by clicking the button below
        </span>
      <button *ngIf="!loadForm && !accountListFound" (click)="routeToSettings()"  type="button" class="btn btn-lg btn-primary btn-pi text-white font-class mr-2 mb-1" title="Add account">
        <i class="fa fa-plus white" style="margin: 3px; margin-right: 5px;"></i>Click here to add an account</button>
        </div>
        <div class="col-lg-12 col-md-12 field-container">
          <label class="formLabel">Organization Description</label>
          <span class="after">*</span>
          <textarea *ngIf="!isValid" class="form-control description-content"
                    placeholder="Organization Description" type="text" value="Organization Description"
                    [(ngModel)]="organizationDescription" #sprincipalIdsName="ngModel" required name="organizationDescription"
                    maxlength="500"></textarea>
        </div>
        <div class="col-lg-12 col-md-12 field-container">
          <label class="formLabel">Principal Investigator IDs</label>
          <div *ngIf="loadProjForm" style="text-align: center; ">
            <img src="/assets/images/barspreloaders.svg" style="height: 60px">
        </div>
          <div class="form-control researcher-selection-container" *ngIf="principalIdList.length > 0">
            <span class="selection-header" *ngIf="principalIds.length === 0">Select principal investigators from the list</span>
            <span class="selection-header" *ngIf="principalIds.length !== 0">Selected Principal Investigators ( {{principalIds.length}} )</span>
            <div class="selection" *ngFor="let p of principalIdList">
              <input type="checkbox" [id]="p.value" name="p.value" [disabled]="p.disabled" (change)="selectPrincipalIds(p, $event)">
              <label [for]="p.value" class="selection-label">{{p.viewValue}}</label>
            </div>
          </div>
          <span class="name-helper-text" *ngIf="principalIdList.length > 0">
          Select users who can create and manage projects within this organizational unit
        </span>
        <span *ngIf=" !loadProjForm && principalIdList.length === 0" class="name-helper-text error-text">
          Please add a user with principal investigator role from the User Management screen and assign the user to this O.U. later
        </span>
        </div>
        <div class="col-lg-12 col-md-12 add-btn">
          <button *ngIf="!isValid"
                  style="background-color: #3E6BFF;border-radius:23px !important;opacity: 1 !important;width: 165px; height:35px"
                  class="btn btn-primary" data-dismiss="modal" type="button" (click)="addOrganization()"
                  [disabled]="!f.valid || !submitBTN ">
            Add Organization <em class="fa fa-refresh fa-spin" *ngIf="showSpinner"></em>
          </button>
        </div>
      </div> -->
    </div>
  </form>
</div>
