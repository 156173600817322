import { Component, OnInit, Inject } from '@angular/core';
import { MdlDialogReference } from '@angular-mdl/core';
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import {Globals} from "../../globals";
import { ResearchService } from "../../_services/research.service";
import { DataService } from '../../_services/data.service';

@Component({
  selector: 'app-attach-volume',
  templateUrl: './attach-volume.component.html',
  styleUrls: ['./attach-volume.component.css'],
  providers: [Globals]

})
export class AttachVolumeComponent implements OnInit {

  constructor(
    @Inject('data') public data: any,
    public dialogRef: MdlDialogReference,private toastr: ToastrService,private router: Router, private researchService: ResearchService, private globals: Globals,private dataService: DataService

  ) { }
  productName;
  deviceName;
  selectedVolume;
  productDetails;
  ppId;
  projectName;
  projectStatus;
  selectedVolumePpId;
  volumeList = [];
  isLoaded = false;
  loader = false;
  clicked=true;

  ngOnInit(): void {
    if (this.data != undefined) {
      this.productName = this.data.productName;  
      this.ppId = this.data.ppId;
      this.projectName = this.data.projectName;
      this.projectStatus = this.data.projectStatus;
    }
    this.getProductDetails();
  }
  getProductDetails() {
    this.isLoaded = false;
    this.researchService.getProvisionedProductDetails(this.ppId).subscribe((res:any) => {
      this.productDetails = res;
      this.isLoaded = true;
      if(this.productDetails.availableVolumes.length > 0 ){
        this.volumeList = this.productDetails.availableVolumes.map(function(element){
          const volumeObj = {
            'volumeName' : element.ProvisionedProductName,
            'volumeId' : element.volumeId,
            'volumePpId' : element.ProvisionedProductId
          }
          return volumeObj;
        })
      }
      if(this.volumeList.length == 1){
        this.selectedVolume = this.volumeList[0].volumeName;
        this.selectedVolumePpId = this.volumeList[0].volumePpId;
      }
    }, error => {
      this.loader = false;
      let errBody = error.error;
      if(error && error.status === 401){
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }else if (error && error.status === 403) {
        const err = errBody.error ? errBody.error : errBody;
        this.toastr.error(err, 'Error', this.globals.tosterOverride);
        this.router.navigate(['pageNotFound']);
      }else{
        this.toastr.error(errBody, 'Error', this.globals.tosterOverride);
      }
    });
  }

  cancel() {
    this.dialogRef.hide();
  }

  checkDevice(){
    if(this.deviceName !== undefined && this.selectedVolume !== undefined){
      this.clicked = false;
    }
  }

  seletedAvailabeVolume(){
    let selectedValue = this.volumeList.filter(val => val.volumeName == this.selectedVolume); 
    this.selectedVolumePpId = selectedValue[0].volumePpId;
    if(this.deviceName !== undefined && this.selectedVolume !== undefined){
      this.clicked = false;
    }
  }

  attachVolume(){
    let volToAttach = {
      instancePPID : this.ppId,
      volPPID  : this.selectedVolumePpId,
      device : this.deviceName
    }
    let t = new Date().getTime();
    let resp = this.researchService.attachVolume(volToAttach).subscribe((res:any) => {
      this.loader = true;
      if(res.status === "success"){
        this.toastr.success(res.data.message);
        this.dialogRef.hide();
        this.router.navigate(['/product/' + this.projectName + '/' + this.productName, { ppId: this.ppId }],{ queryParams: { showTab:'productDetails', updatedData: t}});
      
      }else {
        this.dialogRef.hide();
        this.toastr.error('Volume attach to instance failed', 'Error', this.globals.tosterOverride)
      }
    }, error => {
      this.dialogRef.hide();
      if(error && error.status === 401){
        let errBody= error.error.error;
         this.toastr.error(errBody, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }else {
        let errBody= error.error.error;
        this.toastr.error(errBody, '', this.globals.tosterOverride);
       }   
    });  
  }
}
