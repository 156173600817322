import { Component, OnInit } from '@angular/core';
import { ResearchService} from "../../../_services/research.service";
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from "../../../_services/dashboard.service";
import {Globals} from "../../../globals";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-budget-project',
  templateUrl: './budget-project.component.html',
  styleUrls: ['./budget-project.component.css', '../../../catalog/catalog.component.css', '../../../app.component.css'],
  providers: [Globals]
})
export class BudgetProjectComponent implements OnInit {
  totalBudgetAllotted: any = 0;
  totalBudgetConsumed: any = 0;
  totalCurrentMonthCost: any = 0;
  currencySymbol: string = '$';
  loader: boolean = false;
  projects=[];;
  budgetDetails=[];
  projectIds;
  researcher;
  consumedBudgetTextColor = {
    passed: '#73E2CF',
    threshold: 'red',
    pauseThreshold: '#F5AF19'
}

  constructor( private router: Router,
               private route: ActivatedRoute,
               private researchService: ResearchService,
               private apiService: DashboardService,
               private globals: Globals,private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.apiService.checkIfResearcher();
    this.researcher = this.route.snapshot.queryParams['researcher'];
    this.getProjects();

  }


  getProjects() {
    this.loader = true;
    this.researchService.getResearcherWiseBudget().subscribe((data:any) => {
      this.projects = data;
      this.loader = false;
      this.getBudgetCardsData();
    }, error => {
      this.loader = false;
      if(error && error.status === 401){
        let errBody= error.error;
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
      }
    });
  }

  openProjectDetails(projectId) {
    this.router.navigate(['researcherData/budget-researcherData'],{ queryParams: { researcher: this.researcher, id: projectId}});
  }

  getBudgetCardsData(){
    let allotted : any = 0;
    let consumed : any = 0;
    let currentMonthCostByResearcher : any = 0;
    this.projects.forEach((project) => {
      // Safe navigation on all keys
      if(project.budgetAllocated){
        allotted += project.budgetAllocated
      }
      let budgetConsumed = project.budgetConsumed;
      if (budgetConsumed) {
        // let projectBudgetConsumed = budgetConsumed['projectBudgetConsumed']
        // if (projectBudgetConsumed) {
          consumed += project.budgetConsumed;
        //}
      }
      if(project.monthlyBudgetCosumedByResearcher){
        currentMonthCostByResearcher += project.monthlyBudgetCosumedByResearcher
      }
      this.totalBudgetAllotted = parseFloat(allotted).toFixed(2);
      this.totalBudgetConsumed = parseFloat(consumed).toFixed(2);
      this.totalCurrentMonthCost = parseFloat(currentMonthCostByResearcher).toFixed(2);
    });
  }

  projectConsumedBudget(project){
    if(project.budgetConsumed){
      if('budgetConsumedPercentage' in project){
        return `${parseFloat(project.budgetConsumed).toFixed(2)} ( ${project.budgetConsumedPercentage} % )`;
      }
      return `${parseFloat(project.budgetConsumed).toFixed(2)}`;
    }else{
      return 0;
    }
  }

  projectMonthBudget(project){
    let value: any = 0;
    if(project.budgetConsumed){
      if(project.monthlyBudgetCosumedByResearcher){
        value = project.monthlyBudgetCosumedByResearcher
      }
    }
   return parseFloat(value).toFixed(2);
  }

  projectConsumedBudgetPercentage(project){
    if(project.budgetConsumed){
      let consumed = project.budgetConsumed
      return ((consumed/project.budgetAllocated)*100).toFixed(0)
    }else {
      return 0;
    }
 }

  getColor(project){
    if(project.budgetConsumedPercentage >= project.projectStopThresholdPercentage){
      return this.consumedBudgetTextColor.threshold;
    }if(project.budgetConsumedPercentage >= project.projectPauseThresholdPercentage){
      return this.consumedBudgetTextColor.pauseThreshold;
    }else{
      return this.consumedBudgetTextColor.passed;
    }
  }

  getMarkerWidth(project){
    let markerWidth = 80
     if('projectStopThresholdPercentage' in project && 'budgetConsumedPercentage' in project){
        markerWidth = project.projectStopThresholdPercentage - project.budgetConsumedPercentage;
     }
     return markerWidth+'%';
  }

  getData(data) {
    const strLen = data.length;
    let str = data;
    if (strLen > 15) {
      str = str.slice(0, 15) + '...';
    }
    return str;
  }

  getRoundOffBudget(data){
    return parseFloat(data).toFixed(2);
  }

}
