<div class="container">

  <!-- breadcrumb -->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb bc-custom">
      <li class="breadcrumb-item active bc-item-active" aria-current="page">Key Pairs</li>
    </ol>
  </nav>
  <!-- details -->
  <div style="display: flex; justify-content: space-between; align-items: center; margin: 10px 0px;">
    <div style="display: flex;">
      <div style="display: flex; flex-direction: column;">
        <h4 class="text-white font-class">Key Pairs</h4>
      </div>
    </div>
    <div class="text-right">
      <button (click)="addKeypair()"  type="button" class="btn btn-lg btn-primary btn-pi text-white font-class mr-2 mb-1" title="Create Key pair">
        <em class="fa fa-plus white"></em>  Create New</button>
      <button type="button" class="btn btn-lg btn-primary btn-pi text-white font-class mb-1 display-none" title="Refresh">
        Sort by <em class="fa fa-angle-down white"></em></button>
    </div>
  </div>

  <div class="divider"></div>

  <div class='s3Explore'>
    <div class="primary-flex">
      <div class="search-div" style="margin-right: auto">
        <em class="glyphicon glyphicon-search search-icon"></em>
        <label for="searchKeyForKeyPair" class="visually-hidden">searchKeyForKeyPair</label>  
        <input (keyup)="searchKeypair()" id="searchKeyForKeyPair" [(ngModel)]="searchKey" class="form-control search-inp search-inp-add" placeholder="Search" type="text">
      </div>
      <div (click)="deleteKeypair()"  class="web-status display-none" style="margin-right: 15px; cursor: pointer;height: 45px; display: flex; border: 1px solid #465374; border-radius: 23px; align-items: center; padding: 10px;">
        <i class="fa fa-trash" title="Delete" aria-hidden="true" style="color: #fff;width: 12px;height: 14px;font-size: 15px; margin-left: 10px;"></i>
        <span style="margin: 0px 10px; font-size: 14px;" class="text-white font-class">Delete</span>
      </div>
      <div class="web-status" style="height: 40px; display: flex; border: 1px solid #465374; border-radius: 23px; align-items: center; padding: 10px;">
        <img style="height: 15px; margin: 10px; cursor: pointer;" src="../../assets/images/refresh.png" alt="Refresh Key Pairs" (click)="refresh()" />
        <span style="margin: 0px 10px; font-size: 14px;" class="text-white font-class">Refresh</span>
      </div>
    </div>
    <div class="m-search-tp">
      <!-- Responsive actions -->
      <div class="search-div-responsive">
        <em class="glyphicon glyphicon-search search-icon"></em>
        <input style=" width: 100%; " (keyup)="searchKeypair()" aria-label="searchKeyPairForMobileView" [(ngModel)]="searchKey" class="form-control search-inp search-inp-add" placeholder="Search" type="text">
      </div>

      <!-- mobile actions bar -->
      <div class="mbl-control-bar d-flex justify-content-between m-4 search-hearder">
        <div style="height: 40px; display: flex; border: 1px solid #465374; border-radius: 23px; align-items: center; ">
          <img style="height: 15px; margin: 0px 0px 0px 10px; cursor: pointer;" src="../../assets/images/refresh.png" alt="Refresh Key Pairs" (click)="refresh()" />
          <span style="margin: 0px 10px; font-size: 14px;" class="text-white font-class">Refresh</span>
        </div>
      </div>
    </div>


    <app-loader class="spinner" *ngIf="showSpinner"></app-loader>

    <div *ngIf="keypairList.length === 0 && noData === true">
      <h4 style=" color: #fff;text-align: center;padding: 100px; font-size: 22px">
        {{noDataTemplate}}
      </h4>
    </div>
    <div *ngIf="keypairList.length > 0" class="table-responsive md-layout lg-layout" style="margin-bottom: 25px;">
      <table class = "gfg" style="width: 100%">
        <thead style="background-color: #15244B">
        <tr>
          <th class="tableHeader" style="width: 20%;">NAME</th>
          <th class="tableHeader" style="width: 20%;">PROJECT NAME</th>
          <th class="tableHeader" style="width: 15%;">CREATED ON</th>
          <th class="tableHeader" style="width: 30%;">FINGERPRINT</th>
          <th class="tableHeader" style="width: 15%;">ID</th>
          <th class="visually-hidden">-</th>
        </tr>
        </thead>
        <tbody style="background-color: #07102A">
        <tr  *ngFor="let data of keypairList" style="background-color:inherit">
          <td title="{{data.keypairName}}" class="tableData" style="width: 20%;font-size: 14px">{{projectName(data.keypairName)}}</td>
          <td title="{{data.projectName}}" class="tableData" style="width: 20%;font-size: 14px">{{projectName(data.projectName)}}</td>
          <td class="tableData" style="width: 15%;font-size: 14px" *ngIf="data.createdTime">{{data.createdTime | date:'medium'}}</td>
          <td class="tableData" style="width: 30%; font-size: 14px"> {{data.keyFingerprint}} </td>
          <td class="tableData" style="width: 15%;font-size: 14px" >{{data.keyPairId}}</td>
          <td class="tableData" style="text-align: center;overflow: visible !important;position:relative;width: 10px;">
            <div class="dropdown">
                  <span>
                  <i class="fa fa-ellipsis-v" data-toggle="dropdown" title="Action" aria-hidden="true" style="color: #f1f1f1;cursor: pointer;"></i>
                  <ul class="dropdown-menu" style="cursor:pointer;background: #07102A;">
                    <li><a  class="listItems" (click)="deleteKeypairOne(data)">Delete</a></li>
                  </ul>
                </span>
            </div>
          </td>
        </tbody>
      </table>
      <div class="load-more-btn-wrapper" *ngIf="showLoadMore">
        <button class="load-more-btn" (click)="loadMoreEvents()">Load More</button>
      </div>
    </div>

    <div class="sm-layout xs-layout" *ngIf="keypairList.length > 0 && !showSpinner">
      <!-- mobile responsive -->
      <div class="project-cards-layout">
        <div class="project-card" *ngFor="let data of keypairList;">
          <ul class="list-group">
            <div class="d-flex justify-content-between mb-2">
              <div class="project-card-title project-card-line-height font-class">NAME</div>
              <div class="project-card-project-name" title="{{data.keypairName}}">{{projectName(data.keypairName)}}</div>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <div class="project-card-title project-card-line-height font-class">PROJECT NAME</div>
              <div class="project-card-project-name" title="{{data.projectName}}">{{projectName(data.projectName)}}</div>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <div class="project-card-title project-card-line-height font-class">CREATED ON</div>
              <div class="project-card-project-name">{{data.createdTime | date:'medium'}}</div>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <div class="project-card-title project-card-line-height font-class"> FINGERPRINT</div>
              <div class="project-card-project-name" >{{data.keyFingerprint}}</div>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <div class="project-card-title project-card-line-height font-class"> ID</div>
              <div class="project-card-project-name" >{{data.keyPairId}}</div>
            </div>
            <div class="d-flex justify-content-between mb-2" style="margin-top: 10px;" >
              <button type="button" style="width: 100%;" class="btn  can-btn" (click)="deleteKeypairOne(data)">Delete</button>
            </div>
          </ul>
        </div>
      </div>
      <div class="load-more-btn-wrapper" *ngIf="showLoadMore">
        <button class="load-more-btn" (click)="loadMoreEvents()">Load More</button>
      </div>
    </div>

  </div>
</div>
