<div class='s3Upload' id="instanceSSHPage">
  <div class="dialog-header">
    <img class="cancel-icon" (click)="cancel()" src="../../assets/images/cancel@2x.png" alt="Cancel"/>
    <h2 class="modal-title title-header" id="myModalLabel" class="Header">
      {{action === 'enableUser' ? 'Enable User' : 'Disable User'}}
    </h2>
  </div>
  <div style="display: flex; flex-direction: row; margin-top: 10px;">
    <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
  </div>
  <div class="modal-body modal-title">
    <label class="details" *ngIf="action === 'disableUser'">A user, once disabled, will no longer be able to login to the system. Are you sure you want to proceed?</label>
    <label class="details" *ngIf="action === 'enableUser'">A user, once enabled, will be able to log in to the system and carry out activities according to his role. Are you sure you want to proceed?</label>
  </div>
  
  <div class="col-lg-12 col-md-12 mt-5" style="text-align: center; margin-bottom: 35px !important; margin-top: 30px !important">
    <div class="marginForButtons l-footer">
      <button   type="button" class="btn cat-btn-update sshSubmit ssh-btn l-submit" (click)="submit()">
        {{action === 'enableUser' ? 'Enable' : 'Disable'}}
      </button>
    </div>
  </div>
</div>
<app-loader class="spinner" *ngIf="showSpinner"></app-loader>  