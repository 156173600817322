<!-- Terminate active products -->
<div tabindex="-1" #productStopTerminationWindow *ngIf="data.action != 'stop' && serviceType !='pcluster' && data.action == 'provisionedProduct' && data.action != 'primaryContact' && serviceType !='s3' && !s3ShareData && !shareData">
    <div class="dialog-header">
        <img class="cancel-icon" tabindex="0" (keypress)="cancel()" (click)="cancel()" src="../../assets/images/cancel@2x.png" alt="Cancel"/>
        <h3 class="title">Terminate {{ProvisionedProductName}} product</h3>
    </div>
    <div style="display: flex; flex-direction: row; margin-top: 0px;">
      <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
      <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
      <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
      <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
    </div>
    <div class="content">
      <h2 class="content-1">Are you sure you want to terminate the <h3 class="content-2"> "{{ ProvisionedProductName}}"</h3> product? </h2>
    </div>
    <div class="warningData">
      <h2 class="war-msg">All information associated to this product will be permanently deleted.</h2>
    </div>
    <div class="warning">
        <h2 class="delete-msg"> This operation cannot be undone. </h2>
    </div>
    <div class="mdl-dialog__actions" style="margin-top: 15px; margin-bottom: 15px;">
      <button class="btn btn-primary dele-btn" type="button" (click)="confirmRequest()"><h2 class="del-text">Delete</h2></button>
      <button class="btn  can-btn" type="button"  (click)="cancel()"><h2 class="cal-text">Cancel</h2></button>
    </div>
  </div>

<!-- Delete Settings -->
<div *ngIf="data.action != 'stop' && serviceType !='pcluster'&& data.action != 'primaryContact' && serviceType !='s3' && !s3ShareData && !shareData && data.action != 'provisionedProduct' && !showConfirmDeactivateBox && !showConfirmDeleteBox">
  <div class="dialog-header">
      <img class="cancel-icon" (click)="cancel()" src="../../assets/images/cancel@2x.png" alt="Cancel"/>
      <h3 class="title">Delete {{accountName}} account</h3>
  </div>
  <div style="display: flex; flex-direction: row; margin-top: 0px;">
    <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
  </div>
  <div class="content">
    <h2 class="content-1">Please select one of the options to proceed further. </h2><br>
    <input type="radio" id="deactivate" name="delete" value="deactivate" (click)="settingsDeleteOption.type = 'delink'" [checked]="settingsDeleteOption.type === 'deactivate'" >
    <label for="deactivate" style="color:white;font-size: 15px">Delink my aws account</label>
    <p style="color: white">Choosing this option will only remove the association of Research Gateway from your AWS account. All the services and resources used via Research Gateway will keep running and consume the budget.</p>
    <br>
  </div>
  <div class="mdl-dialog__actions" style="margin-top: 15px;margin-bottom: 15px;">
    <button class="btn btn-primary dele-btn" type="button" (click)="deleteSettings()" [disabled]="settingsDeleteOption.type == ''"><h2 class="del-text">Confirm<i *ngIf="showSpinner" class="fa fa-refresh fa-spin"></i></h2></button>
    <button class="btn can-btn" type="button"  (click)="cancel()"><h2 class="cal-text">Cancel</h2></button>
  </div>
</div>

<!-- Delete Settings -->
<div *ngIf="data.action != 'studyAccount' && data.action != 'stop' && serviceType !='pcluster' && data.action != 'primaryContact' && serviceType !='s3' && !s3ShareData && !shareData && data.action != 'provisionedProduct' && (showConfirmDeactivateBox || showConfirmDeleteBox)" >
  <div class="dialog-header">
      <img class="cancel-icon" (click)="cancelSettingDelete()" src="../../assets/images/cancel@2x.png" alt="Cancel"/>
    <h3 *ngIf="showConfirmDeleteBox" class="title">Delete <h3 class="content-2">"{{accountName}}"</h3> account</h3>
      <h3 *ngIf="showConfirmDeactivateBox" class="title">De-link  <h3 class="content-2">"{{accountName}}"</h3> account</h3>
  </div>
  <div style="display: flex; flex-direction: row; margin-top: 0px;">
    <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
  </div>
  <div class="content">
    <h2 *ngIf="showConfirmDeleteBox" class="content-1">Are you sure you want to delete the <h3 class="content-2"> "{{ accountName}}"</h3> account? </h2>
    <h2 *ngIf="showConfirmDeactivateBox" class="content-1">Are you sure you want to de-link the <h3 class="content-2"> "{{ accountName}}"</h3> account? </h2>
  </div>
  <div class="warningData" *ngIf="showConfirmDeleteBox">
    <h2 class="war-msg">All information associated to this account will be permanently deleted.</h2>
  </div>
  <div class="warning">
      <h2 class="delete-msg"> This operation cannot be undone. </h2>
      <p style="color: white">Choosing this option will only remove the association of Research Gateway from your AWS account. All the services and resources used via Research Gateway will keep running and consume the budget.</p>
      <p *ngIf="studiesAssigned" style="color: white">This action will also delete all internal studies created in Research Gateway using this account.
      </p>
  </div>
  <div class="content">
    <input type="checkbox" name="confirm" id="1" aria-label="conformationCheckBox" (click)="confirmDeleteBox()">
    <label style="color:white; font-size: 15px; margin-left: 5px;">Are you sure you want to {{showConfirmDeleteBox ? 'delete' : 'de-link' }} your AWS account ?</label>
  </div>
  <div class="mdl-dialog__actions" style="margin-top: 15px;margin-bottom: 15px;">
    <button class="btn btn-primary dele-btn" type="button" (click)="confirmRequest()" [disabled]="!confirmBox" ><h2 class="del-text">De-link  <i *ngIf="showSpinner" class="fa fa-refresh fa-spin"></i></h2></button>
    <button class="btn  can-btn" type="button"  (click)="cancelSettingDelete()"><h2 class="cal-text">Cancel</h2></button>
  </div>
</div>

<!-- S3 Share dialog box -->
<form *ngIf="s3ShareData && !shareData">
  <div class="container s3Upload">
    <div class="share-header">
      <img class="cancel-icon" (click)="cancel()" src="../../assets/images/cancel@2x.png" alt="Cancel"/>
      <h3 class="title">Share S3 Bucket</h3>
  </div>
    <div style="display: flex; flex-direction: row; margin-top: 10px;">
      <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
      <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
      <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
      <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
    </div>
    <div class="col-lg-12 col-md-12" style="padding: 25px" *ngIf="researcherList.length > 0">
      <label class="researcher" style="text-align: left;font: normal normal bold 16px/12px Nunito Sans;
      color: #FFFFFF;margin-bottom: 15px; position: relative;
      display: block;">Researcher</label>
      <select class="form-control selectDropdown" required placeholder="researcher" name="researcher" [(ngModel)]="researcher">
        <option value="" disabled selected hidden> Choose researcher</option>
        <option *ngFor="let researcher of researcherList" [value]="researcher">{{researcher}}</option>
      </select>
      <h5 style="color: #F26E83;margin-top: 12px;font-size: 14px;font-family: Nunito Sans">When you share this folder, all contents in it, current and future will be visible to the user selected above.</h5>
    </div>
    <div style="text-align: center;margin-bottom: 35px;margin-top: 20px;" *ngIf="researcherList.length > 0">
      <button  [disabled]="!researcher" name="button" type="button" class="btn shareButton"
      (click)="shareS3Product()">Share</button>
    </div>
    <div *ngIf="researcherList.length == 0">
      <h3 class="noData">
        No researchers are available. Please add a new researcher to share the S3 bucket.
      </h3>
    </div>
  </div>
</form>
<!-- Share dialog box -->
<div *ngIf="shareData">
    <div class="container s3Upload">
 <div class="dialog-header">
      <img class="cancel-icon" (click)="cancel()" src="../../assets/images/cancel@2x.png" alt="Cancel"/>
      <h3 class="title">Share Workspace</h3>
  </div>
  <div style="display: flex; flex-direction: row; margin-top: 0px;">
    <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
  </div>
  <div class="content">
    <h2 class="content-1">This action will make this provisioned product available to all the members of the project. Cost for this product will continue to accrue against your budget.</h2>
  </div>
  <div class="warningData">
    <h2 class="war-msg">For other users to access this product, you will have to share the pem file with them outside of Research Gateway.</h2>
  </div>
  <div class="warning">
    <h2 class="delete-msg"> Once the workspace is shared, it cannot be unshared.</h2>
</div>
  <div class="content">
    <input type="checkbox" aria-label="conformationCheckBox" name="confirm" id="1" (click)="confirmDeleteBox()">
    <label style="color:white; font-size: 15px; margin-left: 5px;">Are you sure you want to continue ?</label>
  </div>
  <div class="mdl-dialog__actions" style="margin-top: 15px; margin-bottom: 15px;">
    <button class="btn btn-primary dele-btn" type="button" (click)="shareProvisionedProduct(productSharingMetadataObj)" [disabled]="!confirmBox"><h2 class="del-text">Share</h2></button>
    <button class="btn  can-btn" type="button"  (click)="cancel()"><h2 class="cal-text">Cancel</h2></button>
  </div>
  </div>
</div>



<!-- Stop Pcluster -->
<div *ngIf="data.action == 'stop' && serviceType =='pcluster' ">
  <div tabindex="-1" #productStopTerminationWindow class="dialog-header">
      <img class="cancel-icon" tabindex="0" (keypress)="cancel()" (click)="cancel()" src="../../assets/images/cancel@2x.png" alt="Cancel"/>
      <h3 class="title">Stop <h3 class="content-2">"{{ProvisionedProductName}}"</h3> product</h3>
  </div>
  <div style="display: flex; flex-direction: row; margin-top: 0px;">
    <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
  </div>
  <div class="content">
    <h2 class="content-1">This action will only stop the head node. If the ComputeMinvCPUs (in case of AWSBatch scheduler) or MinimumInstances (in case of Slurm scheduler) is set to a non-zero value, the compute nodes will continue to run in the background and accrue costs.</h2>
  </div>
  <div class="content">
    <input type="checkbox" aria-label="conformationCheckBoxForStopPcluster" name="confirm" id="1" (change)="confirmDeleteBox()">
    <label style="color:white; font-size: 15px; margin-left: 5px;">I understand and agree.</label>
  </div>
  <div class="mdl-dialog__actions" style="margin-top: 15px; margin-bottom: 15px;">
    <button class="btn btn-primary dele-btn" type="button" (click)="stopProduct()" (keypress)="stopProduct()" [disabled]="!confirmBox">
      <h2 class="del-text">Stop<i *ngIf="showSpinner" class="fa fa-refresh fa-spin"></i></h2>
    </button>
    <button class="btn  can-btn" type="button" (keypress)="cancel()"  (click)="cancel()"><h2 class="cal-text">Cancel</h2></button>
  </div>
</div>

<div *ngIf="data.action == 'studyAccount'" >
  <div class="dialog-header">
      <img class="cancel-icon" (click)="cancelSettingDelete()" src="../../assets/images/cancel@2x.png" alt="Cancel"/>
    <h3 class="title">Delete <h3 class="content-2">"{{accountName}}"</h3> account</h3>
  </div>
  <div style="display: flex; flex-direction: row; margin-top: 0px;">
    <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
  </div>
  <div class="content">
    <h2  class="content-1">Are you sure you want to delete the <h3 class="content-2"> "{{ accountName}}"</h3> account? </h2>
  </div>
  <div class="warningData">
    <h2 class="war-msg">All information associated to this account will be permanently deleted.</h2>
  </div>
  <div class="warning">
      <h2 class="delete-msg"> This operation cannot be undone. </h2>
  </div>
  <div class="content">
    <input type="checkbox" aria-label="conformationCheckBox" name="confirm" id="1" (click)="confirmDeleteBox()">
    <label style="color:white; font-size: 15px; margin-left: 5px;">Are you sure you want to delete your AWS account ?</label>
  </div>
  <div class="mdl-dialog__actions" style="margin-top: 15px;margin-bottom: 15px;">
    <button class="btn btn-primary dele-btn" type="button" (click)="confirmRequest()" [disabled]="!confirmBox" ><h2 class="del-text">Delete  <i *ngIf="showSpinner" class="fa fa-refresh fa-spin"></i></h2></button>
    <button class="btn  can-btn" type="button"  (click)="cancelSettingDelete()"><h2 class="cal-text">Cancel</h2></button>
  </div>
</div>