import { Component, NgZone, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder } from '@angular/forms';
import { MdlSnackbarService } from '@angular-mdl/core';
import { DashboardService } from '../_services/dashboard.service';
import {GoogleSignInSuccess} from 'angular-google-signin';
import { Globals } from "../globals";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [Globals]
})

export class LoginComponent implements OnInit {
  public fromData:any={
    company:'',
    username:'',
    password:'',
    email: ''
  };
  public showSpinner = false;
  showPassword = false;
  org = null;
  showSelfRegister = true;
  checkSubscribeUserorNot = true;
  loader = false
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: DashboardService,
    private fb: FormBuilder,
    private snackBar: MdlSnackbarService,
    private toastr: ToastrService,
    private globals: Globals,
    private zone:NgZone
  ) { }

  ngOnInit() {
    sessionStorage.removeItem('Level');  
    this.org = this.route.snapshot.queryParams['id'];
    this.route.queryParams.subscribe(
      params => {
        if('google_error' in params){
          this.toastr.error(params['google_error'], '', this.globals.tosterOverride);
        }
      }
    )
    this.loader = true;
    this.apiService.getApplicationMode().subscribe((data:any) => {
      this.loader = false;
      if (!data.enableB2CMode) {
        this.showSelfRegister = false;
      }
      this.router.navigate(['login']);
      sessionStorage.removeItem('currentUser');
      sessionStorage.removeItem('version');
      sessionStorage.removeItem("Selected");
      if(data.enableB2CMode){
        let subscriptionDate;
        this.apiService.getSAMLUser().subscribe((data:any) =>{
          subscriptionDate = data.orgSubscriptionRenewalDate;    
          const date = new Date();       
          const todayDate = date.toISOString();   
          if((todayDate < subscriptionDate)) {
            this.checkSAMLRouting();
          }  
          else {           
            this.router.navigate(['login']);  
          }    
        });
      }else{
        this.checkSAMLRouting();
      }
    }, error =>{
      this.loader = false;
      if(error && error.status === 401){
        let errBody = error.error;
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
      }
    });
  } 

  togglePassword(show){
    this.showPassword = show;
  }

  checkSAMLRouting(){
    this.showSpinner = true;
    this.apiService.getLoginStrategy().subscribe((data:any) =>{
      sessionStorage.setItem('strategy', data.strategy);
        this.apiService.getSAMLUser().subscribe(current => {
            if (!current && data.strategy === 'saml') {
              console.log("Routing to saml");
              window.location.href =  window.location.origin + '/saml';
            }if (!current && data.strategy === 'azure-ad-oidc') {
              console.log("Routing to azure-ad");
              window.location.href =  window.location.origin + '/azureAdLogin';
            } else {
              this.showSpinner = false;
              this.getUser();
            }
        }, error => {
          console.log(error);
          if(error && error.status === 401){
            let errBody = error.error;
             this.toastr.error(errBody.err, '', this.globals.tosterOverride);
             this.router.navigate(['login']);
           }else if(data.strategy === 'saml'){
            window.location.href = window.location.origin + '/saml';
          }else if(data.strategy === 'azure-ad-oidc'){
            window.location.href = window.location.origin + '/azureAdLogin';
          }else{
            this.showSpinner = false;
          }
        });
    }, error => {
        this.showSpinner = false;
        console.log(error);
        if(error && error.status === 401){
          let errBody = error.error;
           this.toastr.error(errBody.err, '', this.globals.tosterOverride);
           this.router.navigate(['login']);
        }
  });
  }

  getUser(){
    // this.showSpinner = true;
    this.apiService.getSAMLUser().subscribe((data:any) =>{
      this.showSpinner = false;
      if (data.code && data.code !== 200) {
        sessionStorage.removeItem('currentUser');
        this.tosterMobile();
        this.toastr.error(data.message.errorSummary, '',  this.globals.tosterOverride);
        // this.snackBar.showToast(data.message.errorSummary, 3000);
      } else {
        sessionStorage.setItem('currentUser', data.username);
        sessionStorage.setItem('tenantid', data.tenantID);
        sessionStorage.setItem("5b1647bf469e0b0cf9dcacca",data['custom:tenantType']);
        sessionStorage.setItem("Level",data.level);
        sessionStorage.setItem('org_id', data.org_id);
        sessionStorage.setItem('roles', JSON.stringify(data.roles));
        sessionStorage.setItem('currentUserEmail', data.email);
        sessionStorage.setItem('displayName', data.displayName);
        sessionStorage.setItem('photo', data.photo);
        sessionStorage.setItem('subscriptionRenewalDate', data.orgSubscriptionRenewalDate);
        if (data.isGroupsMismatched || !data.enabled) {
          this.router.navigate(['welcome']);
        }else if ((data && data['custom:tenantType'] && data['custom:tenantType'] == 'landlord') || data.level == 2) {
          this.router.navigate(['admin']);
        }else if (data['custom:Level'] === "1" || data.level == 1) {
          this.router.navigate(['principal'])
        } else if(data.level == 0) {
          this.router.navigate(['researcher']);
        }else{
          this.router.navigate(['welcome']);
        }
      }
    }, error => {
      this.showSpinner = false;
      console.log(error);
        if(error && error.status === 401){
          let errBody = error.error;
           this.toastr.error(errBody.err, '', this.globals.tosterOverride);
           this.router.navigate(['login']);
         }
  });
  }

  login($event) {
    this.showSpinner = true;
    $event.preventDefault();
    $event.stopPropagation()
    if(this.org && this.org.length > 0){
      this.fromData.company = this.org;
    }
    let formData = {
      username: this.fromData.username,
      password: this.fromData.password,
      email: this.fromData.email
    };
    this.showSpinner = true;
    this.apiService.loginForDashboard(formData).subscribe((data:any) => {
      this.org = null;
      this.showSpinner = false;      
      if((data.level == 0 || data.level == 1) && (data.orgSubscriptionRenewalDate != "" && data.orgSubscriptionRenewalDate != null)) {
        const date = new Date();       
        const todayDate = date.toISOString();  
        let subscriptionRenewalDate = data.orgSubscriptionRenewalDate;   
        if(todayDate > subscriptionRenewalDate) {
          this.checkSubscribeUserorNot = false;    
        }else {
          this.checkSubscribeUserorNot = true;    
        }
      } 
     
      if (data.code && data.code !== 200) {
        sessionStorage.removeItem('currentUser');
        this.tosterMobile();
        this.toastr.error(data.message.errorSummary, '',  this.globals.tosterOverride);
        // this.snackBar.showToast(data.message.errorSummary, 3000);
      } else {
        sessionStorage.setItem('currentUser', data.username);
        sessionStorage.setItem('currentUserEmail', data.email);
        sessionStorage.setItem('tenantid', data.tenantID);
        sessionStorage.setItem('org_id', data.org_id);
        sessionStorage.setItem("roles", JSON.stringify(data.roles));
        sessionStorage.setItem("5b1647bf469e0b0cf9dcacca",data['custom:tenantType']);
        sessionStorage.setItem('displayName', data.displayName);
        sessionStorage.setItem('photo', data.photo);
        sessionStorage.setItem('subscriptionRenewalDate', data.orgSubscriptionRenewalDate);
        if('custom:Level' in data){
          sessionStorage.setItem("Level",data['custom:Level']);
        }else{
          sessionStorage.setItem("Level",data.level);
        }

        if((data && data['custom:tenantType'] && data['custom:tenantType'] == 'landlord') || data.level == '2' ) {
          this.zone.run(() => this.router.navigate(['admin']));
        }else if(this.checkSubscribeUserorNot === true && (data['custom:Level'] === "1" || data.level == 1)){
          this.zone.run(() => this.router.navigate(['principal']));          
        }else if(this.checkSubscribeUserorNot){
          this.zone.run(() => this.router.navigate(['researcher']));
        }else {
          this.zone.run(() => this.router.navigate(['logout']));  
        }
      }
    }, error => {
      this.showSpinner = false;
      let errorbody = '';
        if(error._body){
          errorbody = JSON.parse(error._body).message;
        }else if(error.error.message){
          errorbody = error.error.message;
        } else { 
          errorbody = "Server Down";
        }
      this.tosterMobile();
      this.toastr.error(errorbody, '', this.globals.tosterOverride);
    });
  }

  googleAuth($event) {    
    window.location.href = window.location.origin + '/auth/google';
    $event.preventDefault();
    $event.stopPropagation();   
  }  

  private tosterMobile() {
    const objOption = this.globals.tosterOverride;
    const wWidth = window.innerWidth;
    if (wWidth < 500) {
      objOption['positionClass'] = 'toast-bottom-center';
    }
  }

  private myClientId: string = '677290941196-op505gloe88a9mftc2vf1p5irgne89b6.apps.googleusercontent.com';
  onGoogleSignInSuccess(event: GoogleSignInSuccess) {
    let googleUser: gapi.auth2.GoogleUser = event.googleUser;
    let id: string = googleUser.getId();
    let profile: gapi.auth2.BasicProfile = googleUser.getBasicProfile();
    var id_token = googleUser.getAuthResponse().id_token;
    this.apiService.loginForDashboard({
      login_type: 'Google',
      client_id: this.myClientId,
      token: id_token,
      mail_Id: profile.getEmail(),
      image_url: profile.getImageUrl(),
      username: profile.getName(),
      userId: profile.getId()
    }).subscribe((data:any) => {
      if (data.code && data.code !== 200) {
        sessionStorage.removeItem('currentUser');
        sessionStorage.removeItem('login_type');
        this.snackBar.showToast(data.message.errorSummary, 3000);
      } else {
        this.apiService.updateHeader(data.token);
        sessionStorage.setItem('currentUser', profile.getName());
        sessionStorage.setItem('login_type', 'Google');
        this.apiService.getVersion().subscribe((data:any) => {
          sessionStorage.setItem('version', data);
          this.router.navigate(['dashboard']);
        }, error => {
          this.router.navigate(['dashboard']);
        })
      }
    })
  }
}
