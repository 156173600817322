<div tabindex="-1">
    <div class="dialog-header">
        <img class="cancel-icon" (click)="cancel()" src="../../assets/images/cancel@2x.png" alt="Cancel"/>
            <h3 class="title">Assign study to the projects</h3>
    </div>
    <div style="display: flex; flex-direction: row; margin-top: 10px;">
        <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
        <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
        <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
        <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
    </div>
    <div class="modal-body" *ngIf="showProjectList.length > 0">
        <label class="details-a">
            Select one or more projects to which you want to make this study available
        </label>    
      </div>
    <div *ngIf=" !loader && showProjectList.length > 0" class="form-control account-selection-content" style="margin-bottom: 10px;">
        <div class="selection" *ngFor="let project of showProjectList;">
            <input type="checkbox" required [checked]="project.checked" [id]="project.projectId" name="project" required  (change)="selectProjectIds(project.projectId, $event);" >
            <label [for]="project.projectId" class="selection-label">{{project.projectName}}</label> <br>
        </div>
    </div>        
    <div *ngIf="!loader && showProjectList.length == 0" class="selection">
        <h3 class="noData">
            Could not find any projects. Please check if projects are created and assigned to you.
        </h3>
    </div>
    <div *ngIf="!loader&& showProjectList.length > 0" class="mdl-dialog__actions" style="margin-top: 15px;margin-bottom: 15px;">
        <button class="btn btn-primary btn-default sub-btn" [disabled] = "disabled" (click)="assignStudy()" name="button" type="submit" style="cursor:hand"><h2 class="sub-text">Submit</h2></button>
        <button class="btn can-btn" type="button" (click)="cancel()"><h2 class="cal-text">Cancel</h2></button>
    </div>
</div>
<app-loader class="spinner" *ngIf="loader"></app-loader>