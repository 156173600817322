<div>
  <div class="dialog-header">
      <img class="cancel-icon" (click)="cancel()" src="../../assets/images/cancel@2x.png" alt="Cancel"/>
      <h3 class="title">Sync S3 bucket</h3>
  </div>
  <div class="content">
    <h2 class="content-1">This operation will sync all the objects from your S3 bucket. This might take few minutes to complete. Do you want to proceed? </h2>
  </div>
  <div class="mdl-dialog__actions" style="margin-top: 15px; margin-bottom: 15px;">
    <button class="btn btn-primary dele-btn" type="button" (click)="syncS3()"><h2 class="del-text">Sync</h2></button>
    <button class="btn  can-btn" type="button"  (click)="cancel()"><h2 class="cal-text">Cancel</h2></button>
  </div>
</div>