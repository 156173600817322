<div class="container">
  <!-- breadcrumb -->
  <nav aria-label="breadcrumb">
      <ol class="breadcrumb bc-custom">
        <li class="breadcrumb-item"><a class="bc-item" routerLink="/admin/budget-tracking"> Budgets </a></li>
        <li class="breadcrumb-item" aria-current="page"><a class="bc-item" (click)="openOrganisationDetails()"> {{ orgName }} </a></li>
        <li class="breadcrumb-item" aria-current="page"><a class="bc-item-active"> {{ projectName }} </a></li>
      </ol>
  </nav>
  <!-- <div class="back-layout" (click)="goBack()">
    <img class="back-icon" src="../../../assets/images/pagination-prev-active.png" />
    <h3 class="back-text text-white font-class">Back</h3>
  </div> -->
  <div class="project-info-layout">
    <div class="project-name-layout">
      <span class="project-name text-white font-class">{{ projectName }}</span>
    </div>
    <div class="project-budget-layout">
      <div class="project-budget-wrapper">
        <div class="project-budget-info">
            <span class="budget-title font-class">Total Project Budget</span>
            <span class="budget-value font-class">{{currencySymbol}} {{budgetAllotted}}</span>
        </div>
      </div>
      <div class="project-budget-wrapper">
        <div class="project-budget-info">
            <span class="budget-title font-class">Total Direct Cost</span>
            <span class="budget-value font-class">{{currencySymbol}} {{budgetConsumed}}</span>
        </div>
      </div>
      <div class="project-budget-wrapper">
        <div class="project-budget-info">
            <span class="budget-title font-class">Available Budget</span>
            <span class="budget-value font-class">{{currencySymbol}} {{budgetAvailable}}</span>
        </div>
      </div>
    </div>

    <!-- mobile responsive -->
    <div class="project-budget-layout-mobile">
      <div style="flex: 1">
          <div class="project-budget-info">
              <span class="budget-title font-class">Total Project Budget</span>
              <span class="budget-value font-class">{{currencySymbol}} {{budgetAllotted}}</span>
          </div>
          <div class="project-budget-info" style="margin-top: 10px;">
              <span class="budget-title font-class">Total Direct Cost</span>
              <span class="budget-value font-class">{{currencySymbol}} {{budgetConsumed}}</span>
          </div>
      </div>
      <div style="flex: 1">
          <div class="project-budget-info">
              <span class="budget-title font-class">Available Budget</span>
              <span class="budget-value font-class">{{currencySymbol}} {{budgetAvailable}}</span>
          </div>
      </div>
    </div>
  </div>

  <div>
    <div class="container budget-breakdown">
      <div class="row budget-breakdown-header">
        <div class="col align-self-center font-class text-left budget-header">
          <h3 >Researcher-wise Budget Breakdown</h3>
        </div>
        <!-- <div class="col-auto pr-0 text-right">
          <button class="export-btn font-class" (click)="exportCSV()">Export as CSV</button>
        </div> -->
      </div>
      <!-- Budget Breakdown Projects list -->
      <div class="project-table-layout">
        <div class="projects-header d-flex w-100">
          <div class="title">
            Researcher Name
          </div>
          <div class="title">
            Total Project Budget
          </div>
          <div class="title">
            Direct Cost
          </div>
          <!-- <div class="title">
            Shared Consumed Budget
          </div>
          <div class="title">
            Available Budget
          </div> -->
          <div class="title">
            Products Used
          </div>
        </div>
        <div class="projects" *ngIf="researchers && researchers.length > 0">
          <div class="project-row d-flex w-100 py-4 my-3 cursor-disable" *ngFor="let researcher of researchers" (click)="openProductBudgetScreen(researcher)">
            <div class="project-column">
              <span class="researcher-name font-class">{{ researcher.displayName }}</span>
            </div>
            <div class="project-column">
              <span>{{ currencySymbol }} {{ getRoundOffBudget(researcher.budgetAllocated) }}</span>
            </div>
            <div class="project-column">
              <span>{{ currencySymbol }} </span>
              <span>{{ getRoundOffBudget(researcher.budgetConsumed) }}</span>
            </div>
            <!-- <div class="project-column">
              <span>{{ currencySymbol }} {{ getRoundOffBudget(researcher.sharedConsumedBudget) }}</span>
            </div>
            <div class="project-column">
              <div class="project-products">
                <img class="product-info-icon" src="../../../assets/images/info.png"
                title="{{ budgetData }} {{getRoundOffBudget(researcher.budgetAvailable)}} = {{researcher.budgetCal}}">
                <span>{{ currencySymbol }} {{ getRoundOffBudget(researcher.budgetAvailable) }}</span>
              </div>
            </div> -->
            <div class="project-column">
              <div class="project-products">
                <img class="product-info-icon" src="../../../../assets/images/info.png"
                  title="{{researcher.productsInString}}">
                <span class="my-product-value researcher-product font-class" *ngIf="researcher.productList && researcher.productList.length > 0">{{researcher.productList | filterProduct}}</span>
                <span class="my-product-value researcher-product font-class" *ngIf="researcher.productList && researcher.productList.length === 0">No products configured</span>
              </div>
            </div>
          </div>
        </div>

        <!-- No product available -->
        <div *ngIf="researchers && researchers.length === 0 && !loader">
          <h3 style="margin: 40px; text-align: center;">No Researcher Assigned!</h3>
        </div>
      </div>

      <!-- mobile responsive -->
      <div class="project-cards-layout" *ngIf="researchers && researchers.length > 0">
        <div class="project-card" *ngFor="let researcher of researchers" (click)="openProductBudgetScreen(researcher)">
          <ul class="list-group">
            <div class="d-flex justify-content-between mb-2">
              <div class="project-card-title font-class">Researcher Name</div>
              <div class="project-card-project-name">{{ researcher.displayName }}</div>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <div class="project-card-title font-class">Total Project Budget</div>
              <div class="project-card-value">{{ currencySymbol }} {{ getRoundOffBudget(researcher.budgetAllocated) }}</div>
            </div>
            <div class="d-flex justify-content-between mb-2">
                <div class="project-card-title project-card-line-height font-class">Direct Cost</div>
                <div class="project-card-value">{{ currencySymbol }} {{ researcher.budgetConsumed }}</div>
            </div>
            <!-- <div class="d-flex justify-content-between mb-2">
              <div class="project-card-title project-card-line-height font-class">Shared Consumed Budget</div>
              <div class="project-card-value">{{ currencySymbol }} {{ getRoundOffBudget(researcher.sharedConsumedBudget) }}</div>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <div class="project-card-title font-class">Available Budget</div>
              <div class="project-card-value">{{ currencySymbol }} {{ getRoundOffBudget(researcher.budgetAvailable) }}</div>
            </div> -->
            <div class="d-flex justify-content-between mb-2">
              <div class="project-card-title project-card-line-height font-class">Products Used</div>
              <div class="project-card-value" style="display: flex;">
                  <img class="product-info-icon" src="../../../assets/images/info.png"
                  title="{{researcher.productsInString}}">
                <span class="my-product-value researcher-product font-class" *ngIf="researcher.productList && researcher.productList.length > 0">{{researcher.productList | filterProduct}}</span>
                <span class="my-product-value researcher-product font-class" *ngIf="researcher.productList && researcher.productList.length === 0 && !loader">No products configured</span>
              </div>
            </div>
          </ul>
        </div>
      </div>

      <!-- Load more projects -->
      <!-- <div class="row load-more-btn-wrapper" *ngIf="products && products.length > 0">
        <button class="load-more-btn">Load More</button>
      </div> -->
    </div>
  </div>
</div>
<app-loader class="spinner" *ngIf="loader"></app-loader>
