import { Component, OnInit, ElementRef, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from "@angular/router";
import { ResearchService } from "../_services/research.service";
import { DashboardService } from '../_services/dashboard.service';
import { HeaderComponent } from '../components/header/header.component';
import { ProjectActionComponent } from '../principal/project-action/project-action.component';
import { ArchiveProjectComponent } from './archive-project/archive-project.component';
import { MdlDialogReference } from "@angular-mdl/core";
import { MdlDialogService } from '@angular-mdl/core';
import { ProductUtilService } from "../_services/productUtil.service";
import { TabsetComponent, TabDirective } from 'ngx-bootstrap/tabs';
import { ToastrService } from 'ngx-toastr';
import {Globals} from "../globals";
import { AddBudgetComponent } from './add-budget/add-budget.component';
import { ServiceActionsService} from "../_services/service-actions.service";
import { ConfirmationDialog } from '../dialog/dialog.component';


@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.css', '../active-product/active-product.component.css'],
  providers: [HeaderComponent, Globals]
})
export class ProjectDetailsComponent implements OnInit {
  projectName;
  updatedName;
  accountId = '';
  accountIdList = [];
  budgetAvailable = 0;
  budgetConsumed = 0;
  budgetConsumedByResearcher = 0;
  researcherExistingIdList = [];
  researcherValidateList = [];
  researcherIdList = [];
  projectDescription;
  researchIds = [];
  researchName: any = [];
  assignedIds = [];
  selectedId = '';
  loader = false;
  projects = [];
  projectId;
  accountIdArr = [];
  fAccountIdList = [];
  thresholdPercentage;
  budgetConsumedPercentage;
  projectStatus;
  projectImg;
  gotProjectData = false;
  gotEventsData = true;
  gotMyProductsData = true;
  gotAvailableProducts = true;
  projectLoader = true;
  showSpinnerForRepair = false;
  showSpinner = false;
  eventsCurrentPageLowerCount = 1;
  eventsCurrentPageUpperCount = 10;
  eventsTotalRecords = 0;
  eventsPage = 1;
  eventsTotalPages = 1;
  eventsLimit = 10;
  category = 'available';
  categoryName = 'Available Products';
  availableProductsList = [];
  activeProductsList = [];
  selectedProduct;
  totalPages;
  totalProducts = 0;
  totalProductsActive = 0;
  activeProductCount;
  availableProductCount;
  currentPage = 1;
  pageSize = 12;
  page = 1;
  limit = 12;
  currentPageLowerCountActive = 1;
  currentPageLowerCount = 1;
  currentPageUpperCount = 10;
  currentPageUpperCountActive = 10;
  activeProductCurrentPage = 1;
  allProductCurrentPage = 1;
  activeProductLimit = 12;
  allProductLimit = 12;
  activeProductTotalPages = 1;
  allProductTotalPages = 1;
  activeProductCurrentPageLowerCountActive = 1;
  activeProductCurrentPageLowerCount = 1;
  activeProductCurrentPageUpperCount = 10;
  activeProductCurrentPageUpperCountActive = 10;
  activeProductPageSize = 12;
  allProductPageSize = 12
  allProductCurrentPageUpperCountActive = 10;
  allProductCurrentPageLowerCountActive = 1;
  searchKeyForActiveProduct='';
  searchKeyForAllProduct='';
  searchKeyForAvailableProduct='';
  searchExecKey = '';
  isCardView = true;
  isSearchedList = false;
  logLoader = true;
  auditTrail = [];
  loadMore = false;
  provisionIdForLog;
  servicemenu;
  searchValue = false;
  searchKeyword = false;
  searchValueTemp = '';
  noDataTemplate;
  noDataMsg;
  filterListForMyProducts = ['All', 'Active', 'Terminated'];
  filterList = [];
  categoryFilterForAvailableProduct;
  categoryFilterForActiveProduct;
  activeProductsFilter;
  activeProductFilterBy;
  availableProductFilterBy;
  placeHolder;
  showAvailableProducts = true;
  projectEvents = [];
  userName = sessionStorage.getItem('currentUser');
  tabName;
  tabHeader;
  updateBTN = true;
  private parametersObservable: any;
  currentTab = 1;
  checkIfResearcherIsPartOfProject = false;
  showActiveProductsTab = false;
  showAvailableProductsTab = false;
  checkForTabs = false;
  totalBudgetAllotted: any = 0;
  totalBudgetConsumed: any = 0;
  totalcurrentMonthCost: any = 0;
  failedReason: string = "";
  dependencyChecksStatus: string = "";
  projectOwner;
  allowProjectActionsOverride = true;
  eventS = this.getEventSource(`/stream_events`);
  accountName;
  syncType;
  byocCatalogs;
  productSyncType = [];
  productSyncTypeVal;
  showTagFiled = false;
  productCatalogTag;
  tagKey;
  tagValue;
  isProjectEdit = false;
  isEdit = false;
  projectSyncType= false;
  manageSyncType = false;
  isValueChanged = false;
  searchUser = '';
  searchUserExecKey = '';
  selectSearchVal = false;
  selectValChanged = false;
  checkedValue = [];
  unCheckedValue =[];
  studies;
  studyList = [];
  allProductsList = [];
  allProductCount;
  allProductFilterBy;
  getAllProducts = false;
  showProductAction = true;
  getInstanceIp;
  bucketName;
  s3BucketTotalrecords;
  resourceType;
  projectType;
  showAddProducts = true;
  checkEgressStore:any = false;
  unCheckedResearchers=[];
  storageType: string;
  loginStrategy;
  showMangeUsers = true;
  showLinkedExternalGroup = false;

  constructor(private researchService: ResearchService,
    private route: ActivatedRoute, private router: Router, private dashboardService: DashboardService,
    private serviceAction: ServiceActionsService,
    private callHelpComp: HeaderComponent, private dialog: MdlDialogService,
    private productUtil: ProductUtilService,private elementRef:ElementRef,
    private apiService: DashboardService,
    private globals: Globals,private toastr: ToastrService) {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;

      router.events.subscribe((val) => {
        const navVal = val instanceof NavigationStart;
        if(navVal == true) {
          this.eventS.close();
        }
      });

  }
  // @ViewChild("focusTrg") trgFocusEl: ElementRef;
  // @ViewChild("searchBoxFocus") searchBoxFocus: ElementRef;

  ngOnInit() {
      //get SSE on page load
      this.dashboardService.checkIfPI();
      this.loginStrategy = sessionStorage.getItem('strategy');
      if (this.loginStrategy === 'saml' || this.loginStrategy === 'azure-ad-oidc') {
        this.showMangeUsers = false;
        this.showLinkedExternalGroup = true;
      }
      const projectStatus = this.route.snapshot.queryParamMap.get('showTab');
      if(projectStatus == 'active' || projectStatus == 'myProducts') {
        this.currentTab = 3;
      } else if(projectStatus == 'availableProducts') {
        this.currentTab = 2;
      }
      this.serverStreamEvent();
      this.projectId = this.route.snapshot.params['projectId'];
      this.getResearchers();
      this.filterListForMyProducts = ['All', 'Active', 'Terminated'];
      this.getProductTypes();
      this.getProjectEvents();
      this.getProjects(this.projectId);
      this.getStudies();
  }

  //If project is in creating state show events tab and if it is in active state, show Active products / Available products (based on active product count)
  showTabsOnLoad() {
    this.route.queryParams.subscribe(map => map);
    this.tabName = this.route.snapshot.queryParams['showTab'];
    if (this.tabName == 'myProducts') {
      this.currentTab = 3;
      return;
    } else if (this.tabName == 'availableProducts') {
      this.currentTab = 2;
      return;
    }else if (this.tabName == 'projectDetails' || !this.checkIfResearcherIsPartOfProject) {
      this.currentTab = 0;
      return;
    }
    switch (this.projectStatus) {
      case 'Creating':
        this.currentTab = 1;
        break;
      case 'Active':
        if (this.showActiveProductsTab) {
          this.currentTab = 3;
          return;
        } else if (this.showAvailableProductsTab) {
          this.currentTab = 2;
        }else{
          this.currentTab = 1;
        }
        break;
      case 'Failed':
        this.currentTab = 1;
        break;
      default:
        this.currentTab = 1;
    }
  }

  getEventSource(url: string): EventSource {
    return new EventSource(url, { withCredentials: true });
  }

  showTabsAfterPageUpdate(){
    this.route.queryParams.subscribe(map => map);
    this.tabName = this.route.snapshot.queryParams['showTab'];
    if(this.tabName == 'projectDetails'){
      this.currentTab = 0;
    }
  }

  getActiveProductsOnLoad(projectId){
    this.loader = true;
    this.projectLoader = true;
    this.researchService.getPrincipalProjectsUsingId(projectId).subscribe((data:any) => {
      this.projects = data;
      this.loader = false;
      this.projectLoader = false;
      this.projectStatus = data[0].projectStatus;
      this.failedReason = data[0].dependencyChecksMessage;
      this.dependencyChecksStatus = data[0].dependencyChecksStatus;
      this.projectName = data[0].name;
      this.accountId = data[0].account_id;
      sessionStorage.setItem('accountId', this.accountId);
      this.projectDescription = data[0].description;
      this.checkIfResearcherIsPartOfProject = data[0].isPartOfProject;
      if (data[0].researchers) {
        let rname = [];
        for (const name of data[0].researcherDetails) {
          if (name) {
            rname.push(name.displayName);
          }
        }
        this.researchName = rname.toString().replace(/,/g, ", ");
        this.researchIds = data[0].toString().replace(/,/g, ", ");
        this.researcherExistingIdList = data[0].researchers;
        const getRE = this.researcherExistingIdList.map((e) => e);
        this.assignedIds = getRE;
        this.getValidateResearchers();
        this.tabName = this.route.snapshot.queryParams['showTab']
      }
      this.getBudgetCardsData();
      this.categoryFilterForActiveProduct = 'Active';
      this.getAllActiveProducts('Active');
    }, error => {
      this.loader = false;
      if(error && error.status === 401){
        let errBody= error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }else if (error && error.status === 403) {
        const err = error.error;
        this.toastr.error(err.error, 'Error', this.globals.tosterOverride);
        this.router.navigate(['pageNotFound']);
      }
    });
  }

  getAvailableProductsOnLoad(projectId){
    this.loader = true;
    this.projectLoader = true;
    this.researchService.getPrincipalProjectsUsingId(projectId).subscribe((data:any) => {
      this.projects = data;
      this.loader = false;
      this.projectLoader = false;
      this.projectStatus = data[0].projectStatus;
      this.failedReason = data[0].dependencyChecksMessage;
      this.dependencyChecksStatus = data[0].dependencyChecksStatus;
      this.projectName = data[0].name;
      this.accountId = data[0].account_id;
      sessionStorage.setItem('accountId', this.accountId);
      this.projectDescription = data[0].description;
      this.checkIfResearcherIsPartOfProject = data[0].isPartOfProject;
      if (data[0].researchers) {
        let rname = [];
        for (const name of data[0].researcherDetails) {
          if (name) {
            rname.push(name.displayName);
          }
        }
        this.researchName = rname.toString().replace(/,/g, ", ");
        this.researchIds = data[0].toString().replace(/,/g, ", ");
        this.researcherExistingIdList = data[0].researchers;
        const getRE = this.researcherExistingIdList.map((e) => e);
        this.assignedIds = getRE;
        this.getValidateResearchers();
        this.tabName = this.route.snapshot.queryParams['showTab']
      }
      this.getBudgetCardsData();
      this.searchKeyForActiveProduct = '';
      this.searchKeyForAllProduct = '';
      //this.currentTab = 2;
      this.currentPage = 1;
      this.getAllAvailableProducts('All', this.accountId);
      this.categoryFilterForAvailableProduct = 'All';

    }, error => {
      this.loader = false;
      if(error && error.status === 401){
        let errBody= error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }else if (error && error.status === 403) {
          const err = error.error;
          this.toastr.error(err.error, 'Error', this.globals.tosterOverride);
          this.router.navigate(['pageNotFound']);
      }
    });
  }

  getStudies() {
    this.researchService.getStudyProject(this.projectId).subscribe(res => {
      this.studies = res;
      for(let i = 0; i < this.studies.length; i++){
        if((this.studies[i].projectId).includes(this.projectId)){
          this.studyList.push(this.studies[i].name);
        }
      }
    }, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    });
  }

  getProjects(projectId) {
    this.loader = true;
    this.projectLoader = true;
    this.researchService.getPrincipalProjectsUsingId(projectId).subscribe((data:any) => {
      this.projects = data;
      this.loader = false;
      this.projectLoader = false;
      this.gotProjectData = true;
      this.projectOwner = data[0].created_by;
      this.projectStatus = data[0].projectStatus;
      this.projectName = data[0].name;
      this.updatedName = this.projectName;
      this.accountName = data[0].account_name;
      this.allowProjectActionsOverride = data[0].allowProjectActionsOverride;
      this.checkEgressStore = data[0].isEgressStoreEnabled;
      if(data[0].project_type === 'secure'){
        this.showAddProducts = false;
        this.projectType = "Secure Research";
      } else if (data[0].project_type === 'DataLibrary') {
        this.projectType = "Data Library";
      }else if (data[0].project_type === 'IngressGateway') {
        this.projectType = "Ingress Gateway";
      }else {
        this.projectType = "Regular";
      }
      this.byocCatalogs = [{
        viewValue: 'Bring all catalog items',
        value: 'ownCatalog',
        checked: false
      },
      {
        viewValue: 'Bring specific catalog items',
        value: 'ownCatalogWithTag',
        checked: false
      }
      ];
      this.productSyncTypeVal = data[0].syncType;
      this.productCatalogTag = data[0].productCatalogTag;
      if(this.projectStatus === 'Archived'){
        this.router.navigate(['/principal']);
        this.toastr.error( `Project ${this.projectName} has been archived and is no longer available.`,'Error', this.globals.tosterOverride);
      }
      this.accountId = data[0].account_id;
      sessionStorage.setItem('accountId', this.accountId);
      this.projectDescription = data[0].description;
      this.projectImg = data[0].img_src;
      this.checkIfResearcherIsPartOfProject = data[0].isPartOfProject;
      this.failedReason = data[0].dependencyChecksMessage;
      this.dependencyChecksStatus = data[0].dependencyChecksStatus;
      for(let i=0; i< this.byocCatalogs.length; i++){
          if(this.byocCatalogs[i].value == 'ownCatalog' && data[0].syncType.includes('ownCatalog')){
            this.byocCatalogs[i].checked = true;
          }else if(this.byocCatalogs[i].value == 'ownCatalogWithTag' && data[0].syncType.includes('ownCatalogWithTag')){
            this.byocCatalogs[i].checked = true;
          }
      }
      // if(this.projectStatus == 'Active'){
      //   this.helpComponent();
      // }
      if (data[0].researchers) {
        let rname = [];
        for (const name of data[0].researcherDetails) {
          if (name) {
            rname.push(name.displayName);
          }
        }
        this.researchName = rname.toString().replace(/,/g, ", ");
        this.researchIds = data[0].toString().replace(/,/g, ", ");
        this.researcherExistingIdList = data[0].researchers;
        const getRE = this.researcherExistingIdList.map((e) => e);
        this.assignedIds = getRE;
        this.getValidateResearchers();
        this.tabName = this.route.snapshot.queryParams['showTab']
      }
      //If User is not part of the project, show only project details and events tab
      if(!this.checkIfResearcherIsPartOfProject){
        this.currentTab = 0;
      }
      // after loading all projects data, get budget cards data(total-> allotted, consumed, available)
      this.getBudgetCardsData();
      this.categoryFilterForActiveProduct = sessionStorage.getItem('Selected') ?  sessionStorage.getItem('Selected') : 'Active';
      this.categoryFilterForAvailableProduct = 'All';
      // If user is part of project then only load the available products and my products
      if(this.checkIfResearcherIsPartOfProject){
        this.getAvailableAndProvisionedProductCount(this.categoryFilterForActiveProduct);
        this.getAllActiveProducts(this.categoryFilterForActiveProduct);
        this.getProjectwiseProvisionedProducts(this.categoryFilterForActiveProduct);
        this.getAllAvailableProducts('All', this.accountId);
      }
      if(!this.checkIfResearcherIsPartOfProject){
        if(this.loginStrategy == 'saml' || this.loginStrategy == 'azure-ad-oidc'){
          this.toastr.info('Please contact your Active Directory administrator.', '', this.globals.tosterOverride);
        }else{
          this.toastr.info(`You don't have access to this project. Please contact the project owner ${this.projectOwner}`, '', this.globals.tosterOverride);
        }
      }
    }, error => {
      this.loader = false;
      if (error && error.status === 403){
        const err = error.error;
        this.toastr.error(err.error, 'Error', this.globals.tosterOverride);
        this.router.navigate(['pageNotFound']);
      }else if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }else {
        const err = error.error;
        this.toastr.error(err, 'Project Error', this.globals.tosterOverride);
      }
    });
  }

  getAvailableAndProvisionedProductCount(filterBy) {
    this.researchService.getAvailableAndProvisionedProductCount(this.currentPage, this.pageSize, this.accountId, this.projectId ,filterBy).subscribe((res:any) => {
      const { availableDataCount, provisionedDataCount } = res.data;
      if(availableDataCount > 0 || provisionedDataCount > 0) {
        if(provisionedDataCount > 0) {
          this.showActiveProductsTab = true;
        } else {
          this.showAvailableProductsTab = true;
        }
      }
      if(!this.checkForTabs){
        this.showTabsOnLoad();
      }
    }, error => {

    });
  }

  withSpaceAndComma(data){
    let syncTypeArr = [];
    if(data.includes('standard')){
      syncTypeArr.push('Standard catalog')
    }
    if(data.includes('ownCatalog')){
      syncTypeArr.push('Bring all catalog items (This option will sync all the products which have the required launch permission)');
    }
    if(data.includes('ownCatalogWithTag')){
      syncTypeArr.push('Bring specific catalog items (This option will sync only products which have the required tag)');
    }
    return syncTypeArr;
  }

  selectBYOCSyncType(syncType, event, i) {
    this.isValueChanged = true;
    this.byocCatalogs.forEach(function (catalog, j) {
      if (catalog.value == syncType.value) {
        catalog.checked = ! catalog.checked
      }else{
        catalog.checked = false;
      }
    });
    if (event.target.checked) {
      if(this.productSyncType.includes('standard')){
        this.productSyncType = ['standard'];
    }else {
      this.productSyncType.length = 0;
    }
      this.productSyncType.push(syncType.value.toString());
      if(syncType.value === 'ownCatalogWithTag'){
        this.showTagFiled = true;
      }else{
        this.showTagFiled = false;
        this.tagKey = '';
        this.tagValue = '';
        this.productCatalogTag = undefined;
      }
    }else{
      const index = this.productSyncType.indexOf(syncType.value);
      if (index !== -1) {
        this.productSyncType.splice(index, 1);
      }
      this.showTagFiled = false;
      if(this.productSyncType.includes('ownCatalogWithTag')){
        this.showTagFiled = true;
      }else{
        this.showTagFiled = false;
        this.tagKey = '';
        this.tagValue = '';
        this.productCatalogTag = undefined;
      }
    }
    if(this.productSyncTypeVal.includes('standard')){
      this.productSyncType.push('standard');
    }
  }

  updateProjectName(currentName, existingName: string) {
    const obj = {
      projectId: this.projectId,
      projectName: currentName,
      originalName: existingName
    };
    if (currentName === existingName) {
      this.projectSyncType = false;
      this.isProjectEdit = false;
      this.toastr.info("The new project name provided is the same as the existing name. Nothing to do!", '', this.globals.tosterOverride);
      return;
    }
    this.researchService.updateProjectName(obj).subscribe(
      (res: any) => {
        if (res.code === 1001) {
          let t = new Date().getTime();
          this.toastr.success(`The project name was updated successfully`, '', this.globals.tosterOverride);
          this.router.navigate([`/project-details/${this.projectId}`], { queryParams: { showTab: 'projectDetails', updatedData: t } });
        }
      }, error => {
        if (error && error.status === 401) {
          let errBody = error.error;
          this.toastr.error(errBody.err, '', this.globals.tosterOverride);
          this.router.navigate(['login']);
        } else {
          this.toastr.error('The project name could not be updated.', "", this.globals.tosterOverride);
        }
      });
  }

  isInvalidProjectName(projectName: string): boolean {
    if (!projectName || projectName.length < 1 || projectName.length > 32 || !projectName.match(/^\S.*$/)) {
      return true;
    }
    return false;
  }

  cancelEdit(pname) {
    this.projectSyncType=false;
    this.isProjectEdit = false;
    this.updatedName = pname;
  }

  updateAddProducts(){
    if(!this.isValueChanged){
      return;
    }
    let data = {
      "projectId": this.projectId,
      "productSyncType": this.productSyncType
    }
    if(this.productSyncType.includes('ownCatalogWithTag') && this.productCatalogTag != undefined){
      data['productCatalogTag'] = this.productCatalogTag;
    }
    this.researchService.updateAddProductForProject(data).subscribe(res =>{
      let t = new Date().getTime();
      this.router.navigate([`/project-details/${this.projectId}`],{ queryParams: { showTab:'projectDetails', updatedData: t}});
    }, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    }
    )
  }

  submitTag(){
    if (!this.tagKey.trim() || !this.tagValue.trim()) {
      return;
    }
    this.productCatalogTag = {
      'key': this.tagKey,
      'value': this.tagValue
    }
    this.showTagFiled = false;
  }

  removeTag(){
    this.tagKey = '';
    this.tagValue = '';
    this.productCatalogTag = undefined;
    this.showTagFiled = true;
  }

  getProjectThresholdClass(project) {
    if (project.budgetConsumedPercentage >= project.thresholdPercentage) {
      return { 'threshold-cross-above': true, 'threshold-cross-below': false };
    } else {
      return { 'threshold-cross-above': false, 'threshold-cross-below': true };
    }
  }

  getStatusColor(status) {
    let statusV = 'status-paused';
    switch (status.toLowerCase()) {
      case 'active':
        statusV = 'status-running';
        break;
      case 'creating':
        statusV = 'status-paused';
        break;
      case 'failed':
        statusV = 'status-stopped';
        break;
      case 'stopped':
        statusV = 'status-stopped';
        break;
    }
    return statusV;
  }

  getValidateResearchers() {
    this.researchService.getValidateResearcherList(this.projectId).subscribe(
      (res:any) => {
        this.researcherValidateList = res;
      }, error => {
        if(error && error.status === 401){
          let errBody = error.error;
           this.toastr.error(errBody.err, '', this.globals.tosterOverride);
           this.router.navigate(['login']);
         }
  });
  }

  projectActions(action, projectStatus): void {
    const projData = {
      "projectId": this.projects[0]._id.toString(),
      "projectName": this.projects[0].name,
      "action": action,
      "projectStatus": projectStatus,
      "accountId": this.projects[0].account_id
    }
    this.dashboardService.getDialogBox().subscribe((data:any) => {
      if(data.status === 200){
    this.dialog.showCustomDialog({
      component: ProjectActionComponent,
      providers: [{ provide: 'data', useValue: projData }],
      isModal: true,
      styles: {
        "height": "auto",
        "width": "40%",
        "border": "1px solid #FFFFFF33",
        "border-radius": "10px",
        "background-color": "#07102A",
        "padding": "0px"
      },
      clickOutsideToClose: true,
      enterTransitionDuration: 400,
      leaveTransitionDuration: 400,
    })}}, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    })
  }

  getFilterName(data) {
    if (!data) {
      return data;
    }
    const getLen = data.length;
    if (getLen > 10) {
      data = data.slice(0, 10) + '...';
    }
    return data;
  }

  getProductTypes() {
    this.researchService.getListOfProductTypes().subscribe((res:any) => {
      this.filterList = res;
    }, error => {
      console.error(error);
      if(error && error.status === 401){
        let errBody= error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
      //this.toastr.error(`${this.bucketName} - `+err.error, 'Error', this.globals.tosterOverride)
    }
    )
  }

  dataBindingAvailableProduct() {
    try {
      for (let i = 0; i < this.availableProductsList.length; i++) {
        let iName;
        this.availableProductsList[i]['imageUrl'] = (this.availableProductsList[i]['vendor'] === 'AZURE') ? '/assets/images/azure_icon/azure.png' : '/assets/images/aws_icon/aws.png';
        if (this.availableProductsList[i].tags.length > 0) {
          //iName = this.availableProductsList[i].tags[0].Value.toLowerCase();
          const serviceName = this.productUtil.getServiceFromTags(this.availableProductsList[i].tags);
          const getImgLinkObj = this.productUtil.getImageForProduct(serviceName);

          if (getImgLinkObj) {
            this.availableProductsList[i]['imageUrl'] = getImgLinkObj['url'];
          }
          const viewMoreDetails = this.availableProductsList[i].tags.filter(tag => {
            return tag["Key"] === "DetailsLink"
          })
          if (viewMoreDetails.length > 0) {
            const viewMoreLink = viewMoreDetails[0].Value;
            this.availableProductsList[i]["detailsLink"] = viewMoreLink
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  getAllAvailableProducts(filterBy, account_id) {
    this.availableProductFilterBy = filterBy;
    this.placeHolder = "Search available products";
    this.loader = true;
    this.gotAvailableProducts = true;
    this.researchService.getListOfResearchProducts(this.currentPage, this.pageSize, account_id, this.projectId ,filterBy).subscribe((res:any) => {
      this.loader = false;
      this.availableProductsList = res.docs;
      if(this.availableProductsList.length == 0){
        this.gotAvailableProducts = false;
        this.showAvailableProductsTab = false;
      }else{
        this.showAvailableProductsTab = true;
      }
      this.totalProducts = res.total;
      this.totalPages = res.pages;
      this.availableProductCount = res.total;
      if (filterBy == 'All') {
        this.noDataTemplate = "No products assigned to the project catalog"
        this.noDataMsg = "Please contact your project owner to assign products that you can use"
      } else {
        this.noDataTemplate = "We could not find any products that matched your filter"
        this.noDataMsg = "";
      }
      //Calculation for lower & upper count per page
      this.currentPageLowerCount = 1 + ((this.currentPage - 1) * this.pageSize);
      if (res.docs.length < 12) {
        this.currentPageUpperCount = res.docs.length + (this.pageSize * (this.currentPage - 1));
      } else {
        this.currentPageUpperCount = this.pageSize * this.currentPage;
      }
      this.dataBindingAvailableProduct();
      // if(!this.checkForTabs){
      //   setTimeout(()=>this.showTabsOnLoad(),200);
      // }

    }, error => {
      this.loader = false;
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    });
  }

  getAvailableProductsFilter(data)  {
    //filters for available products
    this.searchKeyForActiveProduct = '';
    this.searchKeyForAllProduct = '';
    this.currentTab = 2;
    this.currentPage = 1;
    this.getAllAvailableProducts('All', this.accountId);
    this.categoryFilterForAvailableProduct = 'All';
  }

  getActiveProductsFilter(data){
    this.searchKeyForAvailableProduct = '';
    this. searchKeyForAllProduct ='';
    this.currentTab = 3;
    this.activeProductCurrentPage = 1;
    this.categoryFilterForActiveProduct = sessionStorage.getItem('Selected') ? sessionStorage.getItem('Selected') : 'Active';
    this.getAllActiveProducts(this.categoryFilterForActiveProduct);
  }

  getAllProductsFilter(data){
    this.searchKeyForAvailableProduct = '';
    this.searchKeyForActiveProduct = '';
    this.currentTab = 4;
    this.allProductCurrentPage = 1;
    this.categoryFilterForActiveProduct = sessionStorage.getItem('Selected') ? sessionStorage.getItem('Selected') : 'Active';
    this.getProjectwiseProvisionedProducts(this.categoryFilterForActiveProduct);
  }

  tabChanged(event) {
    this.checkForTabs = true;
    if(event.index == 2){
      this.getAvailableProductsFilter(event);
    }else if(event.index==3){
      this.getActiveProductsFilter(event);
    }else if(event.index==4){
      this.getAllProductsFilter(event);
    }
  }

  catalogOrder(availPObj) {
    const productId = availPObj.productId;
    const name = encodeURIComponent(availPObj.name);
    const stdCatalogItemId = availPObj.stdCatalogItemId;
    localStorage.setItem(productId, JSON.stringify(availPObj));
    const projectName = encodeURIComponent(this.projectName);
    sessionStorage.setItem('Level', '1');
    sessionStorage.setItem('projectId', this.projectId);
    sessionStorage.setItem('projectName', this.projectName);
    const vendor = availPObj.vendor;
    this.router.navigate(['/order/' + projectName + '/' + this.accountId + '/' + this.projectId + '/' + productId + '/' + name + '/' + stdCatalogItemId + '/' + vendor]);  }

  dataBindingActiveProduct() {
    for (let i = 0; i < this.activeProductsList.length; i++) {
      try {
        //const iName = this.activeProductsList[i].Tags[0].Value.toLowerCase();
        this.activeProductsList[i]['imageUrl'] = (this.activeProductsList[i]['ProvisionedProductType'] === 'ARM_TEMPLATE')? '/assets/images/azure_icon/azure.png' : '../../assets/images/aws_icon/aws.png';
        ;
        const serviceName = this.productUtil.getServiceFromTags(this.activeProductsList[i].Tags);
        const getImgLinkObj = this.productUtil.getImageForProduct(serviceName);
        if (getImgLinkObj) {
          this.activeProductsList[i]['imageUrl'] = getImgLinkObj['url'];
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  dataBindingAllProduct() {
    for (let i = 0; i < this.allProductsList.length; i++) {
      try {
        //const iName = this.activeProductsList[i].Tags[0].Value.toLowerCase();
        this.allProductsList[i]['imageUrl'] = (this.allProductsList[i]['ProvisionedProductType'] === 'ARM_TEMPLATE')? '/assets/images/azure_icon/azure.png' : '../../assets/images/aws_icon/aws.png';
        const serviceName = this.productUtil.getServiceFromTags(this.allProductsList[i].Tags);
        const getImgLinkObj = this.productUtil.getImageForProduct(serviceName);
        if (getImgLinkObj) {
          this.allProductsList[i]['imageUrl'] = getImgLinkObj['url'];
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  private getAllSearchActiveProducts() {
    this.loader = true;
    this.getAllProducts = false;
    this.researchService.getSearchProvisionedProducts(this.activeProductCurrentPage, this.activeProductPageSize, this.accountId, this.projectId, this.activeProductFilterBy, this.searchKeyForActiveProduct, this.getAllProducts).subscribe((res:any) => {
      this.loader = false;
      this.activeProductsList = res.docs;
      this.totalProductsActive = res.docs.length;
      this.totalPages = res.pages;
      this.activeProductCount = res.docs.length;
      this.activeProductTotalPages = res.pages;
      this.noDataTemplate = "We could not find any products that matched your search"
      this.noDataMsg = "";
      if(this.activeProductsList.length == 0){
        this.gotMyProductsData = false;
      }
      //Calculation for lower & upper count per page
      this.activeProductCurrentPageLowerCountActive = 1 + ((this.activeProductCurrentPage - 1) * this.activeProductPageSize);
      if (res.docs.length < 12) {
        this.activeProductCurrentPageUpperCountActive = res.docs.length + (this.activeProductPageSize * (this.activeProductCurrentPage - 1));
      } else {
        this.activeProductCurrentPageUpperCountActive = this.activeProductPageSize * this.activeProductCurrentPage;
      }
      this.dataBindingActiveProduct();
    }, error => {
      this.loader = false;
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    });
  }

  private getSearchAllProducts() {
    this.loader = true;
    this.getAllProducts = true;
    this.researchService.getSearchProvisionedProducts(this.allProductCurrentPage, this.allProductPageSize, this.accountId, this.projectId, this.allProductFilterBy, this.searchKeyForAllProduct, this.getAllProducts).subscribe((res:any) => {
      this.loader = false;
      this.allProductsList = res.docs;
      this.totalPages = res.pages;
      this.allProductCount = res.docs.length;
      this.allProductTotalPages = res.pages;
      this.noDataTemplate = "We could not find any products that matched your search"
      this.noDataMsg = "";
      if(this.allProductsList.length == 0){
        this.gotMyProductsData = false;
      }
      //Calculation for lower & upper count per page
      this.allProductCurrentPageLowerCountActive = 1 + ((this.allProductCurrentPage - 1) * this.allProductPageSize);
      if (res.docs.length < 12) {
        this.allProductCurrentPageUpperCountActive = res.docs.length + (this.allProductPageSize * (this.allProductCurrentPage - 1));
      } else {
        this.allProductCurrentPageUpperCountActive = this.allProductPageSize * this.allProductCurrentPage;
      }
      this.dataBindingAllProduct();
    }, error => {
      this.loader = false;
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    });
  }

  getAllActiveProducts(filterBy) {
    this.activeProductFilterBy = filterBy ? filterBy : 'Active';
    sessionStorage.setItem('Selected', this.activeProductFilterBy);
    this.placeHolder = "Search in my products";
    this.loader = true;
    this.gotMyProductsData = true;
    this.getAllProducts = false;
    this.researchService.getFilteredProvisionedProducts(this.activeProductCurrentPage, this.activeProductLimit, this.accountId, this.projectId, filterBy,this.getAllProducts).subscribe((res:any) => {
      this.searchKeyword = false;
      this.activeProductsList = res.docs;
      this.totalProductsActive = res.total;
      this.activeProductTotalPages = res.pages;
      this.activeProductCount = res.total;
      if(this.activeProductsList.length == 0){
        this.gotMyProductsData = false;
        this.showActiveProductsTab = false;
      }else{
        this.showActiveProductsTab = true;
      }
      if (filterBy == 'All'){
        this.noDataTemplate = "You currently don't have any products";
        this.noDataMsg = "Launch your first product from the Available Products page";
      }else{
        this.noDataTemplate = "We could not find any products that matched your filter";
        this.noDataMsg = "";
      }
      //This is to set focus on search input box
      // setTimeout(()=>{
      //   this.searchBoxFocus.nativeElement.focus();
      // },0);
      //Calculation for lower & upper count per page
      this.activeProductCurrentPageLowerCountActive = 1 + ((this.activeProductCurrentPage - 1) * this.activeProductPageSize);
      if (res.docs.length < 12) {
        this.activeProductCurrentPageUpperCountActive = res.docs.length + (this.activeProductPageSize * (this.activeProductCurrentPage - 1));
      } else {
        this.activeProductCurrentPageUpperCountActive = this.activeProductPageSize * this.activeProductCurrentPage;
      }
      this.dataBindingActiveProduct();
      this.loader = false;
      // if(!this.checkForTabs){
      //    setTimeout(()=>this.showTabsOnLoad(),100);
      // }
    }, error => {
      this.loader = false;
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    });
  }
  getProjectwiseProvisionedProducts(filterBy) {
    this.allProductFilterBy = filterBy ? filterBy : 'Active';
    sessionStorage.setItem('Selected', this.allProductFilterBy);
    this.placeHolder = "Search in my products";
    this.loader = true;
    this.gotMyProductsData = true;
    this.getAllProducts = true;
      this.researchService.getFilteredProvisionedProducts(this.allProductCurrentPage, this.allProductLimit, this.accountId, this.projectId, filterBy, this.getAllProducts).subscribe((res:any) => {
      this.searchKeyword = false;
      this.allProductsList = res.docs;
      this.allProductTotalPages = res.pages;
      this.allProductCount = res.total;
      if(this.allProductsList.length == 0){
        this.gotMyProductsData = false;
        this.showActiveProductsTab = false;
      }else{
        this.showActiveProductsTab = true;
      }
      if (filterBy == 'All'){
        this.noDataTemplate = "You currently don't have any products";
        this.noDataMsg = "Launch your first product from the Available Products page";
      }else{
        this.noDataTemplate = "We could not find any products that matched your filter";
        this.noDataMsg = "";
      }
      //This is to set focus on search input box
      // setTimeout(()=>{
      //   this.searchBoxFocus.nativeElement.focus();
      // },0);
      //Calculation for lower & upper count per page
      this.allProductCurrentPageLowerCountActive = 1 + ((this.allProductCurrentPage - 1) * this.allProductPageSize);
      if (res.docs.length < 12) {
        this.allProductCurrentPageUpperCountActive = res.docs.length + (this.allProductPageSize * (this.allProductCurrentPage - 1));
      } else {
        this.allProductCurrentPageUpperCountActive = this.allProductPageSize * this.allProductCurrentPage;
      }
      this.dataBindingAllProduct();
      this.loader = false;
      // if(!this.checkForTabs){
      //    setTimeout(()=>this.showTabsOnLoad(),100);
      // }
    }, error => {
      this.loader = false;
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    });
  }


  getActiveMenu(data, service) {
    const menuI = ['Upload', 'Explore', 'Share'];
    try {
      const serviceName = this.productUtil.getServiceFromTags(data);
      const getMenu = this.productUtil.getMenuForProduct(serviceName);
      if (service === "menu") {
        return getMenu.menu;
      }
      if(getMenu && 'name' in getMenu){
        return getMenu.name;
      }
    } catch (e) {
      console.log(e);
      return  menuI;
    }
  }

  sagemakerActions(body, provProduct){
    this.loader = true;
    this.serviceAction.serviceAction(body).subscribe((res:any) => {
      this.loader = false;
      switch (body.action.toLowerCase()){
       case 'stop':
            this.showProductAction = false;
            this.toastr.success(`${provProduct.ProvisionedProductName} : stop process initiated successfully`, '', this.globals.tosterOverride);
          break;
        default:
          break
      }
    }, error => {
      this.loader = false;
      console.error(error);
      let errBody = error.error;
      if(error && error.status === 401){
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
       }else{
          this.toastr.error(`${provProduct.ProvisionedProductName} - `+errBody.error, 'Error', this.globals.tosterOverride)
       }
    });
  }

  ec2Actions(body, provProduct){
    this.loader = true;
    this.serviceAction.serviceAction(body).subscribe(res => {
      this.loader = false;
      switch (body.action.toLowerCase()) {
        case 'stop':
          this.showProductAction = false;
          this.toastr.success(`${provProduct.ProvisionedProductName} : stop process initiated successfully`, '', this.globals.tosterOverride);
          break;
        default:
          break
      }
    }, error => {
      this.loader = false;
      let errBody = error.error;
      if(error && error.status === 401){
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
       }else{
          this.toastr.error(`${provProduct.ProvisionedProductName} - `+ errBody.error, 'Error', this.globals.tosterOverride)
       }
    });
  }

  actions(provProduct, action) {
    let body;
    let instanceId;
    if(provProduct.Resources.length > 0){
      this.resourceType = provProduct.Resources[0].Type;
      switch (this.resourceType) {
        case 'AWS::S3::Bucket':
          this.bucketName = provProduct.Resources[0].PhysicalResourceId;
          break;
      }
    }
    let service = this.getActiveMenu(provProduct.Tags, "");
    if(service === 'ec2' || service === 'rstudio' || service === 'nextflow'  || service === 'cromwell' || service === 'ec2-dcv'){
      for(var i=0;i< provProduct.RecordOutputs.length;i++){
        if(provProduct.RecordOutputs[i]['OutputKey'] === "InstanceId"){
            instanceId = provProduct.RecordOutputs[i]['OutputValue']
        }
        if (provProduct.RecordOutputs[i]['OutputKey'] === 'InstanceIPAddress') {
          this.getInstanceIp = provProduct.RecordOutputs[i]['OutputValue'];
        }
      }
      body = {
        "account_id" : provProduct.account_id,
        "provisionedProductId": provProduct.ProvisionedProductId,
        "service":service,
        "action": action.toLowerCase(),
        "instanceId": instanceId,
        "instanceIp": this.getInstanceIp,
        "projectId": this.projectId
      }
    } else if(service === 'pcluster'){
        const resourceDetails = (provProduct.Resources.find(resource => resource.LogicalResourceId == "HeadNode") || "").Details || [];
        const pclusterInstanceId = (resourceDetails.find(detail => detail.Name == "InstanceId") || "").Value || '';
        const pclusterInstanceIp = (resourceDetails.find(detail => detail.Name == "PublicIpAddress") || "").Value || '';
        body = {
          "account_id" : provProduct.account_id,
          "provisionedProductId": provProduct.ProvisionedProductId,
          "service":service,
          "action": action.toLowerCase(),
          "instanceId": pclusterInstanceId,
          "instanceIp": pclusterInstanceIp,
          "projectId": this.projectId
        }
    } else if(service === 'sagemaker') {
      body = {
      "service": service.toLowerCase(),
      "action": action.toLowerCase(),
      "ProvisioningParameters": provProduct.ProvisioningParameters,
      "account_id": provProduct.account_id,
      "resources": provProduct.Resources,
      "projectId": this.projectId,
      "provisionedProductId": provProduct.ProvisionedProductId,
    };
    } else {
      body = {
        "action": action.toLowerCase(),
        "account_id": provProduct.account_id,
        "resources": provProduct.Resources,
        "projectId": this.projectId,
        "provisionedProductId": provProduct.ProvisionedProductId
      }
    }
    action = action.toLowerCase();
    if(action === 'terminate'){
      if(service === 's3' && this.bucketName){
        //this is to check s3 bucket before deleting the bucket
        this.serviceAction.checkS3Bucket(this.bucketName, this.accountId).subscribe((res:any) => {
          if(res){
            if(res.Contents.length>0){
              this.s3BucketTotalrecords = res.Contents.length;
            }else if(res.CommonPrefixes.length>0){
              this.s3BucketTotalrecords = res.CommonPrefixes.length;
            }else{
              this.s3BucketTotalrecords = 0;
            }
            provProduct.s3BucketTotalSize = this.s3BucketTotalrecords;
            this.apiService.getDialogBox().subscribe((data:any) => {
              if(data.status === 200){
            this.dialog.showCustomDialog({
              component: ConfirmationDialog,
              providers: [{ provide: 'data', useValue: { deleteObj:provProduct, action: 'provisionedProduct', message: `Are you sure you want to delete ${provProduct.ProvisionedProductName} product ?` } }],
              isModal: true,
              styles: {
                "height":"auto",
                "width":"520px",
                "border": "1px solid #FFFFFF33",
                "border-radius": "10px",
                "background-color": "#07102A",
                "padding": "0px"
              },
              clickOutsideToClose: true,
              enterTransitionDuration: 400,
              leaveTransitionDuration: 400,
            })}}, error => {
              if(error && error.status === 401){
                let errBody= error.error;
                 this.toastr.error(errBody.err, '', this.globals.tosterOverride);
                 this.router.navigate(['login']);
               }
            })
          }
        }, error => {
          if(error && error.status === 401){
            let errBody= error.error;
            this.toastr.error(errBody.err, '', this.globals.tosterOverride);
            this.router.navigate(['login']);
          }else {
          this.toastr.error(error._body, 'Error', this.globals.tosterOverride);
          }
        });
      }  else {
          this.apiService.getDialogBox().subscribe((data:any) => {
            if(data.status === 200){
          this.dialog.showCustomDialog({
            component: ConfirmationDialog,
            providers: [{ provide: 'data', useValue: { deleteObj:provProduct, action: 'provisionedProduct', message: `Are you sure you want to delete ${provProduct.ProvisionedProductName} product ?` } }],
            isModal: true,
            styles: {
              "height":"auto",
              "width":"520px",
              "border": "1px solid #FFFFFF33",
              "border-radius": "10px",
              "background-color": "#07102A",
              "padding": "0px"
            },
            clickOutsideToClose: true,
            enterTransitionDuration: 400,
            leaveTransitionDuration: 400,
          })}}, error => {
            if(error && error.status === 401){
              let errBody= error.error;
               this.toastr.error(errBody.err, '', this.globals.tosterOverride);
               this.router.navigate(['login']);
             }
          })
        }
      }else {
        switch (service) {
          case 'sagemaker':
           action = action.toLowerCase();
           if (body.action == "stop") {
              this.sagemakerActions(body, provProduct);
           }
           break;
          case 'nextflow':
          case 'rstudio':
          case 'cromwell':
          case 'ec2':
          case 'pcluster':
          case 'ec2-dcv':
          case 'ec2-igv':
          case 'ec2-secure-desktop':
          case 'ec2-vscode':
          case 'ec2-jupyterlab':
          case 'ec2-spyder':
          if (service == "pcluster" && body.action == "stop") {
            this.apiService.getDialogBox().subscribe((data: any) => {
              if (data.status === 200) {
                this.dialog.showCustomDialog({
                  component: ConfirmationDialog,
                  providers: [{ provide: 'data', useValue: { stopObj: provProduct, action: 'stop', body: body } }],
                  isModal: true,
                  styles: {
                    "height": "auto",
                    "width": "520px",
                    "border": "1px solid #FFFFFF33",
                    "border-radius": "10px",
                    "background-color": "#07102A",
                    "padding": "0px"
                  },
                  clickOutsideToClose: true,
                  enterTransitionDuration: 400,
                  leaveTransitionDuration: 400,
                })
              }
            }, error => {
              if (error && error.status === 401) {
                let errBody = error.error;
                this.toastr.error(errBody.err, '', this.globals.tosterOverride);
                this.router.navigate(['login']);
              }
            })
          } else if (body.action == "stop") {
            this.ec2Actions(body, provProduct);
          }
          break;
        default:
          break;
      }
      }
  }

  getStatus(status) {
    let statusV = status;
    let state = statusV.toUpperCase();
    switch (state) {
      case 'INITIATED':
        statusV = "Initiated";
        break;
      case 'CREATE_IN_PROGRESS':
        statusV = 'Creating';
        break;
      case 'CREATE_COMPLETE':
        statusV = 'Provisioned';
        break;
      case 'CREATE_FAILED':
        statusV = 'Failed';
        break;
      case 'ROLLBACK_IN_PROGRESS':
        statusV = 'Failed';
        break;
      case 'ROLLBACK_COMPLETE':
        statusV = 'Failed';
        break;
      case 'CREATED':
        statusV = 'Provisioned';
        break;
      case 'AVAILABLE':
        statusV = 'Provisioned';
        break;
      case 'ERROR':
        statusV = 'Failed';
        break;
      case 'DELETE_IN_PROGRESS':
        statusV = 'Terminating';
        break;
      case 'DELETE_COMPLETE':
        statusV = 'Terminated';
        break;
      default:
        break;
    }
    return statusV;
  }

  getProductStatusColor(status) {
    let statusV = 'status-paused';
    switch (status.toLowerCase()) {
      case 'active':
        statusV = 'status-running';
        break;
      case 'warning':
        statusV = 'status-paused';
        break;
      case 'failed':
        statusV = 'status-stopped';
        break;
      case 'create_in_progress':
        statusV = 'status-paused';
        break;
      case 'create_complete':
        statusV = 'status-running';
        break;
      case 'create_failed':
        statusV = 'status-stopped';
        break;
      case 'rollback_complete':
        statusV = 'status-stopped';
        break;
      case 'available':
        statusV = 'status-running';
        break;
      case 'created':
        statusV = 'status-running';
        break;
      case 'error':
        statusV = 'status-stopped';
        break;
      default:
        statusV = 'status-paused';
        break;
    }
    return statusV;
  }

  nameTrim(data) {
    if (!data) {
      return data;
    }
    const getLen = data.length;
    if (getLen > 24) {
      data = data.slice(0, 24) + '...';
    }
    return data;
  }

  nameTrimData(data) {
    if (!data) {
      return data;
    }
    const getLen = data.length;
    if (getLen > 30) {
      data = data.slice(0, 30) + '...';
    }
    return data;
  }

  projectDescTrim(data){
    if (!data) {
      return data;
    }
    const getLen = data.length;
    if (getLen > 235) {
      data = data.slice(0, 235) + '...';
    }
    return data;
  }

  openKnowMore(detailsLink) {
    if (detailsLink) {
      window.open(detailsLink, '_blank');
    } else {
      return
      // window.open("#", '_blank');
    }
  }

  setPage() {
    this.currentPage = 1;
    this.checkForTabs = true;
  }

  availableSearchProduct() {
    try{
      const searchValue = this.searchKeyForAvailableProduct.trim() || '';
      if (this.searchValueTemp === this.searchKeyForAvailableProduct) {
        return;
      }
      if (searchValue.length < 3 && searchValue.length > 0) {
        return;
      }
        this.currentPage = 1;
        if (searchValue.length > 2 && this.searchExecKey !== searchValue) {
          this.searchExecKey = searchValue;
          this.isSearchedList = true;
          this.searchValue = true;
          this.searchValueTemp = this.searchKeyForAvailableProduct;
          this.getAllSearchAvailableProducts();
        } else if (searchValue.length === 0 && this.searchExecKey !== searchValue) {
          this.searchExecKey = searchValue;
          this.isSearchedList = false;
          if (this.searchValue === true) {
            this.searchValueTemp = this.searchKeyForAvailableProduct;
            this.getAllAvailableProducts(this.availableProductFilterBy, this.accountId);
          }
        }
    }catch(e){
        console.log("Error => "+e);
    }
  }

  myProductsSearch(){
    try{
      const searchValue = this.searchKeyForActiveProduct.trim() || '';
      if (this.searchValueTemp === this.searchKeyForActiveProduct) {
        return;
      }
      if (searchValue.length < 3 && searchValue.length > 0) {
        return;
      }
      this.activeProductCurrentPage = 1;
      if (searchValue.length > 2 && this.searchExecKey !== searchValue) {
        this.searchExecKey = searchValue;
        this.isSearchedList = true;
        this.searchValue = true;
        this.searchValueTemp = this.searchKeyForActiveProduct;
        this.getAllSearchActiveProducts();
      } else if (searchValue.length === 0 && this.searchExecKey !== searchValue) {
        this.searchExecKey = searchValue;
        this.isSearchedList = false;
        if (this.searchValue === true) {
          this.searchValueTemp = this.searchKeyForActiveProduct;
          this.getAllActiveProducts(this.activeProductFilterBy);
        }
      }
    }catch(e){
      console.log("Error => "+e);
    }
  }

  allProductsSearch(){
    try{
      const searchValue = this.searchKeyForAllProduct.trim() || '';
      if (this.searchValueTemp === this.searchKeyForAllProduct) {
        return;
      }
      if (searchValue.length < 3 && searchValue.length > 0) {
        return;
      }
      this.allProductCurrentPage = 1;
      if (searchValue.length > 2 && this.searchExecKey !== searchValue) {
        this.searchExecKey = searchValue;
        this.isSearchedList = true;
        this.searchValue = true;
        this.searchValueTemp = this.searchKeyForAllProduct;
        this.getSearchAllProducts();
      } else if (searchValue.length === 0 && this.searchExecKey !== searchValue) {
        this.searchExecKey = searchValue;
        this.isSearchedList = false;
        if (this.searchValue === true) {
          this.searchValueTemp = this.searchKeyForAllProduct;
          this.getProjectwiseProvisionedProducts(this.allProductFilterBy);
        }
      }
    }catch(e){
      console.log("Error => "+e);
    }
  }

  private getAllSearchAvailableProducts() {
    this.loader = true;
    this.researchService.getListOfSearchResearchProducts(this.searchKeyForAvailableProduct, this.currentPage, this.pageSize, this.accountId, this.projectId, this.availableProductFilterBy).subscribe((res:any) => {
      this.loader = false;
      this.availableProductsList = res.docs;
      this.totalProducts = res.total;
      this.totalPages = res.pages;
      this.availableProductCount = res.total;
      if(this.availableProductsList.length == 0){
        this.gotAvailableProducts = false;
      }
      this.noDataTemplate = "We could not find any products that matched your search"
      this.noDataMsg = "";
      //Calculation for lower & upper count per page
      this.currentPageLowerCount = 1 + ((this.currentPage - 1) * this.pageSize);
      if (res.docs.length < 9) {
        this.currentPageUpperCount = res.docs.length + (this.pageSize * (this.currentPage - 1));
      } else {
        this.currentPageUpperCount = this.pageSize * this.currentPage;
      }
      this.dataBindingAvailableProduct();
    }, error => {
      this.loader = false;
    });
  }

  getNextProjectEvents(action) {
    if (action === 'first' && this.eventsPage !== 1) {
      this.eventsPage = 1;
      this.getProjectEvents();
    }
    if (action === 'previous' && this.eventsPage > 1) {
      this.eventsPage = this.eventsPage - 1;
      this.getProjectEvents();

    }
    if (action === 'next' && this.eventsPage !== this.eventsTotalPages) {
      this.eventsPage = this.eventsPage + 1;
      this.getProjectEvents();

    }
    if (action === 'last' && this.eventsPage !== this.eventsTotalPages) {
      this.eventsPage = this.eventsTotalPages;
      this.getProjectEvents();
    }
  }

  getNextAvailableProducts(action) {
    if (action === 'first' && this.currentPage !== 1) {
      this.currentPage = 1;
      if (this.isSearchedList) {
        this.getAllSearchAvailableProducts();
      }else {
        this.getAllAvailableProducts(this.availableProductFilterBy, this.accountId);
      }
    }
    if (action === 'previous' && this.currentPage > 1) {
      this.currentPage = this.currentPage - 1;
      if (this.isSearchedList) {
        this.getAllSearchAvailableProducts();
      }else {
        this.getAllAvailableProducts(this.availableProductFilterBy, this.accountId);
      }
    }
    if (action === 'next' && this.currentPage !== this.totalPages) {
      this.currentPage = this.currentPage + 1;
      if (this.isSearchedList) {
        this.getAllSearchAvailableProducts();
      }else {
        this.getAllAvailableProducts(this.availableProductFilterBy, this.accountId);
      }
    }
    if (action === 'last' && this.currentPage !== this.totalPages) {
      this.currentPage = this.totalPages;
      if (this.isSearchedList) {
        this.getAllSearchAvailableProducts();
      }else {
        this.getAllAvailableProducts(this.availableProductFilterBy, this.accountId);
      }
    }
  }

  getNextActiveProducts(action) {
    if (action === 'first' && this.activeProductCurrentPage !== 1) {
      this.activeProductCurrentPage = 1;
      if (this.isSearchedList) {
        this.getAllSearchActiveProducts();
      }else {
        this.getAllActiveProducts(this.activeProductFilterBy);
      }
    }
    if (action === 'previous' && this.activeProductCurrentPage > 1) {
      this.activeProductCurrentPage = this.activeProductCurrentPage - 1;
      if (this.isSearchedList) {
        this.getAllSearchActiveProducts();
      }else {
        this.getAllActiveProducts(this.activeProductFilterBy);
      }
    }
    if (action === 'next' && this.activeProductCurrentPage !== this.activeProductTotalPages) {
      this.activeProductCurrentPage = this.activeProductCurrentPage + 1;
      if (this.isSearchedList) {
        this.getAllSearchActiveProducts();
      }else {
        this.getAllActiveProducts(this.activeProductFilterBy);
      }
    }
    if (action === 'last' && this.activeProductCurrentPage !== this.activeProductTotalPages) {
      this.activeProductCurrentPage = this.activeProductTotalPages;
      if (this.isSearchedList) {
        this.getAllSearchActiveProducts();
      }else {
        this.getAllActiveProducts(this.activeProductFilterBy);
      }
    }
  }

  getNextAllProducts(action) {
    if (action === 'first' && this.allProductCurrentPage !== 1) {
      this.allProductCurrentPage = 1;
      if (this.isSearchedList) {
        this.getSearchAllProducts();
      }else {
        this.getProjectwiseProvisionedProducts(this.allProductFilterBy);
      }
    }
    if (action === 'previous' && this.allProductCurrentPage > 1) {
      this.allProductCurrentPage = this.allProductCurrentPage - 1;
      if (this.isSearchedList) {
        this.getSearchAllProducts();
      }else {
        this.getProjectwiseProvisionedProducts(this.allProductFilterBy);
      }
    }
    if (action === 'next' && this.allProductCurrentPage !== this.allProductTotalPages) {
      this.allProductCurrentPage = this.allProductCurrentPage + 1;
      if (this.isSearchedList) {
        this.getSearchAllProducts();
      }else {
        this.getProjectwiseProvisionedProducts(this.allProductFilterBy);
      }
    }
    if (action === 'last' && this.allProductCurrentPage !== this.allProductTotalPages) {
      this.allProductCurrentPage = this.allProductTotalPages;
      if (this.isSearchedList) {
        this.getSearchAllProducts();
      }else {
        this.getProjectwiseProvisionedProducts(this.allProductFilterBy);
      }
    }
  }

  getProjectEvents() {
    //this.currentTab = 1;
    this.loader = true;
    this.researchService.getAuditDetails(this.eventsPage, this.eventsLimit, '',
      '', '&category=projectId', `&value=${this.projectId}`).subscribe((res:any) => {
        this.loader = false;
        this.gotEventsData = true;
        this.projectEvents = res.docs;
        if(this.projectEvents.length == 0){
          this.gotEventsData = false;
        }
        this.eventsTotalRecords = res.total || 0;
        this.eventsPage = res.page;
        this.eventsTotalPages = res.pages;
        this.eventsLimit = res.limit;
        // Calculation for lower & upper count per page
        this.eventsCurrentPageLowerCount = 1 + ((this.eventsPage - 1) * this.eventsLimit);
        if (this.projectEvents.length < 10) {
          this.eventsCurrentPageUpperCount = this.projectEvents.length + (this.eventsLimit * (this.eventsPage - 1));
        }else {
          this.eventsCurrentPageUpperCount = this.eventsLimit * this.eventsPage;
        }
        this.showTabsAfterPageUpdate();
      }, error => {
        this.loader = false;
        if(error && error.status === 401){
          let errBody = error.error;
           this.toastr.error(errBody.err, '', this.globals.tosterOverride);
           this.router.navigate(['login']);
         }else if(error && error.status === 403){
          const err = error.error;
          this.toastr.error(err.error, 'Error', this.globals.tosterOverride);
          this.router.navigate(['pageNotFound']);
        }
      });
  }

  getprojectStatus(data) {
    if (data) {
      const strLen = data.length;
      let str = data;
      if (strLen > 160) {
        str = str.slice(0, 160) + '...';
      }
      return str;
    } else {
      return 'Nil';
    }
  }

  getEventStatusClass(status) {
    let statusClass = 'tableData'
    switch (status) {
      case 'Info':
        statusClass = 'tableData-info'
        break;
      case 'Error':
        statusClass = 'tableData-error'
        break;
    }
    return statusClass;

  }

  getResearchers() {
    this.loader = true;
    try{
      this.researchService.getResearcherList().subscribe(
        (res:any) => {
          this.loader = false;
          this.researcherIdList = res.map(obj => {
            const fObj = {
              'value': obj.username,
              'viewValue': `${obj.fullName} (${obj.email})`
            };
            return fObj;
          });
        }, error =>{
          this.loader = false;
          if(error && error.status === 401){
            let errBody= error.error;
             this.toastr.error(errBody.err, '', this.globals.tosterOverride);
             this.router.navigate(['login']);
           }
          // this.accountListFound = false;
          // this.fAccountIdList = [];
        });
    }catch(error){
      this.loader = false;
    }
  }

  validateResearcher(researcher) {
    if(this.selectSearchVal && researcher!=undefined){
      return true;
    }else{
      const validateResearcher = this.researcherExistingIdList.some((e) => e === researcher);
      return validateResearcher;
    }
  }

  validateProvisionedResearcher(researcher) {
    const validateResearcher = this.researcherValidateList.find((e) => e.researcherName === researcher);
    let status = false;
    if (validateResearcher) {
      status = validateResearcher.provisioned;
    };
    return status;
  }

  EditResearcherId(researcher, event) {
    this.selectValChanged = true;
    if (event.target.checked) {
      this.checkedValue.push(researcher.value);
      this.assignedIds.push(researcher.value);
    } else {
      const index = this.assignedIds.indexOf(researcher.value);
      if (index > -1) {
        this.assignedIds.splice(index, 1);
        this.unCheckedResearchers.push(researcher.value);
        this.unCheckedValue.push(this.assignedIds.splice(index,-1));
      }
    }
  }

  insertResearchers() {
    this.updateBTN = false;
    if (this.assignedIds.length === 0) {
      return;
    }

    if(this.selectSearchVal && ! this.selectValChanged){
       this.researcherIdList.forEach( (data) => {
            this.assignedIds.push(data.value);
       });
    }
    // Remove any duplicates
    this.assignedIds = this.assignedIds.filter(function (value, index, array) {
      return array.indexOf(value) === index;
    });
    const formObj = {
      'projectId': this.projectId,
      'researcherNames': this.assignedIds,
      'dataForAdd': this.checkedValue,
      'dataForRemove':this.unCheckedValue,
      'dataToRemoveFolder':this.unCheckedResearchers
    };
    this.researchService.updateResearchers(formObj).subscribe(
      (res:any) => {
        const urlPname = this.assignedIds[0];
        const urlPnamearray = urlPname.split('/');
        if (res.code === 1001) {
          this.updateBTN = true;
          let t = new Date().getTime();
          this.toastr.success(`Updated successfully`, '', this.globals.tosterOverride);
          this.router.navigate([`/project-details/${this.projectId}`],{ queryParams: { showTab:'projectDetails', updatedData: t}});
        } else {
          this.toastr.error( 'Error in updating','Error', this.globals.tosterOverride);
        };
      }, error => {
        if(error && error.status === 401){
          let errBody = error.error;
          this.toastr.error(errBody.err, '', this.globals.tosterOverride);
          this.router.navigate(['login']);
        } else if(error && error.status === 500){
          let errBody = error.error;
          this.toastr.error(errBody.message, '', this.globals.tosterOverride);
        }
      });

  }

  openProductDetails(activeProduct) {
    const projectName = encodeURIComponent(this.projectName);
    sessionStorage.setItem('projectId',this.projectId);
    sessionStorage.setItem('projectName',this.projectName);
    sessionStorage.setItem('Level', '1');
    sessionStorage.setItem('isEgressStoreEnabled', this.checkEgressStore);
    this.router.navigate(['/product/' + projectName + '/' +
    activeProduct.ProvisionedProductName, { ppId: activeProduct.ProvisionedProductId }],{ queryParams: { projectStatus: this.projectStatus}});
  }

  //SSE for project status and active products
  private serverStreamEvent() {
    this.researchService.getServerSentEvent(this.eventS).subscribe(res => {
      const resObj = res.data;
      if (!resObj) {
        return;
      }
      try {
        const { status, data, payload } = JSON.parse(resObj);
        // server give handshake to the alive connection
        if (data === 'check') {
          // console.log('check');
          return;
        }
        if (data === 'project') {
          this.getProjects(this.projectId);
          this.getProjectEvents();
        }
        if (data === 'provisionProduct') {
          const { ProvisionedProductId , Type, ResourceType } = payload;
          if(Type === 'ResourceStateChange' && ResourceType === 'aws.s3') {
            return;
          }
          this.activeProductFilterBy = sessionStorage.getItem('Selected') ?  sessionStorage.getItem('Selected') : 'Active';
          this.getAllActiveProducts(this.activeProductFilterBy);
          this.getProjectwiseProvisionedProducts(this.activeProductFilterBy);
          this.categoryFilterForActiveProduct = this.activeProductFilterBy;
        }
      } catch (e) {
        console.log(e.message);
      }
    });
  }

  //repair project
  repairProject(){
    this.showSpinnerForRepair = true;
    this.researchService.repairProject(this.projectId).subscribe( (response:any) => {
      this.showSpinnerForRepair = false;
      let t = new Date().getTime();
      this.toastr.success(response.message, '', this.globals.tosterOverride);
      this.router.navigate([`/project-details/${this.projectId}`],{ queryParams: { showTab:'events', updatedData: t}});
      this.getProjectEvents();
      this.currentTab = 1;
    }, error => {
      this.showSpinnerForRepair = false;
      let errorMes = error.error;
      if (errorMes && (errorMes.code && errorMes.message)) {
        this.toastr.error('', errorMes.message, { tapToDismiss: false, timeOut: 0, extendedTimeOut: 0, closeButton : true });
      } else{
        this.toastr.error('Error in project repair', '', this.globals.tosterOverride);
      }
    });
  }

  //sync project
  syncProject(){
    let settingDetails = { _id : this.accountId, projectId: this.projectId};
    this.researchService.syncProduct(settingDetails).subscribe( response => {
      this.toastr.success('Sync started. Follow the progress on the events tab', '', this.globals.tosterOverride);
    }, error => {
      let errorMes = error.error;
      if (errorMes && (errorMes.code && errorMes.message)) {
        this.toastr.error('', errorMes.message, { tapToDismiss: false, timeOut: 0, extendedTimeOut: 0, closeButton : true });
      }else if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
      else{
        this.toastr.error('Error in project sync', '', this.globals.tosterOverride);
      }
    });
  }

  //archive Project
  archiveProject(){
    if(this.projectType == "Ingress Gateway"){
        this.storageType = "ingress"
    }else{
        this.storageType = "project"
    }
    const projectData = {
      projectId: this.projectId,
      projectName: this.projectName,
      accountName: this.accountName,
      storageType:this.storageType
    }
    this.dashboardService.getDialogBox().subscribe((data:any) => {
      if(data.status === 200){
    this.dialog.showCustomDialog({
      component: ArchiveProjectComponent,
      providers: [{ provide: 'data', useValue: projectData }],
      isModal: true,
      styles: {
        "height":"auto",
        "width":"520px",
        "border": "1px solid #FFFFFF33",
        "border-radius": "10px",
        "background-color": "#07102A",
        "padding": "0px"
      },
      clickOutsideToClose: true,
      enterTransitionDuration: 400,
      leaveTransitionDuration: 400,
    })}}, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    })

  }
  getBudgetCardsData(){
    let allotted : any = 0;
    let consumed : any = 0;
    let currentMonthCost : any = 0;
    this.projects.forEach((project) => {
      // Safe navigation on all keys
      if(project.budget){
        allotted += project.budget
      }
      let budgetConsumed = project.budgetConsumed;
      if (budgetConsumed) {
        let projectBudgetConsumed = budgetConsumed['projectBudgetConsumed']
        if (projectBudgetConsumed) {
          consumed += projectBudgetConsumed;
        }
      }
      let today = new Date();
      let currentMonth = today.getMonth() + 1;
      let currentYear = today.getFullYear();
      if (budgetConsumed.monthlyProjectBudgetConsumed.length > 0){
          let currentMonthProjectCost = budgetConsumed.monthlyProjectBudgetConsumed.filter( (monthCostByProject) => {
          return currentMonth == monthCostByProject.month && currentYear == monthCostByProject.year;
          });
          if(currentMonthProjectCost.length > 0){
            currentMonthCost = currentMonthProjectCost[0].cost;
           }
       }
      this.totalBudgetAllotted = parseFloat(allotted).toFixed(2);
      this.totalBudgetConsumed = parseFloat(consumed).toFixed(2);
      this.totalcurrentMonthCost = parseFloat(currentMonthCost).toFixed(2);
    });
  }

  //Go to budget details page for a project
  getBudgetDetails(){
    this.router.navigate([`/principal/project/${this.projectId}/details`])
  }

  getActiveProductName(name){
    const trimmedData = this.productUtil.dataTrim(name, 28);
    return trimmedData;
  }

  searchUsers(){
    const searchKey = this.searchUser.trim() || '';
    if (searchKey.length >= 3 && this.searchExecKey !== searchKey)  {
      this.searchExecKey = searchKey;
      this.getSearchData();
    } else if (searchKey.length === 0 && this.searchExecKey !== searchKey) {
      this.searchExecKey = searchKey;
      this.getSearchData();
    }
  }

  getSearchData(){
    this.researchService.searchUsers(this.searchUser).subscribe( (response:any) => {
      const getRE = this.researcherExistingIdList.map((e) => e);
      this.assignedIds = getRE;
      this.searchUser = this.searchUser.trim() || '';
      if(this.searchUser.length != 0){
        this.selectSearchVal = true;
        this.researcherIdList = response.map(obj => {
          const fObj = {
            'value': obj.username,
            'viewValue': `${obj.fullName} (${obj.email})`
          };
          return fObj;
        });
        // push all the search result to assignedIds
        this.researcherIdList.forEach((data)=>{
          this.assignedIds.push(data.value);
        });
        this.assignedIds = this.assignedIds.filter(function (value, index, array) {
          return array.indexOf(value) === index;
        });
      }
      if(this.searchUser.length == 0 ){
        this.selectSearchVal = false;
        this.getResearchers();
      }
    }, error => {
      let errorMes = error.error;
      if (errorMes && errorMes.message) {
        this.toastr.error('', errorMes.message, { tapToDismiss: false, timeOut: 0, extendedTimeOut: 0, closeButton : true });
      }else{
        this.toastr.error('Error in finding user', '', this.globals.tosterOverride);
      }
    });
  }

  addBudget(){
    const projectData = {
       projectId: this.projectId,
       projectName: this.projectName,
       accountName: this.accountName
     }
     this.dashboardService.getDialogBox().subscribe((data:any) => {
      if(data.status === 200){
     this.dialog.showCustomDialog({
       component: AddBudgetComponent,
       providers: [{ provide: 'data', useValue: projectData }],
       isModal: true,
       styles: {
         "height":"auto",
         "width":"520px",
         "border": "1px solid #FFFFFF33",
         "border-radius": "10px",
         "background-color": "#07102A",
         "padding": "0px"
       },
       clickOutsideToClose: true,
       enterTransitionDuration: 400,
       leaveTransitionDuration: 400,
     })}},error =>{
      if(error && error.status === 401){
      let errBody= error.error;
       this.toastr.error(errBody.err, '', this.globals.tosterOverride);
       this.router.navigate(['login']);
     }
    })
    }


}
