import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DashboardService } from '../_services/dashboard.service';
import { MdlSnackbarService } from '@angular-mdl/core';
import { ToastrService } from 'ngx-toastr';
import { Globals } from "../globals";
import {Router} from "@angular/router";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
  providers: [Globals]
})
export class RegisterComponent implements OnInit {

  public fromData:any={
    first_name:'',
    last_name:'',
    username:'',
    email:'',
    password:'',
    repass:'',
    captcha:'',
    phone_number:'',
    license: false
  };
  public showSpinner = false;
  public showmsg = false;
  public form: FormGroup;
  public patternPopOver:boolean =false;
  hasError:boolean=false;
  errorMsg:any='';
  captchaverified = false;
  captchaPublicKey = null;
  termsAndConditionLink = "";
  constructor(
    private fb: FormBuilder,
    private apiService: DashboardService,
    private snackBar: MdlSnackbarService,
    private toastr: ToastrService,
    private globals: Globals,
    private router: Router
  ) {
     this.apiService.getPublicKey().subscribe((data:any) => {
       this.captchaPublicKey = data.publicKey;
     });

  }

  ngOnInit() {
    this.showmsg = false;
    this.apiService.getTermsAndCondition().subscribe((data:any) => {
      if (data.termsAndConditions){
        this.termsAndConditionLink = data.termsAndConditions;
      }
    });
    this.apiService.getApplicationMode().subscribe((data:any) => {
        if(!data.enableB2CMode){
          this.router.navigate(['login']);
        }
    },error =>{
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    });
  }


  openTermsAndCondition(){
    window.open(this.termsAndConditionLink);
  }

  signup() {
    let formData = {
      first_name: this.fromData.first_name,
      last_name: this.fromData.last_name,
      // username: this.fromData.username,
      // password: this.fromData.password,
      // company: this.fromData.company,
      phone_number: this.fromData.phone_number,
      email: this.fromData.email,
      captcha: this.fromData.captcha,
      role: 1,
      source: 'self_sign_up'
      // name: this.fromData.username
    };

    this.showSpinner = true;
    this.apiService.signup(formData).subscribe(data => {
      this.showSpinner = false;
      // this.showmsg = true;
      this.toastr.success(`A verification email has been sent to the address you provided. Please click on the link sent in that email to continue with the activation process.`, 'Thank You!');
      this.fromData.username = '';
      this.fromData.email = '';
      this.fromData.password = '';
      this.fromData.repass = '';
      this.fromData.phone_number = '';
      this.fromData.first_name = '';
      this.fromData.last_name = '';
      this.fromData.captcha = '';
      this.captchaverified = false;
      this.fromData.license = false;
    },
      error => {
        this.showSpinner = false;
        let errorbody = '';
        let errorBody = error.error;
        try {
          errorbody = error.error.message;
        } catch (e) {
          //errorbody = "Server Down";
        }
        // this.snackBar.showToast(errorbody, 3000);
        if ('code' in errorBody && errorBody.code === 'EntityExists'){
          this.toastr.info(errorbody, '', this.globals.tosterOverride);
        }else{
          this.toastr.error(errorbody, '', this.globals.tosterOverride);
        }
        this.fromData.captcha = '';
        this.captchaverified = false;
      });
   }

  policy(){
    return "<div class='pass-mess' ><div>Password should meet the following requirement:</div>\n" +
      "<div>Should have a minimum of 8 characters and a maximum of 16 characters.</div>\n" +
      "<div>Should have at least one of each of the following :</div>\n" +
      "<div>Lowercase characters.</div>\n" +
      "<div>Uppercase characters.</div>\n" +
      "<div>Numbers (0-9).</div>\n" +
      "<div>Special Characters</div></div> "
  }
  passwordEmpty(){
    this.fromData.repass='';
  }

  resolved(captchaResponse: string) {
    const data = {
      captcha: captchaResponse
    };
    if(captchaResponse !== null || captchaResponse === '') {
      this.apiService.verifyCaptcha(data).subscribe(res => {
        this.captchaverified = true;
      }, error => {
        let errorbody = '';
        try {
          errorbody = error.error.message;
        } catch (e) {
          //errorbody = `Please verify captcha`;
        }
        // this.snackBar.showToast(errorbody, 3000);
        this.toastr.error(errorbody,' ', this.globals.tosterOverride);
        this.captchaverified = false;
        this.fromData.captcha = '';
      });
    }
  }

  googleAuth() {
    window.location.href = window.location.origin + '/auth/google';
  }

  passwordMatch() {
    this.hasError=false;
    if(this.fromData.password == this.fromData.repass) {
      this.hasError=false;
    }
    else {
      this.hasError=true;
      this.errorMsg="Password does not match";
    }
  }
}
