import { Component, OnInit, Inject,ViewChild, KeyValueDiffers, ElementRef, AfterViewInit } from '@angular/core';
import { MdlDialogReference } from '@angular-mdl/core';
import { ToastrService } from 'ngx-toastr';
import { ResearchService } from "../../_services/research.service";
import { DataService } from '../../_services/data.service';
import { Router } from '@angular/router';
import { Globals } from "../../globals";
import { DashboardService } from '../../_services/dashboard.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css'],
  providers: [DashboardService, Globals]
})
export class AddUserComponent implements OnInit {
  @ViewChild('AddUserAction') AddUserAction: ElementRef;
  formData = {
    firstName: '',
    lastName: '',
    username: '',
    email: '',
  };
  organisationData;
  roleData;
  selectedOrganisation;
  selectedRole;
  showSpinner = false;
  isDisabled = false;
  isDisabledOrgUnit = false;
  tags = [];
  keyValue;
  userId;
  action;
  pageLimit = 9;
  currentPage = 1;
  requestParams;
  users;
  filterBy;
  loader: boolean = false;
  editableUser;
  roleSelected;
  role;
  organisationSelected;
  organisation;
  isEditDisabled = false;
  firstNameScope = false;
  lastNameScope = false;
  tagScope = false;
  orgScope = false;
  userTags = [];
  searchKey = "";
  requestParamsTemp;
  searchValueTemp;

  constructor(public dialogRef: MdlDialogReference, private router: Router, private researchService: ResearchService,
    private toastr: ToastrService, private dataService: DataService, private globals: Globals, private dashboardService: DashboardService, @Inject('data') public data: any) { }

  ngOnInit() {
    this.getDropdownData();
    if (this.data != undefined) {
      this.userId = this.data.userId;
      this.action = this.data.action;
      this.requestParams = this.data.requestParams;
      this.searchKey = this.data.searchKey
    }
    if(this.action == 'editUser'){
      if (this.searchKey.length > 2) {
        this.searchUsers();
      } else {
        this.users = this.data.users
        this.getUserDetails();
      }
    }
  }

  ngAfterViewInit() {
    setTimeout(() => this.AddUserAction.nativeElement.focus(), 0);
  }

  checkIfUserIsPI() {
    return this.researchService.checkIfUserIsPI()
  }

  cancel(): void {
    this.dialogRef.hide();
  }


  searchUsers() {
    const al = document.getElementById("email");
    al.style.cursor = 'not-allowed';
    this.searchKey = this.searchKey.trim();
    if (this.searchValueTemp === this.searchKey && this.requestParams === this.requestParamsTemp) {
      return;
    }
    this.currentPage = 1;
    this.searchValueTemp = this.searchKey;
    this.requestParamsTemp = this.requestParams;
    if (this.searchKey.length > 2) {
      this.loader = true;
      this.researchService.getSearchedUsers(this.currentPage, this.pageLimit, this.searchKey, this.requestParams).subscribe((data: any) => {
        this.loader = false;
        this.users = data.users.docs;     
        this.showUser();
      }, error => {
        this.loader = false
        if (error && error.status === 401) {
          let errBody = error.error;
          this.toastr.error(errBody.err, '', this.globals.tosterOverride);
          this.router.navigate(['login']);
        }
      })
    } 
  }
  getUserDetails(){
    this.isDisabled = false;
    this.isEditDisabled = true;
    const al = document.getElementById("email");
    al.style.borderLeft = '5px solid #42A948';
    al.style.cursor = 'not-allowed';
    this.loader = true;
    this.showUser();
  }

  showUser(){
    this.editableUser = this.users.filter(val => val._id == this.userId);   
    this.roleSelected = this.editableUser[0].role
    this.formData.email = this.editableUser[0].email;  
    if(this.roleSelected == "Admin"){
      this.isDisabledOrgUnit = true;
      this.selectedOrganisation = undefined;     
    }
    this.formData.firstName = this.editableUser[0].first_name;
    this.formData.lastName = this.editableUser[0].last_name;
    if(this.editableUser[0].userTags != undefined){
      this.tags = this.editableUser[0].userTags;
      this.userTags = [...this.editableUser[0].userTags];   
    }
    this.organisationSelected = this.editableUser[0].org_id.org_name;   
  }
  
  checkFirstName(){
    if(this.action == 'editUser'){
      if (this.formData.firstName == this.editableUser[0].first_name) {
        this.firstNameScope = false;
      } else {
        this.firstNameScope = true;
      }
    }
  }
  checkLastName(){
    if(this.action == 'editUser'){
      if (this.formData.lastName == this.editableUser[0].last_name) {
        this.lastNameScope = false;
      } else {
        this.lastNameScope = true;
      }
    }
  }

  getDropdownData() {
    this.researchService.getOrganisationDropdown().subscribe((res:any) => {
      this.organisationData = res.filterList;
      if (this.data.hasOwnProperty('orgId')) {
        for (var i = 0; i < this.organisationData.length; i++) {
          if (this.organisationData[i].key === this.data.orgId) {
            this.selectedOrganisation = this.organisationData[i];
          }
        }
        this.isDisabled = true;
      }
    }, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    })
    this.researchService.getRoleFilter().subscribe((res:any) => {
      this.roleData = res.filterList;
    }, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    })
  }

  addUser() {
    let postData;
    if (this.selectedOrganisation) {
      postData = {
        first_name: this.formData.firstName,
        last_name: this.formData.lastName,
        org_id: this.selectedOrganisation.key,
        role: this.selectedRole.key,
        username: this.formData.username,
        email: this.formData.email,
        userTags: this.tags,
        source: 'admin'
      }
    } else {
      postData = {
        first_name: this.formData.firstName,
        last_name: this.formData.lastName,
        role: this.selectedRole.key,
        username: this.formData.username,
        email: this.formData.email,
        userTags: this.tags,
        source: 'admin'
      }
    }
    this.showSpinner = true;
    this.researchService.addUser(postData).subscribe((res:any) => {
      this.showSpinner = false;
      if(res.status === 'success'){
        if(this.data.hasOwnProperty('action')){
          if(this.data.action == 'addUserFromProjectPage'){
            this.dialogRef.hide();
            let t = new Date().getTime();
            this.router.navigate(['/addProject/addUser'], { queryParams: { updatedData: t } });
          } else if (this.data.action == 'addUserFromOrgPage') {
            this.dialogRef.hide();
            let t = new Date().getTime();
            this.router.navigate(['/addOrganization/addUser'], { queryParams: { updatedData: t } });
          }
        }
        this.toastr.success(res.message);
        this.dataService.updatePage.next(true);
        this.dialogRef.hide();
      } else {
        this.toastr.error(res.message);
        this.dialogRef.hide();
      }
    }, error => {
      this.dialogRef.hide();
      this.showSpinner = false;
      const errBody = error.error;
      if (error && error.status === 401) {
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
      }else {
        this.toastr.error(errBody.message, '', this.globals.tosterOverride);
      }
    })
  }

  updateUser() {
    let postData;
    if (this.selectedOrganisation) {
      postData = {
   	    userId: this.userId, 
        first_name: this.formData.firstName,
        last_name: this.formData.lastName,
        org_id: this.selectedOrganisation.key,
        userTags: this.tags
      }
    } else {
      postData = {
        userId: this.userId, 
        first_name: this.formData.firstName,
        last_name: this.formData.lastName,
        userTags: this.tags
      }
    }  
    let t = new Date().getTime();
    this.researchService.updateUser(postData).subscribe((res:any) => {
      this.showSpinner = false;
      if(res.status === 'success'){     
        this.toastr.success(res.message);
        this.dataService.updatePage.next(true);
        this.dialogRef.hide();
      } else {
        this.toastr.error(res.message);
        this.dialogRef.hide();
      }
    }, error => {
      this.dialogRef.hide();
      this.showSpinner = false;
      const errBody = error.error;
      if (error && error.status === 401) {
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
      } else {
        this.toastr.error(errBody.message, '', this.globals.tosterOverride);
      }
    })
  } 

  checkRole(){
    if(this.selectedRole.value == "Admin"){
      this.isDisabledOrgUnit = true;
      this.selectedOrganisation = undefined;
    } else {
      this.isDisabledOrgUnit = false;
      this.selectedOrganisation = this.selectedOrganisation;
    }    
  }

  checkOrg(){
    if(this.selectedOrganisation !== this.organisationSelected){
      this.orgScope = true;
    }    
  }

  addTag(formValidationValue) {
    if (!this.keyValue.trim() || !formValidationValue) {
      return;
    }
    let obj = (this.keyValue).toLowerCase();
    let result = this.tags.includes(obj);
    if (result == false) {
      this.tags.push(obj);
    }
    this.keyValue = '';
    const validateTagsArray = [];
    for (let i = 0; i < this.tags.length; i++) {
      if(this.userTags.includes(this.tags[i])){
      var validateTags = true;
      validateTagsArray.push(validateTags);      
      } else {
        var validateTags = false;  
        validateTagsArray.push(validateTags); 
      }
    }
    if (this.action == 'editUser') {
      if (this.userTags.length == this.tags.length) {
        if (validateTagsArray.includes(false)) {
          this.tagScope = true;
        } else {
          this.tagScope = false;
        }
      } else{
        this.tagScope = true;
      }
    }
  }

  removeTag(tag) {
    const index = this.tags.indexOf(tag);
    if (index > -1) {
      this.tags.splice(index, 1);
    }
    const validateTagsArray = [];
    for (let i = 0; i < this.tags.length; i++) {
      if(this.userTags.includes(this.tags[i])){
      var validateTags = true;      
      validateTagsArray.push(validateTags);
      } else {
        var validateTags = false;   
        validateTagsArray.push(validateTags);
      }
    }
    if (this.action == 'editUser') {
      if (this.userTags.length == this.tags.length) {
        if (validateTagsArray.includes(false)) {
          this.tagScope = true;
        } else {
          this.tagScope = false;
        }
      } else{
        this.tagScope = true;
      }
    }
  }
  checkTagValue(formValidationValue) {
    let tagKeyValue = (this.keyValue).toLowerCase();
    let length = tagKeyValue.length;
    let result = this.tags.includes(tagKeyValue);
    if (result == false && formValidationValue) {
      const el = document.getElementById("tagValueId");
      el.style.borderLeft = '5px solid #42A948';
      const al = document.getElementById("buttonClickId");
      al.style.background = '#85D4FF';
    } else {
      const el = document.getElementById("tagValueId");
      el.style.borderLeft = '5px solid #a94442';
      const al = document.getElementById("buttonClickId");
      al.style.background = '#465374';
    }
  }
}

