import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResearchService} from "../../../_services/research.service";
import { DashboardService } from "../../../_services/dashboard.service";
import {Globals} from "../../../globals";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-budget-researcherwise',
  templateUrl: './budget-researcherwise.component.html',
  styleUrls: ['./budget-researcherwise.component.css', '../../../app.component.css'],
  providers: [Globals]
})
export class BudgetResearcherwiseComponent implements OnInit {

  researcher;
  projectId;
  projectName;
  projectType;
  loader = false;
  budgetAllotted :any = 0;
  budgetConsumed :any = 0;
  currentMonthCost :any = 0;
  currencySymbol: string = '$';
  products = [];
  researchers = [];
  budgetData;
  projectImg;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private researchService: ResearchService,
    private apiService: DashboardService,
    private globals: Globals,private toastr: ToastrService) { }

  ngOnInit() {
    this.researcher = this.route.snapshot.queryParams['researcher'];
    this.projectId = this.route.snapshot.queryParams['id'];
    this.getResearcherBudget();
    this.getProjects();
  }


budgetManagement(){
  this.router.navigate(['researcherData/budget-projectData'],{ queryParams: { researcher: this.researcher}});
}

getResearcherBudget() {
  this.loader = true;
  this.researchService.getResearcherListFromProjectId(this.projectId).subscribe((data:any) => {
    this.loader = false;
    this.researchers = data;
    this.budgetData = `Researcher Available Budget = Allocated Budget - Researcher Consumed Budget - ((Project Consumed Budget - Total Researcher Consumed Budget) / Total Number Of Researchers)\n`;
    this.researchers.forEach(item => {
      if(item.productList.length > 0){
        item.productsInString = item.productList.toString()
      }else {
        item.productsInString = "No products configured"
      }
      item.budgetCal = `${item.budgetAllocated} - ${parseFloat(item.budgetConsumed).toFixed(2)} - ((${ parseFloat(item.projectConsumedBudget).toFixed(2)} - ${ parseFloat(item.totalResearcherConsumedBudget).toFixed(2) })/ ${item.totalNumberOfResearchers})`
    })
    for(let i=0;i<data.length;i++){
      if(data[i]['name'] == this.researcher){
        this.budgetAllotted = parseFloat(data[i]['budgetAllocated']).toFixed(2);
        this.budgetConsumed = parseFloat(data[i]['budgetConsumed']).toFixed(2);
        this.currentMonthCost = parseFloat(data[i]['monthlyBudgetCosumedByResearcher']).toFixed(2);
      }
    }
  }, error => {
    this.loader = false;
    if(error && error.status === 401){
      let errBody = error.error;
       this.toastr.error(errBody.err, '', this.globals.tosterOverride);
       this.router.navigate(['login']);
     }
  });
}


getRoundOffBudget(data){
  return parseFloat(data).toFixed(2);
}

getProjects() {
  this.researchService.getPrincipalProjectsUsingId(this.projectId).subscribe(data => {
    this.projectImg = data[0].img_src;
    this.projectName = data[0].name;
    this.projectType = data[0].project_type;
  }, error => {
    this.loader = false;
    if(error && error.status === 401){
      let errBody = error.error;
       this.toastr.error(errBody.err, '', this.globals.tosterOverride);
       this.router.navigate(['login']);
     }else if (error && error.status === 403) {
      const err = error.error;
      this.toastr.error(err.error, 'Error', this.globals.tosterOverride);
      this.router.navigate(['pageNotFound']);
    }
  });
}

openProductBudgetScreen(researcher) {
  this.router.navigate(['researcherData/budget-management'],{ queryParams: { researcher: this.researcher, id: this.projectId, displayName: researcher.displayName }});
}

}
