<div class="container">
   <!-- breadcrumb -->
   <nav aria-label="breadcrumb">
      <ol class="breadcrumb bc-custom">
         <li class="breadcrumb-item">
            <a class="bc-item" *ngIf="userLevel == '0'" routerLink="/researcher"> My Projects </a>
            <a class="bc-item" *ngIf="userLevel == '1'" routerLink="/principal"> My Projects </a>
         </li>
         <li class="breadcrumb-item" aria-current="page">
            <a class="bc-item" *ngIf="userLevel == '0'" (click)="navigateToProject()"> {{ projectName }} </a>
            <a class="bc-item" *ngIf="userLevel == '1'" routerLink="/project-details/{{projectId}}"
            [queryParams]="{showTab: 'myProducts'}"> {{ projectName }} </a>
         </li>
         <li class="breadcrumb-item " aria-current="page" style="cursor: pointer;" (click)="routeBack()"
         *ngIf="provisionedProductName"><a class="bc-item">{{ provisionedProductName }} </a></li>
         <li class="breadcrumb-item active bc-item-active" aria-current="page">Explore Details</li>
      </ol>
   </nav>
   <!-- details -->
   <div style="display: flex; justify-content: space-between; align-items: center; margin: 20px 0px;">
      <div style="display: flex;">
         <img style="max-width: 75px; max-height: 75px;border-radius: 10px;" src="{{imageUrl}}"
            alt="S3 Bucket" />
         <div style="display: flex; flex-direction: column; margin: 10px 15px;">
            <h4 class="text-white font-class" *ngIf="provisionedProductName">{{ provisionedProductName}}</h4>
            <span style="color: #7596FF; font-size: 14px;" *ngIf="productName" class="font-class">{{productName}}
            <span *ngIf="isShared === true" class="vl">
            <img class="share-icon" src="../../assets/images/share-white.png" alt="Shared" />
            <span class="share-icon-text"> Shared </span>
            </span>
            <span *ngIf="isProjectStorage === true" class="vl">
            <i class="fa fa-hdd-o" style="font-size: 1.4em; color: #fff;margin-left: 20px;vertical-align: middle;"
               aria-hidden="true"></i>
            <span class="share-icon-text"> Project Storage </span>
            </span>
            <span *ngIf="isIngressStorage === true" class="vl">
               <i class="fa fa-hdd-o" style="font-size: 1.4em; color: #fff;margin-left: 20px;vertical-align: middle;"
                  aria-hidden="true"></i>
               <span class="share-icon-text"> Ingress Storage </span>
            </span>
            </span>
         </div>
      </div>
      <div class="web-status" style="display: flex;">
         <div class="ingress-btn-wrapper" *ngIf="isIngressStorage">
            <button [disabled]="!isAbleToSubmitIngressRequest  || clicked" class="ingress-btn text-white font-class" name="button" type="submit" (click)="submitIngressRequest()">Submit Ingress Request
               <i *ngIf="ingressStoreLoader" class="fa fa-refresh fa-spin" style="margin-left: 10px;"></i>
            </button>
         </div>
         <div class="web-status"
         style="height: 45px; display: flex; border: 1px solid #465374; border-radius: 23px; align-items: center; padding: 10px;">
            <img style="height: 15px; margin: 10px; cursor: pointer;" src="../../assets/images/refresh.png" alt="Refresh Provisioned Product Explore"
               (click)="refresh()" />
            <span style="margin: 0px 10px; font-size: 14px;" class="text-white font-class">Refresh</span>
         </div>
      </div>
   </div>
   <!-- mobile actions bar -->
   <div class="mbl-control-bar d-flex justify-content-between m-4" *ngIf="productStatus">
      <div
         style="height: 45px; display: flex; border: 1px solid #465374; border-radius: 23px; align-items: center; padding: 10px;">
         <div style="width: 20px; height: 20px; border-radius: 10px;" class="{{getStatusColor(productStatus)}}"></div>
         <span style="margin: 0px 10px; font-size: 14px;" class="text-white font-class">{{getStatus(productStatus)}}</span>
      </div>
   </div>
   <div class="divider"></div>
   <div class='s3Explore'>
      <div class="primary-flex">
         <h3 class="bucket-object" style="margin-right: auto;">
         Objects <h3 class="bucket-object" style="margin-top: 0px !important" *ngIf="!showSpinner">&nbsp;( {{ bucketData.length }} )</h3> 
         </h3>
 
         <div class="dropdown" style="margin-right: 20px" *ngIf="checkBoxValue.length > 0">
            <button class="tenant-cl btn dropdown-toggle menu-dropdown-content text-white font-class" type="button"
               (click)="checkForFileType()" data-toggle="dropdown">
            Actions
            <i class="fa fa-angle-down" title="Actions" aria-hidden="true"
               style="color: #fff;width: 12px;height: 18px;font-size: 20px;    margin-left: 10px;"></i>
            </button>
            <ul class="dropdown-menu" style="cursor: pointer;background: rgb(7, 16, 42);position: absolute;will-change: transform;top: 0px;left: 0px;
               transform: translate3d(-58px, 17px, 0px);">
               <li><a class="listItems" *ngIf="checkBoxValue.length == 1 && selectedFileType =='file' "
                  (click)="downloadS3FromCheckBoxValue()">Download</a>
               </li>
               <li><a class="listItems" *ngIf="checkBoxValue.length > 0" (click)="deleteMultipleS3Data()">Delete</a></li>
               <li><a class="listItems" *ngIf="checkBoxValue.length > 0 && !isIngressStorage" ngxClipboard [cbContent]="copyClipboard()"
                  (cbOnSuccess)="onCopySuccess()" (cbOnError)="onCopyError()">Copy to clipboard</a>
               </li>
               <li><a class="listItems" *ngIf="checkBoxValue.length == 1 && selectedFileType =='file' && preSignedUrlAccess && userLevel != '0'" 
                  (click)="createCopyPreSignedURLFromCheckBoxValue()">Create pre-signed URL</a>
               </li>
            </ul>
         </div>
 
         <div class="search-div">
          <em class="glyphicon glyphicon-search search-icon"></em>
          <input (keyup)="searchFilesInS3()" [(ngModel)]="searchKey" aria-label="searchFileName" class="form-control search-inp search-inp-add"
          placeholder="Find files by name" type="text">
        </div>
 
         <!-- <div class="web-status"
            style="height: 45px; display: flex; border: 1px solid #465374; border-radius: 23px; align-items: center; padding: 10px;">
            <img style="height: 15px; margin: 10px; cursor: pointer;" src="../../assets/images/refresh.png"
               (click)="refresh()" />
            <span style="margin: 0px 10px; font-size: 14px;" class="text-white font-class">Refresh</span>
         </div> -->
         <div class="web-status" (click)="createS3EmptyFolder()"
            style="background: #85D4FF; height: 45px; cursor: pointer; margin-left: 20px; display: flex; border: 1px solid #465374; border-radius: 23px; align-items: center; padding: 10px;">
            <span style="margin: 0px 10px; font-size: 14px;color: #030A1E !important; font-weight: bold;"
               class="text-white font-class">Create Folder</span>
         </div>
         <div class="dropdown" (click)="uploadFiles()"
            style="background: #85D4FF;margin-left: 20px;height: 45px; display: flex; border-radius: 23px; align-items: center">
            <button class="tenant-cl btn dropdown-toggle menu-dropdown-content text-white font-class" type="button">
               <i class="fa fa-upload" aria-hidden="true"
                  style="color: #030A1E;width: 12px;height: 24px;font-size: 18px; margin-right: 5px;"></i>
               <h3 style="color:#030A1E;font: normal normal bold 14px/12px Nunito Sans;padding: 10px;"> Upload</h3>
            </button>
         </div>
      </div>
      <!-- Responsive actions -->
      <div class="search-div-responsive">
         <em class="glyphicon glyphicon-search search-icon"></em>
         <input (keyup)="searchFilesInS3()" [(ngModel)]="searchKey" class="form-control search-inp search-inp-add"
         placeholder="Find files by name" aria-label="searchFileNameMobileView" type="text">
      </div>
      <div class="actionMenu">
         <div class="createNewDropdown">
            <button class="tenant-cl btn dropdown-toggle menu-dropdown-content text-white font-class" type="button"
               (click)="checkForFileType()" data-toggle="dropdown" style="color: #030A1E !important;">
            Create New
            <i class="fa fa-angle-down" title="Create New" aria-hidden="true"
               style="color: #030A1E;width: 12px;height: 18px;font-size: 15px;padding-left: 5px"></i>
            </button>
            <ul class="dropdown-menu" style="cursor: pointer;background: rgb(7, 16, 42);position: absolute;will-change: transform;top: 0px;left: 0px;
               transform: translate3d(-58px, 17px, 0px);">
               <li><a class="listItems" (click)="uploadFiles()">Upload</a></li>
               <li><a class="listItems" (click)="createS3EmptyFolder()">Folder</a></li>
            </ul>
         </div>
         <div
            style="height: 30px; display: flex; border: 1px solid #465374; border-radius: 23px; align-items: center; padding: 10px;background: #07102A;">
            <img style="height: 15px; margin: 10px; cursor: pointer;" src="../../assets/images/refresh.png" alt="Refresh Provisioned Product Explore"
               (click)="refresh()" />
            <span style="margin: 0px 10px; font-size: 10px;" class="text-white font-class">Refresh</span>
         </div>
      </div>
      <div style="margin-top: 10px">
         <div class="back-layout" (click)="goBackToRootFolder()" style="text-align: center;float: left;margin-right: 15px;"
         *ngIf="!showSpinner && showRootIcon">
         <img class="back-icon" style="margin-right: 10px;" src="../../../assets/images/pagination-prev-active.png" alt="Go To Root"/>
         <img class="back-icon" src="../../../assets/images/pagination-prev-active.png" alt="Go To Root"/>
         <h3 class="back-text text-white font-class">Root</h3>
      </div>
      <div class="back-layout" (click)="goBack()" style="text-align: center;float: left;">
         <img class="back-icon" src="../../../assets/images/pagination-prev-active.png" alt="Go Back" />
         <h3 class="back-text text-white font-class">Back</h3>
      </div>
      <div style="text-align: center" *ngIf="bucketData.length > 0 && !showSpinner && !folderName">
         <h2 class="modal-title title-header" id="myModalLabel" class="Header" style="text-align: center;
            font: normal normal bold 20px/12px Nunito Sans;
            letter-spacing: 0px;
            color: #85D4FF;">
            Available Items
         </h2>
      </div>
      <div style="text-align: center" *ngIf="!showSpinner && folderName">
         <h2 class="modal-title title-header" id="myModalLabel" class="Header" style="text-align: center;
            font: normal normal bold 20px/12px Nunito Sans;
            letter-spacing: 0px;
            color: #85D4FF;">
            {{folderName}}
         </h2>
      </div>
   </div>
   <app-loader class="spinner" *ngIf="showSpinner"></app-loader>
   <div *ngIf="bucketPrefixData.length == 0 && bucketData.length==0 && !showSpinner && productStatus!='Synchronizing'"
      class="empty-my-product-layout">
      <div>
         <h3 class="no-data-found text-white font-class text-center"> No files or folders </h3>
         <h4 class="no-data-found-subtext text-white font-class text-center">You don't have any files or folders in this
            bucket.
         </h4>
      </div>
   </div>
   <div class="table-responsive md-layout lg-layout" style="margin-bottom: 25px;">
      <table class="gfg" style="width: 100%" *ngIf="bucketData.length > 0 && !showSpinner">
         <thead style="background-color: #15244B">
            <tr>
               <th>
                  <p class="visually-hidden">checkbox</p>
                  <input type="checkbox" name="allCheckBoxSelected" [value]="" aria-label="allCheckBoxSelected" (change)="allCheckBoxSelected($event)">
               </th>
               <th class="tableHeader" style="width: 40%;">FILE NAME</th>
               <th class="tableHeader" style="width: 15%;">SIZE</th>
               <th class="tableHeader" style="width: 10%;">TYPE</th>
               <th class="tableHeader" style="width: 25%;">LAST UPDATED ON</th>
               <th class="visually-hidden">-</th>
            </tr>
         </thead>
         <tbody style="background-color: #07102A">
            <tr *ngFor="let data of bucketData" style="background-color:inherit">
               <td style="width: 10px;"><input type="checkbox" aria-label="data.name" [(ngModel)]="data.isChecked" [checked]="data.isChecked"
                  (change)="onCheckboxChanged($event, data)">
               </td>
               <td class="tableData" style="width: 40%;cursor: pointer;color: #73E2CF;" *ngIf="data.type == 'folder'" 
               (click)="getS3FolderData(data.name, true)">
               <i class="fa fa-folder" aria-hidden="true" style="margin-right: 15px;color: #f1f1f1"></i>
               {{getFiletName(data.name, '')}}
               </td>
               <td class="tableData" style="width: 40%;color: #73E2CF;" *ngIf="data.type != 'folder'">
                  <i class="fa fa-file" aria-hidden="true" style="margin-right: 15px;color: #f1f1f1"></i>
                  {{getFiletName(data.Key, 'table')}}
               </td>
               <td class="tableData" style="width: 15%;font-size: 14px" *ngIf="data.Size != 0">{{getFileSize(data.Size)}}
               </td>
               <td class="tableData" style="width: 15%;font-size: 14px" *ngIf="data.Size == 0 && data.type != 'folder'">
                  {{data.Size}}
               </td>
               <td class="tableData" style="width: 15%;" *ngIf="data.Size == 0 && data.type == 'folder'"> - </td>
               <td class="tableData" style="width: 10%;font-size: 14px">{{getFileType(data)}}</td>
               <td class="tableData" style="width: 25%;font-size: 14px" *ngIf="data.LastModified">{{data.LastModified |
                  date:'medium'}}
               </td>
               <td class="tableData" style="width: 25%;" *ngIf="!data.LastModified"> - </td>
               <td class="tableData" style="text-align: center;overflow: visible !important;position:relative;">
                  <div class="dropdown">
                     <span>
                        <i class="fa fa-ellipsis-v" data-toggle="dropdown" title="Action" aria-hidden="true"
                           style="color: #f1f1f1;cursor: pointer;"></i>
                        <ul class="dropdown-menu" style="cursor:pointer;background: #07102A;">
                           <li><a class="listItems" *ngIf="data.type != 'folder'" (click)="downloadS3(data.Key)">Download</a>
                           </li>
                           <li><a class="listItems" *ngIf="data.type != 'folder'" (click)="deleteS3File(data.Key)">Delete</a>
                           </li>
                           <li><a class="listItems" *ngIf="data.type == 'folder'"
                              (click)="deleteS3Folder(data.name)">Delete</a>
                           </li>
                           <li><a class="listItems"  *ngIf="!isIngressStorage" ngxClipboard [cbContent]="copyPath(data)" (cbOnSuccess)="onCopySuccess()" (cbOnError)="onCopyError()">Copy to clipboard</a>
                           </li>
                           <li><a class="listItems" *ngIf="data.type == 'folder'"
                              (click)="uploadFilesToSpecificFolder(data.name)">Upload</a>
                           </li>
                           <li><a class="listItems" *ngIf="data.type != 'folder' && preSignedUrlAccess && userLevel != '0'" (click)="createCopyPreSignedURL(data.Key)">Create pre-signed URL</a>
                           </li>
                        </ul>
                     </span>
                  </div>
               </td>
            </tr>
         </tbody>
      </table>
      <div class="load-more-btn-wrapper" *ngIf="showLoadMore && !showSpinner">
         <button class="load-more-btn" (click)="loadMoreObjects()">Load More</button>
      </div>
   </div>
   <div class="sm-layout xs-layout" *ngIf="bucketData.length > 0 && !showSpinner">
      <!-- mobile responsive -->
      <div class="project-cards-layout">
         <div class="project-card" *ngFor="let data of bucketData;">
            <ul class="list-group">
               <div class="d-flex justify-content-between mb-2">
                  <div class="project-card-title project-card-line-height font-class">File Name</div>
                  <div class="project-card-project-name" style="text-decoration: underline;" *ngIf="data.type == 'folder'"
                  title="{{data.name}}" (click)="getS3FolderData(data.name)">{{getFiletName(data.name, '')}}
               </div>
               <div class="project-card-project-name" *ngIf="data.fileType != 'folder'" title="{{data.Key}}">
                  {{getFiletName(data.Key, 'table')}}
               </div>
         </div>
         <div class="d-flex justify-content-between mb-2">
         <div class="project-card-title project-card-line-height font-class">Size</div>
         <div class="project-card-project-name" *ngIf="data.size != 0">{{getFileSize(data.size)}} MB</div>
         <div class="project-card-project-name" *ngIf="data.size == 0 && data.type != 'folder'">{{data.size}} MB
         </div>
         <div class="project-card-project-name" *ngIf="data.size == 0 && data.type == 'folder'"> - </div>
         </div>
         <div class="d-flex justify-content-between mb-2">
         <div class="project-card-title project-card-line-height font-class">Type</div>
         <div class="project-card-project-name">{{getFileType(data)}}</div>
         </div>
         <div class="d-flex justify-content-between mb-2">
         <div class="project-card-title project-card-line-height font-class">Last Updated On</div>
         <div class="project-card-project-name" *ngIf="data.lastModified">{{ data.lastModified | date:'medium' }}
         </div>
         <div class="project-card-project-name" *ngIf="!data.lastModified"> - </div>
         </div>
         <div class="d-flex justify-content-between mb-2" style="margin-top: 10px;" *ngIf="data.type != 'folder'">
         <button type="button" style="width: 40%;" class="btn  can-btn"
            (click)="downloadS3(data.Key)">Download</button>
         <button type="button" style="width: 40%;" class="btn  can-btn"
            (click)="deleteS3File(data.Key)">Delete</button>
         </div>
         <div class="d-flex justify-content-between mb-2" style="margin-top: 10px;" *ngIf="data.type == 'folder'">
         <button type="button" style="width: 40%;" class="btn  can-btn"
            (click)="deleteS3Folder(data.name)">Delete</button>
         <button type="button" style="width: 40%;" class="btn  can-btn"
            (click)="uploadFilesToSpecificFolder(data.name)">Upload</button>
         </div>
         </ul>
      </div>
   </div>
   <div class="load-more-btn-wrapper" *ngIf="showLoadMore && !showSpinner">
      <button class="load-more-btn" (click)="loadMoreObjects()">Load More</button>
   </div>
 </div>
 </div>
</div>