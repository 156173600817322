import { Component, OnInit, Inject } from '@angular/core';
import { MdlDialogReference } from '@angular-mdl/core';
import { ToastrService } from 'ngx-toastr';
import { ResearchService} from "../../_services/research.service";
import { DataService } from '../../_services/data.service';
import {Globals} from "../../globals";
import { Router } from '@angular/router';
@Component({
  selector: 'app-enable-user',
  templateUrl: './enable-user.component.html',
  styleUrls: ['./enable-user.component.css'],
  providers: [Globals]
})
export class EnableUserComponent implements OnInit {

  userInfo;
  action;
  showSpinner = false;

  constructor(public dialogRef: MdlDialogReference, @Inject('data') public data: any, private researchService: ResearchService, 
    private toastr: ToastrService, private router: Router, private dataService: DataService,private globals: Globals) { }

  ngOnInit() {
    if(this.data.hasOwnProperty('userInfo')){
      this.userInfo = this.data.userInfo;
    }
    if(this.data.hasOwnProperty('action')){
      this.action = this.data.action;
    }
  }

  cancel() {
    this.dialogRef.hide();
  }

  submit() {
    if(this.action === 'enableUser'){
      this.showSpinner = true;
      this.researchService.enableUser(this.userInfo.user_id).subscribe((res:any) => {
        this.showSpinner = false;
        if(res.status === 'success'){
          this.toastr.success(res.message);
          this.dataService.updatePage.next(true);
          this.dialogRef.hide();
        }else {
          this.toastr.error(res.message);
          this.dialogRef.hide();
        }
      }, error => {
        this.showSpinner = false;
        this.dialogRef.hide();
        if(error && error.status === 401){
          let errBody = error.error;
           this.toastr.error(errBody.err, '', this.globals.tosterOverride);
           this.router.navigate(['login']);
         }
        const errorBody = error.error;
        this.toastr.error(errorBody.message);
      })
    }else if(this.action === 'disableUser'){
      this.showSpinner = true;
      this.researchService.disableUser(this.userInfo.user_id).subscribe((res:any) => {
        this.showSpinner = false;
        if(res.status === 'success'){
          this.toastr.success(res.message);
          this.dataService.updatePage.next(true);
          this.dialogRef.hide();
        }else {
          this.toastr.error(res.message);
          this.dialogRef.hide();
        }
      }, error => {
        if(error && error.status === 401){
          let errBody = error.error;
           this.toastr.error(errBody.err, '', this.globals.tosterOverride);
           this.router.navigate(['login']);
         }
        const errorBody = error.error;
        this.showSpinner = false;
        this.toastr.error(errorBody.message, '', this.globals.tosterOverride);
      })
    }
  }

}
