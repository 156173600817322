<div class="form-group" id="formData" >
  <button class="close close-btn" data-dismiss="modal" type="button" title="Close" (click)="cancel()">
    <i class="fa fa-times-circle close-btn-icon"></i></button>
  <h3 class="Header header-title">Set up Account</h3>
  <div style="display: flex;flex-direction: row;margin-top: 10px;margin-bottom: 5px;">
    <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
  </div>
  <div>
    <h3 style="padding: 35px;color: white;font:normal normal normal 14px/12px Nunito Sans;line-height: 1.6em;">
      <i class="fa fa-info-circle" aria-hidden="true" style="color: orange;margin-right: 5px;font-size: 15px;"></i>
      You need an AWS account to create a new {{value}}. Add an account on the settings page first.
    </h3>
    <div class="col-lg-12 col-md-12" style="text-align: center;">
      <button class="launch-btn text-white font-class" style="height: 45px !important;width: 182px !important;outline: none" (click)="routeToSettings()" name="button" type="button">Add Account</button>
    </div>

  </div>
</div>
