import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import {MatAccordion} from '@angular/material/expansion';
import { ResearchService } from "../_services/research.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { FormBuilder } from "@angular/forms";
import { Globals } from "../globals";
import { PreviousRouteService } from '../_services/url.service';
// import 'rxjs/add/operator/pairwise';
// import 'rxjs/add/operator/filter';
import {CreateKeypairComponent} from "../keypair/create-keypair/create-keypair.component";
import {MdlDialogService} from "@angular-mdl/core";
import { DashboardService } from '../_services/dashboard.service';


@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css'],
  providers: [DashboardService,Globals]
})
export class OrderComponent implements OnInit {
  studySelection = false;
  productId;
  stdCatalogitemId
  productName;
  parameterArray = [];
  parameterGroups = [];
  keyPairNames = [];
  vpcNames = [];
  securityGroupsNames = [];
  subnetNames = [];
  dynamicVpcId;
  dynamicSubnetId;
  dynamicKeyPair = "";
  teamForm;
  formedParams = [];
  formedSecondParams = [];
  formedobject = [];
  projectName;
  accountId;
  provisionedProductName;
  provisioningArtifactId;
  pathId;
  provisionedSharedResource;
  getSessionData;
  getETADetails= "";
  showSpinner = false;
  loaderShow = false;
  currentUrl;
  userLevel;
  projectId;
  currentUser = sessionStorage.getItem('currentUser');
  productNameErrDesc;
  defaultHelpClass='productNameHelpText';
  showNoParamFoundMsg;
  ppSearchArea = false;
  s3SearchArea = false;
  s3OutSearchArea = false;
  dynamicS3="";
  tempDynamicS3="default";
  tempDynamicOutS3="default";
  dynamicOutS3="";
  ppContainer = [];
  s3Container = [];
  ppContainerActual = [];
  dynamicPPName = "";
  dynamicPLContainer = "";
  preProvisioningArray = [];
  outSelectS3 = "all";
  inSelectS3 = "all"
  ppOptionImage = false;
  s3IOptionImage = false;
  s3OOptionImage = true;
  filterListS3 = ['All', 'Private', 'Shared', 'Project Storage', 'Study'];
  filterList = [];
  filterS3I = "All";
  filterS3O = "All";
  inSelectS3Select = "All";
  outSelectS3Select = "All";
  searchLoader = true;
  s3BucketRegex = new RegExp(/(?=^.{3,63}$)(?!^(\d+\.)+\d+$)(^(([a-z0-9A-Z]|[a-z0-9A-Z][a-z0-9A-Z\-]*[a-z0-9A-Z])\.)*([a-z0-9A-Z]|[a-z0-9A-Z][a-z0-9A-Z\-]*[a-z0-9A-Z\s])$)/)
  ppNameRegex = new RegExp(/[(),-_., ]/)
  productService = "s3BucketRegex";
  browserName="";
  studySelectionCount = 3;
  studyList=[];
  studySelectedList = [];

  @ViewChild('menu') menu: ElementRef;
  @ViewChild('MatAccordion') accordion: MatAccordion;
  vendor: any;
  researchProducts: Object;

  constructor(private researchService: ResearchService, private dialog: MdlDialogService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private globals: Globals,
    private renderer: Renderer2,
    private dashboardService: DashboardService,
    private previousRouteService: PreviousRouteService) {
    this.researchService.getKeypairCreateEvent().subscribe(() => {
      this.getKeyName();
    });

    this.renderer.listen('window', 'click',(e:Event)=> {
      const tagClassName = this.getValue(e);

      const validatePP = tagClassName.includes("pp-input-btn");
      const validateS3 = tagClassName.includes("InputDataLocation");
      const validateOutS3 = tagClassName.includes("OutputDataLocation");

      this.s3OutSearchArea = false;
      this.s3SearchArea = false;
      this.ppSearchArea = false;
      // This is module will execute when inputdatalocation pressed
      if(validateS3 == true) {
        let searchKey = this.dynamicS3.trim() || '';
        if(searchKey) {searchKey = searchKey.toLocaleLowerCase();}
        const s3BucketNameTest = this.s3BucketRegex.test(searchKey);
        if(s3BucketNameTest == false) {
          this.s3Container = [];
          this.s3SearchArea = false;
          if(searchKey == '') {
            this.getS3Data(this.inSelectS3, searchKey,'in');
            this.s3SearchArea = true;
          }
        } else {
          this.s3SearchArea = true;
        }

      }
      // This is module will execute when pipeline pressed
      if(validatePP == true) {
        this.ppSearchArea = true
      }
      // This is module will execute when outputdatalocation pressed
      if(validateOutS3 == true) {
        let searchKey = this.dynamicOutS3.trim() || '';
        if(searchKey) {searchKey = searchKey.toLocaleLowerCase();}
        const s3BucketNameTest = this.s3BucketRegex.test(searchKey);
        if(s3BucketNameTest == false) {
          this.s3Container = [];
          this.s3OutSearchArea = false;
          if(searchKey == '') {
            this.getS3Data(this.outSelectS3, '', 'out');
            this.s3OutSearchArea = true;
          }
        } else {
          this.s3OutSearchArea = true;
        }
      }

    })
  }

  getValue(event: Event): string {
    return (event.target as HTMLInputElement).className;
  }

  ngOnInit() {
    this.productId = this.route.snapshot.params['productId'];
    this.stdCatalogitemId = this.route.snapshot.params['stdCatalogitemId'];
    this.productName = decodeURIComponent(this.route.snapshot.params['name']);
    this.projectName = sessionStorage.getItem('projectName');
    this.accountId = this.route.snapshot.params['accountId'];
    this.projectId = this.route.snapshot.params['projectId'];
    this.vendor = this.route.snapshot.params['vendor'];
    if (this.vendor.toLocaleLowerCase()=='azure'){
      this.studySelection=true;
    }
    this.researchService.getResearchProductsParameters(this.productId, this.productName, this.projectId, this.stdCatalogitemId).subscribe(res => {
      this.showSpinner = false;
      this.researchPP(res);
      this.researchProducts = res
    }, error => {
      this.showSpinner = false;
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }else if (error && error.status === 403) {
        const err = error.error;
        this.toastr.error(err.error ? err.error : err, 'Error', this.globals.tosterOverride);
        this.router.navigate(['pageNotFound']);
      }
    });
    this.getSessionData = localStorage.getItem(this.productId);
    this.getSessionData = JSON.parse(this.getSessionData);
    if(this.getSessionData && this.getSessionData.tags){
      this.getProductETA(this.getSessionData.tags);
    }
    this.userLevel = sessionStorage.getItem('Level');
    this.showSpinner = true;
    this.getS3Data('all', '','all');

  }


  private getS3Data(type,searchKey,label) {
    this.searchLoader = true;
    this.researchService.getResearchProduct(this.productId, 'Amazon S3',type, searchKey, this.projectId).subscribe((res:any) => {
      this.s3Container = res || [];
      this.searchLoader = false;
      if(this.s3Container.length == 0) {
        if(label == 'in') {
          this.s3SearchArea = true;
        } else if(label == 'out') {
          this.s3OutSearchArea = true;
        }

      }
    }, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    });
  }

  private researchPP(res) {
    this.parameterArray = res.params.ProvisioningArtifactParameters;
    this.preProvisioningArray = res.params.PreProvisioning || [];

    // Product study selection integration
    const studies = res.params.Studies;
    let {studySelectionCount, isStudySupported, studyList} = studies;
    if(isStudySupported) {
      this.studySelection = true;
    }
    this.studySelectionCount = studySelectionCount;
    studyList.map((studyObj) => {
      studyObj.studyAccess == 'readwrite' ? studyObj.studyAccess = 'read-write' : studyObj.studyAccess = 'read-only'
      if (studyObj.studyType == 'internal'){
        studyObj.studyType = 'Internal'
      } else if (studyObj.studyType == 'external'){
        studyObj.studyType = 'External';
        studyList = studyList.filter(function (study) {
          return study.status !== 'Error'
        })
      }
    })
    this.studyList = studyList;

    // tslint:disable-next-line:triple-equals
    if (this.parameterArray.length == 0) {
      this.showNoParamFoundMsg = true;
    }
    this.parameterGroups = res.params.ParameterGroups;
    this.provisioningArtifactId = res.artifactId;
    this.pathId = res.pathId;
    this.formedParams = this.parameterArray.map(ob => {
      let scCFTParamsOV = this.preProvisioningArray.find((el) => el.name == ob.ParameterKey);
      let pType = ob.ParameterType;
      if(scCFTParamsOV) {
        pType = scCFTParamsOV.type
      }
      const obj = {
        key: ob.ParameterKey,
        default: ob.DefaultValue || '',
        description: ob.Description,
        select: ob.ParameterConstraints.AllowedValues,
        value: ob.DefaultValue,
        allowedValue: ob.allowedValue,
        parameterType: pType,
        parameterConstraints: ob.ParameterConstraints
      };
      if(obj.key == 'ResearcherName' && obj.parameterType == 'RL::SC::PARAM::HD') {
        obj.value = this.currentUser;
      }
      if(obj.key == 'ProjectId' && obj.parameterType == 'RL::SC::PARAM::HD') {
        obj.value = this.projectId;
      }

      return obj;
    });

    this.formedSecondParams = this.parameterGroups.reduce((accumulator, currentValue, currentIndex, array) => {
      const parameters = currentValue['Parameters'];
      const parametersObj = parameters.map((el) => {
        const getKeyObj = this.formedParams.find(obj => obj.key === el);
        return getKeyObj;
      });
      const obj = {
        label: currentValue['Label']['default'] || null,
        getKeyObj: parametersObj
      };
      return accumulator.concat(obj);
    }, []);
    if (this.formedSecondParams.length === 0) {
      const obj = {
        label: '',
        getKeyObj: this.formedParams
      };
      this.formedSecondParams.push(obj);
    }
    this.getKeyName();
    this.researchService.getKeypairCreateEvent().subscribe(() => {
      this.getKeyName();
    });

    // According to the service fetch the pipeline in the backend
    switch(this.productService) {
      case 'nextflow':
        this.getNFPP();
      break;
      case 'cromwell':
        this.getCromwellPP();
      break;
    }


  }

  onSelectChange(fParams: any, selectedValue: any) {
    if (selectedValue === "awsbatch") {
      fParams.getKeyObj.forEach((obj: { key: string; parameterType: string }) => {
        switch (obj.key) {
          case "MaximumInstances":
            obj.key = "ComputeEnvMaxvCpus";
            break;
          case "MinimumInstances":
            obj.key = "ComputeEnvMinvCpus";
            break;
          case "ComputeEnvDesiredvCpus":
            obj.parameterType = "Number";
            break;
        }
      });
    } else if (selectedValue === "slurm") {
      fParams.getKeyObj.forEach((obj: { key: string; parameterType: string }) => {
        switch (obj.key) {
          case "ComputeEnvMaxvCpus":
            obj.key = "MaximumInstances";
            break;
          case "ComputeEnvMinvCpus":
            obj.key = "MinimumInstances";
            break;
          case "ComputeEnvDesiredvCpus":
            obj.parameterType = "RL::SC::PARAM::HD";
            break;
        }
      });
    }
  }

  private getNFPP() {
    this.researchService.getNFCorePipeline().subscribe((res:any) => {
      this.ppContainerActual = res.data;
      this.ppContainer = this.ppContainerActual;
    });
  }

  private getCromwellPP() {
    this.researchService.getCromwellPipeline().subscribe((res:any) => {
      this.ppContainerActual = res.data.value;
      this.ppContainer = this.ppContainerActual;
    });
  }

  searchS3Product() {
    let searchKey = this.dynamicS3.trim() || '';
    if(searchKey.length == 0) {
      this.dynamicS3 = "";
      this.getS3Data(this.inSelectS3, searchKey,'in');
    }
    if(searchKey) {searchKey = searchKey.toLocaleLowerCase();}
    const s3BucketNameTest = this.s3BucketRegex.test(searchKey);

    if(this.tempDynamicS3 == searchKey || s3BucketNameTest == false || searchKey.length == 0) {
      this.s3SearchArea = false;
      return;
    } else {
      this.s3SearchArea = true;
    }
    this.tempDynamicS3 = searchKey;
    this.getS3Data(this.inSelectS3, searchKey, 'in');
  }

  searchS3OutProduct() {
    let searchKey = this.dynamicOutS3.trim() || '';
    if(searchKey.length == 0) {
      this.dynamicOutS3 = "";
      this.getS3Data(this.inSelectS3, searchKey,'out');
    }

    if(searchKey) {searchKey = searchKey.toLocaleLowerCase();}
    const s3BucketNameTest = this.s3BucketRegex.test(searchKey);

    if(this.tempDynamicOutS3 == searchKey || s3BucketNameTest == false || searchKey.length == 0) {
      this.s3OutSearchArea = false;
      return;
    } else {
      this.s3OutSearchArea = true;
    }
    this.tempDynamicOutS3 = searchKey;
    this.getS3Data(this.outSelectS3, searchKey, 'out');
  }

  searchNFPipeline() {
    let searchKey = this.dynamicPPName.trim() || '';

    if(searchKey.length == 0) {
      this.dynamicPPName = "";
    }
    if(this.isURL(searchKey)) {
      this.ppOptionImage = true;
    } else {
      this.ppOptionImage = false;
    }
    if (searchKey.length === 0 && this.dynamicPPName !== searchKey){
      this.ppContainer = this.ppContainerActual;
    }
    if(searchKey) {searchKey = searchKey.toLocaleLowerCase();}

    const regex = new RegExp(`${searchKey}`);
    this.ppContainer = [];
    for(let i=0; i<this.ppContainerActual.length; i++){
      const result = this.ppContainerActual[i].pipeline_name.match(regex);
      if(result){
        const input=result['input']
        const ppObj = this.ppContainerActual.find(obj => obj.pipeline_name == input)
        this.ppContainer.push(ppObj)
        // this.ppOptionImage = true;
      }
    }
    this.ppSearchArea = true;
  }

  isURL(str) {
    const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
    return re.test(str);
  }

  ppContainerArea(obj) {
    this.dynamicPPName = obj.pipeline_git_url;
    this.dynamicPLContainer = obj.pipeline_container;
    this.ppOptionImage = true;
    this.formedParams.map((ob) => {
      if (ob.parameterType === 'RL::SC::PIPELINE') {
        ob.value = this.dynamicPPName;
      }
      if (ob.parameterType === 'RL::SC::PIPELINE_CONTAINER') {
        ob.value = this.dynamicPLContainer;
      }
    });
  }

  s3ContainerArea(obj, key) {
    this.formedParams.map((ob) => {
      if (ob.parameterType === 'RL::SC::S3' && ob.key === key) {
        ob.value = obj.s3BucketName;
        if(key == 'InputDataLocation') {
          this.dynamicS3 = obj.s3BucketName;
          this.tempDynamicS3 = this.dynamicS3;
          this.s3IOptionImage = true;
        }
        if(key == 'OutputDataLocation') {
          this.dynamicOutS3 = obj.s3BucketName;
          this.tempDynamicOutS3 = this.dynamicOutS3;
          this.s3OOptionImage = true;
        }
      }
    });
  }

  detectBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  private getKeyName() {
    this.browserName = this.detectBrowserName();
    try {
      for (let i = 0; i < this.formedParams.length; i++) {
        if (this.formedParams[i].allowedValue === true) {
          const payload = {
            'keyType' : this.formedParams[i].parameterType,
            'accountId' : this.accountId,
            'projectId'  : this.projectId
          };
          this.researchService.getParameterAllowedValue(payload).subscribe((res:any) => {
            const resData = res.data;
            const { data , parameterType } = resData;
            switch (parameterType) {
              case 'KeyPair' :
                const getOrganName = data.map((org) => org.keypairName);
                this.keyPairNames = getOrganName || [];
                const getKPLen = this.keyPairNames.length;
                if(getKPLen == 1) {
                  this.formedParams.map((ob) => {
                    if (ob.parameterType === 'AWS::EC2::KeyPair::KeyName') {
                      ob.value = this.keyPairNames[0];
                      this.dynamicKeyPair = this.keyPairNames[0];
                    }
                  });
                }
                break;
              case 'VPC' :
                this.vpcNames = data || [];
                const getLenVPC = this.vpcNames.length;
                if (getLenVPC === 1) {
                  this.dynamicVpcId = this.vpcNames[0].vpcId;
                } else {
                  const vpcDefaultObj = this.vpcNames.find((ob) => ob.isDefault === true);
                  this.dynamicVpcId = vpcDefaultObj.vpcId;
                }
                this.formedParams.map((ob) => {
                  if (ob.parameterType === 'AWS::EC2::VPC::Id') {
                    ob.value = this.dynamicVpcId;
                  }
                });
                this.getSecurityGroupsAndSubnet();
                break;
              default:
                break;
            }
          }, error => {
            this.toastr.error(`We are not able to fetch the launch form details. Please reload the page.`, '', this.globals.tosterOverride);
          });
        }
      }
    } catch (e) {

    }
  }

  getSecurityGroupsAndSubnet() {
    try {
      this.researchService.getSecurityGroupsAndSubnet(this.dynamicVpcId).subscribe((res:any) => {
        const {securityGroups, subnets } = res.data;
        this.securityGroupsNames = securityGroups;
        this.subnetNames = subnets || [];

        const getLenSub = this.subnetNames.length;
        const subDefaultObj = this.subnetNames.find((ob) => ob.isDefault === true);
        if (getLenSub === 1 || !subDefaultObj) {
          this.dynamicSubnetId = this.subnetNames[0].subnetId;
        } else {
          this.dynamicSubnetId = subDefaultObj.subnetId;
        }
        this.formedParams.map((ob) => {
          if (ob.parameterType === 'AWS::EC2::Subnet::Id') {
            ob.value = this.dynamicSubnetId;
          }
        }, error => {
          this.toastr.error(`We are not able to fetch the launch form details. Please reload the page`, '', this.globals.tosterOverride);
        });
      });
    } catch (e) {

    }
  }

  onFormSubmit(obj) {
    if (!this.provisionedProductName) {
      this.toastr.error(`Specify a name for the product that you want to create.`, '', this.globals.tosterOverride);
      return;
    }
    this.showSpinner = true;
    let validateParams = false;
    let keyPair = obj.filter(ob => ob.key === 'KeyName');
    if(keyPair && keyPair.length > 0){
      obj.find(ob => ob.key == 'adminPublicKey').value = obj.find(ob => ob.key == 'KeyName').value
    }
    obj = obj.filter(ob => !((ob.key === "ComputeEnvMinvCpus" || ob.key === "ComputeEnvMaxvCpus" || ob.key === "ComputeEnvDesiredvCpus" || ob.key === "MaximumInstances" || ob.key === "MinimumInstances") && ob.parameterType == "RL::SC::PARAM::HD"));
    const ProvisioningParameters = obj.map(o => {
      const ob = {
        Key: o.key,
        Value: o.value,
      };
      return ob;
    });
    for (const ob of obj) {
      if (!ob.value && ob.parameterType !== 'RL::SC::PARAM::HD') {
        validateParams = true;
        this.toastr.error(`Specify a value for the "${ob.key}" that you want to create.`, '', this.globals.tosterOverride);
      }
    }
    if (validateParams === true) {
      this.showSpinner = false;
      return;
    }
    const formedObj = {
      'studies' : this.studySelectedList,
      'account_id': this.accountId,
      'projectId': this.projectId,
      'ProductName': this.productName,
      'projectName': this.projectName,
      'ProvisionedProductName': this.provisionedProductName,
      'ProductId': this.productId,
      'ProvisioningArtifactId': this.provisioningArtifactId,
      'PathId': this.pathId,
      'ProvisioningParameters': ProvisioningParameters,
      'tags': this.getSessionData.tags || []
    };
    this.showSpinner = true;
    this.researchService.postProvisionProduct(formedObj).subscribe((res:any) => {
      this.showSpinner = false;
      if (res.status === "ERROR") {
        if (typeof res.message === "string") {
          this.toastr.error(res.message, 'Product Provision', this.globals.tosterOverride);
        } else if (typeof res.message === "object" && res.message.message) {
          this.toastr.error(res.message.message, '', this.globals.tosterOverride);
        }
      } else {
        this.toastr.success(this.productName + " launched successfully!", '', this.globals.tosterOverride);
        this.toastr.info(this.productName + " is expected to take " + this.getETADetails + " of time to complete provisioning!", '', this.globals.tosterOverride);
        let userRole = sessionStorage.getItem('Level');
        //if user is principal, route to project-details page else route to catalog page
        if(userRole == '1'){
          this.router.navigate([`/project-details/${this.projectId}`],{ queryParams: { showTab:'myProducts'}});
        }else{
          this.router.navigate(['/catalog/' + this.projectName + '/' + this.accountId]);
        }
      }
    }, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    });
  }

  getProductETA(data) {
    try {
      const getETA = data.find((obj) => obj.Key === 'EstimatedTimeToProvision');
      const getService = data.find((obj) => obj.Key === 'Service') || "";
      if(getService) {
        const getSerVal = getService.Value.toLowerCase();
        this.productService = getSerVal;
        if(getSerVal == "nextflow") {
          this.dynamicOutS3="/home/ec2-user/nextflow/outputs";
        }
      }
      this.getETADetails = getETA['Value'] || 'NA';
    } catch (error) {
      this.getETADetails = 'NA';
    }
  }

  checkProductName(event){
    const dataLeng = event.target.value.length;
    const regexPattern = /^[a-zA-Z0-9][a-zA-Z0-9._-]*$/
    const getRegValue = event.target.value.match(regexPattern);
    if(dataLeng == 0){
         this.defaultHelpClass = 'productNameHelpText';
    }else if(getRegValue === null){
      this.defaultHelpClass = 'productNameError';
      // this.productNameErrDesc = 'Only alphanumeric characters, dot, hyphens and underscores are allowed. Spaces and special characters are not allowed';
    }else if(getRegValue.length > 0){
      this.defaultHelpClass = 'productNameHelpText';
    }
  }

  addKeyPairs(): void {
    this.dashboardService.getDialogBox().subscribe((data:any) => {
      if(data.status === 200){
    this.dialog.showCustomDialog({
      component: CreateKeypairComponent,
      providers: [{ provide: 'data', useValue: { services: 'order', name: '' } }],
      isModal: true,
      styles: {
        "height":"auto",
        "width":"40%",
        "background": "#07102A",
        "border": "1px solid #FFFFFF33",
        "border-radius": "10px",
        "padding": "0px"
      },
      clickOutsideToClose: true,
      enterTransitionDuration: 400,
      leaveTransitionDuration: 400
    })}}, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    })
  }

  navigateToProject(){
    const projectName = encodeURIComponent(this.projectName);
    sessionStorage.setItem('projectName', this.projectName);
    this.router.navigate(['/catalog/' + projectName + '/' + this.accountId ]);
  }

  inputS3DD(type) {
    this.inSelectS3 = type.toLowerCase().replace(" ", "");
    this.getS3Data(this.inSelectS3, '', 'in');
    if(type == "Project Storage") {
      type = "Project..."
    }
    this.filterS3I = type;
  }

  outputS3DD(type) {
    this.outSelectS3 =type.toLowerCase().replace(" ", "");
    this.getS3Data(this.outSelectS3, '', 'out');
    if(type == "Project Storage") {
      type = "Project..."
    }
    this.filterS3O = type;
  }

  ppLead() {
    window.open(this.dynamicPPName, '_blank');
  }

  createStudy() {
    this.router.navigate(['researcher/studies/actions' ],{ queryParams :{mode:"2"}});
  }

  updateStudySelection(study, event) {
    const getLen = this.studySelectedList.length;
    if (event.target.checked) {
      if(getLen >= this.studySelectionCount) {
        this.toastr.info(`You can select a maximum of ${this.studySelectionCount} studies.`, '', this.globals.tosterOverride);
        event.target.checked = false;
        return;
      }
      this.studySelectedList.push(study._id);
    } else {
      const index = this.studySelectedList.indexOf(study._id);
      if (index > -1) {
        this.studySelectedList.splice(index, 1);
      }
    }
  }

}
