import { Component, OnInit } from '@angular/core';
import { ResearchService } from "../../_services/research.service";
import { ProductUtilService } from "../../_services/productUtil.service";
import { ToastrService } from 'ngx-toastr';
import { MdlDialogService, MdlDialogReference } from '@angular-mdl/core';
import { Globals } from "../../globals";
import { ActivatedRoute, Router, Event, NavigationStart, NavigationError, NavigationEnd } from "@angular/router";

import { AssignCatalogComponent } from '../../catalog-management/assign-catalog/assign-catalog.component';
import { DashboardService } from "../../_services/dashboard.service";

@Component({
  selector: 'app-catalog-management-principal',
  templateUrl: './catalog-management-principal.component.html',
  styleUrls: [ '../catalog-management.component.css', './catalog-management-principal.component.css'],
  providers: [DashboardService,Globals]
})
export class CatalogManagementPrincipalComponent implements OnInit {

  categoryFilter = "O.U. Catalog";
  selectedFilter = "All"
  projects;
  showSpinner = true;
  projectId;
  availableProductsToShow = [];
  catalogCount = 0;
  disableAssign = true;
  productTypes;
  noDataTemplate = "";
  searchKey;
  searchValueTemp = '';
  searchExecKey = '';
  searchValid = false;
  checkBoxValue = [];
  accountId;
  projectName;
  productIds = [];
  isStackCreated = false;
  parametersObservable: any;

  constructor(private researchService: ResearchService,
    private productUtil: ProductUtilService,
    private toastr: ToastrService,
    private globals: Globals,
    private activatedRoute: ActivatedRoute,
    private route: Router,
    private dialog: MdlDialogService,
    private apiService:DashboardService) {
      this.route.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (event['url'] === '/principal/catalog?assignedProduct=success') {
          this.route.navigate(['/principal/catalog']);
          this.getProjects();
          this.getCatalogItems('All', '');
        }
      }
    })

  }

  ngOnInit() {

    document.querySelector('.custom-select-wrapper').addEventListener('click', function () {
      this.querySelector('.custom-select').classList.toggle('open');
    })
    window.addEventListener('click', function (e) {
      const select = document.querySelector('.custom-select')
      if (select && !select.contains(e.target as Node)) {
        select.classList.remove('open');
      }
    });


    // mobile responsive dropdown
    document.querySelector('.mobile-custom-select-wrapper').addEventListener('click', function () {
      this.querySelector('.mobile-custom-select').classList.toggle('open');
    })
    window.addEventListener('click', function (e) {
      const select = document.querySelector('.mobile-custom-select')
      if (select && !select.contains(e.target as Node)) {
        select.classList.remove('open');
      }
    });
    this.parametersObservable = this.activatedRoute.params.subscribe(params => {
    this.getCatalogItems('All', '');
    this.getProjects();
    this.getProductTypes();
  })
  }

  orgName(data) {
    const strLen = data.length;
    let str = data;
    if (strLen > 18) {
      str = str.slice(0, 17) + '...';
    }
    return str;
  }

  getOrgName(data) {
    const strLen = data.length;
    let str = data;
    if (strLen > 25) {
      str = str.slice(0, 24) + '...';
    }
    return str;
  }


  getOrgNameMobile(data) {
    const strLen = data.length;
    let str = data;
    if (strLen > 22) {
      str = str.slice(0, 21) + '...';
    }
    return str;
  }

  getOrgNameTab(data){
    const strLen = data.length;
    let str = data;
    if (strLen > 13) {
      str = str.slice(0, 12) + '...';
    }
    return str;
  }

  getProjects() {
    this.researchService.getProjectsForPrincipal().subscribe((data):any => {
      let projectList:any = data;
      let filterProjects = projectList.filter((project)=> project.project_type != "IngressGateway")
      this.projects = filterProjects;
      this.showSpinner = false;
    }, error => {
      this.showSpinner = false;
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.route.navigate(['login']);
       }
    });
  }

  getCatalogItems(projectId, projectData) {
    this.showSpinner = true;
    this.disableAssign = true;
    this.checkBoxValue = [];
    this.searchKey = '';
    if(projectData){
      this.projectId = projectData._id;
      this.accountId = projectData.account_id;
      this.projectName = projectData.name;
      this.isStackCreated = projectData.isStackCreated;
    }
    this.projectId = projectId;
    this.productIds = [];
    this.getProductIds(this.checkBoxValue);
    this.selectedFilter = 'All';
    this.getAllCatalogItems(projectId);

  }

  getProductIds(orgData) {
    for (var i = 0; i < orgData.length; i++) {
      this.productIds.push(orgData[i]._id);
    }
  }

  getAllCatalogItems(projectId) {
    this.noDataTemplate = "Please contact your Administrator to add products to your project."
    this.showSpinner = true;
    this.availableProductsToShow = [];
    this.researchService.getAssignedProductsForProjects(projectId).subscribe((res:any) => {
      if(res.stackStatus == 'Creating'){
        let toasterMsg = 'Catalog items that are previously assigned are still being created. You cannot make any other changes at this time';
          this.toastr.info(``, toasterMsg, this.globals.tosterOverride);
      }
      if (res) {
        this.showSpinner = false;
        this.availableProductsToShow = res.docs;
        this.catalogCount = res.docs.length;
        this.infoMapping()
      }
    }, error => {
      this.showSpinner = false;
      console.error(error);
      let errBody = error.error;
      if(error && error.status === 401){
      this.toastr.error(errBody.err, '', this.globals.tosterOverride);
      this.route.navigate(['login']);
      }else if(error && error.status === 403){
        const err = error.error;
        this.toastr.error(err.error, 'Error', this.globals.tosterOverride);
        this.route.navigate(['pageNotFound']);
      }else{
        this.toastr.error(errBody.error, 'Error', this.globals.tosterOverride);
      }
    });
  }

  openKnowMore(detailsLink) {
    if (detailsLink) {
      window.open(detailsLink, '_blank');
    } else {
      return
      // window.open("#", '_blank');
    }
  }

  /* This function is used to get product icons and know more link*/
  infoMapping() {
    for (var i = 0; i < this.availableProductsToShow.length; i++) {
      if (this.availableProductsToShow[i].isCatalogItemCreated) {
        this.availableProductsToShow[i].selected = true;
        this.availableProductsToShow[i].isDisabled = true;

      } else {
        this.availableProductsToShow[i].selected = false;
        this.availableProductsToShow[i].isDisabled = false;
      }
      this.availableProductsToShow[i]['imageUrl'] = '/assets/images/aws_icon/aws.png';
      if (this.availableProductsToShow[i].tags.length > 0) {
        const serviceName = this.productUtil.getServiceFromTags(this.availableProductsToShow[i].tags);
        const getImgLinkObj = this.productUtil.getImageForProduct(serviceName);

        if (getImgLinkObj) {
          this.availableProductsToShow[i]['imageUrl'] = getImgLinkObj['url'];
        }
        const viewMoreDetails = this.availableProductsToShow[i].tags.filter(tag => {
          return tag["Key"] === "DetailsLink"
        })
        if (viewMoreDetails.length > 0) {
          const viewMoreLink = viewMoreDetails[0].Value;
          this.availableProductsToShow[i]["detailsLink"] = viewMoreLink
        }
      }
    }
  }


  getProductTypes() {
    this.researchService.listProductType().subscribe(res => {
      this.productTypes = res;
    }, error => {
      console.error(error);
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.route.navigate(['login']);
       }
    }
    )
  }

  onCheckboxChanged(event, value) {
    if (event.target.checked) {
      this.checkBoxValue.push(value);
      if (this.checkBoxValue.length > 0) {
        this.disableAssign = false;
      }
    }
    if (!event.target.checked) {
      let index = this.checkBoxValue.indexOf(value);

      if (index > -1) {

        this.checkBoxValue.splice(index, 1);
      }

      if (this.checkBoxValue.length == 0) {
        this.disableAssign = true;
      }
    }
    this.productIds = [];
    this.getProductIds(this.checkBoxValue);
  }

  onChangeFilter(filterType) {
    this.showSpinner = true;
    this.selectedFilter = filterType;
    this.noDataTemplate = "We could not find any products that matched your search."
    this.disableAssign = true;
    // this.checkBoxValue = [];
    this.researchService.searchPICatalogItems('', filterType, this.projectId).subscribe((res:any) => {
      this.showSpinner = false;
      this.availableProductsToShow = res;
      this.catalogCount = res.length;
      this.infoMapping();
    }, error => {
      console.error(error);
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.route.navigate(['login']);
       }
    }
    )
  }

  searchCatalogItems() {
    const searchKey = this.searchKey.trim() || '';
    if (searchKey.length >= 3 && this.searchExecKey !== searchKey)  {
      this.searchExecKey = searchKey;
      this.searchValid = true;
      this.researchService.searchPICatalogItems(this.searchKey, this.selectedFilter, this.projectId).subscribe((res:any) => {
        this.noDataTemplate = "We could not find any products that matched your search."
        this.availableProductsToShow = res;
        this.catalogCount = res.length;
        this.infoMapping();
      }, error => {
        console.error(error);
        if(error && error.status === 401){
           let errBody = error.error;
           this.toastr.error(errBody.err, '', this.globals.tosterOverride);
           this.route.navigate(['login']);
         }
        this.availableProductsToShow = [];
      }
      )
    } else if (searchKey.length === 0 && this.searchExecKey !== searchKey) {
      this.searchExecKey = searchKey;
      this.searchValid = true;
      this.onChangeFilter(this.selectedFilter)
    } else {
      this.searchValid = false;
    }
  }

  assignToProject(){
    this.apiService.getDialogBox().subscribe((data:any) => {
      if(data.status === 200){
    this.dialog.showCustomDialog({
      component: AssignCatalogComponent,
      providers: [{ provide: 'data', useValue: { assignData: this.checkBoxValue, assignType: 'Project' } }],
      isModal: true,
      styles: {
        "height": "445px",
        "width": "450px",
        "border": "1px solid #FFFFFF33",
        "border-radius": "10px",
        "background-color": "#07102A",
        "padding": "0px"
      },
      clickOutsideToClose: true,
      enterTransitionDuration: 400,
      leaveTransitionDuration: 400,
    })}}, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.route.navigate(['login']);
       }
    })
  }

  assignToProjectData(){
    this.showSpinner = true;
    let data = {}
    data['account_id'] = this.accountId;
    data['name'] = this.projectName;
    data['projectId'] = this.projectId;
    data['productIds'] = this.productIds;
    let toasterMsg = `The selected products are being assigned to your project. This can take some time to complete. You can monitor the progress in the events tab of the project.`
    this.toastr.info(``, toasterMsg, this.globals.tosterOverride);
    this.showSpinner = false;
    this.researchService.assignProductsToProject(data).subscribe(res => {
      if(res){
      let toasterStatus = res['status'];
      if(toasterStatus == 'info'){
        this.toastr.info(``, toasterMsg, this.globals.tosterOverride);
        this.isStackCreated = false;
      }
      this.getProjects();
      }
    }, error => {
      this.getProjects();
      this.showSpinner = false;
      console.error(error);
      let errBody = error.error;
      if(error && error.status === 401){
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.route.navigate(['login']);
       }else{
         this.toastr.error(errBody.error, 'Error in assigning products to project ', this.globals.tosterOverride);
      }
    });
  }

  updateProdToProj(){
    this.showSpinner = true;
    let data = {}
    data['account_id'] = this.accountId;
    data['name'] = this.projectName;
    data['projectId'] = this.projectId;
    data['productIds'] = this.productIds;
    let toasterMsg = `The selected products are being updated to your project. This can take some time to complete. You can monitor the progress in the events tab of the project.`
    this.toastr.info(``, toasterMsg, this.globals.tosterOverride);
    this.showSpinner = false;
    this.researchService.updateProductsToProject(data).subscribe((res:any) => {
      if(res){
      let resMsg = `Catalog items that are previously assigned are still being created. Please try after sometime`;
      if(res.stackStatus == 'Creating'){
        this.toastr.error(``, res['message'] ? res['message'] : resMsg , this.globals.tosterOverride);
      }
      //this.route.navigate(['/principal/catalog'],{ queryParams: { assignedProduct : 'success' }});
      this.getProjects();
      }
    }, error => {
      this.getProjects();
      this.showSpinner = false;
      console.error(error);
      let errBody = error.error;
      if(error && error.status === 401){
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.route.navigate(['login']);
       } else if(errBody.status == "info") {
        this.toastr.error(errBody.message, 'Error in updating products to project ', this.globals.tosterOverride);
       }else {
          this.toastr.error(errBody.error, 'Error in updating products to project ', this.globals.tosterOverride);
       }
    });
  }

  getFilterName(data){
    if (!data) {
      return data;
    }
    const getLen = data.length;
    if (getLen > 10) {
      data = data.slice(0, 10) + '...';
    }
    return data;
  }

}
