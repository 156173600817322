<div class="container">
  <!-- breadcrumb -->
  <nav aria-label="breadcrumb">
      <ol class="breadcrumb bc-custom">
        <li class="breadcrumb-item"><a class="bc-item" routerLink="/admin/budget-tracking"> Budgets </a></li>
        <li class="breadcrumb-item" aria-current="page"><a class="bc-item-active"> {{ orgName }} </a></li>
      </ol>
  </nav>
  <!-- <div class="back-layout" (click)="goBack()">
    <img class="back-icon" src="../../../assets/images/pagination-prev-active.png" />
    <h3 class="back-text text-white font-class">Back</h3>
  </div> -->
  <div class="project-info-layout">
    <div class="project-name-layout">
      <img class="project-image" alt="Budget For {{orgName}}"  src="{{ orgImg }}"/>
      <span class="project-name text-white font-class">{{ orgName }}</span>
    </div>
    <div class="project-budget-layout">
      <div class="project-budget-wrapper">
        <div class="project-budget-info">
            <span class="budget-title font-class">Total Organisation Budget</span>
            <span class="budget-value font-class">{{currencySymbol}} {{budgetAllotted}}</span>
        </div>
      </div>
      <div class="project-budget-wrapper">
        <div class="project-budget-info">
            <span class="budget-title font-class">Total Direct Cost</span>
            <span class="budget-value font-class">{{currencySymbol}} {{budgetConsumed}}</span>
        </div>
      </div>
      <div class="project-budget-wrapper">
        <div class="project-budget-info">
            <span class="budget-title font-class">Available Budget</span>
            <span class="budget-value font-class">{{currencySymbol}} {{budgetAvailable}}</span>
        </div>
      </div>
    </div>

    <!-- mobile responsive -->
    <div class="project-budget-layout-mobile">
      <div style="flex: 1">
          <div class="project-budget-info">
              <span class="budget-title font-class">Total Project Budget</span>
              <span class="budget-value font-class">{{currencySymbol}} {{budgetAllotted}}</span>
          </div>
          <div class="project-budget-info" style="margin-top: 10px;">
              <span class="budget-title font-class">Total Direct Cost</span>
              <span class="budget-value font-class">{{currencySymbol}} {{budgetConsumed}}</span>
          </div>
      </div>
      <div style="flex: 1">
          <div class="project-budget-info">
              <span class="budget-title font-class">Available Budget</span>
              <span class="budget-value font-class">{{currencySymbol}} {{budgetAvailable}}</span>
          </div>
      </div>
    </div>
  </div>

  <div>
    <div class="container budget-breakdown">
      <div class="row budget-breakdown-header">
        <div class="col align-self-center text-left font-class budget-header">
          <h3 >Project-wise Budget Breakdown</h3>
        </div>
        <!-- <div class="col-auto pr-0 text-right">
          <button class="export-btn font-class" (click)="exportCSV()">Export as CSV</button>
        </div> -->
      </div>
      <!-- Budget Breakdown Projects list -->
      <div class="project-table-layout">
          <div class="projects-header d-flex w-100">
            <div class="title">
              Project Name
            </div>
            <div class="title">
              Total Allocated Budget
            </div>
            <div class="title">
              Direct Cost
            </div>
            <div class="title">
              Available Budget
            </div>
            <div class="title">
              Consumed Budget Percentage
            </div>
          </div>
          <div class="projects" *ngIf="projects && projects.length > 0">
            <div class="project-row d-flex w-100 py-4 my-3" *ngFor="let project of projects" (click)="openProjectDetails(project)">
              <div class="project-column value-height">
                <span class="text-white">{{ project.projectName }}</span>
              </div>
              <!-- Total Project Budget -->
              <div class="project-column value-height">
                <span>{{ currencySymbol }} {{ getRoundOffBudget(project.totalBudgetAllocated) }}</span>
              </div>
              <!-- Consumed Budget -->
              <div class="project-column value-height">
                <span [ngStyle]="{'color':getColor(project)}">{{ currencySymbol }} {{ getRoundOffBudget(project.budgetConsumed) }}</span>
                <span [ngStyle]="{'color':getColor(project)}">( {{ project.budgetConsumedPercentage }} % )</span>
              </div>
              <!--  Available Budget -->
              <div class="project-column value-height">
                <span>{{ currencySymbol }} {{ getAvailableBudget(project) }}</span>
              </div>
              <!--  Percentage -->
              <div class="project-column px-4 value-height">
              <!--<span>{{  projectAvailableBudgetPercentage(project) }}</span>-->
                <div class="progress">
                  <div class="progress-bar" role="progressbar"
                   [style.width]="projectConsumedBudgetPercentage(project)+'%'"
                   [style.background-color]="getColor(project)"
                   aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="arrow" [style.margin-right]="97 - project.projectStopThresholdPercentage+'%'" >
                  <i class="fa fa-caret-down" aria-hidden="true"></i><div class="marker"></div>
                </div>
              </div>
            </div>
          </div>
      </div>

      <!-- mobile responsive -->
      <div class="project-cards-layout" *ngIf="projects && projects.length > 0">
        <div class="project-card" *ngFor="let project of projects" (click)="openProjectDetails(project)">
          <ul class="list-group">
            <div class="d-flex justify-content-between mb-2">
              <div class="project-card-title font-class">Project Name</div>
              <div class="project-card-project-name">{{ project.projectName }}</div>
            </div>
            <div class="d-flex justify-content-between mb-2">
                <div class="project-card-title project-card-line-height font-class">Total Project Budget</div>
                <div class="project-card-value">{{ currencySymbol }} {{ getRoundOffBudget(project.totalBudgetAllocated) }}</div>
            </div>
            <div class="d-flex justify-content-between mb-2">
                <div class="project-card-title project-card-line-height font-class">Direct Cost</div>
                <div class="project-card-value" [ngStyle]="{'color':getColor(project)}">{{ currencySymbol }} {{ getRoundOffBudget(project.budgetConsumed) }}
                  <span [ngStyle]="{'color':getColor(project)}">( {{ project.budgetConsumedPercentage }} % )</span>
                </div>
            </div>
            <div class="d-flex justify-content-between mb-2">
                <div class="project-card-title project-card-line-height font-class">Available Budget</div>
                <div class="project-card-value">{{ currencySymbol }} {{ getAvailableBudget(project) }}</div>
            </div>
            <div class="d-flex justify-content-between mb-2" style="align-items: center;">
              <div class="project-card-title project-card-line-height font-class">Consumed Budget Percentage</div>
              <div id="container">
                <div  id="bar"  class="progress">
                  <div class="progress-bar" role="progressbar"
                       [style.width]="projectConsumedBudgetPercentage(project)+'%'"
                       [style.background-color]="getColor(project)"
                       aria-valuemin="0" aria-valuemax="100">
                  </div>
                </div>
                <div id="marker" class="arrow" [style.margin-right]="97 - project.thresholdPercentage+'%'" >
                  <i class="fa fa-caret-down" aria-hidden="true"></i><div class="marker"></div>
                </div>
              </div>
            </div>
          </ul>
        </div>
      </div>
      <div class="projects" *ngIf="projects && projects.length === 0">
        <h3 style="text-align: center; margin: 40px">There are no projects in this organization.</h3>
      </div>

      <!-- Load more projects -->
      <!-- <div class="row load-more-btn-wrapper" *ngIf="projects && projects.length > 0">
        <button class="load-more-btn" >Load More</button>
      </div> -->
    </div>
  </div>
</div>
