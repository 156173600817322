<div class="signup-container">
  <div class="container successMsg" style="height: 390px;">
    <img *ngIf="checkUnSubscribeUser == false && checkUnassignedUser == false" class="success-icon" src="../../assets/images/success@2x.png" alt="Successfully Logged Out"/>
<!--    <h3 class="successDataMsg">-->
<!--      L successful.-->
<!--    </h3>-->
    <h4 *ngIf="checkUnSubscribeUser == false && checkUnassignedUser == false" class="linkMsg" style="margin-top: 15px !important">
      You have successfully logged out from Research Gateway.
    </h4>        
    <div *ngIf="checkUnSubscribeUser == true">
      <img class="success-icon" src="../../assets/images/icons/error@2x.png" alt="Subscription Expired"/>
      <h4 class="linkMsg">Your subscription renewal is overdue. To continue using Research Gateway, please renew your subscription. </h4>
    </div>  
    <div *ngIf="checkUnassignedUser == true">
      <img class="success-icon" src="../../assets/images/icons/error@2x.png" alt="Un Assigned User"/>
      <h4 class="linkMsg">You are not assigned to an organization. Please contact your administrator. </h4>
    </div> 
    <div class="row">
      <div>
        <button type="submit" class="btn btn-dark btn-block btn-flat submit-btn" style="width: 175px !important;" (click)="goToLoginPage()">Click here to login</button>
      </div>
    </div>
  </div> 
</div>

