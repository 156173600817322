import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../_services/dashboard.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {Globals} from "../globals";

@Component({
  selector: 'app-resend-verification',
  templateUrl: './resend-verification.component.html',
  styleUrls: ['./resend-verification.component.css'],
  providers: [Globals]
})
export class ResendVerificationComponent implements OnInit {
  
  username:any;
  data:any;
  showSuccessMsg = false;
  showErrorMsg = false;
  showSpinner = false;

  constructor(private apiService: DashboardService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private globals: Globals) { }

  ngOnInit() {
    this.username = this.route.snapshot.queryParams['user'];
    this.data = {
      'username' : this.username
    }
    this.sendVerification();
  }

  sendVerification() {
    this.showErrorMsg = false;
    this.showSpinner = true;
    this.apiService.resendVerification(this.data).subscribe(data => {
      this.showSpinner = false;
      this.showSuccessMsg = true;
    },
    error => {
      this.showSpinner = false;
      let errorbody = '';
      try {
        errorbody = error.error.message;
      } catch (e) {
        //errorbody = "Server Down";
      }
      this.toastr.error(errorbody, '', this.globals.tosterOverride);
      this.showSuccessMsg = false;
      this.showErrorMsg = true;
    })
  }

}
