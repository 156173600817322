import { Component, OnInit, Inject } from '@angular/core';
import * as $ from 'jquery';
import * as Terminal from '../../assets/js/terminal.js';
import * as io from '../../assets/js/socket.io.js'; 
import {ActivatedRoute, Router} from "@angular/router";
import {ResearchService} from "../_services/research.service";
import { ToastrService } from 'ngx-toastr';
import {Globals} from "../globals";

declare var instanceSSH: any;

@Component({
  selector: 'app-instance-rdp',
  templateUrl: './instance-rdp.component.html',
  styleUrls: ['./instance-rdp.component.css']
})

export class InstanceRdpComponent implements OnInit {

  constructor(private route: ActivatedRoute, private researchService: ResearchService,
    private toaster: ToastrService,
    private router: Router,
    private globals: Globals) {
    this.researchService.sendEvent();
   }
  usernameF = 'Administrator';
  passwordF = '';
  pemFileCheckboxF = 'pemFile';
  instanceDetails;
  pemfileInputF = '';
  instanceId = '';
  getInstanceIp = '';
  accountId;
  showPassword = false;
  ppId;
  productDetails;

  ngOnInit() {
    this.accountId = this.route.snapshot.params['accountId'];
    this.ppId = this.route.snapshot.params['ppId'];
    /*Check whether user has product access*/
    this.getProductDetails();
    const accountId = this.accountId;
    $('#ssh-win-box').hide();
    var $sshContainer = $("#instanceSSHPage .modal-body");
    $sshContainer.find(".passwordCheck").hide();
    $sshContainer.find(".chooseAuthenticationtype").on('change',function(e) {
      if ((e.target as HTMLInputElement).value === "Password") {
        $sshContainer.find(".pemFileCheck").hide();
        $sshContainer.find(".passwordCheck").show();
      } else {
        $sshContainer.find(".passwordCheck").hide();
        $sshContainer.find(".pemFileCheck").show();
      }
    });

    $("#instanceSSHPage #ssh-pemfileInput").on('change',function(e) {
      const fileName = (e.target as HTMLInputElement).files[0].name; 
      $(this).next().val(fileName);
      if (fileName) {
        let fName = fileName;
        const getLen = fName.length;
        if (getLen > 50) {
          fName = fName.slice(0, 50) + '...';
        }
        const getWWidth = $( window ).width();
        if ( getWWidth < 500 ) {
          if (getLen > 15) {
            fName = fName.slice(0, 15) + '...';
          }
        }
        $("#ssh-fileName").text(fName);
      } else {
        $("#ssh-fileName").text('No file selected');
      };
      $("#ssh-pemfileInput-lab").removeClass('file-lab-red');
      $("#ssh-pemfileInput-lab").addClass('file-lab');
    });

    var $sshContainer = $("#instanceSSHPage");
    var $pemFileCheckBox = $sshContainer.find("#ssh-pemFileCheckbox");
    var $errorArea = $sshContainer.find("#ssh-errorArea");
    var $terminalContainer = $("#ssh-terminalContainer");
    var $loadingContainer = $sshContainer.find("#ssh-loadingContainer");
    var $loginForm = $sshContainer.find("#ssh-login-form");
    var $terminateBtn = $sshContainer.find(".ssh-terminateBtn");
    var $sshActionLogIdInput = $sshContainer.find("#ssh-actionLogId");
    var $rdpPassword = $sshContainer.find("#ssh-password");
    var $rdpSubmit = $sshContainer.find("#rdpSubmit");
    var term;
    var passwordF;
    var instancePassword = false;
    var sshOpened = false;
    var sshSocket;
    $loadingContainer.hide();
    $sshContainer.find(".rdpSubmit").hide();

    function openSSh(reqBody) {
      $sshContainer.find('.sshSubmit').hide();
      $errorArea.hide();
      $terminalContainer.hide();
      $loginForm.hide();
      $loadingContainer.show();
      sshSocket = io.connect('/rdpShell', {
        // 'force new connection': true,
        reconnection: true
      });

      sshSocket.on('connect', function() {
        sshSocket.emit('open', reqBody);
      });
      sshSocket.on('conErr', function(data) {
        $errorArea.empty().append(data.message);
        $errorArea.show();
        $loginForm.show();
        $sshContainer.find('.passwordCheck').hide();
        $sshContainer.find('.sshSubmit').show();
        $sshContainer.find('.pemFileCheck').show();
        $sshContainer.find('.authType').show();
        $sshContainer.find('.rdpSubmit').hide();
        $terminalContainer.hide();
        $loadingContainer.hide();
        if (data.actionLogId) {
          $sshActionLogIdInput.val(data.actionLogId);
        }
      });
      sshSocket.on('opened', function(data) {
        $loadingContainer.hide();
        $loginForm.show();
        instancePassword = true;
        passwordF = data.decryptedPassword;
        if(passwordF.length > 0){
          $rdpPassword.val(passwordF).addClass('ng-valid');
          $sshContainer.find(".passwordCheck").show();
          $sshContainer.find('.pemFileCheck').hide();
          $sshContainer.find('.authType').hide();
          $sshContainer.find(".rdpSubmit").show();
        }else {
          $errorArea.empty().append('Password not available yet. Please wait at least 4 minutes after launching an instance before trying to retrieve the password.');
          $errorArea.show();
          $sshContainer.find('.passwordCheck').hide();
          $sshContainer.find('.sshSubmit').show();
          $sshContainer.find('.pemFileCheck').show();
          $sshContainer.find('.authType').show();
          $sshContainer.find('.rdpSubmit').hide();
          $loadingContainer.hide();
        }


      });
      sshSocket.on('out', function(data) {
        if (term) {
          term.write(data.res);
        }
      });
      sshSocket.on('disconnect', function() {
        $('#ssh-conn-header').show();
        $('#ssh-win-box').hide();
        destroyTerminal();
        //$sshContainer.find('.sshSubmit').show();
      });
      sshSocket.on('close', function() {
        $('#ssh-conn-header').show();
        $('#ssh-win-box').hide();
        destroyTerminal();
        // $sshContainer.find('.sshSubmit').show();
      });
      sshSocket.on('error', function(err) {
        if (err && err.reason) {
          $errorArea.empty().append(err.reason);
        } else {
          $errorArea.empty().append('Error occured while making a connection');
        }
        $errorArea.show();
        $loginForm.show();
        $sshContainer.find('.sshSubmit').show();
        $terminalContainer.hide();
        $loadingContainer.hide();
      });
    }

    function destroyTerminal() {
      if (term) {
        term.destroy();
      }
      $terminalContainer.hide();
      $loginForm.show();
      $errorArea.hide();
      $loadingContainer.hide();
    }

    $sshContainer.find('.sshSubmit').on('click', (e) => {

      var reqBody = {
        id : '' as any,
        sessionUser : '' as any,
        username : 'ubuntu' as any,
        password : '' as any,
        pemFileData : '' as any,
        accountId : '' as any,
        instanceId: '' as any,
        pemFileName: '' as any
      };

      /*Values set from external page*/
      reqBody.id = $sshContainer.find("#ssh-instanceId").val();
      reqBody.sessionUser = $sshContainer.find("#ssh-sessionUser").val();
      /*Values entered by user*/
      reqBody.username = $sshContainer.find("#ssh-username").val();
      reqBody.accountId = accountId;
      reqBody.instanceId = this.instanceId;
      if (!reqBody.id) {
        return;
      }
      if ($pemFileCheckBox.val() === 'Password') {
        // reqBody.password = $sshContainer.find("#ssh-password").val();

        if (!reqBody.username) {
          console.log('Please Enter Username');
          return;
        }
        if (!reqBody.password) {
          console.log('Please Enter Password or Choose a Pem file');
          return;
        } 
        openSSh(reqBody);
      } else {
        try {
          const BR = (<HTMLInputElement>document.getElementById('ssh-pemfileInput')).files[0];
          if (!reqBody.username) {
            $errorArea.empty().append('Please enter the username');
            return;
          }
          reqBody.pemFileName = BR.name;
          reqBody.pemFileData = '';
          const reader = new FileReader();
          reader.onload = function(e: any) {
            reqBody.pemFileData = e.target.result;
            openSSh(reqBody);
          };
          // Read in the image file as a data URL.
          reader.readAsText(BR);
          
        } catch (e) {
          $errorArea.empty().append('Please choose the Pem file');
        }
      }
      return false;
    });

    $terminateBtn.on('click',function() {
      if (sshOpened) {
        if (sshSocket) {
          sshSocket.disconnect();
        }
      }
    });

    $sshContainer.find('.rdpSubmit').on('click',()=>{
      if(this.showPassword === true){
        var userData = $sshContainer.find("#ssh-username").val();
        if(!userData){
          $errorArea.empty().append('Please enter the username');
          $errorArea.show();
          return;
        }
        this.researchService.downloadRdpFile(this.getInstanceIp, this.usernameF).subscribe((res:any) => {
          const rdpData = document.createElement("a");
          document.body.appendChild(rdpData);
          let blob = new Blob([res], {type: "data:text/csv;charset=utf-8,"}),
          url = window.URL.createObjectURL(blob);
          rdpData.href = url;
          rdpData.download = `${this.getInstanceIp}.rdp`;
          rdpData.click();
          $errorArea.hide();
          window.URL.revokeObjectURL(url);
        });
      }
    })

  }

  terminate(): void {
    window.close();
  }

  togglePassword(show){
    this.showPassword = show;
  }

  /* This is to check whether user has access to product and also get product IP address */
  getProductDetails() {
    try{
      this.researchService.getProvisionedProductDetails(this.ppId).subscribe((res:any) => {
        this.productDetails = res;
        const getInstanceDetails = this.productDetails.RecordOutputs;
        for (let i = 0; i < getInstanceDetails.length; i++) {
          if (getInstanceDetails[i].OutputKey === 'InstanceIPAddress') {
            this.getInstanceIp = getInstanceDetails[i].OutputValue;
          }
          if (getInstanceDetails[i].OutputKey === 'InstanceId') {
            this.instanceId = getInstanceDetails[i].OutputValue;
          }
        }
        /*If IP address is not available, then show toaster msg with page not found*/
        if (!this.getInstanceIp) {
          this.toaster.error(`${this.productDetails.ProvisionedProductName} - `+ "This action cannot be completed as some of the outputs are not yet available. Please retry the action after some time.", 'Error', this.globals.tosterOverride)
          this.router.navigate(['pageNotFound']);
        }
      }, error => {
        let errBody = error.error;
        if(error && error.status === 401){
           this.toaster.error(errBody.err, '', this.globals.tosterOverride);
           this.router.navigate(['login']);
         }else if (error && error.status === 403) {
          const err = errBody.error ? errBody.error : errBody;
          this.toaster.error(err, 'Error', this.globals.tosterOverride);
          this.router.navigate(['pageNotFound']);
        }else{
          this.toaster.error(errBody, 'Error', this.globals.tosterOverride);
          }
  });
    }catch(error){
      let errBody = error.error;
      this.toaster.error(errBody, 'Error', this.globals.tosterOverride);
    }
  }

}
