<div class='s3Upload' id="instanceSSHPage">
    <div class="dialog-header">
    <img class="cancel-icon" (click)="cancel()" src="../../assets/images/cancel@2x.png" alt="Cancel"/>
    <h2 class="modal-title title-header" id="myModalLabel" class="Header" *ngIf="!nestedFolder">
        Create {{ service | titlecase}} Folder 
    </h2>
    <h2 class="modal-title title-header" id="myModalLabel" class="Header" *ngIf="nestedFolder">
        Create {{ service | titlecase}} Folder in  {{nestedFolder}}
    </h2>
  </div>
  <div style="display: flex; flex-direction: row; margin-top: 10px;">
    <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
  </div>
  <app-loader class="spinner" *ngIf="showSpinner"></app-loader>
  <div>
    <fieldset *ngIf="!showSpinner">
      <form class="form">
          <div class="col-lg-12 col-md-12" style="margin-top: 25px">
              <label class="formLabel">Folder Name</label>
              <span class="after">*</span>
              <input class="form-control" aria-label="folderName" style="width: 315px;color: #ffffff;font-size: 12px" placeholder="Please enter the folder name" type="text" value="" [(ngModel)]="folderName"
                     #sName="ngModel" required name="folderName"  minlength="3"  (keyup)="validateForwardSlash($event.target.value);">
              <span class="length-cal" style="margin-bottom:-24px;"></span>
              <label class="formLabel" style="margin-top: 15px;color:#F283A4 !important" *ngIf="showErrorMsg">Folder names can't contain "/".</label>
            </div>
      </form>
</fieldset>
  </div>
  <div class="modal-footer" *ngIf="!showSpinner">
    <div class="marginForButtons l-footer" id="fileUplaod">
      <button  type="button" [disabled]="!folderName || showErrorMsg" class="btn cat-btn-update sshSubmit ssh-btn l-submit" (click)="createS3EmptyFolder()">
          Submit
      </button>
    </div>
  </div>
</div>
