import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DashboardService } from '../_services/dashboard.service';
import { MdlSnackbarService } from '@angular-mdl/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import {Globals} from "../globals";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
  providers: [Globals]
})

export class ResetPasswordComponent implements OnInit {

  public fromData:any={
    username:'',
    email:'',
    password:'',
    repass:''
  };
  public showSpinner = false;
  public showSuccessMsg = false;
  public showSuccessVerifiedMsg = false;
  public form: FormGroup;
  public patternPopOver: boolean =false;
  hasError: boolean=false;
  errorMsg: any='';
  confirmationCode: any;
  username: any;
  email: null;
  password: any;
  showPassword = false;
  showNewPass = false;
  showErrorMsg = false;
  userId = null;
  showSuccessResetVerifiedMsg = false;
  errorMessage = `We encountered an error during password reset. Please try again.`
  showLoginButton: boolean = false;
  constructor(
    private fb: FormBuilder,
    private apiService: DashboardService,
    private snackBar: MdlSnackbarService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private globals: Globals
  ) {  }
  @ViewChild('f') f: HTMLFormElement;

  ngOnInit() {
    this.confirmationCode = this.route.snapshot.queryParams['code'];
    this.username = this.route.snapshot.queryParams['user'];
    this.userId = this.route.snapshot.queryParams['id'];
  }

  checkIfPasswordFormShouldBeShown(){
    if (this.userId && !this.showErrorMsg){
      return !this.showSuccessResetVerifiedMsg;
    }else {
      return (!this.showSuccessMsg && this.username && this.confirmationCode && !this.showSuccessVerifiedMsg  && !this.showErrorMsg)
    }
  }

  checkIfResetFormShouldBeShown(){
    if (this.userId){
      return false;
    }else {
      return (!this.showSuccessMsg && !this.username && !this.confirmationCode && !this.showSuccessVerifiedMsg && !this.showErrorMsg)
    }
  }

  resetCognitoPassword() {
    let formData = {
      email: this.fromData.email
    }
    this.showSpinner = true;
    this.apiService.resetCognitoPassword(formData).subscribe(data => {
      this.showSpinner = false;
      this.showSuccessMsg = true;
      // this.toastr.success(`Verfication link mail is on it's way to ${formData.email}`, 'Thank You!')
      this.fromData.company = '';
      this.fromData.username = '';
      this.fromData.email = '';
      this.fromData.password = '';
      this.fromData.repass = '';
    },
      error => {
        this.showSpinner = false;
        this.fromData.company = '';
        this.fromData.username = '';
        this.fromData.email = '';
        let errorbody = '';
        try {
          errorbody = error.error.message;
        } catch (e) {
          //errorbody = "Server Down";
        }
        this.toastr.error(errorbody, '', this.globals.tosterOverride);
      });
   }

   policy(){
    return "<div class='pass-mess' ><div>Password should meet the following requirement:</div>\n" +
      "<div>Should have a minimum of 8 characters and a maximum of 16 characters.</div>\n" +
      "<div>Should have at least one of each of the following :</div>\n" +
      "<div>Lowercase characters.</div>\n" +
      "<div>Uppercase characters.</div>\n" +
      "<div>Numbers (0-9).</div>\n" +
      "<div>Special Characters</div>\n" +
      "<div> = + - ^ $ * . [ ] { } ( ) ? ! @ # % & / \ , > < ' : ; | _ ~ `</div></div>"
  }
  passwordEmpty(){
    this.fromData.repass='';
  }

  passwordMatch() {
    this.hasError=false;
    if(this.fromData.password == this.fromData.repass) {
      this.hasError=false;
    }
    else {
      this.hasError=true;
      this.errorMsg="Password does not match";
      this.f['controls']['repass'].setErrors({'incorrect': true});

    }
  }

  togglePassword(show){
    this.showPassword = show
  }

  toggleNewPassword(show){
    this.showNewPass = show
  }



  confirmResetCognitoPass(){
    let formData = {
      username:this.username,
      code: this.confirmationCode,
      password: this.fromData.password,
      userId: this.userId
    };
    this.showSpinner = true;
    if (this.userId) {
       this.apiService.forceResetPassword(formData).subscribe((data:any) =>{
         this.showSpinner = false;
         this.showSuccessMsg = false;
         this.showSuccessResetVerifiedMsg = true;
         this.fromData.password = '';
         this.fromData.repass = '';
         this.username = data.username;
         this.email = data.email;
         this.errorMessage = `We encountered an error during verification. Please try again.`;
       },
         error => {
           this.showSpinner = false;
           this.showErrorMsg = true;
           let errorbody = '';
           try {
             errorbody = error.error.message;
             let errorResponse = error.error;
             console.log(errorResponse);
             if(errorResponse.code === 'USER_CONFIRMED'){
               this.showLoginButton = true;
               this.errorMessage = 'This user is already verified. To login click on the link below';
             }else if(errorResponse.code === 'CodeMismatchException'){
               this.errorMessage = errorbody;
             }
           } catch (e) {
             //errorbody = "Server Down";
           }
           this.toastr.error(errorbody, '', this.globals.tosterOverride);
         });
    } else {
      this.apiService.verifyCognitoPassword(formData).subscribe(data => {
          this.showSpinner = false;
          this.showSuccessMsg = false;
          this.showSuccessVerifiedMsg = true;
          this.fromData.password = '';
          this.fromData.repass = '';
          this.userId = null;
        },
        error => {
          this.showSpinner = false;
          this.showErrorMsg = true;
          let errorbody = '';
          try {
            errorbody = error.error.message;
          } catch (e) {
            //errorbody = "Server Down";
          }
          this.toastr.error(errorbody, '', this.globals.tosterOverride);
        });
    }
  }

  login() {
    this.apiService.logoutForDashboard({}).subscribe(data => {
      sessionStorage.removeItem('currentUser');
      sessionStorage.removeItem('tenantid');
      sessionStorage.removeItem("5b1647bf469e0b0cf9dcacca");
      sessionStorage.removeItem("landlordUser");
      sessionStorage.removeItem("roles");
      sessionStorage.removeItem("strategy");
      sessionStorage.removeItem("Selected");

    }, error => {
      console.log(error);
    });
    this.router.navigate(['/login']);
  }

  loginRoute(){
    this.router.navigate(['/login']);
  }

  tryAgian(){
    this.showErrorMsg = false;
    this.showSuccessMsg = false;
    this.showSuccessVerifiedMsg = false;
    this.fromData.password = '';
    this.fromData.repass = '';
    this.username = false;
    if (this.userId) {
      this.router.navigate(['/reset-password'], {queryParams: {id: this.userId, code: this.confirmationCode}})
    }else {
      this.router.navigate(['/reset-password']);
    }
  }
}
