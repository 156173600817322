import { Component, OnInit, OnDestroy, NgZone, ViewChild } from '@angular/core';
import { Router, ActivatedRoute , NavigationStart, RoutesRecognized} from '@angular/router';
import { AuthGuardService } from './guard/auth-guard.service';
import { DashboardService } from './_services/dashboard.service';
import { ResearchService } from './_services/research.service';
import { PreviousRouteService } from './_services/url.service';
import * as config from './_services/config';
import {Globals} from "./globals";
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [ Globals ]
})

export class AppComponent implements OnInit, OnDestroy {
  username = sessionStorage.getItem('currentUser');
  version = '0.0';
  messages = [];
  noticeCount = 0;
  connection;
  userType:any;
  user = null;
  enabled = false;
  // b2cEnabled = false;
  showResearcherView = false;
  role = [];
  previousUrl: string = null;
  currentUrl: string = null;
  private parametersObservable: any;
  public allTenant:any=[];
  public selectedTenant:any='';
  showMenus = true;
  constructor(
    public authGuard: AuthGuardService,
    private apiService: DashboardService,
    private router: Router,
    private activatedRoute: ActivatedRoute, private researchService: ResearchService,
    private urlService: PreviousRouteService,
    private globals: Globals,private toastr: ToastrService, private zone:NgZone
  ) {
    this.router.events.subscribe(path=>{
      this.username = sessionStorage.getItem('currentUser');
      let role = sessionStorage.getItem('roles');
      this.role = JSON.parse(role);
      if(this.role && this.role.includes(0)){
        this.showResearcherView = true;
      }
      let level = sessionStorage.getItem('Level');
      if(role === "landlord" || level === '2'){
        this.userType = 'admin';
      }else if(level === '1'){
        this.userType = 'principal';
      }else if(level === '0'){
        this.userType = 'researcher';
      }else {
        this.userType = null;
      }
    })
    this.apiService.getApplicationMode().subscribe((data:any) => {
      if (data.enableB2CMode) {
        this.enabled = true;
      }
    }, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    });

    this.researchService.getEvent().subscribe(() => {
      this.showMenus = false;
    });
  }

  async ngOnInit() {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if(event.url && event.url == "/login"){
          this.allTenant=[];
        }
      }
      this.checkSubscriptionUser();
    });
    this.user = await this.apiService.showMenu();
      
   
    // this.b2cEnabled = await this.apiService.checkIfB2CMode();
    // this.router.events.subscribe((val) => {
    //   if(sessionStorage.getItem("5b1647bf469e0b0cf9dcacca") == 'landlord' && this.allTenant.length == 0){
    //     this.apiService.getAllTenant().subscribe(res=>{
    //       if(res && res.length >0){
    //         //this.allTenant = res.filter((tenant) => tenant.enabled === true);
    //         if(!sessionStorage.getItem("landlordUser")){
    //           var cu=this.allTenant.filter(function(user){
    //             return user.tenantID==="RL-Marketplace"
    //            })[0];
    //            if(cu && cu.tenantID){
    //            this.selectedTenant=cu.given_name;
    //            sessionStorage.setItem("landlordUser", cu.tenantID);
    //            }else{
    //              this.selectedTenant=res[0].given_name;
    //              sessionStorage.setItem("landlordUser", res[0].tenantID);
    //            }
    //         }else{
    //           var cu=this.allTenant.filter(function(user){
    //            return user.tenantID===sessionStorage.getItem("landlordUser");
    //            })[0];
    //            if(cu && cu.tenantID){
    //            this.selectedTenant=cu.given_name;
    //            sessionStorage.setItem("landlordUser", cu.tenantID);
    //            }else{
    //              this.selectedTenant=res[0].given_name;
    //              sessionStorage.setItem("landlordUser", res[0].tenantID);
    //            }
    //         }
    //
    //         this.apiService.selected_tenant({"user":{
    //             "custom:TenantID": sessionStorage.getItem("landlordUser"),
    //             "name": sessionStorage.getItem("tenantid"),
    //             "username": sessionStorage.getItem("currentUser")
    //          }}
    //         ).subscribe(data => {
    //
    //         });
    //       }
    //     });
    //   }
    // });
  // this.data.currentMessage.subscribe(message => this.message = message)
    this.parametersObservable = this.activatedRoute.params.subscribe(params => {
      this.username = sessionStorage.getItem('currentUser');
    })

    this.apiService.getVersion().subscribe((data:any) => {
          this.version = data.version
        }, error => {
          this.version = '0.0';
        })

        document.querySelector('.mdl-layout').addEventListener('click', function () {

    })
    
   

  }

  ngOnDestroy() {
    this.connection.unsubscribe();
  }

  logout() {
    this.apiService.logoutForDashboard({}).subscribe(data => {
      sessionStorage.removeItem('currentUser');
      sessionStorage.removeItem('tenantid');
      sessionStorage.removeItem("5b1647bf469e0b0cf9dcacca");
      sessionStorage.removeItem("landlordUser");
      sessionStorage.removeItem("currentUserEmail");
      sessionStorage.removeItem("Selected");
      sessionStorage.removeItem("org_id");
      sessionStorage.removeItem('subscriptionRenewalDate');      
      sessionStorage.removeItem('Level');       
      sessionStorage.removeItem('roles');
      sessionStorage.removeItem('displayName');            
      this.messages = [];
      this.noticeCount = 0;
      this.router.navigate(['/login']);
    });
  }

  openPDF(){
    window.open(config.helpPDFUrl);
  }

  checkSubscriptionUser() {
    let org_id = sessionStorage.getItem("org_id");
    if(this.userType == 'admin') {
      this.showMenus = true;        
    }else if(this.userType != 'admin' && (org_id == "" || org_id == null || org_id == undefined)) {
      this.showMenus = false;        
    } else {
      this.showMenus = true; 
    }
    let subscriptionDate = sessionStorage.getItem("subscriptionRenewalDate")
    if(subscriptionDate ! == '' || subscriptionDate !== null) {
      const date = new Date();       
      const todayDate = date.toISOString();  
      if(todayDate > subscriptionDate && (this.userType === 'principal' || this.userType === 'researcher')) {
        this.showMenus = false;           
      }
    } 
  }
  
  selectTenant(tenant, sele){
    this.selectedTenant=sele;
    sessionStorage.setItem("landlordUser", tenant.tenantID);
    this.apiService.selected_tenant({"user":{
        "custom:TenantID": tenant.tenantID,
        "name": tenant.name,
        "username": sessionStorage.getItem("currentUser")
  }}
    ).subscribe(data => {
      const url=this.activatedRoute.snapshot['_routerState'].url;
      let d = new Date();
      let t = d.getTime();
      if(url && url.indexOf('dashboard') != -1) {
        this.router.navigate(['dashboard/'+t]);
      } else if(url && url.indexOf('alert') != -1) {
        this.router.navigate(['alert/'+t]);
      } else if(url && url.indexOf('setting') != -1) {
        this.router.navigate(['setting/'+t]);
      } else if(url && url.indexOf('cmdb') != -1) {
        this.router.navigate(['cmdb'],{ queryParams: { time: t}});
      } else if(url && url.indexOf('serviceHealth') != -1) {
        this.router.navigate(['serviceHealth'],{ queryParams: { time: t}});
      } else if(url && url.indexOf('ITSM') != -1) {
        this.router.navigate(['/ITSM/'+t]);
      } else if(url && url.indexOf('web-app-details') != -1) {
        this.router.navigate(['dashboard/'+t]);
      } else if(url && url.indexOf('bsm-health-indicator') != -1) {
        this.router.navigate(['bsm-health-indicator'],{ queryParams: { time: t}});
      } else if(url && url.indexOf('planOutageDetails') != -1) {
        this.router.navigate(['planOutageDetails'],{ queryParams: { p: t}});
      } else if(url && url.indexOf('report') != -1) {
        this.router.navigate(['report'],{ queryParams: { p: t}});
      } else if(url && url.indexOf('runbook') != -1) {
        this.router.navigate(['runbook'], {queryParams: {p: t}});
      } else if(url && url.indexOf('monitoringTools') != -1){
        var urlArr = url.split('/');
        this.router.navigate([urlArr[1]+'/'+urlArr[2]+'/'+urlArr[3]+'/'+t]);
      } else if(url && url.indexOf('monitor') != -1){
        this.router.navigate(['monitor'], {queryParams:{p:t}});
      } else if(url && url.indexOf('outage-fault') != -1) {
        this.router.navigate(['outage-fault'],{ queryParams: { p: t}});
      }
    });
  }

  /** Clear notification messages and counts after click "See All" */
  goAlerts() {
    this.messages = [];
    this.noticeCount = 0;
    this.zone.run(() => this.router.navigate(['/alert']));
  }

  showMenu() {
    if ((this.user) && (this.user.level === null || this.user.isGroupsMismatched || !this.user.enabled)) {
      return false;
    }else {
      return true;
    }
  }

  navigateToResearch() {
    let role = sessionStorage.getItem('5b1647bf469e0b0cf9dcacca');
    let level = sessionStorage.getItem('Level');
    if(role === "landlord" || level == '2') {
      this.zone.run(() => this.router.navigate(['admin']));
    } else if(level == "1") {
      this.zone.run(() => this.router.navigate(['principal']));
    } else {
      this.zone.run(() => this.router.navigate(['researcher']));
    }
  }

  navigateToBudget() {
    if(this.userType === 'admin'){
      this.zone.run(() => this.router.navigate(['admin/budget-tracking']));
    }else if(this.userType === 'principal'){
      this.zone.run(() => this.router.navigate(['principal/budget-tracking']));
    }else if(this.userType === 'researcher'){
      this.zone.run(() => this.router.navigate(['researcherData/budget-projectData'],{ queryParams: { researcher: this.username}}));
    }
  }

  navigateToKeypair() {
    if (this.userType === 'researcher' || (this.role && this.role.includes(0))) {
      console.log("Going to keypair")
      this.zone.run(() => this.router.navigate(['/keypair']));
    }
  }

  navigateToStudies() {
    if (this.userType === 'researcher' || (this.role && this.role.includes(0))) {
      this.zone.run(() => this.router.navigate(['researcher/studies']));
    }
  }

  navigateToUser() {
    this.zone.run(() => this.router.navigate(['users']));
  }

  navigateToAuditTrail() {
    this.zone.run(() =>  this.router.navigate(['/audittrail']));
  }

  navigateToCatalog() {
    if (this.userType === 'admin') {
      this.zone.run(() => this.router.navigate(['/admin/catalog']));
    }else if (this.userType === 'principal') {
      this.zone.run(() => this.router.navigate(['principal/catalog']));
    }
  }
  
  navigateToBillingAccount() {
    this.zone.run(() => this.router.navigate(['BillingAccount']));
  }

  openSideMenu(){
    const selectorId = document.querySelector('.mdl-layout');
    console.log(selectorId)
    // selectorId.MaterialLayout.toggleDrawer();
  }

}
