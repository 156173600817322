import { Component, OnInit, Inject } from '@angular/core';
import { MdlDialogReference } from '@angular-mdl/core';
import { ToastrService } from 'ngx-toastr';
import { ResearchService} from "../../_services/research.service";
import { DataService } from '../../_services/data.service';
import {Globals} from "../../globals";
import { Router } from '@angular/router';
@Component({
  selector: 'app-assign-organisation',
  templateUrl: './assign-organisation.component.html',
  styleUrls: ['./assign-organisation.component.css'],
  providers: [Globals]
})

export class AssignOrganisationComponent implements OnInit {

  userInfo;
  accountInfo;
  organisationData = [];
  selectedOrganisation;
  showSpinner = false;

  constructor(private dialogRef: MdlDialogReference, @Inject('data') public data: any,
    private researchService: ResearchService, private router : Router,
    private toastr: ToastrService, private globals: Globals,private dataService: DataService){ }

  ngOnInit() {
    if(this.data.hasOwnProperty('userInfo')){
      this.userInfo = this.data.userInfo;
    }
    if(this.data.hasOwnProperty('accountInfo')){
      this.accountInfo = this.data.accountInfo;
      if(this.accountInfo && this.accountInfo.name && this.accountInfo.name.length > 60){
        this.accountInfo.name = this.accountInfo.name.slice(0, 60) + '...';
      }
    }
    this.getDropdownData();
  }

  cancel() {
    this.dialogRef.hide();
  }

  getDropdownData() {
    this.showSpinner = true;
    this.researchService.getOrganisationDropdown().subscribe((res:any) => {
      this.showSpinner = false;
      this.organisationData = res.filterList;
    }, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    })
  }

  submit() {
    const data = {
      organization: this.selectedOrganisation.key
    }
    this.showSpinner = true;
    if(this.userInfo) {
      this.researchService.assignOrg(this.userInfo.user_id, data).subscribe((res:any) => {
        this.showSpinner = false;
        if(res.status === 'success'){
          this.toastr.success(res.message);
          this.dataService.updatePage.next(true);
          this.dialogRef.hide();
        }else {
          this.toastr.success(res.message);
          this.dialogRef.hide();
        }
      }, error => {
        this.showSpinner = false;
        this.dialogRef.hide();
        if(error && error.status === 401){
          let errBody = error.error;
           this.toastr.error(errBody.err, '', this.globals.tosterOverride);
           this.router.navigate(['login']);
         }else{
        const errorBody = error.error;
        this.toastr.error(errorBody.err, '', this.globals.tosterOverride);
         }
      })
    }else if(this.accountInfo) {
      this.researchService.assignOrgToAccount(this.accountInfo._id, data).subscribe((res:any) => {
        this.showSpinner = false;
        if(res.status === 'success'){
          this.toastr.success(res.message);
          this.dataService.updatePage.next(true);
          this.dialogRef.hide();
        }else {
          this.toastr.success(res.message);
          this.dialogRef.hide();
        }
      }, error => {
        this.showSpinner = false;
        this.dialogRef.hide();
        if(error && error.status === 401){
          let errBody = error.error;
           this.toastr.error(errBody.err, '', this.globals.tosterOverride);
           this.router.navigate(['login']);
         }else{
        const errorBody = error.error;
        this.toastr.error(errorBody.err);}
      })
    }
  }

}
