import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from "@angular/router";
import { MdlDialogReference } from '@angular-mdl/core';
import { ResearchService } from "../../_services/research.service";
import {Globals} from "../../globals";
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
@Component({
  selector: 'app-show-project-details',
  templateUrl: './show-project-details.component.html',
  styleUrls: ['./show-project-details.component.css'],
  providers: [Globals]
})
export class ShowProjectDetailsComponent implements OnInit {

  projectName:any = '';
  accountName:any = '';
  accountNumber:any = '';
  region:any = '';
  settingsData:any = '';
  projects:any = [];
  projectOwner;
  projectStatus;
  researcherList;
  createdOn;
  settingName;
  showSpinner = true;

  constructor( 
    @Inject('data') public data: any, 
    private route: ActivatedRoute, private router: Router,
    private dialogRef: MdlDialogReference,
    private researchService: ResearchService,
    private globals: Globals,private toastr: ToastrService
    ) { }

  ngOnInit() {
    if(this.data.hasOwnProperty('settingsData')){
      this.settingsData = this.data.settingsData;
      this.settingName = this.settingsData.name;
      this.getProjects(this.settingsData._id, this.settingsData.orgId[0]);
      this.accountName = this.settingsData.name;
      this.region = this.settingsData.region;
      this.accountNumber = this.settingsData.accountNumber;
    }
  }

  cancel() {
    this.dialogRef.hide();
  }
  
  getProjects(accountId, orgId) {
    this.researchService.getPrincipalProjectsUsingIdForAdmin(accountId, orgId).subscribe(data => {
      this.projects = data;
      this.showSpinner = false;
    }, error => {
      this.showSpinner = false;
      this.dialogRef.hide();
      let errBody = error.error;
      const err = error._body;
      if(error && error.status === 401){
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }else{
        this.toastr.error(err, 'Project Error', this.globals.tosterOverride);
       }
    });
  }
  convertDateToString(date) {
    if(date !== 0) {
      var sysCreatedOn =  moment(date).format('DD/MM/YYYY, h:mm:ss ')
      return sysCreatedOn;
    } else {
      return '-'
    }
  }
}
