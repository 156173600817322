import { Component, EventEmitter, OnInit, Output, Inject } from '@angular/core';
import { MdlDialogReference } from "@angular-mdl/core";
import { MdlDialogService } from '@angular-mdl/core';
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { Globals } from "../../globals";
import { ResearchService } from "../../_services/research.service";
import { DashboardService } from '../../_services/dashboard.service';
import { OrganizationService } from '../../_services/organization.service';
import { ProjectActionComponent } from '../../principal/project-action/project-action.component';

@Component({
  selector: 'app-investigator',
  templateUrl: './investigator.component.html',
  styleUrls: ['./investigator.component.css', '../../product-details/product-details.component.css'],
  providers: [Globals]
})
export class InvestigatorComponent implements OnInit {
  projectName;
  submitBTN = true;
  updateBTN = true;
  showSpinner = false;
  budgetAvailable = 0;
  budgetConsumed = 0;
  accountId = '';
  accountIdList = [];
  researcherExistingIdList = [];
  researcherValidateList = [];
  researcherIdList = [];
  projectDescription;
  researchIds = [];
  researchName:any = [];
  assignedIds = [];
  selectedId = '';
  isValid: boolean = false;
  uniquename = [];
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  projectStatus;
  ProjectData;
  accountIdArr = [];
  fAccountIdList = [];
  loadForm;
  accountIdname = [];
  viewAccountIdname: string;
  thresholdPercentage = 80;
  budgetConsumedPercentage = 0;
  userType;
  accountListFound;
  orgNameErrDesc = 'Enter a unique project name made of alphanumeric, hyphen and underscore characters';
  defaultClass = 'helpText';
  projectstatus;

  constructor(public dialogRef: MdlDialogReference,
    public router: Router,
    private serviceApi: ResearchService,
    private orgService: OrganizationService,
    private dialog: MdlDialogService,
    private dashboardService: DashboardService,
    private toastr: ToastrService,
    private globals: Globals,

    @Inject('data') public data: any
  ) { }

  ngOnInit() {
    this.loadForm = true;
    this.dashboardService.checkIfPI()
    let userRole = sessionStorage.getItem('5b1647bf469e0b0cf9dcacca');
    let userLevel = sessionStorage.getItem('Level');
    if (userRole === "landlord") {
      this.userType = 'admin'
    } else if (userLevel === "1") {
      this.userType = 'principal'
    } else {
      this.userType = 'researcher'
    }

    // Toggle product actions button for mobile view
    $(document).off("click").on("click", ".action-button", function (e) {
      e.preventDefault();
      $(this).parent().find("ul").slideToggle();
    });
    this.getPrincipalProjects();
    this.getResearchers();
    this.dropdownList = [
      { "id": 1, "itemName": "India" },
      { "id": 2, "itemName": "Singapore" },
      { "id": 3, "itemName": "Australia" },
      { "id": 4, "itemName": "Canada" },
      { "id": 5, "itemName": "South Korea" },
      { "id": 6, "itemName": "Germany" },
      { "id": 7, "itemName": "France" },
      { "id": 8, "itemName": "Russia" },
      { "id": 9, "itemName": "Italy" },
      { "id": 10, "itemName": "Sweden" }
    ];
    this.selectedItems = [
      { "id": 2, "itemName": "Singapore" },
      { "id": 3, "itemName": "Australia" },
      { "id": 4, "itemName": "Canada" },
      { "id": 5, "itemName": "South Korea" }
    ];
    this.dropdownSettings = {
      singleSelection: false,
      text: "Select Countries",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "myclass custom-class"
    };
    this.projectName = this.data.name;
    this.projectDescription = this.data.description;
    this.accountId = this.data.account_id;
    if (this.data.researchers) {
      let rname = [];
      for ( const name of this.data.researcherDetails){
        if (name) {
          rname.push(name.fullName);
        }
      }
      this.researchName = rname.toString().replace(/,/g, ", ");
      this.researchIds = this.data.researchers.toString().replace(/,/g, ", ");
      this.researcherExistingIdList = this.data.researchers;
      const getRE = this.researcherExistingIdList.map((e) => e);
      this.assignedIds = getRE;
      this.getValidateResearchers(this.data.account_id);
    }
    if (this.data.thresholdPercentage) {
      this.thresholdPercentage = this.data.thresholdPercentage;
    }
    if(this.data.budgetConsumedPercentage){
      this.budgetConsumedPercentage = this.data.budgetConsumedPercentage;
    }
    this.budgetAvailable = this.data.budget;
    if (this.projectName) {
      this.isValid = true;
    }
    if (this.data.projectStatus) {
      this.projectStatus = this.data.projectStatus;
    }

  }

  getPrincipalProjects() {
    this.loadForm = true;
    this.serviceApi.getPrincipalProjects('1', '9999').subscribe((res:any) => {
      this.loadForm = false;
      this.ProjectData = res.docs;
      for (let i = 0; i < this.ProjectData.length; i++) {
        this.accountIdArr.push(this.ProjectData[i].account_id);
      }
      this.viewAccountIdname = this.accountIdname.toString();
      this.getAccounts();
    }, error=>{
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
      this.loadForm = false;
      this.accountListFound = false;
      this.fAccountIdList = [];
    });
  }

  getThresholdCrossRes(){
    if(this.thresholdPercentage <= this.budgetConsumedPercentage)  {
      return {color : 'red'};
    }else{
      return {color :  '#73E2CF'};
    }
  }

  getResearchers() {
    this.loadForm = true;
    try{
      this.serviceApi.getResearcherList().subscribe(
        (res:any) => {
          this.researcherIdList = res.map(obj => {
            const fObj = {
              'value': obj.username,
              'viewValue': `${obj.fullName} (${obj.email})`
            };
            return fObj;
          });
        }, error =>{
          this.loadForm = false;
          this.accountListFound = false;
          this.fAccountIdList = [];
          if(error && error.status === 401){
            let errBody= error.error;
             this.toastr.error(errBody.err, '', this.globals.tosterOverride);
             this.router.navigate(['login']);
           }
        });
    }catch(error){
    }
    
  }

  getValidateResearchers(accountId) {
    this.serviceApi.getValidateResearcherList(accountId).subscribe(
      (res:any) => {
        this.researcherValidateList = res;
      }, error => {
        if(error && error.status === 401){
          let errBody = error.error;
           this.toastr.error(errBody.err, '', this.globals.tosterOverride);
           this.router.navigate(['login']);
         }
  });
  }

  getAccounts() {
    this.loadForm = true;
    this.dashboardService.getSettingsDetail('1', '999', 'created_on', 'asc', this.userType,'project').subscribe(
      (res:any) => {
        this.accountIdList = res.settings.map(obj => {
          const fObj = {
            'value': obj._id,
            'viewValue': obj.name
          };
          return fObj;
        });
        for (let i = 0; i < this.accountIdList.length; i++) {
          let data = this.accountIdArr.find(obj => obj == this.accountIdList[i].value)
          if (!data) {
            this.fAccountIdList.push(this.accountIdList[i]);
            this.accountListFound = true;
          }
        }
        if (this.data.account_id) {
          const acc_id = this.data.account_id;
          const found = this.accountIdList.find(element => element.value == acc_id);
          if (found) {
            this.accountId = found.viewValue;
          }

        }
        this.loadForm = false;
        if(this.fAccountIdList.length == 0){
          this.accountListFound = false;
        }
    }, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
     });
  }

  onItemSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
  }
  OnItemDeSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  onDeSelectAll(items: any) {
    console.log(items);
  }


  cancel(): void {
    this.dialogRef.hide();
  }

  selectResearcherIds(researcher, event) {
    if (event.target.checked) {
      this.researchIds.push(researcher.value)
    } else {
      this.researchIds = this.researchIds.filter((item) => {
        item !== researcher.value
      })
    }
  }

  EditResearcherId(researcher, event) {
    if (event.target.checked) {
      this.assignedIds.push(researcher.value);
    } else {
      const index = this.assignedIds.indexOf(researcher.value);
      if (index > -1) {
        this.assignedIds.splice(index, 1);
      }
    }
  }

  insertProject() {
    this.submitBTN = false;
    this.showSpinner = true;
    const formObj = {
      'name': this.projectName,
      'description': this.projectDescription,
      'budget': this.budgetAvailable,
      'account_id': this.accountId.toString(),
      'img_src': '../assets/images/rocket.png',
      'researchers': this.researchIds,
      'projectStatus': 'Active'
    };
    this.serviceApi.postProject(formObj).subscribe(
      (res:any) => {
        this.showSpinner = false;
        if (res.status === 'Success') {
          this.dialogRef.hide();
          this.submitBTN = true;
          this.router.navigate(['principal/success/Saved successfully']);
        } else if (res.status === 'Failed') {
          this.submitBTN = true;
          this.toastr.error(res.message);
        } else {
          this.router.navigate(['principal/error/Error in updating']);
        }
      },
      error => {
        this.showSpinner = false;
        console.error("Error", error);
        let errBody = error.error;
        if(error && error.status === 401){
          this.toastr.error(errBody.err, '', this.globals.tosterOverride);
          this.router.navigate(['login']);
        }else{
        this.toastr.error(errBody.message, 'Error', this.globals.tosterOverride);
      }
        this.dialogRef.hide();
        this.router.navigate(['principal/error/Error in updating']);
      });
  }

  insertResearchers() {
    this.updateBTN = false;
    if (this.assignedIds.length === 0) {
      return;
    }
    const formObj = {
      'projectId': this.data._id,
      'researcherNames': this.assignedIds,
    };
    this.serviceApi.updateResearchers(formObj).subscribe(
      (res:any) => {
        const urlPname = this.assignedIds[0];
        const urlPnamearray = urlPname.split('/');
        if (res.code === 1001) {
          this.dialogRef.hide();
          this.updateBTN = true;
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.toastr.success(`Updated successfully`, '', this.globals.tosterOverride);
          this.router.navigate(['principal/success/Saved successfully ' + urlPnamearray[1]]);
        } else {
          this.toastr.error( 'Error in updating','Error', this.globals.tosterOverride);
          this.router.navigate(['principal/error/Error in updating ' + urlPnamearray[1]]);
        };
      },error =>{
        if(error && error.status === 401){
          let errBody = error.error;
           this.toastr.error(errBody.err, '', this.globals.tosterOverride);
           this.router.navigate(['login']);
         }
      });

  }

  validateResearcher(researcher) {
    const validateResearcher = this.researcherExistingIdList.some((e) => e === researcher);
    return validateResearcher;
  }

  validateProvisionedResearcher(researcher) {
    const validateResearcher = this.researcherValidateList.find((e) => e.researcherName === researcher);
    let status = false;
    if (validateResearcher) {
      status = validateResearcher.provisioned;
    };
    return status;
  }

  projectActions(action, projectStatus): void {
    this.dialogRef.hide();
    const projData = {
      "projectId": this.data._id,
      "projectName": this.data.name,
      "action": action,
      "projectStatus": projectStatus,
      "accountId": this.data.account_id
    }
    this.dialog.showCustomDialog({
      component: ProjectActionComponent,
      providers: [{ provide: 'data', useValue: projData }],
      isModal: true,
      styles: {
        "height": "auto",
        "width": "40%",
        "border": "1px solid #FFFFFF33",
        "border-radius": "10px",
        "background-color": "#07102A",
        "padding": "0px"
      },
      clickOutsideToClose: true,
      enterTransitionDuration: 400,
      leaveTransitionDuration: 400,
    })
  }

  checkProjName(){
    const dataLeng = this.projectName.length;
    const regexPattern = /^[-_ a-zA-Z0-9]+$/
    const getRegValue = this.projectName.match(regexPattern);
    if(dataLeng == 0){
      this.defaultClass = 'helpText';
      this.orgNameErrDesc = 'Enter a unique project name made of alphanumeric, hyphen and underscore characters';
    }else if(getRegValue === null){
      this.defaultClass = 'errorText';
      this.orgNameErrDesc = 'Only alphanumeric characters, hyphens and underscores are allowed';
    }else if(dataLeng < 3){
        this.defaultClass = 'errorText';
        this.orgNameErrDesc = "Project name must be at least 3 characters";
    }else this.orgNameErrDesc = '';

  }

  getHelpTextClass(value: string) {
    switch (value) {
      case "helpText": 
          return "helpText";
      case "errorText": 
          return "errorText";
    }
  }

}
