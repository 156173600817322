import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { ResearchService} from "../../_services/research.service";
import { ActivatedRoute } from '@angular/router';
import {Globals} from "../../globals";

@Component({
  selector: 'app-principal',
  templateUrl: './budget-details.component.html',
  styleUrls: ['./budget-tracking.component.css', './budget-details.component.css', '../../app.component.css'],
  providers: [Globals]
})
export class AdminBudgetDetailsComponent implements OnInit {
  organisationId;
  budgetAllotted: any = 0;
  budgetConsumed: any = 0;
  budgetAvailable: any = 0;
  getAvailableBud: any = 0;

  currencySymbol: string = '$';
  projects;
  consumedBudgetTextColor = {
    passed: '#73E2CF',
    threshold: 'red',
    pauseThreshold: '#F5AF19'
  }
  organisations;
  orgName;
  orgImg;

  private sub: any;

  loader: boolean = true;
  constructor(private toastr: ToastrService,
              private researchService: ResearchService,
              private route: ActivatedRoute,
              private location: Location,
              private router: Router,
              private globals: Globals
  ) {}

  ngOnInit() {
    this.organisationId = this.route.snapshot.params['id'];
    this.getOrganizationProjects();
    this.getOrganization();
  }


  goBack() {
    this.location.back();
  }

  getMarkerWidth(project){
    let markerWidth = 80
    if('projectStopThresholdPercentage' in project && 'budgetConsumedPercentage' in project){
      markerWidth = Math.abs(project.projectStopThresholdPercentage - project.budgetConsumedPercentage);
    }
    return markerWidth+'%';
  }

  getOrganizationProjects() {
    this.researchService.getOrganizationProjects(this.organisationId).subscribe(data => {
      this.projects = data;
      // this.loader = false;
    }, error => {
      // this.loader = false;
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    });
  }

  getAvailableBudget(project){
    this.getAvailableBud = parseFloat(project.totalBudgetAllocated) - parseFloat(project.budgetConsumed);
    return parseFloat(this.getAvailableBud).toFixed(2);
  }

  exportCSV(){
    this.toastr.error("Coming Soon!")
  }

  getColor(project){
    if(project.budgetConsumedPercentage >= project.projectStopThresholdPercentage){
      return this.consumedBudgetTextColor.threshold;
    }if(project.budgetConsumedPercentage >= project.projectPauseThresholdPercentage){
      return this.consumedBudgetTextColor.pauseThreshold;
    }else{
      return this.consumedBudgetTextColor.passed;
    }
  }

  projectConsumedBudgetPercentage(project){
    if(project.budgetConsumed){
      let consumed = project.budgetConsumed;
      return ((consumed/project.totalBudgetAllocated)*100).toFixed(0)
    }else {
      return 0;
    }
 }

  openProjectDetails(project) {
    this.router.navigate(['/admin/project/'+ project.projectId+'/details'], { queryParams: { id: this.organisationId, projectName: project.projectName}});
  }

  getRoundOffBudget(data){
    return parseFloat(data).toFixed(2)
  }

  getOrganization() {
    this.loader = true;
    this.researchService.getOrganizations().subscribe(data => {
      this.loader = false;
      this.organisations = data;
      this.organisations.forEach((item => {
        if(item.organizationID === this.organisationId){
        this.budgetAllotted += item.totalBudgetAllocated;
        this.budgetConsumed += item.budgetConsumed;
        this.orgName = item.organizationName;
        this.orgImg = item.imageSource;
        }
      }))
      this.budgetAvailable = parseFloat(this.budgetAllotted) - parseFloat(this.budgetConsumed);
      this.budgetAvailable = parseFloat(this.budgetAvailable).toFixed(2);
      this.budgetAllotted = parseFloat(this.budgetAllotted).toFixed(2);
      this.budgetConsumed = parseFloat(this.budgetConsumed).toFixed(2);
    }, error => {
      this.loader = false;
      if(error && error.status === 403){
        const err = error.error;
        this.toastr.error(err.error, 'Error', this.globals.tosterOverride);
        this.router.navigate(['pageNotFound']);
      }else if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    });
  }

}
