import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'properName'
})
export class ProperNamePipe implements PipeTransform {

  transform(value: string): string {
    // Split the string into words
    const words = value.split(/(?=[A-Z])/);

    // Capitalize the first letter of each word
    const capitalizedWords = words.map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    // Join the words back into a single string
    return capitalizedWords.join(' ');
  }

}
