<!-- <div class='s3Upload' id="instanceSSHPage">
    <div class="dialog-header">
    <img class="cancel-icon" (click)="cancel()" src="../../assets/images/cancel@2x.png" alt="../../assets/images/cancel@2x.png"/>
    <h2 class="modal-title title-header" id="myModalLabel" class="Header" *ngIf="!filePrefix">
        Upload to {{provisionedProductName}} 
    </h2>
    <h2 class="modal-title title-header" id="myModalLabel" class="Header" *ngIf="filePrefix">
        Upload to {{provisionedProductName}} - {{filePrefix}}
    </h2>
  </div>
  <div style="display: flex; flex-direction: row; margin-top: 10px;">
    <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
  </div>
  <div class="modal-body ssh-model-body p-body">
    <fieldset>
      <form class="form" id="ssh-login-form" [formGroup]="form" (ngSubmit)="onImageUpload()" style="margin-bottom: 10px;">
          <div class="col-lg-12 col-md-12 credentialSection pemFileCheck sshStyle" id="fileUploadSection">
          <div class="smart-forms" id="formData">
            <span class="control-label">Choose a file</span>
            <span class="control-label-red">*</span>
            <label name="field" class="file form-control file-lab-red c-file-label control-label-data" id="ssh-pemfileInput-lab">
              <input required id="ssh-pemfileInput" type="file" multiple class="gui-file c-file-input" (change)="onImagePicked($event)">
              <input required id="ssh-pemfileInput" type="file" webkitdirectory directory class="gui-file c-file-input" (change)="onFileChange($event.target.files)">
              <span id="ssh-fileName" class="c-file-span-fn">No file selected</span>
              <span class="c-file-span">Choose a file</span>
            </label>
          </div>
          <div class="progress form-group" *ngIf="progress > 0 && !fileUpload" style="height: 2rem;margin-top: 30px;">
              <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width.%]="progress"> {{progress}} %
              </div>
          </div>
        </div>
        <div *ngIf="fileUpload">
            <h4 style="margin-top: 25px;padding-left: 15px;color: #fff;margin-bottom: 65px;"> File upload complete</h4>
        </div>
      </form>
</fieldset>
  </div>
  <div class="modal-footer" style="margin-top: 15px; margin-bottom: 20px">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 importErrorMsg text-right error-padding">
      <span class="ssh-errorArea" id="ssh-errorArea"></span>
    </div>
    <div class="marginForButtons l-footer" id="fileUplaod">
      <button *ngIf="!fileUpload"  type="button" [disabled]="!imageObj" class="btn cat-btn-update sshSubmit ssh-btn l-submit" (click)="onImageUpload()">
          Submit
      </button>
    </div>
  </div>
</div> -->
<div class="container" id="scrollId">
  <nav aria-label="breadcrumb">
   <ol class="breadcrumb bc-custom">
     <li class="breadcrumb-item">
       <a class="bc-item" *ngIf="userLevel == '0'" routerLink="/researcher"> My Projects </a>
       <a class="bc-item" *ngIf="userLevel == '1'" routerLink="/principal"> My Projects </a>
     </li>
     <li class="breadcrumb-item" aria-current="page">
       <a class="bc-item" *ngIf="userLevel == '0'" (click)="navigateToProject()"> {{ projectName }} </a>
       <a class="bc-item" *ngIf="userLevel == '1'" routerLink = "/project-details/{{projectId}}" [queryParams]="{showTab: 'myProducts'}"> {{ projectName }} </a>
     </li>
     <li class="breadcrumb-item " aria-current="page" style="cursor: pointer;" (click)="routeBack()"><a class="bc-item"> {{provisionedProductName}} </a></li>
     <li *ngIf="filePrefix" class="breadcrumb-item " aria-current="page" style="cursor: pointer;" (click)="routeBackToFolder()"><a class="bc-item"> {{filePrefix}} </a></li>
     <li *ngIf="!filePrefix" class="breadcrumb-item " aria-current="page" style="cursor: pointer;" (click)="routeBackToRoot()"><a class="bc-item"> Explore Details </a></li>
     <li class="breadcrumb-item active bc-item-active" aria-current="page">Upload</li>
   </ol>
 </nav>
 <!-- details -->
 <div style="display: flex; justify-content: space-between; align-items: center; margin: 20px 0px;">
   <div style="display: flex;">
     <img style="max-width: 75px; max-height: 75px;border-radius: 10px;" src="{{imageUrl}}" alt="S3 Bucket"/>
     <div style="display: flex; flex-direction: column; margin: 10px 15px;">
       <h4 class="text-white font-class">{{ provisionedProductName }}</h4>
         <span style="color: #7596FF; font-size: 16px; margin-bottom: 10px;" class="font-class">Add the files you want to upload to {{service === 's3'? 'S3' : 'Blob'}}. 
           <span *ngIf="isShared === true" class="vl">
               <img class="share-icon" src="../../assets/images/share-white.png" alt="Shared"/>
               <span class="share-icon-text"> Shared </span>
           </span>
       </span>
     </div>
   </div>
   <div class="actionItems" style="display: flex">
     <div class="web-status" *ngIf="showAdd && showAddFile" style="height: 45px; display: flex; border: 1px solid #465374; background: #85D4FF;border-radius: 23px; align-items: center; padding: 10px;">
       <div class="text-wrapper">
         <div class="centered">
           <input
             type="file"
             name="file"
             id="file"
             (change)="onFileChange($event.target.files, 'File')"
             multiple
             aria-label="addFilesMobileView"
           />
           <label for="file" style="cursor: pointer;font: normal normal bold 14px/12px Nunito Sans;margin-bottom: 0px !important;"><span class="textLink">Add files</span> </label>
         </div>
       </div>
     </div>

     <div class="col-lg-12 col-md-12 credentialSection pemFileCheck sshStyle" id="fileUploadSection" *ngIf="showAdd && showAddFolder">
       <div class="smart-forms" id="formData">
         <label name="field" style="cursor: pointer;background: #85D4FF !important;height: 45px;border-radius: 23px;"lass="file form-control file-lab-red c-file-label control-label-data" id="ssh-pemfileInput-lab">
           <input required id="ssh-pemfileInput" type="file" webkitdirectory directory class="gui-file c-file-input" (change)="onFileChange($event.target.files, 'Folder');">
           <span class="c-file-span">Add Folder</span>
         </label>
       </div>
     </div>
   </div>
   <div class="web-status" *ngIf="!showAdd && fileUploadComplete" (click)="uploadComplete()" style="height: 45px; width:175px; display: flex; border: 1px solid #465374; background: #85D4FF; border-radius: 23px; align-items: center; padding: 20px;">
       <div class="text-wrapper">
         <div class="centered">
           <label for="file" style="margin-top: 5px;cursor: pointer; font: normal normal bold 14px/12px Nunito Sans"><span class="textLink-close">Done</span> </label>
         </div>
       </div>
     </div>
 </div>
 <!-- mobile actions bar -->
 <div class="actionMenu">
   <div class="web-status" *ngIf="showAdd && showAddFile" style="height: 45px; display: flex !important; border: 1px solid #465374; background: #85D4FF;border-radius: 23px; align-items: center; padding: 10px;">
     <div class="text-wrapper">
       <div class="centered">
         <input
           type="file"
           name="file"
           id="file"
           (change)="onFileChange($event.target.files, 'File')"
           multiple
           aria-label="addFilesMobileView"
         />
         <label for="file" style="cursor: pointer;font: normal normal bold 14px/12px Nunito Sans"><span class="textLink">Add files</span> </label>
       </div>
     </div>
   </div>

   <div class="col-lg-12 col-md-12 credentialSection pemFileCheck sshStyle" id="fileUploadSection" *ngIf="showAdd && showAddFolder">
     <div class="smart-forms" id="formData">
       <label name="field" style="cursor: pointer;background: #85D4FF !important;height: 45px;border-radius: 23px;"lass="file form-control file-lab-red c-file-label control-label-data" id="ssh-pemfileInput-lab">
         <input required id="ssh-pemfileInput" type="file" webkitdirectory directory class="gui-file c-file-input" (change)="onFileChange($event.target.files, 'Folder');">
         <span class="c-file-span">Add Folder</span>
       </label>
     </div>
   </div>

   <div class="web-status" *ngIf="!showAdd && fileUploadComplete" (click)="uploadComplete()" style="height: 45px; width:175px ; display: flex !important; border: 1px solid #465374; background: #85D4FF; border-radius: 23px; align-items: center; padding: 20px;">
     <div class="text-wrapper">
       <div class="centered">
         <label for="file" style="margin-top: 5px;cursor: pointer; font: normal normal bold 14px/12px Nunito Sans"><span class="textLink-close">Done</span> </label>
       </div>
     </div>
   </div>

</div>
 
 
 <div class="divider"></div>

<div *ngIf="files.length > 0">
 <h4 style="margin-top: 15px;color: #ffffff; font:  normal normal 400 16px/14px Nunito Sans;"> Files ( {{fileCount}} )</h4>
</div>

 <div class='s3Explore'>
     <div class="table-responsive md-layout lg-layout" style="margin-bottom: 25px;">
     <table class = "gfg" style="width: 100%" *ngIf="files.length > 0">
       <thead style="background-color: #15244B">
         <tr>
        
           <th class="tableHeader" style="width: 30%;">FILE NAME</th>
           <th class="tableHeader" style="width: 10%;">SIZE</th>
           <th class="tableHeader" style="width: 20%;">TYPE</th>
           <th *ngIf="!fileUpload && showActions" class="tableHeader" style="width: 10%;">ACTION</th>
           <th class="tableHeader" style="width: 20%;">UPLOAD STATUS</th>
         </tr>
       </thead>
       <tbody style="background-color: #07102A">
         <tr *ngFor="let data of files; let ind = index;" style="background-color:inherit">
           <td class="tableData" style="width: 30%;font-size: 14px" *ngIf="uploadType ==='File'">{{data.name}}</td>
           <td class="tableData" style="width: 30%;font-size: 14px" *ngIf="uploadType ==='Folder'">{{data.webkitRelativePath}}</td>
           <td class="tableData" style="width: 10%;font-size: 14px">{{getFileSize(data.size)}}</td>
           <td *ngIf="data.type" class="tableData" style="width: 20%;font-size: 14px">{{data.type}}</td>
           <td *ngIf="!data.type" class="tableData" style="width: 20%;font-size: 14px"> - </td>
           <td *ngIf="!fileUpload && showActions" class="tableData" style="width: 10%;font-size: 14px;cursor: pointer;" title="Remove" (click)="deleteSelected(ind)">
             <i class="fa fa-trash-o" style="font-size:18px; color: #ffffff"></i>
           </td>
           <td  *ngIf="data.status && data.status === 'In Progress'" class="tableData" style="width: 20%;">
             <div class="progress form-group" *ngIf="data.value > 0 && data.status === 'In Progress'" style="height: 2rem;margin-top: 0px;margin-bottom: 0px;">
               <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width.%]="data.value"> {{data.value}} %
               </div>
           </div>
           <div *ngIf="data.value == 0 && data.status === 'In Progress'" style="font-size: 14px;font-family: Nunito Sans">
             {{data.status}}
           </div>
         </td>
           <td class="tableData" style="width: 20%;" *ngIf="((data.status == 'Success') || (data.status =='Failed'))">
               <div *ngIf="data.status === 'Success'" style="color: #36C643;font-family: Nunito Sans; font-size: 14px;font-weight: bold">
                   {{data.status}}  <i class="fa fa-check-circle" aria-hidden="true" style="color: #36C643; margin-left: 5px;font-size: 14px;"></i>
               </div>
               <div *ngIf="data.status === 'Failed'" style="color: #F283A4;font-family: Nunito Sans; font-size: 14px;font-weight: bold">
                   {{data.status}} - {{data.statusMsg}}
               </div>
           </td>
           <td class="tableData" style="width: 20%;font-size: 14px;font-family: Nunito Sans" *ngIf="data.status == 'Pending'">
               {{data.status}}
           </td>
           <td class="tableData" style="color: #F283A4; width: 20%;font-size: 14px;font-family: Nunito Sans;font-weight: bold" *ngIf="data.status.includes('The size of this file is larger than the maximum')">
             {{data.status}}
         </td>
       </tr>
       </tbody>
     </table>
     <div *ngIf="files.length === 0 " class="empty-my-product-layout">
       <div>
         <h3 class="no-data-found text-white font-class text-center"> No files or folders </h3>
         <h4 class="no-data-found-subtext text-white font-class text-center">You have not chosen any files or folders to upload.</h4>
       </div>
     </div>
     <div class="marginForButtons l-footer" id="fileUplaod">
         <button *ngIf="!fileUpload && files.length > 0 && ! fileUploadStatus && showActions"  type="button" class="btn cat-btn-update sshSubmit ssh-btn cancel-submit" (click)="cancelUpload()">
             Cancel
         </button>
       <button *ngIf="!fileUpload && files.length > 0 && ! fileUploadStatus && showActions && uploadType==='File'"  type="button" [disabled]="!imageObj || fileSizeError" class="btn cat-btn-update sshSubmit ssh-btn l-submit" (click)="uploadFiles()">
           Upload
       </button>
       <button *ngIf="!fileUpload && files.length > 0 && ! fileUploadStatus && showActions && uploadType==='Folder'"  type="button" [disabled]="!imageObj || fileSizeError" class="btn cat-btn-update sshSubmit ssh-btn l-submit" (click)="uploadFolders()">
         Upload
     </button>
     </div>
   </div>
   <!-- Responsive view -->
   <div class="sm-layout xs-layout" *ngIf="files.length > 0">
         <div class="project-cards-layout">
           <div class="project-card" *ngFor="let data of files; let ind = index;">
             <ul class="list-group">
               <div class="d-flex justify-content-between mb-2">
                 <div class="project-card-title project-card-line-height font-class">File Name</div>
                 <div class="project-card-project-name" style="text-decoration: underline;" *ngIf="uploadType ==='File'">{{data.name}}</div>
                 <div class="project-card-project-name" style="text-decoration: underline;" *ngIf="uploadType ==='Folder'">{{data.webkitRelativePath}}</div>
               </div>
               <div class="d-flex justify-content-between mb-2">
                   <div class="project-card-title project-card-line-height font-class">Size</div>
                   <div class="project-card-project-name">{{getFileSize(data.size)}}</div>
               </div>
               <div class="d-flex justify-content-between mb-2">
                 <div class="project-card-title project-card-line-height font-class">Type</div>
                 <div *ngIf="data.type" class="project-card-project-name">{{data.type}}</div>
                 <div *ngIf="!data.type" class="project-card-project-name"> - </div>
             </div>
               <div class="d-flex justify-content-between mb-2" *ngIf="!fileUpload && showActions">
                   <div class="project-card-title project-card-line-height font-class">Action</div>
                   <div class="project-card-project-name" (click)="deleteSelected(ind)">
                     <i class="fa fa-trash-o" style="font-size:18px; color: #ffffff"></i>
                   </div>
               </div>
               <div class="d-flex justify-content-between mb-2">
                   <div class="project-card-title project-card-line-height font-class">Upload Status</div>
                   <div *ngIf="data.status && data.status === 'In Progress'">
                       <div class="progress form-group" *ngIf="data.value > 0 && data.status === 'In Progress'" style="height: 2rem;margin-top: 0px;margin-bottom: 0px;">
                           <div class="progress-bar progress-bar-striped bg-success project-card-project-name" role="progressbar" [style.width.%]="data.value"> {{data.value}} %
                           </div>
                       </div>
                       <div *ngIf="data.value == 0 && data.status === 'In Progress'" class="project-card-project-name" style="font-size: 14px;font-family: Nunito Sans">
                         {{data.status}}
                       </div>
                   </div>
                   <div *ngIf="((data.status == 'Success') || (data.status =='Failed'))">
                       <div *ngIf="data.status === 'Success'" class="project-card-project-name" style="color: #36C643;font-family: Nunito Sans; font-size: 14px;font-weight: bold">
                           {{data.status}}  <i class="fa fa-check-circle" aria-hidden="true" style="color: #36C643; margin-left: 5px;font-size: 14px;"></i>
                       </div>
                       <div *ngIf="data.status === 'Failed'" class="project-card-project-name" style="color: #e3111b;font-family: Nunito Sans; font-size: 14px;font-weight: bold">
                          {{data.status}} - {{data.statusMsg}}
                       </div>
                   </div>
                   <div *ngIf="data.status == 'Pending'" class="project-card-project-name">
                       {{data.status}}
                   </div>
                   <div *ngIf="data.status.includes('The size of this file is larger than the maximum')" class="project-card-project-name" style="color: #e3111b;font-family: Nunito Sans; font-size: 14px;font-weight: bold">
                     {{data.status}}
                 </div>
               </div>
             </ul>
           </div>
           <div class="marginForButtons l-footer" id="fileUplaod" style="margin-bottom: 20px;">
               <button *ngIf="!fileUpload && files.length > 0 && ! fileUploadStatus && showActions"  type="button" class="btn cat-btn-update sshSubmit ssh-btn cancel-submit" (click)="cancelUpload()">
                   Cancel
               </button>
             <button *ngIf="!fileUpload && files.length > 0 && ! fileUploadStatus && showActions && uploadType==='File'"  type="button" [disabled]="!imageObj || fileSizeError" class="btn cat-btn-update sshSubmit ssh-btn l-submit" (click)="uploadFiles()">
                 Upload
             </button>
             <button *ngIf="!fileUpload && files.length > 0 && ! fileUploadStatus && showActions && uploadType==='Folder'"  type="button" [disabled]="!imageObj || fileSizeError" class="btn cat-btn-update sshSubmit ssh-btn l-submit" (click)="uploadFolders()">
               Upload
           </button>
           </div>
         </div>
     </div>
 
 </div>
 </div>