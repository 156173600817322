import { Component, OnInit, Inject } from '@angular/core';
import { MdlDialogReference } from '@angular-mdl/core';
import { MdlDialogService } from '@angular-mdl/core';
import { ResearchService } from "../../_services/research.service";
import { SuccessMessageComponent } from '../../dialog/success-message/success-message.component';
import { ErrorMessageComponent } from '../error-message/error-message.component';
import {Globals} from "../../globals";
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-link-study',
  templateUrl: './link-study.component.html',
  styleUrls: ['./link-study.component.css'],
  providers: [Globals],
})
export class LinkStudyComponent implements OnInit {

  studyId;
  products = [];
  selectedValue;
  showSpinner: boolean = false;
  updateStudy;
  resourceDetail;

  constructor(@Inject('data') public data: any, public dialogRef: MdlDialogReference,
      private dialog: MdlDialogService, private router:Router, private researchService: ResearchService, private globals: Globals,private toastr: ToastrService) { }

  ngOnInit() {
    if(this.data.hasOwnProperty('studyId')){
      this.studyId = this.data.studyId;
      this.updateStudy = this.data.updateStudy;
      this.resourceDetail = this.data.resourceDetail;
    }
    this.getStudyResources();
  }

  cancel(): void {
    this.dialogRef.hide();
  }

  getStudyResources() {
    this.showSpinner = true;
    const reqObject = {
      'arn': this.resourceDetail && 'ARN' in this.resourceDetail ? this.resourceDetail.ARN : ''
    };
    this.researchService.getStudyResources(reqObject).subscribe((res:any) => {
      this.showSpinner = false;
      this.products = res;
    }, error =>{
      this.showSpinner = false;
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    })
  }

  linkStudy() {
    this.showSpinner = true;
    const selectedProduct = this.selectedValue;
    const data = {
      studyId: this.studyId,
      ppId: selectedProduct.provisionedProductId
    }
    this.researchService.linkStudyResource(data).subscribe((res:any) => {
      this.showSpinner = false;
      if(res.status === "success"){
        this.dialogRef.hide();
        this.showSuccessMessage();
      }else {
        this.dialogRef.hide();
        this.showErrorMessage();
      }
    }, error => {
      this.showSpinner = false;
      this.dialogRef.hide();
      if(error && error.status === 401){
        let errBody= error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }else {
        this.showErrorMessage();
       }
    })
  }

  showSuccessMessage() {
    this.dialog.showCustomDialog({
      component: SuccessMessageComponent,
      providers: [{ provide: 'data', useValue: { action: 'studyLink'} }],
      isModal: true,
      styles: {
        "height":"450px",
        "width":"400px",
        "border": "1px solid #FFFFFF33",
        "border-radius": "10px",
        "background-color": "#07102A",
        "padding": "0px"
      },
      clickOutsideToClose: true,
      enterTransitionDuration: 400,
      leaveTransitionDuration: 400,
    })
  }

  showErrorMessage() {
    this.dialog.showCustomDialog({
      component: ErrorMessageComponent,
      providers: [{ provide: 'data', useValue: { action: 'studyLink'} }],
      isModal: true,
      styles: {
        "height":"450px",
        "width":"400px",
        "border": "1px solid #FFFFFF33",
        "border-radius": "10px",
        "background-color": "#07102A",
        "padding": "0px"
      },
      clickOutsideToClose: true,
      enterTransitionDuration: 400,
      leaveTransitionDuration: 400,
    })
  }

  trimProductsName(data) {
    const strLen = data.length;
    let str = data;
    if (strLen > 60) {
      str = str.slice(0, 60) + '...';
    }
    return str;
  }

}
