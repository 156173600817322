<form #f="ngForm" (ngSubmit)="addSettings();f.valid">
  <div class="form-group" style="overflow-y: none !important; max-height: 650px !important;">
    <div class="dialog-header">
      <img (click)="cancel()" alt="Cancel" class="cancel-icon"
           src="../../assets/images/cancel@2x.png"/>
      <h2 *ngIf="isEdit" class="Header" style="padding :10px 15px">Repair Provider Settings</h2>
      <h2 *ngIf="!isEdit" class="Header" style="padding :10px 15px">Add Provider Settings </h2>
    </div>
    <div style="display: flex; flex-direction: row; margin-top: 10px;">
      <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
      <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
      <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
      <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
    </div>
    <div class="col-lg-12 col-md-12" style="margin-top: 15px">
      <label class="formLabel">Account Name</label>
      <span class="after">*</span>
      <input #sName="ngModel" [(ngModel)]="serviceName" aria-label="accountName" [disabled]="isEdit" class="form-control form-control-account-name" minlength="3" name="serviceName"
             placeholder="Please enter your account name" required pattern="^[-_a-zA-Z0-9]+$" style="width: 315px;color: #ffffff;margin-bottom: 10px;" type="text"
             value="Service Name" (keypress)="checkAccountName()" (keyup)="checkAccountName()" (keydown.space)="$event.preventDefault()">
      <div  [ngClass]="defaultClass">{{accuntNameErrDesc}}</div>
    </div>
    <div *ngIf="authType == 'Password'">

      <div class="col-lg-12 col-md-12">

        <label>Host</label>
        <span class="after">*</span>
        <input [(ngModel)]="host" class="form-control" maxlength="50" name="host" placeholder="Host" required
               type="text">
        <span class="length-cal" style="margin-bottom:-24px;"><small>{{50 - host.length}}
          Characters left </small></span>
      </div>
      <div class="col-lg-12 col-md-12">

        <label>User Name</label>
        <span class="after">*</span>
        <input [(ngModel)]="userName" class="form-control" maxlength="50" name="userName" placeholder="UserName"
               required
               type="text">
        <span class="length-cal" style="margin-bottom:-24px;"><small>{{50 - userName.length}}
          Characters left </small></span>
      </div>


      <div class="col-lg-12 col-md-12">

        <label>Password</label>
        <span class="after">*</span>
        <input [(ngModel)]="password" class="form-control" maxlength="20" name="password" placeholder="Password"
               required type="password">
        <span class="length-cal" style="margin-bottom:-24px;"><small>{{20 - password.length}}
          Characters left </small></span>
      </div>
    </div>

    <div *ngIf="authType == 'IAM'">
      <div class="col-lg-12 col-md-12">
        <div class="row">
          <div class="col-lg-6 col-md-6" style="margin-top: 10px">
            <label class="formLabel">Access Key</label>
            <span class="after">*</span>
            <div class="">
              <input [(ngModel)]="accessKey" class="form-control" minlength="16" maxlength="128" name="accessKey"
                     placeholder="Please enter your access key" aria-label="accessKey"
                     required style="color: #ffffff" type="password" (keydown.space)="$event.preventDefault()">
              <span class="length-cal" style="margin-bottom:-24px;"><small>{{128 - accessKey.length}}
                Characters left </small></span>
            </div>
          </div>
          <div class="col-lg-6 col-md-6" style="margin-top: 10px">
            <label class="formLabel">Secret Key</label>
            <span class="after">*</span>
            <div class="">
              <input [(ngModel)]="secretKey" class="form-control"  minlength="40" maxlength="128" name="secretKey"
                     placeholder="Please enter your secret key" aria-label="secretKey"
                     required style="color: #ffffff" type="password" (keydown.space)="$event.preventDefault()">
              <span class="length-cal" style="margin-bottom:-24px;"><small>{{128 - secretKey.length}}
                Characters left </small></span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12 col-md-12" style="margin-bottom: 15px">
        <div class="row">
          <div class="col-lg-6 col-md-6" style="margin-top: 20px">
            <label class="formLabel">Region</label>
            <span class="after">*</span>
            <div class="">
              <input [(ngModel)]="region" [disabled]="isEdit" class="form-control" maxlength="14" name="region"
                     placeholder="Please enter your region" required pattern="(eu|sa|ca|ap|us)-(east|west|north|south|central)(east)*-[0-9]{1}"
                     style="color: #ffffff" aria-label="region" type="text" (keydown.space)="$event.preventDefault()">
              <span class="length-cal" style="margin-bottom:-24px;"><small>{{14 - region.length}} Characters left </small></span>
            </div>
          </div>
          <div class="col-lg-6 col-md-6" style="margin-top: 20px">
            <label class="formLabel">Account Number</label>
            <span class="after">*</span>
            <div class="">
              <input [(ngModel)]="accountNumber" [disabled]="isEdit" class="form-control" minlength="12" maxlength="12" name="accountNumber"
                     placeholder="Please enter your account number" pattern="^\d{12}$" aria-label="accountNumber"
                     required style="color: #ffffff" type="text" (keydown.space)="$event.preventDefault()">
              <span class="length-cal" style="margin-bottom:-24px;"><small>{{ 12 - accountNumber.length || "0"}}
                Characters left </small></span>
            </div>
          </div>
    </div>
      </div>
      <span style="padding: 15px;display: flex;" class="helpText">
        Please ensure that the IAM user whose credentials you enter above has the IAMFullAccess access policy attached
      </span>


    </div>

    <div *ngIf="authType == 'Token'">
      <div class="col-lg-12 col-md-12">

        <label>Token</label>
        <span class="after">*</span>
        <input [(ngModel)]="token" class="form-control" name="token" placeholder="Token" required
               type="password">
        <span class="length-cal" style="margin-bottom:-24px;"><small>{{50 - token.length}}
          Characters left </small></span>
      </div>
    </div>

    <div *ngIf="authType == 'oAuth'">
      <div class="col-lg-12 col-md-12">
        <label>Client ID</label>
        <span class="after">*</span>
        <input [(ngModel)]="clientId" class="form-control" maxlength="50" name="clientId" placeholder="Client ID"
               required type="password">
        <span class="length-cal" style="margin-bottom:-24px;"><small>{{50 - clientId.length}}
          Characters left </small></span>
      </div>
      <div class="col-lg-12 col-md-12">
        <label>Client Secret</label>
        <span class="after">*</span>
        <input [(ngModel)]="clientSecret" class="form-control" maxlength="50" name="clientSecret"
               placeholder="Client Secret"
               required type="password">
        <span class="length-cal" style="margin-bottom:-24px;"><small>{{50 - clientSecret.length}}
          Characters left </small></span>
      </div>
      <div class="col-lg-12 col-md-12">
        <label>Grant Type</label>
        <span class="after">*</span>
        <input [(ngModel)]="grantType" class="form-control" maxlength="50" name="grantType" placeholder="Grant Type"
               required
               type="text">
        <span class="length-cal" style="margin-bottom:-24px;"><small>{{50 - grantType.length}} Characters left </small></span>
      </div>
      <div class="col-lg-12 col-md-12">
        <label>Resource</label>
        <span class="after">*</span>
        <input [(ngModel)]="resource" class="form-control" maxlength="50" name="resource" placeholder="Resource"
               required
               type="text">
        <span class="length-cal" style="margin-bottom:-24px;"><small>{{50 - resource.length}}
          Characters left </small></span>
      </div>
      <div class="col-lg-12 col-md-12">
        <label>Subscription ID</label>
        <span class="after">*</span>
        <input [(ngModel)]="subscriptionId" class="form-control" maxlength="50" name="subscriptionId"
               placeholder="Subscription ID"
               required type="text">
        <span class="length-cal" style="margin-bottom:-24px;"><small>{{50 - subscriptionId.length}}
          Characters left </small></span>
      </div>
      <div class="col-lg-12 col-md-12">
        <label>Tenant ID</label>
        <span class="after">*</span>
        <input [(ngModel)]="tenantId" class="form-control" maxlength="50" name="tenantId" placeholder="Tenant ID"
               required
               type="text">
        <span class="length-cal" style="margin-bottom:-24px;"><small>{{50 - tenantId.length}}
          Characters left </small></span>
      </div>
    </div>

    <div *ngIf="authType == 'Google Service Account'">
      <div class="col-lg-12 col-md-12">
        <label>Project ID</label>
        <span class="after">*</span>
        <input [(ngModel)]="projectId" class="form-control" maxlength="50" name="projectId" placeholder="Project ID"
               required type="text">
        <span class="length-cal" style="margin-bottom:-24px;"><small>{{50 - projectId.length}} Characters left </small></span>
      </div>
      <div class="col-lg-12 col-md-12">
        <label>Private key</label>
        <span class="after">*</span>
        <input [(ngModel)]="privateKey" class="form-control" maxlength="3000" name="privateKey"
               placeholder="Private key"
               required type="password">
        <span class="length-cal" style="margin-bottom:-24px;"><small>{{3000 - privateKey.length}}
          Characters left </small></span>
      </div>
      <div class="col-lg-12 col-md-12">
        <label>Client Email</label>
        <span class="after">*</span>
        <input [(ngModel)]="clientEmail" class="form-control" maxlength="200" name="clientEmail"
               placeholder="Client Email" required
               type="email">
        <span class="length-cal" style="margin-bottom:-24px;"><small>{{200 - clientEmail.length}}
          Characters left </small></span>
      </div>
      <div class="col-lg-12 col-md-12">
        <label>Client Certificate URL</label>
        <span class="after">*</span>
        <input [(ngModel)]="client_cert_url" class="form-control" maxlength="200" name="resource"
               placeholder="Client Certificate URL" required
               type="url">
        <span class="length-cal" style="margin-bottom:-24px;"><small>{{200 - client_cert_url.length}}
          Characters left </small></span>
      </div>
    </div>
    <div class="col-lg-12 col-md-12 " style="margin-top: 20px;text-align: center; margin-bottom: 15px;">
      <button  [disabled]="!f.valid" class="btn btn-primary sub-btn" *ngIf="isVerified"
        data-dismiss="modal"  type="submit">
        Repair <i *ngIf="showSpinner" class="fa fa-refresh fa-spin"></i>
      </button>
      <button  [disabled]="!f.valid" class="btn btn-primary sub-btn" *ngIf="!isVerified"
              data-dismiss="modal"
              (click)="verifyAccount($event)">
        Verify <i *ngIf="showSpinner" class="fa fa-refresh fa-spin"></i>
      </button>
    </div>
  </div>
</form>

