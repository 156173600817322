import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, RoutesRecognized, Event as NavigationEvent, NavigationStart } from "@angular/router";
import { DashboardService } from '../_services/dashboard.service';
import { ResearchService } from "../_services/research.service";
import { OrganizationService } from '../_services/organization.service';
import { ToastrService } from 'ngx-toastr';
import { Globals } from "../globals";
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { PreviousRouteService } from '../_services/url.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-add-account',
  templateUrl: './add-account.component.html',
  styleUrls: ['./add-account.component.css'],
  providers: [OrganizationService, Globals]
})
export class AddAccountComponent implements OnInit {

  @ViewChild('addAccountTabs') addAccountTabs: TabsetComponent;
  accountName: string = '';
  authType: string = '';
  showDiscoverMenu: boolean = false;
  vendor: string = '';
  discover: string = '';
  timeZone: string = '';
  host: string = '';
  userName: string = '';
  password: string = '';
  tableName: string = '';
  accessKey: string = '';
  secretKey: string = '';
  organizations;
  orgId;
  loader;
  region;
  repeatEvery: number = 1;
  interval: string = 'Minutes';
  accountNumber: string = '';
  tenantID: string = '';
  objectID: string = '';
  vendorlist;
  showSpinner;
  showSpinnerVerify;
  filedType;
  regionList = [];
  helpTextData;
  sectionWiseHelpdata;
  cur: number = 0;
  curx: number = 0;
  networkConfig = [];
  networkConfigType = [];
  networkConfigValue;
  needsALB = false;
  needsALBName = "alb";
  createProjectStorage = true;
  createProjectStorageVal = "Project";
  userLevel;
  showAddAcc = true;
  backPage;
  previousUrl: Observable<string> = this.previousRouteService.previousUrl$;
  previousURLVal;
  isVerified = false;
  clicked = false;
  selectedNetworkType: string = 'Use default VPC';
  enableTREFeature: boolean = false;
  accountType: string;
  tabName: any;
  isShared = false;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private saveSettings: DashboardService,
    private globals: Globals,
    private toastr: ToastrService,
    private orgService: OrganizationService,
    private serviceApi: ResearchService, private previousRouteService: PreviousRouteService) {
  }

  ngOnInit() {
    const element = document.querySelector('#scrollId');
    element.scrollIntoView();
    //check user level
    this.userLevel = sessionStorage.getItem('Level');
    this.orgId = sessionStorage.getItem('org_id');
    this.accountType = sessionStorage.getItem('accountType');
    if (this.userLevel != 1 && this.accountType == "study") {
      this.accountType = 'project';
    }
    this.getOrganization();
    this.vendorlist = 'Cloud';
    this.authType = 'IAM';
    //this.vendor = 'AWS';
    this.vendor = this.activatedRoute.snapshot.queryParams['vendor'];
    this.timeZone = 'IST';
    this.discover = this.vendor == 'AWS' ? 'VPC': 'VNet';
    this.repeatEvery = 5;
    this.interval = 'Minutes';
    this.regionList = []
    this.getAllHelptextdata('all', (this.vendor == 'AWS') ? 'add_account_helpText' : 'add_azure_account_helpText');
    this.getRegionsList();

    this.networkConfig = [{
      viewValue:  this.vendor == 'AWS' ? 'Use default VPC': 'Use default VNet',
      value: true,
      checked: true
    }];

    this.networkConfigType = [{
      viewValue: this.vendor == 'AWS' ? 'Use default VPC': 'Use default VNet',
      value: true,
      checked: true
    }, {
      viewValue: 'Secure Research Environment',
      value: false,
      checked: false
    }];
    this.networkConfigValue = true;
    //Get previous URL for breadcrumbs
    this.previousRouteService.previousUrl$.subscribe((previousUrl: string) => {
      this.previousURLVal = previousUrl;
      if (this.previousURLVal == '/principal') {
        this.backPage = 'All Projects';
      } else if (this.previousURLVal == '/setting') {
        this.backPage = 'Settings'
      } else if (previousUrl && previousUrl.includes('/addProject')) {
        this.previousURLVal = '/addProject/project';
        this.backPage = 'Create Project'
      } else {
        this.previousURLVal = '/setting';
        this.backPage = 'Settings'
      }
    });
  }

  addAccount($event: MouseEvent) {
    //disable button on add account
    // ($event.target as HTMLButtonElement).disabled = true;
    this.clicked = true;
    const el = document.getElementById("submitAccount");
    el.style.backgroundColor = '#3E6BFC';

    var data = {};
    var auth = {};
    data["name"] = this.accountName;
    data["vendor_category"] = this.vendorlist;
    data["vendor"] = this.vendor;
    data["auth_type"] = this.authType;
    data["interval"] = this.interval;
    data["repeat_every"] = this.repeatEvery;
    data["tableName"] = this.tableName;
    data["accountNumber"] = this.accountNumber;
    data["tenantID"] = this.tenantID;
    data["region"] = this.region;
    data["isSslEnabled"] = this.needsALB;
    data['networkConfig'] = this.networkConfigValue;
    data["accountType"] = this.accountType;
    data['createProjectStorage'] = (this.selectedNetworkType == 'Secure Research Environment') ? false : this.createProjectStorage
    data['isTreEnabled'] = (this.selectedNetworkType == 'Secure Research Environment') ? true : false
    data['albStatus'] = 'none';
    data['isShared'] = this.isShared;
    if (this.authType == 'IAM') {
      data["discover"] = this.discover
      auth = {
        accessKey: this.accessKey,
        secretKey: this.secretKey,
        region: this.region,
        accountNumber: this.accountNumber,
        tenantID: this.tenantID,
      }
      data["auth"] = auth;
    }
    data["time_zone"] = this.timeZone;
    this.showSpinner = true;
    if (this.accountType == 'study') {
      this.addStudyAccount(data)
    } else {
      this.saveSettings.saveSettings(data).subscribe(data => {
        this.showSpinner = false;
        this.navigate('success', 'Setting-saved-successfully');
        let t = new Date().getTime();
        if (this.userLevel === "2") {
          this.router.navigate(['/addOrganization/fromSettings'], { queryParams: { updatedData: t } });
        } else {
          this.router.navigate(['/addProject/fromSettings'], { queryParams: { updatedData: t } });
        }
      }, error => {
        this.showSpinner = false;
        this.isVerified = false;
        this.showAddAcc = false;
        try {
          this.clicked = false;
          // ($event.target as HTMLButtonElement).disabled = false;
          let errorMes = error.error;
          if (error && error.status === 401) {
            this.toastr.error(errorMes.err, '', this.globals.tosterOverride);
            this.router.navigate(['login']);
          } else if (errorMes && (errorMes.code && errorMes.message)) {
            this.navigate('error', errorMes.message);
          } else if (errorMes && errorMes.code === 11000) {
            //this is for checking duplicate record with same account name
            this.navigate('error', 'Setting cannot be saved, record already exists - ' + errorMes.errmsg);
          } else {
            let errMsg = errorMes.err ? errorMes.err : error.err;
            this.navigate('error', 'Setting cannot be saved : ' + + errMsg);
          }
        } catch (e) {
          //this.toastr.error(``, 'Service seems to be down. Please try after sometime.' , { tapToDismiss: false, timeOut: 0, extendedTimeOut: 0, closeButton : true });
        }

      });
    }
  }

  addStudyAccount(data) {
    this.serviceApi.saveStudySettings(data).subscribe(data => {
      this.showSpinner = false;
      this.toastr.success(``, `Registering study account started successfully`, this.globals.tosterOverride);
      this.router.navigate(['/setting'], {
        queryParams: { selectedTab: 'studyAccount' }
      });
    }, error => {
      this.showSpinner = false;
      this.isVerified = false;
      this.showAddAcc = false;
      this.clicked = false;
      let errorMes = error.error;
      if (error && error.status === 401) {
        this.toastr.error(errorMes.error, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
      } else {
        let errMsg = errorMes.error ? errorMes.error : error.error;
        this.toastr.error(`Error in registering study account -` + errMsg, 'Error', this.globals.tosterOverride);;
      }
    });
  }

  verifyAccount() {
    var data = {};
    var auth = {};
    data["accountType"] = this.accountType;
    data["name"] = this.accountName;
    data["vendor_category"] = this.vendorlist;
    data["vendor"] = this.vendor;
    data["auth_type"] = this.authType;
    data["interval"] = this.interval;
    data["repeat_every"] = this.repeatEvery;
    data["tableName"] = this.tableName;
    data["accountNumber"] = this.accountNumber;
    data["region"] = this.region;
    data["objectId"] = this.objectID;
    if (this.authType == 'IAM') {
      data["discover"] = this.discover
      auth = {
        accessKey: this.accessKey,
        secretKey: this.secretKey,
        region: this.region,
        accountNumber: this.accountNumber,
        tenantId: this.tenantID
      }
      data["auth"] = auth;
    }
    data["time_zone"] = this.timeZone;
    this.showSpinnerVerify = true;
    this.saveSettings.verifySettings(data).subscribe(data => {
      this.showSpinnerVerify = false;
      this.showAddAcc = true;
      this.isVerified = true;
      this.toastr.success(``, `Settings verified successfully.`, this.globals.tosterOverride);
    }, error => {
      this.showSpinnerVerify = false;
      try {
        let errorMes = error.error;
        if (error && error.status === 401) {
          this.toastr.error(errorMes.err, '', this.globals.tosterOverride);
          this.router.navigate(['login']);
        } else if (errorMes && (errorMes.code && errorMes.message)) {
          this.navigate('error', errorMes.message);
        } else if (errorMes && errorMes.code === 11000) {
          //this is for checking duplicate record with same account name
          this.navigate('error', 'Setting cannot be verified, record already exists - ' + errorMes.errmsg);
        } else {
          let errMsg = errorMes.err ? errorMes.err : error.err;
          this.navigate('error', 'Setting cannot be verified : ' + errMsg);
        }
      } catch (e) {
        //this.toastr.error(``, 'Service seems to be down. Please try after sometime.' , { tapToDismiss: false, timeOut: 0, extendedTimeOut: 0, closeButton : true });
      }
    });
  }

  highlightHelpText(fieldValue) {
    this.filedType = fieldValue;
    this.isVerified = false;
    //this.showAddAcc = false;
  }

  navigate(type, msg) {
    if (type == 'success') {
      this.toastr.success(``, `Setting saved successfully`, this.globals.tosterOverride);
    }
    else {
      this.toastr.error(``, msg, { tapToDismiss: false, timeOut: 0, extendedTimeOut: 0, closeButton: true });

    }
  }


  accordianCtrlNode(j) {
    this.curx = 999;
    if (this.cur == j)
      return this.cur = 999;
    else return this.cur = j;
  }

  accordianCtrlNodeX(j) {
    this.cur = 999;
    if (this.curx == j)
      return this.curx = 999;
    else return this.curx = j;
  }

  getAllHelptextdata(key, configType) {
    this.serviceApi.getAddAccountHelpText(key, (this.vendor == 'AWS') ? 'add_account_helpText' : 'add_azure_account_helpText').subscribe(res => {
      if (this.accountType == "study") {
        this.helpTextData = res[0].helpText;
        const excludedKeys = ['Use default VPC', 'What is Secure Research?'];
        this.helpTextData = this.helpTextData.filter(
          (item) => !excludedKeys.includes(item.key)
        );
      } else {
        this.helpTextData = res[0].helpText;
      }
    }, error => {
      const err = error.error;
      this.toastr.error(err.error, 'Error', this.globals.tosterOverride);
      if (error && error.status === 401) {
        let errBody = error.error;
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
      } else if (error && error.status === 403) {
        this.router.navigate(['pageNotFound']);
      }
    })
  }

  getOrganization() {
    this.loader = true;
    this.orgService.getOrganizationsList().subscribe(data => {
      this.loader = false;
      this.organizations = data;
      this.organizations.forEach((org => {
        if (org._id == this.orgId && (org.EnableTREFeature != undefined || org.EnableTREFeature != null)) {
          this.enableTREFeature = org.EnableTREFeature
          console.log('org.EnableTREFeature ', this.enableTREFeature)
        }
      }
      ))
      console.log('enableTREFeature ', this.enableTREFeature)
      console.log('userLevel', this.userLevel)
    }, error => {
      this.loader = false;
      if (error && error.status === 403) {
        const err = error.error;
        this.toastr.error(err.error, 'Error', this.globals.tosterOverride);
        this.router.navigate(['pageNotFound']);
      } else if (error && error.status === 401) {
        let errBody = error.error;
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
      }
    });
  }

  getSectionWiseHelp(key) {
    console.log(`sectionWiseHelp key is ${key} and vendor is ${this.vendor}`);
    this.getAllHelptextdata('all', (this.vendor == 'AWS') ? 'add_account_helpText' : 'add_azure_account_helpText')
    this.serviceApi.getAddAccountHelpText(key, (this.vendor == 'AWS') ? 'add_account_helpText' : 'add_azure_account_helpText').subscribe(res => {
      this.sectionWiseHelpdata = res[0].helpText;
      const idToRemove = res[0].helpText[0].key;
      this.helpTextData = this.helpTextData.filter((item) => item.key !== idToRemove);

    }, error => {
      if (error && error.status === 401) {
        let errBody = error.error;
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
      }
    })
  }

  getRegionsList() {
    this.serviceApi.getAddAccountHelpText('', this.vendor === 'AWS' ? 'region' : 'azure-region').subscribe(res => {
      this.regionList = res[0].helpText;
    }, error => {
      if (error && error.status === 401) {
        let errBody = error.error;
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
      }
    })
  }

  onFocus(keyValue) {
    this.cur = 999;
    this.curx = 0;
    this.getSectionWiseHelp(keyValue)
  }

  changeTab() {
    this.addAccountTabs.tabs[1].active = true;
  }

  selectNetworkConfig(data, event, i) {
    if (event.target.checked) {
      this.networkConfigValue = true;
    } else {
      this.networkConfigValue = false;
    }
  }


  selectNetworkConfigType(networkType, event, i) {
    if (event.target.checked) {
      this.selectedNetworkType = networkType.viewValue;
      this.networkConfigValue = true;
      if (this.selectedNetworkType == 'Secure Research Environment') {
        this.needsALB = true;
      }
      console.log(this.selectedNetworkType);
    } else {
      this.selectedNetworkType = '';
      this.networkConfigValue = false;
    }
    for (var network of this.networkConfigType) {
      console.log(this.selectedNetworkType);
      if (network.viewValue == this.selectedNetworkType) {
        network.checked = true;
        network.value = true;
      } else {
        network.checked = false;
        network.value = false;
      }
    }
  }

  selectNeedsALBConfig(event) {
    if (event.target.checked) {
      this.needsALB = true;
    } else {
      this.needsALB = false;
    }
  }

  checkCreateProjectStorage(event) {
    if (event.target.checked) {
      this.createProjectStorage = true;
    } else {
      this.createProjectStorage = false;
    }
  }

  checkShareAccount(event) {
    if (event.target.checked) {
      this.isShared = true;
    } else {
      this.isShared = false;
    }
  }

  sslReadMore() {
    window.open('https://docs.aws.amazon.com/elasticloadbalancing/latest/application/introduction.html', "_blank");
  }

  vpcReadMore() {
    window.open('https://docs.aws.amazon.com/vpc/latest/userguide/default-vpc.html', "_blank");
  }

  treReadMOre() {
    window.open('https://researchgateway.readthedocs.io/en/latest/GettingStarted.html#adding-an-aws-account-to-use-in-a-project', "_blank");
  }

  projectStorageReadMore() {
    window.open('https://researchgateway.readthedocs.io/en/latest/GettingStarted.html#project-storage', "_blank");
  }
}
