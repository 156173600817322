import { Component, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { MdlDialogService } from '@angular-mdl/core';
import { ResearchService } from "../_services/research.service";
import { AddUserComponent } from '../dialog/add-user/add-user.component';
import { DataService } from '../_services/data.service';
import { AssignOrganisationComponent } from '../dialog/assign-organisation/assign-organisation.component';
import { EnableUserComponent } from '../dialog/enable-user/enable-user.component';
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Globals } from "../globals";
import { DashboardService } from '../_services/dashboard.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css', '../app.component.css'],
  providers: [DashboardService, Globals]
})

export class UserComponent implements OnInit {

  users = [];
  organisationFilterData = [];
  roleFilterData = [];
  sortFilterData = [];
  selectedOrganisationFilter = { value: '', key: '' };
  selectedRoleFilter = { value: '', key: '' };
  selectedSortFilter = { value: '', key: '', order: '' };
  requestParams;
  requestParamsTemp;
  filterBy;
  loader: boolean = false;
  pageLimit = 9;
  currentPage = 1;
  totalUsers;
  totalPages;
  hasNextPage = false;
  searchKey = "";
  searchValueTemp;
  isCardView = true;
  currentPageLowerCount = 1;
  currentPageUpperCount = 9;
  isActiveUser = false;
  noDataTemplate = "No users available";
  organisationFilterLeft = -1;
  roleFilterLeft = -1;
  sortFilterLeft = -1;
  showAddButton = true;
  levelOfUser = "2";
  convertedJson;
  orgId;
  maxBulkUser;
  defaultHeader;
  eventS = this.getEventSource(`/stream_events`);

  constructor(private researchService: ResearchService,
    private dialog: MdlDialogService,
    private dataService: DataService,
    private dashboardService: DashboardService,
    private toastr: ToastrService,
    private global: Globals,
    private router: Router) {
    this.dataService.updatePage.subscribe(event => {
      if (event) {
        this.getUsers();
      }
    })
  }

  ngAfterViewInit() {
    if (window.innerWidth <= 576) {
      this.organisationFilterLeft = 16;
      this.roleFilterLeft = 210;
      this.sortFilterLeft = 400;
    } else {
      this.organisationFilterLeft = -1;
      this.roleFilterLeft = -1;
      this.sortFilterLeft = -1;
    }
    if (this.showAddButton) {
      document.querySelector('.add-new-btn-select-wrapper').addEventListener('click', () => {
        document.querySelector('.add-new-btn-select').classList.toggle('open');
      })
    }

    if (this.levelOfUser == '2') {
      // User by filter dropdown click listener
      document.querySelector('#filter-dropdown-wrapper').addEventListener('click', function () {
        this.querySelector('#filter-dropdown').classList.toggle('open');
      })
      window.addEventListener('click', function (e) {
        const select = document.querySelector('#filter-dropdown')
        if (select && !select.contains(e.target as Node)) {
          select.classList.remove('open');
        }
      });
    }
  }

  onScroll(event) {
    if (window.innerWidth <= 576) {
      this.organisationFilterLeft = 16;
      this.roleFilterLeft = 210;
      this.sortFilterLeft = 400;
      this.sortFilterLeft = this.sortFilterLeft - event.target.scrollLeft;
      this.organisationFilterLeft = this.organisationFilterLeft - event.target.scrollLeft;
      this.roleFilterLeft = this.roleFilterLeft - event.target.scrollLeft;
    } else {
      this.organisationFilterLeft = -1;
      this.roleFilterLeft = -1;
      this.sortFilterLeft = -1;
    }
  }


  ngOnInit() {
    //get SSE on page load
    this.serverStreamEvent();
    // Add New btn click listener
    let strategy = sessionStorage.getItem('strategy');
    let org = sessionStorage.getItem('tenantid');
    this.orgId = sessionStorage.getItem('org_id');
    this.getBulkUserConfig();

    this.levelOfUser = sessionStorage.getItem('Level');
    if (this.levelOfUser == "1") {
      this.selectedOrganisationFilter = { "key": this.orgId, "value": org };
    }
    if (strategy === 'saml' || strategy === 'azure-ad-oidc') {
      this.showAddButton = false;
    }

    window.addEventListener('click', function (e) {
      const select = document.querySelector('.add-new-btn-select')
      if (select && !select.contains(e.target as Node)) {
        select.classList.remove('open');
      }
    });

    // User Role by filter dropdown click listener
    document.querySelector('#filter-user-role-dropdown-wrapper').addEventListener('click', function () {
      this.querySelector('#filter-user-role-dropdown').classList.toggle('open');
    })
    window.addEventListener('click', function (e) {
      const select = document.querySelector('#filter-user-role-dropdown')
      if (select && !select.contains(e.target as Node)) {
        select.classList.remove('open');
      }
    });

    // Sort by filter dropdown click listener
    document.querySelector('#filter-sort-dropdown-wrapper').addEventListener('click', function () {
      this.querySelector('#filter-sort-dropdown').classList.toggle('open');
    })
    window.addEventListener('click', function (e) {
      const select = document.querySelector('#filter-sort-dropdown')
      if (select && !select.contains(e.target as Node)) {
        select.classList.remove('open');
      }
    });

    this.getFilters();
    if (this.levelOfUser == "1") {
      this.onChangeFilter('Organisation', this.selectedOrganisationFilter)
    }
    this.getUsers();
  }

  getFilters() {
    this.researchService.getOrganisationFilter().subscribe((res: any) => {
      this.organisationFilterData = res.filterList;
    }, error => {
      if (error && error.status === 401) {
        let errBody = error.error;
        this.toastr.error(errBody.err, '', this.global.tosterOverride);
      }
    })
    this.researchService.getRoleFilter().subscribe((res: any) => {
      this.roleFilterData = res.filterList;
    }, error => {
      if (error && error.status === 401) {
        let errBody = error.error;
        this.toastr.error(errBody.err, '', this.global.tosterOverride);
        this.router.navigate(['login']);
      }
    })
    this.researchService.getSortFilter().subscribe((res: any) => {
      this.sortFilterData = res.filterList;
    }, error => {
      if (error && error.status === 401) {
        let errBody = error.error;
        this.toastr.error(errBody.err, '', this.global.tosterOverride);
        this.router.navigate(['login']);
      }
    })
  }


  onChangeFilter(filterType, filterValue) {
    this.currentPage = 1;
    this.requestParams = new HttpParams();
    this.filterBy = "";
    switch (filterType) {
      case 'Organisation':
        this.selectedOrganisationFilter = filterValue;
        break;
      case 'Role':
        this.selectedRoleFilter = filterValue;
        break;
      case 'Sort':
        this.selectedSortFilter = filterValue;
        break;
      case 'Enabled':
        //this.isActiveUser = !this.isActiveUser;
        break;
    }
    if (this.selectedOrganisationFilter.value) {
      if (this.filterBy) {
        this.filterBy += ',' + `org_id:${this.selectedOrganisationFilter.key}`
      } else {
        this.filterBy = `org_id:${this.selectedOrganisationFilter.key}`
      }
    }
    if (this.selectedRoleFilter.value) {
      if (this.filterBy) {
        this.filterBy += ',' + `level:${this.selectedRoleFilter.key}`
      } else {
        this.filterBy = `level:${this.selectedRoleFilter.key}`
      }
    }
    if (this.isActiveUser) {
      if (this.filterBy) {
        this.filterBy += ',' + `enabled:${this.isActiveUser}`
      } else {
        this.filterBy = `enabled:${this.isActiveUser}`
      }
    }
    if (this.filterBy) {
      this.requestParams = this.requestParams.set('filterBy', this.filterBy);
    }
    if (this.selectedSortFilter.value) {
      this.requestParams = this.requestParams.set('sortBy', this.selectedSortFilter.key);
      this.requestParams = this.requestParams.set('sortOrder', this.selectedSortFilter.order);
    }
    this.requestParams = this.requestParams.toString();
    if (this.searchKey.length > 2) {
      this.searchUsers();
    } else {
      this.getUsers();
    }
  }

  resetFilter() {
    if (this.selectedOrganisationFilter.value || this.selectedRoleFilter.value || this.selectedSortFilter.value || this.isActiveUser) {
      this.currentPage = 1;
      if (this.levelOfUser == '2') {
        this.selectedOrganisationFilter = { key: '', value: '' };
      }
      this.selectedRoleFilter = { key: '', value: '' };
      this.selectedSortFilter = { key: '', value: '', order: '' };
      this.isActiveUser = false;
      this.requestParams = '';
      this.filterBy = '';
      if (this.searchKey.length > 2) {
        if (this.levelOfUser == "1") {
          this.onChangeFilter('Organisation', this.selectedOrganisationFilter)
        }
        this.searchUsers();
      } else {
        if (this.checkIfPrincipal()) {
          this.onChangeFilter('Organisation', this.selectedOrganisationFilter)
        } else {
          this.getUsers();
        }

      }
    }
  }

  getUsers() {
    this.loader = true;
    this.researchService.getUsers(this.currentPage, this.pageLimit, this.requestParams).subscribe((data: any) => {
      this.loader = false;
      this.users = data.users.docs;
      this.hasNextPage = this.currentPage !== data.users.pages;
      this.totalUsers = data.users.total;
      this.totalPages = data.users.pages;
      //Calculation for lower & upper count per page
      this.currentPageLowerCount = 1 + ((this.currentPage - 1) * this.pageLimit);
      if (data.users.docs.length < 9) {
        this.currentPageUpperCount = data.users.docs.length + (this.pageLimit * (this.currentPage - 1));
      } else {
        this.currentPageUpperCount = this.pageLimit * this.currentPage;
      }
    }, error => {
      this.loader = false;
      if (error && error.status === 401) {
        let errBody = error.error;
        this.toastr.error(errBody.err, '', this.global.tosterOverride);
        this.router.navigate(['login']);
      } else if (error && error.status === 403) {
        const err = error.error;
        this.toastr.error(err.error, 'Error', this.global.tosterOverride);
        this.router.navigate(['pageNotFound']);
      }
    })
  }

  loadMoreUsers() {
    this.currentPage++;
    this.loader = true;
    if (this.searchKey.length > 2) {
      this.researchService.getSearchedUsers(this.currentPage, this.pageLimit, this.searchKey, this.requestParams).subscribe((data: any) => {
        this.loader = false;
        this.users = this.users.concat(data.users.docs);
        this.hasNextPage = this.currentPage !== data.users.pages;
      }, error => {
        this.loader = false;
        if (error && error.status === 401) {
          let errBody = error.error;
          this.toastr.error(errBody.err, '', this.global.tosterOverride);
        }
      })
    } else {
      this.researchService.getUsers(this.currentPage, this.pageLimit, this.requestParams).subscribe((data: any) => {
        this.loader = false;
        this.hasNextPage = this.currentPage !== data.users.pages;
        this.users = this.users.concat(data.users.docs);
      }, error => {
        this.loader = false;
        if (error && error.status === 401) {
          let errBody = error.error;
          this.toastr.error(errBody.err, '', this.global.tosterOverride);
          this.router.navigate(['login']);
        }
      })
    }
  }

  searchUsers() {
    this.searchKey = this.searchKey.trim();
    this.searchValueTemp = this.searchKey;
    this.requestParamsTemp = this.requestParams;
    if (this.searchKey.length > 2) {
      this.loader = true;
      this.researchService.getSearchedUsers(this.currentPage, this.pageLimit, this.searchKey, this.requestParams).subscribe((data: any) => {
        this.noDataTemplate = `No matching users found`;
        this.loader = false;
        this.users = data.users.docs;
        this.hasNextPage = this.currentPage !== data.users.pages;
        this.totalUsers = data.users.total;
        this.totalPages = data.users.pages;
        //Calculation for lower & upper count per page
        this.currentPageLowerCount = 1 + ((this.currentPage - 1) * this.pageLimit);
        if (data.users.docs.length < 9) {
          this.currentPageUpperCount = data.users.docs.length + (this.pageLimit * (this.currentPage - 1));
        } else {
          this.currentPageUpperCount = this.pageLimit * this.currentPage;
        }
      }, error => {
        this.loader = false
        if (error && error.status === 401) {
          let errBody = error.error;
          this.toastr.error(errBody.err, '', this.global.tosterOverride);
          this.router.navigate(['login']);
        }
      })
    } else if (this.searchKey.length === 0) {
      this.currentPage = 1;
      this.getUsers()
    }
  }

  toggleView() {
    this.isCardView = !this.isCardView;
    this.currentPage = 1;
    this.getUsers();
  }

  getNextUsers(value) {
    switch (value) {
      case 'first':
        this.currentPage = 1;
        break;
      case 'previous':
        this.currentPage--;
        break;
      case 'next':
        this.currentPage++;
        break;
      case 'last':
        this.currentPage = this.totalPages;
        break;
    }
    if (this.searchKey.length > 2) {
      this.searchUsers();
    } else {
      this.getUsers();
    }
  }

  addUser() {
    this.dashboardService.getDialogBox().subscribe((data: any) => {
      if (data.status === 200) {
        this.dialog.showCustomDialog({
          component: AddUserComponent,
          providers: [{ provide: 'data', useValue: { action: 'addUser' } }],
          isModal: true,
          styles: {
            "height": "auto",
            // "overflow": "auto",
            "width": "400px",
            "border": "1px solid #FFFFFF33",
            "border-radius": "10px",
            "background-color": "#07102A",
            "padding": "0px"
          },
          clickOutsideToClose: true,
          enterTransitionDuration: 400,
          leaveTransitionDuration: 400,
        })
      }
    }, error => {
      if (error && error.status === 401) {
        let errBody = error.error;
        this.toastr.error(errBody.err, '', this.global.tosterOverride);
        this.router.navigate(['login']);
      }
    })
  }

  editUser(user) {
    this.dashboardService.getDialogBox().subscribe((data: any) => {
      if (data.status === 200) {
        this.dialog.showCustomDialog({
          component: AddUserComponent,
          providers: [{ provide: 'data', useValue: { action: 'editUser', userId : user._id, searchKey : this.searchKey, requestParams : this.requestParams, users: this.users} }],
          isModal: true,
          styles: {
            "height": "auto",
            "width": "400px",
            "border": "1px solid #FFFFFF33",
            "border-radius": "10px",
            "background-color": "#07102A",
            "padding": "0px"
          },
          clickOutsideToClose: true,
          enterTransitionDuration: 400,
          leaveTransitionDuration: 400,
        })
      }
    }, error => {
      if (error && error.status === 401) {
        let errBody = error.error;
        this.toastr.error(errBody.err, '', this.global.tosterOverride);
        this.router.navigate(['login']);
      }
    })
  }

  assignUser(user) {
    this.dashboardService.getDialogBox().subscribe((data: any) => {
      if (data.status === 200) {
        this.dialog.showCustomDialog({
          component: AssignOrganisationComponent,
          providers: [{ provide: 'data', useValue: { action: 'assignOrganisation', userInfo: user } }],
          isModal: true,
          styles: {
            "height": "auto",
            "width": "500px",
            "border": "1px solid #FFFFFF33",
            "border-radius": "10px",
            "background-color": "#07102A",
            "padding": "0px"
          },
          clickOutsideToClose: true,
          enterTransitionDuration: 400,
          leaveTransitionDuration: 400,
        })
      }
    }, error => {
      if (error && error.status === 401) {
        let errBody = error.error;
        this.toastr.error(errBody.err, '', this.global.tosterOverride);
        this.router.navigate(['login']);
      }
    })
  }

  disableUser(user) {
    this.dashboardService.getDialogBox().subscribe((data: any) => {
      if (data.status === 200) {
        this.dialog.showCustomDialog({
          component: EnableUserComponent,
          providers: [{ provide: 'data', useValue: { action: 'disableUser', userInfo: user } }],
          isModal: true,
          styles: {
            "height": "auto",
            "width": "400px",
            "border": "1px solid #FFFFFF33",
            "border-radius": "10px",
            "background-color": "#07102A",
            "padding": "0px"
          },
          clickOutsideToClose: true,
          enterTransitionDuration: 400,
          leaveTransitionDuration: 400,
        })
      }
    }, error => {
      if (error && error.status === 401) {
        let errBody = error.error;
        this.toastr.error(errBody.err, '', this.global.tosterOverride);
        this.router.navigate(['login']);
      }
    })
  }

  enableUser(user) {
    this.dashboardService.getDialogBox().subscribe((data: any) => {
      if (data.status === 200) {
        this.dialog.showCustomDialog({
          component: EnableUserComponent,
          providers: [{ provide: 'data', useValue: { action: 'enableUser', userInfo: user } }],
          isModal: true,
          styles: {
            "height": "auto",
            "width": "400px",
            "border": "1px solid #FFFFFF33",
            "border-radius": "10px",
            "background-color": "#07102A",
            "padding": "0px"
          },
          clickOutsideToClose: true,
          enterTransitionDuration: 400,
          leaveTransitionDuration: 400,
        })
      }
    }, error => {
      if (error && error.status === 401) {
        let errBody = error.error;
        this.toastr.error(errBody.err, '', this.global.tosterOverride);
        this.router.navigate(['login']);
      }
    })
  }

  resendVerificationMail(user) {
    this.researchService.resendVerificationMail(user.user_id).subscribe(data => {
      this.toastr.success(`Verification mail sent successfully to ${user.email}`, '', this.global.tosterOverride);
    }, error => {
      console.log(error);
      if (error && error.status === 401) {
        let errBody = error.error;
        this.toastr.error(errBody.err, '', this.global.tosterOverride);
        this.router.navigate(['login']);
      } else {
        let err = error.error;
        this.toastr.error(err.message);
      }
    });
  }

  checkIfPrincipal() {
    if (this.levelOfUser == '1') {
      return true;
    } else {
      return false;
    }
  }

  getUserInitials(user) {

    let initials = ""
    if (!user) {
      return initials;
    }
    if (user.first_name) {
      initials += user.first_name[0]
    }
    if (user.last_name) {
      initials += user.last_name[0]
    }

    if (!user.first_name && !user.last_name) {
      initials += user.email.slice(0, 2);
    }
    return initials.toUpperCase();

  }

  getBulkUserConfig() {
    try {
      //default values if config data is not added
      this.maxBulkUser = 50;
      this.defaultHeader = ['email', 'username', 'first_name', 'last_name', 'role'];
      this.researchService.getConfigData('usersConfig').subscribe((data: any) => {
        if (data.value) {
          this.maxBulkUser = data.value.maxBulkUserCount;
          this.defaultHeader = data.value.csvHeaderValues;
        }
      })
    } catch (err) {
      console.log("getBulkUserConfig Error", err.message);
    }
  }

  /* This is the function used to parse the CSV, get the header value, user data and create bulk users */
  importUsers(event) {
    const selectedFile = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsBinaryString(selectedFile);
    fileReader.onload = (event: any) => {
      let binaryData = event.target.result;
      let fileData = XLSX.read(binaryData, { type: 'binary' });
      fileData.SheetNames.forEach(sheet => {
        let data: Array<any>;
        data = XLSX.utils.sheet_to_json(fileData.Sheets[sheet]);
        this.convertedJson = JSON.stringify(data, undefined, 4);
        let excelRows: any;
        //Get CSV header
        excelRows = XLSX.utils.sheet_to_json(fileData.Sheets[sheet], { header: 1 });
        let excelData = excelRows;
        let header = excelData.shift();
        //Check whether CSV has correct required header values and maxBulkUser
        let checker = (arr, target) => target.every(v => arr.includes(v));
        const isDefaultHeader = checker(header, this.defaultHeader);
        //PI(role: 1) cannot create admin(role: 2) users
        if (this.levelOfUser == '1') {
          data = data.filter(item => item.role != 2)
        }
        // if user role is invalid, set it to 0(researcher)
        let userRole = [0, 1, 2];
        data.filter(user => {
          let roleData = parseInt(user.role);
          let isRoleExist = userRole.includes(roleData);
          if (!isRoleExist) {
            user.role = 0;
          }
        })
        let errMsg;
        if (data.length === 0) {
          errMsg = "The file should have details of at least one user.";
          this.toastr.error(errMsg, 'Invalid CSV', this.global.tosterOverride);
          return;
        } else if (isDefaultHeader && (data.length <= this.maxBulkUser)) {
          // this.toastr.success(`Valid CSV`,'', this.global.tosterOverride);
        } else {
          if (!isDefaultHeader) {
            errMsg = "The CSV file that you are trying to upload has invalid headers. Please download the sample CSV file for your reference.";
          }
          if (data.length > this.maxBulkUser) {
            errMsg = `The CSV file that you are trying to upload has more users than the allowed limit - ${this.maxBulkUser}. Please download the sample CSV file for your reference.`;
          }
          this.toastr.error(errMsg, 'Invalid CSV', this.global.tosterOverride);
          return;
        }
        //Create a user tag array from comma separated string
        for (let i = 0; i < data.length; i++) {
          let tagString = data[i].userTags;
          data[i].userTags = tagString.split(',');
          data[i].role = parseInt(data[i].role);
          data[i].source = 'admin';
          if (data[i].first_name == undefined || data[i].first_name == null) {
            data[i].first_name = '';
          }
          if (data[i].last_name == undefined || data[i].last_name == null) {
            data[i].last_name = '';
          }
        }
        this.researchService.addBulkUser(data).subscribe(res => {
          if (res) {
            this.toastr.success(`Multiple user creation started successfully. Please wait for sometime.`, '', this.global.tosterOverride);
          }
        }, error => {
          let errBody = error.error;
          if (error && error.status === 401) {
            this.toastr.error(errBody.err, '', this.global.tosterOverride);
            this.router.navigate(['login']);
          } else {
            this.toastr.error(errBody.message, '', this.global.tosterOverride);
          }
        });
      })
    }
  }

  downloadSampleCSV() {
    try {
      this.researchService.downloadSampleCSV().subscribe((res: any) => {
        if (res.url) {
          const presignedURL = res.url;
          window.open(presignedURL, '_blank');
        }
      }, error => {
        if (error && error.status === 401) {
          let errBody = error.error;
          this.toastr.error(errBody.err, '', this.global.tosterOverride);
          this.router.navigate(['login']);
        } else {
          let err = error.error;
          this.toastr.error(err.message, 'Download Failed', this.global.tosterOverride);
        }
      })
    } catch (err) {
      console.log("Error in downloadSampleCSV", err.message);
      this.toastr.error(err.message);
    }
  }

  getEventSource(url: string): EventSource {
    return new EventSource(url, { withCredentials: true });
  }

  //SSE for bulkUserCreation events
  serverStreamEvent() {
    this.researchService.getServerSentEvent(this.eventS).subscribe(res => {
      const resObj = res.data;
      if (!resObj) {
        return;
      }
      try {
        const { status, data, payload } = JSON.parse(resObj);
        // server give handshake to the alive connection
        if (data === 'check') {
          return;
        }
        if (data === 'bulkUserCreation') {
          this.getUsers();
        }
      } catch (e) {
        console.log("Error in SES events for bulkUserCreation", e.message);
      }
    });
  }

}
