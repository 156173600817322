import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from "@angular/router";
import { ResearchService} from "../../../_services/research.service";
import { ActivatedRoute } from '@angular/router';
import { DashboardService } from "../../../_services/dashboard.service";
import {Globals} from "../../../globals";
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-budget-researcher',
  templateUrl: './budget-researcher.component.html',
  styleUrls: ['./budget-researcher.component.css', '../budget-details.component.css', '../budget-tracking.component.css', '../../../app.component.css'],
  providers: [Globals]
})
export class BudgetResearcherComponent implements OnInit {

  projectId;
  orgId;
  budgetAllotted: any = 0;
  budgetConsumed: any = 0;
  budgetAvailable: any = 0;
  currencySymbol: string = '$';
  researchers = [];
  loader: boolean = true;
  orgName;
  organisations;
  projectName;
  budgetData;
  projects = [];

  private sub: any;

  constructor(private researchService: ResearchService,
              private route: ActivatedRoute,
              private location: Location,
              private router: Router,
              private apiService: DashboardService,
              private globals: Globals,private toastr: ToastrService) { }

  ngOnInit() {
    this.apiService.checkIfAdmin();
    this.projectId = this.route.snapshot.params['id'];
    this.orgId =  this.route.snapshot.queryParams['id'];
    this.projectName = this.route.snapshot.queryParams['projectName'];
    this.getOrganization();
    this.getProjects();
    this.getResearcherList();
  }


  goBack() {
    this.location.back();
  }

  getResearcherList() {
    this.loader = true;
    this.budgetData = `Available Budget = Allocated Budget - Researcher Consumed Budget - ((Project Consumed Budget - Total Researcher Consumed Budget) / Total Number Of Researchers)\n`;
    this.researchService.getResearcherListFromProjectId(this.projectId).subscribe((data:any) => {
      this.loader = false;
      this.researchers = data;
      this.researchers.forEach(item => {
        if(item.productList.length > 0){
          item.productsInString = item.productList.toString()
        }else {
          item.productsInString = "No products configured"
        }
        item.budgetCal = `${item.budgetAllocated} - ${ parseFloat(item.budgetConsumed).toFixed(2)} - ((${ parseFloat(item.projectConsumedBudget).toFixed(2)} - ${ parseFloat(item.totalResearcherConsumedBudget).toFixed(2)})/ ${item.totalNumberOfResearchers})`
      })
      // this.loader = false;
    }, error => {
      this.loader = false;
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    });
  }

  researcherName(name) {
    // let splitName = name.split('/');
    return name;
  }

  researcherBudgetAvailable(researcher) {
    let value;
    if(researcher.budgetConsumed){
      value = researcher.budgetAllocated - researcher.budgetConsumed
    }else{
      value = researcher.budgetAllocated
    }
    return value;
  }

  openProductBudgetScreen(researcher){
    this.router.navigate(['/admin/researcher/' + researcher.name + '/details'], { queryParams: { id: this.orgId, projectId: this.projectId, projectName : this.projectName, displayName: researcher.displayName}});
  }

  openOrganisationDetails() {
    this.router.navigate(['/admin/organisation/'+ this.orgId +'/details']);
  }

  getRoundOffBudget(data){
    return parseFloat(data).toFixed(2);
  }

  getOrganization() {
    this.loader = true;
    this.researchService.getOrganizations().subscribe(data => {
      this.loader = false;
      this.organisations = data;
      this.organisations.forEach((item => {
        if(item.organizationID === this.orgId){
        // this.budgetAllotted += item.totalBudgetAllocated;
        // this.budgetConsumed += item.budgetConsumed;
        this.orgName = item.organizationName;
        }
      }))
      // this.budgetAvailable = Math.round(this.budgetAllotted - this.budgetConsumed);
      // this.budgetAllotted = Math.round(this.budgetAllotted);
      // this.budgetConsumed = Math.round(this.budgetConsumed);
    }, error => {
      this.loader = false;
      if(error && error.status === 403){
        const err = error.error;
        this.toastr.error(err.error, 'Error', this.globals.tosterOverride);
        this.router.navigate(['pageNotFound']);
      }else if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    });
  }

  getProjects() {
    this.researchService.getPrincipalProjectsBudget(this.projectId, this.orgId).subscribe((data:any) => {
      this.projects = data;
      //this.projectImg = data[0].img_src;
      this.projectName = data[0].name;
      this.getBudgetCardsData();
    }, error => {
      this.loader = false;
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }else if(error && error.status === 403) {
        const err = error.error;
        this.toastr.error(err.error, 'Error', this.globals.tosterOverride);
        this.router.navigate(['pageNotFound']);
      }
    });
  }

  getBudgetCardsData(){
    let allotted : any = 0;
    let consumed : any = 0;
    let available : any = 0;
    this.projects.forEach((project) => {
      // Safe navigation on all keys
      if(project.budget){
        allotted += project.budget
      }
      let budgetConsumed = project.budgetConsumed;
      if (budgetConsumed) {
        let projectBudgetConsumed = budgetConsumed['projectBudgetConsumed']
        if (projectBudgetConsumed) {
          consumed += projectBudgetConsumed;
        }
      }
      available = allotted - consumed;
      this.budgetAllotted = parseFloat(allotted).toFixed(2);
      this.budgetConsumed = parseFloat(consumed).toFixed(2);
      this.budgetAvailable = parseFloat(available).toFixed(2);
    });
  }

}
