<div class="container pi-budget-tracking">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb bc-custom">
      <li class="breadcrumb-item"><a class="bc-item-active"> Billing Accounts </a></li>
    </ol>
  </nav>
  <!-- Page Heading -->
  <div class="header-title">
    <h3 class="text-white">Billing Accounts</h3>
  </div>
  <div class="dividers"></div>
  <div class="budget-container">
    <div class="header">
      <div class="container-fluid">
        <div class="header-body">
          <div class="row kpi-container">
            <div class="col-12 col-sm-4 col-md-4 col-xs-12">
              <div class="card bg-gradient-info border-0 kpi-card-margin">
                <div class="card-body">
                  <div class="row">
                    <div class="col card-bx">
                      <div class="kpi-card-header">
                        <span *ngIf="userLevel == '1'"
                          class="h2 font-weight-bold mb-0 text-white card-bx-ft card-value">{{accountCount}}</span>
                        <span *ngIf="userLevel == '2'"
                          class="h2 font-weight-bold mb-0 text-white card-bx-ft card-value">{{orgCount}}</span>
                        <img class="kpi-card-icon" *ngIf="userLevel == '1'" src="../../assets/images/consumed_budget.png" alt="Number Of Accounts"/>
                        <img class="kpi-card-icon" *ngIf="userLevel == '2'" src="../../assets/images/org_count.png" alt="Number Of Organizations" />
                      </div>
                      <div *ngIf="userLevel == '1'" class="kpi-info">
                        <h5 class="card-title text-muted mb-0 text-white card-bx-se font-class">Number Of Accounts </h5>
                      </div>
                      <div *ngIf="userLevel == '2'" class="kpi-info">
                        <h5 class="card-title text-muted mb-0 text-white card-bx-se font-class">Number Of Organizations
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4 col-md-4 col-xs-12">
              <div class="card bg-gradient-danger border-0 kpi-card-margin">
                <div class="card-body">
                  <div class="row">
                    <div class="col card-bx">
                      <div class="kpi-card-header">
                        <span *ngIf="userLevel == '1'"
                          class="h2 font-weight-bold mb-0 text-white card-bx-ft card-value">{{ currencySymbol }}
                          <span class="h2 font-weight-bold mb-0 text-white card-bx-ft"
                            *ngIf="userLevel == '1' && currentMonthBilling != undefined && currentMonthBilling != '0'">{{currentMonthBilling}}</span>
                          <span class="h2 font-weight-bold mb-0 text-white card-bx-ft"
                            *ngIf="userLevel == '1' && (currentMonthBilling == undefined || currentMonthBilling == '0')">0.00</span>
                        </span>
                        <span *ngIf="userLevel == '2'"
                          class="h2 font-weight-bold mb-0 text-white card-bx-ft card-value">{{accountCount}}</span>
                        <img class="kpi-card-icon" *ngIf="userLevel == '1'" src="../../assets/images/current_month_billing.png" alt="Current Month Billing" />
                        <img class="kpi-card-icon" *ngIf="userLevel == '2'" src="../../assets/images/consumed_budget.png" alt="Number Of Accounts" />
                      </div>
                      <div *ngIf="userLevel == '1'" class="kpi-info">
                        <h5 class="card-title text-muted mb-0 text-white card-bx-se font-class"> Current Month Billing
                        </h5>
                      </div>
                      <div *ngIf="userLevel == '2'" class="kpi-info">
                        <h5 class="card-title text-muted mb-0 text-white card-bx-se font-class"> Number Of Accounts
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4 col-md-4 col-xs-12">
              <div class="card bg-gradient-default border-0 kpi-card-margin">
                <div class="card-body">
                  <div class="row">
                    <div class="col card-bx">
                      <div class="kpi-card-header">
                        <span class="h2 font-weight-bold mb-0 text-white card-bx-ft card-value">{{ currencySymbol }}
                          <span class="h2 font-weight-bold mb-0 text-white card-bx-ft"
                            *ngIf="userLevel == '1' &&(currentMonthForecast == undefined || currentMonthForecast == '0')">0.00</span>
                          <span class="h2 font-weight-bold mb-0 text-white card-bx-ft"
                            *ngIf="userLevel == '1' && currentMonthForecast != undefined && currentMonthForecast != '0'">{{currentMonthForecast}}</span>
                          <span *ngIf="userLevel == '2'"
                            class="h2 font-weight-bold mb-0 text-white card-bx-ft">{{currentMonthBilling}}</span>
                        </span>
                        <img class="kpi-card-icon" *ngIf="userLevel == '1'" src="../../assets/images/current_month_forecast.png" alt="Total Forecast Value"/>
                        <img class="kpi-card-icon" *ngIf="userLevel == '2'" src="../../assets/images/current_month_billing.png" alt="Current Month Billing" />
                      </div>
                      <div *ngIf="userLevel == '1'" class="kpi-info">
                        <h5 class="card-title text-muted mb-0 text-white card-bx-se font-class"> Total Forecast Value
                        </h5>
                      </div>
                      <div *ngIf="userLevel == '2'" class="kpi-info">
                        <h5 class="card-title text-muted mb-0 text-white card-bx-se font-class"> Current Month Billing
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container budget-breakdown">
    <div class="row budget-breakdown-header" style="margin: 25px 0px !important">
      <div class="col align-self-center text-left font-class budget-header">
        <h3>Account-wise Bills</h3>
      </div>
    </div>
    <div class="project-table-layout">
      <div>
        <div class="projects-header d-flex w-100" *ngIf="total !== 0 && !loader">
          <div *ngIf="userLevel == '2'" class="title">
            Organization Name
          </div>
          <div class="title">
            Account Number
          </div>
          <div class="title">
            Current Month Billing
          </div>
          <div class="title">
            Current Month Forecast
            <popover-content placement="bottom" #myPopover1 [closeOnClickOutside]="true">
              <div id="tagPop">
                <h3 class="info-popup wlcTxt">*Forecast value will not be shown if account has less than one full
                  billing
                  cycle of historical data available </h3>
              </div>
            </popover-content>
            <span>
              <i title="Info" class="fa fa-info-circle info-icon" aria-hidden="true" [popover]="myPopover1"
                [popoverCloseOnClickOutside]="true" [popoverOnHover]="false"></i>
            </span>
          </div>
          <div class="title">
            Last Updated
          </div>
        </div>
        <div class="project-row d-flex w-100 py-4 my-3" style="height: 60px !important"
          *ngFor="let bills of accountBillsArray">
          <div *ngIf="userLevel == '2'" class="project-column">
            <span class="text-white"> {{bills.org_name}} </span>
          </div>
          <div class="project-column">
            <span class="text-white" *ngIf="userLevel == '1'"> {{bills.account_number}} </span>
            <span *ngIf="userLevel == '2'"> {{bills.account_number}} </span>
          </div>
          <div class="project-column">
            <span> {{(bills.monthly_cost[0].cost).toFixed(2)}} </span>
          </div>
          <div class="project-column" *ngIf="bills.forecast_current_month_cost == 0">
            <span> Not available </span>
          </div>
          <div class="project-column" *ngIf="bills.forecast_current_month_cost !== 0">
            <span> {{(bills.forecast_current_month_cost).toFixed(2)}} </span>
          </div>
          <div class="project-column">
            <span class="text-white" *ngIf="userLevel == '1'"> {{ convertDateToString(bills.updated_on) }} </span>
            <span *ngIf="userLevel == '2'"> {{ convertDateToString(bills.updated_on) }} </span>
          </div>
        </div>
      </div>
      <!-- No bills -->
      <div class="empty-my-product-layout-a" *ngIf="total == 0 && !loader">
        <div>
          <h3 class="no-data-found text-white font-class">
            No billing accounts have been set up. Add an account in the Settings screen.
          </h3>
          <div class="col-sm-12" style="text-align: center;margin-top: 25px;margin-bottom: 25px">
            <button class="launch-btn text-white font-class" (click)="addSettings()" name="button" type="button">Add Account</button>
          </div>
        </div>
      </div>
      <div class="product-avail" *ngIf="total !== 0 && !loader">
        <span class="page-count-text">{{ currentPageLowerCount }} - {{ currentPageUpperCount }} of {{ total }}</span>
        <div class="pagination-wrapper">
          <img class="pagination-next-btn" [ngClass]="{'disable-first-btn':page === 1 }"
            src="../../assets/images/pagination-first-active.png" alt="First Page" (click)="getNextBills('first')" />
          <img class="pagination-next-btn" [ngClass]="{'disable-prev-btn':page === 1 }"
            src="../../assets/images/pagination-prev-active.png" alt="Previous Page" (click)="getNextBills('previous')" />
          <img class="pagination-next-btn" [ngClass]="{'disable-next-btn':page === totalPages}"
            src="../../assets/images/pagination-next-active.png" alt="Next Page" (click)="getNextBills('next')" />
          <img class="pagination-next-btn" [ngClass]="{'disable-last-btn':page === totalPages}"
            src="../../assets/images/pagination-last-active.png" alt="Last Page" (click)="getNextBills('last')" />
        </div>
      </div>
    </div>
  </div>
  <!-- mobile responsive -->
  <div class="project-cards-layout">
    <div class="project-card" *ngFor="let bills of accountBillsArray">
      <ul class="list-group">
        <div class="d-flex justify-content-between mb-2" *ngIf="userLevel == '2'">
          <div class="project-card-title font-class">Organization Name</div>
          <div class="project-card-project-name">{{bills.org_name}}</div>
        </div>
        <div class="d-flex justify-content-between mb-2">
          <div class="project-card-title project-card-line-height font-class">Account Number</div>
          <div class="project-card-project-name" *ngIf="userLevel == '1'">{{bills.account_number}}</div>
          <div class="project-card-value" *ngIf="userLevel == '2'">{{bills.account_number}}</div>
        </div>
        <div class="d-flex justify-content-between mb-2">
          <div class="project-card-title project-card-line-height font-class">Current Month Billing</div>
          <div class="project-card-value">{{ currencySymbol }} {{(bills.monthly_cost[0].cost).toFixed(2)}} </div>
        </div>
        <div class="d-flex justify-content-between mb-2" *ngIf="bills.forecast_current_month_cost == 0">
          <div class="project-card-title project-card-line-height font-class">Current Month Forecast</div>
          <div class="project-card-value"> Not available </div>
        </div>
        <div class="d-flex justify-content-between mb-2" *ngIf="bills.forecast_current_month_cost !== 0">
          <div class="project-card-title project-card-line-height font-class">Current Month Forecast</div>
          <div class="project-card-value">{{ currencySymbol }} {{(bills.forecast_current_month_cost).toFixed(2)}} </div>
        </div>
        <div class="d-flex justify-content-between mb-2">
          <div class="project-card-title project-card-line-height font-class">Last Updated</div>
          <div class="project-card-project-name" *ngIf="userLevel == '1'">{{ convertDateToString(bills.updated_on) }}</div>
          <div class="project-card-value" *ngIf="userLevel == '2'">{{ convertDateToString(bills.updated_on) }}</div>
        </div>
      </ul>
    </div>
    <div class="empty-my-product-layout-a" *ngIf="total == 0 && !loader">
      <h3 class="no-data-found text-white font-class">
        No billing accounts have been set up. Add an account in the Settings screen.
      </h3>
    </div>
    <div class="product-avail" *ngIf="total !== 0 && !loader">
      <span class="page-count-text">{{ currentPageLowerCount }} - {{ currentPageUpperCount }} of {{ total }}</span>
      <div class="pagination-wrapper">
        <img class="pagination-next-btn" [ngClass]="{'disable-first-btn':page === 1 }"
          src="../../assets/images/pagination-first-active.png" alt="First Page" (click)="getNextBills('first')" />
        <img class="pagination-next-btn" [ngClass]="{'disable-prev-btn':page === 1 }"
          src="../../assets/images/pagination-prev-active.png" alt="Previous Page" (click)="getNextBills('previous')" />
        <img class="pagination-next-btn" [ngClass]="{'disable-next-btn':page === totalPages}"
          src="../../assets/images/pagination-next-active.png" alt="Next Page" (click)="getNextBills('next')" />
        <img class="pagination-next-btn" [ngClass]="{'disable-last-btn':page === totalPages}"
          src="../../assets/images/pagination-last-active.png" alt="Last Page" (click)="getNextBills('last')" />
      </div>
    </div>
  </div>
</div>
<app-loader class="spinner" *ngIf="loader"></app-loader>