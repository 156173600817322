<div *ngIf="folderName">
    <div class="dialog-header">
        <img class="cancel-icon" (click)="cancel()" src="../../assets/images/cancel@2x.png" alt="Cancel"/>
        <h3 class="title" *ngIf="!isStudy">Delete {{service | titlecase}} Folder</h3>
        <h3 class="title" *ngIf="isStudy">Delete Study Folder</h3>
    </div>
    <div style="display: flex; flex-direction: row; margin-top: 0px;">
      <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
      <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
      <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
      <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
    </div>
    <app-loader class="spinner" *ngIf="showSpinner"></app-loader>
    <div class="content" *ngIf="!showSpinner">
      <h2 class="content-1">The folder "{{folderName}}" will be removed permanently. Do you want to proceed? </h2>
    </div>
    <div class="warning" *ngIf="!showSpinner">
        <h2 class="delete-msg"> This operation cannot be undone. </h2>
    </div>
    <div class="mdl-dialog__actions" style="margin-top: 15px; margin-bottom: 15px;" *ngIf="!showSpinner">
      <button class="btn btn-primary dele-btn" type="button" (click)="deleteS3Folder()"><h2 class="del-text">Delete</h2></button>
      <button class="btn  can-btn" type="button"  (click)="cancel()"><h2 class="cal-text">Cancel</h2></button>
    </div>
  </div>

<div *ngIf="fileName" >
      <div class="dialog-header">
          <img class="cancel-icon" (click)="cancel()" src="../../assets/images/cancel@2x.png" alt="Cancel"/>
          <h3 class="title" *ngIf="!isStudy">Delete {{service | titlecase}} File</h3>
          <h3 class="title" *ngIf="isStudy">Delete Study File</h3>
      </div>
      <div style="display: flex; flex-direction: row; margin-top: 0px;">
        <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
        <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
        <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
        <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
      </div>
      <app-loader class="spinner" *ngIf="showSpinner"></app-loader>
      <div class="content" *ngIf="!showSpinner">
        <ng-container>
          <h2 class="content-1">The file "{{ filePath }}" will be removed permanently. Do you want to proceed?</h2>
        </ng-container>
      </div>
      <div class="warning" *ngIf="!showSpinner">
          <h2 class="delete-msg"> This operation cannot be undone. </h2>
      </div>
      <div class="mdl-dialog__actions" style="margin-top: 15px; margin-bottom: 15px;" *ngIf="!showSpinner">
        <button class="btn btn-primary dele-btn" type="button" (click)="deleteS3File()"><h2 class="del-text">Delete</h2></button>
        <button class="btn  can-btn" type="button"  (click)="cancel()"><h2 class="cal-text">Cancel</h2></button>
      </div>
    </div>

<div *ngIf="isMultiple">
            <div class="dialog-header">
                <img class="cancel-icon" (click)="cancel()" src="../../assets/images/cancel@2x.png" alt="Cancel"/>
                <h3 class="title" *ngIf="!isStudy">Delete {{service | titlecase}} data</h3>
                <h3 class="title" *ngIf="isStudy">Delete Study data</h3>
            </div>
            <div style="display: flex; flex-direction: row; margin-top: 0px;">
              <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
              <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
              <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
              <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
            </div>
            <app-loader class="spinner" *ngIf="showSpinner"></app-loader>
            <div class="content" *ngIf="!showSpinner">
              <h2 class="content-1">The files/folders will be removed permanently. Do you want to proceed? </h2>
            </div>
            <div class="warning" *ngIf="!showSpinner">
                <h2 class="delete-msg"> This operation cannot be undone. </h2>
            </div>
            <div class="mdl-dialog__actions" style="margin-top: 15px; margin-bottom: 15px;" *ngIf="!showSpinner">
              <button class="btn btn-primary dele-btn" type="button" (click)="deleteS3MultipleData()"><h2 class="del-text">Delete</h2></button>
              <button class="btn  can-btn" type="button"  (click)="cancel()"><h2 class="cal-text">Cancel</h2></button>
            </div>
    </div>

<div *ngIf="isKeypair">
  <div class="dialog-header">
    <img class="cancel-icon" (click)="cancel()" src="../../assets/images/cancel@2x.png" alt="Cancel"/>
    <h3 class="title">Delete Key pair</h3>
  </div>
  <div style="display: flex; flex-direction: row; margin-top: 0px;">
    <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
  </div>
  <app-loader class="spinner" *ngIf="showSpinner"></app-loader>
  <div class="content" *ngIf="!showSpinner">
    <h2 class="content-1" title="{{keypairName}}">The Keypair "{{name(keypairName)}}" will be removed permanently. Do you want to proceed? </h2>
  </div>
  <div class="warning" *ngIf="!showSpinner">
    <h2 class="delete-msg"> This operation cannot be undone. </h2>
  </div>
  <div class="mdl-dialog__actions" style="margin-top: 15px; margin-bottom: 15px;" *ngIf="!showSpinner">
    <button class="btn btn-primary dele-btn" type="button" (click)="deleteKeypair()"><h2 class="del-text">Delete</h2></button>
    <button class="btn  can-btn" type="button"  (click)="cancel()"><h2 class="cal-text">Cancel</h2></button>
  </div>
</div>

<div *ngIf="isStudyDelete">
  <div class="dialog-header">
    <img class="cancel-icon" (click)="cancel()" src="../../assets/images/cancel@2x.png" alt="Cancel"/>
    <h3 class="title">Delete Study</h3>
  </div>
  <div style="display: flex; flex-direction: row; margin-top: 0px;">
    <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
  </div>
  <app-loader class="spinner" *ngIf="showSpinner"></app-loader>
  <div class="content" *ngIf="!showSpinner">
    <h2 class="content-1" title="{{keypairName}}">This study will be removed permanently. Do you want to proceed? </h2>
  </div>
  <div class="warning" *ngIf="!showSpinner">
    <h2 class="delete-msg"> This operation cannot be undone. </h2>
  </div>
  <div class="mdl-dialog__actions" style="margin-top: 15px; margin-bottom: 15px;" *ngIf="!showSpinner">
    <button class="btn btn-primary dele-btn" type="button" (click)="deleteStudy()"><h2 class="del-text">Delete</h2></button>
    <button class="btn  can-btn" type="button"  (click)="cancel()"><h2 class="cal-text">Cancel</h2></button>
  </div>
</div>

<div *ngIf="isInternalStudyDelete">
  <div class="dialog-header">
    <img class="cancel-icon" (click)="cancel()" src="../../assets/images/cancel@2x.png" alt="Cancel"/>
    <h3 class="title">Delete Study <h3 class="content-2">"{{studyName}}"</h3></h3>
  </div>
  <div style="display: flex; flex-direction: row; margin-top: 0px;">
    <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
  </div>
<div *ngIf="projectArray.length != 0">
  <div class="content">
    <h2 class="content-1" title="{{studyName}}" *ngIf="projectArray.length !== 0">
      Some projects are using this study. If you delete this study, those projects will lose access to the data.
    </h2>
    <h2 class="content-1">
      Please select the check box below to confirm that you want to delete the study!
    </h2>
  </div>
</div>
  <div class="content">
    <input type="checkbox" aria-label="conformationCheckBoxForDeleteStudy" name="confirm" id="1" (change)="confirmDeleteBox()">
    <label style="color:white; font-size: 15px; margin-left: 5px;">Are you sure you want to delete study?</label>
  </div>
<div class="mdl-dialog__actions" style="margin-top: 15px;margin-bottom: 15px;">
  <button class="btn btn-primary dele-btn" type="button" (click)="deleteInternalStudy()" [disabled]="!confirmBox " ><h2 class="del-text">Delete<i *ngIf="showSpinner" class="fa fa-refresh fa-spin"></i></h2></button>
  <button class="btn  can-btn" type="button"  (click)="cancel()"><h2 class="cal-text">Cancel</h2></button>
</div>
</div>

<div *ngIf="isExternalStudyDelete">
  <div class="dialog-header">
    <img class="cancel-icon" (click)="cancel()" src="../../assets/images/cancel@2x.png" alt="Cancel"/>
    <h3 class="title">Delete Study <h3 class="content-2">"{{studyName}}"</h3></h3>
  </div>
  <div style="display: flex; flex-direction: row; margin-top: 0px;">
    <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
  </div>
  <div *ngIf="projectArray.length != 0">
    <div class="content">
      <h2 class="content-1" title="{{studyName}}" *ngIf="projectArray.length !== 0">
        Some projects are using this study. If you delete this study, those projects will lose access to the data.
      </h2>
      <h2 class="content-1">
        Please select the check box below to confirm that you want to delete the study!
      </h2>
    </div>  
  </div>
    <div class="content">
      <input type="checkbox" aria-label="conformationCheckBoxForDeleteExternalStudy" name="confirm" id="1" (change)="confirmDeleteBox()">
      <label style="color:white; font-size: 15px; margin-left: 5px;">Are you sure you want to delete study?</label>
    </div>
    <div class="mdl-dialog__actions" style="margin-top: 15px;margin-bottom: 15px;">
      <button class="btn btn-primary dele-btn" type="button" (click)="deleteExternalStudy()" [disabled]="!confirmBox" ><h2 class="del-text">Delete<i *ngIf="showSpinner" class="fa fa-refresh fa-spin"></i></h2></button>
      <button class="btn  can-btn" type="button"  (click)="cancel()"><h2 class="cal-text">Cancel</h2></button>
    </div>
  
</div>