import {Pipe, PipeTransform} from '@angular/core';
@Pipe ({
   name : 'filterProduct'
})
export class ProductsPipe implements PipeTransform {
   transform(val : Array<String>) : String {
       if(val) {
        let value;
        if(val.length > 1){
         value = val[0] + " and " + (val.length - 1) + " others"
        }else {
         value = val[0]
        }
       return value;
       }
   }
}