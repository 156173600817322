import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MdlDialogReference } from '@angular-mdl/core';
import { ResearchService } from "../../_services/research.service";
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import {Globals} from "../../globals";
import { DashboardService } from "../../_services/dashboard.service";

@Component({
  selector: 'app-project-action',
  templateUrl: './project-action.component.html',
  styleUrls: ['./project-action.component.css'],
  providers: [Globals]
})
export class ProjectActionComponent implements OnInit, AfterViewInit  {
  @ViewChild('projectActionWindow') projectActionWindow: ElementRef;
  projectName:any;
  projectAction:any;
  projectStatus:any;
  private parametersObservable: any;
  loader = false;

  constructor(
    public dialogRef: MdlDialogReference,
    private serviceApi: ResearchService,
    private toastr: ToastrService,
    private router: Router,
    private globals: Globals,
    private apiService: DashboardService,
    @Inject('data') public data: any) { }

  ngOnInit() {
    this.apiService.checkIfPI();
    this.projectName = this.data.projectName;
    this.projectAction = this.data.action;
    this.projectStatus = this.data.projectStatus;
  }

  ngAfterViewInit() {
    setTimeout(() => this.projectActionWindow.nativeElement.focus(), 0);
  }

  cancel(): void {
    this.dialogRef.hide();
  }

   projectActions(){
     this.loader = true;
    const projData = {
      "projectId":this.data.projectId,
      "projectName":this.data.projectName,
      "action":this.data.action,
      "projectStatus": this.data.projectStatus,
      "accountId": this.data.accountId
    }
    this.serviceApi.updateProjectStatus(projData).subscribe(
      (res:any) => {
        this.dialogRef.hide();
        this.loader = false;
        this.router.navigate([`/project-details/${this.data.projectId}`],{ queryParams: { showTab:'projectDetails', projectAction: this.data.action}});
        this.toastr.success(res.msg, 'Principal project action :', this.globals.tosterOverride);
      }, (error) => {
        this.dialogRef.hide();
        this.loader = false;
        let errBody = error.error;
        if(error && error.status === 401){
           this.toastr.error(errBody.err, '', this.globals.tosterOverride);
           this.router.navigate(['login']);
         }else{    
           // this.router.navigate(['principal/error/err']);
        this.toastr.error(errBody.error, 'Principal project action :', this.globals.tosterOverride);
      }
      });
  }
}
