import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
// import 'rxjs/add/operator/filter';
// import 'rxjs/add/operator/pairwise';

@Injectable()
export class PreviousRouteService {

  private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public previousUrl$: Observable<string> = this.previousUrl.asObservable();
  constructor() { }

  setPreviousUrl(previousUrl: string) {
    this.previousUrl.next(previousUrl);
  }
}