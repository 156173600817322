import { Injectable } from '@angular/core';

@Injectable()
export class ProductUtilService {

  menuContainer: any = [
    { name: 'ec2', menu: ['SSH/RDP', 'Start', 'Stop', 'Reboot'] },
    { name: 'vm', menu: ['SSH/RDP', 'Start', 'Stop', 'Reboot'] },
    { name: 'cromwell', menu: ['SSH/RDP', 'Start', 'Stop', 'Reboot'] },
    { name: 'rstudio', menu: ['SSH/RDP', 'Open Link', 'Start', 'Stop', 'Reboot'] },
    { name: 'azure-rstudio', menu: ['SSH/RDP', 'Open Link', 'Start', 'Stop', 'Reboot'] },
    { name: 'nextflow', menu: ['SSH/RDP', 'Open Link', 'Start', 'Stop', 'Reboot'] },
    { name: 'sagemaker', menu: ['Open Notebook', 'Start', 'Stop'] },
    { name: 'azure-ml', menu: ['Open Notebook', 'Start', 'Stop'] },
    { name: 'workspace', menu: ['Link', 'Start', 'Stop', 'Restart'] },
    { name: 's3', menu: ['Upload', 'Explore', 'Share', 'Unshare'] },
    { name: 'blob', menu: ['Upload', 'Explore', 'Share', 'Unshare'] },
    { name: 'wordpress', menu: ['Website-URL'] },
    { name: 'pcluster', menu: ['Start','SSH to Pcluster LauncherNode','SSH to Pcluster HeadNode', 'Stop', 'Reboot'] },
    { name: 'ec2-dcv', menu: ['SSH/RDP', 'Remote Desktop', 'Start', 'Stop', 'Reboot'] },    
    { name: 'ec2-igv', menu: ['SSH/RDP', 'Remote Desktop', 'Start', 'Stop', 'Reboot'] },
    { name: 'ec2-secure-desktop', menu: ['Remote Desktop', 'Start', 'Stop', 'Reboot'] },
    { name: 'ec2-vscode', menu: ['SSH/RDP', 'Open Link', 'Start', 'Stop', 'Reboot'] },
    { name: 'ec2-jupyterlab', menu: ['SSH/RDP', 'Open Link', 'Start', 'Stop', 'Reboot'] },
    { name: 'ec2-spyder', menu: ['Remote Desktop', 'Start', 'Stop', 'Reboot'] },
  ];

  imageContainer: any = [
    { name: 'study', url: '/assets/images/aws_icon/study.png' },
    { name: 'sagemaker', url: '/assets/images/aws_icon/sagemaker.png' },
    { name: 'azure-ml', url: '/assets/images/azure_icon/azure-ml.png' },
    { name: 'emr', url: '/assets/images/aws_icon/emr.png' },
    { name: 'rds', url: '/assets/images/aws_icon/rds.png' },
    { name: 'machinelearning', url: '/assets/images/aws_icon/machinelearning.png' },
    { name: 'azure-rstudio', url: '/assets/images/azure_icon/azure.png' },
    { name: 'hpc', url: '/assets/images/aws_icon/openhpc.png' },
    { name: 'workbench', url: '/assets/images/aws_icon/sagemaker.png' },
    { name: 'vpn', url: '/assets/images/aws_icon/vpn.png' },
    { name: 'vpc', url: '/assets/images/aws_icon/vpc.png' },
    { name: 'dynamodb', url: '/assets/images/aws_icon/dynamodb.png' },
    { name: 's3', url: '/assets/images/aws_icon/s3.png' },
    { name: 'blob', url: '/assets/images/azure_icon/azure.png' },
    { name: 'cromwell', url: '/assets/images/aws_icon/cromwell.png' },
    { name: 'workspace', url: '/assets/images/aws_icon/workspaces.png' },
    { name: 'rlcatalyst', url: '/assets/images/logo_rl.png' },
    { name: 'catalyst', url: '/assets/images/logo_rl.png' },
    { name: 'ec2', url: '/assets/images/aws_icon/ec2.png' },
    { name: 'vm', url: '/assets/images/azure_icon/azure.png' },
    { name: 'office', url: '/assets/images/aws_icon/ms_office.png' },
    { name: 'oracle', url: '/assets/images/aws_icon/oracle.png' },
    { name: 'windows', url: '/assets/images/aws_icon/windows.png' },
    { name: 'cisco', url: '/assets/images/aws_icon/cisco.png' },
    { name: 'aws', url: '/assets/images/aws_icon/aws.png' },
    { name: 'linux', url: '/assets/images/aws_icon/linux.png' },
    { name: 'wordpress', url: '/assets/images/aws_icon/wordpress.png' },
    { name: 'cloud9-ide', url: '/assets/images/aws_icon/cloud9.png' },
    { name: 'rdp', url: '/assets/images/aws_icon/rdp.png' },
    { name: 'appstream', url: '/assets/images/aws_icon/appStream.png' },
    { name: 'chime', url: '/assets/images/aws_icon/chime.png' },
    { name: 'connect', url: '/assets/images/aws_icon/connect.png' },
    { name: 'redshift', url: '/assets/images/aws_icon/redshift.png' },
    { name: 'controltower', url: '/assets/images/aws_icon/controltower.png' },
    { name: 'docker', url: '/assets/images/aws_icon/docker.png' },
    { name: 'drupal', url: '/assets/images/aws_icon/drupal.png' },
    { name: 'emailexchange', url: '/assets/images/aws_icon/emailexchange.png' },
    { name: 'instancescheduler', url: '/assets/images/aws_icon/instancescheduler.png' },
    { name: 'mongodb', url: '/assets/images/aws_icon/mongodb.png' },
    { name: 'moodle', url: '/assets/images/aws_icon/moodle.png' },
    { name: 'sharepoint', url: '/assets/images/aws_icon/sharepoint.png' },
    { name: 'shibboleth', url: '/assets/images/aws_icon/shibboleth.png' },
    { name: 'tableau', url: '/assets/images/aws_icon/tableau.png' },
    { name: 'pcluster', url: '/assets/images/aws_icon/Parallel-Cluster@5x.png' },
    { name: 'ec2-dcv', url: '/assets/images/aws_icon/ec2-dcv.png' },
    { name: 'fsxforlustre', url: '/assets/images/aws_icon/FSXForLustre.png' },
    { name: 'efs', url: '/assets/images/aws_icon/efs@5x.png'},
    { name: 'amazonebsvolume', url: '/assets/images/aws_icon/Elastic-Block-Store.png'},
    { name: 'ec2-igv', url: '/assets/images/igv_icon.png'},
    { name: 'ec2-secure-desktop', url: '/assets/images/Double-Shield.svg'},
    { name: 'ec2-vscode', url: '/assets/images/vscode.svg'},
    { name: 'ec2-jupyterlab', url: '/assets/images/jupyterLab.svg'},
    { name: 'ec2-spyder', url: '/assets/images/spyder.png'},
  ];

  constructor() { }
  //Tags input should be array
  getServiceFromTags(tags){
    if(tags.length !== 0){
      const serviceObj = tags.filter((tag) => {
        if(tag.Key === 'Service'){
          return tag;
        }
      });
      if(serviceObj.length > 0){
        return serviceObj[0].Value.toLowerCase();
      }else{
        return null;
      }
    }else{
      return null;
    }
  }

  getMenuForProduct(service){
    return this.menuContainer.find(img => {
      const regex = new RegExp(`^${img.name}$`, 'i');
      const match = regex.exec(service);
      if (match) if (match[0]) return img.name == match[0];
    });
  }

  getImageForProduct(service){
    return this.imageContainer.find(img => {
      const regex = new RegExp(`^${img.name}$`, 'i');
      const match = regex.exec(service);
      if (match) if (match[0]) return img.name == match[0];
    });
  }

  checkIfUserIsPartOfProject(name, researcherList) {
    let isPresent = false;
    for ( const researcher of researcherList){
      if ( researcher === name ) {
        isPresent = true;
      }
    }
    return isPresent;
  }

  dataTrim(data, dataLength) {
    if (!data) {
      return data;
    }
    const getLen = data.length;
    if (getLen > dataLength) {
      data = data.slice(0, dataLength - 3) + '...';
    }
    return data;
  }
}
