import { Component, OnInit } from '@angular/core';
import {ResearchService} from "../_services/research.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ConfirmationDialog} from "../dialog/dialog.component";
import {MdlDialogService} from "@angular-mdl/core";
import { ServiceActionsService } from "../_services/service-actions.service";
import { Globals } from "../globals";
import { ToastrService } from 'ngx-toastr';
import { ProductUtilService } from "../_services/productUtil.service";
import { DashboardService } from "../_services/dashboard.service";

@Component({
  selector: 'app-active-product',
  templateUrl: './active-product.component.html',
  styleUrls: ['./active-product.component.css', '../app.component.css'],
  providers: [Globals]
})
export class ActiveProductComponent implements OnInit {
  loader: boolean = true;
  accountId;
  category;
  categoryName = 'Available Products';
  availableProductsList;
  activeProductsList = [];
  projectName;
  projectId;
  selectedProduct;
  totalPages;
  totalProducts = 0;
  activeProductCount = 0;
  currentPage = 1;
  pageSize = 12;
  currentPageLowerCount = 1;
  currentPageUpperCount = 12;
  searchKey;
  searchExecKey = '';
  isCardView = true;
  isSearchedList = false;
  imageContainer: any = [
    { name: 'sagemaker', url: '/assets/images/aws_icon/sagemaker.png' },
    { name: 'emr', url: '/assets/images/aws_icon/emr.png' },
    { name: 'rds', url: '/assets/images/aws_icon/rds.png' },
    { name: 'machinelearning', url: '/assets/images/aws_icon/machinelearning.png' },
    { name: 'azure-ml', url: '/assets/images/azure_icon/azure-ml.png' },
    { name: 'azure-rstudio', url: '/assets/images/azure_icon/azure.png' },
    { name: 'hpc', url: '/assets/images/aws_icon/openhpc.png' },
    { name: 'workbench', url: '/assets/images/aws_icon/sagemaker.png' },
    { name: 'vpn', url: '/assets/images/aws_icon/vpn.png' },
    { name: 'vpc', url: '/assets/images/aws_icon/vpc.png' },
    { name: 'dynamodb', url: '/assets/images/aws_icon/dynamodb.png' },
    { name: 's3', url: '/assets/images/aws_icon/s3.png' },
    { name: 'blob', url: '/assets/images/azure_icon/azure.png' },
    { name: 'workspace', url: '/assets/images/aws_icon/workspaces.png' },
    { name: 'rlcatalyst', url: '/assets/images/logo_rl.png' },
    { name: 'catalyst', url: '/assets/images/logo_rl.png' },
    { name: 'ec2', url: '/assets/images/aws_icon/ec2.png' },
    { name: 'vm', url: '/assets/images/azure_icon/azure.png' },
    { name: 'office', url: '/assets/images/aws_icon/ms_office.png' },
    { name: 'oracle', url: '/assets/images/aws_icon/oracle.png' },
    { name: 'windows', url: '/assets/images/aws_icon/windows.png' },
    { name: 'cisco', url: '/assets/images/aws_icon/cisco.png' },
    { name: 'aws', url: '/assets/images/aws_icon/aws.png' },
    { name: 'linux', url: '/assets/images/aws_icon/linux.png' },
    { name: 'wordpress', url: '/assets/images/aws_icon/wordpress.png' },
    { name: 'cloud9-ide', url: '/assets/images/aws_icon/cloud9.png' },
    { name: 'rdp', url: '/assets/images/aws_icon/rdp.png' },
    { name: 'appstream', url: '/assets/images/aws_icon/appStream.png' },
    { name: 'chime', url: '/assets/images/aws_icon/chime.png' },
    { name: 'connect', url: '/assets/images/aws_icon/connect.png' },
    { name: 'redshift', url: '/assets/images/aws_icon/redshift.png' },
    { name: 'controltower', url: '/assets/images/aws_icon/controltower.png' },
    { name: 'docker', url: '/assets/images/aws_icon/docker.png' },
    { name: 'drupal', url: '/assets/images/aws_icon/drupal.png' },
    { name: 'emailexchange', url: '/assets/images/aws_icon/emailexchange.png' },
    { name: 'instancescheduler', url: '/assets/images/aws_icon/instancescheduler.png' },
    { name: 'mongodb', url: '/assets/images/aws_icon/mongodb.png' },
    { name: 'moodle', url: '/assets/images/aws_icon/moodle.png' },
    { name: 'sharepoint', url: '/assets/images/aws_icon/sharepoint.png' },
    { name: 'shibboleth', url: '/assets/images/aws_icon/shibboleth.png' },
    { name: 'tableau', url: '/assets/images/aws_icon/tableau.png' }
  ];

  page = 1;
  limit = 12;
  logLoader = true;
  auditTrail = [];
  loadMore = false;
  provisionIdForLog;
  servicemenu;
  projectStatus;
  searchValue = false;
  searchKeyword = false;
  searchValueTemp = '';
  noDataTemplate;
  noDataMsg;
  filterList = [ 'All', 'Active', 'Terminated']
  categoryFilter = 'Active'; 
  filterBy;
  placeHolder;
  getAllProducts = false;

  constructor(private researchService: ResearchService,
              private router: Router,
              private serviceAction: ServiceActionsService,
              private dialog: MdlDialogService,
              private globals: Globals,
              private toastr: ToastrService,
              private route: ActivatedRoute,
              private productUtil: ProductUtilService,
              private apiService: DashboardService) { }

  ngOnInit() {
    document.querySelector('.custom-select-wrapper').addEventListener('click', function() {
      this.querySelector('.custom-select').classList.toggle('open');
    })
    window.addEventListener('click', function(e) {
      const select = document.querySelector('.custom-select')
      if (select && !select.contains(e.target as Node)) {
          select.classList.remove('open');
      }
    });
    // mobile responsive dropdown
    document.querySelector('.mobile-custom-select-wrapper').addEventListener('click', function() {
      this.querySelector('.mobile-custom-select').classList.toggle('open');
    })
    window.addEventListener('click', function(e) {
      const select = document.querySelector('.mobile-custom-select')
      if (select && !select.contains(e.target as Node)) {
          select.classList.remove('open');
      }
    });

    this.apiService.checkIfResearcher();
    this.selectedProduct = '';
    this.category = this.route.snapshot.params['category'];
    this.accountId = this.route.snapshot.params['accountId'];
    // this.projectName = this.route.snapshot.params['projectName'];
    this.projectName = sessionStorage.getItem('projectName');
    this.projectId = sessionStorage.getItem('projectId');
    this.route.queryParams.subscribe(map => map);
    this.projectStatus = this.route.snapshot.queryParams['projectStatus'];
    this.initProduct();
  }

  openProductDetails(activeProduct) {
    this.router.navigate(['/product/' + this.projectName + '/' +
    activeProduct.ProvisionedProductName, { ppId: activeProduct.ProvisionedProductId }],{ queryParams: { projectStatus: this.projectStatus}});
  }

  private initProduct() {
    if (this.category === 'active') {
      this.categoryName = 'My Products';
      this.categoryFilter = sessionStorage.getItem('Selected') ?  sessionStorage.getItem('Selected') : 'Active'
      this.getAllActiveProducts(this.categoryFilter);
    } else if (this.category === 'budgetConsumed') {
      this.categoryName = 'Project Budget Consumed';
      this.getAllActiveProducts('Active');
    } else if (this.category === 'budgetAvailable') {
      this.categoryName = 'Project Budget Available';
      this.getAllActiveProducts('Active');
    } else {
      this.categoryFilter = 'All';
      this.getAllAvailableProducts('All');
    }
  }

  getNextAvailableProducts(action) {
    if (action === 'first' && this.currentPage !== 1) {
      this.currentPage = 1;
      if (this.isSearchedList) {
        this.getAllSearchAvailableProducts();
      }else {
        this.getAllAvailableProducts(this.filterBy);
      }
    }
    if (action === 'previous' && this.currentPage > 1) {
      this.currentPage = this.currentPage - 1;
      if (this.isSearchedList) {
        this.getAllSearchAvailableProducts();
      }else {
        this.getAllAvailableProducts(this.filterBy);
      }
    }
    if (action === 'next' && this.currentPage !== this.totalPages) {
      this.currentPage = this.currentPage + 1;
      if (this.isSearchedList) {
        this.getAllSearchAvailableProducts();
      }else {
        this.getAllAvailableProducts(this.filterBy);
      }
    }
    if (action === 'last' && this.currentPage !== this.totalPages) {
      this.currentPage = this.totalPages;
      if (this.isSearchedList) {
        this.getAllSearchAvailableProducts();
      }else {
        this.getAllAvailableProducts(this.filterBy);
      }
    }
  }

  getNextActiveProducts(action) {
    if (action === 'first' && this.currentPage !== 1) {
      this.currentPage = 1;
      if (this.isSearchedList) {
        this.getAllSearchActiveProducts();
      }else {
        this.getAllActiveProducts(this.filterBy);
      }
    }
    if (action === 'previous' && this.currentPage > 1) {
      this.currentPage = this.currentPage - 1;
      if (this.isSearchedList) {
        this.getAllSearchActiveProducts();
      }else {
        this.getAllActiveProducts(this.filterBy);
      }
    }
    if (action === 'next' && this.currentPage !== this.totalPages) {
      this.currentPage = this.currentPage + 1;
      if (this.isSearchedList) {
        this.getAllSearchActiveProducts();
      }else {
        this.getAllActiveProducts(this.filterBy);
      }
    }
    if (action === 'last' && this.currentPage !== this.totalPages) {
      this.currentPage = this.totalPages;
      if (this.isSearchedList) {
        this.getAllSearchActiveProducts();
      }else {
        this.getAllActiveProducts(this.filterBy);
      }
    }
  }

  private getAllAvailableProducts(filterBy) {
    this.getProductTypes();
    this.filterBy = filterBy;
    this.placeHolder = "Search available products";
    this.loader = true;
    this.researchService.getListOfResearchProducts(this.currentPage, this.pageSize, this.accountId, this.projectId, filterBy).subscribe((res:any) => {
      this.loader = false;
      this.availableProductsList = res.docs;
      this.totalProducts = res.total;
      this.totalPages = res.pages;
      if(filterBy == 'All'){
        this.noDataTemplate = "No products assigned to the project catalog"
        this.noDataMsg = "Please contact your project owner to assign products that you can use"
      }else{
        this.noDataTemplate = "We could not find any products that matched your filter"
        this.noDataMsg= "";
      }
      //Calculation for lower & upper count per page
      this.currentPageLowerCount = 1 + ((this.currentPage - 1) * this.pageSize);
      if(res.docs.length < 12){
        this.currentPageUpperCount = res.docs.length + (this.pageSize * (this.currentPage - 1));
      }else {
        this.currentPageUpperCount = this.pageSize * this.currentPage;
      }
      this.dataBindingAvailableProduct();
    }, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
});
  }

  private getAllSearchAvailableProducts() {
    this.loader = true;
    this.researchService.getListOfSearchResearchProducts(this.searchKey , this.currentPage, this.pageSize, this.accountId, this.projectId, this.filterBy).subscribe((res:any) => {
      this.loader = false;
      this.availableProductsList = res.docs;
      this.totalProducts = res.total;
      this.totalPages = res.pages;
      this.noDataTemplate = "We could not find any products that matched your search"
      this.noDataMsg= "";
      //Calculation for lower & upper count per page
      this.currentPageLowerCount = 1 + ((this.currentPage - 1) * this.pageSize);
      if(res.docs.length < 9){
        this.currentPageUpperCount = res.docs.length + (this.pageSize * (this.currentPage - 1));
      }else {
        this.currentPageUpperCount = this.pageSize * this.currentPage;
      }
      this.dataBindingAvailableProduct();
    });
  }

  private getAllSearchActiveProducts() {
    this.loader = true;
    this.getAllProducts = false;
    this.researchService.getSearchProvisionedProducts(this.currentPage, this.pageSize, this.accountId, this.projectId, this.filterBy, this.searchKey, this.getAllProducts).subscribe((res:any) => {
      this.loader = false;
      this.activeProductsList = res.docs;
      this.totalProducts = res.docs.length;
      this.totalPages = res.pages;
      this.activeProductCount= res.docs.length;
      this.noDataTemplate = "We could not find any products that matched your search"
      this.noDataMsg = "";
      //Calculation for lower & upper count per page
      this.currentPageLowerCount = 1 + ((this.currentPage - 1) * this.pageSize);
      if(res.docs.length < 9){
        this.currentPageUpperCount = res.docs.length + (this.pageSize * (this.currentPage - 1));
      }else {
        this.currentPageUpperCount = this.pageSize * this.currentPage;
      }
      this.dataBindingActiveProduct();
    }, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
});
  }

  availableSearchProduct() {
    const searchValue = this.searchKey.trim() || '' ;
    if (this.searchValueTemp === this.searchKey) {
      return;
    }
    if (searchValue.length < 3 && searchValue.length > 0) {
      return;
    }
    this.currentPage = 1;
    if (this.category === 'active') {
      if (searchValue.length > 2 && this.searchExecKey !== searchValue) {
        this.searchExecKey = searchValue;
        this.isSearchedList = true;
        this.searchValue = true;
        this.searchValueTemp = this.searchKey;
        this.getAllSearchActiveProducts();
      } else if (searchValue.length === 0 && this.searchExecKey !== searchValue) {
        this.searchExecKey = searchValue;
        this.isSearchedList = false;
        if (this.searchValue === true) {
          this.searchValueTemp = this.searchKey;
          this.getAllActiveProducts(this.filterBy);
        }
      }
    } else {
      if (searchValue.length > 2 && this.searchExecKey !== searchValue) {
        this.searchExecKey = searchValue;
        this.isSearchedList = true;
        this.searchValue = true;
        this.searchValueTemp = this.searchKey;
        this.getAllSearchAvailableProducts();
      } else if (searchValue.length === 0 && this.searchExecKey !== searchValue) {
        this.searchExecKey = searchValue;
        this.isSearchedList = false;
        if (this.searchValue === true) {
          this.searchValueTemp = this.searchKey;
          this.getAllAvailableProducts(this.filterBy);
        }
      }
    }

  }

  dataBindingAvailableProduct() {
    try {
      for (let i = 0; i < this.availableProductsList.length; i++) {
        let iName;
        this.availableProductsList[i]['imageUrl'] = '/assets/images/aws_icon/aws.png';
        if(this.availableProductsList[i].tags.length > 0){
          //iName = this.availableProductsList[i].tags[0].Value.toLowerCase();
          const serviceName = this.productUtil.getServiceFromTags(this.availableProductsList[i].tags);
          const getImgLinkObj = this.productUtil.getImageForProduct(serviceName);

          if (getImgLinkObj) {
            this.availableProductsList[i]['imageUrl'] = getImgLinkObj['url'];
          }
          // for(let tag of this.availableProductsList[i].tags){
          //   iName = tag.Value.toLowerCase();
          //   const getLinkImg = this.imageContainer.find(img => {
          //     const regex = new RegExp(`^${img.name}$`, 'i');
          //     const match = regex.exec(iName);
          //     if (match) if (match[0]) return img.name == match[0];
          //   });
          //
          //   if (getLinkImg) {
          //     this.availableProductsList[i]['imageUrl'] = getLinkImg['url'];
          //   }
          // }
          const viewMoreDetails = this.availableProductsList[i].tags.filter(tag => {
            return tag["Key"] === "DetailsLink"
          })
          if(viewMoreDetails.length > 0) {
            const viewMoreLink = viewMoreDetails[0].Value;
            this.availableProductsList[i]["detailsLink"] = viewMoreLink
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
  getProductETA(data) {
    try {
      const getETA = data.find((obj) => obj.Key === 'EstimatedTimeToProvision');
      const getETADetails = getETA['Value'] || 'NA';
      return getETADetails;
    } catch (error) {
      return 'NA';
    }
  }

  // private getAllActiveProducts() {
  //   this.loader = true;
  //   this.researchService.getProvisionedProducts(this.currentPage, this.limit, this.accountId).subscribe(res => {
  //     this.loader = false;
  //     this.searchKeyword = false;
  //     this.activeProductsList = res.docs;
  //     this.totalProducts = res.total;
  //     this.totalPages = res.pages;
  //     this.activeProductCount= res.activeProductCount;

  //     //Calculation for lower & upper count per page
  //     this.currentPageLowerCount = 1 + ((this.currentPage - 1) * this.pageSize);
  //     if(res.docs.length < 9){
  //       this.currentPageUpperCount = res.docs.length + (this.pageSize * (this.currentPage - 1));
  //     }else {
  //       this.currentPageUpperCount = this.pageSize * this.currentPage;
  //     }
  //     this.dataBindingActiveProduct();
  //   });
  // }

  getAllActiveProducts(filterBy){
    this.filterBy = filterBy;
    if(this.filterBy == 'All' || this.filterBy == 'Acive' || this.filterBy == 'Terminated') {
      sessionStorage.setItem('Selected', this.filterBy);
      }      
      if(this.category === 'active'){
      this.placeHolder = "Search in my products";
      this.loader = true;
      this.getAllProducts = false;
      this.researchService.getFilteredProvisionedProducts(this.currentPage, this.limit, this.accountId, this.projectId, filterBy, this.getAllProducts).subscribe((res:any) => {
        this.loader = false;
        this.searchKeyword = false;
        this.activeProductsList = res.docs;
        this.totalProducts = res.total;
        this.totalPages = res.pages;
        this.activeProductCount= res.total;
        if (filterBy == 'All'){
          this.noDataTemplate = "You currently don't have any products";
          this.noDataMsg = "Launch your first product from the Available Products page";
        }else{
          this.noDataTemplate = "We could not find any products that matched your filter";
          this.noDataMsg = "";
        }

        //Calculation for lower & upper count per page
        this.currentPageLowerCount = 1 + ((this.currentPage - 1) * this.pageSize);
        if(res.docs.length < 12){
          this.currentPageUpperCount = res.docs.length + (this.pageSize * (this.currentPage - 1));
        }else {
          this.currentPageUpperCount = this.pageSize * this.currentPage;
        }
        this.dataBindingActiveProduct();
      }, error => {
        this.loader = false;
        if(error && error.status === 401){
          let errBody = error.error;
           this.toastr.error(errBody.err, '', this.globals.tosterOverride);
           this.router.navigate(['login']);
         }
  });
    }else{
        this.getAllAvailableProducts(filterBy)
    }
    
  }

  loadMoreEvents(){
    this.currentPage = this.currentPage + 1;
    if(this.searchKeyword == true){
      this.getAllSearchActiveProducts()
    }else{
      this.getAllActiveProducts(this.filterBy);
    }

  }

  setPage(){
    this.currentPage = 1;
  }

  dataBindingActiveProduct() {
    for (let i = 0; i < this.activeProductsList.length; i++) {
      try {
        //const iName = this.activeProductsList[i].Tags[0].Value.toLowerCase();
        this.activeProductsList[i]['imageUrl'] = '../../assets/images/aws_icon/aws.png';
        const serviceName = this.productUtil.getServiceFromTags(this.activeProductsList[i].Tags);
        const getImgLinkObj = this.productUtil.getImageForProduct(serviceName);
        if (getImgLinkObj) {
          this.activeProductsList[i]['imageUrl'] = getImgLinkObj['url'];
        }
        // for(let tags of this.activeProductsList[i].Tags){
        //   const iName = tags.Value.toLowerCase();
        //   const getLinkImg = this.imageContainer.find(img => {
        //     const regex = new RegExp(`^${img.name}$`, 'i');
        //     const match = regex.exec(iName);
        //     if (match) if (match[0]) return img.name == match[0];
        //   });
        //   if (getLinkImg) this.activeProductsList[i]['imageUrl'] = getLinkImg['url'];
        // }
      } catch (e) {
        console.log(e);
      }
    }
  }

  convertDateToString(date) {
    if(date !== 0) {
      var timeString = new Date().setTime(date);
      var sysCreatedOn = new Date(timeString).toLocaleString();
      return sysCreatedOn;
    } else {
      return '-';
    }
  }


  menuContainer: any = [
    { name: 'ec2', menu: ['SSH/RDP', 'Start', 'Stop', 'Restart'] },
    { name: 'sagemaker', menu: ['Link', 'Start', 'Stop'] },
    { name: 'workspace', menu: ['Link', 'Start', 'Stop', 'Restart'] },
    { name: 's3', menu: ['Upload', 'Explore', 'Share'] },
  ];

  getAllProduct() {
    this.initProduct();
  }

  catalogOrder(availPObj) {
    const productId = availPObj.productId;
    const name = encodeURIComponent(availPObj.name);
    const stdCatalogItemId = availPObj.stdCatalogItemId
    localStorage.setItem(productId, JSON.stringify(availPObj));
    this.router.navigate(['/order/' + this.projectName + '/' + this.accountId + '/' + this.projectId + '/' + productId + '/' + name + '/' + stdCatalogItemId]);
  }

  deleteProvisionedProduct(ppId) {
    this.apiService.getDialogBox().subscribe((data:any) => {
      if(data.status === 200){
    this.dialog.showCustomDialog({
      component: ConfirmationDialog,
      providers: [{ provide: 'data', useValue: { deleteObj:ppId ,action: 'provisionedProduct', message: `Are you sure you want to delete ${ppId.ProvisionedProductName} product ?` } }],
      isModal: true,
      styles: {
        "height": "auto",
        "width": "40%"
      },
      clickOutsideToClose: true,
      enterTransitionDuration: 400,
      leaveTransitionDuration: 400,
    })}}, error => {
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    })
  }

  getActiveMenu(service) {

    const menuI = ['Upload', 'Explore', 'Share'];
    try {
      let data = this.selectedProduct.Tags;
      const serviceName = this.productUtil.getServiceFromTags(data);
      const getMenu = this.productUtil.getMenuForProduct(serviceName);
      if (service === "menu") {
        return getMenu.menu;
      }
      if(getMenu && 'name' in getMenu){
        return getMenu.name;
      }
      // const itemValue = data[0].Value.toLowerCase();
      // const getMenu = this.menuContainer.find(img => {
      //   const regex = new RegExp(img.name, 'g');
      //   const match = regex.exec(itemValue);
      //   if (match) if (match[0]) return img.name == match[0];
      // });
      // if(service === "menu"){
      //   return getMenu.menu;
      // }else{
      //   return getMenu.name;
      // }
    } catch (e) {
      return  ['Please select one product'];
    }
  }

  getMenuForService(){
    let body = {
      id: this.selectedProduct.ProvisionedProductId
    };

    this.serviceAction.getActionsMenuForService(body).subscribe((res:any) => {
      console.log(res);
      if(res.status === "success"){
        this.servicemenu =  [].concat(res.actions.connect, res.actions.action);
      }else{
        this.toastr.info(res.message, `Product Actions`, this.globals.tosterOverride)
      }
    }, (error) => {
      let errBody = error.error;
      if(error && error.status === 401){
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }else
      this.toastr.error(errBody.error, 'Error', this.globals.tosterOverride);
    })
  }

  actions(action){
    let service = this.getActiveMenu( "");
    let body = {
      "service": service.toLowerCase(),
      "action": action.toLowerCase(),
      ProvisioningParameters: this.selectedProduct.ProvisioningParameters,
      "account_id": this.selectedProduct.account_id
    };
    switch (service){
      case 'sagemaker':
        this.sagemakerActions(body, this.selectedProduct);
        break;
      default:
        break
    }

  }

  sagemakerActions(body, provProduct){
    this.serviceAction.serviceAction(body).subscribe((res:any) => {
      switch (body.action.toLowerCase()){
        case "link":
          window.open(res.AuthorizedUrl);
          break;
        case "start":
          this.toastr.info(`${provProduct.ProvisionedProductName} start process initiated successfully`, 'Active Products', this.globals.tosterOverride);
          break;
        case 'stop':
          this.toastr.info(`${provProduct.ProvisionedProductName} stop process initiated successfully`, 'Active Products', this.globals.tosterOverride);
          break;
        default:
          break
      }
    }, error => {
      let errBody = error.error;
      if(error && error.status === 401){
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
       }else {
        this.toastr.error(errBody.error, 'Error', this.globals.tosterOverride);
      }
    });
  }

  selectProduct(provProduct){
    this.selectedProduct = provProduct;
  }

  openKnowMore(detailsLink) {
    if(detailsLink) {
      window.open(detailsLink, '_blank');
    }else {
      return
      // window.open("#", '_blank');
    }
  }

  getStatus(status) {
    let statusV = status;
    let state = statusV.toUpperCase();
    switch (state) {
      case 'INITIATED':
        statusV = "Initiated";
        break;
      case 'CREATE_IN_PROGRESS':
        statusV = 'Creating';
        break;
      case 'CREATE_COMPLETE':
        statusV = 'Provisioned';
        break;
      case 'CREATE_FAILED':
        statusV = 'Failed';
        break;
      case 'ROLLBACK_IN_PROGRESS':
        statusV = 'Failed';
        break;
      case 'ROLLBACK_COMPLETE':
        statusV = 'Failed';
        break;
      case 'CREATED':
        statusV = 'Provisioned';
        break;
      case 'AVAILABLE':
        statusV = 'Provisioned';
        break;
      case 'ERROR':
        statusV = 'Failed';
        break;
      case 'DELETE_IN_PROGRESS':
        statusV = 'Terminating';
        break;
      case 'DELETE_COMPLETE':
        statusV = 'Terminated';
        break;
      default :
        break;
    }
    return statusV;
  }

  getStatusColor(status) {
    let statusV = 'status-paused';
    switch (status.toLowerCase()) {
      case 'active':
        statusV = 'status-running';
        break;
      case 'warning':
        statusV = 'status-paused';
        break;
      case 'failed':
        statusV = 'status-stopped';
        break;
      case 'create_in_progress':
        statusV = 'status-paused';
        break;
      case 'create_complete':
        statusV = 'status-running';
        break;
      case 'create_failed':
        statusV = 'status-stopped';
        break;
      case 'rollback_complete':
        statusV = 'status-stopped';
        break;
      case 'available':
        statusV = 'status-running';
        break;
      case 'created':
        statusV = 'status-running';
        break;
      case 'error':
        statusV = 'status-stopped';
        break;
      default :
        statusV = 'status-paused';
        break;
    }
    return statusV;
  }

  loadNextPage() {

  }

  nameTrim(data) {
    if (!data) {
      return data;
    }
    const getLen = data.length;
    if (getLen > 24) {
      data = data.slice(0, 24) + '...';
    }
    return data;
  }

  nameTrimData(data) {
    if (!data) {
      return data;
    }
    const getLen = data.length;
    if (getLen > 30) {
      data = data.slice(0, 30) + '...';
    }
    return data;
  }

  getProductTypes() {
    this.researchService.getListOfProductTypes().subscribe((res:any) => {
      this.filterList = res;
    }, error => {
      console.error(error);
      if(error && error.status === 401){
        let errBody= error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
      //this.toastr.error(`${this.bucketName} - `+err.error, 'Error', this.globals.tosterOverride)
    }
    )
  }

  getFilterName(data){
    if (!data) {
      return data;
    }
    const getLen = data.length;
    if (getLen > 10) {
      data = data.slice(0, 10) + '...';
    }
    return data;
  }

  navigateToProject(){
    const projectName = encodeURIComponent(this.projectName);
    sessionStorage.setItem('projectName', this.projectName);
    this.router.navigate(['/catalog/' + projectName + '/' + this.accountId ]);
  }
}
