<div id="popupBoxId" #AddUserAction tabindex="-1" >
  <div class="dialog-header">
    <img class="cancel-icon" tabindex="0" (click)="cancel()" (keypress)="cancel()" src="../../assets/images/cancel@2x.png" alt="Cancel"/>
    <h2 class="modal-title title-header" id="myModalLabel" class="Header">
    Update Network Details
    </h2>       
  </div>    
  <div style="display: flex; flex-direction: row; margin-top: 10px;">
    <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>    
  </div>
  <form #f="ngForm" class="form-wrapper" novalidate (ngSubmit)="f.valid" >
    <span class="helpText">
      Enter the information about your network. You can find these details in the 'Outputs' section of your CloudFormation stack that created the network stack.
    </span>
    <div class="col-lg-6 col-md-6" style="margin-top: 10px">
      <label class="formLabel">VPC</label>
      <span class="order-st">*</span>
      <div class="">
        <input class="form-control" aria-label="vpcInputValue" id="VPCId" required minlength="1" maxlength="21" name="vpc" 
          placeholder="Please enter VPC" style="color: #ffffff" type="text" [(ngModel)]="vpc" (keyup)="checkVPC($event)" 
          (keydown.space)="$event.preventDefault()">
      </div>
    </div>
    <div class="col-lg-12 col-md-12">
      <div class="row">
        <div class="col-lg-6 col-md-6" style="margin-top: 10px">
          <label class="formLabel">PublicSubnet1</label>
          <span class="order-st">*</span>
          <div class="">
            <input  class="form-control" aria-label="PublicSubnet1InputValue" id="PublicSubnet1Id" minlength="1" maxlength="24" name="PublicSubnet1"
              placeholder="Please enter PublicSubnet1 value" [(ngModel)]="publicSubnet1" required style="color: #ffffff" 
              type="text" (keyup)="checkPublicSubnet1($event)" (keydown.space)="$event.preventDefault()">
          </div>
        </div>
        <div class="col-lg-6 col-md-6" style="margin-top: 10px">
          <label class="formLabel">PublicSubnet2</label>
          <span class="order-st">*</span>
          <div class="">
            <input class="form-control" aria-label="PublicSubnet2InputValue" id="PublicSubnet2Id" minlength="1" maxlength="24" name="PublicSubnet2"
             [(ngModel)]="publicSubnet2" placeholder="Please enter PublicSubnet2 value" required style="color: #ffffff" 
             type="text" (keyup)="checkPublicSubnet2($event)" (keydown.space)="$event.preventDefault()">
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12 col-md-12">
      <div class="row">
        <div class="col-lg-6 col-md-6" style="margin-top: 10px">
          <label class="formLabel">PrivateSubnet</label>
          <span class="order-st">*</span>
          <div class="">
            <input  class="form-control" aria-label="privateSubnet1InputValue" id="PrivateSubnetId" minlength="1" maxlength="24" name="PrivateSubnet"
              placeholder="Please enter Private Subnet value" [(ngModel)]="privateSubnet" required style="color: #ffffff" 
              type="text" (keyup)="checkPrivateSubnet($event)" (keydown.space)="$event.preventDefault()">
          </div>
        </div>
        <div class="col-lg-6 col-md-6" style="margin-top: 10px">
          <label class="formLabel">EntryPointSG</label>
          <span class="order-st">*</span>
          <div class="">
            <input class="form-control" aria-label="entryPointSGInputValue" id="EntryPointSGId"  minlength="1" maxlength="20" name="EntryPointSG"
              placeholder="Please enter Entry Point SG value" [(ngModel)]="entryPointSG" required style="color: #ffffff"
              type="text" (keyup)="checkEntryPointSG($event);" (keydown.space)="$event.preventDefault()">
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12 col-md-12">
      <div class="row">
        <div class="col-lg-6 col-md-6" style="margin-top: 10px">
          <label class="formLabel">WorkspaceSG</label>
          <span class="order-st">*</span>
          <div class="">
            <input  class="form-control" aria-label="workspaceSGInputValue" id="WorkspaceSGId" minlength="1" maxlength="20" name="WorkspaceSG" type="text"
              placeholder="Please enter Workspace SG value" [(ngModel)]="workspaceSG" required style="color: #ffffff" 
              (keyup)="checkWorkspaceSG($event)" (keydown.space)="$event.preventDefault()">
          </div>
        </div>
        <div class="col-lg-6 col-md-6" style="margin-top: 10px">
          <label class="formLabel">InterfaceEndpointSG</label>
          <span class="order-st">*</span>
          <div class="">
            <input class="form-control" aria-label="interfaceEndpointSGInputValue" id="InterfaceEndpointSGId" minlength="1" maxlength="20" name="InterfaceEndpointSG" 
              placeholder="Please enter Interface Endpoint SG value" [(ngModel)]="interfaceEndpointSG" style="color: #ffffff"
              required type="text" (keyup)="checkInterfaceEndpointSG($event)" (keydown.space)="$event.preventDefault()">
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12 col-md-12 " style="margin-top: 20px;text-align: center; margin-bottom: 15px;">
      <button class="btn btn-primary sub-btn" id="buttonId" type="submit" [disabled]="!(f.valid && !disable)" (click)="updateSettings()">  Update </button>
    </div>
  </form>
</div>