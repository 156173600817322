<div class="container">
  <!-- breadcrumb -->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb bc-custom">
      <li class="breadcrumb-item"><a class="bc-item-active">Studies</a></li>
    </ol>
  </nav>
  <div [ngClass]="defaultHelpClass">
    <h3 class="header-title">Studies <h3 *ngIf="totalDoc">&nbsp;( {{totalDoc}} )</h3>
    </h3>
    <div style="display: flex;">
      <div style="display: flex;" [ngClass]="defaultClass">
        <div style="display: flex;">
          <em class="glyphicon glyphicon-search search-icon"></em>
          <input (keyup)="searchStudies()" [(ngModel)]="searchKey" aria-label="searchStudies" class="form-control search-inp search-inp-add"
            placeholder="Search Studies" type="text">
        </div>
        <div style="display: flex; align-items: center;">
          <div class="vertical-divider"></div>
          <div class="custom-select-wrapper">
            <div class="custom-select">
              <div class="custom-select__trigger"><span>{{selectedFilter[0].toUpperCase() + selectedFilter.substring(1)}}</span>
                <div class="arrow"></div>
              </div>
              <div class="custom-options">
                <span class="custom-option" [ngClass]="{'selected': filter.value === selectedFilter}"
                  *ngFor="let filter of filters" data-value="filter.value"
                  (click)="onChangeFilter(filter)">{{filter.value[0].toUpperCase() + filter.value.substring(1)}}</span>
              </div>
            </div>
          </div>
          <div class="mdl-dialog__actions" *ngIf="userType=='principal'" style="margin-top:15px;margin-bottom: 10px;">
            <!-- <button class="sub-btn" name="button" type="submit" (click)="createStudy()" style="cursor:hand">
              <h2 class="sub-text">Create Study</h2>
              <div class="arrow" style="padding-right:20px ;"></div>
            </button> -->
            <div class="dropdown">
              <button type="button"
                      style="cursor:hand"
                  class="sub-btn"
                  title="Add Settings" data-toggle="dropdown">
                <h2 class="sub-text">Create Study</h2>
                <div class="arrow" style="padding-right:20px ;"></div>
              </button>
              <ul class="dropdown-menu dropdown-content" style="cursor: pointer;
              background: rgb(7, 16, 42);
              position: absolute;
              will-change: transform;top: 0px;
               left: -45px !important;
              transform: translate3d(-58px, 17px, 0px); ">
                  <li><a class="listItems" (click)="createStudy('AWS')"> AWS
                          </a>
                  </li>
                  <li><a class="listItems" (click)="createStudy('AZURE')"> Azure
                          </a></li>
              </ul>

          </div>
          </div>
        </div>
      </div>
      <!-- <div class="create-study-container" (click)="showToast()">
        <div class="create-study-select-wrapper">
          <div class="create-study-select">
              <div class="create-study__trigger"><span style="margin-right: 20px;">Create Study</span>
                  <div class="arrow"></div>
              </div>
              <div class="custom-options">
                  <span class="custom-option selected" data-value="personal">Personal</span>
                  <span class="custom-option" data-value="public">Public</span>
                  <span class="custom-option" data-value="bookmarked">Bookmarked</span>
              </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
  <div class="divider"></div>
  <!-- mobile responsive -->
  <div class="mobile-divider"></div>
  <div [ngClass]="defaultSearchClass">
    <div style="display: flex;">
      <em class="glyphicon glyphicon-search search-icon"></em>
      <input (keyup)="searchStudies()" [(ngModel)]="searchKey" aria-label="searchStudiesMobileView" class="form-control search-inp"  [ngClass]="defaultSearchInputClass"
        placeholder="Search Studies" type="text">
    </div>
    <div style="display: flex; align-items: center;">
      <div class="vertical-divider"></div>
      <div class="mobile-custom-select-wrapper">
        <div class="mobile-custom-select">
          <div class="mobile-custom-select__trigger"><span>{{selectedFilter}}</span>
            <div class="mobile-arrow"></div>
          </div>
          <div class="mobile-custom-options">
            <span class="mobile-custom-option" [ngClass]="{'selected': filter.value === selectedFilter}"
              *ngFor="let filter of filters" data-value="filter.value"
              (click)="onChangeFilter(filter)">{{filter.value[0].toUpperCase() + filter.value.substring(1)}}</span>
          </div>
        </div>
      </div>
      <div class="mdl-dialog__actions" *ngIf="userType=='principal'" style="margin-top:15px;margin-bottom: 10px;">
        <button class="sub-btn" name="button" type="submit" (click)="createStudy('Aws')" style="cursor:hand">
          <h2 class="sub-text">Create Study</h2>
          <div class="arrow" style="padding-right:20px ;"></div>
        </button>
      </div>
    </div>
  </div>
  <div class="studies-container">
    <div class="col-md-6 col-sm-12 item" *ngFor="let study of studies">
      <div class="study-card" (click)="openStudyDetails(study)">
        <h3 class="study-name font-class">{{study.name}}</h3>
        <p class="study-description text-white font-class line-clamp">{{study.description}}</p>
        <div class="tags-wrapper">
          <div class="tag-content" *ngFor="let tag of study.showTags">
            <span class="tag-txt" title={{tag}}>{{tag}}</span>
          </div>
          <span class="more-tag-content more-tag-txt" *ngIf="study.moreTagsCount">+ {{study.moreTagsCount}} more
            tags</span>
        </div>
        <div class="card-footer">
          <div class="study-type-wrapper">
            <span class="study-type-txt">{{study.studyType[0].toUpperCase() + study.studyType.substring(1)}} Study</span>          </div>
          <div class="bookmark-wrapper" (click)="bookmarkStudy(study); $event.stopPropagation()">
            <div class="bookmark-content" *ngIf="!study.isBookmarked">
              <img class="bookmark-icon" src="../../assets/images/bookmark-inactive.png" alt="Add Studies Bookmark" />
              <span class="bookmark-txt">Bookmark this study</span>
            </div>
            <div class="bookmarked-content" *ngIf="study.isBookmarked">
              <img class="bookmark-icon" src="../../assets/images/bookmark-active.png" alt="Remove Studies Bookmark" />
              <span class="bookmarked-txt">Bookmarked</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="empty-study-list" *ngIf="studies.length === 0 && !loader">
    <span class="empty-study-txt text-white font-class" style="font-size: 22px">{{noDataTemplate}}</span>
  </div>
  <div class="load-more-btn-wrapper" *ngIf="hasNextPage">
    <button class="load-more-btn font-class" (click)="loadMoreStudies()">Load More</button>
  </div>
</div>
<app-loader class="spinner" *ngIf="loader"></app-loader>
