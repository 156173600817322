import { Component, OnInit } from '@angular/core';
import { OrganizationComponent } from './organization/organization.component';
import { MdlDialogService } from '@angular-mdl/core';
import { OrganizationService } from "../_services/organization.service";
import { Router } from "@angular/router";
import { Globals } from "../globals";
import { ToastrService } from 'ngx-toastr';
import {DashboardService} from "../_services/dashboard.service";

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['../researcher/researcher.component.css', './admin.component.css', '../app.component.css'],
  providers: [Globals]
})
export class AdminComponent implements OnInit {

  accounts = [];
  fAccountIdList=[];
  accountIdList;
  accountIdArr = [];
  accountListFound;
  organizations = [];
  organization = [{
    org_name: "atmospheric studies",
    description: "The Atmospheric Physics and Weather Group carries out weather and climate research, studying processes and phenomena related to moist thermodynamics and the hydrologic cycle in the atmosphere.",
    img_src: "../assets/images/atmosphere.png"
  }, {
    org_name: "earth science",
    description: "Earth scientists conduct basic research employing end-to-end expertise in observations from multiple vantage points along with theoretical modeling to characterize and understand the atmosphere, land, cryosphere and oceans on our home planet, with an overarching goal of improving predictions of future changes.",
    img_src: "../assets/images/earth.png"
  }, {
    org_name: "sea and ice level studies",
    description: "This group develops and uses the remotely sensed data products and measurements for sea level change analysis as well as Earth system models for ocean and ice.",
    img_src: "../assets/images/iceberg.png"
  }, {
    org_name: "climate science",
    description: "This group studies climate-variability phenomena such as the Madden-Julian Oscillation, ENSO, and monsoons, as well as global climate trends.",
    img_src: "../assets/images/climate.png"
  }];
  loader: boolean = true;
  hasNextPage = false;
  query = {
    page: 1,
    limit: 10
  };
  organizationLength = 0;
  constructor(
              private router: Router,
              private dialog: MdlDialogService,
              private global: Globals,
              private toastr: ToastrService,
              private apiService: DashboardService,
              private organizationService: OrganizationService
  ) {}

  ngOnInit() {
    this.loader = true;
    this.apiService.checkIfAdmin();
    this.query = {
      page: 1,
      limit: 10
    };
    this.getOrganizations();
  }

  refreshOrganization(){
    this.query = {
      page: 1,
      limit: 10
    };
    this.getOrganizations();
  }

  getOrganizations() {
    this.loader = true;
    this.organizationService.getOrganizationsListWithPagination(this.query).subscribe((organizations:any) => {
      this.organizations = organizations.docs;
      this.organizationLength = organizations.total;
      this.hasNextPage = this.query.page !== organizations.pages;
      for (let org of this.organizations) {
        if (!('img_src' in org)) {
          let org_name = org.org_name.toLowerCase();
          let getLinkImg = this.organization.find(img => {
            let regex = new RegExp(img.org_name, "g");
            const match = regex.exec(org_name);
            if (match) if (match[0]) return img.org_name == match[0];
          });
          if (getLinkImg) org['img_src'] = getLinkImg['img_src'];
        }
      }
      this.loader = false;
      this.getAccounts();
    }, error => {
      this.loader = false;
      console.log(error.status);
      let errorbody = '';
      let errBody = '';
      try {
        errorbody = error.error;
        errBody = error.error.err;
        if(error && error.status === 401){
          this.toastr.error(errBody, '', this.global.tosterOverride);
          this.router.navigate(['login']);
        }else if(error && error.status === 403){
          const err = error.error;
          this.toastr.error(err.error, 'Error', this.global.tosterOverride);
          this.router.navigate(['pageNotFound']);
       }else{
        this.toastr.error(errorbody, '', this.global.tosterOverride);
       }
      } catch (e) {
        //errorbody = "Something went wrong, we are looking into this issue";
      }
      
    });
  }

  loadMore(){
    this.loader = true;
    this.query.page += 1;
    this.organizationService.getOrganizationsListWithPagination(this.query).subscribe((organizations:any) => {
      this.hasNextPage = this.query.page !== organizations.pages;
      this.organizationLength = organizations.total;
      for (let org of organizations.docs) {
        if (!('img_src' in org)) {
          let org_name = org.org_name.toLowerCase();
          let getLinkImg = this.organization.find(img => {
            let regex = new RegExp(img.org_name, "g");
            const match = regex.exec(org_name);
            if (match) if (match[0]) return img.org_name == match[0];
          });
          if (getLinkImg) org['img_src'] = getLinkImg['img_src'];
        }
      }
      this.loader = false;
      this.organizations = this.organizations.concat(organizations.docs);
      this.getAccounts();
    }, error => {
      this.loader = false;
      console.log(error.status);
      let errorbody = '';
      let errBody = '';
      try {
        errorbody = error.error.error;
        errBody = error.error.err;
        if(error && error.status === 401){
          this.toastr.error(errBody, '', this.global.tosterOverride);
          this.router.navigate(['login']);
        }else if(error && error.status === 403){
          const err = error.error;
          this.toastr.error(err.error, 'Error', this.global.tosterOverride);
          this.router.navigate(['pageNotFound']);
       }else{
        this.toastr.error(errorbody, '', this.global.tosterOverride);
       }
      } catch (e) {
        //errorbody = "Something went wrong, we are looking into this issue";
      }
  
    });
  }

  orgOwner(data){
    this.loader= false;
    const research= [];
    for(let name of data){
      if(name){
        if(name.displayName){
          research.push(name.displayName);
        }else{
          research.push(name.email);
        }
      }
    }
    let team;
    if(research.length>0){
      let getLen = research.length;
      if (getLen > 1) {
        getLen = getLen - 1;
      }
      let append_name = ' other';
      if (getLen > 2) {
        append_name = ' others';
      }
      if (research.length > 1) {
        team = research[0] + ' and ' + getLen + append_name;
      } else {
        team = research[0];
      }
    }
    return team;
  }

  addOrganization(): void {
    this.router.navigate(['/addOrganization/Org']);
  }

  viewOrganization(data): void {
    this.apiService.getDialogBox().subscribe((res:any) => {
      if(res.status === 200){
    this.dialog.showCustomDialog({
      component: OrganizationComponent,
      providers: [{ provide: 'data', useValue: data }],
      isModal: true,
      styles: {
        "height": "auto",
        "width": "40%",
        "background": "#07102A",
        "border": "1px solid #FFFFFF33",
        "border-radius": "10px",
        "padding": "0px"
      },
      clickOutsideToClose: true,
      enterTransitionDuration: 400,
      leaveTransitionDuration: 400,
    })
  }}, error => {
    if(error && error.status === 401){
      let errBody = error.error;
       this.toastr.error(errBody.err, '', this.global.tosterOverride);
       this.router.navigate(['login']);
     }
  })
  }

  orgDescription(data) {
    const strLen = data.length;
    let str = data;
    if (strLen > 80) {
      str = str.slice(0, 80) + '...';
    }
    return str;
  }

  getAccounts() {
    this.loader = true;
    this.fAccountIdList = [];
    this.apiService.getSettingsDetail('1', '999', 'created_on', 'asc', 'admin','project').subscribe(
      (res:any) => {
        this.accounts = res.settings;
        this.accountIdList = res.settings.map(obj => {
          const fObj = {
            'value': obj._id,
            'viewValue': obj.name
          };
          return fObj;
        });
        for (let i = 0; i < this.accountIdList.length; i++) {
          let data = this.accountIdArr.find(obj => obj == this.accountIdList[i].value)
          if (!data) {
            this.fAccountIdList.push(this.accountIdList[i]);
            this.accountListFound = true;
          }
        }
        if(this.fAccountIdList.length == 0){
          this.accountListFound = false;
        }
        this.loader = false;
    }, error => {
      
      this.loader = false;
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.global.tosterOverride);
         this.router.navigate(['login']);
       }
     }
    );
  }

}
