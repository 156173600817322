import { Component, OnInit, Inject, HostListener} from '@angular/core';
import * as $ from 'jquery';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ServiceActionsService} from "../../_services/service-actions.service";
import { DashboardService } from "../../_services/dashboard.service";
import { ResearchService } from "../../_services/research.service";
import { S3DataService } from "../../_services/s3-data.service";
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { MdlDialogReference } from '@angular-mdl/core';
import { ToastrService } from 'ngx-toastr';
import {Globals} from "../../globals";
import { Router,ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-s3-file-upload',
  templateUrl: './s3-file-upload.component.html',
  styleUrls: ['./s3-file-upload.component.css', '../../s3-explore/s3-explore.component.css'],
  providers: [Globals]
})
export class S3FileUploadComponent implements OnInit {
  form: FormGroup;
  uploadData;
  accId: any;
  serviceType:any;
  service: string = "s3";
  imageUrl: string = "../../assets/images/aws_icon/s3.png";
  s3Bucket:any;
  imageObj: any;
  myFiles = [];
  progress: number = 0;
  fileUpload: boolean = false;
  fileData: any;
  provisionedProductName: any;
  filePrefix: any;
  fileUploadError: any;
  uploadError: boolean = false;
  routeToExplorePage;
  exploreS3BucketData: any;
  public files: any[] = [];
  projectName: any;
  productName:any;
  ppId:any;
  projectStatus:any;
  userLevel:any;
  projectId:any;
  bucketName:any;
  fileUploadStatus = false;
  showActions = true;
  showAdd = true;
  fileUploadComplete = false;
  progressInfos = [];
  fileCount;
  fileUploadApiCall;
  isShared;
  filesSelected;
  productStatus;
  uploadType;
  showAddFile = true;
  showAddFolder = true;
  private subs: Subscription[] = [];
  s3FileSizeLimit;
  fileSizeLimit;
  fileSizeError = false;
  constructor(
  public fb: FormBuilder,
  private serviceAction: ServiceActionsService,
  private toastr: ToastrService,
  private s3DataService: S3DataService,
  private apiService: DashboardService,
  private router: Router,
  private activatedRoute: ActivatedRoute,
  private researchService: ResearchService,
  private globals: Globals, private _location: Location) {
    this.form = this.fb.group({
    name: [''],
    avatar: [null]
  })}

  ngOnInit() {
    this.service = this.activatedRoute.snapshot.params['service'];
    this.imageUrl = this.service === 's3' ? "../../assets/images/aws_icon/s3.png" : "../../assets/images/azure_icon/azure-blob-block.png" ;
    this.apiService.checkIfResearcher();
    this.uploadData = this.s3DataService.getS3UploadData();
    this.accId = this.uploadData.account_id || sessionStorage.getItem('account_id');
    this.productName = this.uploadData.productName || sessionStorage.getItem('productName');
    this.projectName = this.uploadData.projectName || sessionStorage.getItem('projectName');
    this.filePrefix = this.uploadData.prefix;
    this.ppId = this.uploadData.ppId || sessionStorage.getItem('ppId');
    /* Check if this product has correct ppId*/
    this.researchService.getProvisionedProductDetails(this.ppId).subscribe((res:any) => {

    }, error => {
      let errBody = error.error;
      if(error && error.status === 401){
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }else if (error && error.status === 403) {
        const err = error.error;
        this.toastr.error(err, 'Error', this.globals.tosterOverride);
        this.router.navigate(['pageNotFound']);
      }else{
        this.toastr.error(errBody, 'Error', this.globals.tosterOverride);
        }
    })
    this.isShared = this.uploadData.isShared || sessionStorage.getItem('isShared');
    this.projectStatus = this.uploadData.projectStatus || sessionStorage.getItem('projectStatus');
    this.userLevel = this.uploadData.userLevel || sessionStorage.getItem('Level');
    this.projectId = this.uploadData.projectId || sessionStorage.getItem('projectId');
    this.s3Bucket = this.uploadData.bucketName || sessionStorage.getItem('bucketName');
    this.productStatus = this.uploadData.productStatus ||  sessionStorage.getItem('status');
    this.provisionedProductName = this.uploadData.provisionedProductName || sessionStorage.getItem('provisionedProductName');
    var $uploadSection = $("#instanceSSHPage .modal-footer");
    var $fileUploadContainer = $("#instanceSSHPage .modal-body");
    $("#instanceSSHPage #ssh-pemfileInput").change(function() {
    });

  $("input:file").change(function (){
    }); 

    sessionStorage.setItem('projectName',this.uploadData.projectName || this.projectName);
    sessionStorage.setItem('productName',this.uploadData.productName || this.productName);
    sessionStorage.setItem('provisionedProductName',this.uploadData.provisionedProductName || this.provisionedProductName);
    sessionStorage.setItem('productStatus',this.uploadData.status);
    sessionStorage.setItem('ppId',this.uploadData.ppId || this.ppId);
    sessionStorage.setItem('projectStatus',this.uploadData.projectStatus || this.projectStatus);
    sessionStorage.setItem('account_id',this.uploadData.account_id || this.accId);
    sessionStorage.setItem('isShared',this.uploadData.isShared || this.isShared);
    sessionStorage.setItem('bucketName',this.s3Bucket);
    sessionStorage.setItem('status',this.productStatus);
    this.getS3FileSizeMaxLimit();
}

  onImagePicked(event: Event): void {
    const FILE = (event.target as HTMLInputElement).files;
    this.imageObj = FILE;
    this.form.patchValue({
      avatar: FILE
    });
    this.form.get('avatar').updateValueAndValidity();
    for (var i = 0; i < this.imageObj.length; i++) { 
      this.myFiles.push(this.imageObj[i]);
    }
  }

  uploadFiles(){
    this.showAdd = false;
    //Disable click action untill file upload is complete
    const element = document.querySelector('#scrollId');
    element.scrollIntoView();
    document.getElementById('scrollId').style.pointerEvents = 'none';
    for (let i = 0; i < this.files.length; i++) {
      this.onImageUpload(i, this.files[i]);
    }
    //this.onImageUpload(1, this.files);
  }

  uploadFolders(){
    this.showAdd = false;
    //Disable click action untill file upload is complete
    const element = document.querySelector('#scrollId');
    element.scrollIntoView();
    document.getElementById('scrollId').style.pointerEvents = 'none';
    for (let i = 0; i < this.files.length; i++) {
      this.onFolderUpload(i, this.files[i]);
    }
  }

  onImageUpload(idx, file) {
    this.files[idx].value = 0;
    this.files[idx].status = 'In Progress';
    this.progress = 0;
    this.uploadError = false;
    this.showActions = false;
    const imageForm = new FormData();
    imageForm.append('account_id', this.accId);
    imageForm.append('bucketName', this.s3Bucket);
    imageForm.append('rootFolder', this.uploadData.rootFolder);
    if(this.filePrefix && this.filePrefix!= "undefined"){
      imageForm.append('folder', this.filePrefix);
    }
    // for(let data of this.imageObj){
    //   imageForm.append('image', data);
    // }
    imageForm.append('image', file);
    //this.s3DataService.setFolderPrefix({ folderName:this.filePrefix, rootFolder: this.data.s3Obj.rootFolder });
    this.fileUploadApiCall = this.serviceAction.fileUpload(imageForm, this.accId, this.projectName, this.s3Bucket).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          //console.log('Request has been made!');
          break;
        case HttpEventType.ResponseHeader:
          //console.log('Response header has been received!');
          break;
        case HttpEventType.UploadProgress:
          this.progress = Math.round(event.loaded / event.total * 100);
          this.files[idx].value = Math.round(event.loaded / event.total * 100);
          //console.log(`Uploaded! ${this.progress}%`);
          break;
        case HttpEventType.Response:
          //console.log('File uploaded successfully!', event.body);
          this.fileUpload = true;
          this.files[idx].status = 'Success';
          this.checkFileUploadStatus();
          if(this.routeToExplorePage){
            // if(this.data.s3Obj.isStudy){
            //   this.router.navigate([`/explore/${this.data.s3Obj.id}/success/uploadSuccess`], {queryParams:{name: this.data.s3Obj.provisionedProductName, type: this.data.s3Obj.type, accountId: this.accId}});
            // }else {
            //   this.router.navigate([`s3/bucket/${this.s3Bucket}/success/uploadSuccess`]);
            // }
            //this.router.navigate([`s3/bucket/${this.s3Bucket}/success/uploadSuccess`]);
          }else{
            //this.syncS3BucketData();
          }
          setTimeout(() => {
            this.progress = 0;
          }, 200);
      }

    }, error => {
      //this.dialogRef.hide();
      this.files[idx].value = 0;
      this.uploadError = true;
      this.showActions = true;
      this.fileUpload = true;
      this.files[idx].status = 'Failed';
      this.checkFileUploadStatus();
      //Error test cases
      //case 1) -this is to check file upload error, when file size is more than 10MB (specified in nginx config -> 413 Request Entity Too Large error)
      //case 2) -bucket not found
      //case 3) -connection lost
      //case 4) -access denied
      this.fileUploadError = error.error;
      if(error && error.status === 401){
        let errBody= error.error;
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
      } else if(this.fileUploadError.error && this.fileUploadError.error.code == "NoSuchBucket"){
        this.files[idx].statusMsg = this.fileUploadError.error.message;
      }else if(this.fileUploadError.error && this.fileUploadError.error.code == "AccessDenied"){
        this.files[idx].statusMsg = "Access Denied. Please contact your administrator.";
      }else if(error.status === 413){
        this.files[idx].statusMsg = `The size of this file is larger than the maximum (${this.s3FileSizeLimit}) size allowed on this system. Please contact your administrator`;
        //this.toastr.error("The size of this file is larger than the maximum (10 MB) size allowed on this system. Please contact your administrator", `S3 File upload error`, this.globals.tosterOverride);
      }else if(error.status === 502){
        this.files[idx].statusMsg = `The file could not be uploaded. The connection was interrupted.`;        
      }else if(error.status === 0){
        //Http Unknown error / Connection lost error
          this.files[idx].statusMsg = "Network connection was lost. Please check your network connection";
      }
      else if(error.message){
        this.files[idx].statusMsg = error.message;
      } else if(this.fileUploadError.error.message){
        this.files[idx].statusMsg = this.fileUploadError.error.message;
        //this.toastr.error(this.fileUploadError.error.message, `S3 File upload error`, this.globals.tosterOverride);
      }else {
        this.files[idx].statusMsg = "Error in file upload, please try after sometime";
        //this.toastr.error('Error in file upload, please try after sometime', `S3 File upload error`, this.globals.tosterOverride);
      }
      
    }
  );
  this.subs.push(this.fileUploadApiCall);
  }

  //If User navigates from this page, all uploads would be cancelled.
  ngOnDestroy(){
    this.subs.forEach((s) => s.unsubscribe());
}

//Folder Upload to S3
onFolderUpload(idx, file) {
  this.files[idx].value = 0;
  this.files[idx].status = 'In Progress';
  this.progress = 0;
  this.uploadError = false;
  this.showActions = false;
  const imageForm = new FormData();
  imageForm.append('account_id', this.accId);
  imageForm.append('bucketName', this.s3Bucket);
  imageForm.append('rootFolder', this.uploadData.rootFolder);
  if(this.filePrefix){
    imageForm.append('folder', this.filePrefix);
  }
  // for(let data of this.imageObj){
  //   imageForm.append('image', data);
  // }
  imageForm.append('image', file);
  //this.s3DataService.setFolderPrefix({ folderName:this.filePrefix, rootFolder: this.data.s3Obj.rootFolder });
  this.fileUploadApiCall = this.serviceAction.folderUploadToS3(imageForm, this.accId, this.projectName, this.s3Bucket).subscribe((event: HttpEvent<any>) => {
    switch (event.type) {
      case HttpEventType.Sent:
        //console.log('Request has been made!');
        break;
      case HttpEventType.ResponseHeader:
        //console.log('Response header has been received!');
        break;
      case HttpEventType.UploadProgress:
        this.progress = Math.round(event.loaded / event.total * 100);
        this.files[idx].value = Math.round(event.loaded / event.total * 100);
        //console.log(`Uploaded! ${this.progress}%`);
        break;
      case HttpEventType.Response:
        //console.log('File uploaded successfully!', event.body);
        this.fileUpload = true;
        this.files[idx].folderName = this.filePrefix;
        let newFileArr = [];
        newFileArr.push(this.files[idx]);
        var randomNum = Math.floor(Math.random() * (5 - 2 + 1) + 2); //Generate Random number between 2 - 5
        //This random num will be used to create sub-folders after file upload is complete 
        setTimeout(() => {
          this.files[idx].status = 'Success';
          this.checkFileUploadStatus();
        }, randomNum * 2000);
        if(this.routeToExplorePage){
          // if(this.data.s3Obj.isStudy){
          //   this.router.navigate([`/explore/${this.data.s3Obj.id}/success/uploadSuccess`], {queryParams:{name: this.data.s3Obj.provisionedProductName, type: this.data.s3Obj.type, accountId: this.accId}});
          // }else {
          //   this.router.navigate([`s3/bucket/${this.s3Bucket}/success/uploadSuccess`]);
          // }
          //this.router.navigate([`s3/bucket/${this.s3Bucket}/success/uploadSuccess`]);
        }else{
          //this.syncS3BucketData();
        }
        setTimeout(() => {
          this.progress = 0;
        }, 200);
    }

  }, error => {
    //this.dialogRef.hide();
    this.files[idx].value = 0;
    this.uploadError = true;
    this.showActions = true;
    this.fileUpload = true;
    this.files[idx].status = 'Failed';
    this.checkFileUploadStatus();
    //Error test cases
    //case 1) -this is to check file upload error, when file size is more than 10MB (specified in nginx config -> 413 Request Entity Too Large error)
    //case 2) -bucket not found
    //case 3) -connection lost
    //case 4) -access denied
    this.fileUploadError = error.error;
    if(this.fileUploadError.error && this.fileUploadError.error.code == "NoSuchBucket"){
      this.files[idx].statusMsg = this.fileUploadError.error.message;
    }else if(error && error.status === 401){
      let errBody = error.error;
       this.toastr.error(errBody.err, '', this.globals.tosterOverride);
       this.router.navigate(['login']);
     }else if(this.fileUploadError.error && this.fileUploadError.error.code == "AccessDenied"){
      this.files[idx].statusMsg = "Access Denied. Please contact your administrator.";
    }else if(error.status === 413){
      this.files[idx].statusMsg = `The size of this file is larger than the maximum (${this.s3FileSizeLimit}) size allowed on this system. Please contact your administrator`;
      //this.toastr.error("The size of this file is larger than the maximum (10 MB) size allowed on this system. Please contact your administrator", `S3 File upload error`, this.globals.tosterOverride);
    }else if(error.status === 502){
      this.files[idx].statusMsg = `The file could not be uploaded. The connection was interrupted.`;        
    }else if(error.status === 0){
    //Http Unknown error / Connection lost error
      this.files[idx].statusMsg = "Network connection was lost. Please check your network connection";
    }else if(error.message){
      this.files[idx].statusMsg = error.message;
    } else if(this.fileUploadError.error.message){
      this.files[idx].statusMsg = this.fileUploadError.error.message;
      //this.toastr.error(this.fileUploadError.error.message, `S3 File upload error`, this.globals.tosterOverride);
    }else {
      this.files[idx].statusMsg = "Error in file upload, please try after sometime";
      //this.toastr.error('Error in file upload, please try after sometime', `S3 File upload error`, this.globals.tosterOverride);
    }
  }
);
this.subs.push(this.fileUploadApiCall);
}

  checkFileUploadStatus(){
    const result = this.files.filter(file => file.status.includes("In Progress"));
    if(result.length > 0){
      this.fileUploadComplete = false;
    }else{
      this.fileUploadComplete = true;
      //Enable click event after file upload is successful
      document.getElementById('scrollId').style.pointerEvents = 'auto';
    }
  }

  cancel(): void {
    //this.dialogRef.hide();
  }

  syncS3BucketData(){
    //this.exploreS3BucketData = this.data.s3Obj;
    this.exploreS3BucketData.action = 'explore';
    this.exploreS3BucketData.bucketName = this.s3Bucket;
    this.serviceAction.serviceAction(this.exploreS3BucketData).subscribe(res => {
      if(res){

      }
    }, error => {
      console.error(error);
      let errBody = error.error;
      if(error && error.status === 401){
        this.toastr.error(errBody.err, '', this.globals.tosterOverride);
        this.router.navigate(['login']);
       }
    });
  }

  //check for s3FileSizeMaxLimit for upload
  getS3FileSizeMaxLimit(){
    this.serviceAction.getS3FileSizeMaxLimit().subscribe((res:any) => {
      if(res && res.s3FileSizeMaxLimit){
        this.fileSizeLimit = res.s3FileSizeMaxLimit;
        this.s3FileSizeLimit = this.getFileSize(this.fileSizeLimit);
      }else{
        this.s3FileSizeLimit = '0';
      }
    }, error => {
      // console.error(error);
      if(error && error.status === 401){
        let errBody = error.error;
         this.toastr.error(errBody.err, '', this.globals.tosterOverride);
         this.router.navigate(['login']);
       }
    });
  }

  onFileChange(pFileList: File[], uploadType){
    this.uploadType = uploadType;
    this.progressInfos = [];
    this.filesSelected = Object.keys(pFileList).map(key => pFileList[key]);
    if(this.uploadType == 'File'){
      this.files = this.files.concat(this.filesSelected);
      this.showAddFile = true;
      this.showAddFolder = false;
    }else{
      this.files = this.filesSelected;
      this.showAddFile = false;
      this.showAddFolder = true;
    }
    this.fileCount = this.files.length;
    this.imageObj = this.files;
    for (var i = 0; i < this.imageObj.length; i++) { 
      this.myFiles.push(this.imageObj[i]);
    }
    for(var j=0; j< this.files.length; j++){
      this.files[j].status = 'Pending';
      if(this.files[j].size > this.fileSizeLimit){
        this.files[j].status = `The size of this file is larger than the maximum (${this.s3FileSizeLimit}) size allowed on this system. Please remove this to continue.`;
      }
      this.files[j].originalname = this.files[j].webkitRelativePath;
      this.files[j].account_id = this.accId;
      this.files[j].bucketName = this.s3Bucket;
      this.files[j].rootFolder = this.uploadData.rootFolder;
    }
    let statusRes = this.files.filter(file => file.status.includes('The size of this file is larger than the maximum'));
    if(statusRes.length > 0){
      this.fileSizeError = true;
    }else {
      this.fileSizeError = false;
    }
    this.showActions = true;
    this.fileUploadStatus = false;
    this.fileUpload = false;
  }


  deleteSelected(index) {
    this.files.splice(index, 1);
    this.fileCount = this.files.length;
    for (var j = 0; j < this.files.length; j++) {
      let statusRes = this.files.filter(file => file.status.includes('The size of this file is larger than the maximum'));
      if (statusRes.length > 0) {
        this.fileSizeError = true;
      } else {
        this.fileSizeError = false;
      }
    }
  }

  getFileSize(size) {
    var sizes = [' Bytes', ' KB', ' MB', ' GB', ' TB', ' PB', ' EB', ' ZB', ' YB'];
    for (var i = 1; i < sizes.length; i++) {
      if (size < Math.pow(1024, i)) return (Math.round((size / Math.pow(1024, i - 1)) * 100) / 100) + sizes[i - 1];
    }
    return size;
  }

  routeBack(){
    const projectName = encodeURIComponent(this.projectName);
    this.router.navigate(['/product/' + projectName + '/' +
      this.productName, { ppId: this.ppId }],{ queryParams: { projectStatus: this.projectStatus}});
  }

  routeBackToFolder(){
    this.router.navigate(['/s3/bucket/' + this.s3Bucket ],{ queryParams: { folder: this.filePrefix}});
  }

  routeBackToRoot(){
    this.router.navigate(['/s3/bucket/' + this.s3Bucket ]);
  }

  cancelUpload(){
    this._location.back();
  }

  uploadComplete(){
    if(this.filePrefix && this.filePrefix!="undefined"){
      this.routeBackToFolder();
    }else{
      this.routeBackToRoot();
    }
  }

  canDeactivate() {
    if(this.files.length > 0 && !this.fileUploadComplete){
      return confirm("Are you sure you want to leave the current page? You have not uploaded the specified files.");
    }else{
      return true;
    }
  
  }

  navigateToProject(){
    const projectName = encodeURIComponent(this.projectName);
    sessionStorage.setItem('projectName', this.projectName);
    this.router.navigate(['/catalog/' + projectName + '/' + this.accId ]);
  }

}