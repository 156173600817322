<div class="container">
  <!-- breadcrumb -->
  <nav aria-label="breadcrumb">
      <ol class="breadcrumb bc-custom">
        <li class="breadcrumb-item"><a class="bc-item" routerLink="/admin/budget-tracking"> Budgets </a></li>
        <li class="breadcrumb-item" aria-current="page"><a class="bc-item" (click)="openOrganisationDetails()"> {{ orgName }} </a></li>
        <li class="breadcrumb-item" aria-current="page"><a class="bc-item" (click)="openProjectDetails()"> {{ projectName }} </a></li>
        <li class="breadcrumb-item" aria-current="page"><a class="bc-item-active"> {{ displayName }} </a></li>
      </ol>
  </nav>
  <!-- <div class="back-layout" (click)="goBack()">
    <img class="back-icon" src="../../../assets/images/pagination-prev-active.png" />
    <h3 class="back-text text-white font-class">Back</h3>
  </div> -->
  <div class="project-info-layout">
    <div class="project-name-layout">
      <span class="project-name text-white font-class">{{ displayName }}</span>
    </div>
    <div class="project-budget-layout">
      <div class="project-budget-wrapper">
        <div class="project-budget-info">
            <span class="budget-title font-class">Total Project Budget</span>
            <span class="budget-value font-class">{{currencySymbol}} {{budgetAllotted}}</span>
        </div>
      </div>
      <div class="project-budget-wrapper">
        <div class="project-budget-info">
            <span class="budget-title font-class">Total Direct Cost</span>
            <span class="budget-value font-class">{{currencySymbol}} {{researcherBudgetConsumed}}</span>
        </div>
      </div>
      <div class="project-budget-wrapper">
        <div class="project-budget-info">
            <span class="budget-title font-class">Available Budget</span>
            <span class="budget-value font-class">{{currencySymbol}} {{researcherBudgetAvailable}}</span>
        </div>
      </div>
    </div>

    <!-- mobile responsive -->
    <div class="project-budget-layout-mobile">
      <div style="flex: 1">
          <div class="project-budget-info">
              <span class="budget-title font-class">Total Project Budget</span>
              <span class="budget-value font-class">{{currencySymbol}} {{budgetAllotted}}</span>
          </div>
          <div class="project-budget-info" style="margin-top: 10px;">
              <span class="budget-title font-class">Total Direct Cost</span>
              <span class="budget-value font-class">{{currencySymbol}} {{researcherBudgetConsumed}}</span>
          </div>
      </div>
      <div style="flex: 1">
          <div class="project-budget-info">
              <span class="budget-title font-class">Available Budget</span>
              <span class="budget-value font-class">{{currencySymbol}} {{researcherBudgetAvailable}}</span>
          </div>
      </div>
    </div>
  </div>

  <div>
    <div class="container budget-breakdown">
      <div class="row budget-breakdown-header">
        <div class="col align-self-center font-class text-left budget-header">
          <h3 >Product-wise Budget Breakdown</h3>
        </div>
        <!-- <div class="col-auto pr-0 text-right">
          <button class="export-btn font-class" (click)="exportCSV()">Export as CSV</button>
        </div> -->
      </div>
      <!-- Budget Breakdown Projects list -->
      <div class="project-table-layout">
        <div class="projects-header d-flex w-100">
          <div class="title">
            Product Name
          </div>
          <div class="title">
            Product Type
          </div>
          <div class="title">
            Direct Cost
          </div>
          <div class="title">
            Status
          </div>
          <div class="title">
            Created At
          </div>
        </div>
        <div class="projects" *ngIf="products && products.length > 0">
          <div class="project-row d-flex w-100 py-4 my-3 cursor-disable" *ngFor="let product of products">
            <div class="project-column">
              <span class="researcher-name font-class">{{ product.productName }}</span>
            </div>
            <div class="project-column">
              <span>{{ product.productType }}</span>
            </div>
            <!-- Consumed Budget -->
            <div class="project-column">
              <span>{{ currencySymbol }} </span>
              <span>{{ product.budgetConsumed.toFixed(2)}}</span>
            </div>
            <div class="project-column">
              <span *ngIf="product.status">{{ product.status }}</span>
              <span *ngIf="!product.status">NA</span>
            </div>
            <!--  Available Budget -->
            <div class="project-column">
              <span>{{ product.createdAt | amUtc | amDateFormat: 'YYYY-MM-DD HH:mm A' }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- mobile responsive -->
      <div class="project-cards-layout" *ngIf="products && products.length > 0">
        <div class="project-card" *ngFor="let product of products">
          <ul class="list-group">
            <div class="d-flex justify-content-between mb-2">
              <div class="project-card-title font-class">Product Name</div>
              <div class="project-card-project-name">{{ product.productName }}</div>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <div class="project-card-title font-class">Product Type</div>
              <div class="project-card-value">{{ product.productType }}</div>
            </div>
            <div class="d-flex justify-content-between mb-2">
                <div class="project-card-title project-card-line-height font-class">Direct Cost</div>
                <div class="project-card-value">{{ currencySymbol }} {{ product.budgetConsumed.toFixed(2)}}</div>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <div class="project-card-title font-class">Status</div>
              <div class="project-card-value" *ngIf="product.status">{{ product.status }}</div>
              <div class="project-card-value" *ngIf="!product.status">NA</div>
            </div>
            <div class="d-flex justify-content-between mb-2">
                <div class="project-card-title project-card-line-height font-class">Created At</div>
                <div class="project-card-value">{{ product.createdAt | amUtc | amDateFormat: 'YYYY-MM-DD HH:mm A' }}</div>
            </div>
          </ul>
        </div>
      </div>

      <!-- No product available -->
      <div *ngIf="products && products.length === 0 && !loader">
        <h3 class="empty-text">No products configured</h3>
      </div>

      <!-- Load more projects -->
      <!-- <div class="row load-more-btn-wrapper" *ngIf="products && products.length > 0">
        <button class="load-more-btn">Load More</button>
      </div> -->
    </div>
  </div>
</div>
<app-loader class="spinner" *ngIf="loader"></app-loader>
