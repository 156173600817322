<div class="container">

  <!-- breadcrumb -->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb bc-custom">
      <li class="breadcrumb-item active bc-item-active" aria-current="page">Audit Trail</li>
    </ol>
  </nav>
  <!-- details -->
  <div style="display: flex; justify-content: space-between; align-items: center;" class="audit-title">
    <div style="display: flex;">
      <div style="display: flex; flex-direction: column;">
        <h4 class="text-white font-class">Audit Trail</h4>
      </div>
    </div>
    <div class="text-right">
      <div class="search-div" style="margin-right: auto">
        <em class="glyphicon glyphicon-search search-icon"></em>
        <label for="searchKeyForAuditTrailDetail" class="visually-hidden">searchKeyForAuditTrailDetail</label>  
        <input (keyup)="searchKeypair()" [(ngModel)]="searchKey" id="searchKeyForAuditTrailDetail" class="form-control search-inp search-inp-add" placeholder="Search" type="text">
      </div>
    </div>
  </div>
  <!-- details -->
  <div class="web-filter" style="display: flex; justify-content: space-between; align-items: center; margin: 10px 0px 25px 0px;">
    <div style="display: flex;">
      <div style="margin-right: 20px; display: flex; flex-direction: column;">
        <div class="container">
          <mat-form-field>
            <mat-label>Select date range</mat-label>
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [max]="today" disabled>              
              <input matStartDate formControlName="start" #dateRangeStart>
              <input matEndDate formControlName="end"  aria-label="EndDatePicker" #dateRangeEnd (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker disabled="false"></mat-date-range-picker>
          </mat-form-field>
        </div>
      </div>
      <div style="margin-right: 20px; display: flex; flex-direction: column;">
        <div class="dropdown">
          <button  class="btn header-menu-ft dropdown-toggle" type="button" data-toggle="dropdown">
            <span class="menu-btn">
              <span style=" width: 150px; " class="menu-btn-text menu-cal-c">{{categoryFilter}}</span>
              <em class="fa fa-angle-down white menu-cal-f"></em>
            </span>
          </button>
          <ul class="dropdown-menu filter-dd">
            <button *ngIf="userLevel != '0' && userLevel != '1' " [ngClass]="{'filter-log-active': selectedItem === 'item1'}" (click)="selectedItem = 'item1'; categoryFilter = 'By Admin'; filterCatChange('admin')" type="button" class="btn menu-item-btn filter-log">Admin</button>
            <button [ngClass]="{'filter-log-active': selectedItem === 'item2'}" (click)="selectedItem = 'item2'; categoryFilter = 'By Principal Investigator'; filterCatChange('principal')"  type="button" class="btn menu-item-btn filter-log" >Principal Investigator</button>
            <button [ngClass]="{'filter-log-active': selectedItem === 'item3'}" (click)="selectedItem = 'item3'; categoryFilter = 'By Researcher'; filterCatChange('researcher')" type="button" class="btn menu-item-btn filter-log" >Researcher</button>
            <button [ngClass]="{'filter-log-active': selectedItem === 'item4'}" (click)="selectedItem = 'item4'; categoryFilter = 'By Project'; filterCatChange('project')" type="button" class="btn menu-item-btn filter-log" >Project</button>
            <button *ngIf="userLevel != '0' && userLevel != '1' " [ngClass]="{'filter-log-active': selectedItem === 'item5'}" (click)="selectedItem = 'item5'; categoryFilter = 'By Organization'; filterCatChange('organization')" type="button" class="btn menu-item-btn filter-log" >Organization</button>
          </ul>
        </div>
      </div>
      <div style="margin-right: 20px; display: flex; flex-direction: column;">
        <div class="dropdown">
          <button [disabled]="valFilter" class="btn header-menu-ft dropdown-toggle" type="button" data-toggle="dropdown">
            <span class="menu-btn">
              <span style=" width: 150px; " class="menu-btn-text menu-cal-c">{{nameFilter}}</span>
              <em class="fa fa-angle-down white menu-cal-f"></em>
            </span>
          </button>
          <ul class="dropdown-menu filter-dd">
            <button (click)="filterSearch(data, 'value')" *ngFor="let data of nameFilterValueArr;" type="button" title="{{data.name}}" class="btn menu-item-btn filter-log">{{ dropButtonTxt(data.name)}}</button>
          </ul>
        </div>
      </div>
    </div>
    <div class="text-right">
      <button (click)="resetFilter()" type="button" class="search-web btn btn-lg btn-primary btn-pi-t text-white font-class mr-2 mb-1" title="Reset Filters">
          Reset Filters </button>
    </div>
  </div>

<!--  <div class="divider"></div>-->

  <div class='s3Explore'>

    <div class="m-search-tp">
      <!-- mobile actions bar -->
      <div class="mbl-control-bar d-flex justify-content-between m-4 search-hearder">
        <!-- <mat-form-field>
          <mat-label>Select date range</mat-label>
          <mat-date-range-input [formGroup]="range" [rangePicker]="picker1" [max]="today" disabled>
            <input matStartDate formControlName="start" #dateRangeStart>
            <input matEndDate formControlName="end" #dateRangeEnd (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-date-range-picker #picker1 disabled="false"></mat-date-range-picker>
        </mat-form-field> -->
      </div>
      <!-- Responsive actions -->
      <div class="search-div-responsive">
        <em class="glyphicon glyphicon-search search-icon"></em>
        <input style=" width: 100%; " aria-label="searchKeyForAuditTrailDetailMobileView" (keyup)="searchKeypair()" [(ngModel)]="searchKey" 
        class="form-control search-inp search-inp-add" placeholder="Search" type="text"> 
      </div>

      <div class="mobile-filter" style="background-color: #06153E; padding: 5px;display: flex; justify-content: space-between; align-items: center; margin: 10px 0px 25px 0px;">
        <div style="display: flex;">
          <div style="margin-right: 10px; display: flex; flex-direction: column;">
            <div class="dropdown">
              <button  class="btn header-menu-ft dropdown-toggle" type="button" data-toggle="dropdown">
            <span class="menu-btn">
              <span style=" width: 90px; " class="menu-btn-text menu-cal-c">{{dropButtonTxtMobile(categoryFilter)}}</span>
              <em class="fa fa-angle-down white menu-cal-f"></em>
            </span>
              </button>
              <ul class="dropdown-menu filter-dd">
                <button *ngIf="userLevel != '0' && userLevel != '1' " [ngClass]="{'filter-log-active': selectedItem === 'item1'}" (click)="selectedItem = 'item1'; categoryFilter = 'By Admin'; filterCatChange('admin')" type="button" class="btn menu-item-btn filter-log">Admin</button>
                <button [ngClass]="{'filter-log-active': selectedItem === 'item2'}" (click)="selectedItem = 'item2'; categoryFilter = 'By Principal Investigator'; filterCatChange('principal')"  type="button" class="btn menu-item-btn filter-log" >Principal Investigator</button>
                <button [ngClass]="{'filter-log-active': selectedItem === 'item3'}" (click)="selectedItem = 'item3'; categoryFilter = 'By Researcher'; filterCatChange('researcher')" type="button" class="btn menu-item-btn filter-log" >Researcher</button>
                <button [ngClass]="{'filter-log-active': selectedItem === 'item4'}" (click)="selectedItem = 'item4'; categoryFilter = 'By Project'; filterCatChange('project')" type="button" class="btn menu-item-btn filter-log" >Project</button>
                <button *ngIf="userLevel != '0' && userLevel != '1' " [ngClass]="{'filter-log-active': selectedItem === 'item5'}" (click)="selectedItem = 'item5'; categoryFilter = 'By Organization'; filterCatChange('organization')" type="button" class="btn menu-item-btn filter-log" >Organization</button>
              </ul>
            </div>
          </div>
          <div style="margin-right: 10px; display: flex; flex-direction: column;">
            <div class="dropdown">
              <button [disabled]="valFilter" class="btn header-menu-ft dropdown-toggle" type="button" data-toggle="dropdown">
            <span class="menu-btn">
              <span style=" width: 90px; " class="menu-btn-text menu-cal-c">{{nameFilter}}</span>
              <em class="fa fa-angle-down white menu-cal-f"></em>
            </span>
              </button>
              <ul class="dropdown-menu filter-dd">
                <button (click)="filterSearchMobile(data, 'value')" *ngFor="let data of nameFilterValueArr;" type="button" title="{{data.name}}" class="btn menu-item-btn filter-log">{{ dropButtonTxtMobile(data.name)}}</button>
              </ul>
            </div>
          </div>
          <div (click)="resetFilter()" class="reset-flt">
            <span>Reset Filters</span>
          </div>
        </div>
      </div>
    </div>


    <app-loader class="spinner" *ngIf="showSpinner"></app-loader>

    <div *ngIf="auditDetailList.length === 0 && noData === true">
      <h4 style=" color: #fff;text-align: center;padding: 100px; font-size: 22px">
        {{noDataTemplate}}
      </h4>
    </div>
    <div *ngIf="auditDetailList.length > 0" class="table-responsive md-layout lg-layout" style="margin-bottom: 25px;">
      <table style="width: 100%">
        <thead>
        <tr>
          <th class="tableHeader tab-head">User</th>
          <th class="tableHeader" style="width: 15%;background-color: #07102A !important">Logical ID</th>
          <th class="tableHeader" style="width: 20%;background-color: #07102A !important">Time Stamp</th>
          <th class="tableHeader" style="width: 10%;background-color: #07102A !important">Status</th>
          <th class="tableHeader tab-head-l" style="width: 30%;">Status Reason</th>
        </tr>
        </thead>
        <tbody>
        <tr  *ngFor="let data of auditDetailList" class="table-tr-data">
          <td title="{{data.userId}}" class="tableData" style="width: 15%;font-size: 12px;">{{data.displayName}}</td>
          <td title="{{data.productId}}" class="tableData" style="width: 15%;font-size: 12px;text-transform: uppercase;">{{data.productId}}</td>
          <td class="tableData" style="width: 20%;font-size: 12px;" *ngIf="data.createdDate">{{data.createdDate | date:'medium'}}</td>
          <td class="tableData" style="width: 10%;font-size: 12px; text-transform: uppercase;"> {{data.action}} </td>
          <td title="{{data.message}}" class="tableData" style="width: 30%;font-size: 12px;" >{{projectName(data.message)}}</td>
        </tbody>
      </table>
      <div class="product-avail" style=" border-radius: 10px; ">
        <span class="page-count-text">{{ currentPageLowerCount }} - {{ currentPageUpperCount }} of {{ totalRecords }}</span>
        <div class="pagination-wrapper">
          <img class="pagination-next-btn" [ngClass]="{'disable-first-btn':page === 1 }" src="../../assets/images/pagination-first-active.png"  alt="First Page" (click)="getNextSettings('first')"/>
          <img class="pagination-next-btn" [ngClass]="{'disable-prev-btn':page === 1 }" src="../../assets/images/pagination-prev-active.png"  alt="Previous Page" (click)="getNextSettings('previous')"/>
          <img class="pagination-next-btn" [ngClass]="{'disable-next-btn':page === totalPages}" src="../../assets/images/pagination-next-active.png"  alt="Next Page" (click)="getNextSettings('next')"/>
          <img class="pagination-next-btn" [ngClass]="{'disable-last-btn':page === totalPages}" src="../../assets/images/pagination-last-active.png"  alt="Last Page" (click)="getNextSettings('last')"/>
        </div>
      </div>
<!--      <div class="load-more-btn-wrapper" *ngIf="showLoadMore">-->
<!--        <button class="load-more-btn" (click)="loadMoreEvents()">Load More</button>-->
<!--      </div>-->
    </div>

      <div class="sm-layout xs-layout" *ngIf="auditDetailList.length > 0 && !showSpinner">
        <!-- mobile responsive -->
        <div class="project-cards-layout">
          <div class="project-card" *ngFor="let data of auditDetailList;">
            <ul class="list-group">
              <div class="d-flex justify-content-between mb-2">
                <div class="project-card-title project-card-line-height font-class">User</div>
                <div class="project-card-project-name" title="{{data.userId}}">{{data.displayName}}</div>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <div class="project-card-title project-card-line-height font-class">Logical ID</div>
                <div class="project-card-project-name" title="{{data.productId}}">{{data.productId}}</div>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <div class="project-card-title project-card-line-height font-class">Time Stamp</div>
                <div class="project-card-project-name">{{data.createdDate | date:'medium'}}</div>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <div class="project-card-title project-card-line-height font-class">Status</div>
                <div class="project-card-project-name" >{{data.action}}</div>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <div class="project-card-title project-card-line-height font-class">Status Reason</div>
                <div class="project-card-project-name" >{{projectName(data.message)}}</div>
              </div>
            </ul>
          </div>
        </div>
        <div class="product-avail">
          <span class="page-count-text">{{ currentPageLowerCount }} - {{ currentPageUpperCount }} of {{ totalRecords }}</span>
          <div class="pagination-wrapper">
            <img class="pagination-next-btn" [ngClass]="{'disable-first-btn':page === 1 }" src="../../assets/images/pagination-first-active.png"  alt="First Page" (click)="getNextSettings('first')"/>
            <img class="pagination-next-btn" [ngClass]="{'disable-prev-btn':page === 1 }" src="../../assets/images/pagination-prev-active.png"  alt="Previous Page" (click)="getNextSettings('previous')"/>
            <img class="pagination-next-btn" [ngClass]="{'disable-next-btn':page === totalPages}" src="../../assets/images/pagination-next-active.png"  alt="Next Page" (click)="getNextSettings('next')"/>
            <img class="pagination-next-btn" [ngClass]="{'disable-last-btn':page === totalPages}" src="../../assets/images/pagination-last-active.png"  alt="Last Page" (click)="getNextSettings('last')"/>
          </div>
        </div>
<!--        <div class="load-more-btn-wrapper" *ngIf="showLoadMore">-->
<!--          <button class="load-more-btn" (click)="loadMoreEvents()">Load More</button>-->
<!--        </div>-->
      </div>

  </div>
</div>
