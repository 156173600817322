<div class="container" *ngIf="orgId != undefined && orgId != null && orgId != ''">
  <!-- breadcrumb -->
  <nav aria-label="breadcrumb">
      <ol class="breadcrumb bc-custom" style="padding-left: 0px !important">
        <li class="breadcrumb-item"><a class="bc-item-active"> My Projects </a></li>
      </ol>
  </nav>
  <div class="project-title-layout">
    <h3 class="box-title">My Projects <h3 class="box-title" *ngIf="!loader">&nbsp;( {{projects.length}} ) </h3></h3>
    <!-- <div class="dropdown">
        <button class="tenant-cl btn btn-primary dropdown-toggle menu-dropdown-content text-white font-class" type="button" data-toggle="dropdown">
            Sort by
        </button>
        <ul class="dropdown-menu">
          <button type="button" class="btn menu-item-btn" routerLink='/setting'>Settings</button>
          <button type="button" class="btn menu-item-btn" (click)='logout()'>Sign out</button>
        </ul>
    </div> -->
  </div>
  <div class="project-container">
    <div class="col-md-6 col-sm-12 item" *ngFor="let project of projects">
      <div class="card item-card card-body"  tabindex="0" (click)="navigateToProduct(project)" (keypress)="navigateToProduct(project)">
        <div class="status-info status-running" *ngIf="project.projectStatus === 'Active'">
          <span class="font-class status-text">{{project.projectStatus}}</span>
        </div>
        <div class="status-info status-paused" *ngIf="project.projectStatus === 'Paused' || project.projectStatus === 'Creating'">
            <span class="font-class status-text">{{project.projectStatus}}</span>
        </div>
        <div class="status-info status-stopped" *ngIf="project.projectStatus === 'Stopped' || project.projectStatus === 'Failed'">
          <span class="font-class status-text">{{project.projectStatus}}</span>
      </div>
        <div class="project-header-layout">
          <img class="project-image" src={{project.img_src}} alt="{{project.project_type}} Project">
          <h3 class="card-title  mt-3 mb-3 project-name text-white font-class" title="{{project.name}}">{{ nameTrim(project.name)}}</h3>
        </div>
        <div class="project-info">
          <div class="project-principal-info">
            <label class="project-principal-header font-class">Project Owner</label>
            <label class="project-principal-value text-white font-class">{{project.ownerName}}</label>
          </div>
          <div class="project-principal-info">
            <label class="project-principal-header font-class">Project Budget</label>
            <label class="project-budget-value text-white font-class">$ {{project.budget}}
              <span class="budget-consumed-txt" [ngClass]="getProjectThresholdClass(project)">( {{project.consumedBudgetPercentage}} % used ) </span>
            </label>
          </div>
        </div>
        <p title="{{project.description}}" class="card-text text-white font-class ellipse">{{projectDescription(project.description)}}</p>
      </div>
    </div>

    <div *ngIf="projects.length === 0 && orgId != undefined && orgId != null && orgId != '' && !loader" class="empty-my-product-layout">
        <div>
          <h3 class="no-data-found text-white font-class text-center">You are not assigned to any projects. Please contact your principal investigator.</h3>
          <!-- <h3 class="no-data-found-subtext text-white font-class text-center"></h3> -->
        </div>
      </div>
  </div>
  <!-- <div class="load-more-btn-wrapper">
    <button class="load-more-btn font-class">Load More</button>
  </div> -->
</div>

<div *ngIf="!loader && orgId == undefined || orgId == null || orgId == ''"  class="container empty-my-product-layout">
    <div>
      <h3 class="no-data-found text-white font-class text-center">You are not assigned to an organization. Please contact your administrator.</h3>
    </div>
</div>

