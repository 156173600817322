<div class="container">

  <!-- breadcrumb -->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb bc-custom">
      <li class="breadcrumb-item active bc-item-active" aria-current="page">Audit Trail</li>
    </ol>
  </nav>
  <!-- details -->
  <div class="title-bc" style="display: flex; justify-content: space-between; align-items: center; margin: 10px 0px;">
    <div style="display: flex;">
      <div style="display: flex; flex-direction: column;">
        <h4 class="text-white font-class">Audit Trail</h4>
      </div>
    </div>
    <div class="text-right">
      <div class="search-div" style="margin-right: auto">
        <em class="glyphicon glyphicon-search search-icon"></em>
        <input (keyup)="searchKeypair()" [(ngModel)]="searchKey" class="form-control search-inp search-inp-add" placeholder="Type an organization" type="text">
      </div>
      <button (click)="refresh()" type="button" class="search-web btn btn-lg btn-primary btn-pi font-class mr-2 mb-1" title="Refresh">
        <em class="fa fa-refresh white"></em> Refresh </button>
    </div>
  </div>

  <div class="divider"></div>

  <div class='s3Explore'>

    <div class="m-search-tp">
      <!-- Responsive actions -->
      <div class="search-div-responsive">
        <em class="glyphicon glyphicon-search search-icon"></em>
        <input style=" width: 100%; " (keyup)="searchKeypair()" [(ngModel)]="searchKey" class="form-control search-inp search-inp-add" placeholder="Type an organization" type="text">
      </div>

    </div>


    <app-loader class="spinner" *ngIf="showSpinner"></app-loader>

    <div *ngIf="auditOrgNameList.length === 0 && noData === true">
      <h4 style=" color: #fff;text-align: center;padding: 100px; font-size: 22px">
        {{noDataTemplate}}
      </h4>
    </div>
    <div *ngIf="auditOrgNameList.length > 0" class="table-responsive md-layout lg-layout" style="margin-bottom: 25px;">
      <table class = "gfg" style="width: 100%">
        <thead style="background-color: #15244B">
        <tr>
          <th class="display-none">
            <input id="keyp" type="checkbox" name="allCheckBoxSelected">
            <label for="keyp"></label>
          </th>
          <th class="tableHeader" style="width: 20%;">ORGANIZATION NAME</th>
          <th class="tableHeader" style="width: 25%;">NO OF PRINCIPAL INVESTIGATORS</th>
          <th class="tableHeader" style="width: 15%;">NO OF RESEARCHERS</th>
          <th class="tableHeader" style="width: 20%;">COMMISSIONED PRODUCTS</th>
          <th class="tableHeader" style="width: 30%;">TOTAL LOGS</th>
          <th class="tableHeader"></th>
        </tr>
        </thead>
        <tbody style="background-color: #07102A">
        <tr  *ngFor="let data of auditOrgNameList" style="background-color:inherit">
          <td class="display-none" style="width: 10px;">
            <input id="{{data._id}}" type="checkbox" [(ngModel)]="data.isChecked" [checked]="data.isChecked" >
            <label for="{{data._id}}"></label>
          </td>
          <td title="{{data.org_name}}" class="tableData" style="width: 25%;font-size: 14px">{{projectName(data.org_name)}}</td>
          <td class="tableData" style="width: 20%; font-size: 14px"> {{data.NumberOfPrincipals}} </td>
          <td class="tableData" style="width: 15%; font-size: 14px"> {{data.ResearcherCount.length}} </td>
          <td class="tableData" style="width: 20%; font-size: 14px"> {{data.ProductCount.length}} </td>
          <td class="tableData" style="width: 30%;font-size: 14px" >{{data.LogCount}}</td>
          <td (click)="navigateToAuditDetails(data.org_id, data.org_name)" class="tableData" style="text-align: center;overflow: visible !important;position:relative;width: 10px;">
            <div class="dropdown">
                  <span>
                  <i class="fa fa-chevron-right" data-toggle="dropdown" title="Audit Details" aria-hidden="true" style="color: #f1f1f1;cursor: pointer;"></i>
                </span>
            </div>
          </td>
        </tbody>
      </table>
      <div class="load-more-btn-wrapper" *ngIf="showLoadMore">
        <button class="load-more-btn" (click)="loadMoreEvents()">Load More</button>
      </div>
    </div>

    <div class="sm-layout xs-layout" *ngIf="auditOrgNameList.length > 0 && !showSpinner">
      <!-- mobile responsive -->
      <div class="project-cards-layout">
        <div class="project-card" *ngFor="let data of auditOrgNameList;">
          <ul class="list-group">
            <div class="d-flex justify-content-between mb-2">
              <div class="project-card-title project-card-line-height font-class">ORGANIZATION NAME</div>
              <div class="project-card-project-name" title="{{data.org_name}}">{{projectName(data.org_name)}}</div>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <div class="project-card-title project-card-line-height font-class">NO OF PRINCIPAL INVESTIGATORS</div>
              <div class="project-card-project-name">{{data.NumberOfPrincipals}}</div>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <div class="project-card-title project-card-line-height font-class">NO OF RESEARCHERS</div>
              <div class="project-card-project-name" >{{data.ResearcherCount.length}}</div>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <div class="project-card-title project-card-line-height font-class">COMMISSIONED PRODUCTS</div>
              <div class="project-card-project-name" >{{data.ProductCount.length}}</div>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <div class="project-card-title project-card-line-height font-class">TOTAL LOGS</div>
              <div class="project-card-project-name" >{{data.LogCount}}</div>
            </div>
            <div class="d-flex justify-content-between mb-2" style="justify-content: center !important; margin-top: 10px;">
              <button type="button" style="width: 100%;" class="btn btn-xs btn-dark cat-btn-update btn-black" (click)="navigateToAuditDetails(data.org_id, data.org_name)"> Details </button>
            </div>
          </ul>
        </div>
      </div>
      <div class="load-more-btn-wrapper" *ngIf="showLoadMore">
        <button class="load-more-btn" (click)="loadMoreEvents()">Load More</button>
      </div>
    </div>

  </div>
</div>
