<div #CostControlUndoAction tabindex="-1">
  <div class="dialog-header">
      <img class="cancel-icon" (click)="cancel()" (keypress)="cancel()" tabindex="0" src="../../assets/images/cancel@2x.png" alt="Cancel"/>
      <h3 class="title">Cost Control</h3>
  </div>
  <div style="display: flex; flex-direction: row; margin-top: 0px;">
    <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
    <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
  </div>
  <div class="content">
    <h2 class="content-1">
      Your resources will incur costs as long as they are running. 
      Research Gateway can help to control those costs by taking automatic actions when budget thresholds are breached. 
      By turning this feature off, you will lose the benefits of this cost control feature. 
      <br>
      <br> Are you sure you want to proceed?
    </h2>
  </div>
  <div class="mdl-dialog__actions" style="margin-top: 15px; margin-bottom: 15px;">
    <button class="btn btn-primary dele-btn" type="button" (click)="close()"><h2 class="del-text">OK</h2></button>
    <button class="btn  can-btn" type="button"  (click)="cancel()"><h2 class="cal-text">Cancel</h2></button>
  </div>
</div>
