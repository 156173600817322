<div class="signup-container" *ngIf=checkIfResetFormShouldBeShown()>
  <div class="signup-content">
    <div class="reset-image-wrapper">
      <img class="reset-image" src="../../assets/images/login.png" alt="Login to Research Gateway"/>
    </div>
    <div class="reset-form-wrapper">
    <h2 class="signup-label">Reset Password</h2>
    <div style="display: flex; flex-direction: row; margin-top: 10px;">
      <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
      <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
      <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
      <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
    </div>
    <form #f="ngForm" novalidate class="form-wrapper" (ngSubmit)="f.form.valid && resetCognitoPassword()">
<!--      <div class="form-group">-->
<!--        <label class="input-label" for="username">Username </label>-->
<!--        <input type="text" class="form-control user-input" id="username" maxlength="50" name="username" required-->
<!--          [(ngModel)]="fromData.username" #username="ngModel" placeholder="Please enter your username">-->
<!--        <div *ngIf="!f.submitted" class="info-block">Please enter the username created for you by your administrator</div>-->
<!--        <div *ngIf="f.submitted && username.valid" class="info-block">Please enter the username created for you by your administrator</div>-->
<!--        <div *ngIf="f.submitted && !username.valid" class="help-block">Please enter the username created for you by your administrator</div>-->
<!--      </div>-->
      <div class="form-group">
        <label class="input-label" for="email">Email ID </label>
        <input type="email" class="form-control user-input" name="email" id="email" placeholder="Please enter your email id"
          maxlength="254" [(ngModel)]="fromData.email" #email="ngModel"
          pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" required>
        <div *ngIf="!f.submitted" class="info-block">Please enter your registered email id</div>
        <div *ngIf="f.submitted && email.valid" class="info-block">Please enter your registered email id</div>
        <div *ngIf="f.submitted && !email.valid || email.errors?.['pattern']" class="help-block">Please enter your registered email id</div>
      </div>

      <div class="row">
        <div>
          <button type="submit" class="btn btn-dark btn-block btn-flat submit-btn">Send Reset link</button>
        </div>
      </div>
    </form>
  </div>
  </div>
</div>

<app-loader class="spinner" *ngIf="showSpinner"></app-loader>

<div class="signup-container" *ngIf="showSuccessMsg && !username && !confirmationCode && !showSuccessVerifiedMsg">
  <div class="container successMsg">
    <img class="success-icon" src="../../assets/images/success@2x.png" alt="Reset Password Link sent Successfully"/>
    <h3 class="successDataMsg">
      Password reset link has been sent to your registered email.
    </h3>
    <h4 class="linkMsg">
      Please follow the instructions to proceed further.
    </h4>
  </div>
</div>

<div class="signup-container" *ngIf="showSuccessVerifiedMsg && !showSuccessMsg">
  <div class="container successMsg" style="height: 390px;">
  <img class="success-icon" src="../../assets/images/success@2x.png" alt="Reset Password Successfully"/>
  <h3 class="successDataMsg">
    Password reset successful.
    </h3>
  <h4 class="linkMsg" style="margin-top: 15px !important">
    Your account password has been reset successfully.
    </h4>
    <div class="row">
      <div>
        <button type="submit" class="btn btn-dark btn-block btn-flat submit-btn" style="width: 200px !important;" (click)="login()">Click here to login</button>
      </div>
    </div>
  </div>
</div>

<div class="signup-container"  *ngIf="showSuccessResetVerifiedMsg && !showSuccessMsg">
  <div class="container successResetMsg" style="height: 420px;">
    <img class="success-icon" src="../../assets/images/success@2x.png" alt="Account Verified successfully."/>
      <h3 class="successDataMsg">
        Account verification successful.
      </h3>
      <h4 class="linkMsg" style="margin-top: 15px !important">
        Your account has been verified. You can now login using your email address.
      </h4>
      <div class="row">
        <div>
          <button type="submit" class="btn btn-dark btn-block btn-flat submit-btn" style="width: 200px !important" (click)="login()">Click here to login</button>
        </div>
      </div>
  </div>
</div>

<div class="signup-container" *ngIf="!showSuccessVerifiedMsg && !showSuccessMsg && showErrorMsg">
  <div class="container successMsg" style="height: 390px;">
    <img class="success-icon" src="../../assets/images/icons/error@2x.png" alt="User Already Confirmed"/>
    <h3 class="successDataMsg" *ngIf="!showLoginButton">
      Oops ! Something went wrong.
    </h3>
    <h3 class="successDataMsg" *ngIf="showLoginButton">
      This user is already confirmed.
    </h3>
    <h4 class="linkMsg" style="margin-top: 15px !important">
      {{errorMessage}}
    </h4>
    <div class="row" style="margin-bottom: 30px">
      <div class="col-xs-8">
      </div>
      <div class="col-xs-4" *ngIf="!showLoginButton">
        <button type="submit" class="btn btn-dark btn-block btn-flat submit-btn" (click)="tryAgian()">Try again</button>
      </div>
      <div class="col-xs-4" *ngIf="showLoginButton">
        <button type="submit" class="btn btn-dark btn-block btn-flat submit-btn" (click)="loginRoute()">Redirect to Login</button>
      </div>
    </div>
  </div>
</div>

<div class="signup-container" *ngIf=checkIfPasswordFormShouldBeShown()>
  <div class="signup-content">
    <div class="reset-image-wrapper">
      <img class="reset-image"  alt="Login to Research Gateway" src="../../assets/images/login.png" />
    </div>
    <div>
    <h2 class="signup-label">Change Password</h2>
    <div style="display: flex; flex-direction: row; margin-top: 10px;">
      <div style="height: 0.318rem; background-color: #00A4FF; flex: 1;"></div>
      <div style="height: 0.318rem; background-color: #3FCB57; flex: 1;"></div>
      <div style="height: 0.318rem; background-color: #FFB500; flex: 1;"></div>
      <div style="height: 0.318rem; background-color: #F32561; flex: 1;"></div>
    </div>
    <form #f="ngForm" novalidate class="form-wrapper" (ngSubmit)="f.form.valid && !hasError && confirmResetCognitoPass()">
      <div class="form-group" style="position: relative;">
        <!-- <popover-content placement="auto" #myPopover [closeOnClickOutside]="true">
          <div id="tagPop"  [innerHTML]="policy()"></div>
        </popover-content> -->
        <label for="Password" class="input-label">Enter New Password </label>
        <!-- <i title="Click to view Password Policy" class="fa fa-question-circle" [popover]="myPopover"
          [popoverCloseOnClickOutside]="true" style=" position: absolute;padding:0px 20px;color: #FFB500; top: 0px; right: 0px; font-size: 17px;"></i> -->
        <div style="padding: 0px !important;  border-top-right-radius: 3px !important;border-bottom-right-radius: 3px !important;" class="input-group user-input">
        <input [type]="showPassword ? 'text' : 'password'" class="form-control password-input" name="Password" id="Password" placeholder="Enter your new password"
          [(ngModel)]="fromData.password" #password="ngModel" maxlength="16" required
          pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,16}$" (change)="passwordEmpty()"
          (keypress)="passwordEmpty()" (keyup)="passwordEmpty()" (keydown.space)="$event.preventDefault()" />
          <button type="button" class="button" (keypress)="togglePassword(true)" (click)="togglePassword(true)" *ngIf="showPassword === false"  style="background-color: #07102a; border: solid #07102a">
            <img class="show-icon" alt="Show Password" src="../../assets/images/pass_inactive_icon.png"/>
          </button>
        <button type="button" class="button" *ngIf="showPassword === true" (click)="togglePassword(false)" (keypress)="togglePassword(false)"  style="background-color: #07102a; border: solid #07102a">
            <img class="show-icon" alt="Hide Password" src="../../assets/images/pass_active_icon.png" />
        </button>
        </div>
         <span class="length-cal" style="margin-bottom:-24px;padding:0px 20px"><small>{{16-fromData.password.length}} Characters left
          </small></span>
          <div *ngIf="!f.submitted" class="info-block" style="margin-top: 10px;">Please enter your password</div>
          <div *ngIf="f.submitted && password.valid" class="info-block" style="margin-top: 10px;">Please enter your password</div>
          <div *ngIf="f.submitted && !password.valid || password.errors?.['pattern']"
            class="help-block">Password must contain minimum 8 characters, <br> 1 uppercase, 1 numeric & 1 special character
          </div>
      </div>

      <div class="form-group">
        <label class="input-label" for="repass">Confirm Password</label>
        <div style="padding: 0px !important;  border-top-right-radius: 3px !important;border-bottom-right-radius: 3px !important;" class="input-group user-input">
        <input style="padding-left: 19px !important;" [type]="showNewPass ? 'text' : 'password'" name="repass" class="form-control password-input" #repass="ngModel"
          [(ngModel)]="fromData.repass" id="repass" placeholder="Please re enter your password" maxlength="16" required
          pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,16}$" (change)="passwordMatch()"
          (keypress)="passwordMatch()" (keyup)="passwordMatch()" (keydown.space)="$event.preventDefault()">
          <button type="button" class="button" (click)="toggleNewPassword(true)" (keypress)="toggleNewPassword(true)"   style="background-color: #07102a; border: solid #07102a" *ngIf="showNewPass === false">
            <img class="show-icon"  alt="Show Password" src="../../assets/images/pass_inactive_icon.png"/>
        </button>
        <button type="button" class="button" *ngIf="showNewPass === true"   style="background-color: #07102a; border: solid #07102a" (click)="toggleNewPassword(false)" (keypress)="toggleNewPassword(false)">
            <img class="show-icon" alt="Hide Password" src="../../assets/images/pass_active_icon.png" />
        </button>
      </div>
        <div style="position:relative;" *ngIf="!hasError" class="info-block">Please re enter your password</div>
        <div style="position:relative;" *ngIf="hasError" class="help-block">{{errorMsg}}</div>
      </div>

      <div class="row">
        <div class="col-xs-8">
        </div>
        <div class="col-xs-4">
          <button type="submit" class="btn btn-dark btn-block btn-flat submit-btn">Submit</button>
        </div>
      </div>
    </form>
  </div>
  </div>
</div>

